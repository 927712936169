import React from 'react';
import PropTypes from 'prop-types';
import {adminPagesData} from 'data/admin-data';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import AdminHeader from './admin-header/admin-header';
import AdminGames from './admin-games/admin-games';
import AdminFacilitators from './admin-facilitators/admin-facilitators';
import AdminStatistics from './admin-statistics/admin-statistics';
import AdminGameContent from './admin-game-content/admin-game-content';
import AdminUsers from './admin-users/admin-users';
import './admin.scss';

const Admin = (props) => {
	const {
		page, 
		userData,
		gamesData,
		usersData,
		tagsData,
		updateGameData,
		updateTagsData,
		addFacilitatorsToGame,
		removeFacilitatorFromGame,
		deleteFacilitator,
		handleGoToPage, 
		handleLogout,
		createGame, 
		deleteGame,
		deviceInfo,
	} = props;

	/* Get page data */
	const pageData = adminPagesData.find((p) => {return p.id === page;});

	/* Get content component */
	let PageComponent = AdminGames;
	if (page === 'facilitators') PageComponent = AdminFacilitators;
	if (page === 'statistics') PageComponent = AdminStatistics;
	if (page === 'game-content')  PageComponent = AdminGameContent;
	if (page === 'users') PageComponent = AdminUsers;

	return (
		<div className="Admin">
			<DndProvider options={HTML5toTouch}>
				<div className="Admin-header">
					<AdminHeader 
						page={page} 
						userData={userData} 
						handleGoToPage={handleGoToPage} 
						handleLogout={handleLogout}
					/>
				</div>
				<div className="Admin-content">
					<PageComponent 
						type="admin-page"
						deviceInfo={deviceInfo}
						pageData={pageData} 
						gamesData={gamesData}
						usersData={usersData}
						tagsData={tagsData}
						handleGoToPage={handleGoToPage}
						updateGameData={updateGameData}
						updateTagsData={updateTagsData}
						addFacilitatorsToGame={addFacilitatorsToGame}
						removeFacilitatorFromGame={removeFacilitatorFromGame}
						deleteFacilitator={deleteFacilitator}
						createGame={createGame}
						deleteGame={deleteGame}
					/>
				</div>
			</DndProvider>
		</div>
	);

};

Admin.propTypes = {
	page: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	updateGameData: PropTypes.func.isRequired,
	updateTagsData: PropTypes.func.isRequired,
	addFacilitatorsToGame: PropTypes.func.isRequired,
	removeFacilitatorFromGame: PropTypes.func.isRequired,
	deleteFacilitator: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
};

export default Admin;
