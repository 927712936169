import React from 'react';
import PropTypes from 'prop-types';
// import {facilitatorPagesData} from 'data/facilitator-data';
import appConfig from 'config/app.config';
import {loginUiTexts, generalUiTexts, facilitatorUiTexts} from 'data/ui-texts';
import {facilitatorPagesData} from 'data/facilitator-data';
import Button from 'components/ui/button/button';
import logoMDIImg from 'assets/images/facilitator/logo-mdi.svg';
import facilitatorImg from 'assets/images/facilitator/text-facilitator.svg';
import coFacilitatorImg from 'assets/images/facilitator/text-co-facilitator.svg';
import Manager from 'components/ui/manager/manager';
import './facilitator-header.scss';

const FacilitatorHeader = (props) => {
	/* Props */
	const {page, userData, coFacilitatorData, gameData, setShowManagerPopup, handleGoToPage, handleLogout} = props;

	/* Facilitator / co-facilitator */
	const titleImage = (coFacilitatorData ? coFacilitatorImg : facilitatorImg);
	const titleImageAlt = (coFacilitatorData ? generalUiTexts.coFacilitator : generalUiTexts.facilitator);
	
	/* Page and menu data */
	const pageData = facilitatorPagesData.find((p) => {return p.id === page;});
	const menu = (pageData && pageData.menu ? pageData.menu : null);

	/* Game url */
	const gameUrl = appConfig.gameUrl + '/' + (gameData && gameData.url ? gameData.url : '');
	
	/* Manager id */
	const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);


	return (
		<div className={'FacilitatorHeader ' + page}>
			{/* Logo */}
			<div 
				className="FacilitatorHeader-logo"
				onClick={() => {handleGoToPage('overview');}}
			>
				<img src={logoMDIImg} alt={generalUiTexts.mdiLogo} />
				<img src={titleImage} alt={titleImageAlt} />
			</div>

			{/* Logout / info buttons */}
			<div className="FacilitatorHeader-buttons">
				<Button classes={['logout']} text={loginUiTexts.logout} onClick={handleLogout} />
				{/* <Button classes={['help']} onClick={() => {console.log('info plz!');}} /> */}
			</div>

			{/* User info */}
			<div className="FacilitatorHeader-user">
				<div className="FacilitatorHeader-userInfo">
					<div><span>{loginUiTexts.loggedInAs}:</span><span>{userData.name}</span></div>
					<div><span>{generalUiTexts.store}:</span><span>{gameData ? gameData.storeName : '-'}</span></div>
					<div>
						<span>{generalUiTexts.url}:</span>
						<a href={'https://' + gameUrl} target="_blank" rel="noreferrer">{gameUrl}</a>
					</div>
				</div>
				<div className="FacilitatorHeader-userProfile">
					<Manager page="facilitator-header" managerPose="manager-2" managerId={managerId} />
				</div>
				{(!coFacilitatorData || coFacilitatorData.canChangeManager) && 
					<div className="FacilitatorHeader-userProfileEditBtn" onClick={() => {setShowManagerPopup(true);}}>
						<span>{facilitatorUiTexts.changeAvatar}</span>
					</div>
				}
				
			</div>

			{/* Menu */}
			{menu && <div className="FacilitatorHeader-menu">
				{menu.map((item) => {
					return (
						<div 
							key={item.pageId}
							className={'FacilitatorHeader-menuItem' + (item.pageId === page ? ' selected' : '')}
							onClick={() => {handleGoToPage(item.pageId);}}
						><span>{item.title}</span></div>
					);
				})}	
			</div>}
		</div>
	);

};

FacilitatorHeader.propTypes = {
	page: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	coFacilitatorData: PropTypes.object,
	gameData: PropTypes.object,
	setShowManagerPopup: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorHeader;
