import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getNumberOfFilledStars} from 'helpers/points-helper';
import {starsPerPercentPoints} from 'data/points-data';
import Star from 'components/ui/star/star';
import './points-track.scss';

const PointsTrack = ({points, maxPoints}) => {
	const percentPoints = points / maxPoints * 100.;
	const filledStars = getNumberOfFilledStars(points, maxPoints);
	
	return (
		<div className={'PointsTrack'}>
			<div className="PointsTrack-track">
				<div className="PointsTrack-score" style={{width: percentPoints + '%'}} />
			</div>
			<div className="PointsTrack-stars">
				{[...Array(appConfig.maxStarsPerModule)].map((_, index) => {
					const leftPos = starsPerPercentPoints.pointSteps[index + 1] + '%';
					return (
						<div key={index} className="PointsTrack-star" style={{left: leftPos}}>
							<Star page="track" isFilled={index < filledStars} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

PointsTrack.propTypes = {
	points: PropTypes.number.isRequired,
	maxPoints: PropTypes.number.isRequired
};

export default PointsTrack;
