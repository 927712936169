const moduleCustomerService1 = [
	'modules/tasks/clock-tasks/customer-service-1/csc-01.svg',
	'modules/tasks/clock-tasks/customer-service-1/csc-02.svg',
	'modules/tasks/clock-tasks/customer-service-1/csc-03.svg',

	'modules/tasks/image/customer-service-1-daily-information-board.svg',
	'modules/tasks/image/customer-service-1-desk-front.svg',
	'modules/tasks/image/customer-service-1-desk.svg',
	'modules/tasks/image/customer-service-1-clip-board.svg',

	'modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-4.svg',
	
	'modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-4.svg',
	'modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-5.svg',
	
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-4.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-5.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-6.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-7.svg',
	'modules/tasks/multiple-choice/customer-service-1-cleaning/option-8.svg',
	'modules/tasks/multiple-choice/customer-service-1-clipboards/background.svg',
	'modules/tasks/multiple-choice/customer-service-1-head-out/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-head-out/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-head-out/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-pick-up-phone/background.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-4.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-5.svg',
	'modules/tasks/multiple-choice/customer-service-1-rain-items/option-6.svg',
	'modules/tasks/multiple-choice/customer-service-1-shelf-before-walk-away/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-shelf-before-walk-away/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-4.svg',

	'modules/tasks/spot-errors/customer-service-1-service-desk.svg'
];

export {
	moduleCustomerService1
};
