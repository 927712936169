const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const moduleTasks = [
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-01',
		showLogo: true,
		text: `It's your first day.`,
		buttons: [
			{text: 'Approach store', nextTaskId: 'intro-02', classes: ['intro']}
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-02',
		showLogo: true,
		wizard: 'wizard-1',
		text: `As you approach, a ... Wizard appears?!?`,
		nextTaskId: 'intro-03'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-03',
		zoomLevel: 2,
		wizard: 'wizard-1',
		title: 'Welcome traveler!',
		text: `I have searched the parking lots far and wide, and finally I've found you; the chosen one!`,
		textType: 'speech',
		nextTaskId: 'intro-04',
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-04',	
		zoomLevel: 2,
		wizard: 'wizard-1',
		text:'You are destined to be the one true hero of this ancient land known as ... <br /><strong>Suopi Markellon</strong>. (It\'s Elvish.)',
		textType: 'speech',
		nextTaskId: 'intro-05'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-05',	
		zoomLevel: 2,
		wizard: 'wizard-1',
		text: `YOU must save this land. It is your destiny!<br /><br /><strong>Will you accept this quest, stranger?</strong>`,
		textType: 'speech',
		buttons: [
			{text: 'Yes', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-06', classes: ['intro', 'dark']}
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-06',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `Really? Aw. Come on.<br /><br /><strong>Are you sure you won't do it?</strong>`,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-07', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-07',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `Pretty please?`,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-08', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-08',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `Pretty please with sugar on top?`,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-09', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-09',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `You know you want to.`,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-10', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-10',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `<strong>Come on!</strong><br />You're like the 5th person I've asked this morning.`,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-11', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-11',	
		zoomLevel: 3,
		wizard: 'wizard-3',
		text: `I'm going to hold my breath until you say yes.`,
		textType: 'speech',
		buttons: [
			{text: 'Yes', nextTaskId: 'intro-14', classes: ['intro']},
			{text: 'No', nextTaskId: 'intro-12', classes: ['intro', 'dark']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-12',	
		zoomLevel: 3,
		wizard: 'wizard-2',
		text: `<strong>[pfffffffff!]</strong><br />Okay, I give up. Just go ahead. `,
		textType: 'speech',
		buttons: [
			{text: 'Okay', nextTaskId: 'intro-13', classes: ['intro']},
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-13',	
		zoomLevel: 3,
		wizard: 'wizard-1',
		text: `<strong>Ha! I tricked you!</strong> Quest accepted. Defend the Gates! Remove the 5 dangers next.`,
		textType: 'speech',
		nextTaskId: 'intro-15'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-14',	
		zoomLevel: 3,
		wizard: 'wizard-1',
		text: `<strong>Marvelous!</strong> Before we begin. let's test your skills against the front gates. On the next image, make sure the store is ready for customers by clicking on the five things that are wrong.`,
		textType: 'speech',
		nextTaskId: 'intro-15'
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		id: 'intro-15',
		taskId: 'intro-spot-errors-parking-lot',
		showPoints: false,
		layout: 'intro-parking-lot',
		errors: [
			{id: 'flower'}, 
			{id: 'box'}, 
			{id: 'bin'}, 
			{id: 'cart'},
			{id: 'orc'}
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'intro-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
		nextTaskId: 'intro-16'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-16',	
		zoomLevel: 3,
		wizard: 'wizard-1',
		text: `<strong>Fantastic!</strong><br />Good luck with your mission. Remember: Only you can save Suopi Markellon!`,
		textType: 'speech',
		nextTaskId: 'intro-17'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-17',
		zoomLevel: 4,
		managerPose: 1,
		managerFadeIn: true,
		textFadeIn: true,
		text: `<strong>Hi!</strong><br />Welcome to your 1st day in the digital grocery store. <br /> <br /> I'm the Manager and I'll be helping you.`,
		textType: 'speech',
		nextTaskId: 'intro-18'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-18',
		zoomLevel: 4,
		managerPose: 2,
		text: `And thanks for helping the store look presentable before you even came in the door.`,
		textType: 'speech',
		nextTaskId: 'intro-19'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-19',
		zoomLevel: 4,
		managerPose: 2,
		text: `By the way, that "Wizard" is not affliated with us. But I'm glad YOU are here!`,
		textType: 'speech',
		nextTaskId:  'intro-20'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-20',
		zoomLevel: 4,
		managerPose: 1,
		text: `In the game, you earn <strong>POINTS</strong> by completing tasks. The fewer mistakes you make, the more points you get.`,
		textType: 'speech',
		nextTaskId:  'intro-21'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-21',
		showPoints: true,
		zoomLevel: 4,
		managerPose: 2,
		text: `By earning points you get stars. You can see your points and stars at the top of the screen.`,
		textType: 'speech',
		nextTaskId:  'intro-22'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-22',
		showPoints: true,
		zoomLevel: 4,
		managerPose: 2,
		text: `By completing modules, earning stars, and by solving tasks in specific ways, you can also unlock <strong>BADGES</strong>.`,
		textType: 'speech',
		nextTaskId:  'intro-23'
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-23',
		showPoints: true,
		highlightAvatarLink: true,
		zoomLevel: 4,
		managerPose: 2,
		text: `You can see your badges and edit your avatar on the profile screen. Go take a look and then come back here.`,
		textType: 'speech',
		onLoadEffects: [
			{type: 'update-currentTaskId', value: 'intro-24'}
		]
	}),
	Object.assign({}, taskTemplates.introTemplate.mainTemplate, {
		id: 'intro-24',
		isLastTask: true,
		showPoints: true,
		zoomLevel: 4,
		avatar: 'avatar',
		managerPose: 2,
		text: `Let's begin with <strong>the Orientation</strong> module on the next screen. `,
		textType: 'speech',
		buttons: [
			{text: 'Go to Orientation', classes: ['intro']}
		]
	}),
];

export {
	moduleTasks
};