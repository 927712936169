import {areasData} from 'data/areas-data';
import {checkIfAllModulesInAreaAreCompleted} from 'helpers/module-helper';

/**
 * Check if an area is unlocked
 * @param {string} areaId 
 * @param {array} playerModulesData 
 * @param {object} gameData 
 * @returns 
 */
export function checkIfAreaIsUnlocked(areaId, playerModulesData, gameData) {
	let areaIsUnlocked = false;
	const areaData = areasData.find((a) => {return a.id === areaId;});

	if (areaData) {
		areaIsUnlocked = true;
		if (areaData.unlockConditions) {
			areaData.unlockConditions.forEach((condition) => {
				if (!areaIsUnlocked) return;

				if (condition.type === 'area-completed') {
					/* Check if required area is completed */
					const areaIsCompleted = checkIfAllModulesInAreaAreCompleted(condition.areaId, playerModulesData);
					if (!areaIsCompleted) areaIsUnlocked = false;
				}

				if (condition.type === 'not-deactivated') {
					/* Check if deactivated by admin / facilitator */
					if (
						gameData && 
						gameData.deactivatedAreaIds && 
						gameData.deactivatedAreaIds.indexOf(areaId) >= 0
					) {
						areaIsUnlocked = false;
					}
				}

			});
		}
	}
	return areaIsUnlocked;
}


/**
 * Get why area is locked
 * @param {string} areaId 
 * @param {array} playerModulesData 
 * @param {object} gameData 
 * @returns 
 */
export function getWhyAreaIsLocked(areaId, playerModulesData, gameData) {
	let whyAreaIsLocked = null;
	const areaData = areasData.find((a) => {return a.id === areaId;});

	if (areaData) {
		if (areaData.unlockConditions) {
			areaData.unlockConditions.forEach((condition) => {
				if (whyAreaIsLocked !== null) return;

				if (condition.type === 'not-deactivated') {
					/* Check if deactivated by admin / facilitator */
					if (
						gameData && 
						gameData.deactivatedAreaIds && 
						gameData.deactivatedAreaIds.indexOf(areaId) >= 0
					) {
						whyAreaIsLocked = {type: 'areaDeactivated', areaId: condition.areaId};
					}
				}

				if (condition.type === 'area-completed') {
					/* Check if required area is completed */
					const areaIsCompleted = checkIfAllModulesInAreaAreCompleted(condition.areaId, playerModulesData);
					if (!areaIsCompleted) {
						whyAreaIsLocked = {type: 'areaNotCompleted', areaId: condition.areaId};
					}
				}
			});
		}
	}

	return whyAreaIsLocked;
}