const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		text: `Welcome back to the Bakery.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		animateCharacter: true,
		animationId: 'bubble',
		text: `Are you aready for a party?`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right animate-enter'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `We are going to talk cakes.`,
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Woo-hoo. Free Cake.`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: `No one said ....<i>Sigh</i><br />Moving on.`,
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-important-role',
		text: `What is one of the most important roles in the Bakery?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Cake Decorator', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'The Dinner Roll',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `I said that's my job to tell jokes.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Baker'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'The donut maker'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'The bread chef'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Is that our customer's cake already in the cooler ready to go?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `The decorator left the cake in the rack for your special order.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-2-spot-errors-decorated-cake',
		text: `Let's double check it. Click on the issues.`,
		layout: 'bakery-2-decorated-cake',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'spelling'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'name'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'date'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-congratulations-spelling',
		text: `This one has always been a problem. Which is correct?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Congratulations', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `It's a tricky word and often misspelled on cakes.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Congradulations',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Congrats'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Congrads'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `I'll be sure to get with the decorator to fix it.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Oh you have another customer.`,
	}),
	// BAKERY DIALOG START
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'bakery-2-dialogue-reach-graduation-cake',
		characterId: 'shopping-cart-lady',
		maxPoints: 8,
		introText: 'You greet the customer and ask how you can help her.',
		introTextType: 'info',
		introCharacter: 'shopping-cart-lady-neutral flipped',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-02',
				text: 'I need a cake for my daugher\'s graduation.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Great. What do you need on it?', nextStepId: 'bd-03'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'One second, just let me get a cake form.', 
						nextStepId: 'bd-05',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Alright, when do you need it?', nextStepId: 'bd-04'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-03',
				text: 'It should say congratulations Zoe.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, and when do you need it?', 
						nextStepId: 'bd-06'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Alright, and what flavor do you want?', 
						nextStepId: 'bd-07',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'One second, I\'ll just get a cake form to make sure I get it all right.', 
						nextStepId: 'bd-08',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-04',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Okay, we\'ll have it ready.', 
						nextStepId: 'bd-09'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'What flavor do you want? And do you want something written on it? ', 
						nextStepId: 'bd-10',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'One second, I\'ll just get a cake form to make sure I get it all right.', 
						nextStepId: 'bd-11',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-05',
				type: 'action',
				text: 'You quickly return with the cake form.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'When do you want it ready?', 
						nextStepId: 'bd-12',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'What flavor and message do you want?', 
						nextStepId: 'bd-13',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-06',
				type: 'dialogue',
				text: 'This coming Saturday. In the morning. ',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll make sure to have it ready.', 
						nextStepId: 'bd-14',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'What flavor do you want?', 
						nextStepId: 'bd-15',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-07',
				type: 'dialogue',
				text: 'Chocolate, please.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'And when do you need it?', 
						nextStepId: 'bd-16',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Just to be sure, how do you spell Joey?', 
						nextStepId: 'bd-17',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3,
						text: 'Just to be sure, how do you spell Zoe?', 
						nextStepId: 'bd-18',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-1', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-08',
				type: 'action',
				text: 'You quickly return with the cake form.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'When do you need the cake?', 
						nextStepId: 'bd-19',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'What flavor should it be?', 
						nextStepId: 'bd-20',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3,
						text: 'Just to make sure, it was \'congratulations, Z-O-E\', right?', 
						nextStepId: 'bd-21',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-2', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-09',
				type: 'dialogue',
				text: 'Um, don\'t you need to know flavor and wording?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Yes, of course.', 
						nextStepId: 'bd-22',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Yes, of course. And just let me get a cake form so I get everything right.', 
						nextStepId: 'bd-11',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-10',
				type: 'dialogue',
				text: 'Chocolate. And it should say: Congratulations Zoe.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Great, we\'ll make sure to have it ready.', 
						nextStepId: 'bd-27',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Can you spell her name for me, please. Just in case.', 
						nextStepId: 'bd-18',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-3', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-11',
				type: 'action',
				text: 'You get the cake form and return to the customer.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'This Sunday morning. And what flavor and message do you want?', 
						nextStepId: 'bd-23',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Alright, what flavor and message do you want?', 
						nextStepId: 'bd-24',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3,
						text: 'This Saturday morning. And what flavor and message do you want?', 
						nextStepId: 'bd-24',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-12',
				type: 'dialogue',
				image: 'happy',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready.', 
						nextStepId: 'bd-25',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Noted. And what message and flavor do you want?', 
						nextStepId: 'bd-26',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-13',
				type: 'dialogue',
				image: 'happy',
				text: 'Chocolate, please. And it should say \'congratulations zoe\'',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sounds good. We\'ll have it ready for you. Thank you.', 
						nextStepId: 'bd-28',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Is that spelled Z-O-E?', 
						nextStepId: 'bd-29',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-4', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'When do you want it ready?', 
						nextStepId: 'bd-31',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-14',
				type: 'dialogue',
				image: 'tired',
				text: 'It should be a chocolate cake.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, that is noted. Thank you.', 
						nextStepId: 'bd-27',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Chocolate cake, congratulations Joey, ready Saturday, got it.', 
						nextStepId: 'bd-32',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'Chocolate cake, congratulations Zoe, ready Saturday. Got it.', 
						nextStepId: 'bd-27',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-15',
				type: 'dialogue',
				text: 'Chocolate, please.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready for you Sunday. Thank you.', 
						nextStepId: 'bd-33',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Alright, we\'ll have it ready for you Saturday. Thank you.', 
						nextStepId: 'bd-27',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-16',
				type: 'dialogue',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright. Chocolate cake, congratulations Joey, ready Saturday. We\'ll have it ready.', 
						nextStepId: 'bd-32',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Alright. Chocolate cake, congratulations Zoe, ready Saturday. We\'ll have it ready.', 
						nextStepId: 'bd-27',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-17',
				type: 'dialogue',
				image: 'angry',
				text: 'Not Joey, Z-O-E-Y. Zoey.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sorry about that. When do you need it?', 
						nextStepId: 'bd-34',
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-18',
				type: 'dialogue',
				image: 'happy',
				text: 'Z-O-E-Y. Thanks for asking.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Glad to, we want it to be perfect.', 
						nextStepId: 'bd-27',
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-19',
				type: 'dialogue',
				text: 'This Saturday in the morning. It should be a chocolate cake, by the way.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready for you. Thank you.', 
						nextStepId: 'bd-30',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Chocolate cake, Saturday morning, congratulations Zoe. We\'ll make sure it\'s ready.', 
						nextStepId: 'bd-30',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-20',
				type: 'dialogue',
				text: 'Chocolate, please. And can you have it ready this Saturday in the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'We sure can. Thank you.', 
						nextStepId: 'bd-27',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-21',
				type: 'dialogue',
				text: 'It\'s Z-O-E-Y, thanks for checking. And it should be a chocolate cake for this coming Saturday in the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready for you. Thank you.', 
						nextStepId: 'bd-35',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-22',
				type: 'dialogue',
				image: 'tired',
				text: 'Chocolate. And it should say: Congratulations Zoe.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Perfect. We\'ll have it ready for you this Sunday. Thank you.', 
						nextStepId: 'bd-33',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Perfect. We\'ll have it ready for you this Saturday. Thank you.', 
						nextStepId: 'bd-27',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-23',
				type: 'dialogue',
				image: 'angry',
				text: 'No, Saturday. It should be a chocolate cake and I want it to say \'congratulations Zoe\'.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sorry about that. I\'ll make sure we have it ready. Thank you.', 
						nextStepId: 'bd-30',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'I\'m sorry about that. And could you maybe spell Zoe, just to be sure?', 
						nextStepId: 'bd-18',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-24',
				type: 'dialogue',
				text: 'Chocolate. And it should say: Congratulations Zoe.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready. Thank you.', 
						nextStepId: 'bd-30',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Is that Z-O-E?', 
						nextStepId: 'bd-18',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-5', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-25',
				type: 'dialogue',
				text: 'Um, don\'t you need to know flavor and wording?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Yes, of course.', 
						nextStepId: 'bd-26',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-26',
				type: 'dialogue',
				text: 'Chocolate, please. And it should say \'congratulations Zoe\'',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sounds good. We\'ll have it ready for you. Thank you.', 
						nextStepId: 'bd-30',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'Is that spelled Z-O-E?', 
						nextStepId: 'bd-40',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'bakery-1-stickler-for-details-6', pointId: 'stickler-for-details', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-27',
				image: 'angry',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'shopping-cart-lady-angry',
				text: 'The customer leaves and you go to fill out the form. Hopefully you can remember everything - if she get\'s the wrong cake or the wrong message, she won\'t be happy.',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-28',
				type: 'dialogue',
				text: 'Don\'t you need to know when I want it?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Yes, of course. When do you need it?', 
						nextStepId: 'bd-36',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-29',
				type: 'dialogue',
				image: 'happy',
				text: 'It\'s actually Z-O-E-Y. Thanks for asking.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Glad to, we want it to be perfect. Thank you.', 
						nextStepId: 'bd-38',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Glad to, we want it to be perfect. When do you need it?', 
						nextStepId: 'bd-39',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-30',
				image: 'happy',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'shopping-cart-lady-happy',
				text: 'She thanks you and leaves. You have everything in the form, and that\'s good. But you forgot to check spelling, so she won\'t be happy when it\'s spelled Zoe and not Zoey.',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-31',
				type: 'dialogue',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright. Chocolate cake, congratulations Joey, ready Saturday. We\'ll have it ready.', 
						nextStepId: 'bd-32',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'Alright. Chocolate cake, congratulations Zoe, ready Saturday. We\'ll have it ready.', 
						nextStepId: 'bd-30',
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-32',
				type: 'dialogue',
				image: 'angry',
				text: 'Not Joey, Z-O-E-Y. Zoey.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sorry about that. Z-O-E-Y. We\'ll have it ready, thank you.', 
						nextStepId: 'bd-27',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-33',
				type: 'dialogue',
				image: 'angry',
				text: 'Not Sunday. Saturday.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Sorry about that. Saturday is noted. Thank you.', 
						nextStepId: 'bd-27',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-34',
				type: 'dialogue',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready. Thank you.', 
						nextStepId: 'bd-27',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-35',
				image: 'happy',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'shopping-cart-lady-happy',
				text: 'She thanks you and leaves. You have everything in the cake form and the cake will be ready and just perfect.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-36',
				type: 'dialogue',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll have it ready for you. Thank you.', 
						nextStepId: 'bd-30',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-38',
				type: 'dialogue',
				text: 'Don\'t you need to know when I want it?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Yes, of course. When do you need it?', 
						nextStepId: 'bd-39',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-39',
				type: 'dialogue',
				image: 'happy',
				text: 'This coming Saturday. In the morning.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, we\'ll make sure everything is ready. Thank you.', 
						nextStepId: 'bd-35',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-40',
				type: 'dialogue',
				image: 'happy',
				text: 'It\'s actually Z-O-E-Y. Thanks for asking.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Glad to, we want it to be perfect. Thank you.', 
						nextStepId: 'bd-35',
					}),
				]
			}),
		]		
	}),
	// BAKERY DIALOG END
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-why-buy-cakes',
		text: `Why do customers buy cakes?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Birthdays', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Holidays', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'For throwing',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `That's pies.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Anniversaries'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Graduations', isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-have-more',
		text: `Should we have more during those times then?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-plan-birthday',
		text: `Can we plan ahead for everyone's birthday?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No', isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-plan-holidays',
		text: `Can we plan ahead for Holidays?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-plan-anniversaries',
		text: `Can we plan ahead for Anniversaries?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `My wife says I can't either.`,
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-plan-graduations',
		text: `Can we plan ahead for Graduations?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Generally yes.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-when-graduation-cakes',
		subtype: 'images',
		layout: 'bakery-2-when-graduation-cakes',
		text: `When would we plan for graduation cakes?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // may
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Generally yes.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // july
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // march
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // june
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // december
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['cake-excessive-icing'],
		text: `Hey, I made a great cake.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `That is not a good cake.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-why-not-good-cake',
		text: `Why not?`,
		image: 'cake-excessive-icing',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Causes shrink', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Too much sugar',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Too heavy',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Won't fit in the container`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `It's uneven`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Exactly, always follow the instructions.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-2-spot-errors-prevent-icing-shrink',
		text: `Help prevent icing shrink by scooping out this icing bucket.`,
		layout: 'bakery-2-prevent-icing-shrink',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'clump1',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Saving every bit of icing is a huge savings.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'clump2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'clump3'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-store-extra-icing',
		text: `And store that extra where?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'In a bag with the current date',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'In a bag with the expiration date', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'In a bag',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Eat it`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `Ya, no one's looking.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `In the trash`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-2-spot-errors-stacked-cookies',
		text: `Customers love variety, but some of these cookies are not stacked properly. Which ones?`,
		layout: 'bakery-2-stacked-cookies',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'stack1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'stack2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'stack3'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-tables-with-cookies',
		subtype: 'images',
		layout: 'bakery-2-tables-with-cookies',
		text: `Which table is better?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // more chocolate chip cookies
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // less chocolate chip cookies
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-why-more-chocolate-chip-cookies',
		text: `Why this one?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '80/20 rule', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Looks better',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'More even',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `20/80 rule`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Chocolate chip!!!`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-80-20-which-law',
		text: `What law is that from?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Pareto's Law`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Martial Law',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'In-Law',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-80-sales-come-from',
		text: `80% of sales comes from your ...`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Top 20% items`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `And since chocolate chip cookies are the most popular kind, you'll want more of those.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Top 20 items',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Top 20 categories',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Top 20 songs by Eric Clapton',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '20 highest dollar items',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-20-percent-category',
		text: `Which items make up 20% of the category in the entire bakery department?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Breads and rolls`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Cakes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Cookies',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Muffins',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Donuts',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Never forget your staple items.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-shelf-life-cookies',
		text: `What's the shelf life of cookies?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `7 days`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '2 weeks',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '1 month',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '1 day',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '2 days',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-hot-bread-best-time',
		text: `When's the best time for hot bread?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `4pm to 7pm`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '8am to Noon',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Noon to 4pm',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '5pm to Close',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'All day',
			})
		]
	}),
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'bakery-2-dialogue-reach-cakes',
		characterId: 'old-man-with-cat-food',
		maxPoints: 12,
		introText: 'You are busy stocking as a customer approaches you.',
		introTextType: 'info',
		introCharacter: 'old-man flipped',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-22',
				text: `Excuse me, can you help me?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Yes, of course.`, nextStepId: 'bd-23',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `Be right there, just have to finish stocking.`, nextStepId: 'bd-24',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: `I'm kinda busy.`, nextStepId: 'bd-25'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-23',
				text: `I can't reach those cakes in the back.`,
				image: 'happy',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Let me get that for you.`, nextStepId: 'bd-28',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `I'll knock one to the front for you.`, nextStepId: 'bd-29',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: `You just have to stretch.`, nextStepId: 'bd-25',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-24',
				type: 'action',
				text: `You finish stocking and then ask the customer how you can help.`,
				nextStepId: 'bd-27',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-25',
				text: `Hmph!`,
				image: 'angry',
				nextStepId: 'bd-26',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-26',
				type: 'action',
				image: 'angry',
				text: `The customer leaves angry.`,
				nextStepId: 'bd-30',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-27',
				text: `I can't reach those cakes in the back.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Let me get that for you.`, nextStepId: 'bd-28',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `I'll knock one to the front for you.`, nextStepId: 'bd-29',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: `You just have to stretch.`, nextStepId: 'bd-25',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-28',
				type: 'action',
				text: `The customer leaves with the cakes. Now what?`,
				optionType: 'action',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Pull to front and rotate.`, nextStepId: 'bd-30',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `Pull all product to the front.`, nextStepId: 'bd-30',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: `Lunch`, nextStepId: 'bd-30',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-29',
				text: `Please don't damage it!`,
				nextStepId: 'bd-28',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-30',
				showPoints: true,
				isFinalStep: true,
				showAvatar: false,
				text: `Let's see if you remembered everything.`,
				type: 'action',
				checklistData: {
					title: 'Checklist',
					type: 'bakery-2',
					items: [
						{
							text: 'Greet customer',
							conditions: [{type: 'selected-or', options: [{stepId: 'bd-22', optionId: 1}]}]
						},
						{
							text: 'Exceed expectations',
							conditions: [{type: 'selected-or', options: [{stepId: 'bd-23', optionId: 1}, {stepId: 'bd-27', optionId: 1}]}]
						},
						{
							text: 'Rotate inventory',
							conditions: [{type: 'selected-or', options: [{stepId: 'bd-28', optionId: 1}]}]
						},
					]
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-when-set-displays',
		subtype: 'images',
		layout: 'bakery-2-when-set-displays',
		text: `When should all displays be set?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // 9am
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `By 9:00am at the latest.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 8am
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 7am
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // noon
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-getting-ready-to-leave',
		text: `When getting ready to leave...`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Clean it`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Close it', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Cover it', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Call it',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Cook it',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `And while we are at it, cut it off.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-2-multiple-choice-not-prepped-for-next-day',
		subtype: 'images',
		layout: 'bakery-2-not-prepped-for-next-day',
		shuffleOptions: false,
		text: `What should NOT be prepped and ready for next day?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // pie
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // breads
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // donuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // rolls
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Another good day. Thanks for helping.`,
		characterItems: ['donut-hat'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['donut-hat'],
					text: 'A Donut Hat has been added to your avatar.'
				})
			}),
		]
	}),
];


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'bak2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};