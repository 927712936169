import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {checkIfModuleIsCompleted} from 'helpers/module-helper';
import {loginUiTexts, gameUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Character from 'components/ui/character/character';
import Button from 'components/ui/button/button';
import './splash.scss';

const Splash = ({deviceInfo, gameData, userData, playerData, handleGoToPage, resetPlayerGameData, handleLogout}) => {
	/**
	 * Start playing
	 */
	const startPlaying = () => {
		/* Check if intro module is completed */
		const playerIntroModuleData = (playerData && playerData.modules 
			? playerData.modules.find((module) => {return module.moduleId === 'intro';})
			: []
		);
		const introIsCompleted = checkIfModuleIsCompleted(playerIntroModuleData);


		if (introIsCompleted) {
			/* Returning player, intro module completed, go to map areas page */
			handleGoToPage('areas');
		} else {
			/* New player, go to intro module */
			handleGoToPage('module', 'intro');
		}
	};

	const managerId = (gameData && gameData.managerId ? gameData.managerId : 'a');
	
	return (
		<div className={'Splash ' + deviceInfo.orientation}>
			<div className="Splash-logo"><Logo /></div>
			<div className="Splash-manager">
				<Character page="splash" characterId={'manager-1'} managerId={managerId} />
			</div>
			<div className="Splash-startBtn">
				<Button classes={['login']} text={gameUiTexts.start} onClick={() => {startPlaying();}} />
			</div>
			<div className="Splash-loginInfo"><span>{userData && userData.email}</span></div>
			<div className="Splash-logoutBtn">
				<Button classes={['game-logout3']} text={loginUiTexts.logout} onClick={() => {handleLogout();}} />
			</div>
			{(appConfig.env !== 'production' || userData.role === 'admin') && 
				<div className="Splash-resetBtn">
					<Button classes={['reset']} text={gameUiTexts.reset} onClick={() => {resetPlayerGameData();}} />
				</div>
			}
		</div>
	);
};

Splash.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Splash;
