import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin-remove-facilitator-popup.scss';

const AdminRemoveFacilitatorPopup = (props) => {
	const {
		gameId,
		email, 
		usersData, 
		toggleRemoveFacilitatorPopup, 
		removeFacilitatorFromGame
	} = props;


	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Get facilitator user data */
	const facilitatorData = usersData.find((user) => {return (user.email === email);});

	/* Popup title & text */
	const title = adminUiTexts.removeFacilitatorFromGamePopup.title;
	const text = JSON.parse(JSON.stringify(adminUiTexts.removeFacilitatorFromGamePopup.text))
		.replace('%name%', (facilitatorData ? facilitatorData.name : email ));


	/**
	 * Handle add facilitator
	 */
	const handleRemoveFacilitator = () => {
		if (isLoading) return; 

		setErrorMsg(null);
		setSuccessMsg(null);
		setIsLoading(true);
		
		removeFacilitatorFromGame(gameId, email).then((response) => {
			if (response.status === 'success') {
				toggleRemoveFacilitatorPopup();
			} else {
				console.error(response);
			}
		});

	};


	return (
		<div className="AdminRemoveFacilitatorPopup" onClick={() => {toggleRemoveFacilitatorPopup();}}>
			<div className="AdminRemoveFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminRemoveFacilitatorPopup-header">
					<div className="AdminRemoveFacilitatorPopup-title">{title}</div>
					<div 
						className="AdminRemoveFacilitatorPopup-closeBtn" 
						onClick={() => {toggleRemoveFacilitatorPopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminRemoveFacilitatorPopup-body">
					<div className="AdminRemoveFacilitatorPopup-text">{renderMarkdown(text)}</div>
					<div className={'AdminRemoveFacilitatorPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
					<div className="AdminRemoveFacilitatorPopup-buttons">
						<div className="AdminRemoveFacilitatorPopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['blue', 'adminPopup', 'wide']}
								text={adminUiTexts.removeFacilitatorFromGamePopup.confirmBtn}
								onClick={() => {handleRemoveFacilitator();}}
							/>
						</div>
						<div className="AdminRemoveFacilitatorPopup-cancelBtn">
							<Button 
								classes={['blue', 'adminPopup', 'wide']}
								text={ adminUiTexts.removeFacilitatorFromGamePopup.cancelBtn}
								onClick={() => {toggleRemoveFacilitatorPopup();}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminRemoveFacilitatorPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	usersData: PropTypes.array.isRequired,
	toggleRemoveFacilitatorPopup: PropTypes.func.isRequired,
	removeFacilitatorFromGame: PropTypes.func.isRequired,
};

export default AdminRemoveFacilitatorPopup;
