const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Welcome to the Seafood 2!`,
		background: 'ocean-island',
		animateCharacter: true,
		extraCharacters: [{id: 'giant-sea-creature', type: 'middle'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's start with some COOL labeling.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-cool-meaning',
		text: 'What does COOL tell us?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `The country the product was grown`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `The expiration`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `The native habitat`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `The temperature of the package`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'seafood-2-sort-cool-label',
		text: `With Seafood, what must be labeled with COOL?`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'COOL'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Not COOL'}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 1, text: 'Fresh fish filet', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 2, text: 'Frozen fish', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 3, text: 'Lobster', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 4, text: 'Crab', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 5, text: 'Oyster', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 6, text: 'Canned tuna', categoryIds: ['2']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 7, text: 'Smoked salmon', categoryIds: ['2']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 8, text: 'Breaded shrimp', categoryIds: ['2']}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-country-match',
		text: 'The country should match ...',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `The shipping case`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `The invoice`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `The weather`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `The order`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `On to production planning ...`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-patterns',
		text: 'With seafood, you can ignore buying patterns.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `False`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `True`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-major-holiday',
		text: 'What major holiday would account for a larger increase in Seafood sales?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Christmas',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Thanksgiving',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Lent', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, many people eat seafood during Lent.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Easter',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Memorial Day',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Superbowl',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-start-end',
		text: 'When does it start and end?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Starts February and ends March',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Starts March and ends April',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Is determined by Easter', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `To be precise it's always the 40 days before Easter. Use a calendar to get the exact days.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Only happens every 4 years',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '30 days before Christmas',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-why-buy-seafood',
		text: 'Why else do customers buy seafood?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Save time and money', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Be healthier', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Try something new', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Bored',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Possibly, but probably not seafood.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Broke',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-plan-how-far',
		subtype: 'images',
		layout: 'seafood-2-plan-how-far',
		text: 'How far out do we prepare with seafood? Today is Tuesday.',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // tuesday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // wednesday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // thursday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // friday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // saturday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // sunday
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'feedback',
				feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
					text: `Only 1 or 2 days worth of sales.`,
				})
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-best-friend-when-preparing',
		text: `What is seafood's best friend when preparing?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Ice', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. Keep it cold.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Smell'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Time'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Color',				
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's do a deep dive on getting the service case ready.`
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'seafood-2-order-reset-service-case',
		text: 'What are the steps for resetting the service case in the morning? Click "Done" when you think you have it.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Remove old product', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Break up and remove old ice', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Clean pans, trays, and signage', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Place sanitized equipment back into case', correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Stock case with product, garnish, and ice', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-reuse-garnish',
		text: 'When resetting, can you reuse the garnish instead of replacing it?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes, as long as it looks fresh',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No, always use fresh garnish', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'You can only reuse lemons',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-seafood-temperature',
		text: 'What is the maximum safe temperature for fresh seafood?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: '32'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: '35'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: '40', isCorrect: true,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: '0'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: '30'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Now, we use lots of ice in Seafood. So grab a bucket and fill it with ice.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-important-picking-bucket',
		text: `What's most important when picking a bucket for ice?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'That it has a handle',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'If you can pick it up safely, it doesn\'t need a handle.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `That it's clean`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. The ice will be touching raw Seafood, so the bucket has to be very clean.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `That it's not too big`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `That it's blue`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'seafood-2-order-shelf-life',
		text: 'Arrange these items by shelf life. Shortest shelf life at the top.',
		image: 'calendar2b',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Fresh fish steaks/fillet', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Scallops', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Frozen fillet', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Canned tuna', correctOrderNumbers: [4],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Fresh fish and shellfish have a short shelf life of only 3 to 5 days, shellfish lasting a little longer.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-date-mark-down',
		text: 'If the date gets a little close, can you mark down seafood?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You can. But customers are often concerned with reduced seafood over other foods.',
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Now the service case is set, let\'s move to the self service case.'
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-2-spot-errors-service-case-1',
		text: 'Spot the issues. Click "Done" when you think you have them all.',
		layout: 'seafood-2-self-service-case1',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'melted-ice',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'smelly-one',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'label-left',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'discolored-fillet',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'leaking-package',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'salmon-as-crab',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tiny-fish-big-tray',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-service-case',
		subtype: 'images',
		text: `Which of these is shingled correctly?`,
		shuffleOptions: false,
		layout: 'seafood-2-service-case-shingle',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'column 1'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'column 2', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'column 3'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-touch-ice',
		text: 'Which items cannot touch ice directly?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Fillets', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Steaks', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Crab legs',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Oysters',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Whole fish',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's take a closer look at the lobster tank and how to purge the lobsters.`
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'seafood-2-order-purging-lobsters',
		text: 'Put the steps for purging lobsters in the right order. Click "Done" when you think you have it.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Place lobsters in seperate container', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Cover lobsters with water', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Check PH of tank', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Pre-condition additional water', correctOrderNumbers: [4],
			}), 
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Replace 10% to 20% of tank water', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-purging-lobsters-important',
		text: `What's especially important when purging lobsters?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Lobsters live in fresh water',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Lobsters live in salt water', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Water transferring increases spill hazards', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly, we are moving a fair amount of water around. There could be safety concerns.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Lobsters can very rarely be blue instead of red',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This is true, but not important here.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Purging the lobsters really helps increase the health of the tank and the lobsters.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-bad-for-seafood',
		text: `Beside temperature, what else is bad for seafood?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Water loss', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Sharks',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Time of year'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Open coffin cases',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-prevent-water-loss',
		text: `To prevent excess water loss, thaw seafood under warm water.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'False', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No, that starts the breakdown process.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'True',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-2-multiple-choice-do-next',
		text: `You didn't do too crabby. What more do you need to do?`,
		image: 'wizard3',
		isDilemma: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Send the fish to a school', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Nice!',
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Release the Kraken!',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'No!!! It is free!',
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'special-points',
						specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
							id: 'seafood-2-released-the-kraken', pointId: 'released-the-kraken', value: 1
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Wave goodbye', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Not great, but okay.',
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Dolphinitely go easy on the fish puns', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `A dolphin's not a fish. It makes no sense. `,
						})
					}),
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `That's some great work today.`,
		characterPose: 1,
		characterItems: ['lobster-hat'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['lobster-hat'],
					text: 'A Lobster Hat has been added to your avatar.'
				})
			}),
		]
	}),	
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'sea2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};