const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		animateCharacter: true,
		background: 'ocean-raft',
		showAvatar: true,
		text: `Welcome to the 5-S island.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-5-s',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: `Do you know what 5-S stands for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Perfect. Let\'s start.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'It\'s Sort, Straighten, Shine, Standardize, Sustain'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-5s-help',
		text: `What does 5-S help do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Eliminate Waste',
				isCorrect: true,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Streamline Production',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Optimize',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Prevent out of stocks',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'In helps, but there is no way to stop all OOS.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Create more work',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Actually it helps save labor.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-five-steps',
		image: 'checklist5',
		text: 'How do we accomplish this? Put the five steps in the correct order.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Sort', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Straighten', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Shine', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Standardize', correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Sustain', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-excess-inventory',
		text: `Which step helps us look at excess inventory?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: true,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-everythings-place',
		text: `What finds a place for everything?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: false,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-areas',
		text: `What areas do we need include?`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Inventory',
				isCorrect: true,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Tools',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Computer files',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Equipment',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Paper records/reports',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		showAvatar: true,
		text: `Pretty much every element both physical and digital.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-motions-of-stocking',
		text: `Do we take movement into account when stocking for 5s?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes. Every movement adds up.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Do what now?',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-keep-shelves-clean',
		text: `Which makes sure we keep the shelves clean?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-steps-creating-steps',
		text: `Which step creates steps to follow each time?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-overstock',
		text: `Which requires us to overstock?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: 'None of the above',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-daily-culture',
		text: `Which makes this part of daily culture?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Sort',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Straighten',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Shine',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Standardize',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Sustain',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'backroom-5-s-organize-organize-shelves',
		layout: 'backroom-5-s-organize-shelves',
		text: `Organize these shelves.`,
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'spray-cleaner', alt: 'Spray cleaner', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'gloves', alt: 'Gloves', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'buckets', alt: 'Buckets', correctContainerIds: ['a5']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'paper-towels', alt: 'Paper towels', correctContainerIds: ['a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'cleaner-jugs', alt: 'Cleaner jugs', correctContainerIds: ['a2']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'shelves',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves', finalItemId: 3}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
				]
			})
		],
		doneConditions: [
			{type: 'empty-main-container', mainContainerId: 'initial'}
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: '5s-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'backroom-5-s-organize-organize-files',
		layout: 'backroom-5-s-organize-files',
		text: `Organize these files.`,
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'i1', image: 'order-history', alt: 'Order history', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'i2', image: 'sanitation-schedules', alt: 'Sanitation schedules', correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'i3', image: 'invoice', alt: 'Invoice', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'i4', image: 'holiday-ads', alt: 'Holiday Ads', correctContainerIds: ['a4']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'folders',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'final', finalItemId: 4}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'i1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'i2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'i3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'i4', type: 'initial'}),
				]
			})
		],
		doneConditions: [
			{type: 'empty-main-container', mainContainerId: 'initial'}
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: '5s-1-problem-solver-2', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		showAvatar: true,
		text: `Let's talk more advanced 5-S Culture.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-file-system',
		text: `How should we organize our file system?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: '1 year rotation',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: '1 month rotation',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: '1 week rotation',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: '7 years rotation',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-why',
		text: `Why?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Helps for planning',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. We can see what we did last year.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Taxes',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Required by law',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-why-helpful',
		text: `Why is this information helpful?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'See what worked',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'See what didn\'t',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Fun to recycle',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Forces 5-S culture',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-sales-flyers',
		text: `Would we include sales flyers?`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-departmental-notes',
		text: `What about departmental notes?`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-throw-out-schedules',
		text: `Ok to throw out schedules though.`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-this-order-history',
		text: `How about this order history?`,
		image: 'order-history',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Keep',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes and notes on how well it moved.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Recycle',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-holidays',
		text: `Holidays are always the same.`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'No',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right. Items and dates change year to year.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Yes',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-daily-tracking',
		text: `What would help us keep track daily?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Information Board',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Phone reminders',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Not everyone has access.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Notes',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'But where to put them?'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Filing cabinet',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Too hard to reference.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'backroom-5-s-multiple-choice-clipboards',
		subtype: 'images',
		layout: 'backroom-5-s-clipboards',
		text: 'How does this look? Remove the ones that don\'t belong.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // sales-flyer
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // shortages
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // sanitation-log
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // closing-notes
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // zombie sitings
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // birthdays
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // magic spells
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // timesheet
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 9, // christmas carols
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		text: `That's a great looking board.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		background: 'ocean-raft',
		text: `Remember to check it and keep it updated.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['shirt-m-13', 'shirt-f-13'],
					text: 'A shirt has been added to your avatar.'
				})
			}),
		]
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = '5s1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};