import {
	checkIfModuleSessionIsCompleted,
	getAverageTimeSpent,
	getAverageModuleTaskMistakes
} from './module-helper';

export function getPlayerStatsOfModules(modulesData, modulesPlayersData) {

	const playerModuleStats = [];

	modulesData.forEach((moduleData, index) => {
		const modulePlayersData = modulesPlayersData[index];
		const startedBy = modulePlayersData.length;
		
		let completedData = modulePlayersData.filter((modulePlayer) => {
			const modulePlayerSessionData = (
				modulePlayer && 
				modulePlayer.sessions && 
				modulePlayer.sessions.length > 0
			) ? modulePlayer.sessions[0] : null;
			return checkIfModuleSessionIsCompleted(modulePlayerSessionData);
		}) ;

		let avgTimeSpent = 0;
		let avgMistakes = 0;
		if (modulePlayersData.length > 0) {
			avgTimeSpent = getAverageTimeSpent(modulePlayersData);
			avgMistakes = getAverageModuleTaskMistakes(modulePlayersData);
		};


		playerModuleStats.push({
			index: index,
			moduleId: moduleData.id,
			title: moduleData.title,
			startedBy: startedBy,
			completedBy: completedData.length,
			avgTimeSpent: avgTimeSpent,
			avgMistakes: avgMistakes,
			moduleData: moduleData,
			modulePlayersData: modulePlayersData
		});
	});

	return playerModuleStats;
	
}