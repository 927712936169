const moduleCustomerService2 = [
	
	'modules/tasks/multiple-choice/customer-service-2-collect-carts/option-1.svg',
	'modules/tasks/multiple-choice/customer-service-2-collect-carts/option-2.svg',
	'modules/tasks/multiple-choice/customer-service-2-collect-carts/option-3.svg',
	'modules/tasks/multiple-choice/customer-service-2-collect-carts/option-4.svg',

	'modules/tasks/spot-errors/customer-service-2-generic-employee.svg',
	'modules/tasks/spot-errors/customer-service-2-generic-employee-2.svg',
	'modules/tasks/spot-errors/customer-service-2-outside-collecting.svg'
];

export {
	moduleCustomerService2
};
