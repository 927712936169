import React from 'react';
import PropTypes from 'prop-types';
import {profileUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules/modules-data';
import {getPlayerModuleData, checkIfModuleIsUnlocked} from 'helpers/module-helper';
import './profile-progress.scss';

const ProfileProgress = ({playerData, userData}) => {
	return (
		<div className="ProfileProgress">
			<div className="ProfileProgress-title">{profileUiTexts.progress}</div>
			<div className="ProfileProgress-modules">
				{modulesData.map((moduleData) => {
					if (moduleData.id === 'intro') return null;
					const playerModuleData = getPlayerModuleData(moduleData.id, playerData, null);
					const maxStars = (playerModuleData && playerModuleData.maxStars ? playerModuleData.maxStars : '-');
					const maxPoints = (playerModuleData && playerModuleData.maxPoints 
						? playerModuleData.maxPoints : '-');
					const isStarted = (userData.role === 'admin'
						? true 
						: playerModuleData ? true : false
					);
					const isUnlocked = (userData.role === 'admin'
						? true
						: checkIfModuleIsUnlocked(moduleData, playerData)
					);
					return (
						<div 
							key={moduleData.id} 
							className={'ProfileProgress-module' + (isStarted ? ' started' : '') 
								+ (isUnlocked ? '' : ' locked')}
						>
							<div className="ProfileProgress-moduleHeader">
								<span>{moduleData.title}</span>
							</div>
							<div className="ProfileProgress-moduleBody">
								<div className="ProfileProgress-moduleRow">
									<span>{profileUiTexts.stars}:</span><span>{maxStars}</span>
								</div>
								<div className="ProfileProgress-moduleRow">
									<span>{profileUiTexts.points}:</span><span>{maxPoints}</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ProfileProgress.propTypes = {
	playerData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

export default ProfileProgress;
