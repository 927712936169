import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin-facilitator-popup.scss';

const AdminFacilitatorPopup = (props) => {
	const {facilitatorId, gamesData, usersData, handleSelectFacilitator, toggleDeleteFacilitatorPopup} = props;

	/* Get facilitator data */
	const facilitatorData = usersData.find((user) => {return user.id === facilitatorId;});

	/* Get all games the facilitator is connected to */
	let facilitatorGames = [];
	if (facilitatorData) {
		facilitatorGames = gamesData.filter((game) => {
			return (game.facilitatorEmails && game.facilitatorEmails.indexOf(facilitatorData.email) >= 0);
		});
	}

	return (
		<div className="AdminFacilitatorPopup" onClick={() => {handleSelectFacilitator();}}>
			<div className="AdminFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminFacilitatorPopup-header">
					<div className="AdminFacilitatorPopup-title">{facilitatorData ? facilitatorData.name : '-'}</div>
					<div 
						className="AdminFacilitatorPopup-closeBtn" 
						onClick={() => {handleSelectFacilitator();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminFacilitatorPopup-body">
					<div className="AdminFacilitatorPopup-section">
						<div className="AdminFacilitatorPopup-sectionTitle">{adminUiTexts.connectedStoreMulti}:</div>
						<div className="AdminFacilitatorPopup-sectionText">
							{facilitatorGames.map((game) => {
								return <p key={game.id}>{game.storeName}</p>;
							})}
						</div>
					</div>
					<div className="AdminFacilitatorPopup-section">
						<div className="AdminFacilitatorPopup-sectionTitle">{adminUiTexts.email}:</div>
						<div className="AdminFacilitatorPopup-sectionText">
							<p>{(facilitatorData && facilitatorData.email ? facilitatorData.email : '-')}</p>
						</div>
					</div>
					<div className="AdminFacilitatorPopup-deleteBtn">
						<Button 
							classes={['deleteGame']} 
							text={adminUiTexts.deleteFacilitator}
							onClick={() => {toggleDeleteFacilitatorPopup(true);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

AdminFacilitatorPopup.propTypes = {
	facilitatorId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	handleSelectFacilitator: PropTypes.func.isRequired,
	toggleDeleteFacilitatorPopup: PropTypes.func.isRequired,
};

export default AdminFacilitatorPopup;
