import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({isLoading, isDisabled, isChecked, openInNewTab, type, text, url, classes, onClick}) => {
	/**
	 * Handle button click
	 * @param {object} event 
	 */
	const handleClick = (event) => {
		event.preventDefault();
		if (isDisabled || !onClick) return;
		onClick(event);		
	};

	/* Classes */
	let className = 'Button';
	if (isLoading) {className += ' loading';}
	if (isDisabled) {className += ' disabled';}
	if (classes && classes.length > 0) {
		classes.forEach((c) => {className = className + ' ' + c;});
	}

	if (type === 'link' && url) {
		className = className + ' link';
		return (
			<a href={url} target={(openInNewTab ? '_blank' : '_self')} rel="noreferrer" className={className}>
				{text && <span>{text}</span>}
			</a>
		);
	}

	if (onClick) {
		if (type === 'switch') {
			className = className + ' switch';
			if (isChecked) className = className + ' checked';
			return (
				<div className={className} onClick={handleClick}>
					<span></span>
					<input type="checkbox" />
				</div>
			);
		}
		
		return (
			<div className={className} onClick={handleClick}>
				{text && <span>{text}</span>}
			</div>
		);
	}

	return null;	

};


Button.defaultProps = {
	isLoading: false,
	isDisabled: false,
	isChecked: false,
	openInNewTab: false,
	classes: null
};

Button.propTypes = {
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isChecked: PropTypes.bool,
	openInNewTab: PropTypes.bool,
	type: PropTypes.string,
	text: PropTypes.string,
	url: PropTypes.string,
	classes: PropTypes.array,
	onClick: PropTypes.func
};


export default Button;