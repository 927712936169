import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { yearbookAllQuestions } from 'data/yearbook-data';
import { yearBookUiTexts } from 'data/ui-texts';
import Button from '../button/button';
import './popup-choose-questions.scss';

const PopupChooseQuestions = ({closePopup, setYearbookData, yearbookData}) => {

	/**
	 * keep track of already selected questions
	 */
	const selectedQuestions = Object.keys(yearbookData).filter((question)=>{return question !== 'badgeIds';});
	const [yearBookquestions, setYearBookquestions] = useState([]);

	const [showFeedback, setShowFeedback] = useState(false);

	useEffect(() => {
		setYearBookquestions(selectedQuestions);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * shows the feeedback texts
	 */
	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowFeedback(false);
		}, 3000);
		
		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};

	}, [showFeedback]);

	// Update yearbookData with written input
	const handleUpdateYearbookQuestions = (newQuestion) => {
		// remove already selected question
		if (yearBookquestions.includes(newQuestion)) {
			setYearBookquestions(yearBookquestions.filter((question)=>{return question !== newQuestion;}));
			return;
		}
		// do nothing if three question are seleceted
		if (yearBookquestions.length >= appConfig.questionsAndBadgesInYearbook) {
			return;
		};
		
		// update badge
		setYearBookquestions([...yearBookquestions, newQuestion]);
	};

	// Save yearbookData with written input
	const handleSaveYearbookQuestions = () => {
		// do nothing if three question are seleceted
		if (yearBookquestions.length < appConfig.questionsAndBadgesInYearbook) {
			setShowFeedback(true);
			return;
		};

		// update new questions while keep old
		const newYearbookData = {};
		yearBookquestions.forEach((data) => {
			if (yearbookData[data]) {
				newYearbookData[data] = yearbookData[data];
			} else {
				newYearbookData[data] = '';
			}
		});
		newYearbookData['badgeIds'] = yearbookData['badgeIds'];
		setYearbookData(newYearbookData);
		closePopup(false);
	};
	
	return (
		<div className='PopupChooseQuestions' onClick={(e) => {e.stopPropagation(); closePopup(false);}}>
			<div className='PopupChooseQuestions-content' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupChooseQuestions-title'><span>{yearBookUiTexts.chooseQuestions}</span></div>
				<div className='PopupChooseQuestions-itemWrapper'>
					{yearbookAllQuestions.map((question, index) => {
						return (
							<div className={'PopupChooseQuestions-item ' + 
						(yearBookquestions.includes(question.id) ? 'selected' : '')}
							key={index} 
							onClick={() => {handleUpdateYearbookQuestions(question.id);}}>
								<span>{question.title}</span>
							</div>
						);	
					})}
				
					<div className='PopupChooseQuestions-feedbackWrapper'> 

						<div className={'PopupChooseQuestions-feedback ' + (showFeedback ? 'show' : '' )}>
							<span>{yearBookUiTexts.feedback}</span>
						</div>
					</div>
				</div>

				<div className='PopupChooseQuestions-buttonsWrapper'>
					<Button
						classes={['blueDark']}
						text={yearBookUiTexts.cancel}
						onClick={() => {closePopup(false);}}
					/>
					<Button
						classes={['blueDark']}
						text={yearBookUiTexts.save}
						onClick={() => {handleSaveYearbookQuestions();}}
					/>
					
				</div>
					
			</div>

			

		</div>
	);
};

PopupChooseQuestions.propTypes = {
	closePopup: PropTypes.func.isRequired,
	setYearbookData: PropTypes.func.isRequired,
	yearbookData: PropTypes.object.isRequired,
};

export default PopupChooseQuestions;