import React from 'react';
import { createRoot } from 'react-dom/client';
import {PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from 'config/auth.config';
import App from 'components/app';
import './index.scss';

export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App msalInstance={msalInstance}/>);
