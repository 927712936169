import React from 'react';
import ReactExport from 'react-export-excel';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { facilitatorUiTexts } from 'data/ui-texts';
import {
	getPerfectScorePercentage,
	getAverageTaskMistakes,
	getAverageTimeSpent, 
	getAverageModuleTaskMistakes,
	checkIfModuleSessionIsCompleted
} from 'helpers/module-helper';
import Button from 'components/ui/button/button';

const DownloadStatistics = (props) => {
	const {
		modulePlayerData,
		moduleData,
		type,
		taskData,
		modulesData,
		modulesPlayersData,
	} = props;

	/**
	 * Builds excel sheet object for tasks overview download.
	 * @returns Object
	 */
	const buildTasksSheet = () => {
		const columns = [
			{value: 'task', label: facilitatorUiTexts.task},
			{value: 'avgMistakes', label: facilitatorUiTexts.avgMistakes},
			{value: 'perfScores', label: facilitatorUiTexts.perfScores},
		];

		const sheet = {id: moduleData.id, name: moduleData.title, columns: columns, data: []};

		taskData.forEach((task) => {
			const avgTaskMistake = getAverageTaskMistakes(task, modulePlayerData);
			const perfScore = getPerfectScorePercentage(task, modulePlayerData);
			let sheetData = {task: task.id, avgMistakes: avgTaskMistake, perfScores: perfScore};
			sheet.data.push(sheetData);
		});

		return sheet;
	};

	/**
	 * Builds excel sheet object for modules overview download.
	 * @returns Object
	 */
	const buildModulesSheet = () => {
		const columns = [
			{value: 'module', label: facilitatorUiTexts.module},
			{value: 'startedBy', label: facilitatorUiTexts.startedBy},
			{value: 'completedBy', label: facilitatorUiTexts.completedBy},
			{value: 'avgTime', label: facilitatorUiTexts.avgTimeSpent},
			{value: 'avgMistakes', label: facilitatorUiTexts.avgMistakesTask},
		];

		const sheet = {id: 'modules', name: 'Modules', columns: columns, data: []};

		modulesData.forEach((module, index) => {
			const modulePlayerData = modulesPlayersData[index];

			const startedBy = modulePlayerData ? modulePlayerData.length : 0;
			let completedData = (modulePlayerData 
				? modulePlayerData.filter((modulePlayer) => {
					const modulePlayerSessionData = (
						modulePlayer && 
						modulePlayer.sessions && 
						modulePlayer.sessions.length > 0
					) ? modulePlayer.sessions[0] : null;
					return checkIfModuleSessionIsCompleted(modulePlayerSessionData);
				}) 
				: []
			);
			
			if (!completedData) completedData = [];

			let avgTimeSpent = 0;
			let avgMistakes = 0;
			if (modulePlayerData) {
				avgTimeSpent = getAverageTimeSpent(modulePlayerData);
				avgMistakes = getAverageModuleTaskMistakes(modulePlayerData);
			};

			let sheetData = {
				module: module.id, 
				startedBy: startedBy, 
				completedBy: completedData.length, 
				avgTime: avgTimeSpent, 
				avgMistakes: avgMistakes};
			sheet.data.push(sheetData);
		});

		return sheet;
	};
	
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	let sheet = null;
	let fileName = '';
	if (type === 'tasks') {
		fileName = 'Module-' + moduleData.title + '-' + dayjs(new Date()).format('YYYY-MM-DD');
		sheet = buildTasksSheet();
	} else if (type === 'modules') {
		fileName = 'Modules-' + dayjs(new Date()).format('YYYY-MM-DD');
		sheet = buildModulesSheet();
	}

	return (
		<ExcelFile 
			filename={fileName}
			element={<Button
				classes={['download']}
				text={facilitatorUiTexts.downloadXls}
				onClick={() => {}}/>}>
			{
				<ExcelSheet data={sheet.data} name={sheet.name}>
					{sheet.columns.map((column, index) => {
						return <ExcelColumn key={index} label={column.label} value={column.value} />;
					})}
				</ExcelSheet>
			}
		</ExcelFile>
	);

};

DownloadStatistics.propTypes = {
	type: PropTypes.string.isRequired,
	moduleData: PropTypes.object,
	modulePlayerData: PropTypes.array,
	taskData: PropTypes.array,
	modulesData: PropTypes.array,
	modulesPlayersData: PropTypes.array,
};

export default DownloadStatistics;
