const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		animateCharacter: true,
		text: `Welcome back. I see you completed a little side journey.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-learned-about-checklist',
		subtype: 'images',
		layout: 'center-store-2-learned-about-checklist',
		text: 'Check off what you learned about.',
		showNumberOfAnswersToSelect: false,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // cleaning
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // 5-s
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // safety
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // food safety
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true, // Inventory Managment
			}),
			
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Wow, you have been busy.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `We have a few more to cover here.`,
		layout: 'center-store-2-checklist',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		subtype: 'images',
		taskId: 'center-store-2-mc-tool-belt-items',
		layout: 'center-store-2-tool-belt-items',
		text: 'Better get your tool belt ready.',
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // box cutter
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // tape
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // spray cleaner
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // rag
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // wizard wand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // wizard hat
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // frog
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // unicycle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 9, // milk
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Can't cast spells without a wand.`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `You know we don't pay you right?`,
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `And I'm out...`,
		speechBubbleDirection: 'right',
		fadeoutSpeachBubble: true,
		extraCharacters: [{id: 'wizard', position: 'right', type: 'animate-poof'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `We need to stock aisle 4.`,
		items: ['u-boat-boxes']
	}),
	/* BRANCHING STORY START */
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'center-store-2-branching-story-stock-aisle-4',
		maxPoints: 8,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-02',
				text: `How do you want to stock?`,
				image: 'u-boat-boxes',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'From U-boat', nextStepId: 'cb-06',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {text: `Good idea, work as we go.`})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Set boxes on floor at tag`, nextStepId: 'cb-04',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-04',
				textType: 'info',
				text: `You drive down and set them out.`,
				nextStepId: 'cb-05',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-05',
				text: `A customer almost falls over the boxes. What do you say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Sorry, let me remove those`, nextStepId: 'cb-06',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Sorry, but we are stocking`, nextStepId: 'cb-06',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-06',
				text: `After opening the box, where do you put the cardboard?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `I'll get a cart`, nextStepId: 'cb-11',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {text: `We can stack our cardboard in here.`})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `I'll stack it on the U-boat.`, nextStepId: 'cb-07',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `I'll break it down and place against shelf.`, nextStepId: 'cb-08',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-07',
				text: `Now the cardboard gets in the way.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `I'll go get a cart. `, nextStepId: 'cb-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {text: `We can stack our cardboard in here.`})
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-08',
				text: `A customer complains that they can't reach the cans on the shelf now. What do you say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `I'm sorry, let me move this box.`, nextStepId: 'cb-10',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `My apologies, we'll be done soon.`, nextStepId: 'cb-11',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-10',
				textType: 'info',
				text: `You get a card instead to stack the cardboard in.`,
				nextStepId: 'cb-11'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-11',
				text: `These cans easily stack. How high can we go on top?`,
				image: 'cans',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `1 level only`, nextStepId: 'cb-14',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `2 like the shelves below`, nextStepId: 'cb-12',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `3 for more space`, nextStepId: 'cb-12',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-12',
				textType: 'info',
				text: `Later you spot a customer knocking over some cans and almost hitting a person in the other aisle.`,
				nextStepId: 'cb-13',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-13',
				textType: 'info',
				text: `Ow! My face!`,
				image: 'wizard-black-eye',
				nextStepId: 'cb-16',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-14',
				textType: 'info',
				text: `These go on top shelf.`,
				nextStepId: 'cb-16',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistTemplate, {
				id: 'cb-16',
				text: `Let's see how you did.`,
				checklistData: {
					type: 'center-store-2 branching-story',
					items: [
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Kept cases off floor`,
							conditions: [{type: 'selected-or', options: [{stepId: 'cb-02', optionId: 1}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Didn't block customers`,
							conditions: [{type: 'selected-or', options: [{stepId: 'cb-06', optionId: 1}, {stepId: 'cb-06', optionId: 2}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Consolidated cardboard`,
							conditions: [{type: 'selected-or', options: [{stepId: 'cb-06', optionId: 1}, {stepId: 'cb-07', optionId: 1}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `High inventory stacked low`,
							conditions: [{type: 'selected-or', options: [{stepId: 'cb-11', optionId: 1}]}]
						}),				
					]
				}
			})
			
		]
	}),
	/* BRANCHING STORY END */
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's stack some bag cereals.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-bag-cereals',
		subtype: 'images',
		layout: 'center-store-2-bag-cereals',
		text: 'How should it look?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // long ways, side out
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // long ways, bottom out
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,  // upright
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'These will fall straight into the floor.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-item-sells-out-fast',
		text: 'If an items sells out fast, what do we do?',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Add more facings`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'More product available.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Order more`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Order to your needs.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Make an endcap`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Maybe to keep easy access.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Stock more often`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes. Do not let it run out.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `None of the above`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-why-item-sell-fast',
		text: 'Why might an item sell fast?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `On Ad`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Holiday`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'And there are so many.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Weather`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Storms defnitely can cause this.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `SuperBowl Commerical`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Hunger`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'center-store-2-order-most-planning',
		text: `Which usually have the most planning?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Holiday', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Ad', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Weather', correctOrderNumbers: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-make-bad-weather',
		text: `And what do people make in bad weather?`,
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Milk sandwiches`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Yum yum yes.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Grilled cheese`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Soup`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Peanut butter crackers`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `He's right. Milk and Bread sell out in bad weather.`,
		characterItems: ['shirt-milk-sandwich'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['shirt-m-milk-sandwich', 'shirt-f-milk-sandwich'],
					text: 'A Milk Sandwich Shirt has been added to your avatar.'
				})
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's fix check some tags.`,
		items: ['shelf-with-cans']
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		showCharacter: false,
		extraCharacters: [{id: 'wizard-karate', position: 'right', type: 'animate-chop'}],
		items: ['shelf-with-cans']
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		showCharacter: false,
		background: 'center-store offset-floor',
		extraCharacters: [{id: 'wizard-karate', position: 'right'}],
		items: ['shelf-with-cans-tags-on-floor']
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'center-store-2-organize-shelf-tags',
		layout: 'center-store-2-shelf-tags',
		text: `*Sigh* Let's fix this.`,
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'onion', alt: 'Onion', correctContainerIds: ['c1', 'c2', 'c3', 'c4', 'c5', 'c6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'potatoes', alt: 'Potatoes', correctContainerIds: ['a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'corn', alt: 'Corn', correctContainerIds: ['a5']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'beets', alt: 'Beets', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'carrots', alt: 'Carrots', correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'asparagus', alt: 'Asparagus', correctContainerIds: ['c1', 'c2', 'c3', 'c4', 'c5', 'c6']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'final'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'center-store-2-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterItems: ['tags-broken']
	}),
	/* BRANCHING STORY START */
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'center-store-2-branching-story-new-tags',
		maxPoints: 4,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-21',
				text: `We need new tags. What do you do?`,
				image: 'tags-broken',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Go print some', nextStepId: 'cb-39',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4}),],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Handwrite them`, nextStepId: 'cb-22',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Tape them back together`, nextStepId: 'cb-27',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-22',
				text: `What do you need for that?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Pen, paper, and marker.', nextStepId: 'cb-23',
					}),				
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-23',
				textType: 'info',
				text: `You write some nice tags and put them on the shelves.`,
				nextStepId: 'cb-24',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-24',
				textType: 'info',
				text: `No. Try again.`,
				image: 'cb-24',
				nextStepId: 'cb-25',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-25',
				textType: 'info',
				images: [{type: 'character', characterId: 'wizard-karate branching-story', items: ['chop']}],
				nextStepId: 'cb-26',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-26',
				text: `What do you do?`,
				image: 'tags-broken',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Go print some tags', nextStepId: 'cb-39',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Tape them back together`, nextStepId: 'cb-34',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-27',
				textType: 'info',
				text: `You get some tape and fix them. Sort of. Then you put them on the shelves.`,
				nextStepId: 'cb-28',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-28',
				textType: 'info',
				text: `Nope. Not good enough. Try again.`,
				image: 'cb-28',
				nextStepId: 'cb-29',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-29',
				textType: 'info',
				images: [{type: 'character', characterId: 'wizard-karate branching-story', items: ['chop']}],
				nextStepId: 'cb-26',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-30',
				text: `What do you do?`,
				image: 'tags-broken',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Go print some tags', nextStepId: 'cb-39',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Handwrite them`, nextStepId: 'cb-31',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-31',
				textType: 'info',
				text: `You write some nice tags and put them on the shelves.`,
				nextStepId: 'cb-32',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-32',
				textType: 'info',
				text: `No. Try again.`,
				image: 'cb-32',
				nextStepId: 'cb-33',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-33',
				textType: 'info',
				images: [{type: 'character', characterId: 'wizard-karate branching-story', items: ['chop']}],
				nextStepId: 'cb-37',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-34',
				textType: 'info',
				text: `You get some tape and fix them. Sort of. Then you put them on the shelves.`,
				nextStepId: 'cb-35',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-35',
				textType: 'info',
				text: `Aw, that's nice.`,
				image: 'cb-35',
				nextStepId: 'cb-36',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-36',
				textType: 'info',
				text: `Yep. No handwritten tags.`,
				showManager: true,
				nextStepId: 'cb-37',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-37',
				textType: 'info',
				images: [{type: 'character', characterId: 'wizard-karate branching-story', items: ['chop']}],
				nextStepId: 'cb-38',
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cb-38',
				text: `What do you do?`,
				image: 'tags-broken',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Go print some tags', nextStepId: 'cb-39',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),],
					}),				
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cb-39',
				textType: 'info',
				text: `Exactly. No handwritten or badly fixed tags.`,
				showManager: true,
				image: 'manager',
			})
		]
	}),
	/* BRANCHING STORY END */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-cover-hole',
		text: `If we have hole do we cover it?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `No`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Yes`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-endcap-signs-size',
		text: `What size should endcap signs be the item?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `3 x 5`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `8.5 x 11`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `11 x 7`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `4 x 4`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's talk about endcaps again.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-endcap-product-position',
		subtype: 'images',
		layout: 'center-store-2-endcap-product-position',
		text: `We know to have signs on them, but how do they face?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // all production facing forward
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes. All facing forward.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // product on front faces out, product on side faces side
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-endcap-wings',
		text: `What are these called?`,
		image: 'endcap',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Wings`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Towers`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Mini-caps`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Side-caps`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-shelf-layout-follow',
		text: `And shelf layout follows what?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `POG`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `An order`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Price`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Commodity`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Temperature`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-pgo-stands-for',
		text: `What does it stand for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Planogram`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. This states where items go.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Plan, Order, Grouping`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Place on Gondola`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Piece, Organize, Group`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Sometimes we put Sale Pallets on the floor.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-pallet-max-height',
		subtype: 'images',
		layout: 'center-store-2-pallet-max-height',
		text: `What's the highest we can go?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // 5
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 6
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 4
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 3
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-pallet-min-height',
		subtype: 'images',
		layout: 'center-store-2-pallet-min-height',
		text: `What's the lowest before we need to product or restock?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 1
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-2-mc-customer-pallet-items',
		subtype: 'images',
		layout: 'center-store-2-customer-pallet-items',
		text: `How do we prefer our customer get pallet items?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // waist height
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // reaching up
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // reaching down
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Good work so far.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['karate-headband'],
		text: `Great job tiger.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['karate-headband'],
					text: 'A Karate Headband has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cen2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});



export {
	moduleTasks
};
