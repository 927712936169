import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './star.scss';

const Star = ({page, isFilled}) => {
	const [showFilledStar, setShowFilledStar] = useState(isFilled);
	const [animateStar, setAnimateStar] = useState(false);
	useEffect(() => {
		if (!isFilled && showFilledStar) setShowFilledStar(false);

		if (!isFilled || showFilledStar) return;
		
		const timeout = setTimeout(() => {
			setShowFilledStar(true);
			setAnimateStar(true);
		}, 400);
		
		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};
	}, [isFilled, showFilledStar]);
	
	return (
		<div className={'Star ' + page + (showFilledStar ? ' filled' : '') + (animateStar ? ' animate' : '')} />
	);
};

Star.propTypes = {
	page: PropTypes.string.isRequired,
	isFilled: PropTypes.bool
};

export default Star;
