const mainMenu = [
	{pageId: 'training', title: 'Training'},
	{pageId: 'retention', title: 'Player management'},
	{pageId: 'analytics', title: 'Analytics'},
	{pageId: 'facilitators', title: 'Facilitators'}
];

const facilitatorPagesData = [
	{
		id: 'overview',
		menu: mainMenu
	},
	{
		id: 'training',
		menu: mainMenu,
		subpageIds: ['players', 'game-modules'],
		// subpageIds: ['players', 'game-modules', 'resources']
	},
	{
		id: 'retention',
		menu: mainMenu,
		subpageIds: ['insights', 'competitions', 'messages']
	},
	{
		id: 'analytics',
		menu: mainMenu,
		subpageIds: ['statistics'],
	},
	{
		id: 'facilitators',
		menu: mainMenu,
		subpageIds: ['facilitators']
	}
];

const facilitatorSubpagesData = [
	{
		id: 'players',
		title: 'Players', 
		description: 'View a list of players<br />and add new.',
		description2: 'Invite new players to the game and track player status.<br />Click on a player for detailed information.'
	},
	{
		id: 'game-modules', 
		title: 'Game modules', 
		description: 'See game content.',
		description2: 'Preview training modules.'
	},
	{
		id: 'resources', 
		title: 'Resources', 
		description: 'Download training manual, posters, and more.',
		description2: 'Download training manual, posters, and more. Coming soon.'
	},
	{
		id: 'insights', 
		title: 'Insights', 
		description: 'View insights about your team based on their game performance.',
		description2: 'View insights from the game to use in daily standups etc.'
	},
	{
		id: 'competitions', 
		title: 'Competitions', 
		description: 'Create competitions to drive game activity.',
		description2: 'Special modules that revisits core learning goals and invites players to revisit the game.'
	},
	{
		id: 'messages',
		title: 'Messages',
		description: 'Send messages directly to players.',
		description2: 'Send messages directly to all players or players in specific departments.'
	},
	{
		id: 'statistics', 
		title: 'Analytics', 
		description: 'See statistics about usage, mistakes, time spent, and more.',
		description2: 'View game statistics.'
	},
	{
		id: 'tasks', 
		title: 'Analytics', 
		description: 'See statistics about usage, mistakes, time spent, and more.',
		description2: 'Statistics for %REPLACE%.'
	},
	{
		id: 'facilitators',
		title: 'Facilitators',
		description: 'See facilitators and co-facilitators for this store.',
		description2: 'Facilitators and co-facilitators.'
	}
];


export {
	facilitatorPagesData,
	facilitatorSubpagesData
};
