const modulesStructure = [
	{
		id: 'intro',
		title: 'Intro',
		description: `Introduction to the game. Unlocks Orientation.`,
	},
	{
		id: 'orientation',
		title: 'Orientation',
		description: `Overview and first training. Ulocks Customer Service.`
	},
	{
		id: 'customer-service',
		title: 'Customer service 1+2',
		description: `Basic customer service. Completing module 2 unlocks departments.`
	},
	{
		id: 'departments-1',
		title: 'Departments Level 1',
		description: `Deli, Meat, Produce, and more. Completing any level 1 module unlocks basic training.`
	},
	{
		id: 'basic-training',
		title: 'Basic Training',
		description: `Short modules about 5S, Safety, Daily Guiidelines, Food Safety, and more. Completing all modules unlocks departments level 2.`
	},
	{
		id: 'departments-2',
		title: 'Departments Level 2',
		description: `Advanced training.`
	}
];

export {
	modulesStructure
	
};
