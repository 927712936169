import React from 'react';
import PropTypes from 'prop-types';
import OrderDndItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import './order-dnd-items.scss';

const OrderDndItems = ({isCompleted, taskData, orderedItems, handleMoveItems}) => {
	return (
		<div id="orderItems" className={'OrderDndItems ' + taskData.taskId}>
			{/* Item order numbers */}
			<div className="OrderDndItems-itemNumbers">
				{orderedItems.map((_, index) => {
					return (
						<div key={index} className={'OrderDndItems-item'}>
							<div className="OrderDndItems-itemNumber">
								<span>{(index + 1)}.</span>
							</div>
						</div>
					);
				})}
			</div>

			{/* Items */}
			<div className="OrderDndItems-items">
				{orderedItems.map((item) => {
					const itemData = taskData.items.find((i) => {return i.id === item.id;});
					if (!itemData) return null;
					
					let isMoveable = true;
					let classes = [taskData.taskId];
					const isPlacedCorrect = itemData.correctOrderNumbers.indexOf(item.locationIndex + 1) >= 0;
					if (isCompleted || (item.isLocked && isPlacedCorrect)) {
						isMoveable = false;
						classes.push('correct');
					}
					const locationIndex = item.locationIndex;
					const lastMoved = (item.lastMoved ? item.lastMoved : null);
					return (
						<div key={item.id} className={'OrderDndItems-itemContainer index-' + locationIndex}>
							<OrderDndItem
								isMoveable={isMoveable} 
								isLocked={item.isLocked}
								locationIndex={locationIndex}
								lastMoved={lastMoved}
								itemData={itemData}
								classes={classes}
								handleMoveItems={handleMoveItems}
							/>
						</div>
					);
				})}
			</div>
			<OrderDndPreview taskId={taskData.taskId} itemsData={taskData.items} />
		</div>
	);
};

OrderDndItems.propTypes = {
	isCompleted: PropTypes.bool.isRequired,
	taskData: PropTypes.object.isRequired,
	orderedItems: PropTypes.array.isRequired,
	handleMoveItems: PropTypes.func.isRequired,
};

export default OrderDndItems;