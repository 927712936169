import PropTypes from 'prop-types';
import {getPlayerModulesData, getPlayerModuleData, checkIfModuleIsCompleted} from 'helpers/module-helper';
import {checkIfAreaIsUnlocked} from 'helpers/area-helper';
import Character from 'components/ui/character/character';
import './area.scss';

const Area = ({type, userData, gameData, areaData, playerData, handleGoToArea}) => {
	/* Manager id */
	const managerId = (gameData && gameData.managerId ? gameData.managerId : 'a');
	
	/* Player modules data */
	const playerModulesData = getPlayerModulesData(playerData, null);
	
	/* Lock status */
	let isUnlocked = false;
	if (type === 'area') {
		isUnlocked = (userData.role === 'admin' 
			? true
			: checkIfAreaIsUnlocked(areaData.id, playerModulesData, gameData)
		);
	}
	if (type === 'main-island') {
		isUnlocked = true;
	}

	/* Area progress */
	let numberOfModulesAndSideQuests = 0;
	let numberOfCompletedModules = 0;
	let areaProgressPercent = 0;
	if (type === 'area') {
		numberOfModulesAndSideQuests = areaData.moduleIds.length;
		numberOfCompletedModules = 0;
		areaData.moduleIds.forEach((moduleId) => {
			const playerModuleData = getPlayerModuleData(moduleId, playerData, null);
			const moduleIsCompleted = checkIfModuleIsCompleted(playerModuleData);
			if (moduleIsCompleted) numberOfCompletedModules += 1;
		});
		areaProgressPercent = (numberOfModulesAndSideQuests > 0
			? (numberOfCompletedModules / numberOfModulesAndSideQuests) * 100
			: 0
		);		
	}

	
	return (
		<div 
			className={'Area ' + type + ' ' + areaData.id + (isUnlocked ? '' : ' locked')}
			onClick={() => {if (handleGoToArea) handleGoToArea(areaData.id);}}
		>
			{/* Character (usually manager) */}
			{(type === 'area' && areaData.characterId) && <div className={'Area-character ' + areaData.characterId}>
				<Character page="area" characterId={areaData.characterId} managerId={managerId} />
			</div>}

			{/* Extra items (in front of character) */}
			<div className="Area-items" />

			{/* Areae title */}
			<div className="Area-title">
				<span>{areaData.title}</span>
			</div>

			{/* Areae progress */}
			{type === 'area' && <div className="Area-progress">
				<div className="Area-progressShadow" />
				{type === 'area' && <div className="Area-progressBar" style={{width: areaProgressPercent + '%'}} />}
				<div className="Area-progressLabel">
					<span>{numberOfCompletedModules}/{numberOfModulesAndSideQuests}</span>
				</div>
			</div>}
		</div>
	);
};

Area.propTypes = {
	type: PropTypes.string.isRequired,
	userData: PropTypes.object,
	gameData: PropTypes.object,
	areaData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToArea: PropTypes.func
};

export default Area;
