// NOTE: we can only handle  "type: 'random-item'" and chooseBetwween: 2" at the moment!
const lootBoxesData = [
	{
		id: 'bakery-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'center-store-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'customer-service-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'deli-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'floral-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'meat-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'produce-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'seafood-2',
		type: 'random-item',
		chooseBetween: 2
	},
	{
		id: 'side-quests-1',
		type: 'random-item',
		chooseBetween: 2
	}
	
];

export {
	lootBoxesData
};