import Instructions from 'components/game/module/instructions/instructions';
import BranchingStory from 'components/game/module/branching-story/branching-story';
import ClockTasks from 'components/game/module/clock-tasks/clock-tasks';
import Dialogue from 'components/game/module/dialogue/dialogue';
import Intro from 'components/game/module/intro/intro';
import MultipleChoice from 'components/game/module/multiple-choice/multiple-choice';
import Order from 'components/game/module/order/order';
import Organize from 'components/game/module/organize/organize';
import PaperDoll from 'components/game/module/paper-doll/paper-doll';
import Sort from 'components/game/module/sort/sort';
import SpotErrors from 'components/game/module/spot-errors/spot-errors';
import Welcome from 'components/game/module/welcome/welcome';
import Image from 'components/game/module/image/image';

const moduleComponents = {
	'branching-story': BranchingStory,
	'clock-tasks': ClockTasks,
	dialogue: Dialogue,
	instructions: Instructions,
	image: Image,
	intro: Intro,
	'multiple-choice': MultipleChoice,
	order: Order,
	organize: Organize,
	'paper-doll': PaperDoll,
	sort: Sort,
	'spot-errors': SpotErrors,
	welcome: Welcome,
};

export {
	moduleComponents
};
