import React from 'react';
import PropTypes from 'prop-types';
// import {facilitatorPagesData} from 'data/facilitator-data';
import {loginUiTexts, generalUiTexts} from 'data/ui-texts';
import {adminPagesData} from 'data/admin-data';
import Button from 'components/ui/button/button';
import logoMDIImg from 'assets/images/facilitator/logo-mdi.svg';
import adminImg from 'assets/images/facilitator/text-admin.svg';
import './admin-header.scss';

const AdminHeader = ({page, userData, handleGoToPage, handleLogout}) => {
	return (
		<div className={'AdminHeader ' + page}>
			{/* Logo */}
			<div 
				className="AdminHeader-logo"
				onClick={() => {handleGoToPage('overview');}}
			>
				<img src={logoMDIImg} alt={generalUiTexts.mdiLogo} />
				<img src={adminImg} alt={generalUiTexts.admin} />
			</div>

			{/* Logout / info buttons */}
			<div className="AdminHeader-buttons">
				<Button classes={['logout']} text={loginUiTexts.logout} onClick={handleLogout} />
				{/* <Button classes={['help']} onClick={() => {console.log('info plz!');}} /> */}
			</div>

			{/* User info */}
			<div className="AdminHeader-user">
				<div className="AdminHeader-userInfo">
					<div><span>{loginUiTexts.loggedInAs}:</span></div>
					<div><span>{userData.email}</span></div>
					<div><span>{userData.name}</span></div>
				</div>
			</div>

			{/* Menu */}
			<div className="AdminHeader-menu">
				{adminPagesData.map((adminPage) => {

					return (
						<div 
							key={adminPage.id}
							className={'AdminHeader-menuItem' + (adminPage.id === page ? ' selected' : '')}
							onClick={() => {handleGoToPage(adminPage.id);}}
						><span>{adminPage.title}</span></div>
					);
				})}	
			</div>
		</div>
	);

};

AdminHeader.propTypes = {
	page: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default AdminHeader;
