import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import { getGameUrl } from 'helpers/game-helper';
import { getReducedYearBook } from 'helpers/reports-helper';
import { objectCompare } from 'helpers/general-helper';
import { playerGameDataTemplate } from 'data/templates/player-game-data-template';
import Loading from 'components/loading/loading';
import Lobby from 'components/game/lobby/lobby';
import Game from 'components/game/game';
import ImageLoader from 'components/ui/image-loader/image-loader';

class PlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isConnectedToGame: false,
			gameData: null,
			gamesData: []
		};
		this.unsubscribeGameData = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get game url */
		const gameUrl = getGameUrl(window.location.pathname);

		/* Site root */
		if (!gameUrl || gameUrl.length === 0 || gameUrl === '/') {
			if (
				this.props.userData.role === 'player' &&
				!this.props.userData.isCoFacilitator &&
				this.props.userData.games && 
				this.props.userData.games.length > 0 &&
				this.props.userData.games[0].id
			) {
				/* Role: player, not coFacilitator, connected to game, get redirect uri  */
				const db = firebase.firestore();
				db.collection(appConfig.gamesDbName).doc(this.props.userData.games[0].id).get().then((doc) => {
					if (doc.exists && doc.data() && doc.data().url) {
						/* Redirect to game url */
						window.location.href = '/' + doc.data().url;
					} else {
						/* Show default page (lobby) */ 
						this.setState({isLoading: false});						
					}
				}).catch(() => {
					/* Show default page (lobby) */ 
					this.setState({isLoading: false});
				});
			} else {
				/* Show default page (lobby) */ 
				this.setState({isLoading: false});
			}
		} else {
			/* Get games */
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).get().then((querySnapshot) => {
				let gamesData = [];
				querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
				this.setState({gamesData}, () => {		
					/* Find game with matching url */
					const urlGameData = gamesData.find((game) => {return game.url === gameUrl;});
					if (urlGameData) {
						/* Game exists, subscribe to game data */
						this.subscribeToGameData(urlGameData.id).then(() => {
							/* Role: player, not coFacilitator, only access to 1 game  */
							if (
								this.props.userData.role === 'player' && 
								!this.props.userData.isCoFacilitator === true
							) {
								if (!this.props.userData.games || this.props.userData.games.length === 0) {
									/* Player not connected to any game, connect to this game */
									this.updatePlayerGameData(
										Object.assign({}, playerGameDataTemplate, {id: this.state.gameData.id})
									).then(() => {
										this.setState({isLoading: false, isConnectedToGame: true});	
									}).catch(() => {
										// TODO: handle error updating player
										this.setState({isLoading: false});
									});
								} else {
									/* Player is connected to a game */
									if (this.props.userData.games[0].id === urlGameData.id) {
										/* Player is connected to this game */
										this.setState({isLoading: false, isConnectedToGame: true}, () => {
											/* Re-activate player if removed */
											if (this.props.userData.games[0].isRemoved) {
												let playerGames = JSON.parse(JSON.stringify(this.props.userData.games));
												playerGames[0].isRemoved = false;
												this.updatePlayerGameData(playerGames[0]).catch((error) => {
													console.error(error);
												});
											}
										});	
									} else {
										/* Player is connected to a different game */
										this.setState({isLoading: false});
									}
								}
							}
							
							/* Role: facilitator or player with co-facilitator privs,
							   access to all games they facilitate */
							if (
								this.props.userData.role === 'facilitator' ||
								(this.props.userData.role === 'player' && this.props.userData.isCoFacilitator)
							) {
								if (
									(
										urlGameData.facilitatorEmails && 
										urlGameData.facilitatorEmails.indexOf(this.props.userData.email) >= 0
									) ||
									(
										urlGameData.coFacilitators && 
										urlGameData.coFacilitators.some((c) => {
											return c.email === this.props.userData.email;
										})
									)
								) {
									/* Facilitator is (co-)facilitator of this game */
									if (
										!this.props.userData.games ||
										!this.props.userData.games.length ||
										!this.props.userData.games.some((game) => {return game.id === urlGameData.id;})
									) {
										/* Connect (co-)facilitator to game as player */
										this.connectToNewGame(urlGameData.id).then(() => {
											this.setState({isLoading: false, isConnectedToGame: true});	
										}).catch(() => {
											// TODO: handle error updating player
											this.setState({isLoading: false});
										});
									} else {
										/* (co-)facilitator is already connected to game as player */
										this.setState({isLoading: false, isConnectedToGame: true});
									}
								} else {
									/* User is NOT (co-)facilitator of this game */
									// TODO: let them select between games?
									this.setState({isLoading: false});
								}
							}

							/* Role: admin, access all games */
							if (this.props.userData.role === 'admin') {
								if (
									!this.props.userData.games ||
									!this.props.userData.games.length ||
									!this.props.userData.games.some((game) => {return game.id === urlGameData.id;})
								) {
									/* Connect admin to game as player */
									this.connectToNewGame(urlGameData.id).then(() => {
										this.setState({isLoading: false, isConnectedToGame: true});	
									}).catch(() => {
										// TODO: handle error updating player
										this.setState({isLoading: false});
									});
								} else {
									/* Admin is already connected to game as player */
									this.setState({isLoading: false, isConnectedToGame: true});
								}
							}

							/* Unknown role (shouldn't happen) */
							if (
								this.props.userData.role !== 'admin' &&
								this.props.userData.role !== 'facilitator' && 
								this.props.userData.role !== 'player'
							) {
								this.setState({isLoading: false});
							}
						}).catch(() => {
							// TODO: handle error subscribing to game
							this.setState({isLoading: false});
						});
					} else {
						/* No game corresponds to the url / root url */	
						this.setState({isLoading: false});
					}
				});
			}).catch((error) => {
				// TODO: display error
				console.error('Could not get games: ', error);
				this.setState({isLoading: false});
			});
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel subscribtions */
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();
	};

	/**
	 * Subscribe to game data
	 * @param {string} gameId 
	 * @returns {Promise}
	 */
	subscribeToGameData = (gameId) => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();

		/* Subscribe to game data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGameData = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Save game data to state */
					const newGameData = {id: doc.id, ...doc.data()};
					this.setState({ gameData: newGameData }, () => {resolve({status: 'ok'});});
				} else {
					/* Error: No game data */
					resolve({status: 'error', error: 'no-game-data'});
				}
			},
			(error) => {
				/* Error: Could not get game data */
				console.error('Could not get game data: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Connect to new game
	 * @param {string} gameId 
	 * @returns 
	 */
	connectToNewGame = (gameId) => {
		/* Get user id */
		const userId = this.props.userData.id;

		/* Get current games data */
		let games = (this.props.userData && this.props.userData.games && this.props.userData.games.length > 0
			? [...this.props.userData.games] 
			: []
		);

		/* Add game */
		games.push(Object.assign({}, playerGameDataTemplate, {id: gameId}));

		/* Update database */
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update({games: games});
	};

	/**
	 * Update player game data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updatePlayerGameData = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* No game data */
		if (!this.state.gameData) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Get user id */
		const userId = this.props.userData.id;

		/* Get current games data */
		let games = (this.props.userData && this.props.userData.games && this.props.userData.games.length > 0
			? [...this.props.userData.games] 
			: [Object.assign({}, playerGameDataTemplate, {id: this.state.gameData.id})]
		);

		/* Get game index (always 0 for players, as they can only have 1 game) */
		const gameIndex = games.findIndex((game) => {return game.id === this.state.gameData.id;});
		if (gameIndex >= 0) {
			/* Update games data */
			games[gameIndex] = 	Object.assign({}, games[gameIndex], {...updates});
			/* Save updated games data */
			const db = firebase.firestore();
			return db.collection(appConfig.usersDbName).doc(userId).update({games: games});
		}
		
		/* Error, game not found, should not happen  */
		console.error('game not found: ', this.state.gameData.id);
		return new Promise((resolve)=>{resolve();});
	};

	/**
	 * Switch store
	 * @param {string} gameId 
	 */
	switchPlayerStore = (gameId) => {
		const games = [{...Object.assign({}, playerGameDataTemplate, {id: gameId})}];
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(this.props.userData.id).update({games: games});
	};

	/**
	 * Reset player game data for this game
	 */
	resetPlayerGameData = () => {
		this.updatePlayerGameData({...Object.assign({}, playerGameDataTemplate, {id: this.state.gameData.id})});
	};


	/**
	 * Report the player if an report does not already exsist from user 
	 */ 
	reportPlayer = (playerId, reportedById, yearbookData) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).doc(playerId).get().then((doc) => {
				const user = JSON.parse(JSON.stringify(doc.data()));
				let yearbookReports = [];
				const report = {
					reportedById: reportedById,
					reportTime: dayjs(new Date()).format('YYYY-MM-DD'),
					gameId: (user.hasOwnProperty('games') && user.games.length > 0) ? user.games[0].id : null,
					yearBook: getReducedYearBook(yearbookData)
				};

				// yearBook object is empty
				if (JSON.stringify(getReducedYearBook(yearbookData)) === '{}') {
					resolve({status: 'yearBookEmpty'});
					return;
				}

				if (user.hasOwnProperty('yearbookReports')) {
					yearbookReports = JSON.parse(JSON.stringify(user.yearbookReports));
					/* user already reported onces */
					if (yearbookReports.some((object) => {return object.reportedById === reportedById;})) {
						const oldReport = yearbookReports.find((object) => {
							return (object.reportedById === reportedById 
								&& objectCompare(object.yearBook, report.yearBook));
						});

						// same yearbook data repotred
						if (oldReport && objectCompare(oldReport.yearBook, report.yearBook)) {
							resolve({status: 'userAlreadyReported'});
							return;
						} 
					}
					yearbookReports.push(report);
				}  else {
					yearbookReports = [report];
				}

				db.collection(appConfig.usersDbName).doc(playerId).update({yearbookReports: yearbookReports}).then(()=>{
					resolve({status: 'success'});
				}).catch((error) => {
					console.error('update user role error: ', error);
					resolve({status: 'error'});
				});

			}).catch((error) => {
				console.error('create game error: ', error);
				resolve({status: 'error'});
			});
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-game" deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Not connected to game */
		if (!this.state.isConnectedToGame) {
			return (
				<Lobby 
					userData={this.props.userData}
					gameData={this.state.gameData}
					gamesData={this.state.gamesData}
					switchPlayerStore={this.switchPlayerStore}
					handleLogout={this.props.handleLogout}
				/>
			);
		}


		/* Game */
		let playerGameData = (this.props.userData && this.props.userData.games && this.props.userData.games.length > 0
			? this.props.userData.games.find((game) => {return game.id === this.state.gameData.id;})
			: Object.assign({}, playerGameDataTemplate, {id: this.state.gameData.id})
		);
		playerGameData.role = this.props.userData.role;
		playerGameData.created = this.props.userData.created;

		return (
			<>
				<Game 
					deviceInfo={this.props.deviceInfo}
					userData={this.props.userData}
					playerGameData={playerGameData}
					gameData={this.state.gameData}
					updatePlayerGameData={this.updatePlayerGameData}
					resetPlayerGameData={this.resetPlayerGameData}
					scrollToTop={this.props.scrollToTop}
					handleLogout={this.props.handleLogout}
					reportPlayer={this.reportPlayer}					
				/>
				<ImageLoader type='backgrounds' />
				<ImageLoader type='avatar-maker' />
				<ImageLoader type='badges' />
				<ImageLoader type='characters' />
				<ImageLoader type='module-task-intros' />
				<ImageLoader type='module-tasks' />
			</>
		);
	};
}

PlayerController.propTypes = {
	userData: PropTypes.object,
	handleLogout: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default PlayerController;
