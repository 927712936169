const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Welcome to Seafood.... Home of ... well, this Ocean I guess.`,
		background: 'ocean-island',
		characterPose: 1,
		characterType: 'wizard',
		extraCharacters: [{id: 'giant-sea-creature', type: 'middle'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: `Did you release the Kraken again?`,
		background: 'ocean-island',
		speechBubbleDirection: 'right',
		extraCharacters: [
			{id: 'giant-sea-creature', type: 'middle'},
			{id: 'manager-6 flipped', type: 'right'}
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `What's that? I'm needed elsewhere?`,
		background: 'ocean-island',
		characterPose: 1,
		characterType: 'wizard',
		animateCharacter: true,
		animationId: 'poof',
		extraCharacters: [
			{id: 'giant-sea-creature', type: 'middle'},
			{id: 'manager-6 flipped', type: 'right'}
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		showAvatar: true,
		text: `Ok. Moving on. Let's prepare for working with Seafood.`,
		background: 'ocean-island',
		characterPose: 1,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'seafood-1-paper-doll-dress',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Cut resistant gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'bucket',
				title: 'Mop bucket'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'ice-bucket',
				isCorrect: true,
				title: 'Ice bucket'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'fish',
				isCorrect: true,
				title: 'Dead fish'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'knife-sharpener',
				isCorrect: true,
				title: 'Handheld knife sharpner'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'knife2',
				isCorrect: true,
				title: 'Fillet knife'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hair-net2',
				dependsOnBodyHeight: true,
				isCorrect: true,
				title: 'Hair net'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'box-cutter',
				dependsOnBodyHeight: false,
				title: 'Box cutter'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net',
				isCorrect: true,
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Beard net',
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full',
				type: 'bodytype',
				isCorrect: true,
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scissor',
				type: 'alternative',
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Floral scissors'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-white-2',
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Hat'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'chefs-hat',
					text: 'A Chef\'s hat has been added to your avatar.'
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'seafood-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-allergic-shellfish',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: 'Quick question, are you allergic to shellfish by chance?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Make sure to let your real life Manager know before proceeding in the department.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Great.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Not sure',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You\'d better not go face first into the lobster tank then.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-most-crucial',
		text: `What is crucial with Seafood?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Temperature',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'The most of any department.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Type',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Amount',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Season',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-focus',
		text: `What do we focus on?`,
		
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'High Five Steps',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Quality',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `But it's part of The High 5.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Safety',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `But it's part of The High 5.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Amount',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `But it's part of The High 5.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Seafood',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-top-sales-driver',
		text: `And the top sales driver is?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Quality',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Pricing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Hunger',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Stock level',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		showAvatar: true,
		text: `First, you have to be COOL to tag.`,
		characterPose: 1,
		characterType: 'wizard',
		characterItems: ['sunglasses-1']
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: ` No, we use COOL tagging.`,
		characterPose: 2,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-cool-stand-for',
		text: `What does COOL stand for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Country of Origin Labeling',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Counting Out of Line',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Correct, Observe, Out of Stock, Label',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Cost On or Off Label',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-cool-violation-fine',
		text: `How much does each COOL violation fine?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Up to $1,000',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Up to $100',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Up to $10,000',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Up to $10',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Now, let\'s take a look at the department.'
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'seafood-1-department',
		nextBtnTypes: ['next'], // or ['next']
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-start-make-ready',
		text: `Where's the best place to start when making sure the department is ready for customers?`,
		showNumberOfAnswersToSelect: true,
		image: 'first',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Sales floor',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Exactly. Start from the customer's perspective.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Prep table',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `There's plenty to check on here, but it's not where we'll start.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Cooler',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `There's plenty to check on here, but it's not where we'll start.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Freezer',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `There's plenty to check on here, but it's not where we'll start.`,
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Seafood should be ready by 10:00am, after Meat.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-addressed-first',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'seafood-1-issues-priority',
		text: `Which of these should be addressed first?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // bad seafood
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // out of stock
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // low on stock
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-addressed-first-why',
		text: `Why this first?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Safety',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Quality',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Fun'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Create orders'
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'seafood-1-order-fix-issue',
		text: `In what order do these steps go?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'Cull',
				correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'Clean',
				correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'Rotate & Stock',
				correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'Write Order',
				correctOrderNumbers: [4],
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-freezer-issues',
		text: `Can you see anything wrong with this case?`,
		layout: 'seafood-1-freezer-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'fillets',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'temperature',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lobster',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sign',
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'That is too warm for seafood.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-high-temperature',
		text: `What to do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Fix case setting',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Check temperature of product',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Check last recorded temperature',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Run in a panic'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Throw out everything',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Check product temperature first.`,
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Before we cull anything, let\'s test your knowledge.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'One of the things you have to use is your sense of smell.'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Amazingly technology has allowed smells through devices.`,
		characterPose: 1,
		characterType: 'wizard',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Just sniff this apple on screen.`,
		characterPose: 1,
		characterType: 'wizard',
		items: ['big-apple'],
		autoContinueSeconds: 3
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-sniff-apple',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		image: 'wizard3',
		text: 'HAHA!<br/><br />Did you just do that?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Well, yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Then you deserve this!'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item',
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'shark-hat',
							text: 'A Shark Hat has been added to your avatar.'					
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'No, of course not',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Identifying different smells is tricky. But I can assure you, when fish smells bad, there is no question; it is bad!`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'But physical appearance also matters.'
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-healthy-fish',
		text: 'What parts of the fish should you look at to see if it\'s healthy?',
		layout: 'seafood-1-bad-fish',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'eyes',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gills',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'scales',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'belly-cavity',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-poor-quality',
		subtype: 'images',
		shuffleOptions: true,
		showNumberOfAnswersToSelect: false,
		layout: 'seafood-1-poor-quality-fish',
		text: 'Which of these is of poor quality?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Eye dull, sunken, cloudy pupils
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Gills are bright red, no mucus
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Missing a few scales.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // clean red belly cut showing
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-soft-poke',
		text: 'You poke this one and it\'s soft to the touch and leaves a small indention where you pressed down. Is that good?',
		showNumberOfAnswersToSelect: true,
		image: 'fish',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'No. It could be poor quality and needs further inspection',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'If it\'s not firm and seems flabby, take a closer look.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Yes. This is how good quality fish should feel',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No, good fish would be firm and bounce back when proded.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Now let\'s test the fillets.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-good-quality',
		subtype: 'images',
		shuffleOptions: true,
		showNumberOfAnswersToSelect: false,
		layout: 'seafood-1-fillets',
		text: 'Which of these fillets are of good quality?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // color is uniform and bright
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // color is spotty and darkened
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Straight cut on edges
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Has a little skin/scales left behind
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-typical-shellfish',
		subtype: 'images',
		shuffleOptions: true,
		showNumberOfAnswersToSelect: false,
		layout: 'seafood-1-shellfish',
		text: `What are typical shellfish you'll find in a grocery store?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // hermit crab
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // shrimp
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // lobster
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // scallop
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // clam
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // crab
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // jellyfish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // sea urchin
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 9, // starfish
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-safe-to-eat',
		text: `How do you know if clams, oysters, and mussels <b>are</b> safe to eat?`,
		showNumberOfAnswersToSelect: true,
		image: 'clam-oyster-mussel',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Pry test',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Tap test',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's the one. If they are alive they'll close when you tap them.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Ping test',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Float test',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-pinchy-test-health',
		text: 'How about the pinchy ones - what should you look at to check their health?',
		showNumberOfAnswersToSelect: true,
		image: 'pinchy-ones',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Antennas',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Eyes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Legs',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes, keep an eye on leg movement. Shellfish spoil quickly upon death.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Claws',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `You really shouldn't get to close to the pinchers.`,
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-bad-fish-smell',
		text: 'What does bad fish smell like?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Ammonia',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Bleach',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Onions',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Sulfur',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-resemble',
		text: `What sea item should fresh shrimp and scallops resemble?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Pearls', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Octopus',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Shells',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Coral',
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'seafood-1-organize-bad-shellfish',
		layout: 'seafood-1-bad-shellfish',
		text: `Put the bad Shellfish into the bucket.`,
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'lobster', alt: 'Shellfish', correctContainerIds: ['a1', 'a2', 'a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'clam', alt: 'Shellfish', correctContainerIds: ['a1', 'a2', 'a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'shrimp', alt: 'Shellfish', correctContainerIds: ['a1', 'a2', 'a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'oyster', alt: 'Shellfish', correctContainerIds: ['a1', 'a2', 'a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'crab', alt: 'Shellfish', correctContainerIds: ['c1', 'c2', 'c3', 'c4', 'c5']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'bucket',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'bucket', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'bucket', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'bucket', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'bucket', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'bucket'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'seafood-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'seafood-1-calendar-first',
		text: `Today is the first, everything is fresh...`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-end-of-fish-filets',
		text: 'What day marks the end of Fish Filets?',
		layout: 'seafood-1-calendar-day-4',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'day-4',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-end-of-scallops',
		text: 'What day marks the end of Scallops?',
		layout: 'seafood-1-calendar-day-5',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'day-5',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-end-of-smoked-fish',
		text: 'What day marks the end of Smoked Fish?',
		layout: 'seafood-1-calendar-day-10',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'day-10',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-newest-product',
		text: 'Where does the newest product go in the case?',
		layout: 'seafood-1-product-case',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'back-bottom',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-oldest-product',
		text: 'Where does the oldest product go?',
		layout: 'seafood-1-product-case',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'top-front',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-expired-product',
		text: 'Where does product that expires today go?',
		layout: 'seafood-1-product-case',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'trash',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's right. It's too late to sell this one.`,
						})
					})
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's label our items.`
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'seafood-1-spot-errors-shrimp-tag',
		text: 'What is wrong with this tag? Click on the things that are wrong. ',
		layout: 'seafood-1-shrimp-tag',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'each',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'yesterday',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'barcode',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Good call.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'crab',
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Bar code should only have numbers, and the price is too much for a single shrimp.`,
		characterPose: 2,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-replacement-tag',
		text: 'We need a replacement tag.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `I'll write one`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This isn't consistent with the rest.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `I'll have to print one`,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Great. Thank you.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `I'll just mark out the issues`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Now customers don't trust our product because they didn't trust our sign.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'seafood-1-organize-labels',
		layout: 'seafood-1-fish-labels',
		text: `Place these labels in the correct place.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, containerId: 'b1', image: 'label-1', alt: 'description and cost', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, containerId: 'b2', image: 'label-2', alt: 'save', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, containerId: 'b3', image: 'label-3', alt: 'brand', correctContainerIds: ['a4']
			}),
			
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'final',}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'final',}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'seafood-1-problem-solver-2', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Alright, before you go...`,
		characterPose: 1,
		characterType: 'wizard',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'seafood-1-multiple-choice-crabs-lean',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: `Why don't crabs lean against the glass in the tank?`,
		image: 'wizard2',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `Because it's against the "claws"`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Nice!'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item',
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'lobster-bib',
							text: 'A Lobster Bib has been added to your avatar.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'To keep from cracking',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `It's shellfish to hog the view`,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Are you proud of yourself? How about you "sea" yourself out.`,
		characterPose: 2,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Ah. I see what you did there.`,
		characterPose: 1,
		characterType: 'wizard',
		animateCharacter: true,
		animationId: 'poof',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Great worked today. I'll see you on the next one.`,
		characterPose: 1,
		characterItems: ['mounted-singing-fish'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['mounted-singing-fish'],
					text: 'A Mounted Singing Fish has been added to your avatar.'
				})
			}),
		]
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'sea1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};