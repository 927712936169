const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		text: 'Welcome to the Grocery Greenhouse!',
		background: 'ocean-island',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		showAvatar: true,
		text: `Welcome to the Floral department. Let's get you ready for work.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		text: `On the next screen, click on the items you believe you need for work in the floral department onto your avatar. Click "Done" when you're ready.`,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'floral-1-paper-doll-items',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic', 
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'badge', 
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true,
				title: 'Green Thumb badge'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'plant-mister', 
				type: 'alternative',
				isCorrect: true,
				title: 'Plant mister'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-green', 
				type: 'alternative', 
				dependsOnBodyHeight: true,
				title: 'Wide brim hat'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'umbrella',
				title: 'Umbrella'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'thermometer',
				title: 'Thermometer'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'flower', 
				type: 'alternative',
				isCorrect: true,
				title: 'Flower'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scissor', 
				type: 'alternative',
				isCorrect: true,
				title: 'Scissor'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'green-thumb-badge',
					text: 'A Green Thumb badge has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'floral-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-senses',
		text: `What do you think customers use to shop floral the most?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Sight', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Taste', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Touch', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Smell'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `This is your chance to dress up the store.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-starting-location',
		subtype: 'images',
		layout: 'floral-1-starting-location',
		shuffleOptions: false,
		text: `Where should we start?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // out front on the sales floor
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `No, that's too far back.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // way back 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `No, that's too far back.`,
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'floral-1-spot-errors-needed-corrections',
		text: 'What needs correcting?',
		layout: 'floral-1-needed-corrections',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'paperwork'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'cut-stems'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'scissors'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'ribbon'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'loose-cardboard'}),
		],
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'floral-1-spot-errors-ready-department',
		text: 'What do you need to do to make the department ready for the next day? Touch on the areas that need your attention.',
		layout: 'floral-1-ready-department',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flower-debris'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'floral-1-spot-errors-ready-production',
		text: `Sales floor looks good, let's make sure the production area is also ready.`,
		layout: 'floral-1-floral-production-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tool-blade'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flower-debris'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'counter-mess'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirty-floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirty-tool'}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'floral-1-order-clean-cooler',
		text: `Since it's Tuesday, sales and inventory are usually slower, making it a good day for cooler cleaning. In what order should you do the following?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'Remove all floral buckets',
				correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'Spray the inside of the unit',
				correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'Dry inside of unit',
				correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'Check vents',
				correctOrderNumbers: [4],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-expiration-dates',
		text: `Since we don't eat them, flowers don't have expiration dates.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'True'
				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'False', isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		characterItems: ['flower-1'],
		text: `Speak for yourself, these are great.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-temperature',
		text: `What is the optimal storage temperature for most flowers?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '30-35F',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '33-37F', isCorrect: true 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '38-42F'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Above 50F', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-temperature-tropical',
		text: `What about tropical flowers?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Above 50F', isCorrect: true 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Above 60F'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Above 70F'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Above 80F', 
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['shirt-tropical-m', 'shirt-tropical-f'],
					text: 'A Tropical Shirt has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-ready-time',
		text: `If we open at 7:00am, when should the area be ready?`,
		subtype: 'images',
		layout: 'floral-1-ready-time',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true  // 7:00
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 7:30
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 8:00
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 8:15
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-tuesday-inventory',
		text: 'If it is Tuesday, should you be gearing up on inventory or slowing down?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Gear up', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Better to stay lighter on inventory Tuesday - Thursday.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Slow Down',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Most of our sales come from the weekend starting Friday to Monday.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Stay consistent',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-all-customers-get',
		text: `What should every customer get with their flowers?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'A smile', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. One from you and hopefully one from them.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Plant food'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'A rosebud'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'A flower pot', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['corny-1'],
		text: `Ugh really. Can you hold these?`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Anyway... Let's talk about why people would buy flowers or balloons.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'The most important days are the major annual holidays.'
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'floral-1-sort-holiday',
		image: 'calendar2',
		text: `Match the time of the year with a holiday that could occur at that time.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'graduation', text: 'Graduation'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'easter', text: 'Easter'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'memorial day', text: 'Memorial day'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'start of spring', text: 'Start of spring'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'start of fall', text: 'Start of fall'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 1, text: 'May & June', categoryIds: ['graduation']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 2, text: 'Late March to late April', categoryIds: ['easter']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 3, text: 'Late May', categoryIds: ['memorial day']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 4, text: 'Late March', categoryIds: ['start of spring']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 5, text: 'Late September', categoryIds: ['start of fall']})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-holiday',
		text: 'What holiday would you need to be ready for at this time of year?',
		image: 'calendar3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Valentine\'s', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! And it\'s a big day for the floral department, so you\'d better be ready.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Mother\'s Day', 
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Easter', 
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Memorial Day', 
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Father\'s Day', 
				isCorrect: false
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-valentines-day-1',
		text: 'You need to order extra of the top Valentine\'s sellers. What are they?',
		image: 'hearts',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Red roses', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Red carnations', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Pink roses', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Tulips', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Pink lillies', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Orchids', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'Potted plants', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Holidays are not just a good occasion for selling flowers, but also assortments, such as balloons.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-1-multiple-choice-balloons',
		text: 'When it comes to balloons, which are best to always keep on hand?',
		image: 'balloons',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Happy Birthday', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Congratulations', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Baby', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Get Well', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Happy Anniversary',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Thank You',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, text: 'Sorry for your loss',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Now, let's see how you handle a customer in the department.`
	}),
	// FLORAL DIALOG 1 FD-1
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'floral-1-dialogue-mothers-day',
		characterId: 'middle-aged-man',
		maxPoints: 0,
		introText: 'Mother\'s Day is tomorrow. A customer asks for your help.',
		introTextType: 'info',
		introCharacter: 'middle-aged-man-happy flipped',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 1,
				text: 'I\'m looking for something to give my mother for Mother\'s Day. Do you have any ideas?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'How about a bouquet?', nextStepId: 3
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'How about one of our baskets?', nextStepId: 4
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Would she like a potted plant?', nextStepId: 5
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, text: 'Of course, what kinds of flowers does she like?', nextStepId: 6,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-1', pointId: 'smooth-talker', value: 1
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 3,
				text: 'Sure, sounds good. Which one should I pick?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'I don\'t know, she\'s your mother.', nextStepId: 7
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Roses are always a hit.', nextStepId: 8
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Personally, I really like tulips.', nextStepId: 8
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, text: 'Does she have a specific color she likes?', nextStepId: 9,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-2', pointId: 'smooth-talker', value: 1
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 4,
				text: 'Yeah, they look nice. I\'ll take that yellow one.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Excellent choice. I\'m sure she\'ll appreciate it.', nextStepId: 10
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Good choice. Do you want a card with that?', nextStepId: 11,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-3', pointId: 'smooth-talker', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 5,
				text: 'She does, actually. But she tends to forget to water them. ',
				options: [	
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'An orchid, maybe? We have some beautiful ones, I think.',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'This is not a good choice if she tends to forget the plants.',
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'How about a succulent? They can survive being forgotten a bit.', nextStepId: 16,
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 6,
				text: 'I\'m not sure. But she likes yellow.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'We have some nice daffodils then?', nextStepId: 8
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'How about some yellow tulips?', nextStepId: 8,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'We have a basket with both some yellow flowers and yellow wrapping paper?', nextStepId: 8,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, text: 'What about some fresh daisies with a few sunflowers?', nextStepId: 8,
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 7,
				text: 'Well, excuse me for asking for your help. Won\'t happen again.',
				image: 'angry',
				nextStepId: 12,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 8,
				text: 'Good suggestion, we\'ll go with that. Thank you.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Great. Have a nice day.', nextStepId: 11
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Would you want a card with that?', nextStepId: 13,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-4', pointId: 'smooth-talker', value: 1
								})
							})
						]
					}),	
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 9,
				text: 'She\'s pretty fond of yellow. So, yeah, maybe some yellow flowers could work.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'We have some nice daffodils then?', nextStepId: 8
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'How about some yellow tulips?', nextStepId: 8
					}),	
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'We have a basket with both some yellow flowers and yellow wrapping paper?', nextStepId: 8
					}),	
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, text: 'What about some fresh daisies with a few sunflowers?', nextStepId: 8
					}),	
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 10,
				text: 'I think she will. Thank you.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Have a nice day.', nextStepId: 11
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Can I help you with anything else?', nextStepId: 14,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-5', pointId: 'smooth-talker', value: 1
								})
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 11,
				image: 'happy',
				imageFlip: true,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'middle-aged-man-with-bag flipped',
				text: 'The customer leaves with the flowers.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 12,
				image: 'angry',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'middle-aged-man-angry',
				text: 'The customer leaves without buying anything.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 13,
				text: 'Good idea. I\'ll take that one.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Here you go. Have a nice day.', nextStepId: 15
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 14,
				text: 'No thank you, I\'m good.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Have a nice day.', nextStepId: 11
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 15,
				image: 'happy',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'middle-aged-man-with-bag',
				text: 'The customer leaves with flowers and card. '
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 16,
				text: 'Good suggestion, I\'ll take one of those. Thank you.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Great. Have a nice day.', nextStepId: 17,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Would you want a card with that?', nextStepId: 18,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'floral-1-smooth-talker-6', pointId: 'smooth-talker', value: 1
								})
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 17,
				image: 'happy',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'middle-aged-man-with-bag',
				text: 'The customer leaves with the plant.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 18,
				text: 'Good idea. I\'ll take that one.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Here you go. Have a nice day.', nextStepId: 19,
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 19,
				image: 'happy',
				imageFlip: false,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'middle-aged-man-with-bag',
				text: 'The customer leaves with the plant and a card. '
			}),
		]	
	}),
	// FLORAL DIALOG 1 DONE FD-1 DONE	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterItems: ['balloon-yay-me'],
		text: 'Great work today.',
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['balloon-yay-me'],
					text: 'A Yay Me! Balloon has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'flo1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};