const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		background: 'cashier',
		showAvatar: true,
		text: `Welcome. I see you made it to our 2nd island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		showAvatar: true,
		text: `A few other employees are there but ...`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'customer-service-2-spot-errors-generic-employee',
		layout: 'customer-service-2-generic-employee',
		text: `What do you see that's wrong here?`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron-torn'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pet-hair',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s a good catch. Pet hair is an allergen.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'customer-service-2-spot-errors-generic-employee-2',
		layout: 'customer-service-2-generic-employee-2',
		text: `And what about her?`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'name-tag'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'baggy-pants'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flip-flops'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Glad you came ready to work.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Last time in Customer Service 1, you had 3 customers needing help at once.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-announcement',
		text: `What should we have announced?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Three\'s a Crowd',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'All Hands on Deck',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Attention to the Front'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Nothing since it was less than 5.`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Correct. We want to minimize their wait time.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-where-to-go',
		text: `Three's a Crowd! Where do you go?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Front end',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Make sure not to leave anything unsafe.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Office',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Customer Service Desk'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Front Door`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-skill-sets',
		text: `What skill set(s) are needed to help?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Customer service',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Cashier training',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Kung Fu'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Wizardy`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'NO. No. Good grief we don\'t need more of that.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Let's get some work out of that Wizard.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'},],
		text: `You rang?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
		text: `Exactly what I was about to have you do.`,
	}),
	// CUSTOMER SERVICE 2 DIALOG 1 CD-2
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'customer-servce-2-dialogue-wizard-customer',
		characterId: 'wizard',
		maxPoints: 8,
		introText: 'Oh ok... Ring Ring.',
		introTextType: 'info',
		introCharacter: 'wizard-1',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-31',
				text: 'Hello.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: 'Thank you for calling _____. My Name is _____. How may I help you?', 
						nextStepId: 'cd-32',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Thanks for calling ______. How may I assist you today?',
						nextStepId: 'cd-32',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3,
						text: 'Thanks for calling. Please hold.',
						nextStepId: 'cd-33',
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-32',
				text: 'Oh Hello. When does your store open today?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1,
						text: 'Our store is open everyday start at 7:00am. 10am on Sundays.', 
						nextStepId: 'cd-34',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'We open at 7am.',
						nextStepId: 'cd-35',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-33',
				text: 'I will not!',
				nextStepId: 'cd-38',
				image: 'angry',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-34',
				text: 'Thank you. And how is your quest going?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Ehm ...', 
						nextStepId: 'cd-36',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'Huh?',
						nextStepId: 'cd-36',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'It\'s ...',
						nextStepId: 'cd-36',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-35',
				text: 'What about tomorrow, and the next day?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Our store is open everyday start at 7:00am. 10am on Sundays.', 
						nextStepId: 'cd-37',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-36',
				text: 'I\'ll just take over from here. Good job giving extra information about our opening hours. ',
				showPoints: true,
				isFinalStep: true,
				type: 'dialogue',
				character: 'manager-1'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-37',
				text: 'Better to give a little more information up front about the whole week.',
				showPoints: true,
				isFinalStep: true,
				type: 'dialogue',
				character: 'manager-1'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-38',
				text: 'Always introduce yourself - even when you\'re busy.',
				showPoints: true,
				isFinalStep: true,
				type: 'dialogue',
				character: 'manager-1'
			}),
		]
	}),
	// CUSTOMER SERVICE 2 DIALOG 1 CD-2 DONE
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Now let's make it a bit more complicated.`,
	}),
	// CUSTOMER SERVICE 2 BRANCHING STORY
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'customer-service-2-branching-story-wizard-phone',
		maxPoints: 2,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cs-01',
				image: 'customer2-branching',
				text: `Now another customer walks up while you are on the phone with the wizard.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Hang up the phone right away.', 
						nextStepId: 'cs-02',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Put the wizard on hold.', 
						nextStepId: 'cs-03',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Tell the customer you\'ll be right with them.', 
						nextStepId: 'cs-04',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Ask wizard for a brief moment to let customer know you\'ll be right with them.', 
						nextStepId: 'cs-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-02',
				image: 'wizard-1',
				textType: 'info',
				text: `How rude. Next time I'm in your store, I'll turn you into a frog.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-03',
				image: 'wizard-1',
				textType: 'info',
				text: `Why am I on hold? Hello? Hellllllooo?`,
				nextStepId: 'cs-05'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-04',
				image: 'wizard-1',
				textType: 'info',
				text: `You finish up the conversation with the wizard and then help the customer.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cs-05',
				image: 'customer2-branching',
				text: `Sorry, can you check me out real quick?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Yes, I can.', 
						nextStepId: 'cs-06',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'I\'ll be right with you after this call.', 
						nextStepId: 'cs-08',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cs-06',
				image: 'wizard',
				text: `You check out the customer and return to the wizard. "You left me on hold!" he says.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Sorry, what did you need again?', 
						nextStepId: 'cs-07',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-07',
				image: 'wizard-1',
				textType: 'info',
				text: `Nothing now. Goodbye!`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-08',
				image: 'customer2-branching',
				textType: 'info',
				text: `Okay, I can wait.`,
				nextStepId: 'cs-09',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-09',
				image: 'wizard-1',
				textType: 'info',
				text: `You finish your call with the wizard and then help the customer.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-10',
				image: 'wizard-1',
				textType: 'info',
				text: `Sure thing.`,
				nextStepId: 'cs-11',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'cs-11',
				image: 'customer2-branching',
				text: `Sorry, can you check me out real quick?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Yes I can.', 
						nextStepId: 'cs-12',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'I\'ll be right with you after this call.', 
						nextStepId: 'cs-13',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'I am on with a customer. Please check out at the registers.', 
						nextStepId: 'cs-14',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-12',
				image: 'customer2-branching',
				textType: 'info',
				text: `You check out the customer and then help the wizard. Nice job!`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-13',
				image: 'customer2-branching',
				textType: 'info',
				text: `Okay, I can wait.`,
				nextStepId: 'cs-09',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-14',
				image: 'customer2-branching',
				textType: 'info',
				text: `That's a little rude, but ok.`,
				nextStepId: 'cs-15',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'cs-15',
				image: 'wizard-1',
				textType: 'info',
				text: `You finish helping the wizard.`,
			}),
		]
	}),
	// CUSTOMER SERVICE 2 BRANCHING STORY DONE
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Let's try another situation.`,
	}),
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'customer-service-2-dialogue-dinner',
		characterId: 'bearded-man',
		maxPoints: 3,
		introText: 'You see a confused customer that seems to be looking for assistance.',
		introTextType: 'info',
		introCharacter: 'bearded-man-confused',
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-50',
				optionPrompt: '', // 'What do you say?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Can I help you in any way?', nextStepId: 'cd-51'
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-51',
				text: 'Well, maybe you can. I was simply wondering what I should make for dinner tonight?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `I'm sorry, I haven't got a clue.`, nextStepId: 'cd-52'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `What kind of food do you like?`, 
						nextStepId: 'cd-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: `Are you by any chance a vegetarian?`,
						nextStepId: 'cd-55',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, 
						text: `How about a caserole? It's still early, so you'll have time to let it simmer.`,
						nextStepId: 'cd-56',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-52',
				text: `That's a shame. I'll just keep looking then. Maybe I'll think of something.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Okay, have a nice day.`, nextStepId: 'cd-53'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-53',
				showPoints: true,
				isFinalStep: true,
				type: 'action',
				text: `The customer leaves. You feel like you should have done a better job helping.`
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-54',
				text: `Good question. We had a caserole yesterday.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: `How about steaks? We have some nice ones on sale in the meat department right now.`, 
						nextStepId: 'cd-57',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `If you want something easy, there's rotisserie chicken and coleslaw in the deli.`, 
						nextStepId: 'cd-59',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: `I had burritos with chili, beans and chicken the other day. It was pretty good.`, 
						nextStepId: 'cd-60',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-55',
				text: `Nope, I'm not. But now that you mention it, our teenager keeps telling us to eat less meat. So if you have a good idea, I'm all ears.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: `Okay, how about pasta with mushrooms, garlic and cream?`, 
						nextStepId: 'cd-61',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `You could make a vegetarian lasagna.`, 
						nextStepId: 'cd-62',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: `If you like Mexican style food, you can make vegetarian enchiladas.`, 
						nextStepId: 'cd-60',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-56',
				text: `Good idea, but we just had that yesterday. Took forever to make, but it was worth it.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: `If you want something easy, there's rotisserie chicken and coleslaw in the deli.`, 
						nextStepId: 'cd-59',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `How about steaks, then? We have some nice ones on sale in the meat department right now.`, 
						nextStepId: 'cd-57',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-57',
				text: `That's a good idea. I'll go with that.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Alright, have a nice day.`, nextStepId: 'cd-63'								
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `Would you be needing anything else? Potatoes maybe or charcoal for the grill?`, 
						nextStepId: 'cd-58',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: `Great. Let me just walk you to the meat department and show you what's on offer.`, 
						nextStepId: 'cd-64',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-58',
				text: `Charcoal, of course. Good thing you reminded me. Where do you have those?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Just down the next aisle there.`, nextStepId: 'cd-65',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `Please come with me and I'll show you.`, 
						nextStepId: 'cd-66',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-59',
				text: `That's a great idea. Nice and simple.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Sounds good. Have a nice day.`, nextStepId: 'cd-63',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `Perfect. And do you know where the deli is?`, 
						nextStepId: 'cd-70',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-60',
				text: `I like that.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Sounds good. Have a nice day.`, nextStepId: 'cd-63',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `Great. Do you want me to show you where we have salsa, and spices and all that?`, 
						nextStepId: 'cd-66',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-61',
				text: `Sounds delicious. Is that all there is to it?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `More or less. If you google it, you can find a recipe.`, nextStepId: 'cd-68',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `That and some Parmesan.`, 
						nextStepId: 'cd-68',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-62',
				text: `Great idea. Thanks for helping me out.`,
				image: 'happy',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: `No problem. Have a nice day.`, 
						nextStepId: 'cd-63',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'customer-service-2-helping-hand-1', pointId: 'helping-hand', value: 1
								})
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: `Anytime. Would you be needing something else?`, 
						nextStepId: 'cd-69',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'customer-service-2-helping-hand-2', pointId: 'helping-hand', value: 1
								})
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-63',
				type: 'action',
				text: `The customer leaves looking happy.`,
				nextStepId: 'cd-71'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-64',
				text: `No need for that, I know where it is, but thank you.`,
				image: 'happy',
				autoContinue: true,
				nextStepId: 'cd-63'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-65',
				text: `Alright, I'll find it. Thank you.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: `Anytime. Have a nice day.`, 
						nextStepId: 'cd-63',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'customer-service-2-helping-hand-3', pointId: 'helping-hand', value: 1
								})
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-66',
				text: `That would be a great help, thank you.`,
				image: 'happy',
				autoContinue: true,
				nextStepId: 'cd-67'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-67',
				type: 'action',
				text: `You walk the customer to the aisle. He thanks you again.`,
				nextStepId: 'cd-71'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-68',
				text: `Alright, I got it. Thanks a lot.`,
				autoContinue: true,
				nextStepId: 'cd-63'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-69',
				text: `No thank you, I can take it from here.`,
				image: 'happy',
				autoContinue: true,
				nextStepId: 'cd-63'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-70',
				text: `Thank you, but no need for that. I'm a regular.`,
				autoContinue: true,
				nextStepId: 'cd-63'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'cd-71',
				showPoints: true,
				isFinalStep: true,
				type: 'dialogue',
				text: `Great work you just did there. It may not seem like much, but he'll definitely be coming back to shop here.`,
				character: 'manager-1'
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `I saw we had no carts at the front. Can you retrieve a few?`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-mc-collect-carts',
		subtype: 'images',
		layout: 'customer-service-2-collect-carts',
		text: 'What do you need to collect carts?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Safety Vest
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Be safe. Be seen.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Umbrella
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Rope
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Whistle
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-metal-carts',
		text: `We only use metal carts, so you can collect how many safely?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: '5',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: '2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: '10',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `15`,
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'customer-service-2-spot-errors-outside-collecting',
		layout: 'customer-service-2-outside-collecting',
		text: `What should we be aware of outside while collecting?`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'customers',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Customer service extends to the parking lot.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cars',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes, don\'t hit them or get hit by them.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'trash',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Clean up what you can and what\'s in the carts.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'ogres',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'I mean, if there\'s one there. Ya.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-other-issues',
		text: `What else might be an issue?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Weather',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Especially rain.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Store Hours',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Dogs',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Batman`,
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `And simliar to rain, all our carts are wet from this ocean the Wizard put us in.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-wet-carts',
		text: `What else could be an issue because of the wet carts?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Slippery floors',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. Let\'s mark the floor with the Wet Floor sign.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Dead plants',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Mildew',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Rust`,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Probably, but what is more concerning?'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-2-multiple-choice-most-important',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: `Do you know where to find the wet floor sign in your store?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Good. You need to know this for any department.'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'wet-floor-sign-hat',
							text: 'A Wet Floor Sign Hat has been added to your avatar.'
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Not good. Every department should know where safety equipment is.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'cashier',
		text: `Great job today.`,
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cus2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};