import React from 'react';
import PropTypes from 'prop-types';
import './checklist.scss';

const Checklist = ({checklistData, selectedOptions}) => {

	/**
	 * Check if all conditions are met
	 * @param {object} item 
	 * @returns 
	 */
	const checkIfConditionsAreMet = (item) => {
		let conditionsAreMet = true;

		if (item.conditions && item.conditions.length > 0) {
			if (!conditionsAreMet) return;
			item.conditions.forEach((condition) => {
				if (!checkIfConditionIsMet(condition)) {
					conditionsAreMet = false;
				}
			});
		}

		return conditionsAreMet;
	};

	/**
	 * Check if a single conditions is met
	 * @param {object} condition 
	 * @returns 
	 */
	const checkIfConditionIsMet = (condition) => {
		let conditionIsMet = false;

		if (condition.type === 'selected-or' && condition.options) {
			condition.options.forEach((option) => {
				if (conditionIsMet === true) return;
				if (selectedOptions.some((o) => {
					return (o.stepId === option.stepId && o.optionId === option.optionId);
				})) {
					conditionIsMet = true;
				}
			});
		}

		return conditionIsMet;
	};
	
	return (
		<div className={'Checklist' + (checklistData.type ? ' ' + checklistData.type : '')}>
			{checklistData.title && <div className="Checklist-title">
				<span>{checklistData.title}</span>	
			</div>}
			{checklistData.items && <div className="Checklist-items">
				{checklistData.items.map((item, index) => {
					const isChecked = checkIfConditionsAreMet(item);
					return (
						<div key={index} className={'Checklist-item' + (isChecked ? ' checked' : '')}>
							<span>{item.text}</span>
						</div>
					);
				})}
			</div>}
		</div>
	);
};

Checklist.propTypes = {
	checklistData: PropTypes.object.isRequired,
	selectedOptions: PropTypes.array.isRequired
};

export default Checklist;
