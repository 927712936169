import PropTypes from 'prop-types';
import Countdown from 'components/ui/countdown/countdown';
import TaskIntro from '../task-intro/task-intro';
import './image.scss';

const Image = ({managerId, deviceInfo, taskData, endDelay}) => {

	return (
		<div 
			className={'Image' + (taskData.layout ? ' ' + taskData.layout : '')
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}
		>
			{taskData.subtype === 'countdown' && 
				<div className='Image-countdown'>
					<Countdown 
						delayTime={taskData.delayTime} 
						shownDelay={taskData.shownDelay} 
						onCountdown={endDelay}
					/>
				</div>
			}
			{taskData.layout && <div className="Image-image" />}
			{taskData.text && <div className="Image-intro">
				<TaskIntro 
					text={taskData.text} 
					image={taskData.image} 
					showManager={taskData.showManager}
					managerId={managerId}
					managerPose={taskData.managerPose}
				/>
			</div>}
		</div>
	);
};

Image.propTypes = {
	managerId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	endDelay: PropTypes.func.isRequired
};

export default Image;
