import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import Loading from 'components/loading/loading';
import PlayerController from 'components/users/player-controller';
import FacilitatorController from 'components/users/facilitator-controller';
import AdminController from 'components/users/admin-controller';
import SelectSection from 'components/select-section/select-section';
import ImageLoader from 'components/ui/image-loader/image-loader';

class UserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errMsg: null,
			authData: null,
			userData: null,
			section: null
		};
		this.unsubscribeUserData = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get auth data */
		let authData = null;
		if (this.props.accounts && this.props.accounts.length > 0) {
			const userAccount = this.props.accounts[0];
			authData = {
				id: (userAccount.idTokenClaims && userAccount.idTokenClaims.userObjectId 
					? userAccount.idTokenClaims.userObjectId : null),
				name: userAccount.name,
				email: (userAccount.idTokenClaims && userAccount.idTokenClaims.emailAddress
					? userAccount.idTokenClaims.emailAddress.toLowerCase() : null)
			};
		}

		/* Check if user exists */
		if (authData && authData.id) {
			this.setState({authData}, () => {
				const db = firebase.firestore();
				db.collection(appConfig.usersDbName).doc(authData.id).get().then((doc) => {
					if (doc.exists) {
						/* User exists, subscribe to their data */
						this.subscribeToUserData().then((response) => {
							if (response.status === 'ok') {
								
								this.setState({isLoading: false}, () => {
									/* Update name and last login */
									this.updateUser({
										email: this.state.authData.email,
										lastLogin: dayjs(new Date()).format('YYYY-MM-DD')
									});			
								});
							} else {
								// TODO: probably connection error? show err msg?
								this.setState({isLoading: false});
							}
						});
					} else {
						/* User does not exist */
						const db = firebase.firestore();
						db.collection(appConfig.gamesDbName).get().then((querySnapshot) => {	
							let gamesData = [];
							querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
							/* Get role of new user */
							let userRole = 'player';
							if (gamesData.some((gameData) => {
								return (
									gameData.facilitatorEmails && 
									gameData.facilitatorEmails.length > 0 &&
									gameData.facilitatorEmails.indexOf(this.state.authData.email) >= 0
								);
							})) {
								userRole = 'facilitator';
							}

							/* Create user and subscribe to their data */
							this.createNewUser(userRole).then(() => {
								this.subscribeToUserData().then((response) => {
									if (response.status === 'ok') {
										this.setState({isLoading: false});
									} else {
										// TODO: probably connection error? show err msg?
										this.setState({isLoading: false});
									}
								});
							}).catch((err) => {
								// TODO
								console.error(err);
							});
						});
						
					}
				});
			});
		} else {
			// TODO - handle this situation
			console.error(this.props.accounts);
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel subscribtions */
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();
	};

	/**
	 * Create new user
	 * @returns 
	 */
	createNewUser = (userRole) => {
		const userObj = {
			email: this.state.authData.email,
			games: [],
			name: this.state.authData.name,
			role: userRole,
			lastLogin: dayjs(new Date()).format('YYYY-MM-DD'),
			created: dayjs(new Date()).format('YYYY-MM-DD'),
		};
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(this.state.authData.id).set(userObj);
	};

	/**
	 * Update user data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update player data */
		const userId = this.state.userData.id;
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};


	/**
	 * Subscribe to user data
	 * @returns {Promise}
	 */
	subscribeToUserData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();

		/* Subscribe to user data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUserData = db.collection(appConfig.usersDbName).doc(this.state.authData.id)
				.onSnapshot((doc) => {
					if (doc.exists) {
						/* Save user data to state */
						const userData = {id: doc.id, ...doc.data()};
						this.setState({userData}, () => {resolve({status: 'ok'});});
					} else {
						/* User does not exist in database */
						resolve({status: 'ok'});
					}
				},
				(error) => {
					/* Error: Could not get user data */
					console.error('Could not get user data: ', error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/**
	 * Go to section (admin / facilitator / game)
	 * @param {string} section 
	 */
	handleGoToSection = (section) => {
		this.setState({section});
	};


	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-user-data" deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Auth error */
		if (!this.state.userData) {
			console.error(this.props.accounts);
			console.error(this.state.authData);
			return (
				<div>
					<p>User issues</p>
					<button onClick={() => {this.props.handleLogout();}}>Logout</button>
				</div>
			);
		}

		/* TODO: connection error */
		
		
		/* Component depends on user role */
		let Component = PlayerController;

		if (
			this.state.userData.role === 'facilitator' || 
			this.state.userData.role === 'admin' ||
			this.state.userData.isCoFacilitator === true
		) {
			/* Co-facilitator / facilitator / admin can navigate to different sections */
			Component = SelectSection;
			if (this.state.section === 'game') Component = PlayerController;
			if (this.state.section === 'facilitator') Component = FacilitatorController;
			if (this.state.section === 'admin') Component = AdminController;
		}
		
		return (
			<>
				<Component 
					deviceInfo={this.props.deviceInfo}	
					authData={this.state.authData}
					userData={this.state.userData}
					scrollToTop={this.props.scrollToTop}
					handleGoToSection={this.handleGoToSection}
					handleLogout={this.props.handleLogout} 
				/>
				{this.state.section && <ImageLoader type={'basic-' + this.state.section} />}
			</>
		);
	};
}

UserController.propTypes = {
	accounts: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default UserController;
