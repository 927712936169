import {useState} from 'react';
import PropTypes from 'prop-types';
import './select.scss';

const Select = (props) => {
	const {
		isDisabled = false,
		canSearch = false, 
		canAddOption = false, 
		type, 
		placeholderText = null, 
		noMatchText = null, 
		defaultId = null, 
		selectedId = null, 
		options, 
		onSelect
	} = props;

	/* Dropdown */
	let [dropdownIsOpen, setDropDown] = useState(false);
	const toggleDropdown = () => {
		if (isDisabled) return;
		setDropDown(!dropdownIsOpen);
	};

	/* Select option from list / submit new option */
	const handleSelect = (optionId, isNewOption) =>  {
		if (isDisabled) return;
		onSelect(optionId, isNewOption);
		setNewOptionText('');
		toggleDropdown();
	};

	/* New option input */
	const [newOptionText, setNewOptionText] = useState('');
	const updateInput = (event) => {
		setNewOptionText(event.target.value);
	};

	/* Selected / default option */
	const selectedOption = options.find((option) => {return option.id === selectedId;});
	const defaultOption = options.find((option) => {return option.id === defaultId;});

	/* Filtered options */
	let filteredOptions = [...options];
	if (canSearch && newOptionText) {
		filteredOptions = options.filter((option) => {
			return (option.value && (option.value.toLowerCase().includes(newOptionText.toLowerCase())));
		});
	}

	return (
		<div className={'Select ' + type 
			+ (canSearch ? ' searchable' : '')
			+ (dropdownIsOpen ? ' open' : '') 
			+ (isDisabled ? ' disabled' : '')
		}>
			<div className="Select-panel">
				{canSearch && 
					<form onSubmit={(e) => {e.preventDefault(); if (canAddOption) handleSelect(newOptionText, true);}}>
						<input className="Select-input" 
							name="tag"
							value={newOptionText ? newOptionText : ''}
							placeholder={placeholderText}
							onFocus={() => {setDropDown(true);}}
							onBlur={() => {setDropDown(false);}}
							onChange={(e) => {updateInput(e);}}
						/>
					</form>
				}
				{!canSearch && 
					<div className="Select-selected" onClick={() => {toggleDropdown();}}>
						<span>{(selectedOption 
							? selectedOption.value 
							: defaultOption 
								? defaultOption.value 
								: placeholderText 
									? placeholderText 
									: ''
						)}</span>
					</div>
				}
			</div>
			<div className="Select-dropdown">
				{filteredOptions.map((option) => {
					return (
						<div 
							key={option.id} 
							className={'Select-option' 
								+ (option.id === selectedId ? ' selected' : '') 
								+ (option.class ? ' ' + option.class : '')
							}
							onMouseDown={() => {handleSelect(option.id, false);}}
							// if we use onClick it will trigger onBlur first,
							// and then onClick will never be triggered, 
							// onMouseDown is triggered before onBlur
						>
							<span>{option.value}</span>
						</div>
					);
				})}
				{(filteredOptions.length === 0 && canSearch && newOptionText.length > 0 && noMatchText) && 
					<div className="Select-noMatch"><span>{noMatchText}</span></div>} 
			</div>

		</div>
	);
};

Select.propTypes = {
	isDisabled:PropTypes.bool, 
	canSearch: PropTypes.bool,
	canAddOption: PropTypes.bool,
	type: PropTypes.string.isRequired,
	placeholderText: PropTypes.string,
	noMatchText: PropTypes.string,
	defaultId: PropTypes.string,
	selectedId: PropTypes.string,
	options: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired
};

export default Select;