const moduleFloral2 = [
	
	'modules/tasks/multiple-choice/floral-2-clocks/option-1.svg',
	'modules/tasks/multiple-choice/floral-2-clocks/option-2.svg',
	'modules/tasks/multiple-choice/floral-2-clocks/option-3.svg',
	'modules/tasks/multiple-choice/floral-2-clocks/option-4.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-1.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-2.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-3.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-4.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-5.svg',
	'modules/tasks/multiple-choice/floral-2-flowers/option-6.svg',
	'modules/tasks/multiple-choice/floral-2-stems/option-1.svg',
	'modules/tasks/multiple-choice/floral-2-stems/option-2.svg',
	'modules/tasks/multiple-choice/floral-2-stems/option-3.svg',
	'modules/tasks/multiple-choice/floral-2-stems/option-4.svg',
	'modules/tasks/multiple-choice/floral-2-where-roses/option-1.svg',
	'modules/tasks/multiple-choice/floral-2-where-roses/option-2.svg',
	'modules/tasks/multiple-choice/floral-2-where-roses/option-3.svg',
	'modules/tasks/multiple-choice/floral-2-where-roses/option-4.svg',

	'modules/tasks/organize/floral-2-bouquets/bouquet1.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet2.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet3.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet4.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet5.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet6.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet7.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet8.svg',
	'modules/tasks/organize/floral-2-bouquets/bouquet9.svg',
	'modules/tasks/organize/floral-2-bouquets/pot.svg',
	'modules/tasks/organize/floral-2-bouquets/shelves.svg',

	'modules/tasks/spot-errors/floral-2-dangers.svg'
];

export {
	moduleFloral2
};
