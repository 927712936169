import PropTypes from 'prop-types';
import './character-item.scss';

const CharacterItem = ({character, item, managerId}) => {
	return (
		<div className={'CharacterItem ' + character + ' ' + item + (managerId ? ' manager-' + managerId : '')} />
	);
};

CharacterItem.propTypes = {
	character: PropTypes.string.isRequired,
	item: PropTypes.string.isRequired,
	managerId: PropTypes.string,
};

export default CharacterItem;
