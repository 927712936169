import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Checklist from '../../../ui/checklist/checklist';
import './branching-story-checklist.scss';

const BranchingStoryChecklist = ({taskId, storyStepData, storyArr, managerId}) => {
	if (!storyStepData) return null;
	
	return (
		<div className={'BranchingStoryChecklist ' + taskId}>
			{/* Title and text */}
			{(storyStepData.title || storyStepData.text) && 
				<div className={'BranchingStoryChecklist-textBox' 
					+ (storyStepData.textType === 'speech' ? ' bubble' : '') 
					+ (storyStepData.image ? ' ' + storyStepData.image : '')
				}>
					{storyStepData.title && 
						<div className="BranchingStoryChecklist-title">{storyStepData.title}</div>}
					{storyStepData.text && 
						<div className="BranchingStoryChecklist-text">{renderMarkdown(storyStepData.text)}</div>}
					{storyStepData.image && 
						<div className={'BranchingStoryChecklist-image'}/>
					}
					{storyStepData.checklistData && <div className="BranchingStoryChecklist-checklist">
						<Checklist 
							checklistData={storyStepData.checklistData} 
							selectedOptions={storyArr} 
						/>
					</div>}
				</div>
			}			
		</div>
	);
};

BranchingStoryChecklist.propTypes = {
	taskId: PropTypes.string.isRequired,
	managerId: PropTypes.string.isRequired,
	storyArr: PropTypes.array.isRequired,
	storyStepData: PropTypes.object.isRequired
};

export default BranchingStoryChecklist;
