import React from 'react';
import PropTypes from 'prop-types';
import {mapUiTexts} from 'data/ui-texts';
import BackIcon from 'assets/images/modules/icon-back.svg';
import Avatar from 'components/game/avatar/avatar';
import './navigation-top.scss';

const NavigationTop = ({type, deviceInfo, gameData, playerData, handleGoToPage}) => {
	/* Go back */
	const handleGoBack = () => {
		if (type === 'map') {
			handleGoToPage('areas');
		} else {
			handleGoToPage('splash');
		}
	};

	return (
		<div className={'NavigationTop-navigation ' + type + (deviceInfo ? ' ' + deviceInfo.orientation : '')}>
			{/* Nav item: back */}
			<div className="NavigationTop-navItem back" onClick={() => {handleGoBack();}}>
				<div className="NavigationTop-navItemIcon">
					<img src={BackIcon} alt={mapUiTexts.back} />
				</div>
				<div className="NavigationTop-navItemLabel">{mapUiTexts.back}</div>
			</div>
			
			{/* Nav item: profile */}
			<div className="NavigationTop-navItem avatar" onClick={() => {handleGoToPage('profile');}}>
				<div className="NavigationTop-navItemIcon">
					<Avatar type="mapNav" playerData={playerData} />
				</div>
			</div>
		</div>
	);
};

NavigationTop.propTypes = {
	type: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
};

export default NavigationTop;
