import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import {gameUiTexts} from 'data/ui-texts';
import {avatarData} from 'data/avatar-data';
import {paperDollPoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import Avatar from 'components/game/avatar/avatar';
import Button from 'components/ui/button/button';
import './paper-doll.scss';

const PaperDoll = (props) => {
	const {
		areaId,
		deviceInfo,
		playerData, 
		playerTaskData, 
		taskData,
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask,
		isFacilitator = false,
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track status and selected items */
	const [selectedItemIds, setSelectedItemIds] = useState([]);
	const [selectEffectsSeen, setSelectEffectsSeen] = useState([]);
	const [deselectEffectsSeen, setDeselectEffectsSeen] = useState([]);

	/**
	 * Get selected item ids
	 * @returns {array} itemIds
	 */
	const getSelectedItemIds = () => {
		let itemIds = [];
		if (playerTaskData && playerTaskData.selectedItemIds) {
			itemIds = [...playerTaskData.selectedItemIds];
		}
		return itemIds;
	};

	/* New task: update status, spotted errors, and countdown  */
	useEffect(() => {
		setSelectedItemIds(getSelectedItemIds());
		setSelectEffectsSeen([]);
		setDeselectEffectsSeen([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Check if an item is required
	 * @param {object} item 
	 * @returns 
	 */
	const checkIfItemIsRequired = (item) => {
		let itemIsRequired = false;
		if (item.isCorrect) {
			itemIsRequired = true;
			/* Check if there are any conditions */
			if (item.correctConditions && item.correctConditions.length > 0) {
				/* Check if all conditions are met */
				item.correctConditions.forEach((condition) => {
					/* Return if a condition has not been met */
					if (!itemIsRequired) return;

					/* Condition: avatar is using something in a specific category, e.g. a beard */
					if (condition.type === 'avatar-category-in-use' && condition.categoryId) {
						if (
							!playerData.avatar ||
							!playerData.avatar[condition.categoryId] ||
							playerData.avatar[condition.categoryId] === 'none'
						) {
							/* Condition is not met */
							itemIsRequired = false;
						}
					}
				});
			}
		}
		return itemIsRequired;
	};

	/**
	 * Toggle item
	 * @param {string} itemId 
	 * @returns 
	 */
	const toggleItem = (itemId) => {
		/* Facilitator cannot solve task */
		if (isFacilitator) return;

		/* Already completed */
		if (isCompleted) return;

		/* Update logged time */
		updateLoggedTime();

		/* Toggle item id */
		let newSelectedItemIds = [...selectedItemIds];
		let newSelectEffectsSeen = [...selectEffectsSeen];
		let newDeselectEffectsSeen = [...deselectEffectsSeen];
		const itemData = taskData.items.find((i) => {return i.id === itemId;});
		const itemIndex = newSelectedItemIds.indexOf(itemId);
		let effects = [];
		if (itemIndex < 0) {
			/* Select item */
			newSelectedItemIds.push(itemId);
			if (itemData.selectEffects.length > 0 && selectEffectsSeen.indexOf(itemId) < 0) {
				effects = itemData.selectEffects;
				newSelectEffectsSeen.push(itemId);
			}
		} else {
			/* De-select item */
			newSelectedItemIds.splice(itemIndex, 1);
			if (itemData.deselectEffects.length > 0 && deselectEffectsSeen.indexOf(itemId) < 0) {
				effects = itemData.deselectEffects;
				newDeselectEffectsSeen.push(itemId);
			}
		}
		setSelectedItemIds(newSelectedItemIds);
		setSelectEffectsSeen(newSelectEffectsSeen);
		setDeselectEffectsSeen(newDeselectEffectsSeen);
		
		/* Effects */
		if (effects.length > 0) handleInstantTaskEffects(effects);		
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {		
		/* Errors */
		let numberOfCorrectItems = 0;
		let missedItems = [];
		let excessItems = [];
		taskData.items.forEach((item) => {
			const itemIsRequired = checkIfItemIsRequired(item);
			if (itemIsRequired) {
				numberOfCorrectItems += 1;
				if (selectedItemIds.indexOf(item.id) < 0) missedItems.push(item.title);	
			} else {
				if (selectedItemIds.indexOf(item.id) >= 0) excessItems.push(item.title);
			}
		});
		const errors = missedItems.length + excessItems.length;

		/* Calculate points */
		let points = paperDollPoints.minPoints;
		let pointIndex = paperDollPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = paperDollPoints.pointValues[pointIndex];

		/* Result popup */
		let popupText = gameUiTexts.paperDollPopup.text1;
		if (missedItems.length > 0) {
			popupText = gameUiTexts.paperDollPopup.text2;
			popupText = popupText.replace('%correctItems%', numberOfCorrectItems - missedItems.length);
			missedItems.forEach((item, index) => {
				popupText += item + (index < missedItems.length - 1 ? ', ' : '.');
			});	
		}
		if (excessItems.length > 0) {
			popupText += '<br /><br />' + gameUiTexts.paperDollPopup.notNeeded;
			excessItems.forEach((item, index) => {
				popupText += item + (index < excessItems.length - 1 ? ', ' : '.');
			});	
		}

		/* Effects */
		let effects = [
			{
				type: 'popup',
				popup: {
					type: 'paper-doll-result',
					text: popupText
				}
			},
			{
				type: 'streak',
				isCorrectAnswer: (missedItems.length === 0 && excessItems.length === 0)
			}
		];
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, errors);
				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						effects.push(effect);
					}
				}
			});
		}
	
		/* Save completed task */
		handleCompleteTask(
			'paper-doll', 
			points, 
			errors, 
			effects,
			{selectedItemIds: selectedItemIds}
		);
	};


	/* Get body type, use default if player has not  */
	const bodyType = (playerData.avatar && playerData.avatar['body-type'] 
		? playerData.avatar['body-type']
		: avatarData.categories[0].options[0].id
	);

	return (
		<div className={'PaperDoll ' + taskData.taskId + ' ' + areaId 
			+ (isCompleted ? ' completed' : '')
			+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}
		>
			{/* Avatar */}
			<div className="PaperDoll-avatar">
				<Avatar type="paper-doll" playerData={playerData} />
			</div>

			{/* Selected items in place */}
			<div className="PaperDoll-selectedItems">
				{taskData.items.map((itemData, index) => {
					const itemIsRequired = checkIfItemIsRequired(itemData);
					if (isCompleted && !itemIsRequired) return null;
					if (!isCompleted && selectedItemIds.indexOf(itemData.id) < 0) return null;
					let image = null;
					let zIndex = 1;
					zIndex = (index + 1);
					let suffix = 'a';
					if (itemData.type === 'alternative') suffix = 'b';
					if (itemData.type === 'bodytype') {
						suffix = 'm-b';
						if (bodyType && (bodyType === 'f' || bodyType === 'f2')) suffix = 'f-b';
					}
					try {
						image = require('assets/images/modules/tasks/paper-doll/' 
							+ itemData.id + '-' + suffix + '.svg');
					} catch (e) {
						console.error('image not found: ' + itemData.id);
					}

					return (
						<div 
							key={itemData.id}
							style={{zIndex: zIndex}}
							className={
								'PaperDoll-item ' + itemData.id + (bodyType ? ' ' + bodyType : '')
									+ (itemData.dependsOnBodyHeight ? ' height' : '')
									+ (itemData.dependsOnBodyWidth ? ' width' : '')
							}
							onClick={() => {if (!isCompleted) toggleItem(itemData.id);}} 
						>{image && <ReactSVG src={image}/>}</div>
					);
				})}
			</div>

			{/* Toolbox */}
			{taskData.showToolbox && <div className="PaperDoll-tools" />}

			{/* Availble items */}
			<div className="PaperDoll-panel">
				<div className="PaperDoll-panelHeader" />
				<div className="PaperDoll-availableItems">
					{taskData.items.map((item) => {
						let image = null;
						try {
							image = require('assets/images/modules/tasks/paper-doll/' + item.id + '-a.svg');
						} catch (e) {
							console.error('image not found: ' + item.id);
						}
						return (
							<div 
								key={item.id} 
								className={'PaperDoll-item ' + item.id 
									+ (selectedItemIds.indexOf(item.id) >= 0 ? ' selected' : '')}
								onClick={() => {toggleItem(item.id);}} 
							>
								{image && <ReactSVG src={image}/>}
							</div>
						);
					})}
				</div>
			</div>
			
			{/* Done button */}
			{!isCompleted && !isFacilitator && 
				<div className="PaperDoll-confirmBtn">
					<Button 
						classes={['blue', 'done', 'small']} 
						text={gameUiTexts.done} 
						onClick={() => {completeTask();}}
					/>
				</div>
			}
		</div>
	);
};

PaperDoll.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	areaId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default PaperDoll;
