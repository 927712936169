const insightsData = [
	{
		id: 'produce-1-most-mistakes',
		type: 'most-mistakes-module',
		numberOfPlayers: 19,
		moduleId: 'produce-1',
		title: `Produce 1 biggest hurdle`,
		text: `10 players have completed Produce 1. This is the task they made the most mistakes in.`,
		action: `Talk to your employees about the task in question and/or consider new routines.`
	},
	{
		id: 'orientation-stars',
		type: 'stars-module',
		numberOfPlayers: 10,
		numberOfStars: 2,
		moduleId: 'orientation-1',
		title: `Stars on the rise in Orientation`,
		text: `10 players have done well enough to earn at least 2 stars in Orientation.`,
		action: `Congratulate them on a job well done and remind them to continue to their department specific modules.`
	},
];




export {
	insightsData,
};
