import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {insightsData} from 'data/insights-data';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import FacilitatorMutedInsightsPopup from './facilitator-muted-insights-popup/facilitator-muted-insights-popup';
import Button from 'components/ui/button/button';
import './facilitator-insights.scss';

const FacilitatorInsights = (props) => {
	const {
		userData, 
		coFacilitatorData,
		pageData, 
		gameData, 
		toggleModulePopup, 
		deleteInsightNotification, 
		updateMutedInsights,
		resetInsights,
		handleGoToPage
	} = props;

	/* Track if deleting */
	const [isDeleting, setIsDeleting] = useState(false);

	/* Track insights marked for muting */
	const [mutedInsightIds, setMutedInsightIds] = useState([]);

	/**
	 * Toggle if insight is muted
	 * @param {string} insightId 
	 */
	const toggleMutedInsight = (insightId) => {
		let newMutedInsightIds = JSON.parse(JSON.stringify(mutedInsightIds));
		const insightIndex = newMutedInsightIds.indexOf(insightId);
		if (insightIndex >= 0) {
			newMutedInsightIds.splice(insightIndex, 1);
		} else {
			newMutedInsightIds.push(insightId);
		}
		setMutedInsightIds(newMutedInsightIds);
	};

	/* Handle delete insight notification */
	const handleDeleteInsightNotification = (insightId, notificationIndex) => {
		if (isDeleting) return;

		setIsDeleting(true);
		const isMuted = mutedInsightIds.indexOf(insightId) >= 0;
		deleteInsightNotification(insightId, notificationIndex, isMuted).then((response) => {
			if (response.status === 'success') {
				setIsDeleting(false);
			} else {
				console.error(response);
				setIsDeleting(false);
			}
		});
	};

	/* Track if showing muted insights popup */
	const [showMutedPopup, setShowMutedPopup] = useState(false);
	const handleUpdateMutedInsights = (mutedInsightIds) => {
		updateMutedInsights(mutedInsightIds).then((response) => {
			if (response.status === 'success') {
				setShowMutedPopup(false);
				setMutedInsightIds([]);
			} else {
				console.error(response);
				setShowMutedPopup(false);
				setMutedInsightIds([]);
			}	
		});
	};

	/* Get all insight notifications that have not been deleted by facilitator */
	let notifications = [];
	if (gameData.insights && gameData.insights.length > 0) {
		gameData.insights.forEach((insight) => {
			if (!insight.mutedBy || insight.mutedBy.indexOf(userData.email) < 0) {
				/* Only include insights that the facilitator has not muted */
				if (insight.notifications) {
					insight.notifications.forEach((notification, index) => {
						if (notification.deletedBy.indexOf(userData.email) < 0) {
							/* Only include notifications that the facilitator has not deleted */
							notifications.push({
								insightId: insight.insightId, 
								notificationIndex: index, 
								...notification
							});
						};
					});
				}
			}
		});
	}

	/* Sort by created date (newest first) */
	notifications = sortArrayByProperty(notifications, 'created', 'ASC');

	return (
		<div className="FacilitatorInsights">
			{/* Header */}
			<FacilitatorHeaderSubpage 
				page={pageData.id} 
				subpage="insights"
				subpageIds={pageData.subpageIds}
				handleGoToPage={handleGoToPage}
			/>
			{/* Notifications */}
			<div className="FacilitatorInsights-notifications">
				{notifications.map((notification) => {
					const created = (notification.created ? dayjs(notification.created).format('MM/DD-YY') : '-');
					const insightData = insightsData.find((i) => {return i.id === notification.insightId;});
					return (
						<div 
							key={notification.insightId + '-' + notification.notificationIndex} 
							className="FacilitatorInsights-notification"
						>
							<div className="FacilitatorInsights-notificationHeader">
								<div className="FacilitatorInsights-notificationTitle">
									<span>{insightData.title}</span>
								</div>
								<div className="FacilitatorInsights-notificationCreated">
									<span>{created}</span>
								</div>
								{!coFacilitatorData && <div 
									className="FacilitatorInsights-deleteNotificationBtn" 
									onClick={() => {
										handleDeleteInsightNotification(
											notification.insightId, notification.notificationIndex
										);
									}}
								/>}
							</div>
							<div className="FacilitatorInsights-notificationBody">
								<div className="FacilitatorInsights-notificationText">
									{renderMarkdown(insightData.text)}
								</div>
								{insightData.type === 'most-mistakes-module' &&
									<div className="FacilitatorInsights-notificationTaskInfo">
										<span onClick={() => {
											toggleModulePopup(insightData.moduleId, notification.taskMostErrors.id);
										}}>
											{notification.taskMostErrors.id}
										</span>
									</div>
								}
								{insightData.type === 'stars-module' &&
									<div className="FacilitatorInsights-notificationPlayers">
										{notification.playerNames.map((name, index) => {
											return (
												<span key={index}>
													{name + 
														(index === notification.playerNames.length - 1 ? '.' : ', ')}
												</span>
											);
										})}
									</div>
								}
								<div className="FacilitatorInsights-notificationAction">
									<div className="FacilitatorInsights-notificationActionTitle">
										<span>{facilitatorUiTexts.insights.action}</span>
									</div>
									<div className="FacilitatorInsights-notificationActionText">
										{renderMarkdown(insightData.action)}
									</div>
								</div>

								{!coFacilitatorData && <div 
									className={'FacilitatorInsights-mute' 
										+ (mutedInsightIds.indexOf(notification.insightId) >= 0 ? ' muted' : '')}
									onClick={() => {toggleMutedInsight(notification.insightId);}}
								>
									<span>{facilitatorUiTexts.insights.deleteNotificationPopup.muteNotifications}</span>
								</div>}
							</div>
						</div>
					);
				})}
			</div>

			{/* Btn: view muted insights */}
			<div className="FacilitatorInsights-viewMutedBtn">
				<Button 
					text={facilitatorUiTexts.insights.viewMuted}
					classes={['addGame']} 
					onClick={() => {setShowMutedPopup(true);}}
				/>
			</div>

			{/* Devtools for resetting insights */}
			{appConfig.showDevTools &&
				<div className="FacilitatorInsights-devTools">
					<Button 
						text={facilitatorUiTexts.insights.reset1}
						classes={['addGame']} 
						onClick={() => {resetInsights(false); setMutedInsightIds([]);}}
					/>
					<Button 
						text={facilitatorUiTexts.insights.reset2}
						classes={['addGame']} 
						onClick={() => {resetInsights(true); setMutedInsightIds([]);}}
					/>
				</div>
			}

			{/* Muted insights popup */}
			{showMutedPopup && <FacilitatorMutedInsightsPopup 
				canEdit={!coFacilitatorData}
				userData={userData}
				gameData={gameData}
				setShowMutedPopup={setShowMutedPopup}
				handleUpdateMutedInsights={handleUpdateMutedInsights}
			/>}
		</div>
	);

};

FacilitatorInsights.propTypes = {
	userData: PropTypes.object.isRequired,
	coFacilitatorData: PropTypes.object,
	pageData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	toggleModulePopup: PropTypes.func.isRequired,
	deleteInsightNotification: PropTypes.func.isRequired,
	updateMutedInsights: PropTypes.func.isRequired,
	resetInsights: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default FacilitatorInsights;
