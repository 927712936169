import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './login.scss';

const Login = ({isLoggingIn, deviceInfo, handleLogin}) => {

	return (
		<div className={'Login ' + deviceInfo.orientation}>
			<div className="Login-logo"><Logo /></div>
			<div className="Login-buttons">
				<div className="Login-loginBtn">
					<Button 
						isLoading={isLoggingIn}
						classes={['login']} 
						text={loginUiTexts.login} 
						onClick={() => {handleLogin();}}
					/>
				</div>
				{appConfig.registrationUrl && <div className="Login-signupBtn">
					<Button 
						type="link"
						openInNewTab={false}
						classes={['login']} 
						text={loginUiTexts.signUp} 
						url={appConfig.registrationUrl + '?returnUrl=' +  window.location.href}
					/>
				</div>}
			</div>
			
		</div>
	);
};

Login.propTypes = {
	isLoggingIn: PropTypes.bool.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleLogin: PropTypes.func.isRequired
};

export default Login;
