const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		background: 'ocean-island',
		showAvatar: true,
		text: 'We made it, stranded on what looks like Produce Island.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'ocean-island',
		showAvatar: true,
		text: `To reach safety, we'll need to get all the way to the end of this chain of islands.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		showAvatar: true,
		text: 'Welcome to Produce Department',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-not-belong',
		text: 'What does not belong in this department?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Fruits and Vegetables',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Ready to eat platters',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Frozen Vegetables',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Bulk Candy',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Croutons',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Fresh Flowers',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-allergies',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: 'Speaking of peanuts, do you have any serious allergies?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Be sure to let your manager know.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Ok, good.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Unsure',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Be aware that some people have pretty severe allergies to peanuts.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'produce-1-paper-doll-dress',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full',
				type: 'bodytype',
				isCorrect: true, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Cut resistant gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'vegetables',
				isCorrect: true,
				title: 'Produce'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'book',
				isCorrect: true,
				title: 'PLU code book'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'peeler',
				isCorrect: true,
				title: 'Peeler'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'knife',
				isCorrect: true,
				title: 'Cutting knife'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-white',
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Chef\'s hat'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hair-net',
				dependsOnBodyHeight: true,
				isCorrect: true,
				title: 'Hair net'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Beard net',
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'baseball-bat',
				title: 'Baseball bat'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'knife3',
				title: 'Butter knife'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'fish',
				title: 'Fish'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'keys',
				title: 'Keys'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-where-to-start',
		text: 'Should we head to the back or start at the sales floor?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Sales Floor',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s good. Get customer view.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Backroom',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-1-spot-errors-prep-area',
		layout: 'produce-1-prep-area',
		text: 'Let\'s check the prep area. Click on the issues you see. Click ok when you think you have them all.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'grapes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'notes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'boxes'}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `Let's look at those scales.`,
		layout: 'produce-1-scales',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-1-spot-errors-scales',
		layout: 'produce-1-scales',
		text: 'Anything wrong here?',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'screen'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		showAvatar: true,
		text: 'The calendar says it is May but scales read June.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-what-to-do',
		text: 'What should we do?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Correct date on scales',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Fix the calendar',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Doesn\'t matter',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'It will correct automatically',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-before-we-open',
		text: 'Before we open, what should we do?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Cull',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Stock',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Prepare cut fruits',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Prepare cut vegetables',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Check backroom stock',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Our High Five\'s put Quality and Food Safety as priority.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-culling',
		text: 'Do you know what the culling process means?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Remove poor quality',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly, we want to keep our standards up.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Check for dating',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Cleaning the area',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Disposal of trash',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-true-false',
		text: 'We need to also fill out of stocks while culling?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'False',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No, we want to get out poor quality as soon as possible.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'True',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Produce goes in specific areas.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Now let\'s take a look at the dry produce table.',
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'produce-1-organize-dry',
		layout: 'produce-1-dry-table',
		text: `What items belong here?`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'dry-1', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'dry-2', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'dry-3', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'dry-4', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'dry-5', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'dry-6', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'dry-7', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 8, containerId: 'c8', image: 'dry-8', alt: 'dry', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 9, containerId: 'c9', image: 'cooled-1', alt: 'cooled', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 10, containerId: 'c10', image: 'cooled-2', alt: 'cooled', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 11, containerId: 'c11', image: 'cooled-3', alt: 'cooled', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 12, containerId: 'c12', image: 'cooled-4', alt: 'cooled', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 13, containerId: 'c13', image: 'cooled-5', alt: 'cooled', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 14, containerId: 'c14', image: 'cooled-6', alt: 'cooled', correctContainerIds: []
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 7}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves', finalItemId: 8}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c8', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c9', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c10', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c11', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c12', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c13', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c14', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'That\'s right some fruits need to be cooled and some do not. Same goes for vegetables. Let\'s look at the refrigerated racks now.'
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'produce-1-organize-cooled',
		layout: 'produce-1-cool-racks',
		text: `What items belong here?`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'dry-1', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'dry-2', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'dry-3', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'dry-4', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'dry-5', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'dry-6', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'dry-7', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 8, containerId: 'c8', image: 'dry-8', alt: 'dry', correctContainerIds: []
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 9, containerId: 'c9', image: 'cooled-1', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 10, containerId: 'c10', image: 'cooled-2', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 11, containerId: 'c11', image: 'cooled-3', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 12, containerId: 'c12', image: 'cooled-4', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 13, containerId: 'c13', image: 'cooled-5', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 14, containerId: 'c14', image: 'cooled-6', alt: 'cooled', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 9}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 10}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 11}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 12}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves', finalItemId: 13}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 14}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c8', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c9', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c10', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c11', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c12', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c13', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c14', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-problem-solver-2', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-start-make-ready',
		subtype: 'images',
		text: 'Specifically which item would we cull first?',
		layout: 'produce-1-produce-department',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Bananas', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, always start on the sales floor and usually with bananas. We want the customer's perspective and we can make use of our banana boxes.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Apple table',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,text: 'Cooler',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Misting Racks',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.'
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Before you Cull, let\'s review how well you know your vegetables.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-bell-peppers',
		text: 'How should Bell Peppers be displayed?',
		image: 'bell-pepper',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'All types firm and seperated', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Colored soft, Green firm',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Mixed together',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Green soft, Colored firm',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-broccoli',
		subtype: 'images',
		text: 'How about Broccoli & Cauliflower?',
		layout: 'produce-1-broccoli',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, these look the healthy.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-corn',
		text: 'Let\'s talk corn. Should an ear of corn be lighter or heavier?',
		image: 'corn-cob',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Heavier', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's right, it means it has more moisture.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Lighter',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Lighter is not usually better in produce.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-green-beans',
		text: 'Green Beans should snap when you bend them.',
		image: 'haricot-steamed',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, and watch out for molding.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `If they bend they are not good.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-good-leafy',
		subtype: 'images',
		text: 'Which of these are good leafy greens?',
		layout: 'produce-1-good-leafy',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Lettuces should keep shape.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-lettuce',
		text: 'Where is the best place to check heads of lettuce?',
		image: 'lettuce',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'The stem on bottom',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'The outer most leaves',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'The core',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Inside the first two leaves',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-mushroom',
		text: 'If mushrooms start to turn, they smell like…',
		image: 'mushroom',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'A fish',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'A leather shoe',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Garbage',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Something sweet',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-jalapeno',
		subtype: 'images',
		text: 'Which of Jalapeno would be hottest?',
		layout: 'produce-1-jalapeno',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `These stripes means it's hotter.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-root-vegetable',
		text: 'For root vegetables, the best place to check ripeness is…',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'The root',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'The stalk',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'The chewy center',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'The sides',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-green-potato',
		text: 'Potatoes that are green, are…',
		image: 'potato-green',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Toxic',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Almost ripe',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Potatoes don\'t turn green',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Barely out of date',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-break-down-potato',
		text: 'What breaks down potatoes faster?',
		image: 'potato',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Halogen lights',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Exactly, let's cover at night.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Air circulation',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Bagging at night',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Actually, they should be covered at night.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['wand-1'],
		items: ['bin-of-tomatoes'],
		text: 'Let me show you a trick?'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-flies',
		subtype: 'images',
		text: 'Ever tap on one of these? You try.',
		layout: 'produce-1-flies',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `Look at that. You made flies.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `Tap on the bin.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `Tap on the bin.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		text: 'Flies means we have some rotten tomatoes. Let\'s find them.'
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-1-spot-errors-bad-tomatoes',
		layout: 'produce-1-bad-tomatoes',
		text: 'Take out all the bad ones.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tomato-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tomato-2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tomato-3'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Let\'s look at some fruit items.'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'How about apples, those are easy right? There can\'t be that many types, can there?'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-apple-types',
		text: 'How many different types of apples are there?',
		image: 'apples',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '7',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '12',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '25',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '35+',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `There is a lot, but we only carry about 7 kinds.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-apple-pies',
		text: 'Which of these are best for cooking pies?',
		image: 'checklist3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Red Delicious',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Granny Smith',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'McIntosh',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Golden Delicious',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'These fare ok in the oven, but aren\'t usually the preferred choice.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Fuji',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Honeycrisp',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Gala',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'These fare ok in the oven, but aren\'t usually the preferred choice.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'You are about halfway through the module now. Onward to the melons!'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-honeydew-melon',
		subtype: 'images',
		text: 'Which one of these is a honeydew melon?',
		layout: 'produce-1-melons',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'watermelon'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'honeydew', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'cantaloupe'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'pineapple'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-cantaloupe',
		subtype: 'images',
		text: 'Which one of these is a cantaloupe?',
		layout: 'produce-1-melons',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'watermelon'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'honeydew'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'cantaloupe', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'pineapple'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-watermelon',
		subtype: 'images',
		text: 'Which one of these is a watermelon?',
		layout: 'produce-1-melons',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'watermelon', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'honeydew'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'cantaloupe'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'pineapple'})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'On to something a little more difficult.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-cabbage-head',
		subtype: 'images',
		text: 'Which one of these is a head of cabbage?',
		layout: 'produce-1-leafy-greens',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'cabbage', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'iceberg'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'spinach-loose'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'romaine'})
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-1', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-romaine-lettuce',
		subtype: 'images',
		text: 'Which one is romaine lettuce?',
		layout: 'produce-1-leafy-greens',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'cabbage'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'iceberg'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'spinach-loose'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'romaine', isCorrect: true})
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-2', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-sweet-onion',
		subtype: 'images',
		text: `Let's look at onions. Which of these is not an onion?`,
		layout: 'produce-1-onions-1',
		shuffleOptions: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'white onion'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'red onion'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'leek'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'yellow onion'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'garlic', isCorrect: true}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-3', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-white-onion',
		subtype: 'images',
		text: 'And which one is a white onion?',
		layout: 'produce-1-onions-2',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'white onion', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'red onion'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'leek'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'yellow onion'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'garlic'}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-4', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-russet-potato',
		subtype: 'images',
		text: 'On to potatoes to see how good you are. Which one is a russet potato?',
		layout: 'produce-1-potatoes-1',
		shuffleOptions: true,
		options: [ 
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'russet potato', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'sweet potato'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'yukon gold potato'}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-5', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-sweet-potato',
		subtype: 'images',
		text: 'Which one is a sweet potato?',
		layout: 'produce-1-potatoes-2',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'Jerusalem artichoke'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'russet potato'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'french fry'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'yukon gold potato'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'sweet potato', isCorrect: true}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-6', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-multiple-choice-yukon-gold-potato',
		subtype: 'images',
		text: 'And how about a yukon gold potato?',
		layout: 'produce-1-potatoes-3',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'aspargeskartoffel'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'yukon gold potato', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'french fry'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'Jerusalem artichoke'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'russet potato'}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'produce-1-produce-machine-7', pointId: 'produce-machine', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Good work so far.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-identify-produce',
		text: 'What else can identify produce?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'PLU',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Planogram'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Pictures',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Taste'
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-high-five',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'high-five',
		text: 'Let\'s do some Tagging!',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Oh ya!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You missed.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		animateCharacter: true,
		characterItems: ['sunglasses-1'],
		text: 'First you have to be COOL to tag.'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'No, we use COOL tagging.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-COOL',
		text: 'What does COOL stand for?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Country of Origin Labeling',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Counting Out of Line'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Correct, Observe, Out of Stock, Label',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Cost On or Off Label'
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-COOL-violation',
		text: 'How much does each COOL violation fine?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Up to $1,000',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Per violation too..'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Up to $100'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Up to $10,000',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Up to $10'
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-what-COOL',
		text: 'What does COOL apply to?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Both fruits and vegetables',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Fruits'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Vegetables',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Neither'
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-identify-orange-type',
		text: 'First, what label identifies the type of orange it is?',
		subtype: 'images',
		layout: 'produce-1-orange-type',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'PLU code', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! PLU codes help identify produce easily.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Sign',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That assumes we know it's in the right bin, try again.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Country of origin label',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-orange',
		text: 'Where is this orange from?',
		image: 'orange1',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Harvested in South Africa',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Harvested in USA, but seeds from South Africa',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'USA, but trees are shipped from South Africa',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Species originates from South Africa, but can be grown in USA',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-orange-put',
		text: 'Here\'s another orange. Can you put it with the others?',
		image: 'orange3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Yes',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Look a little closer at the stickers.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'No',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. That PLU is 5 digits. This is an ORGANIC orange.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `It's the same orange.`,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Look a little closer at the stickers.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-good-produce',
		text: 'Generally, what do we look for in good produce?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Firm, vibrant, slight smell',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Firm, vibrant, strong smell',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Soft, vibrant, strong smell`,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Moving on its own`,
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-bananas-mark-down',
		text: 'Which of these bananas are ready to be marked down?',
		subtype: 'images',
		layout: 'produce-1-bananas-mark-down',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. This Banana is past its prime.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This will still ripen.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This one is ready to be removed, not sold.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-berries',
		text: 'What ruins berries the most?',
		image: 'berries',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Mold',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. Look for signs of leaks.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Insects',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Sunlight`,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Yogurt`,
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-oranges-ripen-vs-bananas',
		text: 'Oranges ripen faster than bananas once picked.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'False',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Most citrus stop ripening after it's picked.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'True',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-exotic-fruits',
		text: 'Do you know which of these are considered Exotic fruits?',
		subtype: 'images',
		layout: 'produce-1-exotic-fruits',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s a Dragon Fruit.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s a Passion Fruit.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's a Star Fruit.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's a pineapple, but it's not exotic.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-heavy-melon',
		text: 'Why is a good melon heavy?',
		image: 'melon',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'It\'s juicy',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'More for the money',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Customer expects it',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Thick firm outside',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-good-pear',
		text: 'This pear is still good.',
		image: 'pear',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'True',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Brown spots indicate ripeness.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'False',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Pears are soft, but a pear you can poke into is too old.'
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-1-spot-errors-pineapple-sniff',
		layout: 'produce-1-pineapple-sniff',
		text: 'Where to smell for a good pineapple?',
		resultOverwrites: {
			result1: {
				text2: 'You missed the correct answer.'
			},
			result3: {
				text2: 'You got the correct answer.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'bottom'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-shelf-life',
		text: 'Which have the shortest shelf lives?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Grapes',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Mushrooms',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Berries',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Tomatoes',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-1-mc-should-be',
		text: 'With most produce, items should be…',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Firm, smell Pleasant',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Firm, smell Strong',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Too strong means too ripe.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Squishy, brown',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'A little dirty',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Great start today.',
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'watermelon-helmet',
					text: 'A watermelon helmet has been added to your avatar.'
				})
			}),
		]
	}),
]


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'pro1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};