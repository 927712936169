import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {avatarData} from 'data/avatar-data';
import {getPlayerItems} from 'helpers/module-helper';
import {
	generateRandomAvatar,
	getAvatar, 
	checkIfItemIsUnlocked, 
	checkIfItemIsSeen, 
	getNumberOfUnseenItemsInCategory
} from 'helpers/avatar-helper';
import {generalUiTexts, profileUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Avatar from 'components/game/avatar/avatar';
import {ReactComponent as BodyF} from 'assets/images/avatar-maker/body/body-f.svg';
import {ReactComponent as BodyM} from 'assets/images/avatar-maker/body/body-m.svg';
import {ReactComponent as BodyF2} from 'assets/images/avatar-maker/body/body-f2.svg';
import {ReactComponent as BodyM2} from 'assets/images/avatar-maker/body/body-m2.svg';
import {ReactComponent as Hair} from 'assets/images/avatar-maker/hair/hair.svg';
import {ReactComponent as Nose} from 'assets/images/avatar-maker/nose/nose.svg';
import './avatar-maker.scss';

const AvatarMaker = ({deviceInfo, playerData, userData, handleGoToPage, updatePlayerGameData}) => {
	/* Timeout */
	let timeout = null;

	/* Track if changes have been made */
	const [isEditing, setIsEditing] = useState(false);

	/* Track if saving changes */
	const [isSaving, setIsSaving] = useState(false);

	/* Category naviation */
	const [categoryIndex, setCategoryIndex] = useState(0);

	/* Selected customizations (directly modified by player, database not updated until player saves) */
	const [selectedOptions, setSelectedOptions] = useState(getAvatar(playerData));

	/* Scrolling and scroll indicators */
	const [isScrolling, setIsScrolling] = useState(false);
	const setScrollIndicator = (e) => {
		if (!isScrolling) {
			window.requestAnimationFrame(function() {
				const scrollDiff = e.target.scrollWidth - e.target.clientWidth;
				const arrowLeft = document.getElementsByClassName('AvatarMaker-indicatorLeft')[0];
				const arrowRight = document.getElementsByClassName('AvatarMaker-indicatorRight')[0];

				if (e.target.scrollLeft > 5) {
					arrowLeft.classList.add('active');
				} else {
					arrowLeft.classList.remove('active');
				}

				if (e.target.scrollLeft + 5 < scrollDiff) {
					arrowRight.classList.add('active');
				} else {
					arrowRight.classList.remove('active');
				}
				setIsScrolling(false);
			});
			setIsScrolling(true);
		}
	};

	/**
	 * Generate random avatar
	 */
	const handleGenerateAvatar = () => {
		const generatedAvatar = generateRandomAvatar();
		updatePlayerGameData({avatar: generatedAvatar}).then(
			() => {
				timeout = setTimeout(() => {setSelectedOptions(generatedAvatar);}, 2000);				
			}, (error) => {
				console.error(error);
			});
	};


	/* Component did mount */
	useEffect(() => {	
		/* Check if avatar exists, if not then generate it */
		if (!getAvatar(playerData)) {
			handleGenerateAvatar();
		}

		/* Category scroll indicators */
		const categoriesRef = document.getElementById('categories');
		categoriesRef.addEventListener('scroll', setScrollIndicator);
		if (categoriesRef.scrollWidth > categoriesRef.clientWidth) {
			const arrowRight = document.getElementsByClassName('AvatarMaker-indicatorRight')[0];
			arrowRight.classList.add('active');
		}

		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Select option
	 * @param {string} categoryId 
	 * @param {number} optionId 
	 * @param {bool} itemIsSeen
	 */
	const handleSelectOption = (categoryId, optionId, itemIsSeen) => {
		if (!selectedOptions) return;

		/* Select option */
		let newSelectedOptions = {...selectedOptions};
		newSelectedOptions[categoryId] = optionId;

		/* Adjust affected categories */
		const categoryData = avatarData.categories.find((category) => {
			return category.id === categoryId;
		});
		if (categoryData && categoryData.affectedCategories) {
			/* Loop over affected categories */
			categoryData.affectedCategories.forEach((catId) => {
				/* Only check category if an option has been selected */
				if (newSelectedOptions.hasOwnProperty(catId) && newSelectedOptions[catId] !== null) {
					const catOptions = avatarData.categories.find((category) => {
						return category.id === catId;
					}).options;
					let selectedCatOption = catOptions.find((co) => {
						return co.id === newSelectedOptions[catId];
					});
	
					/* Selected option in category does not allow for new body type */
					if (
						categoryId === 'body-type' && 
						selectedCatOption.bodyTypeIds && 
						selectedCatOption.bodyTypeIds.indexOf(optionId) < 0
					) {
						const newCatOption = catOptions.find((co) => {
							return (
								selectedCatOption.similarIds.indexOf(co.id) >= 0 &&
									co.bodyTypeIds.indexOf(optionId) >= 0
							);
						});
						if (newCatOption) {
							newSelectedOptions[catId] = newCatOption.id;
						} else {
							const defaultOption = catOptions.find((co) => {
								return (
									co.bodyTypeIds.indexOf(optionId) >= 0 &&
										co.isDefault === true
								);
							});
							newSelectedOptions[catId] = (defaultOption ? defaultOption.id : null);
						}
					}
				}
			});
		}
		
		/* Update selected options */
		setSelectedOptions(newSelectedOptions);

		/* Update isEditing */
		setIsEditing(true);

		/* Mark item as seen if applicable */
		if (!itemIsSeen) {
			let playerItems = getPlayerItems(playerData);
			const itemIndex = playerItems.findIndex((item) => {return item.id === optionId;});
			if (itemIndex >= 0) {
				/* Mark item as seen (i.e. tried on) */
				playerItems[itemIndex].isSeen = true;

				/* Mark similar items as seen */
				if (categoryData && categoryData.options) {
					const optionData = categoryData.options.find((o) => {return o.id === optionId;});
					if (optionData && optionData.similarIds && optionData.similarIds.length > 0) {
						optionData.similarIds.forEach((similarOptionId) => {
							const itemIndex = playerItems.findIndex((item) => {return item.id === similarOptionId;});
							if (itemIndex >= 0) {
								playerItems[itemIndex].isSeen = true;
							}
						});
					}
				}

				/* Update player items */
				updatePlayerGameData({items: playerItems});
			}
		}
	};

	/**
	 * Save changes
	 */
	const handleSaveChanges = () => {
		/* Update isSaving */
		setIsSaving(true);

		/* Update player data */
		updatePlayerGameData({avatar: selectedOptions}).then(
			() => {
				setIsSaving(false);
				setIsEditing(false);
			}, (error) => {
				setIsSaving(false);
				console.error(error);
			});
	};

	return (
		<div className={'AvatarMaker' + (deviceInfo ? ' ' + deviceInfo.orientation : '')}>
			{/* Avatar */}
			<div className="AvatarMaker-avatar">
				{!selectedOptions && 
					<div className="AvatarMaker-generatingAvatar">
						<span>{profileUiTexts.generatingAvatar}</span>
					</div>
				}
				{selectedOptions && <Avatar 
					type="avatar-maker" 
					playerData={{avatar: selectedOptions}}
				/>}
			</div>

			{/* Save button */}
			{isEditing && <div className="AvatarMaker-saveBtn">
				<Button 
					isLoading={isSaving}
					isDisabled={isSaving || !isEditing}
					classes={['blue', 'save-avatar']}
					text={generalUiTexts.save}
					onClick={() => {handleSaveChanges();}}
				/>
			</div>}

			{/* Panel */}
			<div className="AvatarMaker-panel">
				<div id='categories' className="AvatarMaker-categories">
					{avatarData.categories.map((category, index) => {
						const newUnseenItems = getNumberOfUnseenItemsInCategory(
							category.id, 
							playerData, 
							selectedOptions ? selectedOptions['body-type'] : null
						);

						return (
							<div 
								key={category.id}
								className="AvatarMaker-category"
								onClick={() => {setCategoryIndex(index);}}
							>
								<div className={'AvatarMaker-categoryImage ' + category.id + 
									(selectedOptions && index === categoryIndex ? ' selected' : '')} />
								{newUnseenItems > 0 && 
									<div className="AvatarMaker-categoryTag"><span>{newUnseenItems}</span></div>}
							</div>
						);
					})}
				</div>
				<div className='AvatarMaker-indicatorLeft'/>
				<div className='AvatarMaker-indicatorRight'/>
				{selectedOptions && <div className="AvatarMaker-options">
					{Array(avatarData.categories[categoryIndex].optionRows).fill().map((_, i) => {
						/* Get total number of available options (not counting the "none" option) */
						const numberOfAvailableOptions = 
							avatarData.categories[categoryIndex].options.filter((option) => {
								return (
									option.id !== 'none' && (
										userData.role === 'admin' || 
										!option.hasOwnProperty('isLocked') ||
										!option.isLocked ||
										checkIfItemIsUnlocked(option.id, playerData)
									)
								);
							}).length;
						/* No options available, show placeholder / nothing */
						if (numberOfAvailableOptions === 0) {
							if (i > 0 || !avatarData.categories[categoryIndex].noAvailableOptionsText) return null;
							return (
								<div key={i} className="AvatarMaker-noOptions">
									<p>{avatarData.categories[categoryIndex].noAvailableOptionsText}</p>
								</div>
							);
						}
						
						/* Get options in row */
						const optionsInRow = avatarData.categories[categoryIndex].options.filter((option) => {
							return (
								(
									(
										!option.hasOwnProperty('bodyTypeIds')  || 
										(
											selectedOptions && 
											option.bodyTypeIds.indexOf(selectedOptions['body-type']) >= 0
										)
									) && 
									(avatarData.categories[categoryIndex].optionRows === 1 || option.row === i + 1)
								)
							);
						});
						if (!optionsInRow || optionsInRow.length === 0) return null;

						return (
							<div key={i} className="AvatarMaker-optionRow">
								{optionsInRow.map((option) => {
									/* Only show locked items if player has aquired them or player is admin */
									if (userData.role !== 'admin' && option.isLocked) {
										if (!checkIfItemIsUnlocked(option.id, playerData)) return null;
									}
									/* Check if item has not been seen by player (i.e. is new and untried) */
									const itemIsSeen = checkIfItemIsSeen(option.id, playerData);

									/* Check if option is selected */
									const isSelected = (
										selectedOptions && 
										selectedOptions.hasOwnProperty(avatarData.categories[categoryIndex].id) &&
										selectedOptions[avatarData.categories[categoryIndex].id] === option.id
									);
						
									/* Get option image (can depend on selected body type / skin color) */
									let image = null;
									let colorClass = (selectedOptions ? selectedOptions['skin-color'] : 1);
									let bodyClass = (selectedOptions ? selectedOptions['body-type'] : 1);
									let hairClasses = '';
									let noseClasses = '';
									if (selectedOptions && option.id !== 'none') {
										if (avatarData.categories[categoryIndex].id === 'body-type') {
											bodyClass = option.id;
											if (option.id === 'f') image = <BodyF />;
											if (option.id === 'f2') image = <BodyF2 />;
											if (option.id === 'm') image = <BodyM />;
											if (option.id === 'm2') image = <BodyM2 />;
										}
										if (avatarData.categories[categoryIndex].id === 'skin-color') {
											image = <span></span>;
											colorClass = option.id;
										}
										if (avatarData.categories[categoryIndex].id === 'hair') {
											image = <Hair />;
											if (selectedOptions['body-type'] === 'f') {
												hairClasses += ' Skin_F';
											} else {
												hairClasses += ' Skin_M';
											}
											if (option.svgIds && option.svgIds.length > 0) {
												option.svgIds.forEach((svgId) => {hairClasses += ' ' + svgId;});
											}
										}
										if (avatarData.categories[categoryIndex].id === 'nose') {
											image = <Nose />;
											if (selectedOptions['body-type'] === 'f') {
												noseClasses += ' Nose_F';
											} else {
												noseClasses += ' Nose_M';
											}
											if (option.svgIds && option.svgIds.length > 0) {
												option.svgIds.forEach((svgId) => {noseClasses += ' ' + svgId;});
											}
										}
										if (
											avatarData.categories[categoryIndex].id === 'eyes' ||
											avatarData.categories[categoryIndex].id === 'mouth' ||
											avatarData.categories[categoryIndex].id === 'beard' ||
											avatarData.categories[categoryIndex].id === 'glasses' ||
											avatarData.categories[categoryIndex].id === 'clothes' ||
											avatarData.categories[categoryIndex].id === 'hats' ||
											avatarData.categories[categoryIndex].id === 'work-clothes' ||
											avatarData.categories[categoryIndex].id === 'accessories' ||
											avatarData.categories[categoryIndex].id === 'items' ||
											avatarData.categories[categoryIndex].id === 'animals' ||
											avatarData.categories[categoryIndex].id === 'decorations' 
										) {
											try {
												const imageSrc = require('assets/images/avatar-maker/' + 
													avatarData.categories[categoryIndex].imageFolder + '/' + 
													option.image
												);
												image = <img 
													src={imageSrc} 
													alt={avatarData.categories[categoryIndex].label}
												></img>;
											} catch (error) {
												console.error(error);	
											}
										}
									}

									return (
										<div 
											key={option.id}
											className={'AvatarMaker-option ' + 
												avatarData.categories[categoryIndex].id + ' option-' + option.id + 
												' color-' + colorClass + ' body-' + bodyClass + 
												(hairClasses.length > 0 ? ' ' + hairClasses : '') +
												(noseClasses.length > 0 ? ' ' + noseClasses : '') +
												(isSelected ? ' selected' : '') +
												(itemIsSeen ? '' : ' not-seen')
											}
											onClick={() => {
												if (!isSelected) {
													handleSelectOption(
														avatarData.categories[categoryIndex].id, 
														option.id,
														itemIsSeen
													);
												}
											}}
										>{image}</div>
									);
								})}
							</div>
						);
					})}
					
				</div>}
			</div>

			{/* Back button */}
			<div className="AvatarMaker-backBtn">
				<Button classes={['back']} text={generalUiTexts.back} onClick={() => {handleGoToPage('profile');}}/>
			</div>

			{/* Profile button */}
			<div className="AvatarMaker-profileBtn" onClick={() => {handleGoToPage('profile');}}>
				<Avatar type="button" playerData={playerData} />
			</div>
		</div>
	);
};

AvatarMaker.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired
};

export default AvatarMaker;
