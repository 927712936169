import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {checkIfValidEmail} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './facilitator-add-co-facilitator-popup.scss';

const FacilitatorAddCoFacilitatorPopup = ({gameId, togglePopup, addCoFacilitatorsToGame}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	
	/* Input co-facilitator e-mail */
	const [email, setEmail] = useState('');
	const updateEmail = (e) => {
		setEmail(e.target.value);
		setErrorMsg(null);
	};

	/**
	 * Add co-facilitator
	 * Check if emails are valid, call backend api to add them
	 */
	const handleAddCoFacilitator = () => {
		if (isLoading) return; 
		if (!email || email.length === 0) return;

		setErrorMsg(null);
		setIsLoading(true);

		if (email.includes(' ')) {
			// Multiple e-mails, get valid e-mails 
			let validEmails = [];
			let emails = email.split(' ');
			emails.forEach((e) => {
				const {isValidEmail} = checkIfValidEmail(e);
				
				if (isValidEmail && validEmails.indexOf(e.toLowerCase()) < 0) {
					validEmails.push(e.toLowerCase());
				}
			});

			if (validEmails.length > 0) {
				/* Add least 1 valid e-mail, add as co-facilitator(s) */
				addCoFacilitatorsToGame(gameId, validEmails).then((response) => {
					/* Check for error messages */
					let errMsg = '';
					if (response.feedback && response.feedback.length > 0) {
						response.feedback.forEach((f) => {
							errMsg += f + ' ';
						});
					}
					if (errMsg.length > 0) {
						/* 1 or more error messages - show them */
						setErrorMsg(errMsg);
					} else {
						/* No error messages - close popup */
						togglePopup();
					}

					setIsLoading(false);
					
				});
			} else {
				/* No valid e-mails */
				setErrorMsg(facilitatorUiTexts.addCoFacilitatorsToGamePopup.noValidEmails);
				setIsLoading(false);
			}

		} else {
			/* Single e-mail, check if valid */
			const {isValidEmail, emailFeedback} = checkIfValidEmail(email);
		
			if (isValidEmail) {
				/* Valid e-mail, add facilitator */
				addCoFacilitatorsToGame(gameId, [email.toLowerCase()]).then((response) => {
					if (response.status === 'error') {
						/* Show error message */
						setErrorMsg(response.feedback[0]);			
					} else {
						/* No error message - close popup */
						togglePopup();
					}
					setIsLoading(false);
				});
			} else {
				/* Invalid e-mail */
				if (facilitatorUiTexts.addCoFacilitatorsToGamePopup.hasOwnProperty(emailFeedback)) {
					setErrorMsg(facilitatorUiTexts.addCoFacilitatorsToGamePopup[emailFeedback]);
				}
				setIsLoading(false);
			}
		}
	};

	return (
		<div className="FacilitatorAddCoFacilitatorPopup" onClick={() => {togglePopup();}}>
			<div className="FacilitatorAddCoFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorAddCoFacilitatorPopup-header">
					<div className="FacilitatorAddCoFacilitatorPopup-title">
						{facilitatorUiTexts.addCoFacilitatorsToGamePopup.title}</div>
					<div 
						className="FacilitatorAddCoFacilitatorPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>				
				</div>
				{/* Popup body */}
				<div className="FacilitatorAddCoFacilitatorPopup-body">
					<div className="FacilitatorAddCoFacilitatorPopup-text">
						{renderMarkdown(facilitatorUiTexts.addCoFacilitatorsToGamePopup.text)}
					</div>
					{/* Email input field */}
					<div className="FacilitatorAddCoFacilitatorPopup-input">
						<input 
							type="text"
							name="email"
							placeholder={facilitatorUiTexts.addCoFacilitatorsToGamePopup.emailPlaceholder}
							value={email ? email : ''}
							onChange={(e) => {updateEmail(e);}}
						/>
					</div>
					{/* Feedback (error message) */}
					<div className="FacilitatorAddCoFacilitatorPopup-feedback error">
						{errorMsg && <span>{errorMsg}</span>}
					</div>
					{/* Confirm btn */}
					<div className="FacilitatorAddCoFacilitatorPopup-confirmBtn">
						<Button 
							isLoading={isLoading}
							classes={['blue', 'adminPopup']}
							text={facilitatorUiTexts.addCoFacilitatorsToGamePopup.okBtn}
							onClick={() => {handleAddCoFacilitator();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

FacilitatorAddCoFacilitatorPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired,
	addCoFacilitatorsToGame: PropTypes.func.isRequired,
};

export default FacilitatorAddCoFacilitatorPopup;
