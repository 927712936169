import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {lootBoxesData} from 'data/loot-boxes-data';
import {itemsData} from 'data/items-data';
import {gameUiTexts} from 'data/ui-texts';
import {getPlayerItems} from 'helpers/module-helper';
import {shuffleArray} from 'helpers/array-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './popup-loot-box.scss';

const PopupLootBox = (props) => {
	/* Props */
	const {
		lootBoxId, 
		playerData, 
		reserveLootBoxOptions, 
		getLootBoxItem, 
		setLootBoxId, 
		deviceOrientation
	} = props;

	/* Check if player has already emptied loot box */
	let playerPrize = null;

	if (playerData && playerData.items && playerData.items.length > 0) {
		playerPrize = playerData.items.find((i) => {
			return i.sourceType === 'loot-box' && i.sourceId === lootBoxId;
		});
	}

	/* Loot box status, options, result */
	const [noAvailableItems, setNoAvailableItems] = useState(false);
	const [lootBoxStatus, setLootBoxStatus] = 
		useState((playerPrize ? 'empty' : 'closed')); // closed -> choose -> empty 
	const [lootBoxOptions, setLootBoxOptions] = useState(null);
	const [lootBoxPrize, setLootBoxPrize] = useState((playerPrize ? playerPrize : null));

	/* Saving new loot to player data */
	const [isSaving, setIsSaving] = useState(false);
	
	/* Loot box data */
	const lootBoxData = lootBoxesData.find((l) => {return l.id === lootBoxId;});

	/* Popup content */
	const title = (lootBoxStatus === 'empty' ? null : gameUiTexts.lootBoxPopup.title);
	let text = gameUiTexts.lootBoxPopup.text1;
	
	if (lootBoxStatus === 'choose') text = gameUiTexts.lootBoxPopup.text2;
	if ((lootBoxStatus === 'closed' || lootBoxStatus === 'choose') && noAvailableItems) {
		text = gameUiTexts.lootBoxPopup.text3;
	};
	if (lootBoxStatus === 'empty') {
		if (lootBoxPrize) {
			let itemData = itemsData.find((i) => {return (i.id === playerPrize.id);});
			if (itemData) {
				text = itemData.text;
			}
		} else {
			text = 'something went wrong :/';
		}
	}

	/**
	 * Open loot box
	 */
	const openLootBox = () => {
		if (isSaving) return;
		
		if (lootBoxData.type === 'random-item') {
			if (playerData.reservedLootBoxOptions && playerData.reservedLootBoxOptions.length > 0) {
				/* Get reserved items */
				setLootBoxOptions(playerData.reservedLootBoxOptions);
				setLootBoxStatus('choose');
			} else {
				/* Get new random items */
				const playerItems = getPlayerItems(playerData);
				const availableItems = itemsData.filter((i) => {
					return (
						i.locationIds.includes('loot-box') && 
						!playerItems.some((pi) => {return pi.id === i.id;})
					);
				});
				if (availableItems.length > 0) {
					const randomizedItems = shuffleArray(availableItems);
				
					if (lootBoxData.chooseBetween === 2) {
						const maxIndex = Math.min(availableItems.length - 1, lootBoxData.chooseBetween - 1);
						const options = randomizedItems.slice(0, maxIndex + 1)
							.map((option) => {return {id: option.id, type: option.type};});
						reserveLootBoxOptions(options);
						setLootBoxOptions(options);
						setLootBoxStatus('choose');
					} else {
						console.error('loot box type not implemented');
					}
				} else {
					setNoAvailableItems(true);
				}
			}
		} else {
			console.error('loot box type not implemented');
		}
	};

	/**
	 * Choose loot
	 * @param {object} optionData 
	 */
	const chooseLootOption = (optionData) => {
		if (lootBoxData.type === 'random-item') {
			handleGetLootBoxItem(optionData, true);
		} else {
			console.error('loot box type not implemented');
		}
	};

	/**
	 * Get loot box item
	 * @param {object} itemData 
	 * @param {bool} resetReserved
	 * @returns 
	 */
	const handleGetLootBoxItem = (optionData, resetReserved) => {
		if (isSaving) return;
		
		setIsSaving(true);
		getLootBoxItem(
			{id: optionData.id, type: optionData.type, sourceType: 'loot-box', sourceId: lootBoxId}, 
			resetReserved
		).then(() => {
			setLootBoxOptions(null);
			setLootBoxStatus('empty');
			setLootBoxPrize(optionData);
			setIsSaving(false);
		}).catch((error) => {
			setIsSaving(false);
			console.error(error);
		});
	};

	if (!lootBoxData) {
		return null;
	}

	return (
		<div className={'PopupLootBox ' + lootBoxStatus 
		+ (deviceOrientation ? deviceOrientation : '')} onClick={() => {setLootBoxId(null);}}>
			<div className="PopupLootBox-background" />
			<div className="PopupLootBox-content" onClick={(e) => {e.stopPropagation();}}>
				{title && <div className="PopupLootBox-title"><span>{title}</span></div>}
				<div className="PopupLootBox-text">{renderMarkdown(text)}</div>
				<div className="PopupLootBox-image" onClick={() => {
					if (lootBoxStatus === 'closed') openLootBox(); 
					if (lootBoxStatus === 'empty') setLootBoxId(null);
				}}>
					{lootBoxOptions && 
						<div className={'PopupLootBox-options'}>
							{lootBoxOptions.map((option, index) => {
								return (
									<div 
										key={index}
										className={'PopupLootBox-option ' + option.id 
											+ ' animation-' + (index + 1) + '-' + lootBoxOptions.length} 
										onClick={() => {chooseLootOption(option);}}
									/>
								);
							})}
						</div>
					}
					{lootBoxPrize && 
						<div 
							className={'PopupLootBox-prize ' + lootBoxPrize.id} 
							onClick={() => {setLootBoxId(null);}}
						/>
					}
				</div>
			</div>
		</div>
	);
};

PopupLootBox.propTypes = {
	lootBoxId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	reserveLootBoxOptions: PropTypes.func.isRequired,
	getLootBoxItem: PropTypes.func.isRequired,
	setLootBoxId: PropTypes.func.isRequired,
	deviceOrientation: PropTypes.string,
};

export default PopupLootBox;
