import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Character from 'components/ui/character/character';
import './branching-story-info.scss';

const BranchingStoryInfo = ({taskId, storyStepData, managerId}) => {
	if (!storyStepData) return null;
	
	return (
		<div className={'BranchingStoryInfo ' + taskId}>
			{/* Title and text */}
			{(storyStepData.title || storyStepData.text) && 
				<div className={'BranchingStoryInfo-textBox' 
					+ (storyStepData.textType === 'speech' ? ' bubble' : '') 
					+ (storyStepData.image ? ' ' + storyStepData.image : '')
				}>
					{storyStepData.title && 
						<div className="BranchingStoryInfo-title">{storyStepData.title}</div>}
					{storyStepData.text && 
						<div className={'BranchingStoryInfo-text' + (storyStepData.showManager ? ' manager' : '')}>
							{renderMarkdown(storyStepData.text)}
						</div>}
					{storyStepData.image && 
						<div className={'BranchingStoryInfo-image'}/>
					}
					{storyStepData.showManager && 
						<div className={'BranchingStoryInfo-manager'}>
							<Character 
								page="branching-story" 
								characterId={'manager-1'}
								managerId={managerId}
							/>

						</div>
					}
				</div>
			}

			{/* Images */}
			{storyStepData.images && storyStepData.images.map((image, index) => {
				if (image.type === 'character') {
					return (
						<div key={index} className={'BranchingStoryInfo-character ' + image.characterId}>
							<Character 
								page="branching-story" 
								characterId={image.characterId}
								managerId={managerId}
								items={image.items ? image.items : []}
							/>
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

BranchingStoryInfo.propTypes = {
	taskId: PropTypes.string.isRequired,
	managerId: PropTypes.string.isRequired,
	storyStepData: PropTypes.object.isRequired
};

export default BranchingStoryInfo;
