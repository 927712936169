import React from 'react';
import PropTypes from 'prop-types';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import './facilitator-statistics.scss';
import {facilitatorUiTexts, generalUiTexts} from 'data/ui-texts';
import {getPerfectScorePercentage, getAverageTaskMistakes} from 'helpers/module-helper';
import DownloadStatistics from '../download-statistics/download-statistics';
import Button from 'components/ui/button/button';

const FacilitatorStatisticsTasks = (props) => {
	const {
		pageData,
		handleGoToPage,
		toggleModulePopup,
		modulePlayersData,
		moduleData
	} = props;

	const tasks = moduleData.tasks.filter((task) => {return task.isSolveToContinue;});
	const isCompetition = (moduleData.areaId === 'competition' ? true : false);

	return (
		<div className="FacilitatorStatistics">
			<div className='FacilitatorStatistics-header'>
				<FacilitatorHeaderSubpage 
					page={pageData.id} 
					subpage='tasks'
					subpageIds={pageData.subpageIds}
					handleGoToPage={handleGoToPage}
					titleInsert={moduleData.title}
				/>
				<div className="FacilitatorStatistics-backBtn">
					<Button 
						classes={['facilitatorBack']} 
						text={generalUiTexts.back} 
						onClick={() => {handleGoToPage('analytics', null);}}
					/>
				</div>
				<DownloadStatistics 
					type='tasks' 
					modulePlayersData={modulePlayersData} 
					moduleData={moduleData} 
					taskData={tasks} />
			</div>

			<div className='FacilitatorStatistics-table'>
				<div className='FacilitatorStatistics-tableHeader'>
					<div className='FacilitatorStatistics-tableHeaderTitle'>
						<span>{facilitatorUiTexts.task}</span>
					</div>
					<div className='FacilitatorStatistics-tableHeaderTitle'>
						<span>{facilitatorUiTexts.avgMistakes}</span>
					</div>
					<div className='FacilitatorStatistics-tableHeaderTitle'>
						<span>{facilitatorUiTexts.perfScores}</span>
					</div>
					<div className='FacilitatorStatistics-tableHeaderTitle'>
						<span>{facilitatorUiTexts.preview}</span>
					</div>
				</div>

				{tasks.map((task, index) => {
					let avgMistakes = 0;
					let perfectScores = 0;
					if (modulePlayersData) {
						avgMistakes = getAverageTaskMistakes(task, modulePlayersData);
						perfectScores = getPerfectScorePercentage(task, modulePlayersData);
					};


					return (
						<div key={index} className='FacilitatorStatistics-tableRow'>
							<div className='FacilitatorStatistics-moduleItem bold'>{task.id}</div>
							<div className='FacilitatorStatistics-moduleItem'>{avgMistakes}</div>
							<div className='FacilitatorStatistics-moduleItem'>{perfectScores}</div>
							<div className='FacilitatorStatistics-moduleItem iconEye' 
								onClick={() => {toggleModulePopup(moduleData.id, task.id, isCompetition);}}/>
						</div>
					);
				})}

			</div>
		</div>
	);

};

FacilitatorStatisticsTasks.propTypes = {
	pageData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	modulePlayersData: PropTypes.array,
	moduleData: PropTypes.object.isRequired,
	toggleModulePopup: PropTypes.func.isRequired,
};

export default FacilitatorStatisticsTasks;
