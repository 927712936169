const areasData = [
	{
		id: 'intro',
		title: 'Intro',
		unlockConditions: null,
		showOnMap: false,
		moduleIds: ['intro'],
		canBeDisabled: false,
	},
	{
		id: 'orientation',
		title: 'Orientation',
		unlockConditions: null,
		moduleIds: ['orientation-1'],
		characterId: 'manager-1',
		managerNotifications: [
			{
				id: 'orientation-completed',
				type: 'area-completed',
				areaId: 'orientation',
				text: `Great, now you're ready for the next modules. Click on the arrow in the top left corner to see the available modules. You need 2 stars in a module to progress.`
			}
		],
		canBeDisabled: false,
	},
	{
		id: 'customer-service',
		title: 'Customer Service',
		unlockConditions: [
			{type: 'area-completed', areaId: 'orientation'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['customer-service-1', 'customer-service-2'],
		characterId: 'manager-1',
		managerNotifications: [
			{
				id: 'customer-service-first-visit',
				type: 'first-visit',
				text: 'You have to complete both customer service modules before you can start in a specific department. Each island is a module. Begin by clicking on island number 1.'
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'center-store',
		title: 'Center Store',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['center-store-1', 'center-store-2'],
		characterId: 'manager-2',
		managerNotifications: [
			{
				id: 'center-store-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['center-store-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'center-store-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['center-store-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'center-store-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['center-store-1', 'center-store-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'deli',
		title: 'Deli',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['deli-1', 'deli-2'],
		managerNotifications: [
			{
				id: 'deli-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['deli-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'deli-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['deli-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'deli-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['deli-1', 'deli-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'floral',
		title: 'Floral',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['floral-1', 'floral-2'],
		managerNotifications: [
			{
				id: 'floral-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['floral-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'floral-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['floral-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'floral-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['floral-1', 'floral-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true
	},
	{
		id: 'meat',
		title: 'Meat',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['meat-1', 'meat-2'],
		managerNotifications: [
			{
				id: 'meat-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['meat-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'meat-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['meat-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'meat-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['meat-1', 'meat-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'seafood',
		title: 'Seafood',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['seafood-1', 'seafood-2'],
		managerNotifications: [
			{
				id: 'seafood-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['seafood-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'seafood-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['seafood-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'seafood-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['seafood-1', 'seafood-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'produce',
		title: 'Produce',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		canBeDisabled: true,
		moduleIds: ['produce-1', 'produce-2'],
		managerNotifications: [
			{
				id: 'produce-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['produce-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'produce-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['produce-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'produce-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['produce-1', 'produce-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true,
	},
	{
		id: 'bakery',
		title: 'Bakery',
		unlockConditions: [
			{type: 'area-completed', areaId: 'customer-service'},
			{type: 'not-deactivated'}
		],
		moduleIds: ['bakery-1', 'bakery-2'],
		managerNotifications: [
			{
				id: 'bakery-1-completed',
				type: 'number-of-stars-in-modules-maximum',
				moduleIds: ['bakery-1'],
				numberOfStars: 1,
				text: `Nice work completing the module! Remember that you need two stars to continue your journey, but I'm sure you'll get that on the next try.`
			},
			{
				id: 'bakery-1-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['bakery-1'],
				numberOfStars: 2,
				text: `You've earned enough stars to continue. Well done! Now your are ready for some basic training. Click on the float next to the level 1 island.`
			},
			{
				id: 'bakery-2-completed-with-5-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['bakery-1', 'bakery-2'],
				numberOfStars: 5,
				text: `You are quite the expert now. Don't forget to open the chest. And maybe check out some of the other departments.`
			}
		],
		canBeDisabled: true
	},
	{
		id: 'side-quest',
		title: 'Basic training',
		moduleIds: [
			'safety-1',
			'food-safety-1',
			'daily-guidelines-1',
			'inventory-management-1',
			'cooler-freezer-1',
			'cleaning-1',
			'backroom-5-s',
			'shrink-1',
		],
		managerNotifications: [
			{
				id: 'basic-training-first-visit',
				type: 'first-visit',
				text: 'You have to complete all of these modules to move on to the next level in your department.'
			},
			{
				id: 'safety-1-completed',
				type: 'module-completed',
				moduleId: 'safety-1',
				text: `Nice! Keep up the good work by completing more training modules.`
			},
			{
				id: 'inventory-management-1-completed',
				type: 'module-completed',
				moduleId: 'inventory-management-1',
				text: `You are on a roll! You've completed half of the basic training.`
			},
			{
				id: 'backroom-5-s-completed-with-2-or-more-stars',
				type: 'number-of-stars-in-modules-minimum',
				moduleIds: ['backroom-5-s'],
				numberOfStars: 2,
				text: `You are almonst an expert in all the basics of working in a store now. If you complete Shrink, you can move on to level 2 in your department.`
			}
		],
		isSideQuest: true,
		canBeDisabled: true,
		showOnMap: false,
	},
];

export {
	areasData
};
