let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let usersDbName = 'users';
let gamesDbName = 'games';
let tagsDbName = 'tags';
let gameUrl = 'localhost:3000';
let redirectUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let registrationUrl = null;
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-mdi-test.web.app';
	redirectUrl = 'https://cgl-mdi-test.web.app';
	apiURL = 'https://cgl-mdi-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-mdi-demo.web.app';
		redirectUrl = 'https://cgl-mdi-demo.web.app';
		apiURL = 'https://cgl-mdi-demo.web.app/api/';
		registrationUrl = 'https://qaidentity.mdi.com/registration';
		usersDbName = 'users-demo';
		gamesDbName = 'games-demo';
		tagsDbName = 'tags-demo';
	}
}
if (env === 'production') {
	gameUrl = 'gamingmdi.com';
	redirectUrl = 'https://cgl-mdi-production.web.app';
	apiURL = 'https://cgl-mdi-production.web.app/api/';
	registrationUrl = 'https://identity.mdi.com/registration';
}

const appConfig = {
	env: env,
	showDevTools: (env === 'development' || env === 'test'),
	apiURL: apiURL,
	gameUrl: gameUrl,
	redirectUrl: redirectUrl,
	registrationUrl: registrationUrl,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	tagsDbName: tagsDbName,
	maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	popupDelay: 500,
	feedbackDelay: 250,
	defaultManagerId: 'a',
	questionsAndBadgesInYearbook: 3,
	highscoreListLength: 5,
};

export default appConfig;