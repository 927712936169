import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, profileUiTexts} from 'data/ui-texts';
import Avatar from 'components/game/avatar/avatar';
import './profile-avatar.scss';
import PopupYearbook from 'components/ui/popup-yearbook/popup-yearbook';


const ProfileAvatar = ({userData, playerData, handleGoToPage, updatePlayerGameData}) => {
	/* Yearbook status */
	const [openYearbook, setOpenYearbook] = useState(false);

	/* Toggle yearbook status */
	const handleToggleYearbook = (state) => {
		setOpenYearbook(state);
	};

	/* Avatar btn text (create or edit) */
	const avatarIsCreated = (playerData.avatar ? true : false);
	const avatarBtnText = (avatarIsCreated ? profileUiTexts.editAvatar : profileUiTexts.createAvatar);

	return (
		<div className="ProfileAvatar">
			<div className="ProfileAvatar-title">{profileUiTexts.profile}</div>
			<div className="ProfileAvatar-avatar">
				<Avatar type="profile" playerData={playerData} />
			</div>
			{avatarIsCreated && <div 
				className="ProfileAvatar-yearbookBtn" 
				onClick={() => {handleToggleYearbook(true);}}
			>
				<span>{generalUiTexts.yearbookTitle}</span>
			</div>}
			<div 
				className={'ProfileAvatar-avatarBtn' + (avatarIsCreated ? '' : ' highlight')}
				onClick={() => {handleGoToPage('avatar');}}
			>
				<span>{avatarBtnText}</span>
			</div>
			{openYearbook &&
				<PopupYearbook 
					userData={userData}
					playerData={playerData} 
					closeYearbook={() => {handleToggleYearbook(false);}}
					updatePlayerGameData={updatePlayerGameData}
					canEdit = {true}
				/>
			}
		</div>
	);
};

ProfileAvatar.propTypes = {
	userData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
};

export default ProfileAvatar;