const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		animateCharacter: true,
		text: `Welcome to the Spotless Island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Even our dalmatians are blank.`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard flipped', position: 'right', items: ['dog-white']}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `How do you keep getting in here?`,
		extraCharacters: [{id: 'wizard flipped', position: 'right', items: ['dog-white']}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `It's a kind of magic.`,
		speechBubbleDirection: 'right',
		fadeoutSpeachBubble: true,
		extraCharacters: [{id: 'wizard flipped', position: 'right', type: 'animate-poof', items: ['dog-white']}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's start with cleaning steps.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-overlooked-tool',
		subtype: 'images',
		layout: 'cleaning-1-overlooked-tool',
		text: `What tool is often overlooked when it comes to cleaning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Clean towel
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, keep a clean towel with you to clean as you go.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Spray cleaner
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Shovel
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // bar of soap
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-high-touch-areas',
		text: `Up front what are some high touched areas?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Pin pads', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Check writing stand', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Trash can edges',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Register belts', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Racks',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-cleaning-only-schedule',
		text: `Cleaning is ONLY done based on schedule?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'False', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `It depends on the area.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'True'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-what-to-clean',
		text: `How do we know what to clean?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Check the cleaning schedule', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Day of week'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Manager tells us'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `It's labeled`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-common-schedules',
		subtype: 'images',
		layout: 'cleaning-1-common-schedules',
		text: `Which are the most common cleaning schedules?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Daily
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,// Weekly
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // Monthly
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 3 month
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // hourly
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Besides the schedule, daily cleaning is best for anything used.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Highly touched devices should be cleaned reguarly.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-touched-by-hands',
		text: `Which devices get touched by hands?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Handles/Knobs', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Floor'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Product'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Carts`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Of course.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Gloves`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `True, but they are changed.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-door-handles',
		text: `Which door handles need cleaning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Cooler/Freezer', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Handwash sink', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Bathrooms', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Car door`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Trailer door`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-handwash-sink',
		text: `Let's fix our handwash sink. Click on the issues that need fixing.`,
		layout: 'cleaning-1-handwash-sink',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'soap'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'towels'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-cleaning-degrees',
		text: `How many degrees of cleaning do we do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '360', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's right. All the way around.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '90',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '180',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `45`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-daily-cleaning',
		text: `What items in Deli/Meat need daily cleaning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Pans', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Knives', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Under cabinets',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `CoolerCoffin case fans`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Coffin case fans`
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-deli-production-area',
		text: `What needs to be cleaned daily?`,
		layout: 'cleaning-1-deli-production-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'slicer'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knives'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'countertop'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'fryer'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-deli-training-to-clean',
		text: `Which need training to clean?`,
		layout: 'cleaning-1-deli-production-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'slicer'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'fryer'}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Correct, make sure we sign off on larger equipment.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'cleaning-1-meat-production',
		text: 'How about in this area?',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-meat-production-area',
		text: `What needs cleaning?`,
		layout: 'cleaning-1-meat-production-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'grinder'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'saw'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knives'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'meat'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'countertop'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-meat-training-to-clean',
		text: `Which of these are dangerous to clean and need training?`,
		layout: 'cleaning-1-meat-production-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'grinder'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'saw'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-coffin-case',
		text: `How about this coffin case? Click on the issues that need cleaning.`,
		layout: 'cleaning-1-coffin-case',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'spots'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'smudge'}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Good work, easier to see when empty.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cleaning-1-spot-errors-coffin-case-seafood',
		text: `How about this one?`,
		layout: 'cleaning-1-coffin-case-seafood',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'spots'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'smudge'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tag'}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'cleaning-1-order-clean-case',
		text: `So how to we clean this one? Put actions in the right order from top to bottom.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Remove product', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Clean and sanitize shelves', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Clean tags', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Add product & rotate', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-dirty-signs',
		subtype: 'images',
		layout: 'cleaning-1-dirty-signs',
		text: `Which of these signs are ready?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // clean sign
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // dirty sign
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // dirty sign
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // dirty sign
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Tags and signs get dirty too.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's see what's next.`,
		items: ['clipboard-wall']
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-weekly-schedule',
		subtype: 'images',
		layout: 'cleaning-1-common-schedules',
		text: `Let's get the weekly list.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Daily
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,// Weekly
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,  // Monthly
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 3 month
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // hourly
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-weekly-items',
		text: `What item(s) should be cleaned weekly?`,
		image: 'checklist-weekly',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Display racks', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Trash cans',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This is daily.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Floors',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This is daily.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Food prep areas`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `These are daily.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-day-most-cleaning',
		subtype: 'images',
		layout: 'cleaning-1-day-most-cleaning',
		text: `Which day of the week should most cleaning be done?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // monday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,// tuesday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,  // wednesday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // thursday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // friday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // saturday
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // sunday
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-weekly-checklist',
		subtype: 'images',
		layout: 'cleaning-1-weekly-checklist',
		text: `Mark it off the list.`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // display racks
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // freezer floor
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,  // cooler floor
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // salad bar
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // hand sink
			}),
		]
	}),
	// DIALOGUE START
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-dialogue-cooler-freezer',
		characterId: 'wizard',
		maxPoints: 7,
		introText: `Okay, now you and I can go clean the cooler and the freezer. `,
		introCharacter: 'wizard',
		introBackground: 'freezer-cooler-on-rafts',
		resultBackground: 'ocean-ice-rink',
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-01',
				text: 'Which one do you want?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Cooler.', nextStepId: 'sd-02'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Freezer.', nextStepId: 'sd-03',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-02',
				text: `Alright, you'll clean the freezer.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'But I said cooler!', 
						nextStepId: 'sd-04'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-03',
				text: `Sounds good. What equipment do you want?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Broom and dust pan.', nextStepId: 'sd-05',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Mop and bucket.', nextStepId: 'sd-06',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-04',
				text: `Sorry, I'm allergic to ice.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Okay, I'll take the freezer.`, nextStepId: 'sd-03',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-05',
				text: `Actually, you take the mop.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Why?`, nextStepId: 'sd-07',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-06',
				text: `Great! How do you plan on mopping?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Pour the bucket onto the floor.', nextStepId: 'sd-08',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Get the mop very wet to spread the cleaner.', nextStepId: 'sd-08',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Keep emptying the mop.', nextStepId: 'sd-09',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-07',
				text: `I'm allergic to buckets.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Okay, I'll take the mop.`, nextStepId: 'sd-06',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-08',
				text: `Okay!`,
				nextStepId: 'sd-10'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-09',
				text: `Good call.`,
				nextStepId: 'sd-10'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-10',
				type: 'action',
				text: 'You mop the floor. The water gets very ... frozen.',
				nextStepId: 'sd-11'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-11',
				text: `Looks like we are all done!`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'No, I think we have a problem.', nextStepId: 'sd-12',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Yes.', nextStepId: 'sd-13',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-12',
				text: `Nope, looks good to me.`,
				nextStepId: 'sd-14'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-13',
				text: `Yes, looks good to me.`,
				nextStepId: 'sd-14'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'sd-14',
				imageFlip: false,
				type: 'dialogue',
				showPoints: true,
				isFinalStep: true,
				showAvatar: false,
				character: 'wizard-skates',
				text: `The ice rink is ready for business!`,
			}),
		]		
	}),// DIALOG END
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-mop-in-freezer',
		text: `Did you use the mop in the freezer instead of the cooler?`,
		isDilemma: true,
		image: 'manager-top-right',
		showManager: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'The wizard made me', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `I don't doubt it.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Well at least you admit.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Pretty sure you did.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-freezer-next-time',
		text: `Next time let's not use the mop in the freezer.`,
		isDilemma: true,
		image: 'manager-top-right',
		showManager: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Okay', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'bucket-hat',
							text: 'A Bucket Hat has been added to your avatar.'
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Only if I remember my skates.',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Nope. Just don't do it.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-clean-monthly',
		text: `While we are here, what areas need to be cleaned monthly?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Walls', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Racks', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Vents', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Floor',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Great work. Wizard be useful here would you?`,
		background: 'ocean-ice-rink'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['fireball'],
		text: `Sure. A little fireball will thaw this floor quick.`,
		background: 'ocean-ice-rink'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-cleaning-habits',
		text: `What system helps maintain good cleaning habits?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '5-S', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'GUEST',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Risk/Reward',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'CLEAN',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'MOP',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-other-main-step',
		text: `Besides Sanitize, which other main step helps keep a store clean?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Sustain', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. We have to keep it up.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Standarize',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Sort',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Straighten',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-daily-cleaning-tools',
		subtype: 'images',
		layout: 'cleaning-1-daily-cleaning-tools',
		text: `What tools might you need daily to clean?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // mop
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // broom
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // mop bucket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // rake
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // sponge
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Sponges can hold a lot of germs and are rarely sanitized.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true, // cloth
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-where-obtain-tools',
		text: `Do you know where to obtain these items in your store?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Great.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Ask your store manager.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-how-clean-utensils',
		text: `What do we use to clean utensils, pans, and cutlery?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '3-well sink', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Dishwasher',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Bleach'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Sink',
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'cleaning-1-3-well-sink',
		text: `This is a 3 well sink.`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'cleaning-1-order-3-well-sink',
		text: `What order does our 3-well sink go?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Prep', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Wash', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Rinse', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Sanitize', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-3-well-sink-sanitizer',
		text: `What's the proper use of sanitizer for items in 3-bay sinks?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Leave in solution for 1 minute and air dry', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Leave in solution for 30 seconds and air dry',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Leave in solution for 30 seconds and immediately towel dry'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Leave in solution for 1 minutes and immediately towel dry',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Dunk into solution quickly, drain, and air dry',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-sanitizer-working',
		text: `How do we know the sanitizer is working?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Use test strips', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Good for 1 hour',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Check for soap bubbles',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Sanitzier and Wash are separate.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Check temperature',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Just keep adding more',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cleaning-1-multiple-choice-mix-cleaners',
		text: `How do we mix cleaners?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Read directions', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. Each chemical is different.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '50/50 with water',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '70/30 with water',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '30/70 with water',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterItems: ['mop'],
		text: `Great work today. Just remember, clean as you go.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['mop-hat'],
					text: 'A Mop Hat has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cl1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};