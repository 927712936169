import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { facilitatorUiTexts } from 'data/ui-texts';
import { sortArrayByProperty } from 'helpers/array-helper';
import { getModulesCompletionPercentage } from 'helpers/module-helper';
import { checkIfAllReportsAreSeen } from 'helpers/reports-helper';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import FacilitatorPlayerPopup from './facilitator-player-popup/facilitator-player-popup';
import FacilitatorRemovePlayerPopup from './facilitator-remove-player-popup/facilitator-remove-player-popup';
import FacilitatorEditPlayerPopup from './facilitator-edit-player-popup/facilitator-edit-player-popup';
import FacilitatorPlayerReportsPopup from './facilitator-player-reports-popup/facilitator-player-reports-popup';
import Button from 'components/ui/button/button';
import './facilitator-players.scss';

const FacilitatorPlayers = (props) => {
	const {
		deviceInfo, 
		pageData, 
		coFacilitatorData,
		gameData, 
		gamePlayers, 
		handleGoToPage, 
		removePlayerFromGame,
		updatePlayerAreaIds,
		markReportAsSeen,
		userId,
		deleteReport
	} = props;

	/* Player info (popup) */
	const [selectedPlayerId, setSelectedPlayerId] = useState(null);

	/* Remove player popup */
	const [removePlayerId, setRemovePlayerId] = useState(null);

	/* Edit player popup */
	const [editPlayerId, setEditPlayerId] = useState(null);

	/* View Report player popup */
	const [reportPlayerId, setReportPlayerId ] = useState(null);
	

	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('name');
	const [sortDirection, setSortDirection] = useState('ASC');

	/* Sorted game players */
	let sortedGamePlayers = [...gamePlayers];
	gamePlayers.forEach((gamePlayer) => {
		gamePlayer.completion = getModulesCompletionPercentage(gamePlayer.modules);
	});
	sortedGamePlayers = sortArrayByProperty(sortedGamePlayers, sortProperty, sortDirection);

	/**
	 * Sort players by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	return (
		<div className="FacilitatorPlayers">
			{/* Subpage navigation */}
			<FacilitatorHeaderSubpage 
				page={pageData.id} 
				subpage="players"
				subpageIds={pageData.subpageIds}
				handleGoToPage={handleGoToPage}
			/>

			{/* List of players */}
			<div className="FacilitatorPlayers-header">
				<div 
					className={'FacilitatorPlayers-cell name' 
						+ (sortProperty === 'name' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('name');}}
				>
					<span>{facilitatorUiTexts.playerName}</span>
				</div>
				<div 
					className={'FacilitatorPlayers-cell email' 
						+ (sortProperty === 'email' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('email');}}
				>
					<span>{facilitatorUiTexts.playerEmail}</span>
				</div>
				<div 
					className={'FacilitatorPlayers-cell completion' 
						+ (sortProperty === 'completion' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('completion');}}
				>
					<span>{facilitatorUiTexts.playerCompletion}</span>
				</div>
				<div 
					className={'FacilitatorPlayers-cell lastLogin' 
						+ (sortProperty === 'lastLogin' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('lastLogin');}}
				>
					<span>{facilitatorUiTexts.playerLastLogin}</span>
				</div>
				<div className="FacilitatorPlayers-cell remove">
					<span>{facilitatorUiTexts.remove}</span>
				</div>
				<div className="FacilitatorPlayers-cell edit">
					<span>{facilitatorUiTexts.editAccess}</span>
				</div>
				<div className="FacilitatorPlayers-cell report">
					<span>{facilitatorUiTexts.reports}</span>
				</div>
			</div>
			<div className="FacilitatorPlayers-body">
				{sortedGamePlayers.map((player, index) => {
					const lastLogin = (player.lastLogin ? dayjs(player.lastLogin).format('MM/DD-YY') : '-');

					return (
						<div 
							key={index} 
							className="FacilitatorPlayers-row"
							onClick={() => {setSelectedPlayerId(player.id);}}
						>
							<div className="FacilitatorPlayers-cell name"><span>{player.name}</span></div>
							<div className="FacilitatorPlayers-cell email"><span>{player.email}</span></div>
							<div className="FacilitatorPlayers-cell completion">
								<span>{player.completion.toFixed(2) + '%'}</span>
							</div>
							<div className="FacilitatorPlayers-cell lastlogin"><span>{lastLogin}</span></div>
							<div className="FacilitatorPlayers-cell remove">
								{!coFacilitatorData && <Button 
									text="X" 
									classes={['removeUser']} 
									onClick={(e) => {e.stopPropagation(); setRemovePlayerId(player.id);}}
								/>}
							</div>
							<div className="FacilitatorPlayers-cell edit">
								{(!coFacilitatorData || coFacilitatorData.canToggleDepartmentAccess) && <Button 
									text="-" 
									classes={['editUser']} 
									onClick={(e) => {e.stopPropagation(); setEditPlayerId(player.id);}}
								/>}
							</div>
							<div className={'FacilitatorPlayers-cell report' + 
							(checkIfAllReportsAreSeen(player.yearbookReports, userId) ? ' seen' : '')}>
								{player.yearbookReports.length !== 0 && <Button 
									text="!" 
									classes={['reportUser']} 
									onClick={(e) => {e.stopPropagation(); setReportPlayerId (player.id);}}
								/>}
							</div>
						</div>		
					);
				})}
			</div>
			{selectedPlayerId && 
				<FacilitatorPlayerPopup 
					deviceInfo={deviceInfo}
					gameData={gameData}
					playerData={gamePlayers.find((p) => {return p.id === selectedPlayerId;})}
					togglePopup={setSelectedPlayerId}
				/>}
			{removePlayerId &&
				<FacilitatorRemovePlayerPopup 
					gameId={gameData.id}
					playerData={gamePlayers.find((p) => {return p.id === removePlayerId;})}
					setRemovePlayerId={setRemovePlayerId}
					removePlayerFromGame={removePlayerFromGame}
				/>}
			{editPlayerId &&
				<FacilitatorEditPlayerPopup 
					gameId={gameData.id}
					playerData={gamePlayers.find((p) => {return p.id === editPlayerId;})}
					setEditPlayerId={setEditPlayerId}
					updatePlayerAreaIds={updatePlayerAreaIds}
				/>}

			{reportPlayerId  &&
				<FacilitatorPlayerReportsPopup 
					reportPlayerId={reportPlayerId}
					gamePlayers={gamePlayers}
					setReportPlayerId={setReportPlayerId}
					markReportAsSeen={markReportAsSeen}
					userId={userId}
					deleteReport={deleteReport}
					coFacilitatorData={coFacilitatorData}
				/>}
		</div>
	);
};

FacilitatorPlayers.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	pageData: PropTypes.object.isRequired,
	coFacilitatorData: PropTypes.object,
	gameData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	removePlayerFromGame: PropTypes.func.isRequired,
	updatePlayerAreaIds: PropTypes.func.isRequired,
	markReportAsSeen: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	deleteReport: PropTypes.func.isRequired,
};

export default FacilitatorPlayers;
