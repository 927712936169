const moduleTasks = [
	'modules/tasks/branching-story/icon-arrow-down.svg',
	'modules/tasks/dialogue/carts.svg',
	'modules/tasks/dialogue/checkinglist.svg',
	'modules/tasks/dialogue/icon-select-answer.svg',
	'modules/tasks/dialogue/trashcan.svg',
	'modules/tasks/multiple-choice/high-five/icon-pow.svg',
	'modules/tasks/paper-doll/apron-full-a.svg',
	'modules/tasks/paper-doll/apron-full-f-b.svg',
	'modules/tasks/paper-doll/apron-full-m-b.svg',
	'modules/tasks/paper-doll/apron-half-a.svg',
	'modules/tasks/paper-doll/apron-half-b.svg',
	'modules/tasks/paper-doll/badge-a.svg',
	'modules/tasks/paper-doll/badge-b.svg',
	'modules/tasks/paper-doll/bag-a.svg',
	'modules/tasks/paper-doll/bag-of-bird-seeds-a.svg',
	'modules/tasks/paper-doll/baseball-bat-a.svg',
	'modules/tasks/paper-doll/beard-net-a.svg',
	'modules/tasks/paper-doll/beef-slab-a.svg',
	'modules/tasks/paper-doll/book-a.svg',
	'modules/tasks/paper-doll/box-cutter-a.svg',
	'modules/tasks/paper-doll/bucket-a.svg',
	'modules/tasks/paper-doll/christmas-sweater-a.svg',
	'modules/tasks/paper-doll/cloth-a.svg',
	'modules/tasks/paper-doll/clown-hair-a.svg',
	'modules/tasks/paper-doll/ears-a.svg',
	'modules/tasks/paper-doll/ears-b.svg',
	'modules/tasks/paper-doll/fish-a.svg',
	'modules/tasks/paper-doll/flour-a.svg',
	'modules/tasks/paper-doll/flower-a.svg',
	'modules/tasks/paper-doll/flower-b.svg',
	'modules/tasks/paper-doll/gloves-plastic-a.svg',
	'modules/tasks/paper-doll/gloves-plastic-b.svg',
	'modules/tasks/paper-doll/gloves-rubber-a.svg',
	'modules/tasks/paper-doll/gloves-rubber-b.svg',
	'modules/tasks/paper-doll/gloves-white-a.svg',
	'modules/tasks/paper-doll/gloves-white-b.svg',
	'modules/tasks/paper-doll/goggles-a.svg',
	'modules/tasks/paper-doll/hair-net-a.svg',
	'modules/tasks/paper-doll/hair-net2-a.svg',
	'modules/tasks/paper-doll/hammer-a.svg',
	'modules/tasks/paper-doll/hand-sanitizer-a.svg',
	'modules/tasks/paper-doll/hard-hat-a.svg',
	'modules/tasks/paper-doll/hat-green-a.svg',
	'modules/tasks/paper-doll/hat-green-b.svg',
	'modules/tasks/paper-doll/hat-white-a.svg',
	'modules/tasks/paper-doll/hat-white-2-a.svg',
	'modules/tasks/paper-doll/ice-bucket-a.svg',
	'modules/tasks/paper-doll/keys-a.svg',
	'modules/tasks/paper-doll/knife-a.svg',
	'modules/tasks/paper-doll/knife-sharpener-a.svg',
	'modules/tasks/paper-doll/knife2-a.svg',
	'modules/tasks/paper-doll/knife3-a.svg',
	'modules/tasks/paper-doll/life-jacket-a.svg',
	'modules/tasks/paper-doll/life-jacket-b.svg',
	'modules/tasks/paper-doll/lucky-clover-a.svg',
	'modules/tasks/paper-doll/map-a.svg',
	'modules/tasks/paper-doll/meat-cleaver-a.svg',
	'modules/tasks/paper-doll/meat-grinder-a.svg',
	'modules/tasks/paper-doll/nametag-a.svg',
	'modules/tasks/paper-doll/nametag-b.svg',
	'modules/tasks/paper-doll/oven-timer-a.svg',
	'modules/tasks/paper-doll/paper-a.svg',
	'modules/tasks/paper-doll/paper-bag-a.svg',
	'modules/tasks/paper-doll/peeler-a.svg',
	'modules/tasks/paper-doll/pencil-a.svg',
	'modules/tasks/paper-doll/pencil-and-pad-a.svg',
	'modules/tasks/paper-doll/phone-a.svg',
	'modules/tasks/paper-doll/plant-mister-a.svg',
	'modules/tasks/paper-doll/plant-mister-b.svg',
	'modules/tasks/paper-doll/rag-wet-clean-a.svg',
	'modules/tasks/paper-doll/rag-wet-dirty-a.svg',
	'modules/tasks/paper-doll/rolling-pin-a.svg',
	'modules/tasks/paper-doll/safety-vest-a.svg',
	'modules/tasks/paper-doll/safety-vest-b.svg',
	'modules/tasks/paper-doll/scales-a.svg',
	'modules/tasks/paper-doll/scissor-a.svg',
	'modules/tasks/paper-doll/scissor-b.svg',
	'modules/tasks/paper-doll/scotch-tape-a.svg',
	'modules/tasks/paper-doll/smile-a.svg',
	'modules/tasks/paper-doll/spray-bottle-a.svg',
	'modules/tasks/paper-doll/thermometer-a.svg',
	'modules/tasks/paper-doll/tools.svg',
	'modules/tasks/paper-doll/umbrella-a.svg',
	'modules/tasks/paper-doll/vegetables-a.svg',
	'modules/tasks/paper-doll/wet-floor-sign-a.svg',
	'modules/tasks/paper-doll/whistle-a.svg',
];

export {
	moduleTasks
};
