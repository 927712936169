const moduleBakery1 = [
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-01.svg',
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-02.svg',
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-05.svg',
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-07.svg',
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-08.svg',
	'modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-10.svg',

	'modules/tasks/image/bakery-1-cakes-fallen-over.svg',
	'modules/tasks/image/bakery-1-cakes-stacked.svg',
	'modules/tasks/image/bakery-1-department.svg',
	'modules/tasks/image/bakery-1-donuts-on-shelves.svg',

	'modules/tasks/instructions/bread-on-table.svg',

	'modules/tasks/multiple-choice/bakery-1-cupcake-label/background.svg',
	'modules/tasks/multiple-choice/bakery-1-cupcakes/option-1.svg',
	'modules/tasks/multiple-choice/bakery-1-cupcakes/option-2.svg',
	'modules/tasks/multiple-choice/bakery-1-cupcakes/option-3.svg',
	'modules/tasks/multiple-choice/bakery-1-cupcakes/option-4.svg',
	'modules/tasks/multiple-choice/bakery-1-donuts/option-1.svg',
	'modules/tasks/multiple-choice/bakery-1-donuts/option-2.svg',
	'modules/tasks/multiple-choice/bakery-1-donuts/option-3.svg',
	'modules/tasks/multiple-choice/bakery-1-donuts/option-4.svg',
	'modules/tasks/multiple-choice/bakery-1-food-below-standards/option-1.svg',
	'modules/tasks/multiple-choice/bakery-1-food-below-standards/option-2.svg',
	'modules/tasks/multiple-choice/bakery-1-food-below-standards/option-3.svg',
	'modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-1.svg',
	'modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-2.svg',
	'modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-3.svg',
	'modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-4.svg',
	'modules/tasks/multiple-choice/bakery-1-knife-angle/option-1.svg',
	'modules/tasks/multiple-choice/bakery-1-knife-angle/option-2.svg',
	'modules/tasks/multiple-choice/bakery-1-knife-angle/option-3.svg',

	'modules/tasks/organize/bakery-1-cupcakes/cupcake-1-final.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-1.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-2-final.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-2.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-3-final.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-3.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-4-final.svg',
	'modules/tasks/organize/bakery-1-cupcakes/cupcake-4.svg',
	'modules/tasks/organize/bakery-1-cupcakes/shelves.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-1.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-2.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-3.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-4.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-5.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-6.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-7.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-8.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-9.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-10.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-11.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-12.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-1.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-2.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-3.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-4.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-5.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-6.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-7.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-8.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-9.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-10.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-11.svg',
	'modules/tasks/organize/bakery-1-donuts/donut-bunch-12.svg',
	'modules/tasks/organize/bakery-1-donuts/remove.svg',
	'modules/tasks/organize/bakery-1-donuts/shelves.svg',

	'modules/tasks/spot-errors/bakery-1-cookies.svg',
	'modules/tasks/spot-errors/bakery-1-department.svg',
	'modules/tasks/spot-errors/bakery-1-muffin-display.svg',
	'modules/tasks/spot-errors/bakery-1-stacked-cakes.svg',
	'modules/tasks/spot-errors/bakery-1-table.svg',
];

export {
	moduleBakery1
};
