import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {loginRequest} from 'config/auth.config';
import Login from 'components/login/login';
import UserController from 'components/users/user-controller';
import './auth.scss';

const Auth = ({deviceInfo, scrollToTop}) => {
	const {instance} = useMsal();
	const {accounts} = useMsal();
	
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	
	/**
	 * Handle login
	 * @returns 
	 */
	const handleLogin = () => {
		if (isLoggingIn) return;

		setIsLoggingIn(true);
		return instance.loginRedirect(loginRequest);	
	};

	/**
	 * Handle logout
	 * @returns 
	 */
	const handleLogout = () => {
		return instance.logoutRedirect();
	};

	return (
		<>
			<UnauthenticatedTemplate>
				<Login 
					isLoggingIn={isLoggingIn} 
					handleLogin={handleLogin} 
					deviceInfo={deviceInfo}
				/>
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>
				<UserController 
					accounts={accounts} 
					deviceInfo={deviceInfo} 
					scrollToTop={scrollToTop} 
					handleLogout={handleLogout}
				/>
			</AuthenticatedTemplate>
		</>
	);
};

Auth.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default Auth;
