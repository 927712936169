import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {sortPoints} from 'data/points-data';
import {shuffleArray} from 'helpers/array-helper';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import SortDndContainer from './sort-dnd-container';
import SortDndItem from './sort-dnd-item';
import SortDndPreview from './sort-dnd-preview';
import './sort.scss';

const Sort = (props) => {
	const {
		areaId, 
		deviceInfo, 
		playerTaskData, 
		taskData, 
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask,
		isFacilitator = false
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Get items to be sorted */
	const getItems = () => {
		/* Get items from player data or data file */
		let items = shuffleArray(taskData.items.map((item) => {return {id: item.id, containerId: null};})); 
		if (
			playerTaskData && 
			playerTaskData.sortedItems && 
			playerTaskData.sortedItems.length === taskData.items.length
		) {
			items = playerTaskData.sortedItems;
		}

		/* Special case: if facilitator and completed, show correct solution */
		if (isFacilitator && isCompleted) {
			items = taskData.items.map((item) => {
				return {id: item.id, containerId: item.categoryIds[0]};
			});
		}

		/* Return */
		return items;
	};

	/* Track sorted items & errors */
	const [sortedItems, setSortedItems] = useState(getItems());
	const [errors, setErrors] = useState(0);

	/* Update sorted items if new task */
	useEffect(() => {
		const items = getItems();
		setSortedItems(items);
		setErrors(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = sortedItems.find((item) => {return item.id === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, containerId) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Move item */
		let newSortedItems = JSON.parse(JSON.stringify(sortedItems));
		const itemIndex = newSortedItems.findIndex((item) => {return item.id === itemId;});
		newSortedItems[itemIndex].containerId = containerId;
		setSortedItems(newSortedItems);

		/* Check if it is an error */
		let newErrors = errors;
		const itemData = taskData.items.find((itemData) => {return itemData.id === itemId;});
		const isCorrect = (itemData.categoryIds.indexOf(containerId) >= 0);
		if (!isCorrect) {
			newErrors += 1;
			setErrors(newErrors);
		}

		/* Update streak */
		const instantEffects = [{type: 'streak', isCorrectAnswer: isCorrect}];

		/* Check if task is completed: all items are placed into a category && it is the right category */
		if (
			newSortedItems.every((item) => {return item.containerId !== null;}) && 
			newSortedItems.every((item) => {
				const itemData = taskData.items.find((itemData) => {return itemData.id === item.id;});
				return (itemData.categoryIds.indexOf(item.containerId) >= 0);
			})
		) {
			completeTask(newSortedItems, newErrors, instantEffects);
		} else {
			handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSortedItems, newErrors, instantEffects) => {
		/* Return if not all items have been sorted */
		if (newSortedItems.some((item) => {return item.containerId === null;})) return;

		/* Calculate points */
		let points = sortPoints.minPoints;
		let pointIndex = sortPoints.pointLimits.findIndex((limit) => {return newErrors <= limit;});
		if (pointIndex >= 0) points = sortPoints.pointValues[pointIndex];


		/* Save completed task */
		handleCompleteTask(
			'sort', 
			points, 
			newErrors, 
			instantEffects,
			{sortedItems: newSortedItems}
		);
	};

	return (
		<div className={'Sort ' + areaId + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			<div className="Sort-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
				/>
			</div>
			<div className={'Sort-categories ' + (taskData.layout ? taskData.layout : '')}>
				{taskData.categories && taskData.categories.map((categoryData) => {
					return (
						<div key={categoryData.id} 
							className={'Sort-category ' + (taskData.layout ? taskData.layout : '')}
						>
							{taskData.withHeader && <div className="Sort-categoryTitle">
								<span>{categoryData.text}</span>
							</div>}
							<SortDndContainer
								layout={taskData.layout}
								containerId={categoryData.id}
								handleFindItem={handleFindItem}
								handleMoveItem={handleMoveItem}
							>
								{sortedItems.filter((item) => {
									return item.containerId === categoryData.id;
								}).map((item) => {
									const itemData = taskData.items.find((itemData) => {
										return itemData.id === item.id;
									});
									if (!itemData) return null;

									let isDraggable = true;
									let classes = null;
									if (isCompleted) {
										isDraggable  = false;
										classes = ['completed'];
									} else {
										const isPlacedCorrect = (itemData.categoryIds.indexOf(item.containerId) >= 0);
										classes = (isPlacedCorrect ? ['animateCorrect'] : ['animateWrong']);
										if (isPlacedCorrect) isDraggable = false;
									}

									if (taskData.layout) {
										if (!classes) {
											classes = [];
										}

										classes.push(taskData.layout);
									}
									return (
										<SortDndItem 
											key={itemData.id} 
											isDraggable={isDraggable} 
											classes={classes}
											itemId={itemData.id}
											isFacilitator={isFacilitator}
										>
											<span>{itemData.text}</span>
										</SortDndItem>
									);
								})}
							</SortDndContainer>
						</div>
					);
				})}
			</div>
			<div className={'Sort-items ' + (taskData.layout ? taskData.layout : '')}>
				{sortedItems.filter((i) => {return i.containerId === null;}).map((item) => {
					const itemData = taskData.items.find((itemData) => {
						return itemData.id === item.id;
					});
					if (!itemData) return null;
					const isDraggable = (!playerTaskData || !playerTaskData.isCompleted) && !isFacilitator;
					return (
						<SortDndItem key={item.id}
							isDraggable={isDraggable} 
							itemId={item.id} 
							classes={taskData.layout ? [taskData.layout] : null}>
							<span>{itemData.text}</span>
						</SortDndItem>
					);
				})}
			</div>
			<SortDndPreview itemsData={taskData.items} layout={taskData.layout} />
		</div>
	);
};

Sort.propTypes = {
	areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default Sort;
