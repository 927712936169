const moduleCenterStore2 = [
	'modules/tasks/image/center-store-2-checklist.svg',
	
	'modules/tasks/instructions/u-boat-boxes.svg',

	'modules/tasks/multiple-choice/center-store-2-bag-cereals/option-1.svg',
	'modules/tasks/multiple-choice/center-store-2-bag-cereals/option-2.svg',
	'modules/tasks/multiple-choice/center-store-2-bag-cereals/option-3.svg',
	'modules/tasks/multiple-choice/center-store-2-learned-about-checklist/background.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-1.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-2.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-3.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-4.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-5.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-6.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-7.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-8.svg',
	'modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-9.svg',
];

export {
	moduleCenterStore2
};
