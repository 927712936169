import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {
	getPlayerModulesData,
	getPlayerSpecialPoints,
	getPlayerItems,
	getPlayerBadges,
	createNewPlayerModule,
	getPlayerModuleSession,
	getPlayerModuleSessionById,
	starNewSession, 
	getTimeSinceLastActivity, 
	getLastPlayedTaskId, 
	checkIfAllTasksAreCompleted,
	checkIfModuleSessionIsCompleted,
	getNewStreakData
} from 'helpers/module-helper';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {getNewTierBadges, getTriggeredBadges} from 'helpers/badges-helper';
import {errorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import Loading from 'components/loading/loading';
import Module from './module';
import Results from './results/results';
import ImageLoader from 'components/ui/image-loader/image-loader';

class ModuleController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			competitionId: null,
			showResults: false,
			isStartingNewSession: false,
			taskId: null,
			feedbackData: null,
			popupData: null,
			queuedEffects: [],
			streakType: 'error', // error / success
			streakValue: 0,
			loggedTime: 0,
			timestamp: Date.now(),
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Check if competition module */
		const competitionId = (this.props.competitionId ? this.props.competitionId : null);

		/* Get player modules data */
		let playerModulesData = getPlayerModulesData(this.props.playerData, competitionId);

		/* Get player data for this module */
		const moduleIndex = (competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);

		/* Check if module session is completed */
		const moduleSession = getPlayerModuleSession(playerModulesData, moduleIndex);
		const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(moduleSession);

		/* Update last visited module in area */
		this.updateLastVisitedModuleInArea().then(() => {
			/* Show results if module session is completed */
			this.setState({
				competitionId: competitionId,
				showResults: moduleSessionIsCompleted
			}, () => {
				if (moduleIndex < 0) {
					/* Player has just started first session in module, create player module data */
					playerModulesData.push(createNewPlayerModule(this.props.moduleId, competitionId));
					const playerUpdates = (competitionId
						? {competitions: playerModulesData}
						: {modules: playerModulesData}
					);

					this.props.updatePlayerGameData(playerUpdates).then(() => {
						/* Go to first task in module, automatically sets isLoading to false */
						this.handleGoToTask(
							playerModulesData[playerModulesData.length - 1].sessions[0].currentTaskId
						);
					});
				} else {
					/* Player has started module, go to last played task, automatically sets isLoading to false */
					const taskId = getLastPlayedTaskId(
						playerModulesData[moduleIndex],
						competitionId
					);
					if (taskId) {
						this.handleGoToTask(taskId);
					} else {
						/* No last task id, or last task id not found in module data */
						const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});
						if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
							this.handleGoToTask(moduleData.tasks[0].id);
						} else {
							if (moduleData && moduleData.areaId) {
								this.props.handleGoToPage('area-map', null, moduleData.areaId);
							} else {
								this.props.handleGoToPage('areas');
							}	
						}
					}
				}
			});
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Update logged time for session */
		const loggedTime = this.updateLoggedTime();
		let playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);
		let playerSessionData = getPlayerModuleSession(playerModulesData, moduleIndex);
		const sessionIsCompleted = checkIfModuleSessionIsCompleted(playerSessionData);
		if (!sessionIsCompleted) {
			if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
			playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] 
				= playerSessionData;
			
			const updates = (this.state.competitionId
				? {competitions: playerModulesData}
				: {modules: playerModulesData}
			);
			this.props.updatePlayerGameData(updates);	
		}
		
		
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	};

	/**
	 * Update last visited module in area
	 * @param {bool} isCompetitionModule 
	 * @returns 
	 */
	updateLastVisitedModuleInArea = (isCompetitionModule) => {
		return new Promise((resolve) => {
			if (isCompetitionModule) {
				resolve({status: 'success'});
			} else {
				const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});
				if (moduleData) {
					const playerAreasData = (this.props.playerData.areas 
						? JSON.parse(JSON.stringify(this.props.playerData.areas)) : []);
					const areaIndex = playerAreasData.findIndex((a) => {return a.areaId === moduleData.areaId;});	
					if (areaIndex >= 0) {
						playerAreasData[areaIndex].lastModuleId = moduleData.id;
					} else {
						playerAreasData.push({areaId: moduleData.areaId, lastModuleId: moduleData.id});
					}
					this.props.updatePlayerGameData({areas: playerAreasData}).then(() => {
						resolve({status: 'success'});
					});
				} else {
					resolve({status: 'error', error: 'unknown-module-id'});
				}
			}
		});
	};
	

	/**
	 * Update logged time
	 * @param {bool} resetLoggedTime
	 */
	updateLoggedTime = (resetLoggedTime = false) => {
		/* Get number of miliseconds since last update */
		const miliseconds = getTimeSinceLastActivity(this.state.timestamp);
		const newLoggedTime = this.state.loggedTime + miliseconds;

		this.setState({loggedTime: (resetLoggedTime ? 0 : newLoggedTime), timestamp: Date.now()});
		return newLoggedTime;
	};


	/**
	 * Update current task id for module in player data
	 */
	updateCurrentTaskId = (taskId) => {
		if (!taskId) return;

		/* Get player modules data */
		let playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);

		/* Get module index in player data */
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);

		/* Get module session */
		let moduleSession = getPlayerModuleSession(playerModulesData, moduleIndex);

		if (moduleSession) {
			moduleSession.currentTaskId = taskId;
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = moduleSession;
			const updates = (this.state.competitionId
				? {competitions: playerModulesData}
				: {modules: playerModulesData}
			);
			this.props.updatePlayerGameData(updates);	
		} else {
			console.error('This should not happen!');
		}
	};


	/**
	 * Go to module task
	 * @param {number} taskId 
	 */
	handleGoToTask = (taskId) => {
		/* Log time */
		this.updateLoggedTime();

		/* Go to task */
		this.setState({taskId, isLoading: false}, () => {
			/* Scroll to page top */
			this.props.scrollToTop();

			/* Get module and task data */
			const moduleData = (this.state.competitionId
				? modulesCompetitionsData.find((m) => {return m.id === this.props.moduleId;})
				: modulesData.find((m) => {return m.id === this.props.moduleId;})
			);
			const taskData = (moduleData 
				? moduleData.tasks.find((task) => {return task.id === this.state.taskId;})
				: null
			);

			/* Prepare to update value of currentTaskId */
			let currentTaskId = taskId;
			
			/* Handle special effects */
			if (taskData && taskData.onLoadEffects && taskData.onLoadEffects.length > 0) {
				/* Get queued effects */
				let queuedEffects = (this.state.queuedEffects && this.state.queuedEffects.length > 0 
					? [...this.state.queuedEffects] 
					: []
				);
				let playerItems = getPlayerItems(this.props.playerData);

				taskData.onLoadEffects.forEach((effect) => {
					if (effect.type === 'update-currentTaskId' && effect.value) {
						currentTaskId = effect.value;
					} else if (effect.type === 'avatar-item') {
						/* Avatar item */
						if (effect.avatarItem.itemId) {
							if (!playerItems.some((pi) => {return pi.id === effect.avatarItem.itemId;})) {
								/* Add new item (only if player does not already have it) */
								playerItems.push({type: effect.type, id: effect.avatarItem.itemId, isSeen: false});
								queuedEffects.push(effect);
							}
						} else if (effect.avatarItem.itemIds) {
							let queueEffect = false;
							effect.avatarItem.itemIds.forEach((item) => {
								if (!playerItems.some((pi) => {return pi.id === item;})) {
									playerItems.push({type: effect.type, id: item, isSeen: false});
									queueEffect = true;
								}
							});
							if (queueEffect) {
								queuedEffects.push(effect);
							}
						}
					} else if (effect.type === 'feedback') {
						queuedEffects.push(effect);
					}
				});

				if (queuedEffects) {
					/* Update player data */
					this.props.updatePlayerGameData({
						items: playerItems
					}).then(() => {
						this.updateCurrentTaskId(currentTaskId);
					});
					/* Start processing queued effects */
					this.setState({queuedEffects}, () => {
						this.processQueuedEffects(false);
					});
				} else {
					this.updateCurrentTaskId(currentTaskId);
				}
			} else {
				this.updateCurrentTaskId(currentTaskId);
			}
		});
	};

	/**
	 * Handle instant task effects 
	 * They are triggered during a task (e.g. by selecting an option in multiple choice)
	 * @param {array} effects 
	 */
	handleInstantTaskEffects = (effects) => {
		/* Get queued effects */
		let queuedEffects = (this.state.queuedEffects && this.state.queuedEffects.length > 0 
			? [...this.state.queuedEffects] 
			: []
		);

		/* Get player data */
		const playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);
		const playerBadges = getPlayerBadges(this.props.playerData);
		let playerSpecialPoints = getPlayerSpecialPoints(this.props.playerData);
		let playerItems = getPlayerItems(this.props.playerData);
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Loop over effects */
		let checkBadges = false;
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}			
			if (effect.type === 'special-points' && effect.specialPoints) {
				/* Special points */
				if (!playerSpecialPoints.some((sp) => {return sp.id === effect.specialPoints.id;})) {
					/* Add special points (only if player has not earned them before) */
					playerSpecialPoints.push(effect.specialPoints);
					checkBadges = true;
				}
			}
			if (effect.type === 'avatar-item' && effect.avatarItem) {
				/* Avatar item */
				if (effect.avatarItem.itemId) {
					if (!playerItems.some((pi) => {return pi.id === effect.avatarItem.itemId;})) {
						/* Add new item (only if player does not already have it) */
						playerItems.push({type: effect.type, id: effect.avatarItem.itemId, isSeen: false});
						queuedEffects.push(effect);
					}
				} else if (effect.avatarItem.itemIds) {
					let queueEffect = false;
					effect.avatarItem.itemIds.forEach((item) => {
						if (!playerItems.some((pi) => {return pi.id === item;})) {
							playerItems.push({type: effect.type, id: item, isSeen: false});
							queueEffect = true;
						}
					});
					if (queueEffect) {
						queuedEffects.push(effect);
					}
				}
			}
			if (effect.type === 'streak') {
				/* Success / error Streak */
				checkBadges = true;
				const newStreakData = 
					getNewStreakData(this.state.streakType, this.state.streakValue, effect.isCorrectAnswer);
				newStreakType = newStreakData.newStreakType;
				newStreakValue = newStreakData.newStreakValue;
			}
		});

		if (checkBadges) {
			/* Check for new badge tiers */
			const newTierBadges = getNewTierBadges(
				this.props.playerData.specialPoints, 
				playerSpecialPoints, 
				playerBadges
			);
			if (newTierBadges.length > 0) {
				/* Add new badge tiers to effect queue */
				newTierBadges.forEach((newTierBadge) => {
					queuedEffects.push({
						type: 'feedback',
						feedback: {type: 'new-badge-tier', badgeId: newTierBadge}
					});
				});
			}

			/* Check for new badges */
			const {newBadges, newPlayerBadgesData} = getTriggeredBadges(
				'task-effect',
				playerModulesData, 
				playerBadges,
				playerSpecialPoints,
				newStreakType,
				newStreakValue,
				moduleIndex,
				this.state.competitionId
			);

			if (newBadges.length > 0) {
			/* Add new badges to effect queue */
				newBadges.forEach((newBadge) => {
					queuedEffects.push({
						type: 'feedback',
						feedback: {type: 'new-badge', badgeId: newBadge.id}
					});
				});
			}

			/* Update player data */
			this.props.updatePlayerGameData({
				badges: newPlayerBadgesData, 
				specialPoints: playerSpecialPoints, 
				items: playerItems
			});
		} else {
			/* Update player data */
			this.props.updatePlayerGameData({
				specialPoints: playerSpecialPoints,
				items: playerItems
			});
		}
	
		if (queuedEffects.length > 0) {
			/* Update streak data & start showing queued instant effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Process queued instant effects
	 */
	processQueuedEffects = (delayNextEffect = true) => {
		/* Close all effects */
		this.setState({feedbackData: null, popupData: null}, () => {
			let queuedEffects = [...this.state.queuedEffects];
			if (queuedEffects.length === 0) {
				/* Queue is empty */
				const moduleData = (this.state.competitionId
					? modulesCompetitionsData.find((m) => {return m.id === this.props.moduleId;})
					: modulesData.find((m) => {return m.id === this.props.moduleId;})
				);
				const taskIndex = moduleData.tasks.findIndex((task) => {return task.id === this.state.taskId;});
				const playerModuleSessionData = getPlayerModuleSessionById(
					this.props.playerData, 
					this.props.moduleId, 
					this.state.competitionId
				);
				const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(playerModuleSessionData);
				if (
					moduleSessionIsCompleted && 
					taskIndex + 1 >= moduleData.tasks.length &&
					this.props.moduleId !== 'intro'
				) {
					/* Last task in module completed, show results */
					this.setState({showResults: true});
				}
				
			} else {
				/* Display next effect */
				if (
					queuedEffects[0].type === 'feedback' ||
					queuedEffects[0].type === 'popup'
				) {
					/* Feedback or popup */
					const newQueuedEffects = queuedEffects.slice(1);
					const feedbackData = (queuedEffects[0].type === 'feedback' ? queuedEffects[0].feedback : null);
					const popupData = (queuedEffects[0].type === 'popup' ? queuedEffects[0].popup : null);

					const delay = (delayNextEffect 
						? (queuedEffects[0].type === 'feedback' ? appConfig.feedbackDelay : appConfig.popupDelay)
						: 0
					);
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.setState({feedbackData, popupData, queuedEffects: newQueuedEffects});
					}, delay);	
				}
				if (queuedEffects[0].type === 'avatar-item') {
					const newQueuedEffects = queuedEffects.slice(1);
					let feedbackData = null;
					if (queuedEffects[0].avatarItem.itemId) {
						feedbackData = {
							type: 'avatar-item', 
							itemId: queuedEffects[0].avatarItem.itemId,
							text: queuedEffects[0].avatarItem.text,
						};
					} else if (queuedEffects[0].avatarItem.itemIds) {
						feedbackData = {
							type: 'avatar-item', 
							itemId: queuedEffects[0].avatarItem.itemIds[0],
							text: queuedEffects[0].avatarItem.text,
						};
					}
					const delay = (delayNextEffect ? appConfig.feedbackDelay : 0);
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.setState({feedbackData, queuedEffects: newQueuedEffects});
					}, delay);	
				}
			}
		});
	};


	/**
	 * Complete intro module
	 */
	handleCompleteIntro = () => {
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);
		
		/* Get player modules data */
		let playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);
		let moduleSession = getPlayerModuleSession(playerModulesData, moduleIndex);
		if (moduleSession) {
			/* Flag intro as completed */
			moduleSession.isCompleted = true;

			/* Update logged time */
			if (!moduleSession.hasOwnProperty('milisecondsPlayed')) moduleSession.milisecondsPlayed = 0;
			moduleSession.milisecondsPlayed = moduleSession.milisecondsPlayed + loggedTime;	
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] 
				= moduleSession;

			/* Update player data */
			const updates = (this.state.competitionId
				? {competitions: playerModulesData}
				: {modules: playerModulesData}
			);

			this.props.updatePlayerGameData(updates).then(() => {
				this.props.handleGoToPage('module', 'orientation-1');
			});
		}
	};

	/**
	 * Handle a completed task
	 * @param {string} type 
	 * @param {number} points 
	 * @param {number} errors 
	 * @param {*object} taskData 
	 */
	handleCompleteTask = (type, points, errors, effects, taskData) => {	
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get streak data */ 
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Get task data */	
		const moduleData = (this.state.competitionId
			? modulesCompetitionsData.find((m) => {return m.id === this.props.moduleId;})
			: modulesData.find((m) => {return m.id === this.props.moduleId;})
		);
		const taskId = (moduleData && moduleData.tasks.some((task) => {return task.id === this.state.taskId;})
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;}).taskId
			: null
		);
		if (!taskId) {
			console.error('Completing task - but no task data found. Should not happen!');
			console.error('Task id: ', this.state.taskId);
			return;
		}

		/* Get player data */
		let playerSpecialPoints = getPlayerSpecialPoints(this.props.playerData);
		let playerItems = getPlayerItems(this.props.playerData);
		let playerBadges = getPlayerBadges(this.props.playerData);
		let playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);
		let playerSessionData = getPlayerModuleSession(playerModulesData, moduleIndex);

		if (!playerSessionData) {
			console.error('Completing task - but no module / session found in database. Should not happen!');
			return;
		}

		/* Update logged time for session */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	

		/* Prepare array of effects to show */
		let queuedEffects = [];

		/* Handle effects */
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}			
			if (effect.type === 'special-points' && effect.specialPoints) {
				/* Special points */
				if (!playerSpecialPoints.some((sp) => {return sp.id === effect.specialPoints.id;})) {
					/* Add special points (only if player has not earned them before) */
					playerSpecialPoints.push(effect.specialPoints);
				}
			}
			if (effect.type === 'avatar-item' && effect.avatarItem) {
				/* Avatar item */
				if (effect.avatarItem.itemId) {
					if (!playerItems.some((pi) => {return pi.id === effect.avatarItem.itemId;})) {
						/* Add new item (only if player does not already have it) */
						playerItems.push({type: effect.type, id: effect.avatarItem.itemId, isSeen: false});
						queuedEffects.push(effect);
					}
				} else if (effect.avatarItem.itemIds) {
					let queueEffect = false;
					effect.avatarItem.itemIds.forEach((item) => {
						if (!playerItems.some((pi) => {return pi.id === item;})) {
							playerItems.push({type: effect.type, id: item, isSeen: false});
							queueEffect = true;
						}
					});
					if (queueEffect) {
						queuedEffects.push(effect);
					}
				}
			}
			if (effect.type === 'streak') {
				/* Success / error Streak */
				const newStreakData = 
					getNewStreakData(this.state.streakType, this.state.streakValue, effect.isCorrectAnswer);
				newStreakType = newStreakData.newStreakType;
				newStreakValue = newStreakData.newStreakValue;
			}
		});

		/* Update points */
		playerSessionData.points += points;

		/* Add completed task to module */
		let taskObj = Object.assign({}, {
			isCompleted: true,
			taskId: taskId,
			type: type,
			points: points,
			errors: errors
		}, (taskData ? taskData : {}));
		if (!playerSessionData.tasks) playerSessionData.tasks = [];
		playerSessionData.tasks.push(taskObj);

		/* Check if all tasks in module are complete */
		const moduleTasksAreCompleted = checkIfAllTasksAreCompleted(
			this.props.moduleId, 
			playerSessionData, 
			this.state.competitionId
		);
		playerSessionData.moduleTasksAreCompleted = moduleTasksAreCompleted;
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;

		if (moduleTasksAreCompleted) {
			/* Log module max points */
			playerModulesData[moduleIndex].maxPoints = Math.max(
				(playerModulesData[moduleIndex].maxPoints ? playerModulesData[moduleIndex].maxPoints : 0),
				playerSessionData.points
			);

			/* Log module max stars */
			playerModulesData[moduleIndex].maxStars = Math.max(
				(playerModulesData[moduleIndex].maxStars ? playerModulesData[moduleIndex].maxStars : 0),
				getNumberOfFilledStars(
					playerSessionData.points,
					getModuleMaxPoints(this.props.moduleId, (this.state.competitionId ? true : false))
				)
			);
		}

		/* Check for new badge tiers */
		const newTierBadges = getNewTierBadges(
			getPlayerSpecialPoints(this.props.playerData), // original special points
			playerSpecialPoints, // updated special points 
			playerBadges
		);
		if (newTierBadges.length > 0) {
			/* Add new badge tiers to effect queue */
			newTierBadges.forEach((newTierBadge) => {
				queuedEffects.push({
					type: 'feedback',
					feedback: {type: 'new-badge-tier', badgeId: newTierBadge}
				});
			});
		}

		/* Check for new badges */
		const {newBadges, newPlayerBadgesData} = getTriggeredBadges(
			'complete-task',
			playerModulesData, 
			playerBadges,
			playerSpecialPoints,
			newStreakType,
			newStreakValue,
			moduleIndex,
			this.state.competitionId
		);
		if (newBadges.length > 0) {
			/* Add new badges to effect queue */
			newBadges.forEach((newBadge) => {
				queuedEffects.push({
					type: 'feedback',
					feedback: {type: 'new-badge', badgeId: newBadge.id}
				});
			});
		}

		/* Update player data */
		const updates = {
			badges: newPlayerBadgesData,
			specialPoints: playerSpecialPoints,
			items: playerItems
		};
		if (this.state.competitionId) {
			updates.competitions = playerModulesData;
		} else {
			updates.modules = playerModulesData;
		}
		this.props.updatePlayerGameData(updates);

		
		if (queuedEffects.length > 0) {
			/* Update streak data & start showing queued effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Complete module session
	 */
	handleCompleteModuleSession = () => {
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get player data */
		const playerSpecialPoints = getPlayerSpecialPoints(this.props.playerData);
		const playerBadges = getPlayerBadges(this.props.playerData);
		let playerModulesData = getPlayerModulesData(this.props.playerData, this.state.competitionId);
		const moduleIndex = (this.state.competitionId
			? playerModulesData.findIndex((m) => {return m.competitionId === this.state.competitionId;})
			: playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;})
		);
		let playerSessionData = getPlayerModuleSession(playerModulesData, moduleIndex);
		
		/* Update logged time & isCompleted flag */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;
		playerSessionData.isCompleted = true;
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;

		/* Get new badges */
		const {newBadges, newPlayerBadgesData} = getTriggeredBadges(
			'finish-module-session',
			playerModulesData, 
			playerBadges,
			playerSpecialPoints,
			this.state.streakType,
			this.state.streakValue,
			moduleIndex,
			this.state.competitionId
		);
		
		if (newBadges.length > 0) {
			/* Add new badges to effect queue */
			let queuedEffects = [];
			newBadges.forEach((newBadge) => {
				queuedEffects.push({
					type: 'feedback',
					feedback: {type: 'new-badge', badgeId: newBadge.id}
				});
			});

			/* Update player data */
			const updates = {
				badges: newPlayerBadgesData
			};
			if (this.state.competitionId) {
				updates.competitions = playerModulesData;
			} else {
				updates.modules = playerModulesData;
			}
			this.props.updatePlayerGameData(updates);

			/* Start showing queued effects */
			this.setState({queuedEffects}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* No new badges, go directly to results */
			const updates = (this.state.competitionId
				? {competitions: playerModulesData}
				: {modules: playerModulesData}
			);
			this.props.updatePlayerGameData(updates);
			if (this.props.moduleId !== 'intro') this.setState({showResults: true});
		}
	};

	/**
	 * Handle reset module
	 */
	handleStartNewSession = () => {
		this.setState({isStartingNewSession: true, loggedTime: 0, timestamp: Date.now()}, () => {
			const playerModulesData = starNewSession(
				this.props.moduleId, 
				this.props.playerData, 
				this.state.competitionId
			);
			if (playerModulesData) {
				/* Reset module */
				const updates = (this.state.competitionId
					? {competitions: playerModulesData}
					: {modules: playerModulesData}
				);
				this.props.updatePlayerGameData(updates).then(() => {
					const moduleData = (this.state.competitionId
						? modulesCompetitionsData.find((m) => {return m.id === this.props.moduleId;})
						: modulesData.find((m) => {return m.id === this.props.moduleId;})
					);
					const taskId = (moduleData && moduleData.tasks && moduleData.tasks.length > 0 
						? moduleData.tasks[0].id
						: null
					);
					/* Hide result, go to first task in module */
					this.setState({
						isStartingNewSession: false,
						showResults: false,
						taskId: taskId,
					});
				});
			} else {
				/* Module not found (should not happen) */
				this.setState({isStartingNewSession: false});
			}
		});
	};


	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-module" deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Get module and module task data */
		const moduleData = (this.state.competitionId 
			? modulesCompetitionsData.find((m) => {return (m.id === this.props.moduleId);})
			: modulesData.find((m) => {return m.id === this.props.moduleId;})
		);

		/* Module not found */
		if (!moduleData) {
			return <div>{errorUiTexts.unknownModuleId}: {this.props.moduleId}</div>;
		}

		/* Show results page */
		if (this.state.showResults) {
			return (
				<Results 
					isStartingNewSession={this.state.isStartingNewSession}
					navigationHistory={this.props.navigationHistory}
					competitionId={this.state.competitionId}
					moduleData={moduleData} 
					playerData={this.props.playerData} 
					handleGoToPage={this.props.handleGoToPage}
					handleStartNewSession={this.handleStartNewSession}
				/>
			);
		}

		/* Get task data */
		const taskData = (moduleData 
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;})
			: null
		);
		if (!taskData) {
			console.error(this.props.moduleId);
			console.error(this.state.taskId);
			return (
				<div>{errorUiTexts.unknownModuleTaskId}: {this.state.taskId}</div>
			);
		}
		
		/* Render component */
		return (
			<>
				<Module
					hasQueuedEffects={this.state.queuedEffects.length > 0 || this.state.queuedEffects.length > 0}
					deviceInfo={this.props.deviceInfo}
					navigationHistory={this.props.navigationHistory}
					competitionId={this.state.competitionId}
					gameData={this.props.gameData}
					moduleData={moduleData} 
					taskData={taskData}
					playerData={this.props.playerData}
					feedbackData={this.state.feedbackData}
					popupData={this.state.popupData}
					updateLoggedTime={this.updateLoggedTime}
					handleGoToTask={this.handleGoToTask}
					handleInstantTaskEffects={this.handleInstantTaskEffects}
					handleCompleteTask={this.handleCompleteTask}
					handleGoToPage={this.props.handleGoToPage}
					processQueuedEffects={this.processQueuedEffects}
					handleCompleteIntro={this.handleCompleteIntro}
					handleCompleteModuleSession={this.handleCompleteModuleSession}
				/>
				<ImageLoader type={'module-' + this.props.moduleId} />
			</>
		);
	}
}

ModuleController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	navigationHistory: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	moduleId: PropTypes.string.isRequired,
	competitionId: PropTypes.string,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default ModuleController;
