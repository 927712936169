import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {adminUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import Admin from 'components/admin/admin';
import apiHelper from 'helpers/api-helper';
import dayjs from 'dayjs';

class AdminController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'games',
			gamesData: [],
			usersData: [],
			tagsData: []
		};
		this.unsubscribeGamesData = null;
		this.unsubscribeUsersData = null;
		this.unsubscribeTagsData = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Subscribe to games, users and tags data */
		Promise.all([
			this.subscribeToGamesData(), 
			this.subscribeToUsersData(), 
			this.subscribeToTagsData()
		]).then((responses) => {
			if (responses[0].status === 'success' && responses[1].status === 'success') {
				this.setState({isLoading: false});
			} else {
				// : handle loading error
				this.setState({isLoading: false});
			}
		});
	}
	
	/**
	 * Subscribe to all games
	 * @returns {Promise}
	 */
	subscribeToGamesData = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();
		
		/* Subscribe to games */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGamesData = db.collection(appConfig.gamesDbName).onSnapshot((querySnapshot) => {
				let gamesData = [];
				querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
				this.setState({gamesData: gamesData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get games data */
				console.error('Could not get games data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all users
	 * @returns {Promise}
	 */
	subscribeToUsersData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUsersData !== null) this.unsubscribeUsersData();
			
		/* Subscribe to users */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUsersData = db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let usersData = [];
				querySnapshot.forEach((doc) => {usersData.push({id: doc.id, ...doc.data()});});
				this.setState({usersData: usersData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get users data */
				console.error('Could not get users data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all tags
	 * @returns {Promise}
	 */
	subscribeToTagsData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeTagsData !== null) this.unsubscribeTagsData();
			
		/* Subscribe to tags */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeTagsData = db.collection(appConfig.tagsDbName).onSnapshot((querySnapshot) => {
				let tagsData = [];
				querySnapshot.forEach((doc) => {tagsData.push({id: doc.id, ...doc.data()});});
				this.setState({tagsData: tagsData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get tags data */
				console.error('Could not get tags data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Create game
	 */
	createGame = (storeName, url, facilitatorEmails) => {
		return new Promise((resolve) => {
			apiHelper('admin/create-game', {
				created: dayjs(new Date()).format('YYYY-MM-DD'),
				createdByUserId: this.props.userData.id,
				facilitatorEmails : facilitatorEmails,
				storeName: storeName,
				storeAddress1: null,
				storeAddress2: null,
				url: url,
			}).then(
				(response) => {
					// Success
					if (response.status === 'success' && response.gameId) {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	deleteGame = (gameId) => {
		return new Promise((resolve) => {
			apiHelper('admin/delete-game', {gameId}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Add facilitator(s) to game
	 * @param {string} gameId 
	 * @param {array} emails 
	 */
	addFacilitatorsToGame = (gameId, emails) => {
		return new Promise((resolve) => {
			/* Get game data */
			const gameData = this.state.gamesData.find((game) => {return game.id === gameId;});

			if (gameData) {
				/* Get game facilitator emails */
				let facilitatorEmails = (gameData.facilitatorEmails && gameData.facilitatorEmails.length > 0 
					? [...gameData.facilitatorEmails] : []);

				/* Get game co-facilitators */
				let coFacilitators = (gameData.coFacilitators && gameData.coFacilitators.length > 0
					? [...gameData.coFacilitators] : []);

				let feedback = [];
				let promises = [];
				let addedFacilitators = 0;
				let removedCoFacilitators = 0;
				emails.forEach((email) => {
					/* Get user data by e-mail (if they exist) */
					const userData = this.state.usersData.find((user) => {return user.email === email;});
					if (facilitatorEmails.indexOf(email) >= 0) {
						/* E-mail is already added to game */
						if (userData) {
							/* User exists */
							const feedbackText = JSON.parse(JSON.stringify(
								adminUiTexts.addFacilitatorToGamePopup.alreadyFacilitator))
								.replace(/%name%/g, userData.name);
							feedback.push(feedbackText);
						} else {
							/* User does not exist */
							const feedbackText = JSON.parse(JSON.stringify(
								adminUiTexts.addFacilitatorToGamePopup.alreadyInvited))
								.replace(/%name%/g, email);
							feedback.push(feedbackText);
						}
					} else {
						/* Add e-mail to game */
						facilitatorEmails.push(email);
						addedFacilitators += 1;

						/* Remove as co-facilitator  */
						const coFacilitatorIndex = coFacilitators.findIndex((c) => {return c.email === email;});
						if (coFacilitatorIndex >= 0) {
							coFacilitators.splice(coFacilitatorIndex, 1);
							removedCoFacilitators += 1;
						}

						if (userData) { 
							/* User exists */
							if (userData.role === 'player') {
								/* Give user "facilitator" role */
								promises.push(this.updateUserData(userData.id, {role: 'facilitator'}));
							}
							/* User name in feedback text */ 
							feedback.push(JSON.parse(JSON.stringify(
								adminUiTexts.addFacilitatorToGamePopup.facilitatorAdded))
								.replace(/%name%/g, userData.name));
						} else {
							/* User does not exist, user email in feedback text */
							feedback.push(JSON.parse(JSON.stringify(
								adminUiTexts.addFacilitatorToGamePopup.facilitatorAdded))
								.replace(/%name%/g, email));
						}	
					}
				});

				if (addedFacilitators > 0) {
					if (removedCoFacilitators > 0) {
						promises.push(this.updateGameData(gameId, {facilitatorEmails, coFacilitators}));
					} else {
						promises.push(this.updateGameData(gameId, {facilitatorEmails}));
					}
					Promise.all(promises).then(() => {
						resolve({status: 'success', feedback: feedback});
					}).catch((error) => {
						console.error(error);
						resolve({status: 'error', feedback: error});
					});
				} else {
					resolve({status: 'error', feedback: feedback});
				}
				
			} else {
				resolve({status: 'error', feedback: 'noGame'});
			}
		});
	};

	/**
	 * Remove facilitator from game
	 * @param {*} gameId 
	 * @param {*} email 
	 */
	removeFacilitatorFromGame = (gameId, facilitatorEmail) => {
		return new Promise((resolve) => {
			apiHelper('admin/remove-facilitator', {gameId, facilitatorEmail}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Delete facilitator
	 * Remove them from all games and give "player" role
	 * @returns 
	 */
	deleteFacilitator = (role, facilitatorId, facilitatorEmail) => {
		return new Promise((resolve) => {
			apiHelper('admin/delete-facilitator', {role, facilitatorId, facilitatorEmail}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Update game data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGameData = (gameId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.gamesDbName).doc(gameId).update(updates);
	};

	/**
	 * Update tags data (add or delete a tag)
	 * @param {object} tagData 
	 * @param {string} action 
	 * @returns 
	 */
	updateTagsData = (tagData, action) => {
		if (action === 'add') {
			/* Add new tag */
			const db = firebase.firestore();
			return db.collection(appConfig.tagsDbName).doc(tagData.id).set({value: tagData.value});
		}
		if (action === 'delete') {
			/* Delete existing tag */
			const db = firebase.firestore();
			return db.collection(appConfig.tagsDbName).doc(tagData.id).delete();
		}
	};

	/**
	 * Update user data
	 * @param {string} userId 
	 * @param {object} updates 
	 * @returns 
	 */
	updateUserData = (userId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}
		
		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};

	/**
	 * Go to page
	 * @param {string} page 
	 * @param {string} subpage 
	 */
	handleGoToPage = (page, subpage = null) => {
		this.setState({page: page, subpage: subpage});
	};

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-admin-data" deviceInfo={this.props.deviceInfo} />
			);
		}

		return (
			<Admin 
				page={this.state.page} 
				userData={this.props.userData}
				gamesData={this.state.gamesData}
				usersData={this.state.usersData}
				tagsData={this.state.tagsData}
				updateGameData={this.updateGameData}
				updateTagsData={this.updateTagsData}
				createGame={this.createGame}
				deleteGame={this.deleteGame}
				addFacilitatorsToGame={this.addFacilitatorsToGame}
				removeFacilitatorFromGame={this.removeFacilitatorFromGame}
				deleteFacilitator={this.deleteFacilitator}
				handleGoToPage={this.handleGoToPage}
				handleLogout={this.props.handleLogout}
				deviceInfo={this.props.deviceInfo}
				checkGameUrlUniqueness={this.checkGameUrlUniqueness}
			/>
		);
	}
}

AdminController.propTypes = {
	userData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default AdminController;
