import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import Character from '../character/character';
import './notification-manager.scss';

const NotificationManager = ({notificationData, gameData, handleCloseNotification}) => {
	/* Manager id */
	const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);

	/* Manager pose */
	const managerPose = (notificationData.pose ? notificationData.pose : '1');

	return (
		<div 
			className={'NotificationManager ' + notificationData.type} 
			onClick={() => {handleCloseNotification(notificationData.id);}}
		>
			<div className="NotificationManager-content">
				<div className="NotificationManager-text">
					{renderMarkdown(notificationData.text)}
				</div>
				<div className="NotificationManager-manager">
					<Character 
						page="area-notification" 
						characterId={'manager-' + managerPose}
						managerId={managerId}
					/>
				</div>
			</div>
		</div>
	);
};

NotificationManager.propTypes = {
	notificationData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	handleCloseNotification: PropTypes.func.isRequired
};

export default NotificationManager;
