const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'outside',
		characterPose: 1,
		showAvatar: true,
		text: `Well now that you fully exist. How about we go inside the store and get started?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'outside',
		characterPose: 2,
		showAvatar: true,
		speechBubbleDirection: 'middle',
		extraCharacters: [{id: 'wizard-1', type: 'middle'}],
		text: `Wait just a minute, I said we are going on an adventure!`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'outside',
		characterPose: 2,
		showAvatar: true,
		extraCharacters: [
			{id: 'wizard-1', type: 'middle'},
			{id: 'poof', type: 'poof'},
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: '2 ocean',
		showAvatar: true,
		avatarPose: 'ocean',
		extraCharacters: [
			{id: 'wizard-1 ocean', type: 'ocean'},
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: '3 ocean',
		showAvatar: true,
		avatarPose: 'ocean',
		extraCharacters: [
			{id: 'wizard-side ocean', type: 'ocean'},
		],
		text: `Did you just strand us in the ocean?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: '2 ocean',
		showAvatar: true,
		avatarPose: 'ocean',
		extraCharacters: [
			{id: 'wizard-side ocean', type: 'ocean'},
		],
		text: `\\*Sigh\\* Let's start picking up some of the major pieces we need for store operations.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'orientation-1-spot-errors-orientation-1-pick-stuff',
		layout: 'orientation-1-pick-stuff',
		text: 'Find the needed items',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'timeclock'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'fire-extinguisher'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'first-aid-kit'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'home-phone'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'employee-handbook'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'bathroom-sign'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'exit-sign'})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		background: 'ocean-island',
		text: `Over Here. I found an island to get us out of that water.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'ocean-island',
		showAvatar: true,
		text: `Great Job. All these are essential items in the store.`,
		items: ['box-items'],
		
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-apron',
		image: 'manager',
		showManager: true,
		image: 'manager-center-right',
		subtype: 'images',
		layout: 'orientation-1-apron',
		text: `While you were searching, I found this for you. Now you are offically an employee of this ... well, mess that Wizard got us into.`,
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemIds: ['apron-f-1', 'apron-f-2', 'apron-f-3', 'apron-m-1', 'apron-m-2', 'apron-m-3', 'name-tag'],
							text: 'Apron and name tag has been added to your avatar.'					
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'ocean-island',
		showAvatar: true,
		text: `Guess we will play the Wizard's game and train at the same time.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'ocean-island',
		text: `Let's look at the Information Board`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-information-board',
		subtype: 'images',
		layout: 'orientation-1-information-board',
		text: 'Choose the High Five one first.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'We aren\'t ready for that just yet.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'We aren\'t ready for that just yet.'
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Getting customers to have us be their top choice is our priorty. `,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-high-five-steps',
		text: 'What are our High Five Steps?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Quality',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
				text: 'Cleanliness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true,
				text: 'Stock Level',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true,
				text: 'Signage/Pricing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: true,
				text: 'Customer Service',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				isCorrect: false,
				text: 'Wizardry',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-high-five',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'high-five',
		text: 'Correct. Those are the 5 steps. How about an actual High Five.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Oh ya!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You missed.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-safe-product',
		text: 'Which of these steps ensures safe and edible product?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Quality',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
				text: 'Cleanliness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Stock Level',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Signage/Pricing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'Customer Service',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `That's right. The store being clean is just as important as food handling.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-customer-understanding',
		text: 'Which of these helps customers understand what they are buying?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false,
				text: 'Quality',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Cleanliness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Stock Level',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true,
				text: 'Signage/Pricing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'Customer Service',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-control',
		text: 'Great Work. Now which of these are you able to control?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'All of them',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Cleanliness and stock level',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Signage and customer service',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Just quality',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'None of them',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `That is correct. You are responsible for each of these steps. Know them well.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-valuable-asset',
		text: 'What is our most valuable asset?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Me',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes, you.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Manager',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Me? I\'m only a digital guide.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Wizard',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Product',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'Store front',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-information-board-guest',
		subtype: 'images',
		layout: 'orientation-1-information-board',
		text: 'Go ahead and get the GUEST clipboard.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `GUEST is what we use to focus on our customers.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-what-is-guest',
		text: 'Do you know what GUEST stands for?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Greet, Understand, Exceed, Serve, Thank',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Very well done.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Give, Understand, Excel, Smile, Timing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Guests Use Every Single Towel',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Great, Unbelievable, Extraordinary, Successful, Tireless',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `So what does this really mean?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `When guests feel understood, respected, and appreciated, they will return.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Where is that wizard? Have him conjure a customer for us.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: `Finally, I'm needed.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		extraCharacters: [
			{id: 'poof', type: 'poof-2'},
		],
	}),
	// ORIENTATION DIALOG 1 OD-1
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'orientation-1-dialogue-wizard-customer',
		characterId: 'wizard-customer',
		maxPoints: 8,
		introCharacter: 'wizard-customer',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-01',
				text: 'Excuse me. I need some help. Do you know where the bathroom is?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Yes. All the way to the back beside the dairy cooler.', 
						nextStepId: 'OD-02',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2,
						text: 'No. I am new here.',
						nextStepId: 'OD-03',
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-02',
				text: 'Thank you',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Actually, let me take you there.', 
						nextStepId: 'OD-04',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-03',
				text: 'But don\'t you wash your hands somewhere?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Actually, I can help you. Let me take you there.', 
						nextStepId: 'OD-04',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-04',
				text: 'Even better.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Happy to help. Let me know if there is anything else you need.', 
						nextStepId: 'OD-05',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-05',
				text: 'Thank you. I\'m glad you showed up.',
				autoContinue: false,
				nextStepId: 'OD-06',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'OD-06',
				text: 'The customer needed the restroom, but by taking them there we UNDERSTOOD and EXCEEDED their expectations.',
				isFinalStep: true,
				showPoints: true,
				character: 'manager-2',
			}),
		]
	}),
	// ORIENTATION DIALOG 1 OD-1 DONE
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: 'Excuse me again, but your bathroom is flooded because we are in the ocean.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		characterType: 'manager',
		extraCharacters: [
			{id: 'wizard-side', type: 'right'},
		],
		text: 'That\'s your fault. Now shoo.'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		text: 'Safety refers to anything that risk physically hurting a customer or an employee.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Food safety is making sure all products are stored, rotated, and cooked correctly.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-hot-food',
		text: 'So if food that a customer buys to eat or drink is too hot to be eaten without burning their mouth, which is it?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Safety',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, that\'s an immediate threat to their body.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Food Safety',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-old-meat',
		text: 'What about if they consume a sandwich with meat that went out of date?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false,
				text: 'Safety',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
				text: 'Food Safety',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, the customer could get sick from eating it.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-protecting',
		text: 'Food Safety and Safety are important to protecting whom?',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false,
				text: 'Customer',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Yourself',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Other employees',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'The wizard',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: true,
				text: 'All of the above',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly, we want everyone safe, even that crazy wizard.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'orientation-1-order-of-important',
		text: `Sort the following in order of importance where 1 is most important.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'fire', text: 'Store Fire', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'leaking-water', text: 'Pallet of leaking water', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'out-of-stock', text: 'Paper aisle has many items out of stock', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'canned-veggies', text: 'Canned vegetables needs to be blocked', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'wizard', text: 'The wizard is using "magic" to make the balloons float', correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Fire would be the biggest deal. May never happen, but always be prepared.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-product-never',
		text: 'Where should product NEVER be?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'On the floor',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'In the backroom',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'In the freezer',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Top shelf',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'Behind the register',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-product-floor',
		text: 'Leaving product on the floor can cause what issues?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: 'Tripping hazard',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Contamination',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				text: 'Eye sore',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Lost product',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
				text: 'Pests',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-leaking-pallet-tools',
		subtype: 'images',
		layout: 'orientation-1-leaking-pallet-tools',
		text: 'Speaking of safety, we mentioned a leaking pallet of water before. What do you need for dealing with that?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Safety Vest
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Mop & Bucket
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Wet floor sign
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Spray cleaner
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'It was just water so we shouldn\'t really need any cleaner.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Paper towels
				isCorrect: true,
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'orientation-1-spot-errors-pallet-with-water',
		text: 'Make sure you get everything. Click on all the issues on the image.',
		layout: 'orientation-1-pallet-with-water',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle3'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle4'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'jug1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'jug2',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes, get all the damaged and leaning product also.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Step ladders are extremely helpful, but require attention when using. On the next screen, click on the issues you see.',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'orientation-1-spot-errors-step-ladder',
		layout: 'orientation-1-step-ladder',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'can'}), 
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'child',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'The child is no danger to you. But if the ladder is left up unattended, they could hurt themselves.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'full-hands'})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		text: 'On the next image, see if you can spot the safety issues.',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'orientation-1-spot-errors-store-aisle',
		layout: 'orientation-1-store-aisle',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'utensil',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This may seem like a small thing, but we don\'t know where that utensil has been before.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-cooler-door',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Open cooler doors can create condensation and frost build up which can be slippery. Nice catch!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'fire-exit-blocked'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'no-fire-extinguisher'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Let\'s talk product rotation to ensure safe dating, especially those with a shorter shelf life.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-mc-date-checking',
		text: 'Which of these is not important when checking the dating?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,				
				isCorrect: false,
				text: 'Expiration on product',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Current date',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true,
				text: 'Location on shelf',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'But after checking, be sure to rotate properly'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-fifo',
		text: 'Know what FIFO means?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,				
				isCorrect: false,
				text: 'Fresh In, Fresh Out',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				text: 'Fresh Ice Fermented Onions',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true,
				text: 'First In First Out',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false,
				text: 'Final In First Out',
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'orientation-1-organize-restock-bread',
		layout: 'orientation-1-restock-bread',
		text: `Drag items to appropriate spots on the shelf. Some may need to be removed.`,
		image: 'calendar-april17',
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'bread-blue-1', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'bread-red-1', alt: 'Bread', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'bread-red-2', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'bread-green-1', alt: 'Bread', correctContainerIds: ['a6', 'a7']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'bread-green-2', alt: 'Bread', correctContainerIds: ['a6', 'a7']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'bread-blue-2', alt: 'Bread', correctContainerIds: ['a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'bread-green-3', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 8, containerId: 'c8', image: 'bread-blue-3', alt: 'Bread', correctContainerIds: ['a3', 'a4']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'shelves',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 8}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a10', type: 'shelves'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'remove',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'remove', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'remove', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'remove', finalItemId: 7}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c8', type: 'initial'}),
				]
			})
		],
		doneConditions: [
			{type: 'empty-main-container', mainContainerId: 'initial'}
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'orientation-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Do you know the law? Pareto\'s Law that is.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-multiple-choice-paretos-law',
		text: 'How much of sales will be generated by the most popular 20% of items?',
		image: 'percent2',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '50%',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '25%',
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '80%',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. So know what the best are and keep them in stock.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '90%',
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Almost 100%',
				isCorrect: false
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'orientation-1-multiple-choice-leaving',
		text: 'What should you do before leaving for the day?',
		image: 'clock',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Check tomorrow's schedule`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Properly return equipment', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Clock out', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Wash your hands',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Not a bad idea, but not mandatory.'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'special-points', 
						specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
							id: 'orientation-1-neat-freak', pointId: 'neat-freak', value: 1			
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Say "sea" you later to all the seafood.',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Okay, but WHY would you do that?'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Tell the wizard how I\'ve fared on my quest today',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'I\'m telling you, we\'re not affiliated with that guy.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		text: 'Great work for now. Let\'s meet back again later.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		background: 'ocean-island',
		text: 'I can see your next assignment up ahead. Let\'s swim over to that island.',
		characterItems: ['life-jacket'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['lifejacket-m', 'lifejacket-f'],
					text: 'A lifejacket has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'ori1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};
