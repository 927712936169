import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {adminUiTexts} from 'data/ui-texts';
import {getModulesCompletionPercentage} from 'helpers/module-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import './admin-game-popup-players.scss';

const AdminGamePopupPlayers = ({gameData, usersData}) => {
	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('name');
	const [sortDirection, setSortDirection] = useState('ASC');

	/**
	 * Sort players by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	/* Get game players */
	let gamePlayers = usersData.filter((user) => {
		return (
			user.role === 'player' && 
			user.games &&
			user.games.length > 0 &&
			user.games.some((g) => {return g.id === gameData.id && !g.isRemoved;})
		);
	});
	gamePlayers.forEach((gamePlayer) => {
		const playerGame = gamePlayer.games.find((g) => {return g.id === gameData.id;});
		gamePlayer.completion = getModulesCompletionPercentage(playerGame.modules);
	});

	/* Sorted game players */
	const sortedGamePlayers = sortArrayByProperty(gamePlayers,  sortProperty, sortDirection);


	return (
		<div className="AdminGamePopupPlayers">
			<div className="AdminGamePopupPlayers-header">
				<div 
					className={'AdminGamePopupPlayers-cell name' 
						+ (sortProperty === 'name' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('name');}}
				>
					<span>{adminUiTexts.playerName}</span>
				</div>
				<div 
					className={'AdminGamePopupPlayers-cell email' 
						+ (sortProperty === 'email' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('email');}}
				>
					<span>{adminUiTexts.email}</span>
				</div>
				<div 
					className={'AdminGamePopupPlayers-cell completion' 
						+ (sortProperty === 'completion' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('completion');}}
				>
					<span>{adminUiTexts.completion}</span>
				</div>
				<div 
					className={'AdminGamePopupPlayers-cell lastLogin' 
						+ (sortProperty === 'lastLogin' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('lastLogin');}}
				>
					<span>{adminUiTexts.lastLogin}</span>
				</div>
			</div>
			<div className="AdminGamePopupPlayers-body">
				{sortedGamePlayers.map((player, index) => {
					const lastLogin = (player.lastLogin ? dayjs(player.lastLogin).format('MM/DD-YY') : '-');

					return (
						<div key={index} className="AdminGamePopupPlayers-row">
							<div className="AdminGamePopupPlayers-cell name"><span>{player.name}</span></div>
							<div className="AdminGamePopupPlayers-cell email"><span>{player.email}</span></div>
							<div className="AdminGamePopupPlayers-cell completion">
								<span>{player.completion.toFixed(2) + '%'}</span>
							</div>
							<div className="AdminGamePopupPlayers-cell lastlogin"><span>{lastLogin}</span></div>
						</div>		
					);
				})}
			</div>
		</div>
	);

};

AdminGamePopupPlayers.propTypes = {
	gameData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
};

export default AdminGamePopupPlayers;
