import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {checkCompetition, getParticipatingPlayers} from 'helpers/competition-helper';
import {facilitatorUiTexts, errorUiTexts} from 'data/ui-texts';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {competitionTypesData} from 'data/templates/competition-template';
import {areasData} from 'data/areas-data';
import DatePicker from 'react-datepicker';
import Button from 'components/ui/button/button';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './facilitator-edit-competition-popup.scss';

const FacilitatorEditCompetitionPopup = (props) => {
	const {gameData, gamePlayers, competitionData, editCompetition, setEditCompetitionData} = props;
	
	/* Selected competition type, module, participants */
	const competitionType = (competitionTypesData.some((t) => {return t.id === competitionData.type;})
		? competitionTypesData.find((t) => {return t.id === competitionData.type;})
		: competitionTypesData[0]
	);
	const competitionModuleData = modulesCompetitionsData.find((m) => {return m.id === competitionData.moduleId;});
	
	const allCanParticipate = (!competitionData.selectedAreaIds || competitionData.selectedAreaIds.length === 0);
	const selectedAreaIds = (competitionData.selectedAreaIds ? competitionData.selectedAreaIds : []);

	/* Description */
	const [description, setDescription] = useState(competitionData.description);
	const updateDescription = (event) => {
		setDescription(event.target.value);
		setErrMsg(null);
	};

	/* Prize */
	const [prize, setPrize] = useState(competitionData.prize);
	const updatePrize = (event) => {
		setPrize(event.target.value);
		setErrMsg(null);
	};

	/* Competition dates */
	const initialSelectedStartDate = new Date(dayjs(competitionData.startDate).format());
	const [selectedStartDate, setSelectedStartDate] = useState(new Date(dayjs(competitionData.startDate).format()));
	const [selectedEndDate, setSelectedEndDate] = useState(new Date(dayjs(competitionData.endDate).format()));

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/* Is creating new competition */
	const [isCreating, setIsCreating] = useState(false);
	
	/* Confirm */
	const handleConfirmCompetition = () => {
		if (isCreating) return;

		const newErrMsg = checkCompetition(
			false,
			competitionData.type,
			competitionData.moduleId, 
			selectedStartDate,
			selectedEndDate,
			allCanParticipate,
			selectedAreaIds,
			description,
			prize,
			gameData.competitions
		);

		if (newErrMsg) {
			setErrMsg(newErrMsg);
		} else {
			setIsCreating(true);
			const competitionUpdates = {
				description: description,
				prize: prize,
				startDate: dayjs(selectedStartDate).format('YYYY-MM-DD'),
				endDate: dayjs(selectedEndDate).format('YYYY-MM-DD')
			};
			editCompetition(competitionData.id, competitionUpdates).then((response) => {
				if (response.status === 'success') {
					setEditCompetitionData(null);
				} else {
					setErrMsg(errorUiTexts.unknownError);
					setIsCreating(false);
				}
			});
		}
	};

	/* Get all current players that are able to participate */
	const participatingPlayers = getParticipatingPlayers(gamePlayers, allCanParticipate, selectedAreaIds);


	return (
		<div className="FacilitatorEditCompetitionPopup">
			<div className="FacilitatorEditCompetitionPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorEditCompetitionPopup-header">
					<div className="FacilitatorEditCompetitionPopup-title">
						<span>{facilitatorUiTexts.editCompetitionPopup.title}</span>
					</div>		
					<div
						className="FacilitatorEditCompetitionPopup-closeBtn" 
						onClick={() => {setEditCompetitionData(false);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorEditCompetitionPopup-body'>
					{/* Competition module */}
					<div className='FacilitatorEditCompetitionPopup-selectModule'>
						<span>{facilitatorUiTexts.editCompetitionPopup.module}</span>
						<span>
							{competitionModuleData.title} ({competitionType.title 
								+ (competitionType.id === 'shared-goal' 
									? ', ' + competitionData.stars + ' ' + facilitatorUiTexts.editCompetitionPopup.stars
									: '')
							})
						</span>
					</div>

					{/* Competition dates */}
					<div className='FacilitatorEditCompetitionPopup-competitionSchedule'>
						<div className='FacilitatorEditCompetitionPopup-competitionScheduleLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.scheduleCompetition}</span>
						</div>
						<div className='FacilitatorEditCompetitionPopup-competitionScheduleInput'>
							<DatePicker
								disabled={false}
								selected={selectedStartDate ? selectedStartDate : ''}
								minDate={initialSelectedStartDate}
								onChange={(date) => {setSelectedStartDate(date); setErrMsg(null);}}
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-mm-dd"
							/>
							<DatePicker
								disabled={false}
								selected={selectedEndDate ? selectedEndDate : ''}
								minDate={initialSelectedStartDate}
								onChange={(date) => {setSelectedEndDate(date); setErrMsg(null);}}
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-mm-dd"
							/>
						</div>
					</div>

					{/* Competition players */}
					<div className='FacilitatorEditCompetitionPopup-competitionPlayers'>
						<span>{facilitatorUiTexts.addCompetitionPopup.participants 
							+ ' (' + participatingPlayers.length + ')'}</span>
						<span>
							{(selectedAreaIds.length === 0 
								? facilitatorUiTexts.addCompetitionPopup.allPlayers
								: facilitatorUiTexts.addCompetitionPopup.somePlayers + ':'
							)}
						</span>
						{selectedAreaIds.length > 0 && 
							<div className='FacilitatorEditCompetitionPopup-competitionAreas'>
								{selectedAreaIds.map((areaId, index) => {
									const isLast = (index + 1 === selectedAreaIds.length);
									const areaData = areasData.find((a) => {return a.id === areaId;});
									if (!areaData) return null;
									return (
										<span key={index}>{areaData.title + (isLast ? '.' : ', ')}</span>
									);
								})}
							</div>
						}
					</div>

					{/* Competition description */}
					<div className='FacilitatorEditCompetitionPopup-competitionDescription'>
						<div className='FacilitatorEditCompetitionPopup-competitionDescriptionLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.description}</span>
						</div>
						<div className='FacilitatorEditCompetitionPopup-competitionDescriptionInput'>
							<textarea 
								name="description"
								placeholder={facilitatorUiTexts.addCompetitionPopup.typeHere}
								value={description ? description : ''}
								onChange={(e) => {updateDescription(e);}}
							/>
						</div>
					</div>

					{/* Competition prize */}
					<div className='FacilitatorEditCompetitionPopup-competitionPrize'>
						<div className='FacilitatorEditCompetitionPopup-competitionPrizeLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.prize}</span>
						</div>
						<div className='FacilitatorEditCompetitionPopup-competitionPrizeInput'>
							<textarea 
								name="prize"
								placeholder={facilitatorUiTexts.addCompetitionPopup.typeHere}
								value={prize ? prize : ''}
								onChange={(e) => {updatePrize(e);}}
							/>
						</div>
					</div>

					<div className="FacilitatorEditCompetitionPopup-errorMsg">
						{errMsg && renderMarkdown(errMsg)}
					</div>

					<div className="FacilitatorEditCompetitionPopup-confirmBtn">
						<Button 
							isLoading={isCreating}
							text={facilitatorUiTexts.editCompetitionPopup.edit}
							classes={['download', 'launch']}
							onClick={() => {handleConfirmCompetition();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorEditCompetitionPopup.propTypes = {
	gameData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	competitionData: PropTypes.object.isRequired,
	editCompetition: PropTypes.func.isRequired,
	setEditCompetitionData: PropTypes.func.isRequired
};

export default FacilitatorEditCompetitionPopup;
