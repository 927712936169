/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {avatarData} from 'data/avatar-data';
import {ReactComponent as AvatarSVG} from 'assets/images/avatar-maker/avatar.svg';
import {ReactComponent as AvatarShadowSVG} from 'assets/images/avatar-maker/avatar-shadow.svg';
import {ReactComponent as AvatarBottomSVG} from 'assets/images/avatar-maker/avatar-bottom.svg';
import './avatar.scss';

/* This must always be in the top of the avatar.svg fil (under definitions) */
/* 
	<clipPath id="arm-right-f">
    <polygon points="231.45 364.33 274.69 364.33 274.69 38.1 100.19 38.1 100.19 465.5 231.45 465.5 231.45 364.33" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="arm-left-f">
      <polygon points="143.43 364.33 100.19 364.33 100.19 38.1 274.69 38.1 274.69 465.5 143.43 465.5 143.43 364.33" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="arm-both-f">
      <polygon points="143.43 364.33 100.19 364.33 100.19 38.1 274.69 38.1 274.69 465.5 143.43 465.5 143.43 364.33" clip-path="url(#arm-right-f)" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="arm-right-m">
      <polygon points="239.29 374.33 274.69 374.33 274.69 38.1 100.19 38.1 100.19 465.5 239.29 465.5 239.29 374.33" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="arm-left-m">
      <polygon points="135.59 374.33 100.19 374.33 100.19 38.1 274.69 38.1 274.69 465.5 135.59 465.5 135.59 374.33" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="arm-both-m">
      <polygon points="135.59 374.33 100.19 374.33 100.19 38.1 274.69 38.1 274.69 465.5 135.59 465.5 135.59 374.33" clip-path="url(#arm-right-m)" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="hair-1">
      <rect x="100.25" y="167.6" width="174.5" height="297.9" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="hair-2">
      <polygon points="274.75 465.5 100.25 465.5 100.25 210.65 144.24 167.6 230.79 167.6 274.75 210.62 274.75 465.5" fill="none" stroke-width="0"/>
    </clipPath>
    <clipPath id="hairnet">
      <path d="m185.8,169.62c35,0,37.85,25.06,42.6,37.38,8.86-6.95,9.29-36.69,9.29-45.17,0-23.85-22.1-43.19-51.88-43.19s-51.88,19.34-51.88,43.19c0,8.85,3.61,40.11,12.16,45.17,2.53-12,9.49-37.38,39.72-37.38Z" fill="none" stroke-width="0"/>
    </clipPath>
*/

const Avatar = ({type, areaId, avatarPose, items, playerData}) => {
	let bodyTypeId = null;
	let skinColorId = null;
	let armMask = null;
	let avatarImageClass = 'Avatar-image';

	const showHandHeldItems = (type !== 'yearbook' && !areaId);
	const showHat = (areaId ? false : true);

	/* Find svg paths/groups that should be visible */ 
	avatarData.categories.forEach((category) => {
		/* Get default / selected option  */
		const defaultOption = category.options.find((option) => {return option.isDefault === true;});
		let selectedOption = (defaultOption ? defaultOption : null);
		if (playerData && playerData.avatar && playerData.avatar.hasOwnProperty(category.id)) {
			if (category.options.some((option) => {return option.id === playerData.avatar[category.id];})) {
				selectedOption = category.options.find((option) => {
					return option.id === playerData.avatar[category.id];
				});
			}
		}

		/* Special case: skin color */
		if (category.id === 'skin-color') {
			skinColorId = selectedOption.id;
			return;
		}

		/* Special case: body type */
		if (category.id === 'body-type') bodyTypeId = selectedOption.id;

		/* Add path/group ids to class name */
		if (selectedOption && selectedOption.svgIds && selectedOption.svgIds.length > 0) {
			selectedOption.svgIds.forEach((svgId) => {
				if (!selectedOption.isInHand || showHandHeldItems) { // no hand-held items in yearbook
					if (category.id !== 'hats' || showHat ) {
						avatarImageClass += ' ' + svgId;
						if (svgId.slice(-1) === '_') {
							/* SVG id depends on body type */
							avatarImageClass += (bodyTypeId === 'f' || bodyTypeId === 'f2' ? 'F' : 'M');
						}
					}
				}
			});

			/* Item or animal in hand */
			if (selectedOption.isInHand && showHandHeldItems) {
				if (category.id === 'items') {
					if (!armMask) {
						armMask = 'right';
					} else {
						armMask = 'both';
					}
					avatarImageClass += ' Hand_right_' + (bodyTypeId === 'f' || bodyTypeId === 'f2' ? 'F' : 'M');
				}
				if (category.id === 'animals') {
					if (!armMask) {
						armMask = 'left';
					} else {
						armMask = 'both';
					}
					avatarImageClass += ' Hand_left_' + (bodyTypeId === 'f' || bodyTypeId === 'f2' ? 'F' : 'M');
				}
			}
		}
	});


	/* Get content for decorations / animal categories */
	const decorationCategory = avatarData.categories.find((category) => {return category.id === 'decorations';});
	const animalCategory = avatarData.categories.find((category) => {return category.id === 'animals';});

	/* Get avatar component */
	const isAvatarShadow = (!playerData || !playerData.avatar ? true : false);
	const AvatarComponent = (isAvatarShadow ? AvatarShadowSVG : AvatarSVG);



	return (
		<div 
			className={'Avatar ' + type 
				+ ' body-' + bodyTypeId + (isAvatarShadow ? ' shadow' : '')
				+ (avatarPose ? ' ' + avatarPose : '') 
				+ (areaId ? ' ' + areaId : '') 
			}
		>
			{/* Body */}
			<div 
				className={avatarImageClass 
					+ ' body-' + bodyTypeId 
					+ ' color-' + skinColorId 
					+ (armMask ? ' arm-' + armMask : '')}
			>
				<AvatarComponent />
				{type === 'map-full-body' && <AvatarBottomSVG />}
			</div>
			{/* Decorations */}
			{(type === 'avatar-maker' || type === 'profile') && <div className="Avatar-decorations">
				{decorationCategory.options.map((decoration) => {
					if (decoration.id === 'none') return null;
					const isSelected = (playerData && playerData.avatar && playerData.avatar.decorations === decoration.id);
					if (!isSelected) return null;

					return <div key={decoration.id} className={'Avatar-decoration ' + decoration.id} />;
				})}
			</div>}

			{/* Task items */}
			{items && <div className="Avatar-taskItems">
				{items.map((item) => {
					return <div 
						key={item} 
						className={'Avatar-taskItem ' + item + ' body-' + bodyTypeId} 
					/>;
				})}
			</div>}
			
			{/* Animals */}
			{(type === 'avatar-maker' || type === 'profile' || type === 'yearbook') && <div className="Avatar-animals">
				{animalCategory.options.map((animal) => {
					if (animal.id === 'none') return null;

					const isSelected = (playerData && playerData.avatar && playerData.avatar.animals === animal.id);
					if (!isSelected) return null;

					return <div key={animal.id} className={'Avatar-animal ' + animal.id} />;
				})}
			</div>}
		</div>
	);
};

Avatar.propTypes = {
	type: PropTypes.string.isRequired,
	areaId: PropTypes.string,
	avatarPose: PropTypes.string,
	items: PropTypes.array,
	playerData: PropTypes.object.isRequired
};

export default Avatar;
