import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import Button from 'components/ui/button/button';
import './facilitator-delete-competition-popup.scss';

const FacilitatorDeleteCompetitionPopup = ({competitionData, setDeleteCompetitionData, deleteCompetition}) => {
	/* Competition module data */
	const competitionModuleData = modulesCompetitionsData.find((m) => {return m.id === competitionData.moduleId;});

	/* Competition status */
	let statusMessage = facilitatorUiTexts.finished;
	const today = dayjs(new Date()).format('YYYY-MM-DD');
	if (dayjs(today).diff(dayjs(competitionData.endDate), 'days') <= 0) {
		if (dayjs(today).diff(dayjs(competitionData.startDate), 'days') < 0) {
			statusMessage = facilitatorUiTexts.ready;
		} else {
			const timeLeft = dayjs(competitionData.endDate).diff(dayjs(today), 'days');
			if (timeLeft === 1) {
				statusMessage = timeLeft + ' ' + facilitatorUiTexts.dayLeft;
			} else {
				statusMessage = timeLeft + ' ' + facilitatorUiTexts.daysLeft;
			}
		}
	}

	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	
	/**
	 * Handle delete competition
	 */
	const handleDeleteCompetition = () => {
		if (isLoading) return; 
	
		setErrorMsg(null);
		setIsLoading(true);
			
		deleteCompetition(competitionData.id).then((response) => {
			if (response.status === 'success') {
				setDeleteCompetitionData(null);
			} else {
				console.error(response);
				setIsLoading(false);
			}
		});
	};
	return (
		<div className="FacilitatorDeleteCompetitionPopup">
			<div className="FacilitatorDeleteCompetitionPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorDeleteCompetitionPopup-header">
					<div className="FacilitatorDeleteCompetitionPopup-title">
						<span>{facilitatorUiTexts.deleteCompetitionPopup.title}</span>
					</div>		
					<div
						className="FacilitatorDeleteCompetitionPopup-closeBtn" 
						onClick={() => {setDeleteCompetitionData(null);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorDeleteCompetitionPopup-body'>
					<div className="FacilitatorDeleteCompetitionPopup-text">
						{renderMarkdown(facilitatorUiTexts.deleteCompetitionPopup.text)}
					</div>
					<div className="FacilitatorDeleteCompetitionPopup-competition">
						<div className="FacilitatorDeleteCompetitionPopup-competitionInfo">
							<span>{facilitatorUiTexts.module}:</span>
							<span>{competitionModuleData.title}</span>
						</div>
						<div className="FacilitatorDeleteCompetitionPopup-competitionInfo">
							<span>{facilitatorUiTexts.deleteCompetitionPopup.dates}:</span>
							<span>
								{dayjs(competitionData.startDate).format('MM/DD-YY') + ' - '} 
								{dayjs(competitionData.endDate).format('MM/DD-YY')}
							</span>
						</div>
						<div className="FacilitatorDeleteCompetitionPopup-competitionInfo">
							<span>{facilitatorUiTexts.deleteCompetitionPopup.status}:</span>
							<span>{statusMessage}</span>
						</div>
						
								
					</div>
					<div className="FacilitatorDeleteCompetitionPopup-feedback error">
						{errorMsg && <span>{errorMsg}</span>}
					</div>
					<div className="FacilitatorDeleteCompetitionPopup-buttons">
						<div className="FacilitatorDeleteCompetitionPopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.deleteCompetitionPopup.confirmBtn}
								onClick={() => {handleDeleteCompetition();}}
							/>
						</div>
						<div className="FacilitatorDeleteCompetitionPopup-cancelBtn">
							<Button 
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.deleteCompetitionPopup.cancelBtn}
								onClick={() => {setDeleteCompetitionData(null);}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorDeleteCompetitionPopup.propTypes = {
	competitionData: PropTypes.object.isRequired,
	setDeleteCompetitionData: PropTypes.func.isRequired,
	deleteCompetition: PropTypes.func.isRequired,
};

export default FacilitatorDeleteCompetitionPopup;
