import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {gameUiTexts, mapUiTexts} from 'data/ui-texts';
import {getActiveCompetitions} from 'helpers/competition-helper';
import {getAllMessages} from 'helpers/message-helper';
import {renderMarkdown} from 'helpers/text-helper';
import CompetitionsIcon from 'assets/images/map/seagull.svg';
import MessageIcon from 'assets/images/map/bottle.svg';
import './notifications-game.scss';

const NotificationsGame = (props) => {
	/* Props */
	const {
		type, 
		deviceInfo, 
		gameData, 
		playerData, 
		handleCloseNotification, 
		updatePlayerGameData, 
		handleGoToPage
	} = props;

	/* Get active competition (if any) */
	const activeCompetitions = getActiveCompetitions(playerData, gameData);

	/* Check if player has seen all active competitions */
	const unseenCompetitionIds = [];
	activeCompetitions.forEach((c) => {
		if (
			!playerData.notifications || 
			playerData.notifications.length === 0 ||
			playerData.notifications.indexOf('competition-' + c.id) < 0
		) {
			unseenCompetitionIds.push(c.id);
		}
	});

	/* Get all messages (if any), sorted by creation date (newest first) */
	const messages = getAllMessages(playerData, gameData);

	/* Check if player has seen all messages */
	const unseenMessageIds = [];
	messages.forEach((m) => {
		if (
			!playerData.notifications || 
			playerData.notifications.length === 0 ||
			playerData.notifications.indexOf('message-' + m.id) < 0
		) {
			unseenMessageIds.push(m.id);
		}
	});


	/* Toggle competition popup index */
	const [competitionPopupIndex, setCompetitionPopupIndex] = useState(null);
	const toggleCompetitionPopupIndex = (newIndex = null) => {
		if (
			newIndex >= 0 && 
			activeCompetitions.length > newIndex &&
			unseenCompetitionIds.indexOf(activeCompetitions[newIndex].id) >= 0
		) {
			/* Mark notification as seen */
			handleCloseNotification('competition-' + activeCompetitions[newIndex].id);
		}
		setCompetitionPopupIndex(newIndex);
	};

	/* Toggle message popup index */
	const [messagePopupIndex, setMessagePopupIndex] = useState(null);
	const toggleMessagePopupIndex = (newIndex = null) => {
		let goToIndex = newIndex;

		if (messagePopupIndex === null && newIndex >= 0) {
			/* Player juts opened messages, go to newest unseen message if possible */
			let unseenMessageIndex = -1;
			messages.forEach((m, index) => {
				if (unseenMessageIndex >= 0) return;
				if (unseenMessageIds.indexOf(m.id) >= 0) {
					unseenMessageIndex = index;
				}
			});

			if (unseenMessageIndex >= 0) {
				goToIndex = unseenMessageIndex;
			}
		}

		if (
			goToIndex >= 0 && 
			messages.length > goToIndex &&
			unseenMessageIds.indexOf(messages[goToIndex].id) >= 0
		) {
			/* Mark message as seen */
			handleCloseNotification('message-' + messages[goToIndex].id);
		}
		
		setMessagePopupIndex(goToIndex);
	};

	/**
	 * Flag message as deleted by player
	 * @param {string} messageId 
	 */
	const handleDeleteMessage = (messageId) => {
		const deletedMessageIds = (playerData.deletedMessageIds 
			? JSON.parse(JSON.stringify(playerData.deletedMessageIds)) 
			: []);
		deletedMessageIds.push(messageId);

		const numberOfMessages = messages.length;
		updatePlayerGameData({deletedMessageIds}).then(() => {
			if (messagePopupIndex > 0 && messagePopupIndex + 1 === numberOfMessages) {
				/* Move to prev messsage if deleted message was last */
				toggleMessagePopupIndex(messagePopupIndex - 1);
			}
			if (numberOfMessages === 1) {
				/* Close messages, last one deleted */
				setMessagePopupIndex(null);
			}
		});
	};

	return (
		<div className={'NotificationsGame ' + type + (deviceInfo ? ' ' + deviceInfo.orientation : '')}>
			{/* Nav item: competitions */}
			<div 
				className={'NotificationsGame-button competitions' + (activeCompetitions.length > 0 ? ' active' : '')}
				onClick={() => {toggleCompetitionPopupIndex(0);}}
			>
				<div className="NotificationsGame-buttonIcon competition">
					<img src={CompetitionsIcon} alt={mapUiTexts.competitions} />
					
				</div>
				<div className="NotificationsGame-buttonLabel">{mapUiTexts.competitions}</div>
				{(unseenCompetitionIds.length > 0) && 
					<div className="NotificationsGame-buttonNumber">
						<span>{unseenCompetitionIds.length}</span>
					</div>
				}
			</div>
			
			{/* Nav item: message */}
			<div className="NotificationsGame-button message" onClick={() => {toggleMessagePopupIndex(0);}}>
				<div className="NotificationsGame-buttonIcon"><img src={MessageIcon} alt={mapUiTexts.info} /></div>
				<div className="NotificationsGame-buttonLabel">{mapUiTexts.info}</div>
				{(unseenMessageIds.length > 0) && 
					<div className="NotificationsGame-buttonNumber">
						<span>{unseenMessageIds.length}</span>
					</div>
				}
			</div>

			{/* Messages popup - no messages */}
			{(messagePopupIndex !== null && messagePopupIndex >= 0 && messages.length === 0) && 
				<div className="NotificationsGame-popup message" onClick={() => {setMessagePopupIndex(null);}}>
					<div className="NotificationsGame-popupContent" onClick={(e) => {e.stopPropagation();}}>
						<div className="NotificationsGame-popupMessage simple">
							<div className="NotificationsGame-popupText">
								<p>{mapUiTexts.noMessages}</p>
							</div>
						</div>
					</div>
				</div>
			}

			{/* Message popup - all messages */}
			{(messagePopupIndex !== null && messagePopupIndex >= 0 && messages.length > 0) && 
				<div className="NotificationsGame-popup message" onClick={() => {setMessagePopupIndex(null);}}>
					<div className="NotificationsGame-popupContent" onClick={(e) => {e.stopPropagation();}}>
						{messages.map((m, index) => {
							let style = null;
							if (index === 0) {
								style = {marginLeft: -messagePopupIndex * 100 + '%'};
							}
							return (
								<div 
									key={index} 
									className={'NotificationsGame-popupMessage index-' + index} 
									style={style} 
								>
									<div className="NotificationsGame-popupMessageInfo">
										<span>{m.createdByName}</span>
										<span>{m.created && dayjs(m.created).format('MM/DD/YYYY')}</span>
									</div>
									<div className="NotificationsGame-popupText">
										{m.text && renderMarkdown(m.text)}
									</div>	
									<div 
										className={'NotificationsGame-deleteBtn'} 
										onClick={() => {handleDeleteMessage(m.id);}}
									>
										<span>{gameUiTexts.delete}</span>
									</div>
								</div>
							);
						})}
						<div 
							className={'NotificationsGame-popupNavPrev' + (messagePopupIndex < 1 ? ' hidden' : '')} 
							onClick={() => {toggleMessagePopupIndex(messagePopupIndex - 1);}}
						/>
						<div 
							className={'NotificationsGame-popupNavNext' 
								+ (messagePopupIndex + 1 >= messages.length ? ' hidden' : '')} 
							onClick={() => {toggleMessagePopupIndex(messagePopupIndex + 1);}}
						/>
					</div>
					{messages.length > 0 && <div className="NotificationsGame-popupLocation">
						{messages.map((_, index) => {
							return (
								<div 
									key={index} 
									className={'NotificationsGame-popupLocationDot' 
										+ (messagePopupIndex === index ? ' current' : '')} 
								/>	
							);
						})}
					</div>}
				</div>
			}

			{/* Competitions popup - no active competitions */}
			{(competitionPopupIndex !== null && competitionPopupIndex >= 0 && activeCompetitions.length === 0) && 
				<div className="NotificationsGame-popup" onClick={() => {setCompetitionPopupIndex(null);}}>
					<div className="NotificationsGame-popupContent" onClick={(e) => {e.stopPropagation();}}>
						<div className="NotificationsGame-popupMessage simple">
							<div className="NotificationsGame-popupText">
								<p>{mapUiTexts.noCompetitions}</p>
							</div>
						</div>
					</div>
				</div>
			}
			
			{/* Competitions popup - all active competitions */}
			{(competitionPopupIndex !== null && competitionPopupIndex >= 0 && activeCompetitions.length > 0) && 
				<div className="NotificationsGame-popup competition" onClick={() => {setCompetitionPopupIndex(null);}}>
					<div className="NotificationsGame-popupContent" onClick={(e) => {e.stopPropagation();}}>
						{activeCompetitions.map((c, index) => {
							let style = null;
							if (index === 0) {
								style = {marginLeft: -competitionPopupIndex * 100 + '%'};
							}
							return (
								<div 
									key={index} 
									className={'NotificationsGame-popupMessage index-' + index} 
									style={style} 
								>
									<div className="NotificationsGame-popupSeagull" />
									<div className="NotificationsGame-popupText">
										{c.description && renderMarkdown(c.description)}
										{c.prize && renderMarkdown(c.prize)}
										<p>
											{mapUiTexts.competitionEnds + ': '}
											<span>{c.endDate && dayjs(c.endDate).format('MM/DD/YYYY')}</span>
										</p>
									</div>	
									<div 
										className="NotificationsGame-popupBtn"
										onClick={() => {handleGoToPage('module', c.moduleId, null, c.id);}}
									>
										<span>{mapUiTexts.play}</span>
									</div>
								</div>
							);
						})}
						<div 
							className={'NotificationsGame-popupNavPrev' + (competitionPopupIndex < 1 ? ' hidden' : '')} 
							onClick={() => {toggleCompetitionPopupIndex(competitionPopupIndex - 1);}}
						/>
						<div 
							className={'NotificationsGame-popupNavNext' 
								+ (competitionPopupIndex + 1 >= activeCompetitions.length ? ' hidden' : '')} 
							onClick={() => {toggleCompetitionPopupIndex(competitionPopupIndex + 1);}}
						/>
					</div>
					{activeCompetitions.length > 0 && <div className="NotificationsGame-popupLocation">
						{activeCompetitions.map((_, index) => {
							return (
								<div 
									key={index} 
									className={'NotificationsGame-popupLocationDot' 
										+ (competitionPopupIndex === index ? ' current' : '')} 
								/>	
							);
						})}
					</div>}
				</div>
			}
		</div>
	);
};

NotificationsGame.propTypes = {
	type: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleCloseNotification: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
};

export default NotificationsGame;
