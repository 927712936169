const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		text: `Welcome to Produce: Level 2.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's start with some Production Planning.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-how-much-prepare',
		text: `How much Produce should we prepare?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Enough for next 24 hours`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Enough for next 2 days`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Enough until next delivery`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Same last week`,
			}),		
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-help-know-need-extra',
		subtype: 'images',
		layout: 'produce-2-help-know-need-extra',
		text: `What would help us know if we need extra of anything?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // ad items
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // production planner
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // sanitation
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Cut more of what's on sale.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: `I need a few more trays for tomorrow.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-tray-date-tomorrow',
		text: `Just make them last and date them for tomorrow.`,
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `No`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Definitely not. That violates the Sell By date.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Ok`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `How do you change the scale?`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `I'll ask Manager`,
			}),		
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's take these trays to sales floor.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `These go in the Multi-Deck display.`,
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'produce-2-organize-cut-fruit',
		layout: 'produce-2-cut-fruit',
		text: `Put them in place.`,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 1, containerId: 'a1', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 2, containerId: 'a2', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 3, containerId: 'a3', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 4, containerId: 'a4', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 5, containerId: 'a5', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 6, containerId: 'a6', image: 'cut-fruit', alt: 'cut fruit'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 7, containerId: 'a7', image: 'cut-fruit', alt: 'cut fruit'}),

		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b4', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b5', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b6', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b7', type: 'final'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'initial'}),
				]
			}),
		],
		doneConditions: [
			Object.assign({}, taskTemplates.organizeTemplate.doneConditionTemplate, {
				type: 'empty-main-container', mainContainerId: 'initial'
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-filled-full-ok',
		text: `We filled it full. That ok?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `No`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Yes`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Looks great`,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-filled-full-why-not-ok',
		text: `Why not?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `No space to breathe`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. The display needs air flow.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Too hard to remove`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Made too much`,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `On to the bananas.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-banana-layers',
		text: `How many layers for banana display?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `1`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Never stack.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `2`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `3`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `4`,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-how-put-organics',
		subtype: 'images',
		layout: 'produce-2-how-put-organics',
		text: `How do we put the organics in?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // seperated by solid piece of wood or divider
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Non-organic and Organic foods must be separated by a barrier.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // shouldered next to each other
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // spaced way apart, no divider
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-do-while-cull',
		text: `What can we do while we cull?`,
		image: null,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Clean shelves`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Check temperature of case`, isCorrect: true,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Stock`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Snack`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Check for bags`, isCorrect: true,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-2-spot-errors-fruit-stands',
		text: `How's this display?`,
		layout: 'produce-2-fruit-stands',
		errors: [
			// Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'missing-cool-label'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'label'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'price'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'stacking'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-display-bagged-apples',
		subtype: 'images',
		layout: 'produce-2-display-bagged-apples',
		text: `Which is the way to display bagged?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-display-peppers',
		subtype: 'images',
		layout: 'produce-2-display-peppers',
		text: `For peppers, how do you display?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-tomatoes-layers',
		text: `How many layers high for tomatoes?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `1 - 2`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `2 - 3`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `4 - 5`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `7 - 8`,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-cooler-at-night',
		text: `Which item should be taken to cooler at night?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Asparagus`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Lettuce`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Celery`,
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Cucumbers`,
			}),	
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: 'Hello, adventurer! I have a question for you. '
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-banana-joke',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		image: 'wizard',
		text: 'What did the Banana say to the orange when it had to leave the party?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Orange you glad I didn't say Banana?`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `This ain't a knock knock joke.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Time for me to split`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'HAHA! Yep.'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'banana-headdress',
							text: 'A Banana Headdress has been added to your avatar.'					
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text:`Let's peel out`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'HAHA! Yep.'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemId: 'banana-headdress',
							text: 'A Banana Headdress has been added to your avatar.'					
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Let's bunch up`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Bunch a nope.'
						})
					}),
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Well since "Banana Man" was here, let's talk about Bananas. They can be a lot of work, but they are also sort of like gold.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-bananas-ideal-number',
		subtype: 'images',
		layout: 'produce-2-bananas-ideal-number',
		shuffleOptions: false,
		text: `What is the ideal Number for bananas to sell?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `They are ripe enough for most customers.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6,}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 7,}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-banana-gold',
		text: 'Why are bananas like gold?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Because of their color', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Because banana boxes can be used for everything', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Because their peels can trip up thine enemies',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: `Yes they can! But that's probably not the answer your manager is looking for.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Because they are of the best selling fruit items', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Because they are stored at Fort Knox',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: 'No, I checked. No food inside the building.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: `Because they're very expensive per ounce`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: `At least not if we store them properly so we don't have to throw them out.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'produce-2-order-receiving-bananas',
		text: 'Bananas need to be handled in a specific manner. What is the correct order of business when receiving bananas?',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Unstack from pallets', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Check temperature with pulp thermometer', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Decide whether to remove lid', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Cross stack for air circulation', correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Stock on sales floor', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-banana-temperatures',
		text: 'Bananas need a fairly specific range of tempatures at receiving. What is it?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '55 - 58', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '51 - 55',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '40 - 45',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '62 - 65',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '38 - 40',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-green-bananas',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'produce-2-banana-boxes',
		text: 'Our bananas came in green this week. Lids on or off?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Lid on
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, leaving the lid on will help ripen them.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, //Lid off
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Not a good idea if we need them on the sales floor soon.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Also, remember to never put bananas in the cooler - they'll turn brown.`
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-2-spot-errors-refrigerated-case',
		text: `Click on the issues that need to be fixed. Click done when you think you have them all. Note: today's date is March 14th.`,
		layout: 'produce-2-refridgerated-produce-1',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'salads'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'finger-prints'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wrong-placement'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'price-tag'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-produce-table',
		text: 'How about the produce table - should you restock it before the shop closes?',
		image: 'produce-table',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Getting the floor ready first thing in the morning is more appropriate.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly! Concentrate on sanitizing and cleaning and then stock again next morning.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'produce-2-spot-errors-service-case',
		text: 'Click on the issues that need to be fixed. Click done when you think you have them all.',
		layout: 'produce-2-refridgerated-produce-2',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'temperature',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'plastic-clam',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'uneven-case',
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Okay, let's take a situation that could happen in the department.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-lunch-rush',
		isDilemma: true,
		numberOfAnswersToSelect: 1,
		maxPoints: 3,
		text: `It's almost lunch. You are on the sales floor near the apple bin ready to cull and stock again before the rush. What do you do first?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Refrigerated case',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'story',
							text: 'As you walk past the lady, she makes an annoyed sound because you walked right past her.'
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Refrigerated self service case',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'story',
							text: 'As you walk past the lady, she makes an annoyed sound because you walked right past her.'
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Banana table',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'story',
							text: 'As you walk past the lady, she makes an annoyed sound because you walked right past her.'
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Talk to the lady standing near the apples',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: 'Yes, any customer within 10 feet should be greeted.'
						})
					}),
					Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
				],
			}),
		]
	}),
	// PRODUCE DIALOGUE 1 STARTS HERE
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'produce-2-dialogue-apple-pie',
		characterId: 'old-lady',
		maxPoints: 13,
		introText: 'You approach the lady near the apples.',
		introTextType: 'info',
		introCharacter: 'old-lady',
		introShowAvatar: true,
		steps: [					
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 1,
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Howdy, ma'am.`, nextStepId: 2
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Hello, can I help you with anything?', nextStepId: 3,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 3
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Hello, did you see that we have two packs of paper plates for the price of one?', nextStepId: 4
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 2,
				text: 'Um, yes. Hi.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Can I help you with anything?', nextStepId: 3
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'What can I do for you today?', nextStepId: 3
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 3,
				text: 'This is my first time in the store, and I can\'t find all the things I need for an apple pie.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Sure thing. Those are on aisles 5, 7, and 10. Can\'t miss them', nextStepId: 6
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `Of course. Follow me, And I'll show you where the cinnamon is. The rest is easy.`, nextStepId: 7,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 1
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Of course, Follow me. I\'ll help you get each one.', nextStepId: 8,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'produce-2-helping-hand-1', pointId: 'helping-hand', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 4,
				text: 'Sorry, why would I need paper plates? I just want to make an apple pie.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Do you need help finding what you need for it?', nextStepId: 9,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 1
							}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Well, we have lots of apples right here.', nextStepId: 5
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 5,
				text: 'Yes, but this is my first time in your shop, so I don\'t know where the rest of the items are.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'That\'s easy. Those are on aisles 5, 7, and 10. Can\'t miss them.', nextStepId: 6
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'I see. Follow me, And I\'ll show you to the cinnamon. The rest is easy.', nextStepId: 7,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 1
							}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Alright. Follow me. I\'ll help you get each one.', nextStepId: 8,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'produce-2-helping-hand-2', pointId: 'helping-hand', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 6,
				type: 'action',
				text: 'She walks away in search of the items. You are sure she\'ll find them. Eventually.',
				autoContinue: false,
				showPoints: true,
				isFinalStep: true,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 7,
				type: 'action',
				text: 'You show her the cinnamon and point her in the direction of the rest. She\'ll find it, you\'re sure of that.',
				autoContinue: false,
				showPoints: true,
				isFinalStep: true,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 8,
				text: 'Thank you. Hopefully I can remember next time I shop here.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'I\'m sure you can.', nextStepId: 9,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'If not, feel free to ask anytime.', nextStepId: 9,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'produce-2-smooth-talker-1', pointId: 'smooth-talker', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 9,
				type: 'action',
				text: 'You help her find the items. Now what?',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Do you want some vanilla ice cream too?', nextStepId: 10,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'That should do it. You\'re all set.', nextStepId: 11,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Right this way to the registers, ma\'am.', nextStepId: 12,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 10,
				text: 'I already have that, but thank you.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Alright, that should do it then. You\'re all set.', nextStepId: 11,
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Sure thing. Right this way to the registers, ma\'am.', nextStepId: 12,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 11,
				type: 'action',
				text: 'She thanks you and walk to the registers.',
				autoContinue: false,
				showPoints: true,
				isFinalStep: true,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 12,
				text: 'Oh, thank you.',
				optionPrompt: 'Choose answer',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Thank you for shopping with us today.', nextStepId: 13,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 1
							}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Do you need anyone to walk these out to your car?', nextStepId: 14,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'points',
								value: 2
							}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'produce-2-helping-hand-3', pointId: 'helping-hand', value: 1
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 13,
				type: 'dialogue',
				text: 'And thank you for your help today.',
				autoContinue: false,
				showPoints: true,
				nextStepId: 15,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 14,
				type: 'dialogue',
				text: 'Yes, please. That would be very helpful.',
				autoContinue: false,
				showPoints: true,
				nextStepId: 16,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 15,
				text: 'She goes home to make an apple pie while you return to stocking in produce.',
				type: 'action',
				isFinalStep: true,
				showPoints: true,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 16,
				text: 'You carry her bags to the parking lot and place them in the car. She thanks you and leaves. She\'ll definitely be back.',
				type: 'action',
				isFinalStep: true,
				showPoints: true,
			}),
		]		
	}),// PRODUCE DIALOG 1 DONE FD-1 DONE
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-how-high-pack-in-ice',
		subtype: 'images',
		layout: 'produce-2-how-high-pack-in-ice',
		text: `How high to pack items in ice?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, isCorrect: true}), // 2/3
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2,}), // 1/3
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3,}), // almost top
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4,}), // 1/2
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-why-mist-produce',
		text: `Why do we mist produce?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `To prolong freshness`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `To keep away bugs`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `To add weight`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `To clean it`,
			}),		
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-how-much-water',
		text: `How much of Broccoli and Lettuces are water?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `80%+`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's why misting is such a big deal.`
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `30%`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `10%`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `50%`,
			}),		
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'We need to crisp the vegetables that came in today. But first, wash your hands.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-wash-hands',
		text: 'For how long should you wash your hands?',
		image: 'sink',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '30 seconds', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'One minute',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '45 seconds',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '15 seconds',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Two minutes',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Back to crisping, which helps hydrate the produce from water lost in transit.'
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'produce-2-order-lettuce-crisping',
		image: 'lettuce',
		text: 'When crisping, what is the correct order to do things in? Press done when you think you have it.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Pull off browning leaves', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Trim a thin slice off the bottom', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Soak in sink for 5 min', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Put in the tote', correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Put in cooler for 4 hours', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'produce-2-order-vegetables-crisping',
		image: 'vegetables',
		text: `What's the correct order when crisping long stalk style vegetables?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Pull off any bad stalks', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Trim the butt end off', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Rubberband bunches', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Place bottoms down in couple inches of water', correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Place in cooler for several hours', correctOrderNumbers: [5],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'This step really prolongs the quality and health of the produce.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-mc-not-in-cooler',
		subtype: 'images',
		layout: 'produce-2-not-in-cooler',
		text: `Which of these should not be in cooler?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Asparagus
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // lunch
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // Bananas
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // Floral
			}),
		]
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'produce-2-multiple-choice-last-things-to-check',
		text: 'What last things to check before clocking out?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Cooler door', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Nothing out. Door closed.`
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Lights', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Save energy.`
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Supplies closet',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Bathroom',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `That's great work today.`,
		characterItems: ['beard-11'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'beard-11',
					text: 'A Lettuce Beard has been added to your avatar.'
				})
			}),
		]
	}),
]


let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'pro2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};