import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts, generalUiTexts} from 'data/ui-texts';
// import {renderMarkdown} from 'helpers/text-helper';
import logoMDIImg from 'assets/images/facilitator/logo-mdi.svg';
import Button from 'components/ui/button/button';
import './select-section.scss';

const SelectSection = ({deviceInfo, userData, handleGoToSection, handleLogout}) => {

	return (
		<div className={'SelectSection ' + deviceInfo.orientation}>
			{/* Header */}
			<div className="SelectSection-header">
				{/* Logo */}
				<div  className="SelectSection-logo"><img src={logoMDIImg} alt={generalUiTexts.mdiLogo} /></div>

				{/* Logout / info buttons */}
				<div className="SelectSection-buttons">
					<Button classes={['logout']} text={loginUiTexts.logout} onClick={handleLogout} />
				</div>

				{/* User info */}
				<div className="SelectSection-user">
					<div className="SelectSection-userInfo">
						<div><span>{loginUiTexts.loggedInAs}:</span></div>
						<div><span>{userData.email}</span></div>
						<div><span>{userData.name}</span></div>
					</div>
				</div>

				{/* Title */}
				<div className="SelectSection-title">
					<span>{generalUiTexts.welcome}</span>
				</div>
			</div>


			{/* Content */}
			<div className="SelectSection-content">
				{/* Admin section */}
				{userData.role === 'admin' && <div className="SelectSection-section">
					<div className="SelectSection-sectionText"><span>{generalUiTexts.goToAdmin}</span></div>
					<div className="SelectSection-sectionBtn">
						<Button 
							classes={['blue', 'selectSection']}
							text={generalUiTexts.ok} 
							onClick={() => {handleGoToSection('admin');}}
						/>
					</div>
				</div>}
				{userData.role === 'admin' && <div className="SelectSection-or"><span>{generalUiTexts.or}</span></div>}

				{/* Facilitator section */}
				<div className="SelectSection-section">
					<div className="SelectSection-sectionText"><span>{generalUiTexts.goToFacilitator}</span></div>
					<div className="SelectSection-sectionBtn">
						<Button 
							classes={['blue', 'selectSection']} 
							text={generalUiTexts.ok} 
							onClick={() => {handleGoToSection('facilitator');}}
						/>
					</div>
				</div>
				<div className="SelectSection-or"><span>{generalUiTexts.or}</span></div>

				{/* Game section */}
				<div className="SelectSection-section">
					<div className="SelectSection-sectionText"><span>{generalUiTexts.goToGame}</span></div>
					<div className="SelectSection-sectionBtn">
						<Button 
							classes={['blue', 'selectSection']}
							text={generalUiTexts.ok} 
							onClick={() => {handleGoToSection('game');}}
						/>
					</div>
				</div>
			</div>
		</div>


	);

};

SelectSection.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	handleGoToSection: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default SelectSection;
