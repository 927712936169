const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		showAvatar: true,
		text: `Welcome to the Deli. Let's get started.`,
		background: 'ocean-island',
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'deli-1-paper-doll-items',
		showToolbox: false,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-half', 
				type: 'alternative', 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Half apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full', 
				type: 'bodytype', 
				isCorrect: true, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'nametag', 
				type: 'alternative', 
				isCorrect: true, 
				dependsOnBodyHeight: true,
				title: 'Name tag'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-white', 
				type: 'alternative', 
				isCorrect: true, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Plastic gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hair-net', 
				isCorrect: true, 
				dependsOnBodyHeight: true,
				title: 'Hair net'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net',
				isCorrect: true,
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Beard net',
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-green', 
				type: 'alternative', 
				dependsOnBodyHeight: true,
				title: 'Wide brim hat'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'umbrella',
				title: 'Umbrella'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'thermometer',
				title: 'Thermometer',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'meat-cleaver',
				title: 'Meat cleaver'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'bag-of-bird-seeds',
				title: 'Bag of bird seeds'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'hairnet',
					text: 'A hairnet has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'deli-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-presentation',
		image: 'deli-desk',
		text: `Where is the best place to assess the department?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Sales Floor', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Cooler',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'At service case'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'From a tall ladder'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Lets try to view the department just like a customer would walking into the store.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's test your skills. See how many issues you can spot on the next screen.`
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-1-spot-errors-store',
		layout: 'deli-1-store',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'water'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shelf1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shelf2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flies'})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Without correcting, it would only take us 15 seconds to lose a customer.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-monitor',
		text: `In the Deli, what is extremely important to monitor all day?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Food Safety', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Safety', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Ad Items'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Self Service counter'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-food-safety-important',
		text: `Why is Food Safety more difficult in this department?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Must maintain & track food temperature', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Recipes are specific'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Deli has most customer interactions'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Fighting the temptation to snack'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '3 Bay sink procedure'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-safety',
		image: 'deli-desk',
		text: `When it comes to food safety, what are the most important things you can monitor throughout the day to ensure the product is safe and high quality?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Cooking temperatures', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Safe storing temperatures', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Length of time on sales floor', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Nutritional facts panel'
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-1-spot-errors-sandwiches',
		layout: 'deli-1-sandwiches',
		text: `Which of these sandwiches should be pullled today?`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sandwich-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sandwich-2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sandwich-3'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-expired-sandwich',
		image: 'sandwich-expired',
		text: `What's wrong with this one?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Should have been pulled yesterday', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Nothing, it's still good`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Should have been pulled 3 days ago',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Should have been pulled 2 days ago'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		text: `That's my sandwich.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `You can't have personal food in the service departments.`
	}),
		Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-1-spot-errors-deli-counter-scale',
		layout: 'deli-1-deli-counter',
		text: `Speaking of dates, what should we verify now?`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'scale'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Exactly, if this is wrong, everything we produce is unsafe.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-salad-bar',
		image: 'salad-bar',
		text: `What's important when it comes to the salad bar?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Items should be in alphabetical order'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Tongs should be in the right corner', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Light bulbs should be working', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Walkthrough every 30 minutes to clean and refill', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Bar should be fresh and ready at noon',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This is sort of right. But the bar should be ready at 9am.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Never put melon near beans'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-temperatures-cold',
		subtype: 'images',
		layout: 'deli-1-temperatures-cold',
		text: `Where should Cold foods remain?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // 30-40
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2 // 20-30
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, //  40-50
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 70-80
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-temperatures-hot',
		subtype: 'images',
		layout: 'deli-1-temperatures-hot',
		text: `Where should Hot foods remain?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // 140
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2 // 80
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, //  120
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 100
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-found-outside-range',
		text: `If food has been found outside of its range, how long until it must be thrown out?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '4 hours', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '1 hour',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '2 hours'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '3 hours'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-discard',
		text: `If we remove anything from sales floor to discard...`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'We must record it', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's correct, keep good accurate inventory.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'We must replace it',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'We must restock it'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'We must make more'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-senses',
		text: `What are our 2 best senses for caring for a department?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Smell', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Sight', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Touch'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Test'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Hearing'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Exactly. Set for "eye appeal" and be wary of bad smells.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['spoon-1'],
		text: `I recommend taste. Can't know if it's good if you don't lick the spoon.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `How do you keep getting in here?`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-track-temperature',
		text: `And how do we track these temperatures?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Log', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. We keep track.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Thermometer',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's how we measure it.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Computer'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Phone'
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['log-1'],
		text: `Correct. And presto here it is.`,
		extraCharacters: [{id: 'manager-3 flipped', type: 'right'}],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['giant-log'],
					text: 'A Giant Log has been added to your avatar.'
				})
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-stack-high-limit',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'deli-1-stack-high-limit',
		text: `As far as the service case goes, how high can we stack food?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 2 inches
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // at brim of bowl
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 6 inches
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 1 inch
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `The serving bowl should always look very full.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-stack-low-limit',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'deli-1-stack-low-limit',
		text: `How should serving bowls never look?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // brim or right below
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // halfway
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // empty
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // quarter full
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-spoon-side',
		text: `Which side of the bowl does the serving spoon go in the Service Case?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Right, handle near server`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Left, handle near server'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Right, handle facing out'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Left, handle facing out'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Straight up in middle'
			}),
		]
	}),
	/** DIALOGUE START ... */
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'deli-1-dialogue-quick-lunch',
		characterId: 'woman-with-handbag',
		maxPoints: 20,
		introText: 'A customer approaches the self service bar.',
		introTextType: 'info',
		introShowAvatar: true,
		introCharacter: 'woman-with-handbag-angry',
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 1,
				text: `I was trying to make a quick lunch ... I'm not too happy.`,
				image: 'angry',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 2, 
						text: `Sorry to hear that, how may I help?`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],

					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						nextStepId: 3,
						text: `What do you need?`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						nextStepId: 4,
						text: `It's only 9am, ma'am.`, 
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 2,
				text: `These beans are crusty and the mac & cheese is hard.`,
				image: 'angry',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 5, 
						text: `Do you care to wait while we get some fresh out here?`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],

					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						nextStepId: 6,
						text: `I can offer some of the other items on the bar.`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 3,
				text: `Um. That sounds a bit rude. These beans are crusty and the mac & cheese is hard.`,
				image: 'angry',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 5, 
						text: `Do you care to wait while we get some fresh out here?`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],

					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						nextStepId: 6,
						text: `I can offer some of the other items on the bar.`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 4,
				type: 'action',
				text: `The customer doesn't answer, but just leaves looking very angry.`,
				nextStepId: 15,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 5,
				text: `Yes, I have a few minutes`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 9, 
						text: `Great, let me get these old ones out of the way.`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Good idea getting those out of view.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						nextStepId: 8,
						text: `Great, let me go straight to it.`, 
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 6,
				text: `No thank you. I wanted those for lunch.`,
				image: 'angry',
				nextStepId: 7,
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 7,
				type: 'action',
				text: `The customer leaves looking upset.`,
				nextStepId: 15,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 8,
				text: `You might want to take this with you.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, nextStepId: 9, text: `Yes, you're right.`, 
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 9,
				type: 'action',
				text: `You walk into the deli.`,
				optionType: 'action',
				optionPrompt: 'What do you do first?', 
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 10,
						text: `Dispose of the older food.`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Yes! Let's get rid of this first.`
								})
							}),
						],
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: 'You dispose of the older food.'
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						nextStepId: 11,
						text: `Wash hands and put on gloves.`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Okay, you should really throw out the old food first and then wash your hands.`
								})
							})
						],
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: ''
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, nextStepId: 11, text: `Start preparing new foods.`,
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: ''
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 10,
				optionType: 'action',
				optionPrompt: 'Now what?', 
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 11,
						text: `Wash hands and put on gloves.`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Exactly! Always wash your hands before preparing food.`
								})
							})
						],
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: 'You wash your hands an put on gloves.'
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, nextStepId: 11, text: `Start preparing new foods.`,
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: ''
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 11,
				type: 'action',
				text: `You prepare the food and return to the customer.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 12,
						text: `Here you are. Thank you for waiting.`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, nextStepId: 13, text: `Here you go. Tips aren't included.`
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 12,
				text: `By the way, there aren't any napkins either.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						nextStepId: 14, 
						text: `Let me fill up those supplies.`, 
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, nextStepId: 13, text: `They were full yesterday.`, 
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, nextStepId: 13, text: `We sell them on aisle 12.`, 
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 13,
				text: `Hmph!`,
				image: 'angry',
				nextStepId: 7,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 14,
				text: `Thank you, I appreciate it.`,
				image: 'happy',
				nextStepId: 15,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 15,
				showAvatar: false,
				showPoints: true,
				isFinalStep: true,
				character: 'manager-2 pos2',
				text: `Okay, let's see if you remembered everything.`,
				checklistData: {
					title: 'Checklist',
					type: 'deli-1',
					items: [
						{
							text: 'Helped customer',
							conditions: [{type: 'selected-or', options: [{stepId: 1, optionId: 1}, {stepId: 2, optionId: 1}, {stepId: 3, optionId: 1}]}]
						},
						{
							text: 'Corrected issue',
							conditions: [{type: 'selected-or', options: [{stepId: 9, optionId: 1}]}]
						},
						{
							text: 'Practiced food safety',
							conditions: [{type: 'selected-or', options: [{stepId: 9, optionId: 2}, {stepId: 10, optionId: 1}]}]
						},
						{
							text: 'Checked for supplies',
							conditions: [{type: 'selected-or', options: [{stepId: 12, optionId: 1}]}]
						},
						{
							text: 'GUEST service',
							conditions: [
								{type: 'selected-or', options: [{stepId: 1, optionId: 1}, {stepId: 2, optionId: 1}, {stepId: 3, optionId: 1}]},
								{type: 'selected-or', options: [{stepId: 9, optionId: 1}]},
								{type: 'selected-or', options: [{stepId: 9, optionId: 2}, {stepId: 10, optionId: 1}]},
								{type: 'selected-or', options: [{stepId: 12, optionId: 1}]}
							]
						}
					]
				}
			}),
		]
	}),
	/* ... DIALOGUE END */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-what-else',
		text: `What else could we have done?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Check the temperature of removed food`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Taste the food'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Stir the bowls'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Turn off the food lamps'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-what-else-2',
		text: `Yes. And then what?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Review the temperature log`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. See when it was last recorded.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Make sure it doesn't happen again`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Nothing if it's safe`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Put it back out later'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Okay, let's meet another customer. `
	}),
	/* DIALOGUE START */
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'deli-1-dialogue-ham',
		characterId: 'old-lady',
		maxPoints: 5,
		introText: 'You are at the counter in the deli ready to serve the next customer.',
		introTextType: 'info',
		introShowAvatar: true,
		introCharacter: 'old-lady',
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 1,
				text: 'Can I get 1lb of ham, please?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Here you go, ma'am.`, nextStepId: 2
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'How would you like that sliced?', nextStepId: 3
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 2, 
				image: 'angry',
				text: 'I would have liked the slices a bit thinner.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Well, I\'ve already cut it, so maybe say that next time.', nextStepId: 5
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `Sorry about that, ma'am. Let me try again.`, nextStepId: 4
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 3,
				text: 'I would like it cut thin, please.',
				nextStepId: 4,
				autoContinue: true
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 4,
				optionType: 'action',
				optionPrompt: 'What do you do next?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Cut the ham and give it to the customer.', 
						nextStepId: 6,
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: 'You cut the ham and give it to the customer.'
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'Cut a slice, show it and ask if it\'s okay.', 
						nextStepId: 7,
						dialogueEffects: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'action', text: 'You cut a slice and show it.'
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionEffectTemplate, {
								type: 'dialogue', text: 'Is it okay?'
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 5,
				image: 'angry',
				imageFlip: true,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-lady-angry',
				text: 'The customer leaves. She does not take the ham.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 6,
				text: 'Thank you.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Have a nice day.', 
						nextStepId: 8,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Could I get you some cheese to go with that?', nextStepId: 9
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 7,
				image: 'happy',
				text: 'That looks fine, thank you.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, I\'ll cut it for you.', 
						nextStepId: 10,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'deli-1-old-lady-1', pointId: 'smooth-talker', value: 1
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Great. Could I get you some cheese to go with that?', nextStepId: 11
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 8,
				imageFlip: true,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-lady',
				text: 'The customer nods and leaves.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 9,
				image: 'happy',
				text: 'No thanks.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Alright, have a nice day.', 
						nextStepId: 10,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'deli-1-old-lady-2', pointId: 'smooth-talker', value: 1
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 10,
				image: 'happy',
				imageFlip: true,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-lady-happy',
				text: 'You hand the customer their ham. They thank you and leave.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 11,
				image: 'happy',
				text: 'Well, now that you ask, I think I will.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'You got it. How would like that sliced?', 
						nextStepId: 12,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'special-points',
								specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
									id: 'deli-1-old-lady-3', pointId: 'smooth-talker', value: 1
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 12,
				image: 'happy',
				text: 'Thin. Thank you.',
				nextStepId: 13,
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 13,
				image: 'happy',
				imageFlip: true,
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-lady-happy',
				text: 'The customer thanks you and leaves with ham and cheese.'
			})
		]
	}),
	/* DIALOGUE END */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-restock',
		image: 'sandwich',
		text: `When should you restock items in the service hot and cold cases on the floor?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Only until 4pm'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'All the time', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'YES!'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'When they are almost empty',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Perferrably it gets refilled before getting down this low.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Right after mopping the department, and wiping down the glass on the case.'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-morning',
		image: 'salad',
		text: `What's especially important do in the morning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Remove out of date items before 9am', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Fill salad bar into fresh crocks', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Reduce items that are out of date the coming day',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You don\'t do this until 4pm.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Read the paper'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Mop the floor'
			}),
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'deli-1-sort-temperature',
		image: 'thermometer',
		text: `Temperature should be monitored and logged during the day for maximum food safety. Match the right food or section with the right temperature.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'veryhot', text: '180F'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'hot', text: 'Above 140F'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'cold', text: '38-40F'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 1, text: 'Hot food', categoryIds: ['hot']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 2, text: 'Salad bar', categoryIds: ['cold']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 3, text: 'Rotisserie chicken end of cooking', categoryIds: ['veryhot']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 4, text: 'Refrigerated Foods', categoryIds: ['cold']})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-prep-areas',
		image: 'prep-area',
		text: `What prep areas should especially be clean, neat and organized?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'The ones the customers can see'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'The ones used for specialty items'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'The ones used for meat'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'All prep areas', isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'salad-bar',
		text: `As I said, the deli department has to look inviting. So now let's try and organize the salad bar so that it will catch your customer's eye.
			<br /><br />Let's see your creative side.`
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'deli-1-organize-salad-bar',
		layout: 'deli-1-salad-bar',
		text: `Arrange the items in the salad bar to make it look nice.
			<br /><br />Press done when you're ready.`,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 1, containerId: 'a1', image: 'tomato', alt: 'tomato'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 2, containerId: 'a2', image: 'cucumber', alt: 'cucumber'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 3, containerId: 'a3', image: 'green-salad', alt: 'green salad'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 4, containerId: 'a4', image: 'carrot', alt: 'carrot'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 5, containerId: 'a5', image: 'onion', alt: 'onion'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 6, containerId: 'a6', image: 'champignon-mushroom', alt: 'champignon mushroom'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 7, containerId: 'a7', image: 'red-bell-pepper', alt: 'red bell pepper'}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 8, containerId: 'a8', image: 'corn', alt: 'corn'})
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b4', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b5', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b6', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b7', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b8', type: 'final'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'initial'}),
				]
			}),
		],
		doneConditions: [
			Object.assign({}, taskTemplates.organizeTemplate.doneConditionTemplate, {
				type: 'empty-main-container', mainContainerId: 'initial'
			})
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'feedback',
				feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
					text: 'Nice!'
				})
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Can you see the issues in this case? Select the issues and continue when you think you have them all.`
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-1-spot-errors-deli-case',
		layout: 'deli-1-deli-case',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'bottle'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'ham'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'vent'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'plug'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'water'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-wing-bar',
		image: 'wing-bar',
		text: `The wing bar and steam table hold hot foods. When should you remove items from these?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'After 4 hours', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'At 1pm and 4pm'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'If it looks dried out', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Only when a supervisor tells me'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-1-multiple-choice-rotten-chicken',
		image: 'rotten-chicken',
		text: `You should always immediately pull dried out, poor quality product.
			<br /><br /> But why is that so 
			<br /> important?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'If customers have a bad experience, they don\'t come back', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'It\'s the law'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'For safety reasons',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'If the condition looks poor, the item could very well be on it\'s way to be a health concern if consumed.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `That's some pretty good work for today. Come back for level 2.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['sandwich-costume-m', 'sandwich-costume-f'],
					text: 'A Sandwich Costume has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'del1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};