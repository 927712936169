const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterType: 'skipper-winter-gear',
		characterPose: 1,
		animateCharacter: true,
		text: `Welcome to the Cold Island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterType: 'skipper-winter-gear',
		characterPose: 1,
		text: `This place is dedicated to the Cooler & Freezer.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterType: 'skipper-winter-gear',
		characterPose: 1,
		text: `First let's look at how to keep boxes. These were received on 3/18.`,
		items: ['boxes']
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-multiple-choice-freezer-boxes',
		subtype: 'images',
		layout: 'cooler-freezer-1-freezer-boxes',
		text: `Which one is correct?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Front of box, date of 3/18 in marker
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `Correct. Facing out with label and date received.`
						})
					})
				],
			}),
			// Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
			// 	id: 2, // back of box with 3/18
			// }),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Box upside down date 3/18
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // front of box, with EXP: 3/25
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'cooler-freezer-1-shelf-with-lettuce',
		text: `Look at this shelf with Lettuce.`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-multiple-choice-lettuce-shelf',
		text: `On the previous image, something was wrong. What was it?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `We're ordering too much`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `Exactly, more than one or two expiration dates means we are ordering too often.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Dates are wrong',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Dates aren't even`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Doesn't have year`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-multiple-choice-lettuces-stocking',
		subtype: 'images',
		layout: 'cooler-freezer-1-lettuces-stocking',
		text: `Which of these should go to the sales floor first?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // 3/18
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `Correct. FIFO.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 3/22
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 3/24
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 3/25
			}),
		]
	}),
	

	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-spot-errors-freezer-issues',
		text: `Any other issues while we are here?`,
		layout: 'cooler-freezer-1-freezer-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'fan',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `That's iced over.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'ice-on-floor',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `That's a safety hazard.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cases-on-floor',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `Bad Food Safety.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lunch-box',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `Sorry no personal items.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'refrigerated',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper-winter-gear',
							text: `That should be in the cooler.`
						})
					})
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterType: 'skipper-winter-gear-pick-axe',
		characterPose: 1,
		text: `We need to chisel this ice buildup off.`,
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `While he fixes that, let's see what could have caused it.`,
	}),	
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'cooler-freezer-1-possible-errors',
		text: 'Possible errors.',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `These seem possible, but I bet something simple happened.`,
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-multiple-choice-freezer-door',
		isDilemma: true,
		text: `Was the door open or closed when we received that pallet?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Open`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That will do it. Keep the door closed.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Closed`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Oh good. We'll have Skipper look into it then.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Just cracked`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That will do it. Keep the door closed.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `The wizard probably did it`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Not unlikely. But leaving the door even slightly open can also lead to ice building up.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `All the same goes for the cooler too.`,
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `I left the door open.`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Why?`,
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `For the penguins!!!`,
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
		items: ['penguin-1', 'penguin-2', 'penguin-3']
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: `Close the door and let's go. But I do have one extra question.`,
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
		items: ['penguin-1', 'penguin-2', 'penguin-3']
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'cooler-freezer-1-multiple-choice-penguins',
		isDilemma: true,
		text: `Do you like penguins?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Yes`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'avatar-item', 
						avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
							itemIds: ['penguin-suit'],
							text: 'A Penguin Suit has been added to your avatar.'
						})
					}),
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Not really`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Not when they're in the freezer`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Me neither.`
						})
					})
				],
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Good job in there!`,
	}),	
	
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cf1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};