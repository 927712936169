import PropTypes from 'prop-types';
import './badge.scss';

const Badge = ({isUnlocked, page, badgeId}) => {
	return (
		<div className={'Badge ' + page + ' ' + badgeId + (isUnlocked ? '' : ' locked')} />
	);
};

Badge.propTypes = {
	isUnlocked: PropTypes.bool.isRequired,
	page: PropTypes.string.isRequired,
	badgeId: PropTypes.string.isRequired,
};

export default Badge;
