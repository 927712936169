import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts, lobbyUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './lobby.scss';

const Lobby = ({userData, gameData, gamesData, switchPlayerStore, handleLogout}) => {
	/**
	 * Redirect to url
	 * @param {string} url 
	 */
	const handleGoToStore = (url) => {
		window.location.href = '/' + url;
	};

	/* Toggle "switch store" popup, track if currently switching store */
	const [showSwitchStorePopup, setShowSwitchStorePopup] = useState(false);
	const [isSwitchingStore, setIsSwitchingStore] = useState(false);

	/**
	 * Switch store
	 */
	const handleSwitchStore = () => {
		setIsSwitchingStore(true);
		switchPlayerStore(gameData.id).then(() => {
			window.location.reload();
		});
	};

	/* No game related to url */
	if (!gameData) {
		return (
			<div className="Lobby">
				<div className="Lobby-logoutBtn">
					<Button text={loginUiTexts.logout} classes={['game-logout2']} onClick={() => {handleLogout();}}/>
				</div>
				<div className="Lobby-text">{renderMarkdown(lobbyUiTexts.noStore)}</div>
			</div>
		);
	};

	/* Role: player (not coFacilitator), connected to different game (might be deleted) */
	if (
		userData.role === 'player' && 
		!userData.isCoFacilitator && 
		userData.games && 
		userData.games.length > 0
	) {
		const connectedGameData = gamesData.find((game) => {return game.id === userData.games[0].id;});
		const connectedStoreName = (connectedGameData ? connectedGameData.storeName : lobbyUiTexts.deleteStoreName);

		const text = JSON.parse(JSON.stringify(lobbyUiTexts.playerDiffGame))
			.replace(/%storeName%/g, connectedStoreName);
		const goToStoreBtnText = JSON.parse(JSON.stringify(lobbyUiTexts.goToStoreBtn))
			.replace(/%storeName%/g, connectedStoreName);
		const switchStoreBtnText = JSON.parse(JSON.stringify(lobbyUiTexts.switchStoreBtn))
			.replace(/%storeName%/g, gameData.storeName);
		const switchStoreWarning = JSON.parse(JSON.stringify(lobbyUiTexts.switchStoreWarning))
			.replace(/%storeName1%/g, connectedStoreName)
			.replace(/%storeName2%/g, gameData.storeName);

		return (
			<div className="Lobby">
				<div className="Lobby-logoutBtn">
					<Button 
						text={loginUiTexts.logout} 
						classes={['game-logout2']} 
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="Lobby-title">{gameData.storeName}</div>
				<div className="Lobby-text">{renderMarkdown(text)}</div>
				<div className="Lobby-buttons">
					{connectedGameData && <div className="Lobby-button">
						<Button 
							classes={['yellow']} 
							text={goToStoreBtnText} 
							onClick={() => {handleGoToStore(connectedGameData.url);}} 
						/>
					</div>}
					<div className="Lobby-button">
						<Button 
							classes={['yellow']} 
							text={switchStoreBtnText} 
							onClick={() => {setShowSwitchStorePopup(true);}} 
						/>
					</div>
				</div>

				{showSwitchStorePopup && 
					<div className="Lobby-popup" onClick={() => {setShowSwitchStorePopup(false);}}>
						<div className="Lobby-popupContent" onClick={(e) => {e.stopPropagation();}}>
							<div className="Lobby-popupTitle">{}</div>
							<div className="Lobby-popupText">{renderMarkdown(switchStoreWarning)}</div>
							<div className="Lobby-popupButtons">
								<Button 
									isLoading={isSwitchingStore}
									classes={['popup', 'yellow']} 
									text={lobbyUiTexts.confirmBtn} 
									onClick={() => {handleSwitchStore();}} 
								/>
								<Button 
									classes={['popup', 'yellow']} 
									text={lobbyUiTexts.cancelBtn} 
									onClick={() => {setShowSwitchStorePopup(false);}} 
								/>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}

	/* Role: facilitator or player (co-facilitator), not (co-)facilitator of this game */
	if (
		userData.role === 'facilitator' ||
		(userData.role === 'player' && userData.isCoFacilitator)
	) {
		return (
			<div className="Lobby">
				<div className="Lobby-logoutBtn">
					<Button text={loginUiTexts.logout} classes={['game-logout2']} onClick={() => {handleLogout();}}/>
				</div>
				<div className="Lobby-title">{gameData.storeName}</div>
				<div className="Lobby-text">{renderMarkdown(lobbyUiTexts.facilitatorDiffGame)}</div>
			</div>
		);
	}


	/* Default page */
	return (
		<div className="Lobby">
			<div className="Lobby-logoutBtn">
				<Button text={loginUiTexts.logout} classes={['game-logout2']} onClick={() => {handleLogout();}}/>
			</div>
			<p>{userData.name}</p>
			<p>{userData.email}</p>
			<p>{userData.role}</p>
		</div>
	);
};

Lobby.propTypes = {
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	gamesData: PropTypes.array.isRequired,
	switchPlayerStore: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Lobby;
