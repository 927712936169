const notificationsData = [
	{
		id: 'area-overview-first-visit',
		type: 'first-visit',
		text: 'Here you see all the different departments. But right now you have to complete customer service. Go there now.'
	},
	{
		id: 'customer-service-area-completed',
		type: 'area-completed',
		areaId: 'customer-service',
		text: 'Now you\'re ready to move on to your specific department. Just pick your island.'
	},
];

export {
	notificationsData
};
