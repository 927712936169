import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ClockTasksOverview from './clock-tasks-overview';
import MultipleChoice from 'components/game/module/multiple-choice/multiple-choice';
import SpotErrors from 'components/game/module/spot-errors/spot-errors';
import Organize from 'components/game/module/organize/organize';
import Dialogue from '../dialogue/dialogue';
import Button from 'components/ui/button/button';
import './clock-tasks.scss';

const ClockTasks = (props) => {
	const {
		areaId,
		deviceInfo,
		isShowingFeedbackOrPopup, 
		playerData,
		playerTaskData, 
		taskData, 
		setShowPointsTrack,
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask,
		isFacilitator = false,
		managerId,
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Current task id, time passsed, points, solved / failed tasks, popups */
	const [taskId, setTaskId] = useState(null);
	const [minutesPassed, setMinutesPassed] = useState(0);
	const [totalPoints, setTotalPoints] = useState(0);
	const [solvedTaskIds, setSolvedTaskIds] = useState([]);
	const [failedTaskIds, setFailedTaskIds] = useState([]);

	/**
	 * Get points
	 * @returns {number} points
	 */
	const getPoints = () => {
		let points = null;
		if (isCompleted && playerTaskData) points = playerTaskData.points;
		return points;
	};

	/**
	 * Get minutes passed
	 * @returns {number} minutes passed
	 */
	const getMinutesPassed = () => {
		let minutesPassed = 0;
		if (isCompleted && playerTaskData && playerTaskData.minutesPassed) minutesPassed = playerTaskData.minutesPassed;
		return minutesPassed;
	};

	/**
	 * Get an array with the ids of the solved tasks
	 * @returns {array} solvedTaskIds
	 */
	const getSolvedTaskIds = () => {
		let solvedTaskIds = [];
		if (isCompleted && playerTaskData && playerTaskData.solvedTaskIds) {
			solvedTaskIds = playerTaskData.solvedTaskIds;
		}
		return solvedTaskIds;
	};

	/**
	 * Select a task
	 * @param {string || null} taskId 
	 */
	const selectClockTask = (taskId) => {
		/* Facilitator cannot solve task */
		if (isFacilitator) return;

		/* "Clock tasks" is already completed */
		if (isCompleted) return;
		
		/* Return to task overview */
		if (!taskId) {
			setTaskId(null);
			return;
		}

		/* Get task data */
		const clockTaskData = taskData.tasks.find((t) => {return t.id === taskId;});	
		if (clockTaskData && clockTaskData.type === 'click-to-solve') {
			/* Solve task now */
			solveClockTask('click-to-solve', 0, 0, [], {taskId: taskId});
		} else {
			/* Show task */
			setTaskId(taskId);
		}
	};


	/**
	 * Complete a clock story task
	 * @param {string} type 
	 * @param {number} newPoints 
	 * @param {string} nextStoryStepId 
	 * @param {object} taskData 
	 */
	const solveClockTask = (type, points, errors, effects, specialTaskData) => {
		/* Get clock task data */
		const clockTaskDataId = (taskId ? taskId : specialTaskData.taskId);
		const clockTaskData = taskData.tasks.find((t) => {return t.id === clockTaskDataId;});	

		/* Add to array of solved tasks */
		let newSolvedTaskIds = JSON.parse(JSON.stringify(solvedTaskIds));
		newSolvedTaskIds.push(clockTaskDataId);
		setSolvedTaskIds(newSolvedTaskIds);

		/* Get effects */
		let newTotalPoints = totalPoints + points;
		let instantEffects = [...effects];
		if (clockTaskData && clockTaskData.solvedEffects) {
			clockTaskData.solvedEffects.forEach((effect) => {
				if (effect.type === 'points') {
					/* Effect: points */
					newTotalPoints += effect.value;	
				} else {
					/* Effect: feedback, popup, special points, new avatar item */
					instantEffects.push(effect);
				}
			});
		}

		/* Update points */
		setTotalPoints(newTotalPoints);

		/* Apply instant effects */
		if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);


		/* Finish */
		if (type === 'click-to-solve') finishClockTask(clockTaskDataId);
	};

	/**
	 * Finish clock task (return to overview and advance time)
	 * @param {string} completedTaskId 
	 */
	const finishClockTask = (completedTaskId) => {
		const clockTaskData = taskData.tasks.find((t) => {return t.id === completedTaskId;});	
		if (clockTaskData) {
			/* Return to task overview page */
			setTaskId(null);
			
			/* Advance time passed */
			let newMinutesPassed = minutesPassed;
			if (clockTaskData && clockTaskData.durationMinutes) newMinutesPassed += clockTaskData.durationMinutes;
			setMinutesPassed(newMinutesPassed);
		}
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 */
	const completeTask = (effects) => {
		/* Errors */
		const errors = 0;

		/* Save completed task */
		handleCompleteTask(
			'clock-tasks', 
			totalPoints, 
			errors, 
			effects,
			{minutesPassed, solvedTaskIds});
	};

	/**
	 * Time has passed
	 */
	useEffect(() => {
		if (!isCompleted) {
			/* Update logged time */
			updateLoggedTime();

			let instantEffects = [];
			let newFailedTaskIds = JSON.parse(JSON.stringify(failedTaskIds));

			/* Get failed tasks and their effects */
			taskData.tasks.forEach((t) => {
				if (
					(t.endTime <= minutesPassed || taskData.maxDurationMinutes <= minutesPassed) && 
					(solvedTaskIds.indexOf(t.id) < 0 && failedTaskIds.indexOf(t.id) < 0)
				) {
					newFailedTaskIds.push(t.id);
					if (t.failedEffects) {
						t.failedEffects.forEach((effect) => {
							if (effect.type === 'popup') {instantEffects.push(effect);}
							if (effect.type === 'feedback') instantEffects.push(effect);
						});
					}
				}
			});

			/* Update array of failed tasks */
			setFailedTaskIds(newFailedTaskIds);

			/* Check if clock tasks are completed */
			let clockTasksCompleted = false;
			if (!taskId && !isCompleted) {
				clockTasksCompleted = true;
				if (taskData.maxDurationMinutes > minutesPassed) {
					/* Time has not run out, check if all tasks are completed */
					taskData.tasks.forEach((t) => {
						if (!clockTasksCompleted) return;
						if (t.isRequired === false) return;
						if (solvedTaskIds.indexOf(t.id) < 0 && newFailedTaskIds.indexOf(t.id) < 0) {
							clockTasksCompleted = false;
						}
					});
				}
			}

			if (clockTasksCompleted) {
				/* Complete clock tasks */
				completeTask(instantEffects);
			}  else {
				/* Apply instant effects */
				if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
			}
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minutesPassed]);	


	/**
	 * New clock tasks, reset
	 */
	useEffect(() => {
		setTaskId(null);
		setMinutesPassed(getMinutesPassed());
		setTotalPoints(getPoints());
		setSolvedTaskIds(getSolvedTaskIds());
		setFailedTaskIds([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Get current clock task data */
	const clockTaskData = (taskId ? taskData.tasks.find((t) => {return t.id === taskId;}) : null);	
	
	/* Get component */
	let ClockTaskComponent = ClockTasksOverview;
	if (clockTaskData) {
		if (clockTaskData.type === 'multiple-choice') ClockTaskComponent = MultipleChoice;
		if (clockTaskData.type === 'spot-errors') ClockTaskComponent = SpotErrors;
		if (clockTaskData.type === 'organize') ClockTaskComponent = Organize;
		if (clockTaskData.type === 'dialogue') ClockTaskComponent = Dialogue;
	}
	
	return (
		<div className={'ClockTasks' + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			{/* Clock task */}
			<ClockTaskComponent 
				clockTasksAreCompleted={isCompleted}
				areaId={areaId}
				deviceInfo={deviceInfo}
				type="clock-task"
				clockTaskIsCompleted={(taskId && solvedTaskIds.indexOf(taskId) >= 0 ? true : false)}
				minutesPassed={minutesPassed}
				playerData={playerData}
				taskData={(taskId ? clockTaskData : taskData)}
				solvedTaskIds={solvedTaskIds}
				failedTaskIds={failedTaskIds}
				setShowPointsTrack={setShowPointsTrack}
				selectClockTask={selectClockTask}
				updateLoggedTime={updateLoggedTime}
				handleInstantTaskEffects={handleInstantTaskEffects}
				handleCompleteTask={solveClockTask}
				managerId={managerId}
			/>

			{/* Return to clock tasks overview */}
			{(
				taskId && 
				solvedTaskIds.indexOf(taskId) >= 0 && 
				!isShowingFeedbackOrPopup
			) && <div className={'ClockTasks-nextBtn'}>
				<Button 
					classes={['next', 'animate']} 
					onClick={() => {finishClockTask(taskId);}} />
			</div>}
		</div>
	);
};

ClockTasks.propTypes = {
	areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	isShowingFeedbackOrPopup: PropTypes.bool.isRequired,
	playerData: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	setShowPointsTrack: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
	managerId: PropTypes.string.isRequired,
};

export default ClockTasks;
