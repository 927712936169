/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

/* Connect to firebase project test or production based on env-cmd -f 'environment' on build / start */
let firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_ID,
};

// add measurementId on Production
if (process.env.REACT_APP_MEASUREMENT_ID) {
	firebaseConfig['measurementId'] = process.env.REACT_APP_MEASUREMENT_ID;
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore;