import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import appConfig from 'config/app.config';
import {checkCompetition, getParticipatingPlayers} from 'helpers/competition-helper';
import {facilitatorUiTexts, errorUiTexts} from 'data/ui-texts';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {competitionTypesData} from 'data/templates/competition-template';
import {areasData} from 'data/areas-data';
import Select from 'components/ui/select/select';
import SelectMultiple from 'components/ui/select-multiple/select-multiple';
import DatePicker from 'react-datepicker';
import Button from 'components/ui/button/button';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './facilitator-new-competition-popup.scss';

const FacilitatorNewCompetitionPopup = ({gameData, gamePlayers, createCompetition, setShowNewCompetitionPopup}) => {

	/* Selected type id */
	const [selectedTypeId, setSelectedTypeId] = useState(null);
	const handleSelectTypeId = (typeId) => {
		setSelectedTypeId(typeId);
		setErrMsg(null);
	};

	/* Get type options */
	const typeOptions = competitionTypesData.map((type) => {
		return {id: type.id, value: type.title};
	});

	/* Selected module id */
	const [selectedModuleId, setSelectedModuleId] = useState(null);
	const handleSelectModuleId = (moduleId) => {
		setSelectedModuleId(moduleId);
		setErrMsg(null);
	};

	/* Get module options */
	const moduleOptions = modulesCompetitionsData.map((module) => {
		return {id: module.id, value: module.title};
	});

	/* Competition dates */
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);	

	/* Participants (can depend on selected area(s)) */
	const [allCanParticipate, setAllCanParticipate] = useState(true);
	const [selectedAreaIds, setSelectedAreaIds] = useState([]);
	
	/* Get area options */
	const areaOptions = areasData.filter((area) => {return area.canBeDisabled === true;}).map((area) => {
		return {id: area.id, value: area.title};
	});

	/* Toggle area id */
	const toggleSelectedAreaId = (areaId) => {
		let newSelectedAreaIds = JSON.parse(JSON.stringify(selectedAreaIds));
		const areaIndex = selectedAreaIds.indexOf(areaId);
		if (areaIndex >= 0) {
			newSelectedAreaIds.splice(areaIndex, 1);
		} else {
			newSelectedAreaIds.push(areaId);
		}
		setSelectedAreaIds(newSelectedAreaIds);
		setErrMsg(null);
	};

	/* Selected stars per player (only used for competition type "shared goal") */
	const [starsPerPlayer, setStarsPerPlayer] = useState(1);
	const handleSetStarsPerPlayer = (stars) => {
		setStarsPerPlayer(stars);
		setErrMsg(null);
	};

	/* Description */
	const [description, setDescription] = useState(null);
	const updateDescription = (event) => {
		setDescription(event.target.value);
		setErrMsg(null);
	};

	/* Prize */
	const [prize, setPrize] = useState(null);
	const updatePrize = (event) => {
		setPrize(event.target.value);
		setErrMsg(null);
	};

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/* Is creating new competition */
	const [isCreating, setIsCreating] = useState(false);

	/* Get all current players that are able to participate */
	const participatingPlayers = getParticipatingPlayers(gamePlayers, allCanParticipate, selectedAreaIds);
	
	/* Confirm */
	const handleConfirmCompetition = () => {
		if (isCreating) return;

		const newErrMsg = checkCompetition(
			true,
			selectedTypeId, 
			selectedModuleId,
			selectedStartDate,
			selectedEndDate,
			allCanParticipate,
			selectedAreaIds,
			description,
			prize,
			gameData.competitions
		);

		if (newErrMsg) {
			setErrMsg(newErrMsg);
		} else {
			setIsCreating(true);
			createCompetition(
				selectedTypeId, selectedModuleId, 
				selectedStartDate, selectedEndDate, 
				(allCanParticipate ? [] : selectedAreaIds),
				(selectedTypeId === 'shared-goal' ? participatingPlayers.length * starsPerPlayer : null), 
				description, prize
			).then((response) => {
				if (response.status === 'success') {
					setShowNewCompetitionPopup(false);
				} else {
					setErrMsg(errorUiTexts.unknownError);
					setIsCreating(false);
				}
			});
		}		
	};

	return (
		<div className="FacilitatorNewCompetitionPopup">
			<div className="FacilitatorNewCompetitionPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorNewCompetitionPopup-header">
					<div className="FacilitatorNewCompetitionPopup-title">
						<span>{facilitatorUiTexts.addCompetitionPopup.title}</span>
					</div>		
					<div
						className="FacilitatorNewCompetitionPopup-closeBtn" 
						onClick={() => {setShowNewCompetitionPopup(false);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorNewCompetitionPopup-body'>
					{/* Competition type */}
					<div className='FacilitatorNewCompetitionPopup-selectType'>
						<Select 
							isDisabled={false}
							canSearch={false}
							canAddOption={false}
							type="competition"
							placeholderText={facilitatorUiTexts.addCompetitionPopup.chooseType}
							selectedId={selectedTypeId}
							options={typeOptions}
							onSelect={handleSelectTypeId}
						/>
						<div className="FacilitatorNewCompetitionPopup-typeTooltip">
							<div className="FacilitatorNewCompetitionPopup-typeTooltipIcon" />
							<div className="FacilitatorNewCompetitionPopup-typeTooltipText">
								{renderMarkdown(facilitatorUiTexts.addCompetitionPopup.typeTooltip)}
							</div>
						</div> 
					
					</div>

					{/* Competition module */}
					<div className='FacilitatorNewCompetitionPopup-selectModule'>
						<Select 
							isDisabled={false}
							canSearch={false}
							canAddOption={false}
							type="competition"
							placeholderText={facilitatorUiTexts.addCompetitionPopup.chooseModule}
							selectedId={selectedModuleId}
							options={moduleOptions}
							onSelect={handleSelectModuleId}
						/>
					</div>

					{/* Competition dates */}
					<div className='FacilitatorNewCompetitionPopup-competitionSchedule'>
						<div className='FacilitatorNewCompetitionPopup-competitionScheduleLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.scheduleCompetition}</span>
						</div>
						<div className='FacilitatorNewCompetitionPopup-competitionScheduleInput'>
							<DatePicker
								disabled={false}
								selected={selectedStartDate ? selectedStartDate : ''}
								minDate={new Date()}
								onChange={(date) => {setSelectedStartDate(date); setErrMsg(null);}}
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-mm-dd"
							/>
							<DatePicker
								disabled={false}
								selected={selectedEndDate ? selectedEndDate : ''}
								minDate={new Date()}
								onChange={(date) => {setSelectedEndDate(date); setErrMsg(null);}}
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-mm-dd"
							/>
						</div>
					</div>

					{/* Competition players */}
					<div className='FacilitatorNewCompetitionPopup-competitionPlayers'>
						<div className='FacilitatorNewCompetitionPopup-competitionPlayersLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.participants}</span>
						</div>
						<div 
							className={'FacilitatorNewCompetitionPopup-competitionPlayersSelect all' +
								(allCanParticipate ? ' selected' : '')}
							onClick={() => {setAllCanParticipate(true);}}
						>
							<span>{facilitatorUiTexts.addCompetitionPopup.allPlayers +
								(allCanParticipate ? ' (' + participatingPlayers.length + ')' : '')
							}</span>
						</div>
						<div 
							className={'FacilitatorNewCompetitionPopup-competitionPlayersSelect some' +
								(allCanParticipate ? '' : ' selected')}
							
							onClick={() => {setAllCanParticipate(false);}}
						>
							<span>{facilitatorUiTexts.addCompetitionPopup.somePlayers +
								(!allCanParticipate ? ' (' + participatingPlayers.length + ')' : '')
							}</span>
							{!allCanParticipate && 
								<div className="FacilitatorNewCompetitionPopup-competitionPlayersInput">
									<SelectMultiple 
										isDisabled={false}
										type="competition"
										placeholderText={facilitatorUiTexts.addCompetitionPopup.selectedDepartments 
											+ ' (' + selectedAreaIds.length + ')'}
										selectedIds={selectedAreaIds}
										options={areaOptions}
										onSelect={toggleSelectedAreaId}
									/>
								</div>
							}
						</div>
					</div>

					{/* Required stars (only for type "shared-goal") */}
					{selectedTypeId === 'shared-goal' && <div className='FacilitatorNewCompetitionPopup-requiredStars'>
						<div className='FacilitatorNewCompetitionPopup-requiredStarsLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.starsGoal}</span>
						</div>
						{[...Array(appConfig.maxStarsPerModule)].map((_, index) => {
							return (
								<div 
									key={index} 
									className={'FacilitatorNewCompetitionPopup-requiredStarsOption' +
										(starsPerPlayer === (index + 1) ? ' selected' : '')}
									onClick={() => {handleSetStarsPerPlayer(index + 1);}}
								>
									<span>{(index + 1) * participatingPlayers.length}</span>
									<span> ({(index + 1)} {(index > 0 
										? facilitatorUiTexts.addCompetitionPopup.starsPerPlayer 
										: facilitatorUiTexts.addCompetitionPopup.starPerPlayer
									)})</span>
								</div>
							);
						})}
					</div>}

					{/* Competition description */}
					<div className='FacilitatorNewCompetitionPopup-competitionDescription'>
						<div className='FacilitatorNewCompetitionPopup-competitionDescriptionLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.description}</span>
						</div>
						<div className='FacilitatorNewCompetitionPopup-competitionDescriptionInput'>
							<textarea 
								name="description"
								placeholder={facilitatorUiTexts.addCompetitionPopup.typeHere}
								value={description ? description : ''}
								onChange={(e) => {updateDescription(e);}}
							/>
						</div>
					</div>
					{/* Competition prize */}
					<div className='FacilitatorNewCompetitionPopup-competitionPrize'>
						<div className='FacilitatorNewCompetitionPopup-competitionPrizeLabel'>
							<span>{facilitatorUiTexts.addCompetitionPopup.prize}</span>
						</div>
						<div className='FacilitatorNewCompetitionPopup-competitionPrizeInput'>
							<textarea 
								name="prize"
								placeholder={facilitatorUiTexts.addCompetitionPopup.typeHere}
								value={prize ? prize : ''}
								onChange={(e) => {updatePrize(e);}}
							/>
						</div>
					</div>

					<div className="FacilitatorNewCompetitionPopup-errorMsg">
						{errMsg && renderMarkdown(facilitatorUiTexts.addCompetitionPopup.error + ': ' + errMsg)}
					</div>

					<div className="FacilitatorNewCompetitionPopup-confirmBtn">
						<Button 
							isLoading={isCreating}
							text={facilitatorUiTexts.addCompetitionPopup.launch}
							classes={['download', 'launch']}
							onClick={() => {handleConfirmCompetition();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorNewCompetitionPopup.propTypes = {
	gameData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	createCompetition: PropTypes.func.isRequired,
	setShowNewCompetitionPopup: PropTypes.func.isRequired
};

export default FacilitatorNewCompetitionPopup;
