import PropTypes from 'prop-types';
import Manager from 'components/ui/manager/manager';
import CharacterItem from './character-item';
import './character.scss';

const Character = ({page, managerId, characterId, items}) => {
	if (characterId.includes('manager')) {
		return (
			<Manager page={page} managerPose={characterId} managerId={managerId} items={items} />
		);
	}

	return (
		<div className={'Character ' + page + ' ' + characterId + (managerId ? ' manager-' + managerId : '')} >
			<div className={'Character-character ' 
				+ page + ' ' + characterId + (managerId ? ' manager-' + managerId : '')} />
			{items && <div className="Character-items">
				{items.map((item, index) => {
					return (
						<CharacterItem 
							key={index}
							character={characterId}
							item={item}
						/>
					);
				})}
			</div>}
		</div>
	);
};

Character.propTypes = {
	page: PropTypes.string.isRequired,
	managerId: PropTypes.string.isRequired,
	characterId: PropTypes.string.isRequired,
	items: PropTypes.array
};

export default Character;
