const errorUiTexts = {
	unknownError: 'Error: Unknown error.',
	invalidGameCode: 'Invalid game code.',
	unknownModuleId: 'Unknown module id',
	unknownModuleTaskId: 'Unknown module task id',
	competitionIdMismatch: 'Competition module id / moduleId mismatch: ',
	noActiveCompetition: 'No active competition.',
	unknownBadge: 'Unknown badge',
	couldNotLoadUsersAndOrGames: 'Error: Could not fetch games and/or players. Please check your internet connection and try again.',
};

const loadingUiTexts = {
	loading: 'Loading',
	loadingUserData: 'Loading user data',
	loadingAdminData: 'Loading admin data',
	loadingFacilitatorData: 'Loading facilitator data',
	loadingGameData: 'Loading game data',
	loadingModule: 'Loading module content'
};

const lobbyUiTexts = {
	noStore: 'There is no store connected to this url.',
	playerDiffGame: 'You are currently connected to %storeName%.<br /><br />You can only be connected to one store at a time.',
	deleteStoreName: '??? (deleted)',
	goToStoreBtn: 'Go to %storeName%',
	switchStoreBtn: 'Switch to %storeName%',
	switchStoreWarning: 'You can only be connected to one store at a time.<br /><br />If you switch to %storeName2% all your progress in %storeName1% will be lost.<br /><br />Are you sure you want to switch to %storeName2%?',
	cancelBtn: 'Cancel',
	confirmBtn: 'Yes, switch store',
	facilitatorDiffGame: 'You are not a facilitator at this store and can therefore not play its game.'
};

const gameUiTexts = {
	joinGame: 'Join game',
	start: 'Start',
	storeMap: 'Store map',
	storeEntrance: 'Store entrance',
	reset: 'Reset',
	delete: 'Delete',
	choose: 'Choose',
	confirm: 'Confirm',
	done: 'Done',
	point: 'point',
	points: 'points',
	time: 'Time',
	finish: 'Finish',
	results: 'Results',
	stars: 'stars',
	timeSpent: 'Time spent',
	avrErrors: 'Average mistakes per task',
	noErrorsTasks: 'Number of tasks made without mistakes',
	backToDepartment: 'Back to department',
	tryAgain: 'Try again',
	go: 'Go',
	ok: 'Ok',
	defaultNewBadgeTierText: 'You are now one step closer towards earning ',

	tryAgainPopup: {
		title: 'Try again?',
		text: 'Want to try to beat your own score? You can reset the module and play again.',
		resetBtn: 'Reset',
		cancelBtn: 'Cancel'
	},

	spotErrorsPopup: {
		result1: {
			title: 'Oops!',
			text: 'You only spotted %spotted% of %total% issues.',
			text2: 'You missed the issue.'
		},
		result2: {
			title: 'Not bad!',
			text: 'You spotted %spotted% of %total% issues.',
		},
		result3: {
			title: 'Well done!',
			text: 'You spotted %spotted% of %total% issues.',
			text2: 'You spotted the issue.'
		}
	},

	orderPopup: {
		title: `You got %correctAnswers%/%totalAnswers%.`,
		text1: 'Try again and see if you can figure out what was wrong.',
		text2: `Try again. This time we'll show you which ones were correct.`,
		text3: `Try again!`
	},

	organizePopup: {
		text1: 'Perfect! You had 0 mistakes.',
		text2: 'Nice! You only had 1 mistake.<br /><br />Here is the correct solution:',
		text3: 'You had %numberOfErrors% mistakes.<br /><br />Here is the correct solution:',
		text4: 'Oops, you had %numberOfErrors% mistakes.<br /><br />Here is the correct solution:',

	},

	paperDollPopup: {
		title: null,
		text1: 'You had them all right!',
		text2: 'You had %correctItems% right.<br /><br />You forgot: ',
		notNeeded: 'You did not need: '
	},

	lootBoxPopup: {
		title: 'Well done!',
		text1: 'You found a loot box',
		text2: 'Choose your loot',
		text3: 'You have already found all available loot.'
	}
};

const generalUiTexts = {
	welcome: 'Welcome',
	goToAdmin: 'Continue to Administrator',
	goToFacilitator: 'Continue to Facilitator',
	goToGame: 'Play the game as Student',
	ok: 'OK',
	or: 'or',
	mdiLogo: 'MDI logo',
	yearbookTitle: 'Yearbook',
	admin: 'Admin',
	facilitator: 'Facilitator',
	coFacilitator: 'Co-facilitator',
	player: 'Player',
	store: 'Store',
	game: 'Game',
	gameCode: 'Game code',
	url: 'URL',
	save: 'Save',
	back: 'Back',
	exit: 'Exit',
};

const loginUiTexts = {
	login: 'Login',
	signUp: 'Sign up',
	logout: 'Log out',
	loggedInAs: 'Logged in as'
};

const mapUiTexts = {
	back: 'Back',
	competitions: 'Competitions',
	info: 'Info',
	profile: 'Profile',
	areaDeactivated: 'This area has been deactivated by the game facilitator.',
	areaDisabled: 'This area has been disabled by the game facilitator.',
	areaNotCompleted: 'This area is locked because you have not completed',
	ok: 'Ok',
	moduleLocked: 'Earn more stars to reach this island.',
	sideQuestModuleLocked: 'Earn more stars to go here.',
	lootBoxEmpty: 'You have already picked up this loot.',
	lootBoxLocked: 'Earn more stars to reach this loot box.',
	noCompetitions: 'There are no active competitions.',
	competitionEnds: 'Competition ends',
	play: 'Play',
	noMessages: 'There are no messages.'
};

const yearBookUiTexts = {
	chooseQuestions: 'Choose Questions',
	feedback: 'Choose three questions to continue',
	save: 'Save',
	cancel: 'Cancel',
	edit: 'Edit',
	report: '!',
	yes: 'Yes',
	no: 'No',
	reportPrompt: 'Do you want to report the content in this yearbook to the manager for review?',
	reportPromptSuccessFeedback: 'Thank you for your report. The manager will be notified.',
	reportPromptFailFeedback: 'User is already reported and the manager has been notified.',
	reportPromptYearBookEmptyFeedback: 'Users yearbook is empty and can therefore not be reported.',
	close: 'close',
	chooseBadges: 'Choose Badges',
	invalidFeedback: `These field(s) %invalidFields% contains one or more words that are not allowed in the yearbook. </br>
	Please write something else.`,
};


const profileUiTexts = {
	profile: 'Profile',
	createAvatar: 'Create avatar',
	editAvatar: 'Edit avatar',
	badges: 'Badges',
	progress: 'Progress',
	highscore: 'Leaderboard ',
	points: 'Points',
	stars: 'Stars',
	yearbook: '\'s Yearbook',
	yearbook2: '\' Yearbook',
	generatingAvatar: 'Creating random avatar',
	goToAvatarPopup: {
		title: 'Wait!',
		text: 'Before you return to the game, click "Create avatar" to get your very own avatar.'
	},
	weekly: 'Weekly',
	total: 'Total'
};

const facilitatorUiTexts = {
	selectStore: 'Select store',
	noStores: 'You are not connected to any stores.',
	changeAvatar: 'Change manager',
	playerName: 'Player name',
	playerEmail: 'E-mail',
	playerCompletion: 'Completion',
	playerLastLogin: 'Last login',
	remove: 'Remove',
	addNew: 'Add new',
	lastLogin: 'Last login:',
	selectModule: 'Select module',
	completion: 'Completion:',
	avgMistakes: 'Average mistakes',
	timeSpent: 'Time spent:',
	totalTimeSpent: 'Total time spent',
	minute: 'min',
	stars: 'Stars',
	recentSession: 'Recent playthrough',
	prevSession: 'Previous playthrough',
	task: 'Task',
	mistakes: 'Mistakes',
	avgMistakes: 'Avg. mistakes',
	view: 'View',
	viewGame: 'View game stats',
	included: 'Included in game',
	preview: 'Preview',
	module: 'Module',
	startedBy: 'Started By #',
	completedBy: 'Completed by #',
	avgTimeSpent: 'Average time spent',
	avgMistakesTask: 'Average mistake per task',
	taskStats: 'Task stats',
	perfScores: 'Perfect scores',
	downloadXls: 'Download xls',
	comingSoon: 'Coming soon',
	startTitle: 'Start',
	startText: 'Start with completing Orientation',
	addCompetitionBtn: 'New competition',
	finished: 'Finished',
	ready: 'Ready',
	dayLeft: 'day left',
	daysLeft: 'days left',
	hourLeft: 'hour left',
	hoursLeft: 'hours left',
	findWinner: 'Find winner',
	winner: 'Winner',
	delete: 'Delete',
	edit: 'Edit',
	editAccess: 'Edit access',
	reports: 'Reports',
	viewModule: 'View module',
	question: 'Question',
	answer: 'Answer',
	multipleCorrectSolutions: 'This task has multiple correct solutions.',
	addMessageBtn: 'New message',
	from: 'From',
	sentToAllDepartments: 'Sent to player in all departments.',
	sentToPlayersInDepartments: 'Sent to players in',
	seenMessage: 'players have seen the message',
	competitionNotification: {
		title1: 'You have 1 finished competition with no winner drawn yet.',
		title2: 'You have %number% finished competitions with no winners drawn yet.',
		btn: 'Go to competitions'
	},
	reportsNotification: {
		title: 'You have %number% unseen reports.',
		btn: 'Review'
	},

	removePlayerFromGamePopup: {
		title: 'Remove player',
		text: 'Are you sure you want to remove %player% from the game?<br /><br />They will not show up on the list of players anymore or be part of the statistics.<br /><br />If they log in to the game again, they will be re-activated.',
		removeBtn: 'Remove',
		cancelBtn: 'Cancel'
	},
	editPlayerFromGamePopup: {
		title: 'Edit access for:',
		text: '%player%',
		departments: 'Departments',
		access: 'Access',
		saveBtn: 'Save',
		cancelBtn: 'Cancel'
	},
	viewReportPlayerFromGamePopup: {
		title: 'Reports for: %player%',
		text: 'Take a look at the yearbook and talk to the player if you see something that shouldn\'t be there. Click on the report to mark as seen.',
		reportId: 'Reporter',
		reportTime: 'Time of Report',
		yearBook: 'Yearbook',
		yes: 'Yes',
		no: 'No',
		deletePrompt: 'Are you sure you want to delete this report?',
		delete: 'Delete',
	},
	selectManagerPopup: {
		title: 'Game manager',
		text: 'Select the manager that is used in the game.',
		saveBtn: 'Save'
	},
	addCompetitionPopup: {
		title: 'Start a new competition',
		chooseType: 'Choose type',
		typeTooltip: `Shared goal: All players in the store compete to get a certain number of stars on average. They win a shared prize.
			<br /><br />Competitive: By earning stars players earn lottery tickets to win an individual prize.`,
		chooseModule: 'Choose module',
		scheduleCompetition: 'Schedule competition',
		participants: 'Participants',
		allPlayers: 'All players',
		somePlayers: 'Only players in selected departments',
		selectedDepartments: 'Selected departments',
		starsGoal: 'Stars to complete goal',
		starPerPlayer: 'star per player on average',
		starsPerPlayer: 'stars per player on average',
		description: 'Competition description',
		prize: 'Competition prize',
		typeHere: 'Type here',
		launch: 'Launch',
		error: 'Error',
		errNoType: 'Please select a type.',
		errNoModule: 'Please select a module.',
		errNoSchedule: 'Please select start and end dates.',
		errDescription: 'Please fill out a description of the competition.',
		errPrize: 'Please fill out the competition prize.',
		errAreas: 'Please select at least one department.',
		errDates1: 'Your start date is <i>later</i> than your end date.',
		errDates2: 'Your end date is in the past.',
		// errDates3: 'These dates overlap with an already existing competition.',
		errDates4: 'Your start date is in the past.',
	},
	editCompetitionPopup: {
		title: 'Edit competition',
		type: 'Type',
		module: 'Module',
		stars: 'stars',
		edit: 'Edit'
	},
	deleteCompetitionPopup: {
		title: 'Delete competition',
		text: 'Are you sure you want to delete this competition?',
		dates: 'Dates',
		status: 'Status',
		confirmBtn: 'Yes, delete',
		cancelBtn: 'No, keep'
	},
	findCompetitionWinnerPopup: {
		title: 'Find winner',
		text: 'Draw a winner of the competition. Each player gets a ticket for each star they have earned.',
		numberOfParticipants: 'Number of participants',
		numberOfTickets: 'Number of tickets',
		theWinnerIs: 'The winner is',
		errTickets: 'Error: Not enough tickets to draw a winner.'
	},
	insights: {
		action: 'Action',
		viewMuted: 'View muted',
		reset1: 'Reset deleted / muted notifications',
		reset2: 'Delete all generated insights',
		deleteNotificationPopup: {
			title: 'Delete insight',
			text: 'Do you want to delete this insight?',
			muteNotifications: 'Mute future notifications from this insight.',
			confirmBtn: 'Yes, delete',
			cancelBtn: 'No, keep'
		},
		mutedInsightsPopup: {
			title: 'Muted insights',
			text: 'Checked insights are muted.',
			saveBtn: 'Save',
			cancelBtn: 'Cancel'
		}
	},
	newMessagePopup: {
		title: 'Send a new message',
		recipients: 'Recipients',
		allPlayers: 'All players',
		somePlayers: 'Only players in selected departments',
		selectedDepartments: 'Selected departments',
		text: 'Message',
		typeHere: 'Type here',
		launch: 'Send',
		error: 'Error',
		errDescription: 'Please fill out a message.',
		errAreas: 'Please select at least one department.',
	},
	deleteMessagePopup: {
		title: 'Delete message',
		text: 'Are you sure you want to delete this message?<br />Note that players will still be able to see the message until they delete it themselves.',
		createdOn: 'Created on',
		createdBy: 'Created by',
		confirmBtn: 'Yes, delete',
		cancelBtn: 'No, keep'
	},
	editMessagePopup: {
		title: 'Edit message',
		edit: 'Edit'
	},
	facilitators: {
		facilitators: 'Facilitators',
		coFacilitators: 'Co-facilitators',
		coFacilitatorTooltip: 'A co-facilitator has access to statistics and other facilitator functionalities. You can give them further access to eg. sending messages when they are added.',
		name: 'Name',
		email: 'E-mail',
		lastLogin: 'Last login',
		addNewCoFacilitator: 'Add new',
	},
	addCoFacilitatorsToGamePopup: {
		title: 'Add co-facilitator',
		text: `Enter the e-mail of the co-facilitator you want to add to the game.<br /><br />Use space between e-mails to add multiple co-facilitators.`,
		emailPlaceholder: 'e-mail',
		okBtn: 'OK',
		emptyField: 'Please fill out the e-mail field.',
		invalidEmail: 'Invalid e-mail format.',
		noValidEmails: 'No valid e-mails',
		alreadyFacilitator: '%name% is already a facilitator or co-facilitator of this game.',
		notExistingUser: '%email% is not an existing user.'
	},
	editCoFacilitatorPopup: {
		title: 'Edit co-facilitator',
		title2: 'View co-facilitator',
		name: 'Name',
		email: 'E-mail',
		addedBy: 'Added by',
		remove: 'Remove',
		permissions: 'Permissions',
		sendMessages: 'Send message to players',
		toggleDepartmentAccess: 'Lock/unlock player access to departments',
		changeManager: 'Change manager',
		saveBtn: 'Save changes',
		cancelBtn: 'Cancel'
	},
	removeCoFacilitatorPopup: {
		title: 'Remove co-facilitator',
		text: 'Are you sure you want to remove the co-facilitator %name%?<br />They will no longer have access to this game.',
		confirmBtn: 'Yes, remove',
		cancelBtn: 'No, cancel'
	}
};

const adminUiTexts = {
	createNewGame: 'Create new game',
	client: 'Client',
	created: 'Created',
	gameURL: 'Game URL',
	facilitators: 'Facilitators',
	activePlayers: 'Players',
	completedIntro: 'Compl. Intro',
	avrCompletedModules: 'Avg. compl. modules',
	addFacilitator: 'Add new facilitator',
	name: 'Name',
	playerName: 'Player name',
	completion: 'Completion',
	email: 'E-mail',
	lastLogin: 'Last login',
	stores: 'Stores',
	connectedStore: 'Connected store',
	connectedStoreMulti: 'Connected store(s)',
	basicInfo: 'Basic info',
	createdOn: 'Created on',
	createdBy: 'Created by',
	url: 'URL',
	tags: 'Tags',
	tagsForStats: 'Tags (used for stats)',
	typeToAddTag: 'Start typing name of tag',
	noMatchesPressEnter: 'No matches. Press enter to add tag',
	addFacilitator: 'Add facilitator',
	invited: 'Invited',
	remove: 'Remove',
	deleteGame: 'Delete game',
	deleteFacilitator: 'Delete facilitator',
	gamesMulti: 'Game(s)',
	module: 'Module',
	startedBy: 'Started by #',
	completedBy: 'Completed by #',
	timeSpentAvg: 'Time spent (avg)',
	mistakesAvgPerTask: 'Mistakes (avg/task)',
	starsAvg: 'Stars (avg)',
	role: 'Role',
	typeToFilter: 'Start typing to filter by name and e-mail',
	addGamePopup: {
		title: 'Create new game',
		clientLabel: 'Client',
		clientPlaceholder: 'client name',
		gameUrlLabel: 'Game URL',
		gameUrlPlaceholder: 'game url',
		facilitatorLabel: 'Facilitator',
		facilitatorPlaceholder: 'e-mail',
		okBtn: 'Create game',
		emptyField: 'Please fill out this field',
		invalidUrl: 'Invalid url (valid example: store-1)',
		notUniqueUrl: 'This url is already in use',
		invalidName: 'Invalid name',
		invalidEmail: 'Invalid e-mail',
		invalidEmails: 'One or more e-mails are invalid.'

	},
	addFacilitatorToGamePopup: {
		title: 'Add facilitator',
		text: `Enter the mdi-email of the facilitator you want to add to the game.<br /><br />Use space between e-mails to add multiple facilitators.`,
		emailPlaceholder: 'e-mail',
		okBtn: 'OK',
		emptyField: 'Please fill out the e-mail field.',
		invalidEmail: 'Invalid e-mail format.',
		noValidEmails: 'No valid e-mails',
		alreadyFacilitator: '%name% is already a facilitator of this game.',
		alreadyInvited: '%name% is already invited to facilitate this game.',
		facilitatorAdded: '%name% was added as a facilitator.',
	},
	removeFacilitatorFromGamePopup: {
		title: 'Remove facilitator from game',
		text: 'Are you sure you want to remove the facilitator %name%?<br />The facilitator will no longer have access to this game.',
		confirmBtn: 'Yes, remove',
		cancelBtn: 'No, cancel'
	},
	deleteFacilitatorPopup: {
		title: 'Delete facilitator',
		text: 'Are you sure you want to delete this facilitator?<br />This will disconnect them from their game(s) and change their role to "player".',
		confirmBtn: 'Yes, delete',
		cancelBtn: 'No, keep'
	},
	deleteGamePopup: {
		title: 'Delete game',
		text1: 'Are you sure you want to delete the store %storeName% and its game? <br />All store, game and player data will be lost.',
		text2: `Please confirm that you want to delete %storeName%.`,
		confirmBtn: 'Yes, delete',
		cancelBtn: 'No, keep'
	},

	facilitatorInvite: {
		subject: 'You have been invited to facilitate %storeName%',
		message: 'You have been invited to facilitate %storeName%.<br /><br />Go to %storeUrl% to sign in.'
	}
};


export {
	loadingUiTexts,
	errorUiTexts,
	lobbyUiTexts,
	gameUiTexts,
	generalUiTexts,
	loginUiTexts,
	mapUiTexts,
	profileUiTexts,
	facilitatorUiTexts,
	adminUiTexts,
	yearBookUiTexts
};