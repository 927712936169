import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {checkIfValidEmail, checkIfValidUrl, checkIfValidName} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './admin-add-game-popup.scss';

const AdminAddGamePopup = ({gamesData, createGame, togglePopup}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [clientInputWarning, setClientInputWarning] = useState(null);
	const [emailInputWarning, setEmailInputWarning] = useState(null);
	const [urlWarning, setUrlWarning] = useState(null);
	const [errorMsg, setErrorMsg] = useState(null);

	/* Input client, url and facilitator e-mail */
	const [client, setClient] = useState('');
	const [url, setUrl] = useState('');
	const [facilitatorEmail, setFacilitatorEmail] = useState('');

	/**
	 * Update input
	 * @param {object} e 
	 */
	const updateInput = (e) => {
		setClientInputWarning(null);
		setEmailInputWarning(null);
		setErrorMsg(null);
		
		/* Update client name (and url) */
		if (e.target.name === 'client') {
			setClient(e.target.value);
			let url = '';
			if (e.target.value.length > 0) {
				/* Convert to lowercase, replace space with '-', remove all invalid characters */
				url = e.target.value.toLowerCase().replace(/[ ]+/g, '-').replace(/[^a-z0-9-]+/g, '');
				setUrl(url);
			}
		}
		/* Update url */
		if (e.target.name === 'url') setUrl(e.target.value.toLowerCase());

		/* Update email */
		if (e.target.name === 'email') setFacilitatorEmail(e.target.value);		
	};

	/* Check url */
	useEffect(() => {
		setUrlWarning(null);
		if (url.length > 0) {
			const {isValidUrl, urlFeedback} = checkIfValidUrl(url, gamesData);
			if (!isValidUrl && urlFeedback && adminUiTexts.addGamePopup.hasOwnProperty(urlFeedback)) {
				setUrlWarning( adminUiTexts.addGamePopup[urlFeedback]);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	/**
	 * Handle add game
	 * @returns 
	 */
	const handleAddGame = () => {
		if (isLoading) return;
		setIsLoading(true);
		setErrorMsg(null);

		/* Check input */
		const {isValidName, nameFeedback, invalidChars} = checkIfValidName(client);
		const {isValidUrl, urlFeedback} = checkIfValidUrl(url, gamesData);
		
		if (!isValidName || !isValidUrl) {
			/* Name and/or url is not valid */
			if (!isValidName && adminUiTexts.addGamePopup.hasOwnProperty(nameFeedback)) {
				setClientInputWarning(adminUiTexts.addGamePopup[nameFeedback] + ' ' + invalidChars);
			}
			if (!isValidUrl && adminUiTexts.addGamePopup.hasOwnProperty(urlFeedback)) {
				setUrlWarning(adminUiTexts.addGamePopup[urlFeedback]);
			}
			setIsLoading(false);
		} else {
			let allEmailsValid = true;
			let allEmails = [];
			const emailsFeedback = [];
			if (facilitatorEmail && facilitatorEmail.length > 0) {
				/* Check e-mail(s) */
				allEmails = facilitatorEmail.split(' ');
				
				allEmails.forEach((e) => {
					const {isValidEmail, emailFeedback} = checkIfValidEmail(e);
					if (!isValidEmail) {
						allEmailsValid = false;
						emailsFeedback.push(emailFeedback);
					}
				});
			}

			if (!allEmailsValid) {
				/* Invalid e-mail(s) */
				if (allEmailsValid.length === 1 && emailsFeedback.length === 1) {
					/* 1 e-mail entered */
					if (adminUiTexts.addGamePopup.hasOwnProperty(emailsFeedback[0])) {
						setEmailInputWarning(adminUiTexts.addGamePopup[emailsFeedback[0]]);
					} else {
						setEmailInputWarning(adminUiTexts.addGamePopup.invalidEmail);
					}
				} else {
					/* 2+ e-mails entered */
					setEmailInputWarning(adminUiTexts.addGamePopup.invalidEmails);
				}
				setIsLoading(false);
			} else {
				/* All input fields ok, create game */
				createGame(client, url, allEmails).then((response) => {
					if (response.status === 'error') {
						if (response.status.error === 'couldNotInviteFacilitator') {
							/* Success creating game, error adding facilitator, close popup */
							setIsLoading(false);	
							togglePopup(false);	
						} else {
						/* Error creating game */
							setIsLoading(false);
							setErrorMsg(response.error);
						}
					} else {
						/* Success creating game, close popup */
						setIsLoading(false);
						togglePopup(false);	
					}		
				});
			}
		}
	};

	/**
	 * Checks if the create game button should be enabled or not.
	 * @returns bool. True if input filled out correctly.
	 */
	const getButtonDisabled = () => {
		if (emailInputWarning !== null) return true;
		if (clientInputWarning !== null) return true; 
		if (urlWarning !== null) return true;
		if (!url || url.length === 0) return true;
		if (!client  || client.length === 0) return true;

		return false;
	};

	return (
		<div className="AdminAddGamePopup" onClick={(e) => {e.stopPropagation();}}>
			<div className="AdminAddGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="AdminAddGamePopup-header">
					<div className="AdminAddGamePopup-title">{adminUiTexts.addGamePopup.title}</div>
					<div 
						className="AdminAddGamePopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className='AdminAddGamePopup-body'>
					{/* Input client name */}
					<div className='AdminAddGamePopup-input'>
						<div className='AdminAddGamePopup-inputLabel'>
							<span>{adminUiTexts.addGamePopup.clientLabel}</span>
						</div>
						<div className={'AdminAddGamePopup-inputField' + (clientInputWarning ? ' invalid' : '')}>
							<input 
								name="client"
								type="text"
								placeholder={adminUiTexts.addGamePopup.clientPlaceholder}
								value={client ? client : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{clientInputWarning}</span></div>
					</div>
					{/* Input game url */}
					<div className='AdminAddGamePopup-input'>
						<div className='AdminAddGamePopup-inputLabel'>
							<span>{adminUiTexts.addGamePopup.gameUrlLabel}</span>
						</div>
						<div className='AdminAddGamePopup-inputField url'>
							<span>{window.location.host + '/'}</span>
							<input 
								name="url"
								type="text"
								placeholder={adminUiTexts.addGamePopup.gameUrlPlaceholder}
								value={url ? url : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{urlWarning}</span></div>
					</div>
					{/* Input facilitator e-mail */}
					<div className='AdminAddGamePopup-input'>
						<div className='AdminAddGamePopup-inputLabel'>
							<span>{adminUiTexts.addGamePopup.facilitatorLabel}</span>
						</div>
						<div className={'AdminAddGamePopup-inputField' + (emailInputWarning ? ' invalid' : '')}>
							<input 
								name="email"
								type="text"
								placeholder={adminUiTexts.addGamePopup.facilitatorPlaceholder}
								value={facilitatorEmail ? facilitatorEmail : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{emailInputWarning}</span></div>
					</div>
					<div className="AdminAddGamePopup-errorMsg"><span>{errorMsg}</span></div>

					<div className='AdminAddGamePopup-createGameBtn'>
						<Button
							text={adminUiTexts.addGamePopup.okBtn}
							classes={['addGame']}
							isLoading={isLoading}
							isDisabled={getButtonDisabled()}
							onClick={() => {handleAddGame();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminAddGamePopup.propTypes = {
	gamesData: PropTypes.array.isRequired,
	createGame: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default AdminAddGamePopup;
