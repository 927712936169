const moduleProduce2 = [
	'modules/tasks/multiple-choice/produce-2-banana-boxes/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-banana-boxes/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-bananas-ideal-number/background.svg',
	'modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-display-peppers/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-display-peppers/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-display-peppers/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-display-peppers/option-4.svg',
	'modules/tasks/multiple-choice/produce-2-help-know-need-extra/background.svg',
	'modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-4.svg',
	'modules/tasks/multiple-choice/produce-2-how-put-organics/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-how-put-organics/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-how-put-organics/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-not-in-cooler/option-1.svg',
	'modules/tasks/multiple-choice/produce-2-not-in-cooler/option-2.svg',
	'modules/tasks/multiple-choice/produce-2-not-in-cooler/option-3.svg',
	'modules/tasks/multiple-choice/produce-2-not-in-cooler/option-4.svg',

	'modules/tasks/organize/produce-2-cut-fruit/background.svg',
	'modules/tasks/organize/produce-2-cut-fruit/cut-fruit.svg',

	'modules/tasks/spot-errors/produce-2-fruit-stands.svg',
	'modules/tasks/spot-errors/produce-2-refridgerated-produce-1.svg',
	'modules/tasks/spot-errors/produce-2-refridgerated-produce-2.svg',
];

export {
	moduleProduce2
};
