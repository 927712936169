import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './order-dnd-preview.scss';

const OrderDndPreview = ({taskId, itemsData}) => {
	let {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemId = item.id;
	const itemData = itemsData.find((i) => {return i.id === itemId;});

	return (
		<div className={'OrderDndPreview ' + taskId} style={style}>
			<div className="OrderDndPreview-item">
				<span>{itemData ? itemData.text : null}</span>
			</div>
		</div>
	);
};

OrderDndPreview.propTypes = {
	taskId: PropTypes.string.isRequired,
	itemsData: PropTypes.array.isRequired,
};

export default OrderDndPreview;