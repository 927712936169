import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import appConfig from 'config/app.config';
import {facilitatorPagesData} from 'data/facilitator-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import FacilitatorHeader from './facilitator-header/facilitator-header';
import FacilitatorManagerPopup from './facilitator-manager-popup/facilitator-manager-popup';
import FacilitatorSelectStore from './facilitator-select-store/facilitator-select-store';
import FacilitatorOverview from './facilitator-overview/facilitator-overview';
import FacilitatorPlayers from './facilitator-players/facilitator-players';
import FacilitatorGameModules from './facilitator-game-modules/facilitator-game-modules';
import FacilitatorResources from './facilitator-resources/facilitator-resources';
import FacilitatorInsights from './facilitator-insights/facilitator-insights';
import FacilitatorCompetitions from './facilitator-competitions/facilitator-competitions';
import FacilitatorMessages from './facilitator-messages/facilitator-messages';
import FacilitatorStatistics from './facilitator-statistics/facilitator-statistics';
import FacilitatorTaskPopup from './facilitator-task-popup/facilitator-task-popup';
import FacilitatorStatisticsTasks from './facilitator-statistics/facilitator-statistics-tasks';
import FacilitatorFacilitators from './facilitator-facilitators/facilitator-facilitators';
import Button  from 'components/ui/button/button';
import './facilitator.scss';


const Facilitator = (props) => {
	const {
		loadingErrMsg,
		page,
		pageProps,
		subpage, 
		userData, 
		usersData,
		gamesData, 
		gameData, 
		gamePlayers,
		competitionsNotificationData,
		reportsNotificationData,
		handleSelectGame, 
		handleGoToPage, 
		removePlayerFromGame,
		updatePlayerAreaIds,
		updateGameManager,
		deleteInsightNotification,
		updateMutedInsights,
		resetInsights,
		createCompetition,
		deleteCompetition,
		editCompetition,
		findCompetitionWinner,
		createMessage,
		deleteMessage,
		editMessage,
		addCoFacilitatorsToGame,
		updateCoFacilitator,
		removeCoFacilitatorFromGame,
		handleLogout,
		deviceInfo,
		closeCompetitionNotification,
		closeReportsNotification,
		markReportAsSeen,
		userId,
		deleteReport
	} = props;

	/* Check if user is co-facilitator (if so, coFacilitatorData is not null) */
	let coFacilitatorData = null;
	if (
		/* User must be listed under co-facilitators */
		gameData &&
		gameData.coFacilitators &&
		gameData.coFacilitators.length > 0 &&
		gameData.coFacilitators.some((c) => {return c.email === userData.email;}) &&
		( 
			/* User must NOT be listed under facilitators */
			!gameData.facilitatorEmails ||
			gameData.facilitatorEmails.indexOf(userData.email) < 0
		) 
	) {
		coFacilitatorData = gameData.coFacilitators.find((c) => {return c.email === userData.email;});
	}


	/* Get page data */
	const pageData = facilitatorPagesData.find((p) => {return p.id === page;});

	/* Manager popup */
	const [showManagerPopup, setShowManagerPopup] = useState(false);

	/* Facilitator module popup */
	const [modulePopup, setModulePopup] = useState(null);
	
	/**
	 * Open / close module popup
	 * @param {object} module 
	 * @param {array} playerData 
	 */
	const toggleModulePopup = (moduleId = null, taskId = null, isCompetition = false) => {
		if (!moduleId) {
			setModulePopup(null);	
		} else {
			setModulePopup({moduleId: moduleId, taskId: taskId, isCompetition: isCompetition});	
		}
	};

	/* Get content component */
	let PageComponent = FacilitatorOverview;
	if (page !== 'overview') {
		if (page === 'select-store') {
			PageComponent = FacilitatorSelectStore;
		}
		if (page === 'training') {
			PageComponent = FacilitatorPlayers;
			if (subpage === 'game-modules') PageComponent = FacilitatorGameModules;
			if (subpage === 'resources') PageComponent = FacilitatorResources;
		}
		if (page === 'retention') {
			PageComponent = FacilitatorInsights; 
			if (subpage === 'competitions') PageComponent = FacilitatorCompetitions;
			if (subpage === 'messages') PageComponent = FacilitatorMessages;
		}
		if (page === 'analytics') {
			PageComponent = FacilitatorStatistics;
			if (subpage === 'tasks') {
				PageComponent = FacilitatorStatisticsTasks;
			};
		}
		if (page === 'facilitators') {
			PageComponent = FacilitatorFacilitators;
		}
	}

	/* Competition notification title */
	let competitionNotificationTitle = null;
	if (competitionsNotificationData) {
		if (competitionsNotificationData.length > 1) {
			competitionNotificationTitle = 
				JSON.parse(JSON.stringify(facilitatorUiTexts.competitionNotification.title2));
			competitionNotificationTitle = 
				competitionNotificationTitle.replace('%number%', competitionsNotificationData.length);
		} else {
			competitionNotificationTitle = facilitatorUiTexts.competitionNotification.title1;
		}
	} 

	/* Manager id */
	const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);

	return (
		<div className="Facilitator">
			<DndProvider options={HTML5toTouch}>
				<div className="Facilitator-header">
					<FacilitatorHeader 
						page={page} 
						userData={userData} 
						coFacilitatorData={coFacilitatorData}
						gameData={gameData}
						setShowManagerPopup={setShowManagerPopup}
						handleGoToPage={handleGoToPage}
						handleLogout={handleLogout}
					/>
				</div>
				<div className="Facilitator-content">
					{/* Loading error message */}
					{loadingErrMsg && <div className="Facilitator-loadingErrMsg"><span>{loadingErrMsg}</span></div>}

					{/* Facilitator page */}
					<PageComponent 
						isFacilitator={true}
						deviceInfo={deviceInfo}
						userData={userData}
						coFacilitatorData={coFacilitatorData}
						pageData={pageData} 
						usersData={usersData}
						gamesData={gamesData}
						gameData={gameData}
						gamePlayers={gamePlayers}
						handleSelectGame={handleSelectGame}
						handleGoToPage={handleGoToPage}
						removePlayerFromGame={removePlayerFromGame}
						updatePlayerAreaIds={updatePlayerAreaIds}
						deleteInsightNotification={deleteInsightNotification}
						updateMutedInsights={updateMutedInsights}
						resetInsights={resetInsights}
						createCompetition={createCompetition}
						deleteCompetition={deleteCompetition}
						editCompetition={editCompetition}
						findCompetitionWinner={findCompetitionWinner}
						createMessage={createMessage}
						deleteMessage={deleteMessage}
						editMessage={editMessage}
						addCoFacilitatorsToGame={addCoFacilitatorsToGame}
						updateCoFacilitator={updateCoFacilitator}
						removeCoFacilitatorFromGame={removeCoFacilitatorFromGame}
						toggleModulePopup={toggleModulePopup}						
						modulePlayerData={(pageProps ? pageProps.modulePlayerData : null)}
						modulePlayersData={(pageProps ? pageProps.modulePlayersData : null)}
						moduleData={(pageProps ? pageProps.moduleData : null)}
						markReportAsSeen={markReportAsSeen}
						userId={userId}
						deleteReport={deleteReport}
					/>
				</div>

				{/* Competition notification */}
				{competitionsNotificationData && <div className="Facilitator-competitionNotification">
					<div className="Facilitator-competitionNotificationHeader">
						<div className="Facilitator-competitionNotificationTitle">
							<span>{competitionNotificationTitle}</span>
						</div>
						<div 
							className="Facilitator-competitionNotificationCloseBtn" 
							onClick={() => {closeCompetitionNotification();}}
						/>
					</div>
					<div className="Facilitator-competitionNotificationBody">
						<div className="Facilitator-competitionNotificationBtn">
							<Button 
								classes={['addGame']}
								text={facilitatorUiTexts.competitionNotification.btn}
								onClick={() => {handleGoToPage('retention', 'competitions');}}
							/>
						</div>
					</div>
				</div>}

				{/* reports notification */}
				{reportsNotificationData && <div className="Facilitator-reportsNotification">
					<div className="Facilitator-reportsNotificationHeader">
						<div className="Facilitator-reportsNotificationTitle">
							<span>{
								facilitatorUiTexts.reportsNotification.title
									.replace('%number%', reportsNotificationData.length)
							}</span>
						</div>
						<div 
							className="Facilitator-reportsNotificationCloseBtn" 
							onClick={() => {closeReportsNotification();}}
						/>
					</div>
					<div className="Facilitator-reportsNotificationBody">
						<div className="Facilitator-reportsNotificationBtn">
							<Button 
								classes={['addGame']}
								text={facilitatorUiTexts.reportsNotification.btn}
								onClick={() => {handleGoToPage('training');}}
							/>
						</div>
					</div>
				</div>}


				{/* Module popup */}
				{modulePopup &&
					<div className='Facilitator-popupOverlay' onClick={() => {toggleModulePopup(null, null);}}>
						<FacilitatorTaskPopup 
							isFacilitator={true}
							isCompetition={modulePopup.isCompetition}
							moduleId={modulePopup.moduleId}
							managerId={managerId}
							initialTaskId={(modulePopup.taskId ? modulePopup.taskId : null)}
							deviceInfo={deviceInfo}
							playerData={{avater: null}}
							modulePlayerStats={pageProps ? pageProps.modulePlayerData : null}
							closePopup={() => {toggleModulePopup(null, null);}}
						/>
					</div>}
				{showManagerPopup &&
					<FacilitatorManagerPopup 
						gameData={gameData} 
						updateGameManager={updateGameManager}
						setShowManagerPopup={setShowManagerPopup}
					/>
				}
			</DndProvider>
		</div>
	);

};

Facilitator.propTypes = {
	loadingErrMsg: PropTypes.string,
	page: PropTypes.string.isRequired,
	pageProps: PropTypes.object,
	subpage: PropTypes.string,
	userData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
	gamesData: PropTypes.array.isRequired,
	gameData: PropTypes.object,
	gamePlayers: PropTypes.array.isRequired,
	competitionsNotificationData: PropTypes.array,
	reportsNotificationData: PropTypes.array,
	handleSelectGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	removePlayerFromGame: PropTypes.func.isRequired,
	updatePlayerAreaIds: PropTypes.func.isRequired,
	updateGameManager: PropTypes.func.isRequired,
	deleteInsightNotification: PropTypes.func.isRequired,
	updateMutedInsights: PropTypes.func.isRequired,
	resetInsights: PropTypes.func.isRequired,
	createCompetition: PropTypes.func.isRequired,
	deleteCompetition: PropTypes.func.isRequired,
	editCompetition: PropTypes.func.isRequired,
	findCompetitionWinner: PropTypes.func.isRequired,
	createMessage: PropTypes.func.isRequired,
	deleteMessage: PropTypes.func.isRequired,
	editMessage: PropTypes.func.isRequired,
	addCoFacilitatorsToGame: PropTypes.func.isRequired,
	updateCoFacilitator: PropTypes.func.isRequired,
	removeCoFacilitatorFromGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	closeCompetitionNotification: PropTypes.func.isRequired,
	closeReportsNotification: PropTypes.func.isRequired,
	markReportAsSeen: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	deleteReport: PropTypes.func.isRequired,
};

export default Facilitator;
