const moduleMeat2 = [
	'modules/tasks/multiple-choice/meat-2-hamburger/option-1.svg',
	'modules/tasks/multiple-choice/meat-2-hamburger/option-2.svg',
	'modules/tasks/multiple-choice/meat-2-hamburger/option-3.svg',
	'modules/tasks/multiple-choice/meat-2-help-plan-production/background.svg',
	
	'modules/tasks/organize/meat-2-meat-case/background.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-1.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-2.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-3.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-4.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-5.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-6.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-7.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-8.svg',
	'modules/tasks/organize/meat-2-meat-case/meat-9.svg',

	'modules/tasks/spot-errors/meat-2-freezer.svg',
	'modules/tasks/spot-errors/meat-2-meat-case.svg',
];

export {
	moduleMeat2
};
