
const competitionTemplate = {
	areaId: 'competition',
	background: 'outside',
	title: '',
	description: '',
	relatedModuleIds: [],
	tasks: [],
	playerAreaIds: [] // if empty all can play, otherwise only players with access to listed areas can play
};


const competitionTypesData = [
	{
		id: 'competitive',
		title: 'Competitive'
	},
	{
		id: 'shared-goal',
		title: 'Shared goal'
	}
];


export {
	competitionTemplate,
	competitionTypesData
};
