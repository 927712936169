import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './countdown.scss';

const Countdown = ({delayTime, shownDelay, onCountdown}) => {
	const [secondsGone, setSecondsGone] = useState(0);
	const [currentTime, setCurrentTime] = useState(shownDelay);
	const [currentPercentage, setCurrentPercentage] = useState(0);
	
	useEffect(() => {
		let seconds = secondsGone;

		/* Start countdown */
		let intervalId = null;
		let functionCalled = false;
		intervalId = setInterval(() => {
			seconds++;
			const newCurrentTime = Math.max(shownDelay - seconds, 0);
			setSecondsGone(seconds);
			setCurrentTime(newCurrentTime);
			setCurrentPercentage(100 - (newCurrentTime / shownDelay * 100));
			if (!functionCalled && seconds >= delayTime) {
				functionCalled = true;
				onCountdown();
			}
		
			return () => {return clearInterval(intervalId);};
		}, 1000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ( 
		<div className='Countdown'>
			<div 
				className='Countdown-graphic'
				style={{background: 'conic-gradient(#bbbbbb ' + currentPercentage + '%, #ffffff ' + 1 + '%)'}}
			/>
			<div className='Countdown-numbering'>
				<span>{currentTime}</span>
			</div>
		</div>
	);
};

Countdown.propTypes = {
	delayTime: PropTypes.number.isRequired,
	shownDelay: PropTypes.number.isRequired,
	onCountdown: PropTypes.func.isRequired,
};

export default Countdown;