/* Always loaded */
const basicImagesData = [
	'icon-loading.svg',
	'logo.svg',
	'backgrounds/login.svg',
	'characters/manager.svg',
];

/* Always loaded in game section */
const basicGameImagesData = [
	'mask-shape.svg',
	
	'avatar-maker/avatar-shadow.svg',
	'avatar-maker/avatar-bottom.svg',
	'avatar-maker/avatar.svg',
	'avatar-maker/generating-avatar.svg',
	'avatar-maker/none.svg',
	'avatar-maker/body/body-f.svg',
	'avatar-maker/body/body-f2.svg',
	'avatar-maker/body/body-m.svg',
	'avatar-maker/body/body-m2.svg',

	'backgrounds/avatar-maker.svg',
	'badges/unknown.svg',

	'areas/island-bagger.svg',
	'areas/island-bakery.svg',
	'areas/island-cashier.svg',
	'areas/island-center-store-item-1.svg',
	'areas/island-center-store.svg',
	'areas/island-customer-service.svg',
	'areas/island-deli.svg',
	'areas/island-floral.svg',
	'areas/island-meat.svg',
	'areas/island-orientation.svg',
	'areas/island-produce.svg',
	'areas/island-seafood.svg',
	'areas/island-title.svg',
	'areas/island.svg',
	'areas/wave.svg',

	'map/bottle.svg',
	'map/bridge-1.svg',
	'map/bridge-2-half.svg',
	'map/bridge-2.svg',
	'map/bridge-3-half.svg',
	'map/clouds.svg',
	'map/loot-box/loot-box-open.svg',
	'map/loot-box/loot-box-background.svg',
	'map/loot-box/loot-box-in-water-closed.svg',
	'map/loot-box/loot-box-in-water-closed-glow.svg',
	'map/loot-box/loot-box-in-water-empty.svg',
	'map/loot-box/loot-box-closed.svg',
	'map/module-1-sidequest.svg',
	'map/module-1.svg',
	'map/module-2-sidequest.svg',
	'map/module-2.svg',
	'map/module-3-sidequest.svg',
	'map/module-3.svg',
	'map/module-bagger-1.svg',
	'map/module-bagger.svg',
	'map/module-bakery-1.svg',
	'map/module-bakery-2.svg',
	'map/module-bakery.svg',
	'map/module-cashier-1.svg',
	'map/module-cashier.svg',
	'map/module-center-store-1.svg',
	'map/module-center-store.svg',
	'map/module-customer-service-1.svg',
	'map/module-customer-service-2.svg',
	'map/module-customer-service.svg',
	'map/module-deli-1.svg',
	'map/module-deli-2.svg',
	'map/module-deli.svg',
	'map/module-floral-1.svg',
	'map/module-floral-2.svg',
	'map/module-floral.svg',
	'map/module-meat-1.svg',
	'map/module-meat-2.svg',
	'map/module-meat.svg',
	'map/module-orientation-1.svg',
	'map/module-orientation.svg',
	'map/module-produce-1.svg',
	'map/module-produce-2.svg',
	'map/module-produce.svg',
	'map/module-seafood-1.svg',
	'map/module-seafood-2.svg',
	'map/module-seafood.svg',
	'map/module-sq-5s.svg',
	'map/module-sq-cleaning.svg',
	'map/module-sq-cooler-freezer.svg',
	'map/module-sq-daily-guidelines.svg',
	'map/module-sq-food-safety.svg',
	'map/module-sq-inventory-management.svg',
	'map/module-sq-safety.svg',
	'map/module-sq-shrink.svg',
	'map/module-status-0.svg',
	'map/module-status-1.svg',
	'map/module-status-2.svg',
	'map/module-status-3.svg',
	'map/module-status-no-stars.svg',
	'map/module-status-shadow.svg',
	'map/puddle-1.svg',
	'map/puddle-2.svg',
	'map/raft.svg',
	'map/rock-bridge-1.svg',
	'map/rock-bridge-2.svg',
	'map/rock-bridge-3.svg',
	'map/rock-bridge-4.svg',
	'map/rock-bridge-5.svg',
	'map/rock-bridge-6.svg',
	'map/rock-bridge-7.svg',
	'map/seagull-2.svg',
	'map/seagull.svg',
	'map/stones-1.svg',
	'map/stones-2.svg',
	'map/stones-3.svg',
	'map/under-construction.svg',

	'modules/icon-arrow-right.svg',
	'modules/icon-back.svg',
	'modules/icon-check.svg',
	'modules/icon-check2.svg',
	'modules/icon-error.svg',
	'modules/icon-island.svg',
	'modules/icon-move.svg',
	'modules/icon-move2.svg',
	'modules/icon-next.svg',
	'modules/icon-up-down.svg',
	'modules/star-empty.svg',
	'modules/star-full.svg',

	'profile/icon-close.svg',
	'profile/icon-highscore-star.svg',
	'profile/icon-lock.svg',
	'profile/icon-profile-badges.svg',
	'profile/icon-profile-high-score.svg',
	'profile/icon-profile-progress.svg',
	'profile/pin-blue.svg',
	'profile/pin-red.svg',
	'profile/pin-yellow.svg',
	'profile/pinned-paper.svg',
	'profile/postit-pink.svg',
	'profile/postit-yellow.svg'
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'basic-facilitator': require('data/images/facilitator').facilitator,
	'basic-admin': require('data/images/facilitator').facilitator,
	'backgrounds': require('data/images/backgrounds').backgrounds,
	'avatar-maker': require('data/images/avatar-maker').avatarMaker,
	'badges': require('data/images/badges').badges,
	'characters': require('data/images/characters').characters,
	'module-task-intros': require('data/images/module-task-intros').moduleTaskIntros,
	'module-tasks': require('data/images/module-tasks').moduleTasks,
	'module-bagger-1': require('data/images/module-bagger-1').moduleBagger1,
	'module-bakery-1': require('data/images/module-bakery-1').moduleBakery1,
	'module-bakery-2': require('data/images/module-bakery-2').moduleBakery2,
	'module-cashier-1': require('data/images/module-cashier-1').moduleCashier1,
	'module-center-store-1': require('data/images/module-center-store-1').moduleCenterStore1,
	'module-center-store-2': require('data/images/module-center-store-2').moduleCenterStore2,
	'module-cleaning-1': require('data/images/module-cleaning-1').moduleCleaning1,
	'module-cooler-freezer-1': require('data/images/module-cooler-freezer-1').moduleCoolerFreezer1,
	'module-customer-service-1': require('data/images/module-customer-service-1').moduleCustomerService1,
	'module-customer-service-2': require('data/images/module-customer-service-2').moduleCustomerService2,
	'module-daily-guidelines-1': require('data/images/module-daily-guidelines-1').moduleDailyGuidelines1,
	'module-deli-1': require('data/images/module-deli-1').moduleDeli1,
	'module-deli-2': require('data/images/module-deli-2').moduleDeli2,
	'module-floral-1': require('data/images/module-floral-1').moduleFloral1,
	'module-floral-2': require('data/images/module-floral-2').moduleFloral2,
	'module-intro': require('data/images/module-intro').moduleIntro,
	'module-meat-1': require('data/images/module-meat-1').moduleMeat1,
	'module-meat-2': require('data/images/module-meat-2').moduleMeat2,
	'module-orientation-1': require('data/images/module-orientation-1').moduleOrientation1,
	'module-produce-1': require('data/images/module-produce-1').moduleProduce1,
	'module-produce-2': require('data/images/module-produce-2').moduleProduce2,
	'module-seafood-1': require('data/images/module-seafood-1').moduleSeafood1,
	'module-seafood-2': require('data/images/module-seafood-2').moduleSeafood2,
	'module-5s': require('data/images/module-5s').module5s,
	'module-shrink-1': require('data/images/module-shrink-1').moduleShrink1
};

export {
	imagesData
};
