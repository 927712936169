import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {getParticipatingPlayers} from 'helpers/competition-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {checkIfModuleIsCompleted} from 'helpers/module-helper';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {competitionTypesData} from 'data/templates/competition-template';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import Button from 'components/ui/button/button';
import FacilitatorNewCompetitionPopup from './facilitator-new-competition-popup/facilitator-new-competition-popup';
import FacilitatorDeleteCompetitionPopup from 
	'./facilitator-delete-competition-popup/facilitator-delete-competition-popup';
import FacilitatorEditCompetitionPopup from './facilitator-edit-competition-popup/facilitator-edit-competition-popup';
import FacilitatorFindWinnerPopup from './facilitator-find-winner-popup/facilitator-find-winner-popup';
import './facilitator-competitions.scss';

const FacilitatorCompetitions = (props) => {
	const {
		coFacilitatorData,
		pageData, 
		gameData, 
		gamePlayers, 
		createCompetition, 
		deleteCompetition, 
		editCompetition,
		findCompetitionWinner,
		toggleModulePopup,
		handleGoToPage,
	} = props;

	/* Toggle "new competition" popup */
	const [showNewCompetitionPopup, setShowNewCompetitionPopup] = useState(false);

	/* Toggle "delete competition" popup */
	const [deleteCompetitionData, setDeleteCompetitionData] = useState(null);

	/* Toggle "edit competition" popup */
	const [editCompetitionData, setEditCompetitionData] = useState(null);

	/* Toggle "find winner" popup */
	const [findWinnerCompetitionData, setFindWinnerCompetitionData] = useState(null);

	/* Sort all created competition */
	const competitions = (gameData.competitions
		?	sortArrayByProperty(gameData.competitions, 'startDate', 'DESC')
		: []
	);

	return (
		<div className="FacilitatorCompetitions">
			<div className="FacilitatorCompetitions-header">
				<FacilitatorHeaderSubpage 
					page={pageData.id} 
					subpage="competitions"
					subpageIds={pageData.subpageIds}
					handleGoToPage={handleGoToPage}
				/>
				{!coFacilitatorData && <div className="FacilitatorCompetitions-addCompetitionBtn">
					<Button 
						classes={['addGame']} 
						text={facilitatorUiTexts.addCompetitionBtn}
						onClick={() => {setShowNewCompetitionPopup(true);}} />
				</div>}
			</div>
			<div className="FacilitatorCompetitions-competitions">
				{competitions.map((c, i) => {
					/* Get competition type data */
					const competitionType = (competitionTypesData.some((t) => {return t.id === c.type;})
						? competitionTypesData.find((t) => {return t.id === c.type;})
						: competitionTypesData[0]
					);

					/* Get competition module data */
					const competitionModuleData = modulesCompetitionsData.find((m) => {return m.id === c.moduleId;});
					if (!competitionModuleData) return null;

					/* Get available & completed players, also get their earned stars */
					let numberOfParticipants = 0;
					let numberOfCompletedPlayers = 0;
					let totalStars = 0;
					if (c.result) {
						numberOfParticipants = c.result.numberOfAvailablePlayers;
						numberOfCompletedPlayers = c.result.numberOfCompletedPlayers;
						totalStars = c.result.totalStars;
					} else {
						
						const allCanParticipate = (!c.selectedAreaIds || c.selectedAreaIds.length === 0);
						const participatingPlayers = getParticipatingPlayers(
							gamePlayers, allCanParticipate, c.selectedAreaIds ? c.selectedAreaIds : []
						);
						numberOfParticipants = participatingPlayers.length;
						participatingPlayers.forEach((p) => {
							/* Check competition module */
							if (p.competitions && p.competitions.length > 0) {
								const playerCompetitionData = p.competitions.find((pcd) => {
									return pcd.competitionId === c.id;
								});
								if (playerCompetitionData) {
									/* Player has started competition module */
									if (checkIfModuleIsCompleted(playerCompetitionData) === true) {
										/* Player has completed competition module */
										numberOfCompletedPlayers += 1;
										totalStars += playerCompetitionData.maxStars;
									}
								}
							}
						});
					}

					const starsPercent = (competitionType.id === 'shared-goal' && c.stars > 0
						? Math.min(100, Math.round((totalStars / c.stars) * 100))
						: 0
					);

					let status = 'finished';
					let statusMessage = facilitatorUiTexts.finished;
					const today = dayjs(new Date()).format('YYYY-MM-DD');
					if (dayjs(today).diff(dayjs(c.endDate), 'days') <= 0) {
						if (dayjs(today).diff(dayjs(c.startDate), 'days') < 0) {
							status = 'ready';
							statusMessage = facilitatorUiTexts.ready;
						} else {
							const daysLeft = dayjs(c.endDate).diff(dayjs(today), 'days');
							status = 'open';
							if (daysLeft === 1) {
								/* 1 day left */
								statusMessage = daysLeft + ' ' + facilitatorUiTexts.dayLeft;
							} else {
								if (daysLeft > 1) {
									/* 2 or more days left */
									statusMessage = daysLeft + ' ' + facilitatorUiTexts.daysLeft;
								} else {
									/* Less than 1 day left - swith to hours */
									const now =  dayjs(new Date());
									const tomorrow = dayjs(dayjs(new Date()).format('YYYY-MM-DD')).add(1, 'day');
									const hoursLeft = -dayjs(now).diff(dayjs(tomorrow), 'hour');
									if (hoursLeft <= 1) {
										/* 1 or less hours left */
										statusMessage = (hoursLeft < 0 ? '<' : '') 
											+ hoursLeft + ' ' + facilitatorUiTexts.hourLeft;
									} else {
										/* 2 or more hours left */
										statusMessage = hoursLeft + ' ' + facilitatorUiTexts.hoursLeft;
									}
								}
							}
						}
					}

					const canEdit = (status !== 'finished' && !coFacilitatorData);
					const canDelete = (coFacilitatorData ? false : true);
					const canFindWinner = (coFacilitatorData ? false : true);

					return (
						<div key={i} className="FacilitatorCompetitions-competition">
							<div className="FacilitatorCompetitions-competitionHeader">
								<div className="FacilitatorCompetitions-competitionModule">
									<span>{competitionModuleData.title}</span>
									<span>({competitionType.title})</span>
								</div>
								<div className={'FacilitatorCompetitions-competitionStatus ' + status}>
									<span>{statusMessage}</span>
								</div>
							</div>
							<div className="FacilitatorCompetitions-competitionBody">
								<div className="FacilitatorCompetitions-competitionInfo">
									{/* Dates */}
									<div className="FacilitatorCompetitions-competitionDates"><span>
										{dayjs(c.startDate).format('MM/DD/YYYY')}
										{' - '}										
										{dayjs(c.endDate).format('MM/DD/YYYY')}
									</span></div>

									{/* Participants */}
									<div className="FacilitatorCompetitions-competitionPlayers">
										<span>{numberOfCompletedPlayers}/{numberOfParticipants}</span>
									</div>

									{/* View tasks btn */}
									<div 
										className="FacilitatorCompetitions-viewTasksBtn"
										onClick={() => {toggleModulePopup(competitionModuleData.id, null, true);}}
									>
										<span>{facilitatorUiTexts.viewModule}</span>
									</div>

									{/* Delete btn */}
									{canDelete && <div 
										className="FacilitatorCompetitions-deleteBtn"
										onClick={() => {setDeleteCompetitionData(c);}}
									>
										<span>{facilitatorUiTexts.delete}</span>
									</div>}

									{/* Edit btn */}
									{canEdit && <div 
										className="FacilitatorCompetitions-editBtn"
										onClick={() => {setEditCompetitionData(c);}}
									>
										<span>{facilitatorUiTexts.edit}</span>
									</div>}

									{/* Competitive: find winner btn */}
									{(
										canFindWinner && 
										competitionType.id === 'competitive' && 
										(!c.result || !c.result.winner)
									) && 
										<div 
											className={'FacilitatorCompetitions-competitionWinnerBtn ' + status}
											onClick={() => {
												if (status === 'finished') setFindWinnerCompetitionData(c);
											}}
										>
											<span>{facilitatorUiTexts.findWinner}</span>
										</div>
									}
									{/* Competitive: winner */}
									{(competitionType.id === 'competitive' && c.result && c.result.winner) && 
										<div className="FacilitatorCompetitions-competitionWinner">
											<span>{facilitatorUiTexts.winner}:</span>
											<span>{c.result.winner}</span>
										</div>
									}

									{/* Shared goal: star status */}
									{competitionType.id === 'shared-goal' && 
										<div 
											className={'FacilitatorCompetitions-goalStatus' + 
												(status === 'finished' 
													? starsPercent >= 100 ? ' success' : ' fail' 
													: ''
												)
											}
										>
											<div 
												className="FacilitatorCompetitions-goalStatusTrack" 
												style={{width: starsPercent + '%'}} 
											/>
											<div className="FacilitatorCompetitions-goalStatusLabel" >
												<span>{totalStars}/{c.stars}</span>
											</div>
										</div>}
								</div>
								<div className="FacilitatorCompetitions-competitionDescriptionAndPrize">
									{c.description && renderMarkdown(c.description)}
									{c.prize && renderMarkdown(c.prize)}
								</div>
							</div>
						</div>
					);
				})}
			</div>

			

			{showNewCompetitionPopup &&
				<FacilitatorNewCompetitionPopup 
					gameData={gameData}
					gamePlayers={gamePlayers}
					setShowNewCompetitionPopup={setShowNewCompetitionPopup}
					createCompetition={createCompetition}
				/>
			}

			{deleteCompetitionData && 
				<FacilitatorDeleteCompetitionPopup 
					gameData={gameData}
					competitionData={deleteCompetitionData}
					setDeleteCompetitionData={setDeleteCompetitionData}
					deleteCompetition={deleteCompetition}
				/>
			}

			{editCompetitionData &&
				<FacilitatorEditCompetitionPopup 
					gameData={gameData}
					gamePlayers={gamePlayers}
					competitionData={editCompetitionData}
					setEditCompetitionData={setEditCompetitionData}
					editCompetition={editCompetition}
				/>
			}

			{findWinnerCompetitionData && 
				<FacilitatorFindWinnerPopup 
					competitionData={findWinnerCompetitionData}
					gamePlayers={gamePlayers}
					setFindWinnerCompetitionData={setFindWinnerCompetitionData}
					findCompetitionWinner={findCompetitionWinner}
				/>
			}
		</div>
	);

};

FacilitatorCompetitions.propTypes = {
	coFacilitatorData: PropTypes.object,
	pageData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	createCompetition: PropTypes.func.isRequired,
	deleteCompetition: PropTypes.func.isRequired,
	editCompetition: PropTypes.func.isRequired,
	findCompetitionWinner: PropTypes.func.isRequired,
	toggleModulePopup: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default FacilitatorCompetitions;
