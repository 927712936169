const facilitator = [
	'facilitator/background-header.jpg',
	'facilitator/icon-arrow-down.svg',
	'facilitator/icon-arrow-left.svg',
	'facilitator/icon-arrow-next.svg',
	'facilitator/icon-arrow-previous.svg',
	'facilitator/icon-arrow-up.svg',
	'facilitator/icon-calendar-blue.svg',
	'facilitator/icon-checkbox-checked.svg',
	'facilitator/icon-checkbox-unchecked.svg',
	'facilitator/icon-close.svg',
	'facilitator/icon-competitions-blue.svg',
	'facilitator/icon-competitions.svg',
	'facilitator/icon-eye.svg',
	'facilitator/icon-game-modules-blue.svg',
	'facilitator/icon-game-modules.svg',
	'facilitator/icon-help.svg',
	'facilitator/icon-help-2.svg',
	'facilitator/icon-insights-blue.svg',
	'facilitator/icon-insights.svg',
	'facilitator/icon-logout.svg',
	'facilitator/icon-megafon.svg',
	'facilitator/icon-messages-blue.svg',
	'facilitator/icon-messages.svg',
	'facilitator/icon-players-2-blue.svg',
	'facilitator/icon-players-2.svg',
	'facilitator/icon-players-blue.svg',
	'facilitator/icon-players.svg',
	'facilitator/icon-preview.svg',
	'facilitator/icon-resources-blue.svg',
	'facilitator/icon-resources.svg',
	'facilitator/icon-rocket.svg',
	'facilitator/icon-sort-asc.svg',
	'facilitator/icon-sort-desc.svg',
	'facilitator/icon-statistics-blue.svg',
	'facilitator/icon-statistics.svg',
	'facilitator/icon-view-game-stats.svg',
	'facilitator/logo-mdi.svg',
	'facilitator/star-empty-blue.svg',
	'facilitator/star-empty.svg',
	'facilitator/star-full.svg',
	'facilitator/text-admin.svg',
	'facilitator/text-co-facilitator.svg',
	'facilitator/text-facilitator.svg',
];

export {
	facilitator
};
