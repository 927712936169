const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		title: 'Happy Easter!',
		text: 'This is a special competition module!'
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'bakery-1-paper-doll-1',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Beard net',
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),	
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-white',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Cut resistant gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full',
				type: 'bodytype',
				isCorrect: true, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'oven-timer',
				isCorrect: true,
				title: 'Oven timer'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'rolling-pin',
				isCorrect: true,
				title: 'Rolling pin'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'ice-bucket',
				isCorrect: false,
				title: 'Ice bucket'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'flour',
				isCorrect: true,
				title: 'Bag of flour'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hammer',
				isCorrect: false,
				title: 'Hammer'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-white',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'White paper chef hat'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scotch-tape',
				isCorrect: false,
				title: 'Scotch tape'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'cloth',
				isCorrect: false,
				title: 'Cloth'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'chefs-hat',
					text: 'A Chef\'s hat has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'easter-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'easter-1-sort-temperature',
		image: 'thermometer',
		text: `Temperature should be monitored and logged during the day for maximum food safety. Match the right food or section with the right temperature.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'veryhot', text: '180F'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'hot', text: 'Above 140F'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: 'cold', text: '38-40F'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 1, text: 'Hot food', categoryIds: ['hot']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 2, text: 'Salad bar', categoryIds: ['cold']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 3, text: 'Rotisserie chicken end of cooking', categoryIds: ['veryhot']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 4, text: 'Food in case', categoryIds: ['cold']})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'easter-1-multiple-choice-product-never-be',
		text: `Where should product NEVER be?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'On the floor', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'In the backroom'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'In the freezer'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'Top shelf'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'Behind the register'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'easter-1-multiple-choice-plant-1',
		text: 'What type of plant is this?',
		image: 'plant1',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Succulent', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cactus',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Blooming',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Foliage',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hanging',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'easter-1-spot-errors-store',
		timeLimit: 15,
		layout: 'deli-1-store',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'water'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shelf1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shelf2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flies'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'easter-1-multiple-choice-shelves-1',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'center-store-1-shelves-1',
		text: 'Choose the shelves that have issues.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'easter-1-order-answer-phone',
		text: 'How should I answer the phone?',
		image: 'phone',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Thank you for calling The Suopi Markellon.', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'This is Manager Dakota speaking.', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'How can I assist you?', correctOrderNumbers: [3],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'easter-1-multiple-choice-corndog-where',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-meat-department',
		text: 'If you are looking for a corndog, where would you check?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. Most easy meal solutions that need to be microwaved or baked before consuming are here.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'easter-1-organize-restock-bread',
		layout: 'orientation-1-restock-bread',
		text: `Drag items to appropriate spots on the shelf. Some may need to be removed.`,
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'bread-blue-1', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'bread-red-1', alt: 'Bread', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'bread-red-2', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'bread-green-1', alt: 'Bread', correctContainerIds: ['a6', 'a7']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'bread-green-2', alt: 'Bread', correctContainerIds: ['a6', 'a7']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'bread-blue-2', alt: 'Bread', correctContainerIds: ['a3', 'a4']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'bread-green-3', alt: 'Bread', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 8, containerId: 'c8', image: 'bread-blue-3', alt: 'Bread', correctContainerIds: ['a3', 'a4']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'shelves',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 8}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'shelves'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a10', type: 'shelves'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'remove',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'remove', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'remove', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'remove', finalItemId: 7}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c8', type: 'initial'}),
				]
			})
		],
		doneConditions: [
			{type: 'empty-main-container', mainContainerId: 'initial'}
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'easter-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Well done, you've completed the Easter competition module!<br />
			Remember, if you want to try again and maybe earn more stars, you can do so from the store map.`
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'easter1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};