const moduleBakery2 = [
	'modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-1.svg',
	'modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-2.svg',
	'modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-3.svg',
	'modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-4.svg',
	'modules/tasks/multiple-choice/bakery-2-tables-with-cookies/option-1.svg',
	'modules/tasks/multiple-choice/bakery-2-tables-with-cookies/option-2.svg',
	'modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-1.svg',
	'modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-2.svg',
	'modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-3.svg',
	'modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-4.svg',
	'modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-5.svg',
	'modules/tasks/multiple-choice/bakery-2-when-set-displays/option-1.svg',
	'modules/tasks/multiple-choice/bakery-2-when-set-displays/option-2.svg',
	'modules/tasks/multiple-choice/bakery-2-when-set-displays/option-3.svg',
	'modules/tasks/multiple-choice/bakery-2-when-set-displays/option-4.svg',

	'modules/tasks/spot-errors/bakery-2-decorated-cake.svg',
	'modules/tasks/spot-errors/bakery-2-prevent-icing-shrink.svg',
	'modules/tasks/spot-errors/bakery-2-stacked-cookies.svg',
];

export {
	moduleBakery2
};
