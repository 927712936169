const moduleDeli2 = [	
	'modules/tasks/branching-story/deli-2-tailgate-trays/db-31.svg',

	'modules/tasks/clock-tasks/deli-2/background.svg',
	'modules/tasks/clock-tasks/deli-2/cl-01.svg',
	'modules/tasks/clock-tasks/deli-2/cl-02-solved.svg',
	'modules/tasks/clock-tasks/deli-2/cl-02.svg',
	'modules/tasks/clock-tasks/deli-2/cl-03.svg',
	'modules/tasks/clock-tasks/deli-2/cl-04.svg',
	'modules/tasks/clock-tasks/deli-2/cl-05.svg',
	
	'modules/tasks/image/deli-2-case-types.svg',

	'modules/tasks/multiple-choice/deli-2-clamshell-label/option-1.svg',
	'modules/tasks/multiple-choice/deli-2-clamshell-label/option-2.svg',
	'modules/tasks/multiple-choice/deli-2-clamshell-label/option-3.svg',
	'modules/tasks/multiple-choice/deli-2-clamshell-label/option-4.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/floor.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-1.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-2.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-3.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-4.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-5.svg',
	'modules/tasks/multiple-choice/deli-2-department-areas/option-6.svg',
	'modules/tasks/multiple-choice/deli-2-hot-bar-items/option-1.svg',
	'modules/tasks/multiple-choice/deli-2-hot-bar-items/option-2.svg',
	'modules/tasks/multiple-choice/deli-2-hot-bar-items/option-3.svg',
	'modules/tasks/multiple-choice/deli-2-hot-bar-items/option-4.svg',
	'modules/tasks/multiple-choice/deli-2-hot-bar-items/option-5.svg',

	'modules/tasks/organize/deli-2-sandwiches/background.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-1.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-2.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-3.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-4.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-5.svg',
	'modules/tasks/organize/deli-2-sandwiches/sandwich-6.svg',
	'modules/tasks/organize/deli-2-shelves/background.svg',
	'modules/tasks/organize/deli-2-shelves/can-beans.svg',
	'modules/tasks/organize/deli-2-shelves/can-corn.svg',
	'modules/tasks/organize/deli-2-shelves/can-corn-2.svg',
	'modules/tasks/organize/deli-2-shelves/can-mushrooms.svg',
	'modules/tasks/organize/deli-2-shelves/can-onions.svg',
	'modules/tasks/organize/deli-2-shelves/can-peas.svg',
	'modules/tasks/organize/deli-2-shelves/can-tomatos.svg',
	'modules/tasks/organize/deli-2-shelves/remove.svg',

	'modules/tasks/spot-errors/deli-2-hot-bar-issues.svg',
	'modules/tasks/spot-errors/deli-2-oven-issues.svg',
	'modules/tasks/spot-errors/deli-2-prep-table.svg',
	'modules/tasks/spot-errors/deli-2-salad-bar-issues.svg',
	'modules/tasks/spot-errors/deli-2-salad-bar-not-ready.svg',
	'modules/tasks/spot-errors/deli-2-service-case-issues.svg',
];

export {
	moduleDeli2
};
