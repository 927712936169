import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {badgesData} from 'data/badges-data';
import {gameUiTexts} from 'data/ui-texts';
import Manager from 'components/ui/manager/manager';
import Badge from '../badge/badge';
import Item from '../item/item';
import './feedback.scss';


const Feedback = ({managerId, feedbackData, onClick}) => {
	let feedbackText = (feedbackData.text ? feedbackData.text : '');
	let badgeData = null;
	let itemId = null;

	/* New badge / new badge tier */
	if ((feedbackData.type === 'new-badge' || feedbackData.type === 'new-badge-tier') && feedbackData.badgeId) {
		badgeData = badgesData.find((badge) => {return badge.id === feedbackData.badgeId;});
		if (badgeData) {
			if (feedbackData.type === 'new-badge') {
				feedbackText = (badgeData.textPopup ? badgeData.textPopup : '');
			}
			if (feedbackData.type === 'new-badge-tier') {
				feedbackText = (badgeData.newTierText 
					? badgeData.newTierText 
					: gameUiTexts.defaultNewBadgeTierText + ' ' + badgeData.title);
			}
		}	
	}

	/* New avatar item */
	if (feedbackData.type === 'avatar-item' && feedbackData.itemId) {
		itemId = feedbackData.itemId;
	}

	return (
		<div 
			className={'Feedback' 
				+ (feedbackData.type ? ' ' + feedbackData.type : '') 
				+ (feedbackData.type === 'manager' ? ' manager-' + managerId : '')
				+ (feedbackData.badgeId ? ' ' + feedbackData.badgeId : '')}
			onClick={onClick}
		>
			<div className="Feedback-content">
				{renderMarkdown(feedbackText ? feedbackText : '')}
				{feedbackData.type === 'manager' && 
					<div className="Feedback-manager">
						<Manager page="feedback" managerPose="manager-2" managerId={managerId} />
					</div>
				}
					
				{badgeData && 
					<div className="Feedback-badge">
						<Badge isUnlocked={feedbackData.type === 'new-badge'} page="feedback" badgeId={badgeData.id} />
					</div>
				}
				{itemId && 
					<div className="Feedback-item">
						<Item page="feedback" itemId={itemId} />
					</div>
				}
			</div>

		</div>
	);
};

Feedback.propTypes = {
	managerId: PropTypes.string.isRequired,
	feedbackData: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Feedback;
