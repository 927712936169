import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getPlayerModulesData, checkIfModuleIsCompleted} from 'helpers/module-helper';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import AdminGamePopup from './admin-game-popup/admin-game-popup';
import AdminAddFacilitatorPopup from './admin-add-facilitator-popup/admin-add-facilitator-popup';
import AdminRemoveFacilitatorPopup from './admin-remove-facilitator-popup/admin-remove-facilitator-popup';
import AdminAddGamePopup from './admin-add-game-popup/admin-add-game-popup';
import AdminDeleteGamePopup from './admin-delete-game-popup/admin-delete-game-popup';
import './admin-games.scss';

const AdminGames = (props) => {
	const {
		deviceInfo,
		gamesData, 
		usersData, 
		tagsData, 
		updateGameData, 
		updateTagsData, 
		addFacilitatorsToGame,
		removeFacilitatorFromGame,
		createGame,
		deleteGame
	} = props;

	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('storeName');
	const [sortDirection, setSortDirection] = useState('ASC');

	/* Sorted game players */
	const sortedGames = sortArrayByProperty(gamesData,  sortProperty, sortDirection);

	/* Select game (shows popup with info about game) */
	const [selectedGameId, setSelectedGameId] = useState(null);
	const handleSelectGame = (gameId = null) => {
		setSelectedGameId(gameId);
	};

	/* Add facilitator to game (popup) */
	const [showAddFacilitatorPopup, setShowAddFacilitatorPopup] = useState(false);
	const toggleShowAddFacilitatorPopup = (show = false) => {
		setShowAddFacilitatorPopup(show);
	};

	/* Remove facilitator from game (popup) */
	const [removeFacilitatorEmail, setRemoveFacilitatorEmail] = useState(null);
	const toggleRemoveFacilitatorPopup = (email = null) => {
		setRemoveFacilitatorEmail(email);
	};

	/* Add new game (popup) */
	const [showAddGamePopup, setShowAddGamePopup] = useState(false);
	const toggleShowAddGamePopup = (show = false) => {
		setShowAddGamePopup(show);
	};

	/* Delete game (popup) */
	const [showDeleteGamePopup, setShowDeleteGamePopup] = useState(null);
	const toggleShowDeleteGamePopup = (show = false) => {
		setShowDeleteGamePopup(show);
	};


	/**
	 * Sort players by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	return (
		<>
			<div className="AdminGames">

				{/* Button: Add new game */}
				<div className="AdminGames-addGameBtn">
					<Button 
						text={adminUiTexts.createNewGame} 
						classes={['addGame']} 
						onClick={() => {toggleShowAddGamePopup(true);}}
					/>
				</div>

				{/* List of games */}
				<div className="AdminGames-header">
					<div 
						className={'AdminGames-cell client' 
							+ (sortProperty === 'storeName' ? ' sortedBy ' + sortDirection : '')} 
						onClick={() => {handleSortByProperty('storeName');}}
					>
						<span>{adminUiTexts.client}</span>
					</div>
					<div 
						className={'AdminGames-cell created' 
							+ (sortProperty === 'created' ? ' sortedBy ' + sortDirection : '')} 
						onClick={() => {handleSortByProperty('created');}}
					>
						<span>{adminUiTexts.created}</span>
					</div>
					<div className="AdminGames-cell gameUrl"><span>{adminUiTexts.gameURL}</span></div>
					<div className="AdminGames-cell facilitators"><span>{adminUiTexts.facilitators}</span></div>
					<div className="AdminGames-cell activePlayers"><span>{adminUiTexts.activePlayers}</span></div>
					<div className="AdminGames-cell introCompleted"><span>{adminUiTexts.completedIntro}</span></div>
					<div className="AdminGames-cell avrCompletedModules">
						<span>{adminUiTexts.avrCompletedModules}</span>
					</div>
				</div>
				<div className="AdminGames-body">
					{sortedGames.map((game, index) => {
						const numberOfFacilitators = (game.facilitatorEmails ? game.facilitatorEmails.length : 0);
						const players = usersData.filter((user) => {
							return (
								user.role === 'player' && 
								user.games &&
								user.games.length > 0 &&
								user.games.some((g) => {return g.id === game.id && !g.isRemoved;})
							);
						});
						let totalNumberOfCompletedModules = 0;
						let numberOfPlayersThatCompletedIntro = 0;
						players.forEach((player) => {
							const playerGameData = player.games.find((g) => {return g.id === game.id;});
							const playerModulesData = getPlayerModulesData(playerGameData);
							playerModulesData.forEach((playerModuleData) => {
								const isCompleted = checkIfModuleIsCompleted(playerModuleData);
								if (isCompleted) {
									totalNumberOfCompletedModules += 1;
									if (playerModuleData.moduleId === 'intro') {
										numberOfPlayersThatCompletedIntro += 1;
									}
								}
							});
						});
						const avrNumberOfModulesCompleted = (players.length > 0 
							? totalNumberOfCompletedModules / players.length : 0);
						

						const created = (game && game.created ? dayjs(game.created).format('MM/DD/YY') : '-');
						return (
							<div 
								key={index} 
								className="AdminGames-row"
								onClick={() => {handleSelectGame(game.id);}}
							>
								<div className="AdminGames-cell client"><span>{game.storeName}</span></div>
								<div className="AdminGames-cell created"><span>{created}</span></div>
								<div className="AdminGames-cell gameUrl">
									<span>{appConfig.gameUrl + '/' + game.url}</span>
								</div>
								<div className="AdminGames-cell facilitators"><span>{numberOfFacilitators}</span></div>
								<div className="AdminGames-cell activePlayers"><span>{players.length}</span></div>
								<div className="AdminGames-cell introCompleted">
									<span>{numberOfPlayersThatCompletedIntro}</span>
								</div>
								<div className="AdminGames-cell avrCompletedModules">
									<span>{avrNumberOfModulesCompleted.toFixed(1)}</span>
								</div>
							</div>		
						);
					})}
				</div>
			</div>
			{selectedGameId && 
				<AdminGamePopup 
					deviceInfo={deviceInfo}
					gameId={selectedGameId} 
					gamesData={gamesData}
					usersData={usersData}
					tagsData={tagsData}
					handleSelectGame={handleSelectGame}
					updateGameData={updateGameData}
					updateTagsData={updateTagsData}					
					toggleShowAddFacilitatorPopup={toggleShowAddFacilitatorPopup}
					toggleRemoveFacilitatorPopup={toggleRemoveFacilitatorPopup}				
					toggleShowDeleteGamePopup={toggleShowDeleteGamePopup}	
				/>
			}
			{(selectedGameId && showAddFacilitatorPopup) && 
				<AdminAddFacilitatorPopup 
					gameId={selectedGameId} 
					togglePopup={toggleShowAddFacilitatorPopup}
					addFacilitatorsToGame={addFacilitatorsToGame}
				/>
			}
			{(selectedGameId && removeFacilitatorEmail) &&
				<AdminRemoveFacilitatorPopup 
					gameId={selectedGameId}
					email={removeFacilitatorEmail}
					usersData={usersData}
					toggleRemoveFacilitatorPopup={toggleRemoveFacilitatorPopup}
					removeFacilitatorFromGame={removeFacilitatorFromGame}
				/>
			}
			{showAddGamePopup && 
				<AdminAddGamePopup 
					deviceInfo={deviceInfo}
					gamesData={gamesData}
					createGame={createGame}
					togglePopup={toggleShowAddGamePopup}
				/>
			}
			{(selectedGameId && showDeleteGamePopup) && 
				<AdminDeleteGamePopup 
					gameId={selectedGameId}
					gamesData={gamesData}
					toggleShowDeleteGamePopup={toggleShowDeleteGamePopup}
					deleteGame={deleteGame}
					setSelectedGameId={setSelectedGameId}
				/>
			}
		</>
	);

};

AdminGames.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	updateGameData: PropTypes.func.isRequired,
	updateTagsData: PropTypes.func.isRequired,
	addFacilitatorsToGame: PropTypes.func.isRequired,
	removeFacilitatorFromGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
};

export default AdminGames;
