/* Template for player game data */
const playerGameDataTemplate = {
	id: null,
	avatar: null, 
	badges: [], 
	items: [], 
	areas: [],
	modules: [], 
	competitions: [],
	notifications: [],
	specialPoints: []
};

export {playerGameDataTemplate};
