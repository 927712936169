import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { facilitatorUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import ToggleSwitch from 'components/ui/toggle-switch/toggle-switch';
import './facilitator-edit-player-popup.scss';
import { areasData } from 'data/areas-data';

const FacilitatorEditPlayerPopup = ({ gameId, playerData, setEditPlayerId, updatePlayerAreaIds }) => {
	/* Get player name and prepare popup text */
	const playerName = (playerData ? playerData.name : '???');
	const popupText = JSON.parse(JSON.stringify(facilitatorUiTexts.editPlayerFromGamePopup.text))
		.replace(/%player%/g, playerName);

	/* Handle remove player */
	const [isSavingPlayerAreaIds, setIsSavingPlayerAreaIds] = useState(false);
	
	/* keep track of disabled areas for current player */
	const [disabledAreaIds, setDisabledAreaIds] = useState([]);

	/* load in the players disabled areas if the field exists */
	useEffect(() => {
		if (disabledAreaIds && playerData.disabledAreaIds) {
			setDisabledAreaIds(playerData.disabledAreaIds);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * when a toggle is switched added the currentDepartment or remove it if it already exists 
	 * @param {String} currentDepartment 
	 * @returns 
	 */
	const onChange = (currentDepartment) => {

		/* remove module if already in the state */
		if (disabledAreaIds.includes(currentDepartment)) {
			setDisabledAreaIds(disabledAreaIds.filter((item) => {return item !== currentDepartment;}));
			return;
		}
		/* added the module to the disableareaids array */
		setDisabledAreaIds([...disabledAreaIds, currentDepartment]);
	};
	/**
	 * The functions saves the players disabledAreaIds for the specific player
	 */
	const handleSavePlayerAreaIds = () => {
		setIsSavingPlayerAreaIds(true);
		updatePlayerAreaIds(gameId, playerData.id, disabledAreaIds).then(() => {
			/* Player updated, close popup */
			setEditPlayerId(null);
		}).catch((error) => {
			/* Error removing player */
			console.error(error);
			setIsSavingPlayerAreaIds(false);
		});
	};


	// filter area data to where only areas that can be disabled is chosen
	const canBeDisabledAreasData = areasData.filter((areaData) => {
		return areaData.canBeDisabled ? true : false;
	});

	return (
		<div className="FacilitatorEditPlayerPopup" onClick={() => {setEditPlayerId(null);}}>
			<div className="FacilitatorEditPlayerPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="FacilitatorEditPlayerPopup-header">
					<div className="FacilitatorEditPlayerPopup-closeBtn" onClick={() => {setEditPlayerId(null);}} />
					<div className="FacilitatorEditPlayerPopup-title">
						{facilitatorUiTexts.editPlayerFromGamePopup.title}
					</div>
				</div>
				<div className="FacilitatorEditPlayerPopup-body">
					<div className="FacilitatorEditPlayerPopup-text">
						<span>{renderMarkdown(popupText)}</span>
					</div>
					<div className='FacilitatorEditPlayerPopup-table'>
						<div className="FacilitatorEditPlayerPopup-tableheader">
							<div className={'FacilitatorEditPlayerPopup-cell module'}>
								<span>{facilitatorUiTexts.editPlayerFromGamePopup.departments}</span>
							</div>
							<div className={'FacilitatorEditPlayerPopup-cell access'}>
								<span>{facilitatorUiTexts.editPlayerFromGamePopup.access}</span>
							</div>
						</div>
						{canBeDisabledAreasData && canBeDisabledAreasData.map((item, index)=> {
							return (
								<div className="FacilitatorEditPlayerPopup-row"
									key={item.id}>
									<div className={'FacilitatorEditPlayerPopup-cell module'}>
										<span>{item.title}</span>
									</div>
									<div className={'FacilitatorEditPlayerPopup-cell toggle'}>
										<ToggleSwitch 
											id={item.id} 
											checked={!disabledAreaIds.includes(item.id)}
											onChange={onChange}/>
									</div>
								</div>
							);
						})}
						
					</div>
					<div className="FacilitatorEditPlayerPopup-buttons">
						<Button 
							isLoading={isSavingPlayerAreaIds}
							classes={['blue', 'adminPopup']}
							text={facilitatorUiTexts.editPlayerFromGamePopup.saveBtn}
							onClick={() => {handleSavePlayerAreaIds();}}
						/>
						<Button 
							classes={['blue', 'adminPopup']}
							text={facilitatorUiTexts.editPlayerFromGamePopup.cancelBtn}
							onClick={() => {setEditPlayerId(null);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorEditPlayerPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	setEditPlayerId: PropTypes.func.isRequired,
	updatePlayerAreaIds: PropTypes.func.isRequired,
};

export default FacilitatorEditPlayerPopup;
