import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import {spotErrorsPoints} from 'data/points-data';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import './spot-errors.scss';

const SpotErrors = (props) => {
	const {
		deviceInfo,
		areaId,
		clockTaskIsCompleted, 
		type, 
		playerTaskData, 
		taskData, 
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask,
		isFacilitator = false,
	} = props;

	/* Check if completed already */
	let isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);
	if (type === 'clock-task' && clockTaskIsCompleted) isCompleted = true;


	/* Track available and selected options and countdown */
	// const [status, setStatus] = useState('playing'); // Can be: playing, popup, result
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);
	const [numberOfMisses, setNumberOfMisses] = useState(0);
	const [timeLeft, setTimeLeft] = useState(taskData.timeLimit);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};


	/* Countdown */
	useEffect(() => {
		/* No time limit on this task OR task is completed */
		if (taskData.timeLimit === null || isCompleted) {
			return;
		}

		/* Countdown is done */
		if (!timeLeft) {
			completeTask();
			return;
		};
	
		/* Start countdown */
		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);
	
		return () => {return clearInterval(intervalId);};
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeLeft]);


	/* New task: update status, spotted errors, and countdown  */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
		setNumberOfMisses(0);
		setTimeLeft(taskData.timeLimit);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Facilitator cannot answer task */
		if (isFacilitator) return;

		/* Already completed */
		if (isCompleted === true) return;		

		/* Already spotted */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Update logged time */
		updateLoggedTime();

		/* Spot error */
		let newSpottedErrorIds = JSON.parse(JSON.stringify(spottedErrorIds));
		newSpottedErrorIds.push(errorId);
		setSpottedErrorIds(newSpottedErrorIds);

		/* Effects */
		let instantEffects = [{type: 'streak', isCorrectAnswer: true}];
		const spottedErrorData = taskData.errors.find((error) => {return error.id === errorId;});
		if (spottedErrorData && spottedErrorData.effects && spottedErrorData.effects.length > 0) {
			spottedErrorData.effects.forEach((effect) => {
				/* Effect: feedback (instant) */
				if (effect.type === 'feedback') {
					instantEffects.push(effect);
				}
			});
		}


		if (taskData.doneWhenAllErrorsSpotted && newSpottedErrorIds.length === taskData.errors.length) {
			/* Complete task automatically */
			completeTask(instantEffects);
		} else {
			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Facilitator cannot answer task */
		if (isFacilitator) return;

		/* Already completed */
		if (isCompleted === true) return;

		/* Update misses count */
		const newNumberOfMisses = numberOfMisses + 1;
		setNumberOfMisses(newNumberOfMisses);
		
		/* Update streak */
		const instantEffects = [{type: 'streak', isCorrectAnswer: false}];
		handleInstantTaskEffects(instantEffects);		
	};

	/**
	 * Complete task
	 */
	const completeTask = (instantEffects = null) => {
		/* Prepare array of effects */
		let effects = (instantEffects ? [...instantEffects] : []);
		
		/* Calculate points */
		let points = spottedErrorIds.length;

		const numberOfErrors = taskData.errors.length;
		if (numberOfErrors === 0) {
			points = spotErrorsPoints.noErrorsPoints;
		}

		/* Result popup */
		const spottedIssues = spottedErrorIds.length;
		if (taskData.showDefaultResultPopup) {
			let popupTitle = null;
			let popupText = null;

			/* Only 1 error to spot */
			if (numberOfErrors === 1) {
				if (spottedErrorIds.length === 1) {
					/* Spotted */
					popupTitle = gameUiTexts.spotErrorsPopup.result3.title;
					popupText = gameUiTexts.spotErrorsPopup.result3.text2;
					if (
						taskData.resultOverwrites && 
						taskData.resultOverwrites.result3 && 
						taskData.resultOverwrites.result3.text2
					) {
						popupText = taskData.resultOverwrites.result3.text2;
					}
				} else {
					/* Not spotted */
					popupTitle = gameUiTexts.spotErrorsPopup.result1.title;
					popupText = gameUiTexts.spotErrorsPopup.result1.text2;
					if (
						taskData.resultOverwrites && 
						taskData.resultOverwrites.result1 && 
						taskData.resultOverwrites.result1.text2
					) {
						popupText = taskData.resultOverwrites.result1.text2;
					}
				}
			} else {
				/* More than 1 error to spot */
				let resultTier = 1;
				if (spottedIssues / numberOfErrors > 0.333) resultTier = 2;
				if (spottedIssues / numberOfErrors > 0.667) resultTier = 3;
				popupTitle = gameUiTexts.spotErrorsPopup['result' + resultTier].title;
				popupText = gameUiTexts.spotErrorsPopup['result' + resultTier].text;
				if (taskData.resultOverwrites && taskData.resultOverwrites.hasOwnProperty('result' + resultTier)) {
					popupText = taskData.resultOverwrites['result' + resultTier].text;
				}
			}
			popupText = popupText.replace('%spotted%', spottedIssues).replace('%total%', numberOfErrors);
			effects.push({
				type: 'popup',
				popup: {type: 'spot-errors-result', title: popupTitle, text: popupText}
			});	
		}

		/* Missed issues */
		const missedIssues = (numberOfErrors - spottedIssues);

		/* Effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, missedIssues);
				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						effects.push(effect);
					}
				}
			});
		}

		// Detract errors, if less than 0, give 0
		points = Math.max(points - numberOfMisses, 0);
	
		/* Save completed task */
		handleCompleteTask(
			'spot-errors', 
			points, 
			missedIssues, 
			effects,
			{spottedErrorIds: spottedErrorIds}
		);
	};

	return (
		<div className={'SpotErrors ' + areaId 
			+ (isCompleted ?  ' completed' : '')
			+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}
		>
			<div className={'SpotErrors-errors ' + taskData.layout} onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ (spottedErrorIds.indexOf(error.id) >= 0 ? ' spotted' : '')}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>

			{taskData.text && <div className="SpotErrors-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
				/>
			</div>}
			
			{(!isCompleted && !isFacilitator && !taskData.timeLimit && !taskData.doneWhenAllErrorsSpotted) && 
				<div onClick={(e) => {e.stopPropagation(); completeTask();}} 
					className={'SpotErrors-confirmBtn ' + taskData.taskId}>
					<span>{gameUiTexts.done}</span></div>}
		</div>
	);
};

SpotErrors.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	areaId: PropTypes.string.isRequired,
	clockTaskIsCompleted: PropTypes.bool,
	type: PropTypes.string,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default SpotErrors;
