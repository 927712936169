import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import ToggleSwitch from 'components/ui/toggle-switch/toggle-switch';
import './facilitator-edit-co-facilitator-popup.scss';

const FacilitatorEditCoFacilitatorPopup = (props) => {
	/* Props */
	const {
		canEdit, 
		gameId, 
		coFacilitatorData, 
		togglePopup, 
		toggleShowRemoveCoFacilitatorPopup, 
		updateCoFacilitator
	} = props;

	/* Track if currently editing / saving changes */
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false); 

	/* Get changable data */
	const [canSendMessages, setCanSendMessage] = 
		useState(coFacilitatorData && coFacilitatorData.canSendMessages ? true : false);
	const [canToggleDepartmentAccess, setCanTogglePlayerDepartmentAccess] =
		useState(coFacilitatorData && coFacilitatorData.canToggleDepartmentAccess ? true : false);
	const [canChangeManager, setCanChangeManager] =
		useState(coFacilitatorData && coFacilitatorData.canChangeManager ? true : false);

	/* Toggle setting */
	const toggleSetting = (settingId) => {
		if (settingId === 'messages') {
			setCanSendMessage(!canSendMessages);
		}
		if (settingId === 'departments') {
			setCanTogglePlayerDepartmentAccess(!canToggleDepartmentAccess);
		}
		if (settingId === 'manager') {
			setCanChangeManager(!canChangeManager);
		}
		setIsEditing(true);
	};

	/* Save changes */
	const handleSaveChanges = () => {
		setIsSaving(true);
		updateCoFacilitator(
			gameId, 
			coFacilitatorData.email, 
			{canSendMessages, canToggleDepartmentAccess, canChangeManager}
		).then((response) => {
			if (response.status === 'success') {
				togglePopup();
			} else {
				setIsSaving(false);
				console.error(response.feedback);
			}
		});
	};

	return (
		<div className="FacilitatorEditCoFacilitatorPopup" onClick={() => {togglePopup(null);}}>
			<div className="FacilitatorEditCoFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Header */}
				<div className="FacilitatorEditCoFacilitatorPopup-header">
					<div className="FacilitatorEditCoFacilitatorPopup-closeBtn" onClick={() => {togglePopup(null);}} />
					<div className="FacilitatorEditCoFacilitatorPopup-title">
						{canEdit 
							? facilitatorUiTexts.editCoFacilitatorPopup.title 
							: facilitatorUiTexts.editCoFacilitatorPopup.title2}
					</div>
				</div>
				{/* Body */}
				<div className="FacilitatorEditCoFacilitatorPopup-body">
					{/* Co-facilitator info */}
					<div className="FacilitatorEditCoFacilitatorPopup-info name">
						<span>{facilitatorUiTexts.editCoFacilitatorPopup.name}:</span>
						<span>{coFacilitatorData && coFacilitatorData.name}</span>
					</div>
					<div className="FacilitatorEditCoFacilitatorPopup-info email">
						<span>{facilitatorUiTexts.editCoFacilitatorPopup.email}:</span>
						<span>{coFacilitatorData && coFacilitatorData.email}</span>
					</div>
					<div className="FacilitatorEditCoFacilitatorPopup-info addedBy">
						<span>{facilitatorUiTexts.editCoFacilitatorPopup.addedBy}:</span>
						<span>{coFacilitatorData && coFacilitatorData.addByEmail}</span>
					</div>

					{/* Remove co-facilitator btn */}
					{canEdit && <div 
						className="FacilitatorEditCoFacilitatorPopup-removeBtn"
						onClick={() => {toggleShowRemoveCoFacilitatorPopup(true);}}
					>
						<span>{facilitatorUiTexts.editCoFacilitatorPopup.remove}</span>
					</div>}

					{/* Permissions */}
					<div className="FacilitatorEditCoFacilitatorPopup-permissions">
						<div className="FacilitatorEditCoFacilitatorPopup-title">
							<span>{facilitatorUiTexts.editCoFacilitatorPopup.permissions}</span>
						</div>
						<div className="FacilitatorEditCoFacilitatorPopup-setting">
							<div className="FacilitatorEditPlayerPopup-settingToggle">
								<ToggleSwitch 
									id="messages" 
									checked={canSendMessages}
									isDisabled={!canEdit}
									onChange={toggleSetting} 
								/>
							</div>
							<div className="FacilitatorEditPlayerPopup-settingLabel">
								<span>{facilitatorUiTexts.editCoFacilitatorPopup.sendMessages}</span>
							</div>
						</div>
						<div className="FacilitatorEditCoFacilitatorPopup-setting">
							<div className="FacilitatorEditPlayerPopup-settingToggle">
								<ToggleSwitch 
									id="departments" 
									checked={canToggleDepartmentAccess}
									isDisabled={!canEdit}
									onChange={toggleSetting} 
								/>
							</div>
							<div className="FacilitatorEditPlayerPopup-settingLabel">
								<span>{facilitatorUiTexts.editCoFacilitatorPopup.toggleDepartmentAccess}</span>
							</div>
						</div>
						<div className="FacilitatorEditCoFacilitatorPopup-setting">
							<div className="FacilitatorEditPlayerPopup-settingToggle">
								<ToggleSwitch 
									id="manager" 
									checked={canChangeManager}
									isDisabled={!canEdit}
									onChange={toggleSetting} 
								/>
							</div>
							<div className="FacilitatorEditPlayerPopup-settingLabel">
								<span>{facilitatorUiTexts.editCoFacilitatorPopup.changeManager}</span>
							</div>
						</div>
					</div>
					
					{/* Save & cancel buttons */}
					{canEdit && <div className="FacilitatorEditCoFacilitatorPopup-buttons">
						<Button 
							isDisabled={!isEditing}
							isLoading={isSaving}
							classes={['blue', 'addGame']}
							text={facilitatorUiTexts.editCoFacilitatorPopup.saveBtn}
							onClick={handleSaveChanges}
						/>
						<Button 
							classes={['blue', 'addGame']}
							text={facilitatorUiTexts.editCoFacilitatorPopup.cancelBtn}
							onClick={() => {togglePopup();}}
						/>
					</div>}
				</div>
			</div>
		</div>
	);
};

FacilitatorEditCoFacilitatorPopup.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	gameId: PropTypes.string.isRequired,
	coFacilitatorData: PropTypes.object.isRequired,
	togglePopup: PropTypes.func.isRequired,
	toggleShowRemoveCoFacilitatorPopup: PropTypes.func.isRequired,
	updateCoFacilitator: PropTypes.func.isRequired,
};

export default FacilitatorEditCoFacilitatorPopup;
