import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {orderPoints} from 'data/points-data';
import {shuffleArray, sortArrayByProperty} from 'helpers/array-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import OrderDndItems from './order-dnd-items';
import Popup from 'components/ui/popup/popup';
import './order.scss';

const Order = (props) => {
	const {
		areaId,
		deviceInfo, 
		playerTaskData, 
		taskData,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask,
		isFacilitator = false
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track round number and ordered item ids (current and prev round) */
	const [roundNumber, setRoundNumber] = useState(1);
	const [orderedItems, setOrderedItems] = useState([]);

	/* Popup with number of correct positions */
	const [popupData, setPopupData] = useState(null);

	/**
	 * Adjust height of options container with respect to task intro
	 */
	const [optionsStyle, setOptionsStyle] = useState(null);
	const getOptionsStyle = () => {
		let style = null;
		const taskIntroElement = document.getElementById('taskIntro');
		if (taskIntroElement) {
			const offsetTop = taskIntroElement.clientHeight + taskIntroElement.offsetTop;
			style = {position: 'absolute', top: offsetTop + 'px'};
		}
		return style;
	};

	/* Get items to be ordered */
	const getOrderedItems = () => {
		/* Get items from player data or data file */
		let items = shuffleArray(taskData.items).map((item, index) => {
			return {id: item.id, isLocked: false, locationIndex: index, lastMoved: null};
		});
		if (
			playerTaskData && 
			playerTaskData.orderedItems && 
			playerTaskData.orderedItems.length === taskData.items.length
		) {
			items = playerTaskData.orderedItems;
		}

		/* Special case: if facilitator and completed, show correct order */
		if (isFacilitator && isCompleted) {
			items = taskData.items.map((item, index) => {return {id: item.id, isLocked: false, locationIndex: index};});
		}
		return items;
	};

	

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItems());
		setRoundNumber(1);
		setOptionsStyle(getOptionsStyle());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Update options style if device dimensions change */
	useEffect(() => {
		setOptionsStyle(getOptionsStyle());
	}, [deviceInfo]);

	/**
	 * Move items
	 * Hovered item id: id of item being hovered over
	 * Dragged item id: id of item being dragged by user
	 * @param {string} hoveredItemId
	 * @param {string} draggedItemId 
	 */
	const handleMoveItems = (hoveredItemId, draggedItemId) => {	
		/* Prepare array of new ordered items */
		const newOrderedItems = JSON.parse(JSON.stringify(orderedItems));

		/* Get location index of item being dragged */
		const draggedItemLocationIndex = newOrderedItems.find((i) => {return i.id === draggedItemId;}).locationIndex;

		/* Get location index of item being hovered over */
		const hoveredItemIndex = newOrderedItems.find((i) => {return i.id === hoveredItemId;}).locationIndex;

		/* Get items that are affected (all unlocked items between dragged and hovered items), sort by position */
		const affectedItems = JSON.parse(JSON.stringify(sortArrayByProperty(newOrderedItems.filter((item) => {
			const maxLocationIndex = Math.max(draggedItemLocationIndex, hoveredItemIndex);
			const minLocationIndex = Math.min(draggedItemLocationIndex, hoveredItemIndex);
			return (
				!item.isLocked &&
				item.locationIndex >= minLocationIndex && 
				item.locationIndex <= maxLocationIndex
			);
		}), 'locationIndex', 'ASC')));

		if (draggedItemLocationIndex < hoveredItemIndex) {
			/* Move dragged item down, other items move up */
			affectedItems.forEach((item, index) => {
				const itemIndex = newOrderedItems.findIndex((i) => {return i.id === item.id;});
				if (index === 0) {
					/* Top affected item, this is the one being dragged, move to bottom (i.e. hoveredItemIndex) */
					newOrderedItems[itemIndex].locationIndex = hoveredItemIndex;
				} else {
					/* Other affected items (includes item being hovered over), move 1 place up */
					const newLocationIndex = affectedItems[index - 1].locationIndex;
					newOrderedItems[itemIndex].locationIndex = newLocationIndex;
					newOrderedItems[itemIndex].lastMoved = Date.now(); 
				}
			});
		} else {
			/* Move dragged item up, other items move down */
			affectedItems.forEach((item, index) => {
				const itemIndex = newOrderedItems.findIndex((i) => {return i.id === item.id;});
				if (index === affectedItems.length - 1) {
					/* Bottom item, this is the one being dragged, move to top (i.e. hoveredItemIndex) */
					newOrderedItems[itemIndex].locationIndex = hoveredItemIndex;
				} else {
					/* Affected items (includes item being hovered over), move 1 place up */
					const newLocationIndex = affectedItems[index + 1].locationIndex;
					newOrderedItems[itemIndex].locationIndex = newLocationIndex;
					newOrderedItems[itemIndex].lastMoved = Date.now(); 
				}
			});
		}
		setOrderedItems(newOrderedItems);
	};


	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Update logged time */
		updateLoggedTime();

		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item) => {
			const itemData = taskData.items.find((i) => {return i.id === item.id;});
			if (
				itemData && 
				itemData.correctOrderNumbers && 
				itemData.correctOrderNumbers.indexOf(item.locationIndex + 1) < 0
			) {
				errors += 1;
			}
		});

		if (errors > 0) {
			/* Update streak */
			const instantEffects = [{type: 'streak', isCorrectAnswer: false}];
			handleInstantTaskEffects(instantEffects);

			/* Show popup */
			const popupTextNumber = (roundNumber < 3 ? roundNumber : 3);
			const popupText = gameUiTexts.orderPopup['text' + popupTextNumber];
			const popupTitle = gameUiTexts.orderPopup.title
				.replace('%correctAnswers%', (orderedItems.length - errors))
				.replace('%totalAnswers%', orderedItems.length);
			setPopupData({
				title: popupTitle,
				text: popupText
			});
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Close popup
	 */
	const handleClosePopup = () => {
		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Lock correctly placed items if round >= 3 */
		if (newRoundNumber >= 3) {
			let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
			newOrderedItems.forEach((item) => {
				const itemData = taskData.items.find((i) => {return i.id === item.id;});
				if (
					item.isLocked ||
					(
						itemData && 
						itemData.correctOrderNumbers && 
						itemData.correctOrderNumbers.indexOf(item.locationIndex + 1) >= 0
					)
				) {
					item.isLocked = true;
				}
			});
			setOrderedItems(newOrderedItems);
		}

		/* Close popup */
		setPopupData(null);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);
		
		/* Calculate points */
		let points = orderPoints.minPoints;
		let pointIndex = orderPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		/* Save completed task */
		handleCompleteTask(
			'order', 
			points, 
			errors, 
			[{type: 'streak', isCorrectAnswer: true}],
			{orderedItems: orderedItems}
		);
	};



	return (
		<div className={'Order ' + areaId + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			<div id="taskIntro" className="Order-intro">
				<TaskIntro 
					text={taskData.text}
					image={taskData.image}
				/>
			</div>
			<div id="orderItems" className="Order-items" style={optionsStyle}>
				<OrderDndItems 
					isCompleted={isCompleted}
					taskData={taskData}
					orderedItems={orderedItems}
					handleMoveItems={handleMoveItems}
				/>
			</div>

			{/* Done button */}
			{!isCompleted && !isFacilitator && <div className="Order-doneBtn">
				<Button
					classes={['blue', 'done']}
					text={gameUiTexts.done}
					onClick={confirmOrder}
				/>
			</div>}

			{popupData && <Popup 
				type="order-result"
				title={popupData.title}
				text={popupData.text}
				togglePopup={handleClosePopup}
			/>}
		</div>
	);
};

Order.propTypes = {
	areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default Order;
