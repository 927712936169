import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage' ;
import FacilitatorAddCoFacilitatorPopup 
	from './facilitator-add-co-facilitator-popup/facilitator-add-co-facilitator-popup';
import FacilitatorEditCoFacilitatorPopup 
	from './facilitator-edit-co-facilitator-popup/facilitator-edit-co-facilitator-popup';
import FacilitatorRemoveCoFacilitatorPopup 
	from './facilitator-remove-co-facilitator-popup/facilitator-remove-co-facilitator-popup';
import Button from 'components/ui/button/button';
import './facilitator-facilitators.scss';

const FacilitatorFacilitators = (props) => {
	const {
		coFacilitatorData,
		pageData, 
		gameData, 
		usersData, 
		handleGoToPage, 
		addCoFacilitatorsToGame,
		updateCoFacilitator,
		removeCoFacilitatorFromGame
	} = props;

	/* Default facilitator sorting property and direction */
	const [sortFacilitatorsByProperty, setSortFacilitatorsByProperty] = useState('name');
	const [sortFacilitatorsInDirection, setSortFacilitatorsInDirection] = useState('ASC');

	/* Get game facilitators */
	let gameFacilitators = [];
	gameData.facilitatorEmails.forEach((email) => {
		const facilitatorData = usersData.find((user) => {return user.email === email;});
		if (facilitatorData) {
			gameFacilitators.push(facilitatorData);
		} else {
			coFacilitators.push({
				name: null,
				email: email,
				lastLogin: null
			});
		}
	});
	gameFacilitators = sortArrayByProperty(gameFacilitators, sortFacilitatorsByProperty, sortFacilitatorsInDirection);

	/* Default co-facilitator sorting property and direction */
	const [sortCoFacilitatorsByProperty, setSortCoFacilitatorsByProperty] = useState('name');
	const [sortCoFacilitatorsInDirection, setSortCoFacilitatorsInDirection] = useState('ASC');

	/* Get game co-facilitators */
	let coFacilitators = [];
	if (gameData.coFacilitators && gameData.coFacilitators.length > 0) {
		gameData.coFacilitators.forEach((c) => {
			const userData = usersData.find((user) => {return user.email === c.email;});
			const coFacilitatorData = JSON.parse(JSON.stringify(c));
			if (userData) {
				coFacilitatorData.name = userData.name;
				coFacilitatorData.lastLogin = userData.lastLogin;
			}
			coFacilitators.push(coFacilitatorData);
		});
	}
	coFacilitators = sortArrayByProperty(coFacilitators, sortCoFacilitatorsByProperty, sortCoFacilitatorsInDirection);

	/**
	 * Sort (co) facilitators by property
	 * @param {type} role
	 * @param {string} property 
	 */
	const handleSortByProperty = (role, property) => {
		if (role === 'facilitator') {
			if (property === sortFacilitatorsByProperty) {
				sortFacilitatorsInDirection === 'ASC' 
					? setSortFacilitatorsInDirection('DESC') 
					: setSortFacilitatorsInDirection('ASC');
			} else {
				setSortFacilitatorsByProperty(property);
				setSortFacilitatorsInDirection('ASC');
			}
		}
		if (role === 'co-facilitator') {
			if (property === sortArrayByProperty) {
				sortFacilitatorsInDirection === 'ASC' 
					? setSortCoFacilitatorsInDirection('DESC') 
					: setSortCoFacilitatorsInDirection('ASC');
			} else {
				setSortCoFacilitatorsByProperty(property);
				setSortCoFacilitatorsInDirection('ASC');
			}
		}
	};

	/* Add co-facilitator to game (popup) */
	const [showAddCoFacilitatorPopup, setShowAddCoFacilitatorPopup] = useState(false);
	const toggleShowAddCoFacilitatorPopup = (show = false) => {
		setShowAddCoFacilitatorPopup(show);
	};

	/* Edit co-facilitator (popup) */
	const [editCoFacilitatorData, setEditCoFacilitatorData] = useState(null);
	const toggleShowEditCoFacilitatorPopup = (data = null) => {
		setEditCoFacilitatorData(data);
	};

	/* Remove co-facilitator (popup) */
	const [showRemoveCoFacilitatorPopup, setShowRemoveCoFacilitatorPopup] = useState(false);
	const toggleShowRemoveCoFacilitatorPopup = (show = false) => {
		setShowRemoveCoFacilitatorPopup(show);
	};

	return (
		<div className="FacilitatorFacilitators">
			{/* Subpage navigation */}
			<FacilitatorHeaderSubpage 
				page={pageData.id} 
				subpage="facilitators"
				subpageIds={pageData.subpageIds}
				handleGoToPage={handleGoToPage}
			/>

			{/* List of facilitators */}
			<div className="FacilitatorFacilitators-wrap">
				<div className="FacilitatorFacilitators-list facilitators">
					<div className="FacilitatorFacilitators-title">{facilitatorUiTexts.facilitators.facilitators}</div>
					<div className="FacilitatorFacilitators-header">
						<div 
							className={'FacilitatorFacilitators-cell name' 
								+ (sortFacilitatorsByProperty === 'name' ? ' sortedBy ' 
								+ sortFacilitatorsInDirection : '')} 
							onClick={() => {handleSortByProperty('facilitator', 'name');}}
						>
							<span>{facilitatorUiTexts.facilitators.name}</span>
						</div>
						<div 
							className={'FacilitatorFacilitators-cell email' 
								+ (sortFacilitatorsByProperty === 'email' ? ' sortedBy ' 
								+ sortFacilitatorsInDirection : '')} 
							onClick={() => {handleSortByProperty('facilitator', 'email');}}
						>
							<span>{facilitatorUiTexts.facilitators.email}</span>
						</div>	
						<div className="FacilitatorFacilitators-cell login">
							<span>{facilitatorUiTexts.facilitators.lastLogin}</span>
						</div>
					</div>
					<div className="FacilitatorFacilitators-body">
						{gameFacilitators.map((user, index) => {
							const lastLogin = (user.lastLogin ? dayjs(user.lastLogin).format('MM/DD-YY') : '-');
							return (
								<div key={index} className="FacilitatorFacilitators-row">
									<div className="FacilitatorFacilitators-cell name">
										<span>{user.name ? user.name : '-'}</span>
									</div>
									<div className="FacilitatorFacilitators-cell email"><span>{user.email}</span></div>
									<div className="FacilitatorFacilitators-cell login"><span>{lastLogin}</span></div>
								</div>		
							);
						})}
					</div>
				</div>


				{/* List of co-facilitators */}
				<div className="FacilitatorFacilitators-list coFacilitators">
					<div className="FacilitatorFacilitators-title">
						{facilitatorUiTexts.facilitators.coFacilitators}
					</div>
					<div className="FacilitatorFacilitators-coFacilitatorTooltip">
						<div className="FacilitatorFacilitators-coFacilitatorTooltipIcon" />
						<div className="FacilitatorFacilitators-coFacilitatorTooltipText">
							{renderMarkdown(facilitatorUiTexts.facilitators.coFacilitatorTooltip)}
						</div>
					</div> 
					{/* Add co-facilitator btn */}
					{!coFacilitatorData && <div className="FacilitatorFacilitators-addCoFacilitatorBtn">
						<Button 
							text={facilitatorUiTexts.facilitators.addNewCoFacilitator + ' +'} 
							classes={['addGame']} 
							onClick={() => {toggleShowAddCoFacilitatorPopup(true);}}
						/>
					</div>}
					<div className="FacilitatorFacilitators-header">
						<div 
							className={'FacilitatorFacilitators-cell name' 
								+ (sortFacilitatorsByProperty === 'name' ? ' sortedBy ' 
								+ sortFacilitatorsInDirection : '')} 
							onClick={() => {handleSortByProperty('co-facilitator', 'name');}}
						>
							<span>{facilitatorUiTexts.facilitators.name}</span>
						</div>
						<div 
							className={'FacilitatorFacilitators-cell email' 
								+ (sortFacilitatorsByProperty === 'email' ? ' sortedBy ' 
								+ sortFacilitatorsInDirection : '')} 
							onClick={() => {handleSortByProperty('co-facilitator', 'email');}}
						>
							<span>{facilitatorUiTexts.facilitators.email}</span>
						</div>	
						<div className="FacilitatorFacilitators-cell login">
							<span>{facilitatorUiTexts.facilitators.lastLogin}</span>
						</div>
					</div>
					<div className="FacilitatorFacilitators-body">
						{coFacilitators.map((user, index) => {
							const lastLogin = (user.lastLogin ? dayjs(user.lastLogin).format('MM/DD-YY') : '-');
							return (
								<div 
									key={index} 
									className="FacilitatorFacilitators-row"
									onClick={() => {toggleShowEditCoFacilitatorPopup(user);}}
								>
									<div className="FacilitatorFacilitators-cell name">
										<span>{user.name ? user.name : '-'}</span>
									</div>
									<div className="FacilitatorFacilitators-cell email"><span>{user.email}</span></div>
									<div className="FacilitatorFacilitators-cell login"><span>{lastLogin}</span></div>
								</div>		
							);
						})}
					</div>
				</div>
			</div>

			{/* Popup: add co-facilitator */}
			{showAddCoFacilitatorPopup && 
				<FacilitatorAddCoFacilitatorPopup 
					gameId={gameData.id} 
					togglePopup={toggleShowAddCoFacilitatorPopup}
					addCoFacilitatorsToGame={addCoFacilitatorsToGame}
				/>
			}

			{/* Popup: edit co-facilitator */}
			{editCoFacilitatorData &&
				<FacilitatorEditCoFacilitatorPopup 
					canEdit={coFacilitatorData ? false : true}
					gameId={gameData.id}
					coFacilitatorData = {editCoFacilitatorData}
					togglePopup={toggleShowEditCoFacilitatorPopup}
					toggleShowRemoveCoFacilitatorPopup={toggleShowRemoveCoFacilitatorPopup}
					updateCoFacilitator={updateCoFacilitator}
				/>
			}

			{/* Popup: remove co-facilitator from game */}
			{(editCoFacilitatorData && showRemoveCoFacilitatorPopup) &&
				<FacilitatorRemoveCoFacilitatorPopup 
					gameId={gameData.id}
					coFacilitatorData = {editCoFacilitatorData}
					togglePopup={toggleShowRemoveCoFacilitatorPopup}
					toggleShowEditCoFacilitatorPopup={toggleShowEditCoFacilitatorPopup}
					removeCoFacilitatorFromGame={removeCoFacilitatorFromGame}
				/>
			}
		</div>
	);
};

FacilitatorFacilitators.propTypes = {
	coFacilitatorData: PropTypes.object,
	pageData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	addCoFacilitatorsToGame: PropTypes.func.isRequired,
	updateCoFacilitator: PropTypes.func.isRequired,
	removeCoFacilitatorFromGame: PropTypes.func.isRequired,
};

export default FacilitatorFacilitators;
