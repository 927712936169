const moduleCenterStore1 = [
	'modules/tasks/clock-tasks/center-store-1/ec-01.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-02.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-03-solved.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-03.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-04-solved.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-04.svg',
	'modules/tasks/clock-tasks/center-store-1/ec-05.svg',

	'modules/tasks/image/center-store-1-endcap.svg',
	'modules/tasks/image/center-store-1-shelf.svg',
	'modules/tasks/image/center-store-1-shelf-juice.svg',

	'modules/tasks/multiple-choice/center-store-1-fill-shelf/background.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-fill-shelf/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-attention-areas/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-baby-food/background.svg',
	'modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-fall/option-9.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-9.svg',
	'modules/tasks/multiple-choice/center-store-1-grilling/option-10.svg',
	'modules/tasks/multiple-choice/center-store-1-missing-tags/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-missing-tags/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-missing-tags/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-missing-tags/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-prep-deadline/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-prep-deadline/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-prep-deadline/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-prep-deadline/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-ready-shelves/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-shelves-1/background.svg',
	'modules/tasks/multiple-choice/center-store-1-shelves-2/background.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-1/background.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-2/background.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-3/background.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-9.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-1.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-2.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-3.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-4.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-5.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-6.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-7.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-8.svg',
	'modules/tasks/multiple-choice/center-store-1-tailgating/option-9.svg',

	'modules/tasks/spot-errors/center-store-1-backroom.svg',
	'modules/tasks/spot-errors/center-store-1-endcap.svg',
	'modules/tasks/spot-errors/center-store-1-restock.svg',
	'modules/tasks/spot-errors/center-store-1-rice-shelves.svg',
];

export {
	moduleCenterStore1
};
