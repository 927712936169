import React from 'react';
import {marked} from 'marked';


/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
export function renderMarkdown(markdown) {
	if (markdown && typeof markdown === 'string' && markdown.length > 0) {
		let lexed = marked.lexer(markdown);
		let parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
}

/**
 * Capitalize first letter of string
 * @param {string} str
 */
export function capitalizeFirstLetterOfString(str) {
	const newStr = str.charAt(0).toUpperCase() + str.slice(1);
	return newStr;
}