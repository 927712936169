import React from 'react';
import PropTypes from 'prop-types';
import FacilitatorGameModules from 'components/facilitator/facilitator-game-modules/facilitator-game-modules';
import './admin-game-popup-content.scss';

const AdminGamePopupContent = ({deviceInfo}) => {
	return (
		<div className="AdminGamePopupContent">
			<FacilitatorGameModules deviceInfo={deviceInfo} />
		</div>
	);
};

AdminGamePopupContent.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
};

export default AdminGamePopupContent;
