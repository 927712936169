import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderMarkdown } from 'helpers/text-helper';
import { checkIfAllReportsAreSeen } from 'helpers/reports-helper';
import { facilitatorUiTexts } from 'data/ui-texts';
import { yearbookAllQuestions } from 'data/yearbook-data';
import { sortArrayByProperty } from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import './facilitator-player-reports-popup.scss';


const FacilitatorPlayerReportsPopup = (props) => {
	const { 
		reportPlayerId,
		setReportPlayerId,
		gamePlayers,
		markReportAsSeen,
		deleteReport,
		userId,
		coFacilitatorData
	} = props;

	const playerData = gamePlayers.find((p) => {return p.id === reportPlayerId;});
	/* Get player name and prepare popup text */
	const playerName = (playerData ? playerData.name : '???');
	const popupTitle = JSON.parse(JSON.stringify(facilitatorUiTexts.viewReportPlayerFromGamePopup.title))
		.replace(/%player%/g, playerName);

	// Sort reports by date reported (newest first)
	const sortedReports = sortArrayByProperty(playerData.yearbookReports, 'reportTime', 'DESC');


	const [isLoading, setIsLoading] = useState(false);

	const [deleteReportPopupData, setDeleteReportPopupData] = useState(null);

	/**
	 * calls delete which current report
	 * @param {Object} report 
	 */
	const handleDeleteReport = (report) => {
		setIsLoading(true);
		deleteReport(report, playerData.id).then(() => {
			setDeleteReportPopupData(null);
			setIsLoading(false);
		});
	};

	return (
		<div className="FacilitatorPlayerReportsPopup" onClick={() => {setReportPlayerId(null);}}>
			<div className="FacilitatorPlayerReportsPopup-content" onClick={(e) => {e.stopPropagation();}}>

				<div className="FacilitatorPlayerReportsPopup-header">
					<div className="FacilitatorPlayerReportsPopup-closeBtn" 
						onClick={() => {setReportPlayerId(null);}} />
					<div className="FacilitatorPlayerReportsPopup-title">
						{renderMarkdown(popupTitle)}
					</div>
				</div>

				<div className="FacilitatorPlayerReportsPopup-body">
					<div className="FacilitatorPlayerReportsPopup-text">
						<span>{facilitatorUiTexts.viewReportPlayerFromGamePopup.text}</span>
					</div>
					<div className='FacilitatorPlayerReportsPopup-table'>
						<div className="FacilitatorPlayerReportsPopup-tableheader">
							<div className={'FacilitatorPlayerReportsPopup-cell name'}>
								<span>{facilitatorUiTexts.viewReportPlayerFromGamePopup.reportId}</span>
							</div>
							<div className={'FacilitatorPlayerReportsPopup-cell reportTime'}>
								<span>{facilitatorUiTexts.viewReportPlayerFromGamePopup.reportTime}</span>
							</div>
							<div className={'FacilitatorPlayerReportsPopup-cell yearbook'}>
								<span>{facilitatorUiTexts.viewReportPlayerFromGamePopup.yearBook}</span>
							</div>
						</div>

						{sortedReports.map((report, index) => {
							return (
								<div className={'FacilitatorPlayerReportsPopup-row' 
								+ (checkIfAllReportsAreSeen([report], userId) ? ' seen' : '') }
								key={index}
								onClick={()=>{markReportAsSeen(report, reportPlayerId);}}>
									<div className={'FacilitatorPlayerReportsPopup-cell name'}>
										<span>{gamePlayers.find((p) => {
											return p.id === report.reportedById;
										})?.name}</span>
									</div>
									<div className={'FacilitatorPlayerReportsPopup-cell reportTime'}>
										<span>{report.reportTime}</span>
									</div>
									<div className={'FacilitatorPlayerReportsPopup-cell yearbook'}>
										{Object.keys(report.yearBook).map((key, index) => {
											return <span key={index}>{`${yearbookAllQuestions.find((question) => {
												return question.id === key;
											}).title} ${report.yearBook[key]} `}</span>;
										})}
									</div>
									{!coFacilitatorData && <div className={'FacilitatorPlayerReportsPopup-delete'}>
										<Button
											classes={['blueDark']}
											text={facilitatorUiTexts.viewReportPlayerFromGamePopup.delete}
											onClick={(e) => {
												e.stopPropagation();
												setDeleteReportPopupData(report);
											}}
										/>
									</div> }
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{deleteReportPopupData && <div className='FacilitatorPlayerReportsPopup-deletePopup'>
				<div className='FacilitatorPlayerReportsPopup-deleteContent'>
					<div className='FacilitatorPlayerReportsPopup-deleteTitle'>
						<span>{facilitatorUiTexts.viewReportPlayerFromGamePopup.deletePrompt}</span>
					</div>
					<div className='FacilitatorPlayerReportsPopup-deleteButtonsWrapper'>
						<Button
							classes={[(isLoading) ? 'blueDark loading' : 'blueDark']}
							text={facilitatorUiTexts.viewReportPlayerFromGamePopup.yes}
							onClick={(e) => {e.stopPropagation(); handleDeleteReport(deleteReportPopupData);}}
						/>
						<Button
							classes={['blueDark']}
							text={facilitatorUiTexts.viewReportPlayerFromGamePopup.no}
							onClick={(e) => {e.stopPropagation(); setDeleteReportPopupData(null);}}
						/>
					</div>
				</div>
			</div>}

		</div>
	);

};

FacilitatorPlayerReportsPopup.propTypes = {
	reportPlayerId: PropTypes.string.isRequired,
	setReportPlayerId: PropTypes.func.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	markReportAsSeen: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	deleteReport: PropTypes.func.isRequired,
	coFacilitatorData: PropTypes.object,
};

export default FacilitatorPlayerReportsPopup;
