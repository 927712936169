const avatarMaker = [
	'avatar-maker/accessories/chicken-wings.svg',
	'avatar-maker/accessories/four-leaf-clover.svg',
	'avatar-maker/accessories/green-thumb-badge.svg',
	'avatar-maker/accessories/lobster-bib.svg',
	'avatar-maker/accessories/cloud-stink.svg',
	'avatar-maker/accessories/name-tag.svg',
	
	'avatar-maker/animals/bunny.svg',
	'avatar-maker/animals/canary.svg',
	'avatar-maker/animals/cat.svg',
	'avatar-maker/animals/dog.svg',
	'avatar-maker/animals/disco-chicken.svg',
	'avatar-maker/animals/fish.svg',
	'avatar-maker/animals/hamster.svg',
	'avatar-maker/animals/hedgehog.svg',
	'avatar-maker/animals/jarzard.svg',
	'avatar-maker/animals/lizard.svg',
	'avatar-maker/animals/parrot.svg',
	'avatar-maker/animals/plank-of-wood.svg',
	'avatar-maker/animals/snake.svg',
	'avatar-maker/animals/toad.svg',
	'avatar-maker/animals/turtle.svg',

	'avatar-maker/beard/beard-1.svg',
	'avatar-maker/beard/beard-2.svg',
	'avatar-maker/beard/beard-3.svg',
	'avatar-maker/beard/beard-4.svg',
	'avatar-maker/beard/beard-5.svg',
	'avatar-maker/beard/beard-6.svg',
	'avatar-maker/beard/beard-7.svg',
	'avatar-maker/beard/beard-8.svg',
	'avatar-maker/beard/beard-9.svg',
	'avatar-maker/beard/beard-10.svg',
	'avatar-maker/beard/beard-11.svg',

	'avatar-maker/category-icons/accessories.svg',
	'avatar-maker/category-icons/animals.svg',
	'avatar-maker/category-icons/apron.svg',
	'avatar-maker/category-icons/beard.svg',
	'avatar-maker/category-icons/body-type.svg',
	'avatar-maker/category-icons/clothes.svg',
	'avatar-maker/category-icons/decorations.svg',
	'avatar-maker/category-icons/eyes.svg',
	'avatar-maker/category-icons/glasses.svg',
	'avatar-maker/category-icons/hair.svg',
	'avatar-maker/category-icons/hats.svg',
	'avatar-maker/category-icons/items.svg',
	'avatar-maker/category-icons/mouth.svg',
	'avatar-maker/category-icons/nose.svg',
	'avatar-maker/category-icons/skin-color.svg',

	'avatar-maker/clothes/jacket-f-1.svg',
	'avatar-maker/clothes/jacket-f-2.svg',
	'avatar-maker/clothes/jacket-m-1.svg',
	'avatar-maker/clothes/jacket-m-2.svg',
	'avatar-maker/clothes/leather-jacket.svg',
	'avatar-maker/clothes/longshirt-f-1.svg',
	'avatar-maker/clothes/longshirt-f-2.svg',
	'avatar-maker/clothes/longshirt-m-1.svg',
	'avatar-maker/clothes/longshirt-m-2.svg',
	'avatar-maker/clothes/shirt-f-1.svg',
	'avatar-maker/clothes/shirt-f-2.svg',
	'avatar-maker/clothes/shirt-f-3.svg',
	'avatar-maker/clothes/shirt-f-4.svg',
	'avatar-maker/clothes/shirt-f-5.svg',
	'avatar-maker/clothes/shirt-f-6.svg',
	'avatar-maker/clothes/shirt-f-7.svg',
	'avatar-maker/clothes/shirt-f-8.svg',
	'avatar-maker/clothes/shirt-f-9.svg',
	'avatar-maker/clothes/shirt-f-10.svg',
	'avatar-maker/clothes/shirt-f-11.svg',
	'avatar-maker/clothes/shirt-f-12.svg',
	'avatar-maker/clothes/shirt-m-1.svg',
	'avatar-maker/clothes/shirt-m-2.svg',
	'avatar-maker/clothes/shirt-m-3.svg',
	'avatar-maker/clothes/shirt-m-4.svg',
	'avatar-maker/clothes/shirt-m-5.svg',
	'avatar-maker/clothes/shirt-m-6.svg',
	'avatar-maker/clothes/shirt-m-7.svg',
	'avatar-maker/clothes/shirt-m-8.svg',
	'avatar-maker/clothes/shirt-m-9.svg',
	'avatar-maker/clothes/shirt-m-10.svg',
	'avatar-maker/clothes/shirt-m-11.svg',
	'avatar-maker/clothes/shirt-m-12.svg',
	'avatar-maker/clothes/shirt-m-13.svg',
	'avatar-maker/clothes/shirt-tropical.svg',
	'avatar-maker/clothes/shirt-m-cowprint.svg',
	'avatar-maker/clothes/shirt-m-milk-sandwich.svg',
	'avatar-maker/clothes/shirt-f-milk-sandwich.svg',
	'avatar-maker/clothes/sweatshirt-f-1.svg',
	'avatar-maker/clothes/sweatshirt-f-2.svg',
	'avatar-maker/clothes/sweatshirt-m-1.svg',
	'avatar-maker/clothes/sweatshirt-m-2.svg',
	
	'avatar-maker/decorations/mounted-singing-fish.svg',

	'avatar-maker/eyes/eyes-f-1.svg',
	'avatar-maker/eyes/eyes-f-2.svg',
	'avatar-maker/eyes/eyes-f-3.svg',
	'avatar-maker/eyes/eyes-f-4.svg',
	'avatar-maker/eyes/eyes-f-5.svg',
	'avatar-maker/eyes/eyes-f-6.svg',
	'avatar-maker/eyes/eyes-f-7.svg',
	'avatar-maker/eyes/eyes-f-8.svg',
	'avatar-maker/eyes/eyes-f-9.svg',
	'avatar-maker/eyes/eyes-f-10.svg',
	'avatar-maker/eyes/eyes-m-1.svg',
	'avatar-maker/eyes/eyes-m-2.svg',
	'avatar-maker/eyes/eyes-m-3.svg',
	'avatar-maker/eyes/eyes-m-4.svg',
	'avatar-maker/eyes/eyes-m-5.svg',
	'avatar-maker/eyes/eyes-m-6.svg',
	'avatar-maker/eyes/eyes-m-7.svg',
	'avatar-maker/eyes/eyes-m-8.svg',
	'avatar-maker/eyes/eyes-m-9.svg',
	'avatar-maker/eyes/eyes-m-10.svg',

	'avatar-maker/glasses/glasses-1.svg',
	'avatar-maker/glasses/glasses-2.svg',
	'avatar-maker/glasses/glasses-3.svg',
	'avatar-maker/glasses/glasses-4.svg',
	'avatar-maker/glasses/glasses-5.svg',
	'avatar-maker/glasses/glasses-6.svg',
	'avatar-maker/glasses/glasses-7.svg',
	'avatar-maker/glasses/glasses-8.svg',
	'avatar-maker/glasses/glasses-9.svg',
	'avatar-maker/glasses/glasses-10.svg',

	'avatar-maker/hair/hair.svg',

	'avatar-maker/hats/banana-headdress.svg',
	'avatar-maker/hats/beret.svg',
	'avatar-maker/hats/bucket-hat.svg',
	'avatar-maker/hats/bunny-ears.svg',
	'avatar-maker/hats/chefs-hat.svg',
	'avatar-maker/hats/cupcake-hat.svg',
	'avatar-maker/hats/donut-hat.svg',
	'avatar-maker/hats/flower-pot.svg',
	'avatar-maker/hats/fun-hat.svg',
	'avatar-maker/hats/giant-log.svg',
	'avatar-maker/hats/hairnet.svg',
	'avatar-maker/hats/karate-headband.svg',
	'avatar-maker/hats/lobster-hat.svg',
	'avatar-maker/hats/mop-hat.svg',
	'avatar-maker/hats/shark-hat.svg',
	'avatar-maker/hats/smelly-cheese-crown.svg',
	'avatar-maker/hats/straw-hat.svg',
	'avatar-maker/hats/trapper.svg',
	'avatar-maker/hats/watermelon-helmet.svg',
	'avatar-maker/hats/wet-floor-sign-hat.svg',

	'avatar-maker/head/head-f.svg',
	'avatar-maker/head/head-m.svg',

	'avatar-maker/items/lightsaber-broom.svg',
	'avatar-maker/items/wand-toy.svg',	
	'avatar-maker/items/balloon-yay-me.svg',

	'avatar-maker/mouth/mouth-f-1a.svg',
	'avatar-maker/mouth/mouth-f-1b.svg',
	'avatar-maker/mouth/mouth-f-2a.svg',
	'avatar-maker/mouth/mouth-f-2b.svg',
	'avatar-maker/mouth/mouth-f-3a.svg',
	'avatar-maker/mouth/mouth-f-3b.svg',
	'avatar-maker/mouth/mouth-f-4a.svg',
	'avatar-maker/mouth/mouth-f-4b.svg',
	'avatar-maker/mouth/mouth-f-5a.svg',
	'avatar-maker/mouth/mouth-f-5b.svg',
	'avatar-maker/mouth/mouth-m-1a.svg',
	'avatar-maker/mouth/mouth-m-1b.svg',
	'avatar-maker/mouth/mouth-m-2a.svg',
	'avatar-maker/mouth/mouth-m-2b.svg',
	'avatar-maker/mouth/mouth-m-3a.svg',
	'avatar-maker/mouth/mouth-m-3b.svg',
	'avatar-maker/mouth/mouth-m-4a.svg',
	'avatar-maker/mouth/mouth-m-4b.svg',
	'avatar-maker/mouth/mouth-m-5a.svg',
	'avatar-maker/mouth/mouth-m-5b.svg',

	'avatar-maker/nose/nose.svg',

	'avatar-maker/work-clothes/apron-f-2.svg',
	'avatar-maker/work-clothes/apron-f-1.svg',
	'avatar-maker/work-clothes/apron-f-3.svg',
	'avatar-maker/work-clothes/apron-m-1.svg',
	'avatar-maker/work-clothes/apron-m-2.svg',
	'avatar-maker/work-clothes/apron-m-3.svg',
	'avatar-maker/work-clothes/lifejacket.svg',
	'avatar-maker/work-clothes/sandwich-costume.svg',
	'avatar-maker/work-clothes/penguin-suit.svg',
];

export {
	avatarMaker
};
