import {modulesData} from 'data/modules/modules-data';
import {
	checkIfAllModulesInAreaAreCompleted, 
	checkIfModuleIsUnlocked,
	checkIfModuleIsCompleted,
	getPlayerModulesData,
	getPlayerModuleData,
	getMaxNumberOfStarsInModule
} from 'helpers/module-helper';


/**
 * Check if notification in area is triggered
 * @param {*} notification 
 * @param {*} playerData 
 * @returns 
 */
export function checkIfAreaNotificationIsTriggered(notification, playerData) {
	let isTriggered = false;
	const playerNotifications = playerData.notifications ? playerData.notifications : [];

	/* Only check notifications that have not been seen */
	if (playerNotifications.indexOf(notification.id) < 0) {
		/* First visit - always triggered */
		if (notification.type === 'first-visit') {
			isTriggered = true;
		}

		/* New module unlocked */
		if (notification.type === 'module-unlocked') {
			const moduleData = modulesData.find((m) => {return m.id === notification.moduleId;});

			/* Check if unlocked */
			const isUnlocked = checkIfModuleIsUnlocked(moduleData, playerData);
			if (isUnlocked) {
				isTriggered = true;
			}
		}

		/* Module completed */
		if (notification.type === 'module-completed') {
			const playerModuleData = getPlayerModuleData(notification.moduleId, playerData, null);
			const isCompleted = checkIfModuleIsCompleted(playerModuleData);
			if (isCompleted) {
				isTriggered = true;
			}
		}

		/* Area completed */
		if (notification.type === 'area-completed') {
			const playerModulesData = getPlayerModulesData(playerData);
			const isCompleted = checkIfAllModulesInAreaAreCompleted(notification.areaId, playerModulesData);
			if (isCompleted) {
				isTriggered = true;
			}
		}

		/* Specific number of stars in a group of modules */
		if (notification.type === 'number-of-stars-in-modules-equal') {
			let totalStars = 0;
			notification.moduleIds.forEach((moduleId) => {
				const maxStarsInModule = getMaxNumberOfStarsInModule(playerData, moduleId, null);
				totalStars += maxStarsInModule;
			});
			if (totalStars === notification.numberOfStars) {
				isTriggered = true;
			}
		}

		/* Minimum number of stars */
		if (notification.type === 'number-of-stars-in-modules-minimum') {
			let totalStars = 0;
			notification.moduleIds.forEach((moduleId) => {
				const maxStarsInModule = getMaxNumberOfStarsInModule(playerData, moduleId, null);
				totalStars += maxStarsInModule;
			});
			if (totalStars >= notification.numberOfStars) {
				isTriggered = true;
			}
		}

		/* Maximum number of stars */
		if (notification.type === 'number-of-stars-in-modules-maximum') {
			let totalStars = 0;
			notification.moduleIds.forEach((moduleId) => {
				const maxStarsInModule = getMaxNumberOfStarsInModule(playerData, moduleId, null);
				totalStars += maxStarsInModule;
			});
			if (totalStars <= notification.numberOfStars) {
				isTriggered = true;
			}
		}
	}

	

	return isTriggered;
}