const moduleDailyGuidelines1 = [
	'modules/tasks/instructions/frying-pan-and-veg-oil.svg',

	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-1.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-2.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-3.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-4.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-5.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-label/option-6.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-table-with-cookies/option-1.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-table-with-cookies/option-2.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-1.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-2.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-3.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-4.svg',
	'modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-5.svg',

	'modules/tasks/spot-errors/daily-guidelines-1-service-case-signs.svg',
	'modules/tasks/spot-errors/daily-guidelines-1-sign-blocks.svg',
];

export {
	moduleDailyGuidelines1
};
