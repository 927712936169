import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import './admin-user-popup.scss';

const AdminUserPopup = ({userId, gamesData, usersData, handleSelectUser}) => {
	/* Get user data */
	const userData = usersData.find((user) => {return user.id === userId;});

	/* Get all games the user is connected to */
	let userGames = '';
	if (userData.role === 'player') {
		if (userData.games && userData.games.length > 0) {
			userData.games.forEach((game) => {
				const gameData = gamesData.find((g) => {return g.id === game.id;});
				if (gameData && gameData.storeName) {
					if (userGames.length === 0) {
						userGames = gameData.storeName;
					} else {
						userGames += ', ' + gameData.storeName;
					}
				}
			});
		}
	} else if (userData.role === 'facilitator' || userData.role === 'admin') {
		gamesData.forEach((game) => {
			if (game.facilitatorEmails && game.facilitatorEmails.indexOf(userData.email) >= 0) {
				if (userGames.length === 0) {
					userGames = game.storeName;
				} else {
					userGames += ', ' + game.storeName;
				}
			};
		});
	}

	return (
		<div className="AdminUserPopup" onClick={() => {handleSelectUser();}}>
			<div className="AdminUserPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminUserPopup-header">
					<div className="AdminUserPopup-title">{userData ? userData.name : '-'}</div>
					<div 
						className="AdminUserPopup-closeBtn" 
						onClick={() => {handleSelectUser();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminUserPopup-body">
					<div className="AdminUserPopup-section">
						<div className="AdminUserPopup-sectionTitle">{adminUiTexts.connectedStoreMulti}:</div>
						<div className="AdminUserPopup-sectionText">
							<p>{userGames}</p>
						</div>
					</div>
					<div className="AdminUserPopup-section">
						<div className="AdminUserPopup-sectionTitle">{adminUiTexts.email}:</div>
						<div className="AdminUserPopup-sectionText">
							<p>{(userData && userData.email ? userData.email : '-')}</p>
						</div>
					</div>
				
				</div>
			</div>
		</div>
	);

};

AdminUserPopup.propTypes = {
	userId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	handleSelectUser: PropTypes.func.isRequired,
};

export default AdminUserPopup;
