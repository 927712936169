import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {moduleComponents} from 'data/modules/modules-components';
import {facilitatorUiTexts} from 'data/ui-texts';
import {getPerfectScorePercentage, getAverageTaskMistakes} from 'helpers/module-helper';
import './facilitator-task-popup.scss';

const FacilitatorTaskPopup = ({ 
	isFacilitator = false,
	isCompetition = false,
	showAllTasks = false,
	initialTaskId = null,
	moduleId, 
	managerId,
	playerData = null, 
	playerModuleTasks,
	modulePlayerStats = null,
	closePopup,	
}) => {

	/* Get module data & relevant tasks */
	const moduleData = (isCompetition
		? modulesCompetitionsData.find((m) => {return m.id === moduleId;})
		: modulesData.find((m) => {return m.id === moduleId;})
	);

	const moduleTasks = moduleData.tasks.filter((task) => {
		return (showAllTasks || task.isSolveToContinue);
	});

	/* Get task index from task id */
	const getTaskIndex = (taskId) => {
		const taskIndex = (moduleTasks ? moduleTasks.findIndex((t) => {return t.id === taskId;}) : -1);
		return taskIndex;
	};

	/* Task navigation index */
	const [currentTaskIndex, setCurrentTaskIndex] = useState(() => {
		return (initialTaskId ? getTaskIndex(initialTaskId) : 0);
	});

	/**
	 * Goes to a module task with given index. 
	 * Does nothing if index is out of bounds.
	 * @param {number} index 
	 */
	const goToTask = (index) => {
		if (index < moduleTasks.length && index >= 0) {
			setCurrentTaskIndex(index);
		}
	};
	
	/* Get task component */
	let TaskComponent = null;
	if (
		currentTaskIndex >= 0 && 
		moduleTasks.length > currentTaskIndex && 
		moduleComponents.hasOwnProperty(moduleTasks[currentTaskIndex].type)
	) {
		TaskComponent = moduleComponents[moduleTasks[currentTaskIndex].type];
	}

	/* Get task data & player task data (from most recent session) */
	const taskData = moduleTasks[currentTaskIndex];
	const playerTaskData = (playerModuleTasks && playerModuleTasks.some((t) => {return t.taskId === taskData.taskId;}) 
		? playerModuleTasks.find((t) => {return t.taskId === taskData.taskId;})
		: null
	);

	/* Component mounted */
	useEffect(() => {
		setCurrentTaskIndex((initialTaskId ? getTaskIndex(initialTaskId) : 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialTaskId]);	

	const fakeDeviceInfo = {
		aspectRatio: 1.78,
		isInStandaloneMode: false,
		isUsingTouch: false,
		orientation: 'portrait'
	};

	const showSolvedTask = (
		TaskComponent && 
		moduleData && 
		taskData.type !== 'dialogue' && 
		taskData.type !== 'clock-tasks' && 
		taskData.type !== 'branching-story'
	);

	return (
		<div className='FacilitatorTaskPopup' onClick={(e) => {e.stopPropagation();}}>
			{/* Header - title & close btn */}
			<div className='FacilitatorTaskPopup-header'>
				<div className='FacilitatorTaskPopup-title'><span>{moduleData.title}</span></div>
				<div className='FacilitatorTaskPopup-closeBtn' onClick={() => {closePopup();}}/>
			</div>
			{/* Task (unsolved) */}
			<div className={'FacilitatorTaskPopup-body' + (isCompetition ? ' competition' : '')}>
				<div className='FacilitatorTaskPopup-task'>
					{TaskComponent && moduleData && 
						<TaskComponent
							isShowingFeedbackOrPopup={false}
							areaId={moduleData.areaId}
							managerId={managerId}
							playerData={playerData}
							playerTaskData={playerTaskData}
							moduleData={moduleData}
							taskData={taskData}
							deviceInfo={fakeDeviceInfo}
							setShowPointsTrack={() => {}}
							handleInstantTaskEffects={() => {}}
							handleCompleteIntro={() => {}}
							handleCompleteTask={() => {}}
							handleGoToTask={() => {}}
							updateLoggedTime={() => {}}
							isFacilitator={isFacilitator}
						/>
					}
				</div>
				{/* Task (solved) */}
				{isCompetition && <div className='FacilitatorTaskPopup-taskSolved'>
					{showSolvedTask &&
						<TaskComponent
							isFacilitator={isFacilitator}
							isShowingFeedbackOrPopup={false}
							areaId={moduleData.areaId}
							managerId={managerId}
							playerData={playerData}
							playerTaskData={{isCompleted: true}}
							moduleData={moduleData}
							taskData={taskData}
							deviceInfo={fakeDeviceInfo}
							setShowPointsTrack={() => {}}
							handleInstantTaskEffects={() => {}}
							handleCompleteIntro={() => {}}
							handleCompleteTask={() => {}}
							handleGoToTask={() => {}}
							updateLoggedTime={() => {}}
						/>
					}
					{!showSolvedTask && <div className="FacilitatorTaskPopup-taskSolvedInfo">
						<span>{facilitatorUiTexts.multipleCorrectSolutions}</span>
					</div>}
				</div>}


				{/* Stats */}
				{modulePlayerStats && 
				<div className='FacilitatorTaskPopup-statistics'>
					<div className='FacilitatorTaskPopup-statisticsHeader'>{facilitatorUiTexts.avgMistakes}
						<div className='FacilitatorTaskPopup-statisticsResult'>
							{getAverageTaskMistakes(taskData, modulePlayerStats)}
						</div>
					</div>
					<div className='FacilitatorTaskPopup-statisticsHeader'>{facilitatorUiTexts.perfScores}
						<div className='FacilitatorTaskPopup-statisticsResult'>
							{getPerfectScorePercentage(taskData, modulePlayerStats)}
						</div>
					</div>
					<div className='FacilitatorTaskPopup-footer'>
						<div className='FacilitatorTaskPopup-previous' 
							onClick={() => {goToTask(currentTaskIndex - 1);}}/>
						<div className='FacilitatorTaskPopup-taskText'>
							{facilitatorUiTexts.task + ' ' + (currentTaskIndex + 1) + '/' + moduleTasks.length}
						</div>
						<div className='FacilitatorTaskPopup-next' 
							onClick={() => {goToTask(currentTaskIndex + 1);}}/>
					</div>
				</div>}
			</div>

			{/* Task labels */}
			{isCompetition &&
				<div className="FacilitatorTaskPopup-taskLabels">
					<div className="FacilitatorTaskPopup-taskLabel">
						<span>{facilitatorUiTexts.question}</span>
					</div>
					<div className="FacilitatorTaskPopup-taskLabel">
						<span>{facilitatorUiTexts.answer}</span>
					</div>
				</div>}

			{/* Footer */}
			{!modulePlayerStats &&
			<div className='FacilitatorTaskPopup-footer'>
				<div className='FacilitatorTaskPopup-previous' onClick={() => {goToTask(currentTaskIndex - 1);}}/>
				<div className='FacilitatorTaskPopup-taskText'>
					{facilitatorUiTexts.task + ' ' + (currentTaskIndex + 1) + '/' + moduleTasks.length}
				</div>
				<div className='FacilitatorTaskPopup-next' onClick={() => {goToTask(currentTaskIndex + 1);}}/>
			</div>}
		</div>
	);

};

FacilitatorTaskPopup.propTypes = {
	isFacilitator: PropTypes.bool,
	isCompetition: PropTypes.bool,
	showAllTasks: PropTypes.bool,
	initialTaskId: PropTypes.string,
	moduleId: PropTypes.string.isRequired,	
	managerId: PropTypes.string.isRequired,	
	playerData: PropTypes.object,
	playerModuleTasks: PropTypes.array,
	modulePlayerStats: PropTypes.array,
	closePopup: PropTypes.func.isRequired,
};

export default FacilitatorTaskPopup;
