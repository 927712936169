import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {profileUiTexts} from 'data/ui-texts';
import {badgesData} from 'data/badges-data';
import {getBadgeCompletionLevel} from 'helpers/badges-helper';
import Badge from 'components/ui/badge/badge';
import './profile-badges.scss';

const ProfileBadges = ({playerData, userData}) => {
	const playerBadges = (playerData.badges ? playerData.badges : []);

	const [selectedBadgeId, setSelectedBadgeId] = useState(null);
	const toggleBadgeId = (badgeId = null) => {
		if (!badgeId || badgeId === selectedBadgeId) {
			setSelectedBadgeId(null);
		} else {
			setSelectedBadgeId(badgeId);
		}

	};

	const selectedBadgeData = badgesData.find((badge) => {return badge.id === selectedBadgeId;});

	return (
		<div className="ProfileBadges">
			<div className="ProfileBadges-title">{profileUiTexts.badges}</div>
			<div className="ProfileBadges-badges">
				<div className="ProfileBadges-badgesWrap">
					{badgesData.map((badgeData) => {
						/* Empty placeholder */
						if (badgeData.type === 'placeholder') {
							return <div key={badgeData.id} className="ProfileBadges-badge" />;
						}

						/* Actual badge */
						const playerBadgeData = playerBadges.find((b) => {return b.id === badgeData.id;});
						const isUnlocked = (userData.role === 'admin'
							? true
							: playerBadgeData ? true : false
						);

						/* Level of completion */
						const levelOfCompletion = (isUnlocked 
							? null
							: getBadgeCompletionLevel(badgeData, playerData)
						);


						return (
							<div 
								key={badgeData.id} 
								className={'ProfileBadges-badge' + (isUnlocked ? ' unlocked' : '')}
								onClick={() => {toggleBadgeId(badgeData.id);}}
							>
								{levelOfCompletion && <div className="ProfileBadges-completionLevel">
									<span>{levelOfCompletion.value}/{levelOfCompletion.required}</span>	
								</div>}
								<div className="ProfileBadges-badgeIcon">
									<Badge isUnlocked={isUnlocked} page="profile" badgeId={badgeData.id} />
								</div>
								<div className="ProfileBadges-badgeLabel"><span>{badgeData.title}</span>
								</div>
							</div>
						);
				
					})}
				</div>
			</div>
			<div className="ProfileBadges-postits" />

			{selectedBadgeData && <div className="ProfileBadges-popup" onClick={() => {toggleBadgeId();}}>
				<div className="ProfileBadges-popupClose" />
				<div className="ProfileBadges-popupTitle">{selectedBadgeData.title}</div>
				<div className="ProfileBadges-popupText">{selectedBadgeData.textPinboard}</div>
			</div>}
		</div>
	);
};

ProfileBadges.propTypes = {
	playerData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

export default ProfileBadges;
