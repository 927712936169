/* Intro (only used in intro module) */
const introTemplate = {
	mainTemplate: {
		type: 'intro',
		isSolveToContinue: false,
		showLogo: false,
		showPoints: false,
		highlightAvatarLink: false,
		isLastTask: false,
		zoomLevel: 1,
		avatar: 'shadow',
		wizard: null,
		managerPose: null,
		managerFadeIn: false,
		textFadeIn: false,
		title: null,
		textType: 'info',
		buttons: null,
		nextTaskId: null,
		onLoadEffects: null
	},
};

/* Instructions */
const instructionsTemplate = {
	mainTemplate: {
		type: 'instructions',
		subtype: null, // delay
		isSolveToContinue: false,
		showPoints: true,
		delayTime: 0, // Actual delay time
		shownDelay: 0, // Shown delay time
		autoContinueSeconds: null, // automatically goes to next task after X seconds
		showCharacter: true,
		showAvatar: false,
		avatarPose: null,
		avatarItems: [],
		characterType: 'manager', // 'wizard', 'manager', 'chef', ...
		characterPose: 2,
		characterItems: [],
		animateCharacter: false,
		animationId: 1,	
		fadeoutSpeachBubble: false,
		extraCharacters: [],
		speechBubbleDirection: 'left', // left, middle, right
		items: [], // e.g. 'big-apple'
		background: null,
		title: null,
		text: null,
		onLoadEffects: null,
	},
	extraCharacterTemplate: {
		id: '',
		type: 'right',
		position: 'right',
		items: []
	}
}

/* Image */
const imageTemplate = {
	mainTemplate: {
		type: 'image',
		subtype: null, // delay
		delayTime: 0, // Actual delay time
		shownDelay: 0, // Shown delay time
		isSolveToContinue: false,
		showPoints: true,
		image: null,
		layout: null,
		text: null,
		nextBtnTypes: ['blue', 'done'], // or ['next']
		nextBtnText: 'Done' // or null
	}
}

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		isDilemma: false,
		subtype: null, // null, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1, // used if isDilemma
		maxPoints: 0, // used if isDilemma
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showPoints: true,
		image: null,
		text: '',
		options: [],
		doneEffects: [],
		showManager: false,
		managerPose: null
	},
	optionTemplate: {
		text: '', 
		isCorrect: false,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		isSolveToContinue: true,
		showPoints: true,
		image: null,
		layout: '',
		text: '',
		withHeader: true,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		text: ''
	},
	itemTemplate: {
		text: '',
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		isSolveToContinue: true,
		showPoints: true,
		image: null,
		text: '',
		items: []
	},
	itemTemplate: {
		text: '',
		correctOrderNumbers: []
	}
};

/* Organize */
const organizeTemplate = {
	mainTemplate: {
		type: 'organize',
		isSolveToContinue: true,
		showPoints: true,
		showSolutionWhenDone: false,
		shuffleItems: false,
		layout: '',
		title: null,
		text: '',
		items: [],
		rules: [],
		mainContainers: [],
		doneConditions: [],
		doneEffects: []
	},
	itemTemplate: {
		containerId: null, 
		image: null, 
		alt: '',
		correctContainerIds: []
	},
	mainContainerTemplate: {
		title: null,
		containers: []
	},
	containerTemplate: {
		type: '',
		finalItemId: null
	},
	doneConditionTemplate: {
		type: '', // empty-main-container
		mainContainerId: null
	},
	rulesTemplate: {
		type: '', // items-in-correct-containers, items-in-correct-groups
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		isSolveToContinue: true,
		showPoints: true,
		timeLimit: null, // in seconds, null means no time limit
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		image: null,
		layout: null,
		text: null,
		resultOverwrites: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

/* Paper doll */
const paperDollTemplate = {
	mainTemplate: {
		type: 'paper-doll',
		isSolveToContinue: true,
		taskId: null, // e.g. 'deli-1-paper-doll-items'
		showPoints: true,
		showToolbox: true,
		items: [], // should be ordered after intended z-index (small to large)
		doneEffects: []
	},
	itemTemplate: {
		id: null,
		type: 'default', // default (selected image is same as in panel), alternative (suffix -b), bodytype (suffix f/m-b)
		isCorrect: false,
		correctConditions: null,
		dependsOnBodyHeight: false, // body types f and f2 are shorter
		dependsOnBodyWidth: false, // body types f2 and m2 are wider
		title: '',
		selectEffects: [],
		deselectEffects: [],
	},
	correctConditionTemplate: {
		type: 'avatar-category-in-use',
		categoryId: null
	}
};

/* Dialogue */
const dialogueTemplate = {
	mainTemplate: {
		type: 'dialogue',
		isSolveToContinue: true,
		showPoints: false,
		showPointsIfCompleted: true,
		characterId: null,
		maxPoints: 0,
		introText: '',
		introTextType: 'speech', // info, speech
		introBackground: null,
		introCharacter: 'manager-1',
		introShowAvatar: false,
		steps: []
	},
	stepTemplate: {
		showPoints: false,
		isFinalStep: false,
		imageFlip: false,
		type: 'dialogue', // dialog, action
		image: 'neutral', // neutral, happy, angry
		character: null,
		characters: null,
		items: null,
		text: null,
		autoContinue: false,
		nextStepId: null, // set if autoContinue is true
		optionType: 'dialogue', // dialogue, action
		optionPrompt: 'Choose your answer:', 
		options: [],
		effects: [],
		showAvatar: true, // only used in dialogue end result
		checklistData: null // only used in dialogue end result
	},
	stepOptionTemplate: {
		text: '',
		points: 0,
		dialogueEffects: [],
		effects: [],
		autoContinue: false,
		nextStepId: null // id (continue) or null (stay at step)
	},
	stepOptionEffectTemplate: {
		type: 'dialogue', // dialogue, action
		text: ''
	}
};

/* Clock tasks */
const clockTasksTemplate = {
	mainTemplate: {
		type: 'clock-tasks',
		isSolveToContinue: true,
		layout: null, // unique for each clock task
		showPoints: true,
		startTime: {hours: 0, minutes: 0, period: 'a.m.'},
		maxDurationMinutes: 120,
		tasks: []
	},
	taskTemplate: {
		isRequired: true,
		isSolveToContinue: true,
		delayMinutes: 0,
		durationMinutes: 0,
		type: 'click-to-solve', // click-to-solve or any of the above
		hasImage: false, // has image that is not part of background
		hasSolvedImage: false, // has different image if solved
		maxPoints: 0,
		title: null,
		solvedEffects: [],
		failedEffects: []
	},
};

/* Branching story */
const branchingStoryTemplate = {
	mainTemplate: {
		type: 'branching-story',
		isSolveToContinue: true,
		background: null,
		showPoints: true,
		maxPoints: 0,
		steps: []
	},
	stepInfoTemplate: {
		type: 'info',
		nextStepId: null, // null if last step
		title: null,
		text: '',
		textType: 'info', // info, speech
		images: [],
		autoContinue: true,
		nextStepDelay: 500
	},
	stepMultipleChoiceTemplate: {
			type: 'multiple-choice',
			shuffleOptions: true,
			showNumberOfAnswersToSelect: true,
			title: null,
			text: '',
			image: null,
			options: []
	},
	stepMultipleChoiceOptionTemplate: {
		text: '', 
		effects: [],
		nextStepId: null // null (stay at story step), id (continue)
	},
	stepChecklistTemplate: {
		type: 'checklist',
		title: null,
		text: '',
		nextStepId: null,
		checklistData: null,
	},
	stepChecklistItemTemplate: {
		text: '',
		conditions: []
	},
	stepEmptyFinalStepTemplate: {
		type: 'empty-final-step'
	}
};

export {
	introTemplate,
	instructionsTemplate,
	imageTemplate,
	multipleChoiceTemplate,
	sortTemplate,
	orderTemplate,
	organizeTemplate,
	spotErrorsTemplate,
	paperDollTemplate,
	dialogueTemplate,
	clockTasksTemplate,
	branchingStoryTemplate
};
