import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, loginUiTexts, profileUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import BadgesIcon from 'assets/images/profile/icon-profile-badges.svg';
import ProgressIcon from 'assets/images/profile/icon-profile-progress.svg';
import HighscoreIcon from 'assets/images/profile/icon-profile-high-score.svg';
import ProfileAvatar from './profile-avatar';
import ProfileBadges from './profile-badges';
import ProfileProgress from './profile-progress';
import ProfileHighscore from './profile-highscore';
import Avatar from 'components/game/avatar/avatar';
import './profile.scss';

const Profile = (props) => {
	const {
		deviceInfo, 
		userData, 
		playerData, 
		handleGoToPage, 
		handleGoToPrevPage,
		updatePlayerGameData,
		scrollToTop, 
		togglePopup, 
		handleLogout,
		reportPlayer
	} = props;

	/* Profile page */
	const [profilePage, setProfilePage] = useState('avatar'); // avatar, badges, progress
	const goToProfilePage = (page) => {
		setProfilePage(page);
		scrollToTop();
	};

	/* Player can only leave profile page if avatar has been generated */
	const handleLeaveProfilePage = () => {
		if (playerData.avatar) {
			handleGoToPrevPage();
		} else {
			togglePopup({
				type: 'go-to-avatar', 
				title: profileUiTexts.goToAvatarPopup.title, 
				text: profileUiTexts.goToAvatarPopup.text
			});
			setProfilePage('avatar');
		}
	};

	return (
		<div className={'Profile ' + deviceInfo.orientation}>
			{/* Page content */}
			<div className="Profile-content">
				{profilePage === 'avatar' && 
					<ProfileAvatar 
						userData={userData}
						playerData={playerData}
						handleGoToPage={handleGoToPage}
						updatePlayerGameData={updatePlayerGameData}
					/>
				}
				{profilePage === 'badges' && 
					<ProfileBadges playerData={playerData} userData={userData} />
				}
				{profilePage === 'highscore' && 
					<ProfileHighscore playerData={playerData} userData={userData} reportPlayer={reportPlayer} />
				}
				{profilePage === 'progress' && 
					<ProfileProgress playerData={playerData} userData={userData} />
				}
			</div>

			{/* Back button */}
			<div className="Profile-backBtn">
				<Button classes={['back']} text={generalUiTexts.exit} onClick={() => {handleLeaveProfilePage();}}/>
			</div>

			

			{/* Username & Logout button */}
			{profilePage === 'avatar' && <div className="Profile-userName"><span>{userData.email}</span></div>}
			{profilePage === 'avatar' && <div className="Profile-logoutBtn">
				<Button text={loginUiTexts.logout} classes={['game-logout']} onClick={() => {handleLogout();}}/>
			</div>}

			{/* Profile button */}
			{profilePage !== 'avatar' && 
				<div className="Profile-profileBtn" onClick={() => {goToProfilePage('avatar');}}>
					<Avatar type="button" playerData={playerData} />
				</div>
			}

			{/* Profile navigation */}
			<div className="Profile-navigation">
				<div 
					className={'Profile-navItem avatar' + (profilePage === 'avatar' ? ' selected' : '')}
					onClick={() => {goToProfilePage('avatar');}}
				>
					<div className="Profile-navItemIcon">
						<Avatar type="profileNav" playerData={playerData} />
					</div>
					<div className="Profile-navItemLabel">{profileUiTexts.profile}</div>
				</div>
				<div 
					className={'Profile-navItem badges' + (profilePage === 'badges' ? ' selected' : '')}
					onClick={() => {goToProfilePage('badges');}}
				>
					<div className="Profile-navItemIcon"><img src={BadgesIcon} alt={profileUiTexts.badges} /></div>
					<div className="Profile-navItemLabel">{profileUiTexts.badges}</div>
				</div>
				<div 
					className={'Profile-navItem highscore' + (profilePage === 'highscore' ? ' selected' : '')}
					onClick={() => {goToProfilePage('highscore');}}
				>
					<div className="Profile-navItemIcon"><img src={HighscoreIcon} alt={profileUiTexts.highscore} />
					</div>
					<div className="Profile-navItemLabel">{profileUiTexts.highscore}</div>
				</div>

				<div 
					className={'Profile-navItem progress' + (profilePage === 'progress' ? ' selected' : '')}
					onClick={() => {goToProfilePage('progress');}}
				>
					<div className="Profile-navItemIcon"><img src={ProgressIcon} alt={profileUiTexts.progress} /></div>
					<div className="Profile-navItemLabel">{profileUiTexts.progress}</div>
				</div>
			</div>
		</div>
	);
};

Profile.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleGoToPrevPage: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	reportPlayer: PropTypes.func.isRequired,
};

export default Profile;
