import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorPagesData, facilitatorSubpagesData} from 'data/facilitator-data';
import './facilitator-overview.scss';

const FacilitatorOverview = ({handleGoToPage}) => {
	const overviewPageData = facilitatorPagesData.find((p) => {return p.id === 'overview';});


	return (
		<div className="FacilitatorOverview">
			<div className="FacilitatorOverview-pages">
				{overviewPageData.menu.map((item) => {
					const pageData = facilitatorPagesData.find((p) => {return p.id === item.pageId;});

					return (
						<div key={item.pageId} className={'FacilitatorOverview-page ' + item.pageId}>
							{pageData && pageData.subpageIds.map((subpageId) => {
								const subpageData = facilitatorSubpagesData.find((subpage) => {
									return subpage.id === subpageId;
								});

								return (
									<div 
										key={subpageData.id} 
										className={'FacilitatorOverview-subpage ' + subpageData.id}
										onClick={() => {handleGoToPage(item.pageId, subpageId);}}
									>
										<div className="FacilitatorOverview-subpageHeader">
											<span>{subpageData.title}</span>
										</div>
										<div className="FacilitatorOverview-subpageBody">
											<span>{renderMarkdown(subpageData.description)}</span>
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);

};

FacilitatorOverview.propTypes = {
	handleGoToPage: PropTypes.func.isRequired
};

export default FacilitatorOverview;
