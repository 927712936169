const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		text: `Welcome to the Bakery Island. Hope you can "rise" to the occasion.`,
		background: 'ocean-island',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: `Let's leave the jokes to me.`,
		background: 'ocean-island',
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		text: `Any way let's get ready for our day.`,
		background: 'ocean-island',
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'bakery-1-paper-doll-1',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net', title: 'Beard net', isCorrect: true,
				dependsOnBodyHeight: true, dependsOnBodyWidth: true,
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),	
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-white', title: 'Cut resistant gloves', isCorrect: true,
				type: 'alternative', dependsOnBodyHeight: true, dependsOnBodyWidth: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full', title: 'Full apron', isCorrect: true, 
				type: 'bodytype', dependsOnBodyHeight: true, dependsOnBodyWidth: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'oven-timer', title: 'Oven timer', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'rolling-pin', title: 'Rolling pin', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'ice-bucket', title: 'Ice bucket'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'flour', title: 'Bag of flour', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hammer', title: 'Hammer'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hat-white', title: 'White paper chef hat', isCorrect: true,
				dependsOnBodyHeight: true, dependsOnBodyWidth: true,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scotch-tape', title: 'Scotch tape', isCorrect: false,
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'cloth', title: 'Cloth', isCorrect: false,
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'chefs-hat',
					text: 'A Chef\'s hat has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'bakery-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `You're ready. Let's cover some Daily Expectations.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `We'll begin by taking a look at the entire department.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'bakery-1-department',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-place-to-start',
		text: `Where's the best place to start when making sure the department is ready for customers?`,
		image: 'first',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Sales floor', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, the best place to start is from the customer's perspective`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Production table',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `There's plenty to check on here, but it's not where we'll start.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Freezer',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `There's plenty to check on here, but it's not where we'll start.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Bathroom'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-most-important',
		text: 'What is the MOST important thing for the CUSTOMERS?',
		image: 'checklist2',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Quality and Freshness', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! While all five are important, this is what will bring customers back.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Cleanliness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Stock Level',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Signage and Pricing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Customer Service',
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-department',
		text: 'What is wrong here?',
		layout: 'bakery-1-department',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'notes'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'flour'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-table',
		text: `While we are checking for Quality and Freshness, let's look over this table.`,
		layout: 'bakery-1-table',
		image: 'calendar-august8',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'date-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'date-2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'date-3'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-first-thing-frozen',
		text: `What's the first thing you should check at the Frozen Service Case?`,
		image: 'service-cake-case',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Inventory levels',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Time and date on scale',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Lights on unit',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Temperature of case', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes. A bad temperature means lost product.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Special orders due',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Product rotation',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-most-forget',
		text: 'What do most forget to check daily?',
		image: 'service-cake-case',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Inventory levels',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Time and date on scale', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right. It\'s a small thing, but it affects everything we make today.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Lights on unit',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Special orders due',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Product rotation',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-fridge-temp',
		text: 'What temperature should the refrigerated cases be?',
		image: 'thermometer',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Below 41 F', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Below 35 F'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Below 45 F',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Below 37 F',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-customer-not-buy',
		text: `Customer wouldn't be willing to buy it, if...`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `I'm not willing to`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'It is the lowest price'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `It's not the very best quality`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `It's not brand new`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-food-below-standards',
		text: `Where does food below our standards go?`,
		subtype: 'images',
		layout: 'bakery-1-food-below-standards',
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // trash can
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 50% off sign
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // refrigerator
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-fresh-full',
		text: `Fresh and Full means...`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `All customers have same experience`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Ready to go first thing in the morning'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Maintained until the later evening`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Properly rotated`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `All of the Above`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: `None of the Above`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-senses',
		text: `What sense is used the most to shop?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Sight`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. Most customers shop by sight.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Smell'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Touch`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Taste`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Hearing`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `But smell is a quick 2nd.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Mmm Mmm. Smell those fresh donuts. Let's take a look at them.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-donuts',
		subtype: 'images',
		layout: 'bakery-1-donuts',
		text: 'Which donut has been cooked best?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				effects: [// bagel, with a cut line and seasoning on top
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Nope, that's a bagel.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				effects: [// thin white center line
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. Notice that perfect light ring around the edge.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				effects: [// large white center line
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This one's been overproofed and the center didn't cook well.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				effects: [// dark medium center line
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Underproofed so the center cooked twice'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-donut-need',
		text: `After seeing one of these, what do you need?`,
		image: 'donut-1',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Service bag`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Gloves'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `$1.00`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Another donut`,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Customers can't get fresh product without a way to pick it up.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `If the donut is balanced perfectly in the oil, both sides are cooked the same just like we want it.
			<br> <br>Now, let's fill the case.`,
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'bakery-1-organize-donuts',
		layout: 'bakery-1-donuts',
		text: `Fill the case. Click "Done" when you're ready.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'donut-1', alt: 'donut', correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'donut-2', alt: 'donut', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'donut-3', alt: 'donut', correctContainerIds: ['a4']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'donut-4', alt: 'donut', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'donut-5', alt: 'donut', correctContainerIds: ['a5']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'donut-6', alt: 'donut', correctContainerIds: ['a6']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'donut-7', alt: 'donut', correctContainerIds: ['a7']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 8, containerId: 'c8', image: 'donut-8', alt: 'donut', correctContainerIds: ['a9']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 9, containerId: 'c9', image: 'donut-9', alt: 'donut', correctContainerIds: ['a8']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 10, containerId: 'c10', image: 'donut-10', alt: 'donut', correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 11, containerId: 'c11', image: 'donut-11', alt: 'donut', correctContainerIds: ['b1', 'b2', 'b3']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 7}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves', finalItemId: 9}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'shelves', finalItemId: 8}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'remove',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'remove', finalItemId: 10}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'remove', finalItemId: 11}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'remove'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c8', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c9', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c10', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c11', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'bakery-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-muffin-display',
		text: `What's the problem here?`,
		layout: 'bakery-1-muffin-display',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'high-stack-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'high-stack-2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'donuts'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'empty-space'})
		],
	}),
	


	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'bakery-1-donuts-on-shelves',
		image: 'calendar-august8',
		text: `Take a look at this.`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-something-wrong',
		text: `Was something wrong in the previous picture?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Yes`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `No clue`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-what-issue',
		text: `What is the issue?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `More than 2 "sell-by" dates`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Some are expired'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Items are on wrong shelf`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Labels are misaligned`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `More than two dates means that we are making or ordering too much.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-dragon',
		text: `Time for some magic. Let's heat this bread.`,
		items: ['bread-on-table'],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-what-are-you-doing',
		text: 'What are you doing?',
		showManager: true,
		managerPose: 'manager-3',
		image: 'manager-top-right',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `He's making toast?`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard-dragon',
							text: 'Correct'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `I have no idea`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Making rolls?`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Wasting time!`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard-dragon',
							text: 'No I have a plan.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-dont-like-toast',
		text: `If you don't like toast, let it sit a couples days and get...`,
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Croutons!!!`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'Yes!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Old toast?`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Breakfast`
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: `No. You get an angry manager.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-customers-want',
		text: `Do you know what customers want?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Fresh bread`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly, now stop drying out my bread.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Dragons in the store`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Breakfast`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `S'mores`
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's get back to work.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `How about you and magic man go stock that coffin case with cakes.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-stack-how-high',
		text: `How high are you going to stack?`,
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `2 high`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Just 1 high`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `3 high`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `To the top`
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		characterItems: ['stack-of-cakes'],
		text: `I'm going 4 high for maximum stacking.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Show me the cake on bottom.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'bakery-1-cakes-stacked',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'bakery-1-cakes-fallen-over',
		text: `Seconds later...`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Since it was over 2 high, accidents will happen.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's look at those 3 high again, closely.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-stacking-height',
		text: `What's wrong here?`,
		layout: 'bakery-1-stacked-cakes',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cake-1',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, they are over the fill line.'
						})
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-high-stack-temperature',
		text: `Are these cakes above the line going to be at proper temperature?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `No`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Yes`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Hard to say`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Fine if they sell fast`
			}),
		]
	}),
	/* DIALOGUE START */
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'bakery-1-dialogue-cleaning-rotating',
		characterId: 'manager-2',
		maxPoints: 9,
		introText: `Let's talk about cleaning and rotating.`,
		introTextType: 'speech',
		introCharacter: 'manager-2',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-01',
				text: 'When did you first clean and rotate the service case?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'At 7:00am', nextStepId: 'bd-02',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'At 10:00am', nextStepId: 'bd-03',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'At Lunch', nextStepId: 'bd-04'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-02',
				text: `Good. Ready before we open.`,
				autoContinue: true,
				nextStepId: 'bd-05'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-03',
				text: `That was too late.`,
				autoContinue: true,
				nextStepId: 'bd-05'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-04',
				text: `That is way too late.`,
				image: 'manager-3',
				autoContinue: true,
				nextStepId: 'bd-05'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-05',
				text: 'When should we clean and pull product to front?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Every 2 hours', nextStepId: 'bd-06',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Every 6 hours', nextStepId: 'bd-07',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Every 4 hours', nextStepId: 'bd-07'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, text: 'During cleaning at close', nextStepId: 'bd-08'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-06',
				text: `Exactly! Great work.`,
				autoContinue: true,
				nextStepId: 'bd-09'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-07',
				text: `That's too long.`,
				autoContinue: true,
				nextStepId: 'bd-09'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-08',
				text: `That's waaaay too long to wait.`,
				image: 'manager-3',
				autoContinue: true,
				nextStepId: 'bd-09'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-09',
				text: `Clean and rotate every 2 hours ... but for how long?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: 'Open until close', nextStepId: 'bd-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: 'Until lunch rush', nextStepId: 'bd-11',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: 'Until dinner rush', nextStepId: 'bd-12'
					})
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-10',
				text: `Yes! Clean and fresh all day.`,
				nextStepId: 'bd-13'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-11',
				text: `What about the dinner shoppers?`,
				nextStepId: 'bd-13'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-12',
				text: `What about the late shoppers?`,
				image: 'manager-3',
				nextStepId: 'bd-13'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'bd-13',
				showPoints: true,
				isFinalStep: true,
				showAvatar: true,
				character: 'manager-2',
				text: `Nice work!`,
			}),
			
		]		
	}),
	/* DIALOGUE END */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cake-color',
		text: `Does cake color matter based on time of year?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Based on previous sales'
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'bakery-1-organize-cupcakes',
		layout: 'bakery-1-cupcakes',
		text: `Match these cakes to the correct season.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'cupcake-1', alt: 'cupcake', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'cupcake-2', alt: 'cupcake', correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'cupcake-3', alt: 'cupcake', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'cupcake-4', alt: 'cupcake', correctContainerIds: ['a4']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 4}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
				]
			})
		],
		doneConditions: [
			{type: 'empty-main-container', mainContainerId: 'initial'}
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'bakery-1-problem-solver-2', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'On to the cookies!',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-cookies',
		text: `See if you can spot the issues. Click "Done" when you think you have them all. Today's date is 4/15.`,
		layout: 'bakery-1-cookies',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'low-stack',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Right, low stock here.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'high-stack',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Yes, these are over stacked.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'wrong-placement',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Right, these shouldn't be mixed.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'wrong-order',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Yes, these are improperly rotated.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'old',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Yes, these are out of date.`
						})
					})
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's move on.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-on-every-item',
		text: `What should be on every item for sale in the department?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, isCorrect: true, text: 'Label'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'Lid'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'Sale Sticker'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'Hungry Eyes'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-1',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>Expiration</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-2',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>Item-code</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-3',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>Description</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-4',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>UPC</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-5',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>Ingredients</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcake-label-6',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'bakery-1-cupcake-label',
		text: `Click on the part of the label that shows the following information: <br><br><b>Allergens</b>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, isCorrect: true}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-label-does',
		text: `What's the last thing a label does?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Helps holding product in',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! And the seal also show they haven\'t been opened.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Keeps the food fresher',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Looks pretty',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Prevents nosy wizards from getting to it',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'wizard',
							text: 'I resent that remark.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-cupcakes',
		subtype: 'images',
		shuffleOptions: true,
		layout: 'bakery-1-cupcakes',
		text: 'Where must a label be firmly placed?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1}),// Label is on both top and bottom sealing the lid
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}),// Label only on top
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, isCorrect: true}),// label on front
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}),// Label only on side
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Let's meet a couple of customers.`,
	}),
	/* BRANCHING STORY START */
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'bakery-1-branching-story-cookies',
		maxPoints: 12,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-01',
				image: 'ba-01',
				text: `A young boy walks the around and picks up some chocolate chip cookies.`,
				nextStepId: 'ba-02',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-02',
				image: 'ba-02',
				text: `Something happens when he picks it up ...`,
				nextStepId: 'ba-03',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'ba-03',
				image: 'spilled-cookies',
				text: `What went wrong?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'Product was stacked poorly and too high', 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'No, they looked fine. Choose again.'
								})
							})
						],						
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `The child wasn't paying attention`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `This isn't the child's fault. Choose again.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: 'Clamshells not closed properly',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `I'm pretty sure this can happen even if they are. Choose again.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, text: `Labels not all the way around stuck to each other`, nextStepId: 'ba-04',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `That's right. Take the time to label correctly.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 5, text: 'Cookies stick together. This food is family',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Nope, choose again.`
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'ba-04',
				image: 'mom-unhappy-boy-upset',
				text: `Now the child is very upset, and the mother looks worried. What do you do or say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `"Ma'am it's ok, I'll clean that up."`, nextStepId: 'ba-05',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `"Don't worry little man, I'll fix it"`, nextStepId: 'ba-06',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Go get the broom and dust pan.`, nextStepId: 'ba-07',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-05',
				image: 'ba-05',
				textType: 'info',
				text: `"Thank you. We are so sorry," the mother says and leaves with the kid looking longingly at the cookies.`,
				nextStepId: 'ba-12',
				autoContinue: false,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'ba-06',
				image: 'boy-sad',
				text: `The boy looks at you and says: "But I want my cookies." What do you do or say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Give the boy some of the cookies from the floor`, nextStepId: 'ba-08',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `"You can still take them. We mopped this morning."`, nextStepId: 'ba-09',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Send them to counter for a free cookie`, nextStepId: 'ba-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, text: `Take boy to get free sample at service counter`, nextStepId: 'ba-11',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-07',
				image: 'ba-07',
				textType: 'info',
				text: `The mother leaves with the boy looking upset.`,
				nextStepId: 'ba-12'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-08',
				image: 'ba-08',
				textType: 'info',
				text: `The boy excitedly takes cookie. The mother slaps it from his hand and leaves angrily.`,
				nextStepId: 'ba-12'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-09',
				image: 'ba-08',
				textType: 'info',
				text: `"Uh, no thank you." She hurries of looking annoyed.`,
				nextStepId: 'ba-12'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-10',
				image: 'ba-10',
				textType: 'info',
				text: `They thank you and walk over to the service counter looking happy.`,
				nextStepId: 'ba-12'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-11',
				image: 'ba-11',
				textType: 'info',
				text: `They thank you and walk with you to the service counter. Good service, but you left the spill behind.`,
				nextStepId: 'ba-12'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'ba-12',
				text: `You have to clean the mess. What do you do?`,
				shuffleOptions: false,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Ask service counter employee for broom`, nextStepId: 'ba-13',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Get broom`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Don't leave the spill unsupervised. Choose again.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Stand and wait for help`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `You can't wait for that. Choose again.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, text: `Ask for help to stand, while you get broom`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `I'm ... I don't think that works at all. Choose again.`
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'ba-13',
				image: 'ba-13',
				textType: 'info',
				text: `You get help. Next time you keep those labels flat.`,
			}),
		]
	}),
	/* BRANCHING STORY END */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'bakery-1-order-get-broom-actions',
		text: `What should the person that gave you the broom do? Put actions in the right order.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Go back to department', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Throw away gloves', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Wash hands', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Get new gloves', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-all-done',
		text: `Now everything is clean and is right with the world right?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `True`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Almost, but not quite yet.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `False`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct, we still need to record these as a known loss.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		text: `Let me help you wrap this bread.`,
		characterItems: ['bread']
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-arm-out',
		text: `Make it like a candy.`,
		characterItems: ['bread-wrapped']
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'bakery-1-spot-errors-bread-wrapped',
		text: `What do you see that's wrong.`,
		layout: 'bakery-1-bread-wrapped',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'excess-plastic-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'excess-plastic-2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'label'})
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Much better.`,
		characterItems: ['bread-wrapped-correct']
	}),


	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'bakery-1-multiple-choice-bread-expiration',
		text: `This Thaw & Serve bread should have longer than 5 days expiration.`,
		image: 'wizard3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `That's only while frozen`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `You're right`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Bread doesn't go bad`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Good job today.`,
		characterItems: ['cupcake'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['cupcake-hat'],
					text: 'A Cupcake Hat has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'bak1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};