const moduleOrientation1 = [
	'modules/tasks/multiple-choice/orientation-1-apron/option-1.svg',
	'modules/tasks/multiple-choice/orientation-1-information-board/background.svg',
	'modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-1.svg',
	'modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-2.svg',
	'modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-3.svg',
	'modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-4.svg',
	'modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-5.svg',
	'modules/tasks/spot-errors/orientation-1-pick-stuff.svg',
	'modules/tasks/spot-errors/orientation-1-store-aisle.svg',
];

export {
	moduleOrientation1
};