const modulesData = [
	/* Intro */
	{
		id: 'intro',
		areaId: 'intro',
		tierId: null,
		unlockConditions: null,
		background: 'intro',
		title: 'Intro',
		description: `Introduction to Game Mechanics`,
		tasks: require('data/modules/core/intro-tasks').moduleTasks,
	},

	/* Orientation */
	{
		id: 'orientation-1',
		areaId: 'orientation',
		tierId: null,
		unlockConditions: null,
		background: 'ocean',
		title: 'Orientation',
		description: `Introduction to Stocking
			<br />&#8211; Safety
			<br />&#8211; Food Safety
			<br />&#8211; Retail Tags
			<br />&#8211; Product Rotation`,
		tasks: require('data/modules/core/orientation-1-tasks').moduleTasks,
	},

	/* Customer service 1 & 2 */
	{
		id: 'customer-service-1',
		areaId: 'customer-service',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'orientation-1'},
			{type: 'stars-in-modules', moduleIds: ['orientation-1'], stars: 1}
		],
		background: 'customer-service',
		title: 'Customer service 1',
		description: `&#8211; Interactions with Customers
			<br />&#8211; Proper Engagement Procedures`,
		tasks: require('data/modules/core/customer-service-1-tasks').moduleTasks,
	},
	{
		id: 'customer-service-2',
		areaId: 'customer-service',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'customer-service-1'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-1'], stars: 2},
		],
		background: 'customer-service',
		title: 'Customer service 2',
		description: `Deeper Dive into customer interactions and engagement procedures`,
		tasks: require('data/modules/core/customer-service-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'customer-service-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'customer-service-1'},
					{type: 'module-completed', moduleId: 'customer-service-2'},
					{type: 'stars-in-modules', moduleIds: ['customer-service-1', 'customer-service-2'], stars: 5}
				]
			}
		],
	},

	/* Center store 1 & 2 */
	{
		id: 'center-store-1',
		areaId: 'center-store',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'center-store',
		title: 'Center Store 1',
		description: `Introduction into Stocking
			<br />&#8211; Stocking
			<br />&#8211; More Safe Practices
			<br />&#8211; Holidays/Ads`,
		tasks: require('data/modules/core/center-store-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'center-store-1'},
					{type: 'stars-in-modules', moduleIds: ['center-store-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'center-store-2',
		areaId: 'center-store',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'center-store-1'},
			{type: 'stars-in-modules', moduleIds: ['center-store-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'center-store',
		title: 'Customer service 2',
		description: `Deeper Dive into customer interactions and engagement procedures`,
		tasks: require('data/modules/core/center-store-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'center-store-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'center-store-1'},
					{type: 'module-completed', moduleId: 'center-store-2'},
					{type: 'stars-in-modules', moduleIds: ['center-store-1', 'center-store-2'], stars: 5}
				]
			}
		],
	},

	/* Deli 1 & 2 */
	{
		id: 'deli-1',
		areaId: 'deli',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'deli',
		title: 'Deli 1',
		description: `Introduction into Deli operations`,
		tasks: require('data/modules/core/deli-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'deli-1'},
					{type: 'stars-in-modules', moduleIds: ['deli-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'deli-2',
		areaId: 'deli',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'deli-1'},
			{type: 'stars-in-modules', moduleIds: ['deli-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'deli',
		title: 'Deli 2',
		description: `Deeper Dive into Deli procedures and safety`,
		tasks: require('data/modules/core/deli-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'deli-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'deli-1'},
					{type: 'module-completed', moduleId: 'deli-2'},
					{type: 'stars-in-modules', moduleIds: ['deli-1', 'deli-2'], stars: 5}
				]
			}
		],
	},

	/* Floral 1 & 2 */
	{
		id: 'floral-1',
		areaId: 'floral',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'floral',
		title: 'Floral 1',
		description: `Introduction into Basic Floral Care`,
		tasks: require('data/modules/core/floral-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'floral-1'},
					{type: 'stars-in-modules', moduleIds: ['floral-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'floral-2',
		areaId: 'floral',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'floral-1'},
			{type: 'stars-in-modules', moduleIds: ['floral-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'floral',
		title: 'Floral 2',
		description: `Deeper Dive into Floral Care`,
		tasks: require('data/modules/core/floral-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'meat-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'floral-1'},
					{type: 'module-completed', moduleId: 'floral-2'},
					{type: 'stars-in-modules', moduleIds: ['floral-1', 'floral-2'], stars: 5}
				]
			}
		],
	},

	/* Meat 1 & 2 */
	{
		id: 'meat-1',
		areaId: 'meat',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'meat',
		title: 'Meat 1',
		description: `Introduction into Meat operations`,
		tasks: require('data/modules/core/meat-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'meat-1'},
					{type: 'stars-in-modules', moduleIds: ['meat-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'meat-2',
		areaId: 'meat',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'meat-1'},
			{type: 'stars-in-modules', moduleIds: ['meat-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'meat',
		title: 'Meat 2',
		description: `Deeper Dive into Meat procedures and operations`,
		tasks: require('data/modules/core/meat-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'meat-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'meat-1'},
					{type: 'module-completed', moduleId: 'meat-2'},
					{type: 'stars-in-modules', moduleIds: ['meat-1', 'meat-2'], stars: 5}
				]
			}
		],
	}, 

	/* Seafood 1 & 2 */
	{
		id: 'seafood-1',
		areaId: 'seafood',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'seafood',
		title: 'Seafood 1',
		description: `Introduction into Seafood operations`,
		tasks: require('data/modules/core/seafood-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'seafood-1'},
					{type: 'stars-in-modules', moduleIds: ['seafood-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'seafood-2',
		areaId: 'seafood',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'seafood-1'},
			{type: 'stars-in-modules', moduleIds: ['seafood-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'seafood',
		title: 'Seafood 2',
		description: `Deeper Dive into Seafood procedures and operations`,
		tasks: require('data/modules/core/seafood-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'seafood-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'seafood-1'},
					{type: 'module-completed', moduleId: 'seafood-2'},
					{type: 'stars-in-modules', moduleIds: ['seafood-1', 'seafood-2'], stars: 5}
				]
			}
		],
	}, 

	/* Produce 1 & 2 */
	{
		id: 'produce-1',
		areaId: 'produce',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'produce',
		title: 'Produce 1',
		description: `Introduction into Produce identification and culling`,
		tasks: require('data/modules/core/produce-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'produce-1'},
					{type: 'stars-in-modules', moduleIds: ['produce-1'], stars: 2}
				],
			},
		]
	}, 
	{
		id: 'produce-2',
		areaId: 'produce',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'produce-1'},
			{type: 'stars-in-modules', moduleIds: ['produce-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'produce',
		title: 'Produce 2',
		description: `Deeper Dive into Produce procedures and operations`,
		tasks: require('data/modules/core/produce-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'produce-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'produce-1'},
					{type: 'module-completed', moduleId: 'produce-2'},
					{type: 'stars-in-modules', moduleIds: ['produce-1', 'produce-2'], stars: 5}
				]
			}
		],
	}, 

	/* Bakery 1 & 2 */
	{
		id: 'bakery-1',
		areaId: 'bakery',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'customer-service-2'},
			{type: 'stars-in-modules', moduleIds: ['customer-service-2'], stars: 2}
		],
		background: 'bakery',
		title: 'Bakery 1',
		description: `Introduction into Bakery operations`,
		tasks: require('data/modules/core/bakery-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'area',
				areaId: 'side-quest',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'bakery-1'},
					{type: 'stars-in-modules', moduleIds: ['bakery-1'], stars: 2}
				],
			},
		]
	},
	{
		id: 'bakery-2',
		areaId: 'bakery',
		tierId: 'tier-2',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'bakery-1'},
			{type: 'stars-in-modules', moduleIds: ['bakery-1'], stars: 2},
			{type: 'module-completed', moduleId: 'shrink-1'},
			{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
		],
		background: 'bakery',
		title: 'Bakery 2',
		description: `Deeper Dive into Bakery procedures and operations`,
		tasks: require('data/modules/core/bakery-2-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'bakery-2',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'bakery-1'},
					{type: 'module-completed', moduleId: 'bakery-2'},
					{type: 'stars-in-modules', moduleIds: ['bakery-1', 'bakery-2'], stars: 5}
				]
			}
		],
	}, 

	/* Side quest modules */
	{
		id: 'safety-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions: null,
		background: 'ocean-raft',
		title: 'Safety',
		areaTitle: 'Safety',
		tasks: require('data/modules/sides/safety-1-tasks').moduleTasks
	},
	{
		id: 'food-safety-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'safety-1'},
			{type: 'stars-in-modules', moduleIds: ['safety-1'], stars: 2},
		],
		background: 'ocean-raft',
		title: 'Food safety',
		areaTitle: 'Food safety',
		description: ``,
		tasks: require('data/modules/sides/food-safety-1-tasks').moduleTasks
	},
	{
		id: 'daily-guidelines-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'food-safety-1'},
			{type: 'stars-in-modules', moduleIds: ['food-safety-1'], stars: 2},
		],
		background: 'ocean-raft',
		title: 'Daily Guidelines',
		areaTitle: 'Daily Guidelines',
		description: ``,
		tasks: require('data/modules/sides/daily-guidelines-1').moduleTasks,
	},
	{
		id: 'inventory-management-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'daily-guidelines-1'},
			{type: 'stars-in-modules', moduleIds: ['daily-guidelines-1'], stars: 2},
		],
		background: 'ocean-raft',
		title: 'Inventory Management',
		areaTitle: 'Inventory Management',
		tasks: require('data/modules/sides/inventory-management-1-tasks').moduleTasks
	},
	{
		id: 'cooler-freezer-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'inventory-management-1'},
			{type: 'stars-in-modules', moduleIds: ['inventory-management-1'], stars: 2},
		],
		background: 'ocean-raft-ice',
		title: 'Cooler/Freezer',
		areaTitle: 'Cooler/Freezer',
		description: ``,
		tasks: require('data/modules/sides/cooler-freezer-1').moduleTasks,
	},
	{
		id: 'cleaning-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'cooler-freezer-1'},
			{type: 'stars-in-modules', moduleIds: ['cooler-freezer-1'], stars: 2},
		],
		background: 'ocean-raft-ice',
		title: 'Cleaning',
		areaTitle: 'Cleaning',
		description: ``,
		tasks: require('data/modules/sides/cleaning-1').moduleTasks,
	},
	{
		id: 'backroom-5-s',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions:  [
			{type: 'module-completed', moduleId: 'cleaning-1'},
			{type: 'stars-in-modules', moduleIds: ['cleaning-1'], stars: 2},
		],
		background: 'ocean-raft',
		title: '5S',
		areaTitle: '5S',
		tasks: require('data/modules/sides/backroom-5-s-tasks').moduleTasks
	},
	{
		id: 'shrink-1',
		areaId: 'side-quest',
		tierId: 'tier-1',
		unlockConditions: [
			{type: 'module-completed', moduleId: 'backroom-5-s'},
			{type: 'stars-in-modules', moduleIds: ['backroom-5-s'], stars: 2},
		],
		background: 'ocean-raft',
		title: 'Shrink 1',
		areaTitle: 'Shrink 1',
		description: ``,
		tasks: require('data/modules/sides/shrink-1-tasks').moduleTasks,
		sideQuests: [
			{
				type: 'loot-box',
				lootBoxId: 'side-quests-1',
				unlockConditions: [
					{type: 'module-completed', moduleId: 'shrink-1'},
					{type: 'stars-in-modules', moduleIds: ['shrink-1'], stars: 2}
				]
			}
		],
	},
];

const moduleTiers = [
	{
		id: 'tier-1',
		title: 'Level 1 - Basics',
		text: 'Unlocked by completing Orientation',
		unlockedByModuleId: 'orientation-1',
	},
	{
		id: 'tier-2',
		title: 'Level 2 - Deep dives',
		text: 'Unlocked by completing Customer Service',
		unlockedByModuleId: 'customer-service-1',
	},
	{
		id: 'tier-3',
		title: 'Level 3 - Advanced',
		text: 'Unlocked by completing Bagger',
	},
	{
		id: 'tier-4',
		title: 'Level 4 – Advanced 2',
		text: 'Coming soon.'
	}
]

export {
	modulesData,
	moduleTiers
};
