const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper',
		animateCharacter: true,
		background: 'ocean-raft',
		showAvatar: true,
		text: `Welcome!<br/>Let's start by getting ready for safety.`,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'safety-1-paper-doll',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'whistle',
				isCorrect: false,
				title: 'Whistle',
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'safety-vest',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Safety Vest'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'life-jacket',
				type: 'alternative',
				isCorrect: false,
				dependsOnBodyHeight: true,
				dependsOnBodyWidth: true,
				title: 'Life Jacket'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'goggles',
				isCorrect: false,
				dependsOnBodyHeight: true,
				title: 'Goggles'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hard-hat',
				isCorrect: false,
				dependsOnBodyHeight: true,
				title: 'Hard hat with light'
			}),
			/*
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'bandages',
				isCorrect: true,
				title: 'Bandages'
			}),
			*/
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'wet-floor-sign',
				isCorrect: true,
				title: 'Wet floor sign'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'lucky-clover',
				isCorrect: false,
				title: 'Lucky clover'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'safety-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper',
		background: 'ocean-raft',
		extraCharacters: [{id: 'parrot', type: 'ocean-rock'}],
		text: `With these rough waters, you might need it all.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper-parrot',
		speechBubbleDirection: 'middle',
		background: 'ocean-raft',
		text: `<i>Rawt!!! Yes indeedy.</i>`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-you-sick',
		isDilemma: true,
		image: 'skipper',
		numberOfAnswersToSelect: 1,
		maxPoints: 0,
		text: `First thing first. You sick?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'No',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Yes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'skipper',
							text: 'Well. You can\'t be here. Go home. Or, keep playing, but in real life you should go home.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper',
		background: 'ocean-raft',
		text: `Second thing. Stretch. We move alot in this store. Limber up.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'skipper',
		background: 'ocean-raft',
		text: `Raise your arms up high and count to 10. We'll wait.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		subtype: 'countdown',
		shownDelay: 10,
		delayTime: 5,
		characterPose: 2,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `<i>Seriously. Do it.</i>`,
		speechBubbleDirection: 'middle',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		characterType: 'skipper',
		background: 'ocean-raft',
		text: `Good job, Cap'n. Now below for the toes.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		subtype: 'countdown',
		shownDelay: 10,
		delayTime: 5,
		characterPose: 3,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `<i>I'm watching.</i>`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `<i>Ok repeat now 10 more times.</i>`,
		speechBubbleDirection: 'middle',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `Whoa little buddy, I'm gettin' sea sick. Let's move on.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `3rd, Let's get the area safe.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'safety-1-spot-errors-boat',
		layout: 'safety-1-boat',
		text: 'Click on the safety issues you can see. Click ok when you think you have them all.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash-paper'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dead-fish'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'water'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'open-cans'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-oars'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `Good job the boat is safe to work in.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		extraCharacters: [{id: 'skipper-1', type: 'right'}],
		text: `What about the store?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'skipper-1', type: 'right'}],
		text: `Ok, there too.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'safety-1-spot-errors-desk',
		layout: 'safety-1-desk',
		text: 'Click on the safety issues you can see. Click "Done" when you think you have them all.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'puddle'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'scissors-in-cup'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'computer-on-fire'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-glass'}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'safety-1-organize-gloves',
		layout: 'safety-1-gloves',
		text: `We have gloves for everything. See if you can organize them.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, 
				containerId: 'c1',
				image: 'gloves-1',
				alt: 'Heat resistant gloves',
				correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, 
				containerId: 'c2', 
				image: 'gloves-2', 
				alt: 'Cut proof gloves',
				correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, 
				containerId: 'c3', 
				image: 'gloves-3', 
				alt: 'Cleaning gloves',
				correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 4, 
				containerId: 'c4', 
				image: 'gloves-4', 
				alt: 'Single use gloves',
				correctContainerIds: ['a4']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 5, 
				containerId: 'c5', 
				image: 'gloves-5', 
				alt: 'Baseball mit',
				correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 6, 
				containerId: 'c6', 
				image: 'gloves-6', 
				alt: 'Foam finger',
				correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 7, 
				containerId: 'c7', 
				image: 'gloves-7',
				alt: 'Biker glove',
				correctContainerIds: ['b1', 'b2', 'b3']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'final', finalItemId: 4}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'remove',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'remove', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'remove', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'remove', finalItemId: 7}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'})
				]
			}),
		],
		doneConditions: [
			Object.assign({}, taskTemplates.organizeTemplate.doneConditionTemplate, {
				type: 'empty-main-container', mainContainerId: 'initial'
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'safety-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'safety-1-order-cleanup',
		text: 'What steps do we need to get the puddle cleaned up?',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Have someone guard it', correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Get equipment', correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Place Sign', correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Mop floor', correctOrderNumbers: [4],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		text: `Great job getting someone to watch over the spill.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-raining',
		text: `If it was raining out, where do we need special attention?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Front door',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, customers will track in water'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Freezer',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Cooler',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Check out lanes',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		text: `Let's talk lifting?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		speechBubbleDirection: 'right',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
		text: `Ya, how much you bench?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		characterType: 'manager',
		background: 'ocean-raft',
		extraCharacters: [{id: 'wizard-1', type: 'right'}],
		text: `Will you stop it?`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-picking-up-box',
		image: 'heavy-box',
		text: `Would there be any trouble picking up this box?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Yes, it\'s too heavy',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'No',
				isCorrect: false,
				effects: [	
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There should be. This is far to heavy for you to lift on your own. '
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-what-to-do',
		text: `So what do we do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Ask for help',
				isCorrect: true,
				effects: [	
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly, never risk yourself.'
						})
					})
				]
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Refuse',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Leave it',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Get forklift',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-pick-up-box',
		subtype: 'images',
		layout: 'safety-1-pick-up-box',
		text: 'What\'s the safest place to pick up a box?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-move-box',
		subtype: 'images',
		layout: 'safety-1-move-box',
		text: 'How do we move a box across the sales floor?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Can you see?'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You\'re gonna dropit.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		characterType: 'manager',
		background: 'ocean-raft',
		showAvatar: true,
		avatarItems: ['box-1'],
		text: `What if a customer needed something while carrying?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'old-lady',
		background: 'ocean-raft',
		showAvatar: true,
		avatarItems: ['box-1'],
		text: `Can you point me to the Deli?`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-made-better',
		text: `What would have made this better?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'A U-boat',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Use a U-Boat when on the floor.'
						})
					})
				]
			}),	
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Smaller box',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Another team member',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: 'Third arm',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'safety-1-spot-bodyparts',
		layout: 'safety-1-bodyparts',
		text: 'What parts of the body do we lift with? Click on the two best parts to use, then click done.',
		resultOverwrites: {
			result1: {
				text: 'You only spotted %spotted% of %total% of the correct areas.'
			},
			result2: {
				text: 'You spotted %spotted% of %total% of the correct areas.',
			},
			result3: {
				text: 'You spotted %spotted% of %total% of the correct areas.',
			}
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'upper-legs'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'lower-legs'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-multiple-choice-how-to-turn',
		subtype: 'images',
		layout: 'safety-1-how-to-turn',
		text: 'What\'s the proper way to turn?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Holding box, show turning feet
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Holding box, show turning at upper half only
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'safety-1-mc-high-five',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'high-five',
		text: 'So far so easy right?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Oh ya!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You missed.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 4,
		characterType: 'skipper-parrot',
		background: 'ocean-raft',
		text: `Great work Cap'n. This here parrot's name is Mopin Buckit, and it's yours now.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'parrot',
					text: 'A parrot has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'sft1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};