import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorSubpagesData} from 'data/facilitator-data';
import {renderMarkdown} from 'helpers/text-helper';
import './facilitator-header-subpage.scss';

const FacilitatorHeaderSubpage = ({page, subpage, subpageIds, handleGoToPage, titleInsert}) => {
	const subpageData = facilitatorSubpagesData.find((s) => {return s.id === subpage;});

	let description = subpageData.description2;
	if (titleInsert) {
		description = description.replace('%REPLACE%', titleInsert);
	} 

	return (
		<div className={'FacilitatorHeaderSubpage ' + page}>
			<div className="FacilitatorHeaderSubpage-menu">
				{subpageIds.map((subpageId) => {
					const subpageData = facilitatorSubpagesData.find((s) => {return s.id === subpageId;});
					return (
						<div 
							key={subpageData.id}
							className={'FacilitatorHeaderSubpage-menuItem' + 
								(subpageData.id === subpage ? ' selected' : '')}
							onClick={() => {handleGoToPage(page, subpageData.id);}}
						><span>{subpageData.title}</span></div>
					);
				})}
			</div>
			<div className="FacilitatorHeaderSubpage-description">
				<div className={'FacilitatorHeaderSubpage-descriptionIcon ' + subpage} />
				<div className="FacilitatorHeaderSubpage-descriptionText">
					{renderMarkdown(description)}
				</div>
			</div>
		</div>
	);

};

FacilitatorHeaderSubpage.propTypes = {
	page: PropTypes.string.isRequired,
	subpage: PropTypes.string.isRequired,
	subpageIds: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	titleInsert: PropTypes.string,
};

export default FacilitatorHeaderSubpage;
