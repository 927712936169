import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import {getPlayerItems} from 'helpers/module-helper';
import Splash from 'components/game/splash/splash';
import Areas from 'components/game/areas/areas';
import AreaMap from 'components/game/area-map/area-map';
import Profile from 'components/game/profile/profile';
import AvatarMaker from 'components/game/avatar-maker/avatar-maker';
import ModuleController from 'components/game/module/module-controller';
import Popup from 'components/ui/popup/popup';
import './game.scss';

const Game = (props) => {
	const {
		deviceInfo, 
		userData, 
		playerGameData, 
		gameData,
		updatePlayerGameData, 
		scrollToTop, 
		resetPlayerGameData, 
		handleLogout,
		reportPlayer
	} = props;

	/* Navigation */
	const [navigation, setNavigation] = useState({page: 'splash', areaId: null, moduleId: null, competitionId: null});
	const [navigationHistory, setNavigationHistory] = useState({page: null, areaId: null, moduleId: null});

	/* Popup */
	const [popupData, setPopupData] = useState(null);

	/* Last area index */
	const [lastAreaIndex, setLastAreaIndex] = useState(null);

	/**
	 * Go to a new page
	 * @param {string} page 
	 */
	const handleGoToPage = (page, moduleId = null, areaId = null, competitionId = null) => {
		/* When navigating from map/module to profile, track where you came from */
		if (page !== 'avatar' && navigation.page !== 'avatar') {
			setNavigationHistory({page: navigation.page, moduleId: navigation.moduleId, areaId: navigation.areaId});
		}
		
		/* Go to new page and auto-scroll to top */
		setNavigation({page, moduleId, areaId, competitionId});
		scrollToTop();
	};

	/* Go to prev page */
	const handleGoToPrevPage = () => {
		if (navigationHistory.page) {
			setNavigation({
				page: navigationHistory.page, 
				moduleId: navigationHistory.moduleId, 
				areaId: navigationHistory.areaId,
				competitionId: null
			});
			if (navigationHistory.page !== 'area-map') {
				scrollToTop();
			}
		}
	};

	/**
	 * Player opens a loot box, reserve content until they actually pick it.
	 * This prevents cheating by repeatedly opening and closing loot boxes,
	 * until you get what you want.
	 * 
	 */
	const reserveLootBoxOptions = (options) => {
		return updatePlayerGameData({reservedLootBoxOptions: options});	
	};

	/**
	 * Get item
	 * @param {string} itemId 
	 * @param {bool} resetReserved
	 */
	const getLootBoxItem = (itemData, resetReserved = false) => {
		/* Update player data */
		let playerItems = getPlayerItems(playerGameData);
		playerItems.push({...itemData, isSeen: false});

		if (resetReserved) {
			return updatePlayerGameData({items: playerItems, reservedLootBoxOptions: []});	
		}
		
		return updatePlayerGameData({items: playerItems});
	};

	/**
	 * Toggle popup
	 */
	const togglePopup = (popupData = null) => {
		setPopupData(popupData);
	};


	/* Get page component */
	let PageComponent = Splash;
	let pageId = 'pageComponent';
	if (navigation.page === 'areas') PageComponent = Areas;
	if (navigation.page === 'area-map') PageComponent = AreaMap;
	if (navigation.page === 'profile') PageComponent = Profile;
	if (navigation.page === 'avatar') PageComponent = AvatarMaker;
	if (navigation.page === 'module') {
		PageComponent = ModuleController;
		pageId = navigation.moduleId;
	};

	return (
		<div className={'Game ' + navigation.page}>
			<DndProvider options={HTML5toTouch}>
				<PageComponent 
					key={pageId}
					deviceInfo={deviceInfo}
					navigationHistory={navigationHistory}
					gameData={gameData}
					userData={userData}
					moduleId={navigation.moduleId}
					areaId={navigation.areaId}
					competitionId={navigation.competitionId}
					playerData={playerGameData}
					handleGoToPage={handleGoToPage}
					handleGoToPrevPage={handleGoToPrevPage}
					reserveLootBoxOptions={reserveLootBoxOptions}
					getLootBoxItem={getLootBoxItem}
					updatePlayerGameData={updatePlayerGameData}
					resetPlayerGameData={resetPlayerGameData}
					scrollToTop={scrollToTop}
					togglePopup={togglePopup}
					handleLogout={handleLogout}
					setLastAreaIndex={setLastAreaIndex}
					lastAreaIndex={lastAreaIndex}
					reportPlayer={reportPlayer}
				/>
				{popupData && 
					<Popup
						type={popupData.type}
						title={popupData.title}
						text={popupData.text}
						itemId={(popupData.itemId ? popupData.itemId : null)}
						togglePopup={togglePopup}
					/>
				}
			</DndProvider>
		</div>
	);

};

Game.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	playerGameData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	reportPlayer: PropTypes.func.isRequired,
};

export default Game;
