import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Manager from 'components/ui/manager/manager';
import './task-intro.scss';

const TaskIntro = (props) => {
	const {
		showNumberOfAnswersToSelect, 
		numberOfCorrectAnswers, 
		text, 
		image, 
		showManager, 
		managerId, 
		managerPose
	} = props;

	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				{text && renderMarkdown(text)}
				{showRequiredNumberOfAnswers && <span>{gameUiTexts.choose} {numberOfCorrectAnswers}:</span>}
			</div>
			{(image && !showManager) && <div className="TaskIntro-image" />}
			{showManager && 
				<Manager 
					page={'taskIntro' + (image ? ' ' + image : '')}
					managerPose={(managerPose ? managerPose : 'manager-1')}
					managerId={managerId}
				/>
			}
		</div>
	);
};

TaskIntro.propTypes = {
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string,
	showManager: PropTypes.bool,
	managerId: PropTypes.string,
	managerPose: PropTypes.string,
};

export default TaskIntro;
