import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './facilitator-delete-message-popup.scss';

const FacilitatorDeleteMessagePopup = ({messageData, setDeleteMessageData, deleteMessage}) => {
	

	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	
	/**
	 * Handle delete message
	 */
	const handleDeleteMessage = () => {
		if (isLoading) return; 
	
		setErrorMsg(null);
		setIsLoading(true);
			
		deleteMessage(messageData.id).then((response) => {
			if (response.status === 'success') {
				setDeleteMessageData(null);
			} else {
				console.error(response);
				setIsLoading(false);
			}
		});
	};


	return (
		<div className="FacilitatorDeleteMessagePopup">
			<div className="FacilitatorDeleteMessagePopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorDeleteMessagePopup-header">
					<div className="FacilitatorDeleteMessagePopup-title">
						<span>{facilitatorUiTexts.deleteMessagePopup.title}</span>
					</div>		
					<div
						className="FacilitatorDeleteMessagePopup-closeBtn" 
						onClick={() => {setDeleteMessageData(null);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorDeleteMessagePopup-body'>
					<div className="FacilitatorDeleteMessagePopup-text">
						{renderMarkdown(facilitatorUiTexts.deleteMessagePopup.text)}
					</div>
					<div className="FacilitatorDeleteMessagePopup-message">
						<div className="FacilitatorDeleteMessagePopup-messageInfo">
							<span>{facilitatorUiTexts.deleteMessagePopup.createdBy}: </span>
							<span>{messageData.createdByName}</span>
						</div>
						<div className="FacilitatorDeleteMessagePopup-messageInfo">
							<span>{facilitatorUiTexts.deleteMessagePopup.createdOn}: </span>
							<span>{messageData.created && dayjs(messageData.created).format('MM/DD/YYYY')}</span>
						</div>
								
					</div>
					<div className="FacilitatorDeleteMessagePopup-feedback error">
						{errorMsg && <span>{errorMsg}</span>}
					</div>
					<div className="FacilitatorDeleteMessagePopup-buttons">
						<div className="FacilitatorDeleteMessagePopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.deleteMessagePopup.confirmBtn}
								onClick={() => {handleDeleteMessage();}}
							/>
						</div>
						<div className="FacilitatorDeleteMessagePopup-cancelBtn">
							<Button 
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.deleteMessagePopup.cancelBtn}
								onClick={() => {setDeleteMessageData(null);}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorDeleteMessagePopup.propTypes = {
	messageData: PropTypes.object.isRequired,
	setDeleteMessageData: PropTypes.func.isRequired,
	deleteMessage: PropTypes.func.isRequired,
};

export default FacilitatorDeleteMessagePopup;
