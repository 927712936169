/**
 * Get game url from full url
 * @param {string} pathname 
 * @returns {string} gameUrl
 */
export function getGameUrl(pathname) {
	
	let gameUrl = null;

	/* Get url path (remove starting/trailing '/', convert to lowercase) */
	if (pathname.length > 1) {
		gameUrl = pathname.slice(1).toLowerCase();
		if (gameUrl.charAt(gameUrl.length - 1) === '/') gameUrl = gameUrl.slice(0, gameUrl.length - 1);
	}

	return gameUrl;
}


/**
 * Get current time
 * @param {string} startTime
 * @param {number} minutesPassed 
 * @returns 
 */
export function getCurrentTime(startTime, minutesPassed) {
	let currentTime = '0:00 a.m.';
	
	if (startTime.hasOwnProperty('hours') && startTime.hasOwnProperty('minutes')) {
		const startTimeTotalMinutes = startTime.hours * 60 + startTime.minutes;
		let currentTimeTotalMinutes = startTimeTotalMinutes + minutesPassed;
		
		/* Remove days */
		if (currentTimeTotalMinutes > 24 * 60) {
			currentTimeTotalMinutes -= Math.floor(currentTimeTotalMinutes / (24 * 60)) * (24 * 60);
		}

		/* Get hours and minutes */
		let currentTimeH = Math.floor(currentTimeTotalMinutes / 60);
		const currentTimeM = currentTimeTotalMinutes - 60 * Math.floor(currentTimeTotalMinutes / 60);

		/* Get period */
		const currentPeriod = (currentTimeH >= 12 && currentTimeH <= 23 ? 'p.m.' : 'a.m.');
		if (currentTimeH === 24) {
			currentTimeH = 0;
		} else if (currentTimeH > 12 ) {
			currentTimeH = currentTimeH - 12;
		}

		/* Format time */
		currentTime = currentTimeH + ':' + (currentTimeM < 10 ? '0' : '') + currentTimeM + ' ' + currentPeriod;
	}

	return currentTime;
};


/**
 * Get all players of a specific game
 * @param {string} gameId 
 * @param {array} usersData 
 * @returns 
 */
export function getPlayersOfGame(gameId, usersData) {
	const gamePlayers = [];
	usersData.forEach((user) => {
		if (
			user.role === 'player' &&
			user.games &&
			user.games.length > 0 &&
			user.games[0].id === gameId &&
			!user.games[0].isRemoved
		) {
			gamePlayers.push({
				...user.games[0], 
				id: user.id, 
				gameId: user.games[0].id,
				name: user.name,
				email: user.email,
				lastLogin: user.lastLogin,
				yearbookReports: user.yearbookReports ? user.yearbookReports : []
			});
		}
	});

	return gamePlayers;
};