const moduleTaskIntros = [
	'modules/tasks/task-intro/apples.svg',
	'modules/tasks/task-intro/avatar.svg',
	'modules/tasks/task-intro/bag-lunch.svg',
	'modules/tasks/task-intro/balloons.svg',
	'modules/tasks/task-intro/bell-pepper.svg',
	'modules/tasks/task-intro/berries.svg',
	'modules/tasks/task-intro/bin-of-watermelons.svg',
	'modules/tasks/task-intro/bob.svg',
	'modules/tasks/task-intro/bouqet-vase.svg',
	'modules/tasks/task-intro/bucket.svg',
	'modules/tasks/task-intro/bucket2.svg',
	'modules/tasks/task-intro/cake-excessive-icing.svg',
	'modules/tasks/task-intro/calendar.svg',
	'modules/tasks/task-intro/calendar2.svg',
	'modules/tasks/task-intro/calendar3.svg',
	'modules/tasks/task-intro/cans.svg',
	'modules/tasks/task-intro/case-no-date.svg',
	'modules/tasks/task-intro/chart.svg',
	'modules/tasks/task-intro/check.svg',
	'modules/tasks/task-intro/check2.svg',
	'modules/tasks/task-intro/checklist.svg',
	'modules/tasks/task-intro/checklist2.svg',
	'modules/tasks/task-intro/checklist3.svg',
	'modules/tasks/task-intro/checklist4.svg',
	'modules/tasks/task-intro/checklist5.svg',
	'modules/tasks/task-intro/checklist-weekly.svg',
	'modules/tasks/task-intro/chef.svg',
	'modules/tasks/task-intro/clam-oyster-mussel.svg',
	'modules/tasks/task-intro/clean-schedule-1.svg',
	'modules/tasks/task-intro/clean-schedule-2.svg',
	'modules/tasks/task-intro/clock.svg',
	'modules/tasks/task-intro/cooler.svg',
	'modules/tasks/task-intro/corn-cob.svg',
	'modules/tasks/task-intro/crumbs.svg',
	'modules/tasks/task-intro/cup-water.svg',
	'modules/tasks/task-intro/customer-hungry.svg',
	'modules/tasks/task-intro/customer-line.svg',
	'modules/tasks/task-intro/customer.svg',
	'modules/tasks/task-intro/customer2.svg',
	'modules/tasks/task-intro/deli-desk.svg',
	'modules/tasks/task-intro/deli-shelves.svg',
	'modules/tasks/task-intro/fake-id.svg',
	'modules/tasks/task-intro/fire-extinguisher.svg',
	'modules/tasks/task-intro/first.svg',
	'modules/tasks/task-intro/fish.svg',
	'modules/tasks/task-intro/flower1.svg',
	'modules/tasks/task-intro/flower2.svg',
	'modules/tasks/task-intro/flower3.svg',
	'modules/tasks/task-intro/flower4.svg',
	'modules/tasks/task-intro/frosted-meat.svg',
	'modules/tasks/task-intro/frying-pan-and-veg-oil.svg',
	'modules/tasks/task-intro/haricot-steamed.svg',
	'modules/tasks/task-intro/hearts.svg',
	'modules/tasks/task-intro/heavy-box.svg',
	'modules/tasks/task-intro/issue.svg',
	'modules/tasks/task-intro/lettuce.svg',
	'modules/tasks/task-intro/linda.svg',
	'modules/tasks/task-intro/lisa.svg',
	'modules/tasks/task-intro/magnifying-glass.svg',
	'modules/tasks/task-intro/marshmallows.svg',
	'modules/tasks/task-intro/meat-cooler.svg',
	'modules/tasks/task-intro/melon.svg',
	'modules/tasks/task-intro/milk-and-bleach.svg',
	'modules/tasks/task-intro/mystery-fruit-revealed.svg',
	'modules/tasks/task-intro/mystery-fruit.svg',
	'modules/tasks/task-intro/new-items.svg',
	'modules/tasks/task-intro/orange1.svg',
	'modules/tasks/task-intro/orange2.svg',
	'modules/tasks/task-intro/orange3.svg',
	'modules/tasks/task-intro/order-history.svg',
	'modules/tasks/task-intro/paragraph.svg',
	'modules/tasks/task-intro/pear.svg',
	'modules/tasks/task-intro/peppers.svg',
	'modules/tasks/task-intro/percent.svg',
	'modules/tasks/task-intro/percent2.svg',
	'modules/tasks/task-intro/phone.svg',
	'modules/tasks/task-intro/pinchy-ones.svg',
	'modules/tasks/task-intro/plant1.svg',
	'modules/tasks/task-intro/plant2.svg',
	'modules/tasks/task-intro/plant3.svg',
	'modules/tasks/task-intro/plant4.svg',
	'modules/tasks/task-intro/plant5.svg',
	'modules/tasks/task-intro/potato.svg',
	'modules/tasks/task-intro/potato-green.svg',
	'modules/tasks/task-intro/prep-area.svg',
	'modules/tasks/task-intro/produce-table.svg',
	'modules/tasks/task-intro/products.svg',
	'modules/tasks/task-intro/products2.svg',
	'modules/tasks/task-intro/puddle.svg',
	'modules/tasks/task-intro/register.svg',
	'modules/tasks/task-intro/roses-bad.svg',
	'modules/tasks/task-intro/rotten-chicken.svg',
	'modules/tasks/task-intro/ruler.svg',
	'modules/tasks/task-intro/salad-bar.svg',
	'modules/tasks/task-intro/salad-spill.svg',
	'modules/tasks/task-intro/salad.svg',
	'modules/tasks/task-intro/sale.svg',
	'modules/tasks/task-intro/sale2.svg',
	'modules/tasks/task-intro/sandwich-expired.svg',
	'modules/tasks/task-intro/sandwich-old.svg',
	'modules/tasks/task-intro/sandwich.svg',
	'modules/tasks/task-intro/service-cake-case.svg',
	'modules/tasks/task-intro/shelf.svg',
	'modules/tasks/task-intro/shelves.svg',
	'modules/tasks/task-intro/shelves2.svg',
	'modules/tasks/task-intro/sink.svg',
	'modules/tasks/task-intro/skipper.svg',
	'modules/tasks/task-intro/soup.svg',
	'modules/tasks/task-intro/steak.svg',
	'modules/tasks/task-intro/supply-closet.svg',
	'modules/tasks/task-intro/table-cookies-full.svg',
	'modules/tasks/task-intro/thermometer.svg',
	'modules/tasks/task-intro/thermometer2.svg',
	'modules/tasks/task-intro/three-bay-sink.svg',
	'modules/tasks/task-intro/u-boat-boxes.svg',
	'modules/tasks/task-intro/vegetables.svg',
	'modules/tasks/task-intro/wet-floor-sign.svg',
	'modules/tasks/task-intro/wic-tag.svg',
	'modules/tasks/task-intro/wing-bar.svg',
	'modules/tasks/task-intro/wizard-child.svg',
	'modules/tasks/task-intro/wizard.svg',
];

export {
	moduleTaskIntros
};
