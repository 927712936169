const moduleDeli1 = [
	'modules/tasks/organize/deli-1-salad-bar/carrot.svg',
	'modules/tasks/organize/deli-1-salad-bar/champignon-mushroom.svg',
	'modules/tasks/organize/deli-1-salad-bar/container-bg.svg',
	'modules/tasks/organize/deli-1-salad-bar/containers-bg.svg',
	'modules/tasks/organize/deli-1-salad-bar/corn.svg',
	'modules/tasks/organize/deli-1-salad-bar/cucumber.svg',
	'modules/tasks/organize/deli-1-salad-bar/green-salad.svg',
	'modules/tasks/organize/deli-1-salad-bar/item-depth.svg',
	'modules/tasks/organize/deli-1-salad-bar/item-empty.svg',
	'modules/tasks/organize/deli-1-salad-bar/onion.svg',
	'modules/tasks/organize/deli-1-salad-bar/red-bell-pepper.svg',
	'modules/tasks/organize/deli-1-salad-bar/tomato.svg',

	'modules/tasks/multiple-choice/deli-1-stack-high-limit/option-1.svg',
	'modules/tasks/multiple-choice/deli-1-stack-high-limit/option-2.svg',
	'modules/tasks/multiple-choice/deli-1-stack-high-limit/option-3.svg',
	'modules/tasks/multiple-choice/deli-1-stack-high-limit/option-4.svg',
	'modules/tasks/multiple-choice/deli-1-stack-low-limit/option-1.svg',
	'modules/tasks/multiple-choice/deli-1-stack-low-limit/option-2.svg',
	'modules/tasks/multiple-choice/deli-1-stack-low-limit/option-3.svg',
	'modules/tasks/multiple-choice/deli-1-stack-low-limit/option-4.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-cold/option-1.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-cold/option-2.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-cold/option-3.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-cold/option-4.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-hot/option-1.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-hot/option-2.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-hot/option-3.svg',
	'modules/tasks/multiple-choice/deli-1-temperatures-hot/option-4.svg',

	'modules/tasks/spot-errors/deli-1-deli-counter.svg',
	'modules/tasks/spot-errors/deli-1-deli-case.svg',
	'modules/tasks/spot-errors/deli-1-store.svg',
	'modules/tasks/spot-errors/deli-1-chicken-case.svg',
	'modules/tasks/spot-errors/deli-1-sandwiches.svg',
];

export {
	moduleDeli1
};
