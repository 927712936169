const itemsData = [
	{
		id: 'bunny',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the bunny and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'canary',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the canary and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'cat',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the cat and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'dog',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the dog and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'fish',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the fish and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'hamster',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the hamster and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'hedgehog',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the hedgehog and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'lizard', 
		type: 'animal', 
		locationIds: ['loot-box'],
		text: `You pick up the lizard and take care of it.
			<br /><br />Go to your profile to add it to your avatar.`,
	},
	{
		id: 'snake',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the snake and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
	{
		id: 'turtle',
		type: 'animal',
		locationIds: ['loot-box'],
		text: `You pick up the turtle and take care of it. 
			<br /><br />Go to your profile to add it to your avatar.`
	},
];

export {
	itemsData
};
