import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import { getPlayerModulesData } from 'helpers/module-helper';
import { modulesCompetitionsData } from 'data/modules/modules-competitions-data';
import { getNumberOfFilledStars, getModuleMaxPoints } from 'helpers/points-helper';


/**
 * Checks if the given date is within the same week as today();
 * @param {String} sessionDate 
 * @returns true if the day is in the currrent week
 */
export const isDateInCurrentWeek = (sessionDate) => {
	// get current date and day index 0 aka sunday
	let today = dayjs(new Date()).format('YYYY-MM-DD');

	let lastSunday = dayjs(today).set('day', 0).format('YYYY-MM-DD');

	// if today is sunday meaning diff is zero get the sunday before
	if (dayjs(lastSunday).diff(today, 'days') === 0) {
		lastSunday = dayjs(today).subtract(7, 'day').format('YYYY-MM-DD');
	}
	// check if the last sunday and sessionDate diff is negative meaning apart of the current week
	return (dayjs(lastSunday).diff(sessionDate, 'days') < 0);
};


/**
 * getTotalStars loops through all modules in playerData and gets the accululated stars 
 * @param player 
 * @returns max stars
 */
export const getTotalStars = (player, filtered) => {
	let totalStars = 0;
	getPlayerModulesData(player).forEach((playerModuleData) => {
		// only get stars earned this week
		if (filtered) {
			let filteredplayerModuleData = playerModuleData.sessions.filter((session)=>{
				return session.isCompleted && isDateInCurrentWeek(session.started);  
			});

			filteredplayerModuleData.forEach((item)=>{
				let isCompetition = modulesCompetitionsData.some((competition) => {
					return competition.id === playerModuleData.moduleId;
				});
				totalStars += getNumberOfFilledStars(item.points, 
					getModuleMaxPoints(playerModuleData.moduleId, isCompetition));
			});

		} else {
			// get all stars earned
			totalStars += (playerModuleData && playerModuleData.maxStars ? playerModuleData.maxStars : 0);
		}
	});
	return totalStars;
};


/**
* Get to game players of selected game
* @param {string} gameId 
* @returns 
*/
export const getGamePlayers = (gameId) => {
	/* Subscribe to game players */
	return new Promise((resolve) => {
		const db = firebase.firestore();
		db.collection(appConfig.usersDbName).where('role', '==', 'player').get().then((docs) => {
			let gamePlayers = [];
			docs.forEach((doc) => {
				if (
					doc.data() && 
					doc.data().games && 
					doc.data().games.length > 0 &&
					doc.data().games[0].id === gameId &&
					!doc.data().games[0].isRemoved
				) {
					gamePlayers.push({
						...doc.data().games[0], 
						id: doc.id, 
						gameId: doc.data().games[0].id,
						name: doc.data().name,
						email: doc.data().email,
						lastLogin: doc.data().lastLogin
					});
				}
			});
			resolve({status: 'success', data:gamePlayers});
		},
		(error) => {
			/* Error: could not get game players */
			console.error('could not get game players: ', error);
			resolve({status: 'error', error: error});
		});
	});
};