import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {organizePoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import {shuffleArray} from 'helpers/array-helper';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import Button from 'components/ui/button/button';
import OrganizeDndContainer from './organize-dnd-container';
import OrganizeDndItem from './organize-dnd-item';
import OrganizeDndPreview from './organize-dnd-preview';
import './organize.scss';

const Organize = (props) => {
	const {
		areaId, 
		deviceInfo, 
		clockTaskIsCompleted, 
		type, 
		playerTaskData, 
		taskData,
		updateLoggedTime,
		handleCompleteTask,
		isFacilitator = false
	} = props;

	/* Check if completed already */
	let isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);
	if (type === 'clock-task' && clockTaskIsCompleted) isCompleted = true;

	/* Get items to be organized */
	const getItems = () => {
		let items = [];
		if (isCompleted && type !== 'clock-task' && playerTaskData.organizedItems) {
			if (playerTaskData.organizedItems.length === taskData.items.length) {
				/* Get items from player data */
				items = JSON.parse(JSON.stringify(playerTaskData.organizedItems));
			} else {
				/* Outdatd player data, get items in correct places */
				let containerId = null;
				const occupiedContainerIds = [];
				items = taskData.items.map((item) => {
					for (let i = 0; i < item.correctContainerIds.length; i++) {
						containerId = item.correctContainerIds[i];
						if (occupiedContainerIds.indexOf(containerId) < 0) {
							occupiedContainerIds.push(containerId);
							break;
						}
					}
					return {
						itemId: item.id,
						containerId: containerId
					};
				});
			}
		} else {
			/* Get items from data file */
			items = taskData.items.map((item) => {
				return {
					itemId: item.id,
					containerId: item.containerId
				};
			});

			/* Randomize container id */
			if (taskData.shuffleItems === true) {
				let itemContainerIds = taskData.items.map((item) => {return item.containerId;});
				itemContainerIds = shuffleArray(itemContainerIds);
				items = taskData.items.map((item, index) => {
					return {
						itemId: item.id,
						containerId: itemContainerIds[index]
					};
				});
			}
		}


		/* Return items */
		return items;
	};

	/* Track organized items */
	const [taskId, setTaskId] = useState(null);
	const [organizedItems, setOrganizedItems] = useState(getItems());

	/* Update sorted items if new task */
	useEffect(() => {
		setOrganizedItems(getItems());
		setTaskId(taskData.id);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = organizedItems.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, fromContainerId, toContainerId) => {
		/* Update logged time */
		updateLoggedTime();
		
		let newOrganizedItems = JSON.parse(JSON.stringify(organizedItems));
		const itemIndex = newOrganizedItems.findIndex((item) => {return item.itemId === itemId;});

		/* From items to container */
		if (fromContainerId === null && toContainerId !== null) {
			/* If container is occupied, the initial item is sent back to the items area */
			let itemToSwitchIndex = newOrganizedItems.findIndex((item) => {return item.containerId === toContainerId;});
			if (itemToSwitchIndex > -1) {
				newOrganizedItems[itemToSwitchIndex].containerId = null;
			}
			
			newOrganizedItems[itemIndex].containerId = toContainerId;
		}

		/* From container to container */
		if (fromContainerId !== null && toContainerId !== null && fromContainerId !== toContainerId) {
			/* If container is occupied, make items switch place */
			let itemToSwitchIndex = newOrganizedItems.findIndex((item) => {return item.containerId === toContainerId;});
			if (itemToSwitchIndex > -1) {
				newOrganizedItems[itemToSwitchIndex].containerId = fromContainerId;
			}
			
			newOrganizedItems[itemIndex].containerId = toContainerId;
		}

		setOrganizedItems(newOrganizedItems);
	};

	/**
	 * Check if done btn is enabled
	 * @returns {bool}
	 */
	const checkIfDoneBtnIsEnabled = () => {
		/* Player already completed this game */
		if (isCompleted) return false;

		/* No done conditions, done button always enabled  */
		if (!taskData.doneConditions) return true;

		/* Loop over done conditions */
		let isEnabled = true;
		taskData.doneConditions.forEach((condition) => {
			/* Check if condition is met */
			if (condition.type === 'empty-main-container') {
				/* Condition: main container is empty */
				const mainContainerData = taskData.mainContainers.find((mainContainerData) => {
					return mainContainerData.id === condition.mainContainerId;
				});
				if (mainContainerData && mainContainerData.containers) {
					if (organizedItems.some((item) => {
						return mainContainerData.containers.some((container) => {
							return item.containerId === container.id;
						});
					})) {
						isEnabled = false;
					};
				}
			}
		});
		return isEnabled;
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {			
		/* Prepare array of effects */
		let effects = [];

		/* Errors */
		let errors = 0;
		if (taskData.rules.length > 0) {
			/* Count errors */
			taskData.rules.forEach((rule) => {
				/* Check if each item is placed in (one of) their correct container(s) */
				if (rule.type === 'items-in-correct-containers') {
					organizedItems.forEach((item) => {
						const itemData = taskData.items.find((i) => {return i.id === item.itemId;});
						if (itemData && itemData.correctContainerIds.length > 0) {
							if (itemData.correctContainerIds.indexOf(item.containerId) < 0) {
								errors += 1;
							}
						}
					});
				}
				/* Check if items are grouped correct together */
				if (rule.type === 'items-in-correct-groups') {
					
					rule.containerGroups.forEach((containerGroup) => {
						const itemInFirstContainerInGroup = organizedItems.find((item) => {
							return item.containerId === containerGroup[0];
						});
						const itemGroupIndex = rule.itemGroups.findIndex((itemGroup) => {
							return itemGroup.includes(itemInFirstContainerInGroup.itemId);
						});
						let itemsAreInCorrectGroups = true;

						containerGroup.forEach((containerId) => {
							if (!itemsAreInCorrectGroups) return;
							const itemInContainerGroup = organizedItems.find((item) => {
								return item.containerId === containerId;
							});
							const gIndex = rule.itemGroups.findIndex((itemGroup) => {
								return itemGroup.includes(itemInContainerGroup.itemId);
							});
							if (gIndex !== itemGroupIndex) {
								itemsAreInCorrectGroups = false;
								errors += 1;
							}
						});
					});

				}
			});

			/* Prepare result popup */
			let pointTier = (errors > 3 ? 4 : (errors >= 2 ? 3 : (errors > 0 ? 2 : 1)));
			let popupText = gameUiTexts.organizePopup['text' + pointTier];
			popupText = popupText.replace('%numberOfErrors%', errors);
			effects.push({
				type: 'popup',
				popup: {type: 'organize-result', text: popupText}
			});

			/* Streak */
			effects.push({type: 'streak', isCorrectAnswer: (errors === 0 ? true : false)});
		}

		/* Points */
		let points = 0;
		if (taskData.rules.length > 0) {
			points = organizePoints.minPoints;
			const pointIndex = organizePoints.pointLimits.findIndex((limit) => {return errors <= limit;});
			if (pointIndex >= 0) points = organizePoints.pointValues[pointIndex];
		}

		/* Get effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, errors);

				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						effects.push(effect);
					}
				}
			});
		}



		/* Complete task */
		handleCompleteTask(
			'organize', 
			points, 
			errors, 
			effects,
			{organizedItems: organizedItems.map((i) => {return {itemId: i.itemId, containerId: i.containerId};})}
		);
	};
	
	return (
		<div className={'Organize ' + taskData.layout + ' ' + areaId
			+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '') }
		>
			{/* Task intro */}
			<div className="Organize-intro">
				<TaskIntro
					numberOfCorrectAnswers={null}
					text={taskData.text}
					image={taskData.image}
				/>
			</div>

			{/* Catch all drag'n'drop container */}
			{taskData.id === taskId && <OrganizeDndContainer
				type="all"
				containerId="all"
				layout={taskData.layout}
				isEmpty={false}
				handleFindItem={handleFindItem}
				handleMoveItem={handleMoveItem}
			>
				{/* Loop over all main containers */}
				{taskData.mainContainers.map((mainContainerData) => {
					return (
						<div key={mainContainerData.id} className={'Organize-mainContainer ' + mainContainerData.id}>
							{mainContainerData.title && <div className="Organize-mainContainerTitle">
								<span>{mainContainerData.title}</span>
							</div>}
							<div className="Organize-containers">
								{mainContainerData.containers.map((containerData) => {
									let item = organizedItems.find((item) => {
										return item.containerId === containerData.id;
									});
									if (isCompleted && taskData.showSolutionWhenDone) {
										/* Get correct item for container if task is completed */
										item = organizedItems.find((item) => {
											return item.itemId === containerData.finalItemId;
										});
									}

									let itemData = null;
									let itemImage = null;
									let isDraggable = false;
									let classes = [];
			
									if (item) {
										itemData = taskData.items.find((itemData) => {
											return itemData.id === item.itemId;
										});
										itemImage = itemData.image + ' ' + mainContainerData.id;
										isDraggable = true;
										classes = null;
										if (isCompleted) {
											isDraggable = false;
											classes = ['completed'];
										}
										if (isFacilitator) {
											isDraggable = false; 
										}
									}

									/* Return containers of a main container with items */
									return (
										<div 
											key={containerData.id} 
											className={'Organize-container' + (item ? '' : ' empty')}
										>
											<OrganizeDndContainer
												isEmpty={(item ? false : true)}
												type={containerData.type}
												layout={taskData.layout}
												containerId={containerData.id}
												handleFindItem={handleFindItem}
												handleMoveItem={handleMoveItem}
											>
												{item && (
													<OrganizeDndItem
														key={itemData.id}
														isDraggable={isDraggable}
														layout={taskData.layout}
														classes={classes}
														itemId={itemData.id}
														itemImage={itemImage}
														itemImageAlt={itemData.alt}
														handleMoveItem={handleMoveItem}
														containerId={containerData.id}
														isFacilitator={isFacilitator}
													/>)
												}
											</OrganizeDndContainer>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

				{/* Drag'n'drop preview */}
				<OrganizeDndPreview layout={taskData.layout} itemsData={taskData.items} />
			</OrganizeDndContainer>}
			

			{/* Done button */}
			{(!isCompleted && !isFacilitator && checkIfDoneBtnIsEnabled()) && <div className="Organize-doneBtn">
				<Button
					isDisabled={!checkIfDoneBtnIsEnabled()}
					classes={['blue', 'done']}
					text={gameUiTexts.done}
					onClick={completeTask}
				/>
			</div>}			
		</div>
	);
};

Organize.propTypes = {
	areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	clockTaskIsCompleted: PropTypes.bool,
	type: PropTypes.string,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool
};

export default Organize;
