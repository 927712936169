const moduleCashier1 = [
	'modules/tasks/multiple-choice/cashier-1-checkout-lane/option-1.svg',
	'modules/tasks/multiple-choice/cashier-1-checkout-lane/option-2.svg',
	'modules/tasks/multiple-choice/cashier-1-checkout-lane/option-3.svg',
	'modules/tasks/multiple-choice/cashier-1-checkout-lane/option-4.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-1.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-2.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-3.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-4.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-5.svg',
	'modules/tasks/multiple-choice/cashier-1-minor-purchase/option-6.svg',
	'modules/tasks/spot-errors/cashier-1-bob-1.svg',
	'modules/tasks/spot-errors/cashier-1-bob-2.svg',
	'modules/tasks/spot-errors/cashier-1-bob-3.svg',
	'modules/tasks/spot-errors/cashier-1-check.svg',
	'modules/tasks/spot-errors/cashier-1-checkout-lane.svg',
	'modules/tasks/spot-errors/cashier-1-fake-id.svg',
	'modules/tasks/spot-errors/cashier-1-register-area-dirty-items.svg',
	'modules/tasks/spot-errors/cashier-1-register-lane.svg',
];

export {
	moduleCashier1
};
