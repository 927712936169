import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { profileUiTexts, yearBookUiTexts } from 'data/ui-texts';
import { yearbookInitialEntryPoints, yearbookAllQuestions } from 'data/yearbook-data';
import { blockedWordsData } from 'data/blocked-words-data';
import { renderMarkdown } from 'helpers/text-helper';
import Button from '../button/button';
import Badge from '../badge/badge';
import PopupReportPrompt from './popup-report-prompt';
import PopupAddBadge from './popup-add-badge';
import Avatar from 'components/game/avatar/avatar';
import PopupChooseQuestions from './popup-choose-questions';
import './popup-yearbook.scss';

const PopupYearbook = (props) => {
	const {
		userData,
		playerData,
		closeYearbook,
		updatePlayerGameData,
		canEdit,
		currentPlayer,
		reportPlayer
	} = props;

	const [yearbookData, setYearbookData] = useState(null);
	const [yearbookBadges, setYearbookBadges] = useState([]);
	let yearbookTitle = userData.name + profileUiTexts.yearbook;
	if (userData.name.slice(-1).toLowerCase() === 's') {
		yearbookTitle = userData.name + profileUiTexts.yearbook2;
	}
	const [isSaving, setIsSaving] = useState(false);
	const [isInputMode, setIsInputMode] =  useState(false);

	// keeps track of when to show the popups
	const [showAddBadgePopup, setShowAddBadgePopup] = useState(false);
	const [showChooseQuestionPopup, setShowChooseQuestionPopup] = useState(false);
	const [showReportPopup, setShowReportPopup] = useState(false);
	
	const [yearbookFeedback, setYearbookFeedback] = useState(null);
	/**
	 * Load in the correct data if it exsist else use defualt data
	 */
	useEffect(() => {
		if (playerData.yearbookData) {
			setYearbookData(JSON.parse(JSON.stringify(playerData.yearbookData)));
			if (playerData.yearbookData.badgeIds) {
				setYearbookBadges(playerData.yearbookData.badgeIds);
			}

		} else {
			const initialYearbookData = {};
			yearbookInitialEntryPoints.forEach((data) => {
				initialYearbookData[data.id] = '';
			});
			setYearbookData(initialYearbookData);
		}

	}, [playerData, userData.name]);


	// Update yearbookData with written input
	const handleEditYearbookData = (input, dataPoint) => {
		const newYearbookData = yearbookData ? JSON.parse(JSON.stringify(yearbookData)) : {};
		// check if the input is greater than the character limit
		const charLimit = yearbookAllQuestions.find((item)=>{return item.id === dataPoint;}).charLimit;
		if (input.target.value.length >= charLimit)	return;
		newYearbookData[dataPoint] = input.target.value;
		setYearbookData(newYearbookData);
	};

	/**
	 * checkValidContent returns the validity and fields name if that is invalid
	 */
	const checkValidContent = (data) => {
		let isValid = true;
		let invalidFields = [];
		// remove all non string values from the yearbook
		const filteredArray = Object.entries(data).filter((e) => { 
			return e[0] !== 'badgeIds'; 
		});

		filteredArray.forEach((object) => {
			const [key, val] = object;
			blockedWordsData.forEach((word)=>{
				if (val.toLowerCase().includes(word)) {
					isValid = false;
					invalidFields.push(key);
				}
			});
		});

		return [isValid, invalidFields];
	};

	/**
	 * Update player data with current yearbook data.
	 */
	const handleUpdateYearbook = () => {
		setIsSaving(true);
		// update the badges
		const newYearbookData = yearbookData ? JSON.parse(JSON.stringify(yearbookData)) : {};

		const [isValid, invalidFields] = checkValidContent(newYearbookData);
		
		if (isValid) {
			setYearbookFeedback(null);
			newYearbookData['badgeIds'] = yearbookBadges;
			updatePlayerGameData({yearbookData: newYearbookData}).then(
				() => {
					setIsSaving(false);
					setIsInputMode(false);
				}, (error) => {
					console.error(error);
				}
			);
		} else {
			setIsSaving(false);
			setYearbookFeedback(JSON.parse(JSON.stringify(yearBookUiTexts.invalidFeedback))
				.replace(/%invalidFields%/g, invalidFields));
		}
	};

	/**
	 * calls reportPlayer with the current yearbookData
	*/
	const handleReportPlayer = () => {
		return reportPlayer(userData.id, currentPlayer.id, yearbookData);
	};


	return (
		<div className='PopupYearbook' onClick={(e) => {e.stopPropagation(); closeYearbook();}}>
			<div className='PopupYearbook-dialogue' onClick={(e) => {e.stopPropagation();}}>
				<div className="PopupYeartbook-closeBtn" onClick={() => {closeYearbook();}} />
				<div className="PopupYearbook-avatarFrame">
					<div className='PopupYearbook-avatar'>
						<Avatar className='PopupYearbook-avatarImage' type="yearbook" playerData={playerData} />
					</div>
				</div>
				<div className='PopupYearbook-form'>
					<div className='PopupYearbook-title'>
						<span>{yearbookTitle}</span>
					</div>
					{yearbookData && Object.keys(yearbookData).map((entryData) => {
						if (entryData === 'badgeIds') {return null;}
						const item = yearbookAllQuestions.find((item)=> { return item.id === entryData;});
						return (
							<div key={item.id} className='PopupYearbook-yearbookEntry'>
								<span className='PopupYearbook-yearbookEntryTitle'>{item.title}</span>
								{isInputMode ?
									<input 
										type={'text'}
										value={yearbookData[entryData] ? yearbookData[entryData] : ''}
										onChange={(e) => {handleEditYearbookData(e, entryData);}}
									/>
									:
									<span className='PopupYearbook-yearbookEntryValue'>{yearbookData[entryData]}</span>
								}
							</div>
						);
					})}

					
					<div className='PopupYearbook-yearbookEntry'>
						<div className='PopupYearbook-badges'>
							{(isInputMode && yearbookBadges.length < appConfig.questionsAndBadgesInYearbook) && 
								<div className='PopupYearbook-addBadgeWrapper'> 
									<div className='PopupYearbook-addBadge' 
										onClick={()=>{setShowAddBadgePopup(true);}}/>
								</div>}
								
							{yearbookBadges.length !== 0 && yearbookBadges.map((badge, index) => {
								return (
									<div className='PopupYearbook-addBadgeWrapper' key={index}
										onClick={()=>{
											if (isInputMode) {setShowAddBadgePopup(true);}
										}}> 
										<Badge key={index} isUnlocked={true} page="yearbook" badgeId={badge}/>
									</div>
								);
							})}
							
						</div>
					</div>

				</div>
				{/* feedback */}
				{yearbookFeedback && <div className='PopupYearbook-feedback'>
					<span>{renderMarkdown(yearbookFeedback)}</span>
				</div>}

				{/* buttons */}
				{isInputMode ?
					<div className='PopupYearbook-buttonWrapper'> 
						<div className='PopupYearbook-editQuestions'>
							<Button
								classes={['blueDark']}
								isLoading={isSaving}
								isDisabled={isSaving}
								text={yearBookUiTexts.chooseQuestions}
								onClick={() => {setShowChooseQuestionPopup(true);}}
							/>
						</div>
						<div className='PopupYearbook-saveButton'>
							<Button
								classes={['blueDark']}
								isLoading={isSaving}
								isDisabled={isSaving}
								text={yearBookUiTexts.save}
								onClick={() => {handleUpdateYearbook();}}
							/>
						</div>
					</div>
					:
					(canEdit && <div className='PopupYearbook-saveButton'>
						<Button
							classes={['blueDark']}
							text={yearBookUiTexts.edit}
							onClick={() => {setIsInputMode(true);}}
						/>
					</div>)}


				{(currentPlayer && ((userData.id !== currentPlayer.id) && (currentPlayer.role === 'player'))) &&
				<div className='PopupYearbook-reportButton'>
					<Button
						classes={['reportUser']}
						text={yearBookUiTexts.report}
						onClick={() => {setShowReportPopup(true);}}
					/>
				</div>}
			</div>

			

			{/* add Badge popup */}
			{showAddBadgePopup && <PopupAddBadge 
				playerBadges = {playerData.badges} 
				closeAddBadgePopup = {setShowAddBadgePopup}
				setYearbookBadges = {setYearbookBadges}
				yearbookBadges = {yearbookBadges}/>}
			{/* change popup questions */}
			{showChooseQuestionPopup && <PopupChooseQuestions 
				closePopup = {setShowChooseQuestionPopup}
				setYearbookData = {setYearbookData}
				yearbookData = {yearbookData}/>}
			{/* report popup asking the user yes or no */}
			{showReportPopup && <PopupReportPrompt
				handleReportPlayer={handleReportPlayer}
				
				closePopup={setShowReportPopup}
			/>}

		</div>
	);
};

PopupYearbook.propTypes = {
	userData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	closeYearbook: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func,
	canEdit: PropTypes.bool.isRequired,
	currentPlayer: PropTypes.object,
	reportPlayer: PropTypes.func,
};

export default PopupYearbook;