import dayjs from 'dayjs';
import {insightsData} from 'data/insights-data';
import {sortArrayByProperty} from './array-helper';
import {modulesData} from 'data/modules/modules-data';
import {
	getPlayerModuleData,
	checkIfModuleIsCompleted,
	getAverageTaskMistakes
} from 'helpers/module-helper';

/**
 * Get new insights
 */
export function getNewInsights(gameData, players) {
	/* Get current game insights */
	let gameInsights = gameData.insights ? JSON.parse(JSON.stringify(gameData.insights)) : [];

	/* Track if game insights was updated */
	let isUpdated = false;

	/* Loop over insights */
	insightsData.forEach((insight) => {
		/* Insight index (if it has been triggered before) */
		let insightIndex = gameInsights.findIndex((i) => {return i.insightId === insight.id;});

		/* Get insight players already logged */
		let loggedPlayerIds = (insightIndex >= 0
			? gameInsights[insightIndex].loggedPlayerIds 
				? JSON.parse(JSON.stringify(gameInsights[insightIndex].loggedPlayerIds)) : []
			: []
		); 

		/* New notification */
		let newNotification = null;


		/* Insight type: task with most mistakes in a module */
		if (insight.type === 'most-mistakes-module') {
			/* Get new players that have finished module */
			let insightPlayers = [];
			players.forEach((player) => {
				/* Ignore already logged players */
				if (loggedPlayerIds.indexOf(player.id) >= 0) return;
				const playerModuleData = getPlayerModuleData(insight.moduleId, player);
				if (checkIfModuleIsCompleted(playerModuleData)) {
					const playerData = {
						playerId: player.id, 
						...playerModuleData, 
						started: playerModuleData.sessions[0].started
					};
					insightPlayers.push(playerData);
				}
			});

			if (insightPlayers.length >= insight.numberOfPlayers) {
				/* Get module data */
				const moduleData = modulesData.find((m) => {return m.id === insight.moduleId;});
				const tasks = moduleData.tasks.filter((task) => {return task.isSolveToContinue;});

				/* Sort players by start date (we have not end date) */
				insightPlayers = sortArrayByProperty(insightPlayers, 'started', 'ASC');

				for (let i = 1; i <= Math.floor(insightPlayers.length / insight.numberOfPlayers); i++) {
					/* Notification #i */
					const playerGroup = insightPlayers.slice(
						(i - 1 ) * insight.numberOfPlayers, i * insight.numberOfPlayers
					);
					let tasksErrors = [];
					tasks.forEach((task) => {
						const errors = getAverageTaskMistakes(task, playerGroup);
						if (errors) {
							tasksErrors.push({id: task.id, errors: errors});
						}
					});
					if (tasksErrors.length > 0) {
						/* Add player ids to logged players */
						playerGroup.forEach((player) => {
							loggedPlayerIds.push(player.playerId);
						});	

						/* Get task with most errors */
						tasksErrors = sortArrayByProperty(tasksErrors, 'errors', 'DESC');
						const taskMostErrors = tasksErrors[0];
						
						/* Create new notification */
						newNotification = {
							created: dayjs(new Date()).format('YYYYMMDD'),
							taskMostErrors: taskMostErrors,
							deletedBy: [],
						};
					}
				}
			}
		}

		/* Insight type: minium X stars in a module */
		if (insight.type === 'stars-module') {
			/* Get new players that have finished area with a least X stars */
			let insightPlayers = [];
			players.forEach((player) => {
				/* Ignore already logged players */
				if (loggedPlayerIds.indexOf(player.id) >= 0) return;
				const playerModuleData = getPlayerModuleData(insight.moduleId, player);
				if (
					playerModuleData && 
					playerModuleData.sessions &&
					playerModuleData.sessions.length > 0 &&
					playerModuleData.maxStars >= insight.numberOfStars
				) {
					const playerData = {
						playerId: player.id, 
						playerName: player.name,
						started: playerModuleData.sessions[0].started
					};
					insightPlayers.push(playerData);
				}
			});

			if (insightPlayers.length >= insight.numberOfPlayers) {
				/* Sort players by start date (we have not end date) */
				insightPlayers = sortArrayByProperty(insightPlayers, 'started', 'ASC');

				for (let i = 1; i <= Math.floor(insightPlayers.length / insight.numberOfPlayers); i++) {
					/* Notification #i */
					const playerNames = [];
					for (let j = (i - 1 ) * insight.numberOfPlayers; j < i * insight.numberOfPlayers; j++) {
						/* Get player names */
						playerNames.push(insightPlayers[j].playerName);

						/* Add player ids to logged players */
						loggedPlayerIds.push(insightPlayers[j].playerId);
					}
					
					/* Create new notification */
					newNotification = {
						created: dayjs(new Date()).format('YYYYMMDD'),
						playerNames: playerNames,
						deletedBy: [],
					};
				}
			}
		}

		/* New insight discovered */
		if (newNotification) {

			/* Create insight in game object if it does not exist yet */
			if (insightIndex < 0) {
				gameInsights.push({
					insightId: insight.id,
					notifications: [],
					mutedBy: []
				});
				insightIndex = gameInsights.length - 1;
			}

			/* Create notifications array if it does not exist yet */
			if (!gameInsights[insightIndex].notifications) {
				gameInsights[insightIndex].notifications = [];
			}

			/* Add notification to insight */
			gameInsights[insightIndex].notifications.push(newNotification);
			gameInsights[insightIndex].loggedPlayerIds = loggedPlayerIds;

			/* Flag as updated */
			isUpdated = true;
		}
	});


	return {gameInsights, isUpdated};

}