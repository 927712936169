import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {scrollTo} from 'helpers/scroll-helper';
import BranchingStoryMc from './branching-story-mc';
import BranchingStoryInfo from './branching-story-info';
import BranchingStoryChecklist from './branching-story-checklist';
import Button from 'components/ui/button/button';
import './branching-story.scss';

const BranchingStory = (props) => {
	const {
		areaId, 
		deviceInfo, 
		managerId,
		playerTaskData, 
		moduleData, 
		taskData,
		updateLoggedTime,
		handleCompleteTask,
		isFacilitator = false,
	} = props;

	/* Timeouts */
	const timeout = useRef(null);
	const timeout2 = useRef(null);

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track story progres, points */
	const [storyArr, setStoryArr] = useState([]);
	const [points, setPoints] = useState(0);

	/**
	 * Get points
	 * @returns {number} points
	 */
	const getPoints = () => {
		let points = 0;
		if (isCompleted && playerTaskData) points = playerTaskData.points;
		return points;
	};

	/**
	 * Prepare story
	 * @returns {array} storyArrTemp
	 */
	const prepareStory = () => {
		let storyArrTemp = [];

		if (playerTaskData && playerTaskData.storyArr && playerTaskData.storyArr.length > 0) {
			storyArrTemp = [...playerTaskData.storyArr];
		} else {
			const firstStoryStepData = (taskData && taskData.steps && taskData.steps.length > 0 
				? taskData.steps[0] : null);
	
			if (firstStoryStepData) {
				storyArrTemp.push({stepId: firstStoryStepData.id});
			}			
		}
		return storyArrTemp;
	};

	
	/**
	 * Complete a story step
	 * @param {number} newPoints 
	 * @param {string} nextStoryStepId 
	 * @param {number} optionId 
	 */
	const handleCompleteStoryStep = (newPoints, nextStoryStepId, optionId = null, currentStoryArr = null) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Update points */
		let totalPoints = points + newPoints;
		
		/* Update step data */
		let newStoryArr = currentStoryArr 
			? JSON.parse(JSON.stringify(currentStoryArr)) 
			: JSON.parse(JSON.stringify(storyArr));
		newStoryArr[newStoryArr.length - 1].points = newPoints;
		newStoryArr[newStoryArr.length - 1].optionId = optionId;
		newStoryArr[newStoryArr.length - 1].isCompleted = true;

		const storyStepData = taskData.steps.find((s) => {return s.id === nextStoryStepId;});	

		if (nextStoryStepId && storyStepData && storyStepData.type !== 'empty-final-step') {			
			/* Continue to next step */
			newStoryArr.push({stepId: nextStoryStepId});
		} else {
			/* No next step: Complete task */
			completeTask(newStoryArr, points + newPoints);
		}

		/* Update story & points */
		setPoints(totalPoints);
		setStoryArr(newStoryArr);
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 */
	const completeTask = (newStoryArr = null, newPoints = null) => {
		/* Errors */
		const errors = 0;

		/* Save completed task */
		handleCompleteTask(
			'branching-story', 
			(newPoints ? newPoints : points), 
			errors, 
			[],
			{storyArr: (newStoryArr ? newStoryArr : storyArr)}
		);
	};

	/**
	 * Smooth scroll to dialogue bottom
	 */
	const scrollToBottom = () => {
		var div = document.getElementById('story');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
			// div.scrollTop = div.scrollHeight - div.clientHeight;
		}
	};


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		// ...

		return () => {
			/* Component will unmount */
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Story array was updated
	 */
	useEffect(() => {
		if (storyArr.length > 0) {
			/* Auto-scroll to bottom when story array is updated */
			timeout2.current = setTimeout(() => {
				scrollToBottom();
			}, 200);
			

			const currentStoryStepId = storyArr[storyArr.length - 1].stepId;
			const currentStoryStepData = taskData.steps.find((s) => {return s.id === currentStoryStepId;});
			if (
				currentStoryStepData && 
				(currentStoryStepData.type === 'info' || currentStoryStepData.type === 'checklist')
			) {
				if (currentStoryStepData.nextStepId) {
					if (currentStoryStepData.autoContinue) {
						/* Auto-nav to next story step */
						timeout.current = setTimeout(() => {
							handleCompleteStoryStep(
								(currentStoryStepData.points ? currentStoryStepData.points : 0), 
								currentStoryStepData.nextStepId,
								null,
								null
							);
						}, currentStoryStepData.nextStepDelay);
					}
				} else {
					/* Auto-complete task */
					timeout.current = setTimeout(() => {
						completeTask();
					}, 500);
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyArr]);


	/**
	 * New branching story task, reset
	 */
	useEffect(() => {
		setPoints(getPoints());
		setStoryArr(prepareStory());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	
	return (
		<div 
			className={'BranchingStory ' + areaId 
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
				+ (taskData && taskData.background ? ' ' + taskData.background : '')}
		>
			<div id="story" className="BranchingStory-story">
				{storyArr.map((storyStep, index) => {
					
					const storyStepData = taskData.steps.find((s) => {return s.id === storyStep.stepId;});	
					let StoryStepComponent = BranchingStoryInfo;
					if (
						storyStepData && 
						storyStepData.type === 'multiple-choice'
					) StoryStepComponent = BranchingStoryMc;
					if (storyStepData &&
						storyStepData.type === 'checklist'
					) StoryStepComponent = BranchingStoryChecklist;
					
					let storyStepClass = 'BranchingStory-step' + (index > 0 && !isCompleted ? ' fadeIn' : '');
					let fadeLevel = null;
					if (index < storyArr.length - 1) {
						fadeLevel = (index < storyArr.length - 2 ? 'full' : 'half');
						storyStepClass += ' arrow ' + fadeLevel + 'FadeOut';
					}

					if (isFacilitator) storyStepClass += ' isFacilitator';

					return (
						<div key={index} className={storyStepClass}>
							<StoryStepComponent 
								taskId={taskData.taskId}
								fadeLevel={fadeLevel}
								managerId={managerId}
								playerStoryStepData = {storyStep}
								storyStepData={storyStepData} 
								storyArr={storyArr}
								moduleData={moduleData}
								handleCompleteStoryStep={handleCompleteStoryStep}
							/>		
							{/* Next step btn */}
							{
								!isCompleted && 
								index === storyArr.length - 1 && 
								((storyStepData && storyStepData.nextStepId && !storyStepData.autoContinue)) && 
									<div className="BranchingStory-nextBtn">
										<Button 
											classes={['next', 'animate']} 
											onClick={() => {
												handleCompleteStoryStep(
													(storyStepData.points ? storyStepData.points : 0), 
													storyStepData.nextStepId,
													null,
													null
												);
											}}
										/>
									</div>
							} 
						</div>
					);
				})}
			</div>
		</div>
	);
};

BranchingStory.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	managerId: PropTypes.string.isRequired,
	areaId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default BranchingStory;
