import React from 'react';
import PropTypes from 'prop-types';
// import {renderMarkdown} from 'helpers/text-helper';
// import {facilitatorSubpagesData} from 'data/facilitator-data';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import './facilitator-resources.scss';

const FacilitatorResources = ({pageData, handleGoToPage}) => {
	// const overviewPageData = facilitatorSubpagesData.find((p) => {return p.id === 'players';});


	return (
		<div className="FacilitatorResources">
			<FacilitatorHeaderSubpage 
				page={pageData.id} 
				subpage="resources"
				subpageIds={pageData.subpageIds}
				handleGoToPage={handleGoToPage}
			/>
		</div>
	);

};

FacilitatorResources.propTypes = {
	pageData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default FacilitatorResources;
