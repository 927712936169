import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin-delete-facilitator-popup.scss';

const AdminDeleteFacilitatorPopup = (props) => {
	const {facilitatorId, usersData, toggleDeleteFacilitatorPopup, deleteFacilitator, handleSelectFacilitator} = props;

	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Get facilitator user data */
	const facilitatorData = usersData.find((user) => {return (user.id === facilitatorId);});


	/**
	 * Handle add facilitator
	 */
	const handleDeleteFacilitator = () => {
		if (isLoading) return; 

		setErrorMsg(null);
		setSuccessMsg(null);
		setIsLoading(true);
		
		deleteFacilitator(facilitatorData.role, facilitatorId, facilitatorData.email).then((response) => {
			if (response.status === 'success') {
				toggleDeleteFacilitatorPopup();
				handleSelectFacilitator();
			} else {
				console.error(response);
				setIsLoading(false);
			}
		});
	};


	return (
		<div className="AdminDeleteFacilitatorPopup" onClick={() => {toggleDeleteFacilitatorPopup();}}>
			<div className="AdminDeleteFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminDeleteFacilitatorPopup-header">
					<div className="AdminDeleteFacilitatorPopup-title">{adminUiTexts.deleteFacilitatorPopup.title}</div>
					<div 
						className="AdminDeleteFacilitatorPopup-closeBtn" 
						onClick={() => {toggleDeleteFacilitatorPopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminDeleteFacilitatorPopup-body">
					<div className="AdminDeleteFacilitatorPopup-text">
						{renderMarkdown(adminUiTexts.deleteFacilitatorPopup.text)}
					</div>
					<div className={'AdminDeleteFacilitatorPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
					<div className="AdminDeleteFacilitatorPopup-buttons">
						<div className="AdminDeleteFacilitatorPopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['blue', 'adminPopup', 'wide']}
								text={adminUiTexts.deleteFacilitatorPopup.confirmBtn}
								onClick={() => {handleDeleteFacilitator();}}
							/>
						</div>
						<div className="AdminDeleteFacilitatorPopup-cancelBtn">
							<Button 
								classes={['blue', 'adminPopup', 'wide']}
								text={adminUiTexts.deleteFacilitatorPopup.cancelBtn}
								onClick={() => {toggleDeleteFacilitatorPopup();}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminDeleteFacilitatorPopup.propTypes = {
	facilitatorId: PropTypes.string.isRequired,
	usersData: PropTypes.array.isRequired,
	toggleDeleteFacilitatorPopup: PropTypes.func.isRequired,
	deleteFacilitator: PropTypes.func.isRequired,
	handleSelectFacilitator: PropTypes.func.isRequired,
};

export default AdminDeleteFacilitatorPopup;
