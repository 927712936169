import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {adminUiTexts} from 'data/ui-texts';
import AdminUserPopup from './admin-user-popup/admin-user-popup';
import './admin-users.scss';

const AdminUsers = ({gamesData, usersData}) => {
	/* Select user (shows popup with info about user) */
	const [selectedUserId, setSelectedUserId] = useState(null);
	const handleSelectUser = (userId = null) => {
		setSelectedUserId(userId);
	};

	/* Search word */
	const [searchWord, setSearchWord] = useState('');
	const updateInput = (event) => {
		setSearchWord(event.target.value);
	};

	/* Filtered users */
	let filteredUsers = usersData.filter((user) => {
		return (
			!searchWord || 
			searchWord.length === 0 || 
			(user.name && (user.name.toLowerCase().includes(searchWord.toLowerCase()))) ||
			(user.email && (user.email.toLowerCase().includes(searchWord.toLowerCase())))
		);
	});

	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('name');
	const [sortDirection, setSortDirection] = useState('ASC');

	/* Sorted users */
	const sortedUsers = sortArrayByProperty(filteredUsers,  sortProperty, sortDirection);

	/**
	 * Sort facilitators by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	return (
		<div className="AdminUsers">
			{/* Search input */}
			<div className="AdminUsers-search">
				<input
					name="searchWord"
					value={searchWord ? searchWord : ''}
					placeholder={adminUiTexts.typeToFilter}
					onChange={(e) => {updateInput(e);}}
				/>
			</div>


			{/* Users list - header */}
			<div className="AdminUsers-header">
				<div 
					className={'AdminUsers-cell name' 
						+ (sortProperty === 'name' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('name');}}
				>
					<span>{adminUiTexts.name}</span>
				</div>
				<div 
					className={'AdminUsers-cell email' 
						+ (sortProperty === 'email' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('email');}}
				>
					<span>{adminUiTexts.email}</span>
				</div>
				<div className="AdminUsers-cell role"><span>{adminUiTexts.role}</span></div>
				<div className="AdminUsers-cell lastLogin"><span>{adminUiTexts.lastLogin}</span></div>
				<div className="AdminUsers-cell store"><span>{adminUiTexts.stores}</span></div>
			</div>
			{/* Users list - body */}
			<div className="AdminUsers-body">
				{sortedUsers.map((user, index) => {
					let userGames = '';
					if (user.role === 'player') {
						if (user.games && user.games.length > 0) {
							user.games.forEach((game) => {
								const gameData = gamesData.find((g) => {return g.id === game.id;});
								if (gameData && gameData.storeName) {
									if (userGames.length === 0) {
										userGames = gameData.storeName;
									} else {
										userGames += ', ' + gameData.storeName;
									}
								}
							});
						}
					}
					if (user.role === 'facilitator' || user.role === 'admin') {
						gamesData.forEach((game) => {
							if (game.facilitatorEmails && game.facilitatorEmails.indexOf(user.email) >= 0) {
								if (userGames.length === 0) {
									userGames = game.storeName;
								} else {
									userGames += ', ' + game.storeName;
								}
							};
						});
					}

					const lastLogin = (user && user.lastLogin 
						? dayjs(user.lastLogin).format('MM/DD/YY') : '-');
					return (
						<div 
							key={index} 
							className="AdminUsers-row" 
							onClick={() => {handleSelectUser(user.id);}}
						>
							<div className="AdminUsers-cell name"><span>{user.name}</span></div>
							<div className="AdminUsers-cell email"><span>{user.email}</span></div>
							<div className="AdminUsers-cell role"><span>{user.role}</span></div>
							<div className="AdminUsers-cell lastLogin"><span>{lastLogin}</span></div>
							<div className="AdminUsers-cell stores"><span>{userGames}</span></div>
						</div>		
					);
				})}
			</div>

			{selectedUserId &&
				<AdminUserPopup 
					userId={selectedUserId}
					gamesData={gamesData}
					usersData={usersData}
					handleSelectUser={handleSelectUser}
				/>}
		</div>
	);

};

AdminUsers.propTypes = {
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
};

export default AdminUsers;
