import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Character from 'components/ui/character/character';
import Avatar from 'components/game/avatar/avatar';
import Button from 'components/ui/button/button';
import {ReactComponent as Wizard1SVG} from 'assets/images/characters/wizard-1.svg';
import {ReactComponent as Wizard2SVG} from 'assets/images/characters/wizard-2.svg';
import {ReactComponent as Wizard3SVG} from 'assets/images/characters/wizard-3.svg';
import './intro.scss';

const Intro = ({deviceInfo, managerId, playerData, taskData, handleGoToTask, handleCompleteIntro}) => {

	const [zoomLevel, setZoomLevel] = useState(1);
	const [zoomAnimation, setZoomAnimation] = useState(null);
	console.log(zoomLevel);
	useEffect(() => {
		if (taskData.zoomLevel) {
			if (taskData.zoomLevel !== zoomLevel) {
				setZoomLevel(taskData.zoomLevel);
				setZoomAnimation('zoom-' + zoomLevel + '-to-' + taskData.zoomLevel);
			} else {
				setZoomAnimation(null);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData]);


	let WizardComponent = Wizard1SVG;
	if (taskData.wizard === 'wizard-2') WizardComponent = Wizard2SVG;
	if (taskData.wizard === 'wizard-3') WizardComponent = Wizard3SVG;

	return (
		<div className={'Intro' + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			{/* Logo */}
			{taskData.showLogo && <div className="Intro-logo"><Logo /></div>}
			
			{/* Background building */}
			<div 
				className={'Intro-building ' + (zoomAnimation ? zoomAnimation : 'zoom-' + zoomLevel)} 
			/>

			{/* Wizard */}
			<div 
				className={'Intro-wizard' + (taskData.wizard ? ' show' : '') 
					+ ' ' + (zoomAnimation ? zoomAnimation : 'zoom-' + zoomLevel)
				}
			><WizardComponent /></div>

			{/* Manager */}
			<div className={'Intro-manager' + 
				(taskData.managerPose ? ' show' : '') + 
				(taskData.managerFadeIn ? ' fadeIn' : '')}
			>
				<Character page="intro" characterId={'manager-' + taskData.managerPose} managerId={managerId} />
			</div>

			{/* Title & text */}
			{(taskData.title || taskData.text) && 
				<div 
					className={'Intro-box ' + taskData.textType + (taskData.textFadeIn ? ' fadeIn' : '')
					+ (taskData.managerPose ? ' manager' : '') + (taskData.wizard ? ' wizard' : '')
					+ (taskData.zoomLevel ? ' zoom-' + taskData.zoomLevel : '')}
				>
					{taskData.title && <div className="Intro-title">{taskData.title}</div>}
					{taskData.text && <div className="Intro-text">{renderMarkdown(taskData.text)}</div>}
				</div>
			}

			{/* Avatar */}
			{taskData.avatar && 
				<div className={'Intro-avatar' + (taskData.zoomLevel ? ' zoom-' + taskData.zoomLevel : '')}>
					<Avatar 
						type="intro" 
						playerData={(taskData.avatar === 'avatar' ? playerData : {})}
					/>
				</div>
			}

			{/* Buttons */}
			{taskData.buttons && <div className={'Intro-buttons' + (taskData.buttons.length === 2 ? ' two' : '')}>
				{taskData.buttons.map((btn, index) => {
					return (
						<Button 
							key={index}
							classes={btn.classes} 
							text={btn.text} 
							onClick={() => {
								if (taskData.isLastTask) {
									handleCompleteIntro();
								} else {
									handleGoToTask(btn.nextTaskId);
								}
							}} 
						/>
					);
				})}
			</div>}
			{taskData.nextTaskId && 
				<div className="Intro-nextBtn">
					<Button 
						classes={['next']} 
						onClick={() => {handleGoToTask(taskData.nextTaskId);}} 
					/>
				</div>
			}
		</div>
	);
};

Intro.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	managerId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	handleGoToTask: PropTypes.func.isRequired,
	handleCompleteIntro: PropTypes.func.isRequired
};

export default Intro;
