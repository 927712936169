const moduleProduce1 = [
	'modules/tasks/multiple-choice/produce-1-leafy-greens/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-leafy-greens/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-leafy-greens/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-leafy-greens/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-melons/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-melons/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-melons/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-melons/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-onions-1/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-onions-1/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-onions-1/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-onions-1/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-onions-1/option-5.svg',
	'modules/tasks/multiple-choice/produce-1-onions-2/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-onions-2/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-onions-2/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-onions-2/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-onions-2/option-5.svg',
	'modules/tasks/multiple-choice/produce-1-orange-type/background.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-1/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-1/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-1/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-2/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-2/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-2/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-2/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-2/option-5.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-3/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-3/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-3/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-3/option-4.svg',
	'modules/tasks/multiple-choice/produce-1-potatoes-3/option-5.svg',
	'modules/tasks/multiple-choice/produce-1-produce-department/background.svg',
	'modules/tasks/organize/produce-1-produce-aisle/background-cooled.svg',
	'modules/tasks/organize/produce-1-produce-aisle/background-dry.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-1.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-2.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-3.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-4.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-5.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-6.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-1.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-2.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-3.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-4.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-5.svg',
	'modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-6.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-1.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-2.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-3.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-4.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-5.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-6.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-7.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-8.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-1.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-2.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-3.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-4.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-5.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-6.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-7.svg',
	'modules/tasks/organize/produce-1-produce-aisle/dry-bunch-8.svg',

	
	'modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-4.svg',

	'modules/tasks/multiple-choice/produce-1-broccoli/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-broccoli/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-broccoli/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-broccoli/option-4.svg',

	'modules/tasks/multiple-choice/produce-1-exotic-fruits/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-exotic-fruits/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-exotic-fruits/option-3.svg',
	'modules/tasks/multiple-choice/produce-1-exotic-fruits/option-4.svg',

	'modules/tasks/multiple-choice/produce-1-flies/background.svg',
	'modules/tasks/multiple-choice/produce-1-flies/flies.svg',
	'modules/tasks/multiple-choice/produce-1-good-leafy/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-good-leafy/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-jalapeno/option-1.svg',
	'modules/tasks/multiple-choice/produce-1-jalapeno/option-2.svg',
	'modules/tasks/multiple-choice/produce-1-jalapeno/option-3.svg',

	'modules/tasks/spot-errors/produce-1-scales.svg',
	'modules/tasks/spot-errors/produce-1-prep-area.svg',
	'modules/tasks/spot-errors/produce-1-pineapple-sniff.svg',
	'modules/tasks/spot-errors/produce-1-bad-tomatoes.svg',
	'modules/tasks/spot-errors/produce-1-orange.svg',

	'modules/tasks/image/produce-1-scales.svg',

	'modules/tasks/instructions/bin-of-tomatoes.svg'
];

export {
	moduleProduce1
};
