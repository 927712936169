import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {renderMarkdown} from 'helpers/text-helper';
import {adminUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {checkIfValidEmail} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './admin-add-facilitator-popup.scss';


const AdminAddFacilitatorPopup = ({gameId, togglePopup, addFacilitatorsToGame}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Input facilitator e-mail */
	const [email, setEmail] = useState('');
	const updateEmail = (e) => {
		setEmail(e.target.value);
		setErrorMsg(null);
		setSuccessMsg(null);
	};



	/**
	 * Handle add facilitator
	 */
	const handleAddFacilitator = () => {
		if (isLoading) return; 
		if (!email || email.length === 0) return;


		setErrorMsg(null);
		setSuccessMsg(null);
		setIsLoading(true);

		if (email.includes(' ')) {
			// Multiple e-mails, add valid e-mails 
			let validEmails = [];
			let emails = email.split(' ');
			emails.forEach((e) => {
				const {isValidEmail} = checkIfValidEmail(e);
				
				if (isValidEmail && validEmails.indexOf(e.toLowerCase()) < 0) {
					validEmails.push(e.toLowerCase());
				}
			});

			if (validEmails.length > 0) {
				addFacilitatorsToGame(gameId, validEmails).then(() => {
					setIsLoading(false);
					togglePopup();
				});
			} else {
				setErrorMsg(adminUiTexts.addFacilitatorToGamePopup.noValidEmails);
				setIsLoading(false);
			}

		} else {
			/* Single e-mail, check if valid */
			const {isValidEmail, emailFeedback} = checkIfValidEmail(email);
		
			if (isValidEmail) {
				/* Valid e-mail, add facilitator */
				addFacilitatorsToGame(gameId, [email.toLowerCase()]).then((response) => {
					if (response.status === 'error') {
						setIsLoading(false);
						setErrorMsg(response.feedback[0]);			
					} else {
						// setSuccessMsg(response.feedback);
						setIsLoading(false);
						togglePopup();
					}
				});
			} else {
				/* Invalid e-mail */
				if (adminUiTexts.addFacilitatorToGamePopup.hasOwnProperty(emailFeedback)) {
					setErrorMsg(adminUiTexts.addFacilitatorToGamePopup[emailFeedback]);
				}
				setIsLoading(false);
			}
		}
	};


	return (
		<div className="AdminAddFacilitatorPopup" onClick={() => {togglePopup();}}>
			<div className="AdminAddFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminAddFacilitatorPopup-header">
					<div className="AdminAddFacilitatorPopup-title">{adminUiTexts.addFacilitatorToGamePopup.title}</div>
					<div 
						className="AdminAddFacilitatorPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminAddFacilitatorPopup-body">
					<div className="AdminAddFacilitatorPopup-text">
						{renderMarkdown(adminUiTexts.addFacilitatorToGamePopup.text)}
					</div>
					<div className="AdminAddFacilitatorPopup-input">
						<input 
							type="text"
							name="email"
							placeholder={adminUiTexts.addFacilitatorToGamePopup.emailPlaceholder}
							value={email ? email : ''}
							onChange={(e) => {updateEmail(e);}}
						/>
					</div>
					<div className={'AdminAddFacilitatorPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
					<div className="AdminAddFacilitatorPopup-confirmBtn">
						<Button 
							isLoading={isLoading}
							classes={['blue', 'adminPopup']}
							text={adminUiTexts.addFacilitatorToGamePopup.okBtn}
							onClick={() => {handleAddFacilitator();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminAddFacilitatorPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired,
	addFacilitatorsToGame: PropTypes.func.isRequired,
};

export default AdminAddFacilitatorPopup;
