import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {sortArrayByProperty} from 'helpers/array-helper';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {adminUiTexts} from 'data/ui-texts';
import {
	checkIfModuleIsCompleted, 
	getTotalTimeSpentInModule, 
	getModuleErrorAverage, 
	getAverageStars
} from 'helpers/module-helper';
import FacilitatorTaskPopup from 'components/facilitator/facilitator-task-popup/facilitator-task-popup';
import Select from 'components/ui/select/select';
import './admin-statistics.scss';

const AdminStatistics = ({type, deviceInfo, gameId, gamesData, tagsData, usersData}) => {
	/* Stats array */
	const [moduleStats, setModuleStats] = useState([]);

	/* Selected tags */
	const [tagIds, setTagIds] = useState([]);
	const toggleTagId = (tagId) => {
		const newTagIds = JSON.parse(JSON.stringify(tagIds));
		const newTagIndex = newTagIds.indexOf(tagId);
		if (newTagIndex < 0) {
			newTagIds.push(tagId);
		} else {
			newTagIds.splice(newTagIndex, 1);
		}
		setTagIds(newTagIds);
	};

	/* Show tasks of specific module (popup) */	
	const [selectedModuleId, setSelectedModuleId] = useState(null);

	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('title');
	const [sortDirection, setSortDirection] = useState('ASC');

	/**
	 * Sort modules by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	/**
	 * Generate stats
	 */
	const generateStats = () => {
		
		/* Combine core, side quest and competition modules */
		const allModulesData = modulesData.concat(modulesCompetitionsData);

		let statsArray = [];
		allModulesData.forEach((moduleData) => {
			/* Ignore modules with no tasks */
			if (!moduleData.tasks || moduleData.tasks.length === 0) return;

			/* Get all sessions played in module (all games, all players) */
			let allModuleSessions = [];
			let numberOfPlayers = 0;
			let numberOfPlayersCompleted = 0;
			usersData.forEach((user) => {
				if (user.role === 'player' && user.games && user.games.length > 0) {
					user.games.forEach((game) => {
						/* If we want stats for specific game, check if game is a match */
						if (gameId && game.id !== gameId) return;

						/* Do not include removed players */
						if (game.isRemoved) return;
						
						/* Filter by tags */
						let isIncluded = true;
						if (tagIds.length > 0) {
							isIncluded = false;
							const gameData = gamesData.find((g) => {return g.id === game.id;});
							if (gameData && gameData.tags && gameData.tags.length > 0) {
								tagIds.forEach((tagId) => {
									if (isIncluded) return;
									if (gameData.tags.indexOf(tagId) >= 0) isIncluded = true;
								});
							}
						}
						if (!isIncluded) return;

						
						if (game.modules && game.modules.length > 0) {

							/* Get player module data */
							let playerModuleData = null;
							if (moduleData.areaId !== 'competition') {
								/* Core or side quest module */
								playerModuleData = game.modules.find((module) => {
									return module.moduleId === moduleData.id;
								});
							} else {
								/* Player can have several entries for same competition module */ 
								const playerModules = game.modules.filter((module) => {
									return module.moduleId === module.id;
								});
								if (playerModules.length > 0) {
									playerModuleData = {
										moduleId: module.id,
										sessions: []
									};
									playerModules.forEach((playerModule) => {
										if (playerModule.sessions && playerModule.sessions.length > 0) {
											playerModule.sessions.forEach((session) => {
												playerModuleData.sessions.push(session);
											});
										}
									});
								}
							}
							if (playerModuleData) {
								numberOfPlayers += 1;
							}
							if (checkIfModuleIsCompleted(playerModuleData)) numberOfPlayersCompleted += 1;
							if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
								playerModuleData.sessions.forEach((session) => {
									allModuleSessions.push(session);
								});
							}
						}
					});
				}
			});
	
			/* Get average time spent */
			const timeAvg = (allModuleSessions.length > 0
				? getTotalTimeSpentInModule({sessions: allModuleSessions}) / allModuleSessions.length
				: 0.0
			);

			/* Get average mistakes made */
			const mistakesAvg = (allModuleSessions.length > 0
				? getModuleErrorAverage({sessions: allModuleSessions})
				: 0.0
			);

			/* Get average stars earned */
			const starsAvg = (allModuleSessions.length > 0
				? getAverageStars(moduleData.id, allModuleSessions)
				: 0.0
			);

			/* Add stats for module to array */
			const statsObj = {
				isCompetition: (moduleData.areaId === 'competition' ? true : false),
				moduleId: moduleData.id,
				title: moduleData.title,
				sessions: allModuleSessions,
				numberOfPlayers,
				numberOfPlayersCompleted,
				timeAvg: Math.round(timeAvg * 10) / 10,
				mistakesAvg: Math.round(mistakesAvg * 10) / 10,
				starsAvg: Math.round(starsAvg * 10) / 10,
			};
			statsArray.push(statsObj);
		});

		setModuleStats(statsArray);
	};


	/* Component mounted */
	useEffect(() => {
		/* Generate stats */
		generateStats();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	

	/* Selected tag ids changed */
	useEffect(() => {
		generateStats();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagIds]);	

	/* Sorted stats */
	const sortedModuleStats = sortArrayByProperty(moduleStats, sortProperty, sortDirection);

	/* Selected module data */
	const selectedModuleData = (selectedModuleId 
		? moduleStats.find((moduleStat) => {return moduleStat.moduleId === selectedModuleId;})
		: null
	);


	return (
		<div className={'AdminStatistics ' + type + (gameId ? ' specificGame' : '')}>
			{/* Tags */}
			{tagsData && <div className="AdminStatistics-tags">
				<div className="AdminStatistics-tagsSelect">
					<Select 
						type="stats-tags" 
						placeholderText={adminUiTexts.tags}
						options={tagsData} 
						onSelect={toggleTagId} 
					/>
				</div>
				<div className="AdminStatistics-selectedTags">
					{tagIds.map((tagId) => {
						const tagData = tagsData.find((t) => {return t.id === tagId;});
						if (!tagData) return null;
						return (
							<div 
								key={tagId} 
								className="AdminStatistics-tag"
								onClick={() => {toggleTagId(tagId);}}
							>
								<span>{tagData.value}</span>
							</div>
						);
					})}
				</div>
			</div>}



			{/* Header */}
			<div className="AdminStatistics-header">
				<div 
					className={'AdminStatistics-cell title' 
						+ (sortProperty === 'title' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('title');}}
				><span>{adminUiTexts.module}</span></div>
				{gameId && <div 
					className={'AdminStatistics-cell playersStarted' 
						+ (sortProperty === 'numberOfPlayers' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('numberOfPlayers');}}
				><span>{adminUiTexts.startedBy}</span></div>}
				{gameId && <div 
					className={'AdminStatistics-cell playersCompleted' 
						+ (sortProperty === 'numberOfPlayersCompleted' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('numberOfPlayersCompleted');}}
				><span>{adminUiTexts.completedBy}</span></div>}
				<div 
					className={'AdminStatistics-cell time' 
						+ (sortProperty === 'timeAvg' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('timeAvg');}}
				>
					<span>{adminUiTexts.timeSpentAvg}</span>
				</div>
				<div 
					className={'AdminStatistics-cell mistakes'
						+ (sortProperty === 'mistakesAvg' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('mistakesAvg');}}
				><span>{adminUiTexts.mistakesAvgPerTask}</span></div>
				<div 
					className={'AdminStatistics-cell stars'
						+ (sortProperty === 'starsAvg' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('starsAvg');}}
				><span>{adminUiTexts.starsAvg}</span></div>
				<div className="AdminStatistics-cell iconEye"><span></span></div>
			</div>

			{/* Body */}
			<div className="AdminStatistics-body">
				{sortedModuleStats.map((moduleStat, index) => {
					return (
						<div key={index} className="AdminStatistics-row">
							<div className="AdminStatistics-cell title"><span>{moduleStat.title}</span></div>
							{gameId && <div className="AdminStatistics-cell playersStarted">
								<span>{moduleStat.numberOfPlayers}</span>
							</div>}
							{gameId && <div className="AdminStatistics-cell playersCompleted">
								<span>{moduleStat.numberOfPlayersCompleted}</span>
							</div>}
							<div className="AdminStatistics-cell time"><span>{moduleStat.timeAvg}</span></div>
							<div className="AdminStatistics-cell mistakes"><span>{moduleStat.mistakesAvg}</span></div>
							<div className="AdminStatistics-cell stars"><span>{moduleStat.starsAvg}</span></div>
							<div className="AdminStatistics-cell iconEye">
								<span onClick={() => {setSelectedModuleId(moduleStat.moduleId);}}></span>
							</div>
						</div>		
					);
				})}
			</div>

			{selectedModuleData &&
					<div className='AdminStatistics-popupOverlay' onClick={() => {setSelectedModuleId(null);}}>
						<FacilitatorTaskPopup 
							isFacilitator={true}
							isCompetition={selectedModuleData.isCompetition}
							moduleId={selectedModuleData.moduleId}
							managerId={'a'}
							deviceInfo={deviceInfo}
							playerData={{avater: null}}
							modulePlayerStats={[{sessions: selectedModuleData.sessions}]}
							closePopup={() => {setSelectedModuleId(null);}}
						/>
					</div>}
		</div>
	);

};

AdminStatistics.propTypes = {
	type: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	gameId: PropTypes.string,
	gamesData: PropTypes.array.isRequired,
	tagsData: PropTypes.array,
	usersData: PropTypes.array.isRequired,
};

export default AdminStatistics;
