import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {getPlayerStatsOfModules} from 'helpers/facilitator-helper';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import DownloadStatistics from '../download-statistics/download-statistics';
import './facilitator-statistics.scss';
import { sortArrayByProperty } from 'helpers/array-helper';

const FacilitatorStatistics = ({isFacilitator, pageData, handleGoToPage, gamePlayers}) => {
	/* Prepare array for player module data, grouped by module */
	let modulesPlayers = [];

	/* Get all players' played modules */
	const gamePlayersModules = gamePlayers.map((gamePlayer) => {return gamePlayer.modules;}).flat();
	
	/* Add player data for core and side quest modules */
	modulesData.forEach((module) => {
		modulesPlayers.push(gamePlayersModules.filter((playerModule) => {return playerModule.moduleId === module.id;}));
	});

	/* Add player data for competition modules */
	modulesCompetitionsData.forEach((module) => {
		/* Player can have several entries for same competition module */ 
		const playerModules = gamePlayersModules.filter((playerModule) => {
			return playerModule.moduleId === module.id;
		});
		if (playerModules.length > 0) {
			const playerModuleObj = {
				moduleId: module.id,
				sessions: []
			};
			playerModules.forEach((playerModule) => {
				if (playerModule.sessions && playerModule.sessions.length > 0) {
					playerModule.sessions.forEach((session) => {
						playerModuleObj.sessions.push(session);
					});
				}
			});

			modulesPlayers.push(playerModuleObj);
		} else {
			modulesPlayers.push([]);
		}
	});

	/* Get all modules data (Combine core, side quest and competition modules) */
	const allModulesData = modulesData.concat(modulesCompetitionsData);

	/* Get stats for each module */
	let modulesPlayersStats = getPlayerStatsOfModules(allModulesData, modulesPlayers);

	/* Default sorting property and direction for  */
	const [sortProperty, setSortProperty] = useState('title');
	const [sortDirection, setSortDirection] = useState('ASC');

	/**
	 * Sort modules by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	/* Sort stats */
	const sortedModulesPlayersStats = sortArrayByProperty(modulesPlayersStats, sortProperty, sortDirection);

	return (
		<div className="FacilitatorStatistics">
			{(isFacilitator && pageData && handleGoToPage) && <div className='FacilitatorStatistics-header'>
				<FacilitatorHeaderSubpage 
					page={pageData.id} 
					subpage='statistics'
					subpageIds={pageData.subpageIds}
					handleGoToPage={handleGoToPage}
				/>
				<DownloadStatistics type='modules' modulesPlayersData={modulesPlayers} modulesData={allModulesData} />
			</div>}

			<div className='FacilitatorStatistics-table'>
				<div className='FacilitatorStatistics-tableHeader'>
					<div 
						className={'FacilitatorStatistics-tableHeaderTitle' 
							+ (sortProperty === 'title' ? ' sortedBy ' + sortDirection : '')}
						onClick={() => {handleSortByProperty('title');}}
					><span>{facilitatorUiTexts.module}</span></div>
					<div 
						className={'FacilitatorStatistics-tableHeaderTitle' 
							+ (sortProperty === 'startedBy' ? ' sortedBy ' + sortDirection : '')}
						onClick={() => {handleSortByProperty('startedBy');}}
					><span>{facilitatorUiTexts.startedBy}</span></div>
					<div 
						className={'FacilitatorStatistics-tableHeaderTitle' 
							+ (sortProperty === 'completedBy' ? ' sortedBy ' + sortDirection : '')}
						onClick={() => {handleSortByProperty('completedBy');}}
					><span>{facilitatorUiTexts.completedBy}</span></div>
					<div 
						className={'FacilitatorStatistics-tableHeaderTitle' 
							+ (sortProperty === 'avgTimeSpent' ? ' sortedBy ' + sortDirection : '')}
						onClick={() => {handleSortByProperty('avgTimeSpent');}}							
					><span>{facilitatorUiTexts.avgTimeSpent}</span></div>
					<div 
						className={'FacilitatorStatistics-tableHeaderTitle' 
							+ (sortProperty === 'avgMistakes' ? ' sortedBy ' + sortDirection : '')}
						onClick={() => {handleSortByProperty('avgMistakes');}}
					><span>{facilitatorUiTexts.avgMistakesTask}</span></div>
					<div className={'FacilitatorStatistics-tableHeaderTitle'}>
						<span>{facilitatorUiTexts.taskStats}</span>
					</div>
				</div>

				{sortedModulesPlayersStats.map((statData, index) => {
					return (
						<div key={index} className='FacilitatorStatistics-tableRow'>
							<div className='FacilitatorStatistics-moduleItem bold'>{statData.title}</div>
							<div className='FacilitatorStatistics-moduleItem'>{statData.startedBy}</div>
							<div className='FacilitatorStatistics-moduleItem'>{statData.completedBy}</div>
							<div className='FacilitatorStatistics-moduleItem'>
								{statData.avgTimeSpent + ' ' + facilitatorUiTexts.minute}
							</div>
							<div className='FacilitatorStatistics-moduleItem'>{statData.avgMistakes}</div>
							<div className='FacilitatorStatistics-moduleItem iconEye' 
								onClick={() => {
									handleGoToPage(
										'analytics',
										'tasks',
										{moduleData: statData.moduleData, modulePlayersData: statData.modulePlayersData}
									);
								}}/>
						</div>
					);
				})}
			</div>
		</div>
	);

};

FacilitatorStatistics.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	pageData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	gamePlayers: PropTypes.array.isRequired,
};

export default FacilitatorStatistics;
