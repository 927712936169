import {avatarData} from 'data/avatar-data';
import {getPlayerItems} from './module-helper';


export function generateRandomAvatar() {
	/* Prepare avatar object */
	let avatar = {};

	/* Get random body type */
	const bodyTypeOptions = avatarData.categories.find((category) => {return category.id === 'body-type';}).options;
	const randomIndex = Math.floor(Math.random() * bodyTypeOptions.length);
	avatar['body-type'] = bodyTypeOptions[randomIndex].id;

	/* Loop over categories */
	avatarData.categories.forEach((category) => {

		/* Ignore body type */
		if (category.id === 'body-type') return;

		/* No beard if body type is f or f2 */
		if (category.id === 'beard' && (avatar['body-type'] === 'f' || avatar['body-type'] === 'f2')) {
			avatar[category.id] = 'none';
			return;
		};

		if (category.isAutoGenerated) {
			/* Get available options */
			let options = category.options.filter((option) => {return option.isLocked !== true;});
			/* Special cases */
			if (category.id === 'hair') {
				/* Exclude bald */
				options = options.filter((option) => {return option.id !== 'none';});
			}
			if (category.id === 'beard' || category.id === 'glasses') {
				/* 50/50 if beard / glasses is selected */
				const numberOfOptions = options.length;
				for (let i = 1; i < numberOfOptions; i++) options.push({id: 'none'});
			}
			if (category.id === 'clothes') {
				/* Options much match body type */
				options = options.filter((option) => {
					return (option.bodyTypeIds.indexOf(avatar['body-type']) >= 0);
				});
			}

			/* Get random option */
			const randomIndex = Math.floor(Math.random() * options.length);
			avatar[category.id] = options[randomIndex].id;
		} else {
			avatar[category.id] = 'none';
		}	
	});

	return avatar;
}

/**
 * Get player avatar if available
 * @param {object} playerData 
 * @returns 
 */
export function getAvatar(playerData) {
	if (!playerData || !playerData.avatar) return null;

	let avatar = {};
	avatarData.categories.forEach((category) => {
		avatar[category.id] = 'none';
		if (playerData.avatar[category.id]) {
			/* Get selected category option from player data */
			avatar[category.id] = playerData.avatar[category.id];
		} else {
			/* Get default category option (if it exists) */
			if (category.options) {
				const defaultOption = category.options.find((option) => {
					return (
						option.isDefault === true &&
						(
							!option.hasOwnProperty('bodyTypeIds') ||
							option.bodyTypeIds.indexOf(avatar['body-type']) >= 0
						)

					);
				});
				if (defaultOption) avatar[category.id] = defaultOption.id;
			}
		}
	});
	return avatar;
}

/**
 * Check if player has unlocked a specific item
 * @param {string} itemId 
 * @param {object} playerData 
 * @returns 
 */
export function checkIfItemIsUnlocked(itemId, playerData) {
	let itemIsUnlocked = false;

	if (
		playerData.items && 
		playerData.items.length > 0 &&
		playerData.items.some((item) => {return item.id === itemId;})
	) {
		itemIsUnlocked = true;
	}

	return itemIsUnlocked;
}


/**
 * Check if player has seen a newly unlocked item
 * @param {string} itemId 
 * @param {object} playerData 
 * @returns 
 */
export function checkIfItemIsSeen(itemId, playerData) {
	let itemIsSeen = true;

	if (checkIfItemIsUnlocked(itemId, playerData)) {
		itemIsSeen = false;
	}

	if (
		playerData.items && 
		playerData.items.length > 0 &&
		playerData.items.some((item) => {
			return item.id === itemId && item.isSeen === true;
		})
	) {
		itemIsSeen = true;
	}

	return itemIsSeen;
}


export function getNumberOfUnseenItemsInCategory(categoryId, playerData, currentBodyType) {
	let numberOfUnseenItemsInCategory = 0;

	const playerItems = getPlayerItems(playerData);
	const categoryData = avatarData.categories.find((category) => {return categoryId === category.id;});
	if (categoryData && categoryData.options) {
		categoryData.options.forEach((option) => {
			const hasBodyType = option.hasOwnProperty('bodyTypeIds');
			
			if (
				option.isLocked &&
				playerItems.some((item) => {return (item.id === option.id && !item.isSeen);}) &&
				(!hasBodyType || option.bodyTypeIds.some((bodyType) => {
					return bodyType === currentBodyType;
				}))
			) {
				numberOfUnseenItemsInCategory += 1;
			}
		});
	}

	return numberOfUnseenItemsInCategory;
}