import './logo.scss';

const Logo = () => {

	return (
		<div className="Logo" />
	);
};

export default Logo;
