/**
 * getReducedYearBook removes empty field and badges
 * @param yearBook (object)
 * @returns a new object containing reduced yearBook
 */
export const getReducedYearBook = (yearBook) => {
	return Object.fromEntries(
		Object.entries(yearBook).filter(([_, v]) => {
			return v !== '' && !Array.isArray(v);
		})
	);
};


/**
 * Goes through all reports in array and check if facilitator has seen all the reports for a player
 * @param reports (array of objects)
 */
export const checkIfAllReportsAreSeen = (reports, facilitatorId) => {
	return reports.every((report) => {
		if (report.hasOwnProperty('seenByFacilitatorId')) {
			return report.seenByFacilitatorId.includes(facilitatorId);
		} 
		return false;
	});
};