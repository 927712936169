const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		animateCharacter: true,
		text: `Welcome back to the deli. Let's explore the other side of the counter.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-first-place-to-go',
		subtype: 'images',
		layout: 'deli-2-department-areas',
		text: `Where's the first place we go?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // handwashing sink
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // couter for prep
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // three bay sink
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // supply shelf with gloves
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // display case
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // paper towels
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-second-place-to-go',
		subtype: 'images',
		layout: 'deli-2-department-areas',
		text: `Now where?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // handwashing sink
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // couter for prep
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // three bay sink
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // supply shelf with gloves
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // display case
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // paper towels
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-product-rotation-matters',
		text: `Does rotation matter to product in the department like it does the Sales Floor?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Of course it does.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'No',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-cooler-stock-important',
		image: 'cooler',
		text: `What are the four most important things to remember about stock in your cooler?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Maintain first in first out system', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Write the date on a product when you receive it', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Never store product on the floor', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Take action when you find short-dated product', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Open all boxes to ensure product is okay'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Store items that combine well close to each other'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, 
				text: 'Check the cooler temperature every hour',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `It's always a good idea to check the temperature. But you don't have to do it every hour.`
						})
					})
				]
			}),
		]
	}),
	/* BRANCHING STORY START */
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'deli-2-branching-story-old-sandwich',
		maxPoints: 2,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-17',
				image: 'sandwich-old',
				text: `Imagine the following. A customer asks for a sandwich and they specifically want a kind you only have one left of. However, you notice it doesn't look super fresh. What do you do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask them if they want something else', 
						nextStepId: 'db-18',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'Good choice! Let\'s see what they say.'
								})
							})
						],						
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Sell them the sandwich',
						nextStepId: 'db-19',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'Not the best choice. Even if you make the customer happy now, you risk they don\'t like the product.'
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Sell it with a discount',
						nextStepId: 'db-19',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Not a bad solution. But you still risk the customer not liking the product because it's not fresh.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: `Tell them you can't sell it`,
						nextStepId: 'db-20',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'It\'s probably the best choice. In the future, remember to pull the product and restock with fresh items.'
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-18',
				image: 'sandwich-old',
				text: `They say they want that one and that they don't mind how it looks. What do you do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `Sell them the sandwich`,
						nextStepId: 'db-19',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'If they insist, you can\'t really argue. But it\'s not a great situation.'
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Sell it with a discount`,
						nextStepId: 'db-19',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'Not a bad solution when they insist.'
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `Tell them you can't sell it`,
						nextStepId: 'db-20',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'You should just have said this right away. And in the future, remember to pull the product and restock.'
								})
							})
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'db-19',
				images: [{type: 'character', characterId: 'woman-with-sandwich-happy'}],
				textType: 'info',
				text: `The customer leaves with the sandwich.`

			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'db-20',
				images: [{type: 'character', characterId: 'lady-glasses-angry-pizza'}],
				textType: 'info',
				text: `They aren't happy about it. But you convince them to try something else instead.`,
			})
		]
	}),
	/* BRANCHING STORY END */
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `On the next screen, click on the area in the deli where you want to do something next. Notice the clock and see if you can get everything ready.`
	}),
	/* CLOCK TASKS START */
	Object.assign({}, taskTemplates.clockTasksTemplate.mainTemplate, {
		taskId: 'deli-2-clock-tasks-deli',
		layout: 'deli-2',
		startTime: {hours: 7, minutes: 0},
		maxDurationMinutes: 120,
		tasks: [
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, {
				id: 'cl-01',
				durationMinutes: 15,
				hasImage: true,
				title: 'Salad bar / Hot bar',
				solvedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'clock-task-solved',
							title: 'Well done!',
							text: 'You filled the napkin holder.'
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.organizeTemplate.mainTemplate, {
				id: 'cl-02',
				durationMinutes: 15,
				hasImage: true,
				hasSolvedImage: true,
				title: 'Sales floor shelves',
				text: 'Place the merchandise correctly on the shelves.',
				layout: 'deli-2-shelves',
				showPoints: false,
				showSolutionWhenDone: true,
				items: [
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 1, containerId: 'a1', image: 'can-corn', alt: 'corn', correctContainerIds: ['a10', 'a11']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 2, containerId: 'a2', image: 'can-peas', alt: 'greenpeas', correctContainerIds: ['a7', 'a8', 'a9']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 3, containerId: 'a3', image: 'can-mushrooms', alt: 'mushrooms', correctContainerIds: ['a1', 'a2', 'a3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 4, containerId: 'a4', image: 'can-peas', alt: 'greenpeas', correctContainerIds: ['a7', 'a8', 'a9']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 5, containerId: 'a5', image: 'can-tomatos', alt: 'tomatos', correctContainerIds: ['a4', 'a5', 'a6']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 6, containerId: 'a6', image: 'can-beans', alt: 'baked beans', correctContainerIds: ['a13', 'a14', 'a15']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 7, containerId: 'a7', image: 'can-peas', alt: 'greenpeas', correctContainerIds: ['a7', 'a8', 'a9']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 8, containerId: 'a9', image: 'can-tomatos', alt: 'tomatos', correctContainerIds: ['a4', 'a5', 'a6']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 9, containerId: 'a10', image: 'can-tomatos', alt: 'tomatos', correctContainerIds: ['a4', 'a5', 'a6']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 10, containerId: 'a11', image: 'can-mushrooms', alt: 'mushrooms', correctContainerIds: ['a1', 'a2', 'a3'],
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 11, containerId: 'a12', image: 'can-corn', alt: 'corn', correctContainerIds: ['a10', 'a11']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 12, containerId: 'a13', image: 'can-mushrooms', alt: 'mushrooms', correctContainerIds: ['a1', 'a2', 'a3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 13, containerId: 'a14', image: 'can-corn-2', alt: 'corn', correctContainerIds: ['b1', 'b2', 'b3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 14, containerId: 'a15', image: 'can-beans', alt: 'baked beans', correctContainerIds: ['a13', 'a14', 'a15']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 15, containerId: 'a16', image: 'can-onions', alt: 'onions', correctContainerIds: ['b1', 'b2', 'b3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 16, containerId: 'a17', image: 'can-beans', alt: 'baked beans', correctContainerIds: ['a13', 'a14', 'a15']
					}),
				],
				mainContainers: [
					Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
						id: 'shelves',
						containers: [
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves', finalItemId: 3}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves', finalItemId: 10}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves', finalItemId: 12}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 5}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves', finalItemId: 8}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 9}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 2}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves', finalItemId: 4}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'shelves', finalItemId: 7}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a10', type: 'shelves', finalItemId: 1}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a11', type: 'shelves', finalItemId: 11}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a12', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a13', type: 'shelves', finalItemId: 6}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a14', type: 'shelves', finalItemId: 14}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a15', type: 'shelves', finalItemId: 16}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a16', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a17', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a18', type: 'shelves'})
						]
					}),
					Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
						id: 'remove',
						containers: [
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'remove', finalItemId: 13}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'remove', finalItemId: 15}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'remove'}),
						]
					}),
				],
				rules: [
					{type: 'items-in-correct-containers'},
				],
				doneEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'special-points',
						specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
							id: 'deli-2-problem-solver-1', pointId: 'problem-solver', value: 1
						}),
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate)
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.spotErrorsTemplate.mainTemplate, {
				id: 'cl-03',
				durationMinutes: 15,
				showPoints: false,
				hasImage: true,
				title: 'Prep area',
				text: 'What items should not be in the prep area where you make sandwiches?',
				layout: 'deli-2-prep-table',
				errors: [
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'pot'}),
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'meat1'}),
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'spoon'}),
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'meat2'}),
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'meat3'})
				],
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.multipleChoiceTemplate.mainTemplate, {
				id: 'cl-04',
				durationMinutes: 15,
				hasImage: true,
				title: 'Spill on sales floor',
				text: 'How do you handle the spill?',
				image: 'puddle',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1, text: 'Find a mop and clean it', isCorrect: true
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2, 
						text: `I'm checking inventory right now, so I put a wet floor sign near it for now`, 
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'Sometimes, this is the right choice. But whenever possible spills should take priority and be addressed as soon as possible.'
								})
							})
						],
					}),
				],
				solvedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'clock-task-solved',
							title: 'Well done!',
							text: 'You clean the spill.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, {
				id: 'cl-05',
				durationMinutes: 15,
				hasImage: true,
				title: 'Display cases',
				solvedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'clock-task-solved',
							title: 'Well done!',
							text: 'You clean the dirt.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.multipleChoiceTemplate.mainTemplate, {
				id: 'cl-06',
				delayMinutes: 60,
				endTime: 90,
				durationMinutes: 15,
				failedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'manager',
							text: 'You forgot to make sure the soup was ready. Don\'t forget it next time.'
						})
					})
				],
				title: 'Soup',
				text: 'You check to see if the soup has the right temperature. Where do you measure it?',
				image: 'soup',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1, text: 'At the top', isCorrect: true
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2, text: `In the middle`, 
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3, text: `At the bottom`, 
					}),
				],

			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.spotErrorsTemplate.mainTemplate, {
				id: 'cl-07',
				delayMinutes: 60,
				endTime: 120,
				durationMinutes: 15,
				showPoints: false,
				doneWhenAllErrorsSpotted: true,
				showDefaultResultPopup: false,
				title: 'Chicken case',
				text: `If something's not right here, press it to fix it.`,
				layout: 'deli-1-chicken-case',
				errors: [
					Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'temperature'}),
				],
				solvedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'story',
							text: 'Take all chicken at or below 140F to oven to re-heat to above safe temperature zones.'
						})
					})
				],
				failedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'manager',
							text: 'You forgot to check the temperature in the chicken case. Prepared chicken must be no less than 140F and should be much closer to cooked temperature (180F) at 9am.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.organizeTemplate.mainTemplate, {
				id: 'cl-08',
				delayMinutes: 60,
				endTime: 120,
				durationMinutes: 15,
				failedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'manager',
							text: `You forgot to check dates in the cold food case. Anything that has today's date must be removed before 9am.`
						})
					})
				],
				title: 'Cold food case',
				text: `Current date is March 17th.<br />What items should be removed or reduced?`,
				layout: 'deli-2-sandwiches',
				showSolutionWhenDone: true,
				items: [
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 1, containerId: 'a1', image: 'sandwich-1', alt: 'sandwich', correctContainerIds: ['c1', 'c2', 'c3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 2, containerId: 'a2', image: 'sandwich-2', alt: 'sandwich', correctContainerIds: ['b1', 'b2', 'b3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 3, containerId: 'a3', image: 'sandwich-3', alt: 'sandwich', correctContainerIds: ['b1', 'b2', 'b3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 4, containerId: 'a4', image: 'sandwich-4', alt: 'sandwich', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 5, containerId: 'a5', image: 'sandwich-5', alt: 'sandwich', correctContainerIds: ['c1', 'c2', 'c3']
					}),
					Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
						id: 6, containerId: 'a6', image: 'sandwich-6', alt: 'sandwich', correctContainerIds: ['a1', 'a2', 'a3', 'a4', 'a5', 'a6']
					}),
				],
				mainContainers: [
					Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
						id: 'shelves',
						containers: [
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 4}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves'}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves', finalItemId: 6}),
						]
					}),
					Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
						id: 'reduce',
						title: 'Reduce',
						containers: [
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'reduce', finalItemId: 2}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'reduce', finalItemId: 3}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'reduce'}),
						]
					}),
					Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
						id: 'remove',
						title: 'Remove',
						containers: [
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'remove', finalItemId: 1}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'remove', finalItemId: 5}),
							Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'remove'}),
						]
					})
				],
				rules: [
					{type: 'items-in-correct-containers'},
				],
				doneEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'special-points',
						specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
							id: 'deli-2-problem-solver-2', pointId: 'problem-solver', value: 1
						}),
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate)
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, {
				id: 'cl-09',
				delayMinutes: 60,
				durationMinutes: 15,
				isRequired: false,
				title: 'Salad bar',
				solvedEffects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'story',
							text: 'The crocks are full, nothing to do here right now.'
						})
					})
				]
			}),
		]
	}),
	/* CLOCK TASKS END */
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'deli-2-order-deep-clean-display-case',
		text: `You have to deep clean a display case, place actions in the right order.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'remove-product', text: 'Remove product and place in storage', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'remove-shelves', text: 'Remove interal shelving', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'sanitize', text: 'Wash and sanitize inside case', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'glass', text: 'Clean fans and outside of case', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's look at some of the clamshell side dishes just made.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-clamshell-label',
		subtype: 'images',
		layout: 'deli-2-clamshell-label',
		text: `How should the label be placed on these?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // top, wrapped to bottom
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // hanging off bottom
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // on top, covering food
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // sideways
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-rot-chicken-label',
		text: `What can we add to the label of these Rotiserre Chickens?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'The time completed cooking', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Correct. Hope you still have marker handy.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Time it was put on sales floor',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Date it was created',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's on the label already.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'The name of this chicken',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-affect-planning',
		text: `What can effect our production planning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Season', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Time of Day', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Stock Level', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Weather', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Horoscopes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Insurance',
			}),
		]
	}),
	/* BRANCHING STORY START */
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'deli-2-branching-story-tailgate-trays',
		maxPoints: 12,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'db-31',
				image: 'db-31',
				text: `Help me gather this for all the tailgate trays today.`,
				nextStepId: 'db-32',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-32',
				text: `It takes 10 min to make each one. How much product do we have out?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `3 trays worth`,
						nextStepId: 'db-33',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: 'Good!'
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `8 trays worth`,
						nextStepId: 'db-33',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `Total production log`,
						nextStepId: 'db-33',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4,
						text: `1 tray worth`,
						nextStepId: 'db-33',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'db-33',
				text: `Psst. These trays require salami and pepperoni.`,
				nextStepId: 'db-34',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-34',
				text: `The pepperoni comes pre-sliced. I just use it on both sides.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `We can't change the recipe.`,
						nextStepId: 'db-35',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Works for me.`,
						nextStepId: 'db-35',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `I'll do mine with only Salami then for balance.`,
						nextStepId: 'db-35',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-35',
				text: `You go cut the cheese.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `Ok. Let me change gloves.`,
						nextStepId: 'db-37',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Ok. But I'm not certified yet.`,
						nextStepId: 'db-36',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'db-36',
				text: `Okay, then I'll cut the cheese.`,
				nextStepId: 'db-37',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'db-37',
				text: `These are extra and can go in the case tomorrow.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `Ok. I'll print labels now.`,
						nextStepId: 'db-38',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Ok. I'll print the labels in the morning.`,
						nextStepId: 'db-38',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistTemplate, {
				id: 'db-38',
				text: `Okay, let's see how much of a stickler for rules you are.`,
				checklistData: {
					type: 'deli-2 branching-story',
					items: [
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Food only out 30 min. or less`,
							conditions: [{type: 'selected-or', options: [{stepId: 'db-32', optionId: 1}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Math smarts`,
							conditions: [{type: 'selected-or', options: [{stepId: 'db-32', optionId: 1}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Stuck to recipe`,
							conditions: [{type: 'selected-or', options: [{stepId: 'db-34', optionId: 1}]}]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Proper dating`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'db-37', optionId: 1}]}
							]
						}),				
					]
				}
			})
		]
	}),
	/* BRANCHING STORY END */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-open-product-goes',
		text: `Open product goes ...`,	
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, text: 'Behind the counter for slicing',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Front of display',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Back of display',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'No where, throw out after opening', 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Well we are going to have some real high shrink.'
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Deli has many types of cases to maintain besides the service counter.`
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'deli-2-case-types',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-lunch-meats-after-opening',
		text: `How many days are Lunch Meats good for after opening?`,	
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '7 days', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '3 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '21 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '1 month', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '1 day', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-cheese-after-opening',
		text: `How many days are cheeses good for after opening?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '7 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '3 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '21 days', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '1 month', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '1 day', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-dry-cured-meat-after-opening',
		text: `How many days are Dry Cured Meats good for after opening?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '7 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '14 days', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '21 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '1 month', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '1 day', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-cold-chicken-shelf-life',
		text: `What's the shelf life of cold chicken?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '3 days', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '14 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '7 days',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: '5 days', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: '1 day', 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-every-good-hot-bar-has',
		subtype: 'images',
		layout: 'deli-2-hot-bar-items',
		text: `What should every good hot bar have on it?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // fried chicken
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // broccoli
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // baked potatoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Grilled Chicken
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Biscuits
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-2-spot-errors-salad-bar-not-ready',
		layout: 'deli-2-salad-bar-not-ready',
		text: `Is this salad bar ready? Spot the issues that need to be fixed.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'missing-tongs'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'smears'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'light'}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now, let's look at the department before we close it up. We'll take the bars one at a time. `
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-high-five',
		subtype: 'images',
		shuffleOptions: false,
		layout: 'high-five',
		text: 'Are you ready?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Oh ya!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You missed.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-2-spot-errors-salad-bar-issues',
		layout: 'deli-2-salad-bar-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'missing-supplies1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'missing-supplies2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'cord'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'water'}),
		]
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-2-spot-errors-hot-bar-issues',
		layout: 'deli-2-hot-bar-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'light'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'vent'}),
		]
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-broken-lamp',
		text: `Whoa, a broken bulb? I'll get rid of the food but did you look for glass anywhere?`,	
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Yes. I swept it up', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Yes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'No',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Yes. I mopped it up',
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-2-spot-errors-service-case-issues',
		layout: 'deli-2-service-case-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tag'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glass'}),
		]
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'deli-2-spot-errors-oven-issues',
		layout: 'deli-2-oven-issues',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'chicken'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'on'}),
		]
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-salad-case-close-up',
		text: `What else do we do for the Service Salad Case?`,	
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Remove and wash spoons', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Change bowls', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Cover up leftovers', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Close doors', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Print new logs', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: 'Put leftovers into clamshells',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Since we just cleaned up the case, let's talk more about cleaning.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'deli-2-multiple-choice-clean-slicer',
		text: `How often should the slicer be cleaned?`,	
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Every four hours', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'End of day',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'End of shift',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Start of shift',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `That's some good work.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `You know your stuff. See you next time.`,
		characterItems: ['disco-chicken'],
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['disco-chicken'],
					text: 'A Disco Chicken has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'del2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

		/* Check taskId is set when required and unique */
		if (task.isSolveToContinue) {
			if (task.taskId) {
				if (taskIdsArr.indexOf(task.taskId) >= 0) {
					console.error('Task id not unique: ', task.taskId);
				} else {
					taskIdsArr.push(task.taskId);
				}
			} else {
				console.error('Missing task id for task: ', task);
			}
		}
});


export {
	moduleTasks
};
