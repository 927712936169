import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './order-dnd-item.scss';

const OrderDndItem = (props) => {
	const {
		isMoveable, 
		isLocked,
		locationIndex, 
		lastMoved,
		itemData, 
		classes, 
		handleMoveItems,
	} = props;

	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {
			type: 'item', 
			id: itemData.id,
			locationIndex: locationIndex, 
			isLocked: isLocked
		},
		canDrag() {return (isMoveable);},
		isDragging(monitor) {return (itemData.id === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Drop functionality */
	const [, drop] = useDrop({
		accept: 'item',
			
		hover(hoveringItem) {
			/* Item cannot be moved */
			if (!isMoveable) return;

			/* Item hovers over itself */
			if (hoveringItem.id === itemData.id) return;

			/* Check if item can be hovered (not in the middle of moving) */			
			if (lastMoved && Date.now() - lastMoved <= 200) return null;

			/* Call set preview */
			handleMoveItems(itemData.id, hoveringItem.id);
		}
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Opacity (invisible if dragging) */
	let opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'OrderDndItem';
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}

	const ref = useRef(null);
	const dragDropRef = drag(drop(ref));
	
	return (
		<div 
			ref={dragDropRef} 
			className={className} 
			style={{opacity}}
		>
			{(itemData && itemData.text) && <span>{itemData.text}</span>}
			<div
				className={'OrderDndItem-handle' + (!isMoveable ? ' disabled' : '')}
			/>
		</div>
	);
};

OrderDndItem.propTypes = {
	isMoveable: PropTypes.bool.isRequired,
	isLocked: PropTypes.bool.isRequired,
	locationIndex: PropTypes.number.isRequired,
	lastMoved: PropTypes.number,
	itemData: PropTypes.object.isRequired,
	classes: PropTypes.array,
	handleMoveItems: PropTypes.func.isRequired,
};

export default OrderDndItem;
