const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		animateCharacter: true,
		background: 'ocean-raft',
		showAvatar: true,
		text: `Welcome to Food Safety Island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		showAvatar: true,
		text: `Before we touch any food...`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-multiple-choice-on-department-entry',
		text: 'What is first when entering any department?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Wash your hands',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Clean off your feet',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Clock in',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Good. But not for Food Safety.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Announce yourself',
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Match dress code',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Good. But not for Food Safety.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-sinks',
		subtype: 'images',
		layout: 'food-safety-1-sinks',
		text: 'Correct. Now which sink do you use?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // small hand wash sink
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Yes, only in the handwashing sink.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // large 3 bay sink
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'food-safety-1-spot-errors-sink',
		text: 'Is this area ready for use? Click on the issues. Click OK when you think you have them all. ',
		layout: 'food-safety-1-sink',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'out-of-soap'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'paper-towels-out'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cloth-draped-over'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'floor-mat-turned-up'
			}),
		],
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		subtype: 'countdown',
		shownDelay: 30,
		delayTime: 5,
		layout: 'food-safety-1-sink',
		text: `Well done. Now let's wash for 30 seconds.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `30 seconds is longer than most people think but it’s effective.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-whos-ready',
		subtype: 'images',
		layout: 'food-safety-1-whos-ready',
		text: 'Which person is ready here?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // with hairnet and beardnet
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // with hairnet
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // with hat and beardnet
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // just beardnet
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'food-safety-1-branching-story-quick-sandwich',
		maxPoints: 13,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-01',
				image: 'chef',
				text: `Let's make us a quick sandwich.<br /><br/><b>What do you do first?<b/>`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Put on gloves', 
						nextStepId: 'qb-02',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Get out ham',
						nextStepId: 'qb-03',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Get out cheese',
						nextStepId: 'qb-03',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-02',
				text: `Good. Now let's get out the ham. Do you want to use a slicer?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Yes', 
						nextStepId: 'qb-04',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'No',
						nextStepId: 'qb-05',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-03',
				text: `Do you want to use the slicer?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Yes', 
						nextStepId: 'qb-04',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'No',
						nextStepId: 'qb-07',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-04',
				textType: 'info',
				text: `You cut yourself without proper training. Now you can only count to 9.`,
				nextStepId: 'qb-17'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-05',
				nextStepId: 'qb-06',
				textType: 'info',
				text: `Oh, not trained? I'll do it today then.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-06',
				text: `What's next?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Get out the cheese',
						nextStepId: 'qb-08',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'Change gloves',
						nextStepId: 'qb-09',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-07',
				text: `Oh, not trained? I'll do it today then. But aren't you forgetting something?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Of course, gloves.',
						nextStepId: 'qb-10',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'Nope',
						nextStepId: 'qb-12',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-08',
				text: `Don't forget to change gloves. Always change between species or commodity.`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Change gloves, then get the cheese',
						nextStepId: 'qb-11',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-09',
				nextStepId: 'qb-11',
				textType: 'info',
				text: `Exactly! Always change between species or commodity.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-10',
				nextStepId: 'qb-11',
				textType: 'info',
				text: `You put on gloves and make the sandwhich. And you remember to change gloves when changing between species or commodity.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-11',
				text: `You finish the sandwhich. Let's go eat it. But where?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Eat at counter',
						nextStepId: 'qb-16',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'Eat in break room',
						nextStepId: 'qb-15',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-12',
				text: `Always wear gloves. And ..?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Change gloves when changing between species or commodity',
						nextStepId: 'qb-13',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: 'Wear extra gloves on top of the gloves',
						nextStepId: 'qb-14',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-13',
				text: `Exactly! You finish the sandwhich. Where do you eat it?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Eat at counter',
						nextStepId: 'qb-16',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: 'Eat in break room',
						nextStepId: 'qb-15',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-14',
				nextStepId: 'qb-11',
				textType: 'info',
				text: `No need for that. But always change gloves when changing between species or commodity.`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-15',
				textType: 'info',
				text: `Yeah, that's good.`,
				nextStepId: 'qb-17',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-16',
				textType: 'info',
				text: `You should really go eat in the break room.`,
				nextStepId: 'qb-17',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistTemplate, {
				id: 'qb-17',
				text: `Here's your result.`,
				checklistData: {
					type: 'food-safety branching-story',
					title: 'Checklist',
					items: [
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Wore gloves`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-01', optionId: 1}, {stepId: 'qb-07', optionId: 1}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Didn't use equipment without training`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-02', optionId: 2}, {stepId: 'qb-03', optionId: 2}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Changed gloves`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-06', optionId: 2}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Ate in proper area`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-11', optionId: 2}, {stepId: 'qb-13', optionId: 2}]}
							]
						}),
					]
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-multiple-choice-what-now',
		text: 'Now that we are back. What do we do?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Wash hands',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Wash hands everytime you enter a department.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Put on gloves',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Wipe feet',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Put away food',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'This should be done before leaving.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `You meet that wizard yet?`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'food-safety-1-spot-errors-wizard-ready',
		text: 'Why is he not ready for work? Click on the issues. Click okay when you think you have them all.',
		layout: 'food-safety-1-wizard-ready',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'no-beardnet'
			}),			
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'wearing-shorts'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'oven-mitts-on'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'shoulder-covered-in-cat-hair'
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		background: 'ocean-raft',
		animateCharacter: true,
		text: `Fine. Fine. I'll go change.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-multiple-choice-hold-stuff',
		text: 'I\'ll be right back. Can you hold my cellphone and my lunch?',
		image: 'bag-lunch',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'No personal items',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Sure',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Take the food and run',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Are you sure you want the wizard\'s lunch?'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'I\'d rather not',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'chef',
							text: 'Probably shouldn\'t, but why?'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `That's right no personal items back here.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-multiple-choice-personal-items',
		text: 'Why can\'t I bring personal items?',
		image: 'wizard',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'They aren\'t sanitized',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Not secure',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Distraction',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Lunch will spoil',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'No cell service',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `Well done. Listen to newbie here wizard.`,
		extraCharacters: [
			{id: 'wizard-1', type: 'right'},
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-washing-hands',
		subtype: 'images',
		layout: 'food-safety-1-washing-hands',
		text: 'Which of these require washing hands after completing?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Restroom signs
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // garbage bags
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // bandaid/bandage
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // cutting carrots
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // washing dishes *pile of dishes with soap*
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `Many foods require specific temperatures.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-cooler-temps',
		subtype: 'images',
		layout: 'food-safety-1-cooler-temps',
		text: 'What temperature should coolers be below?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 40
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 32
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 35
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 0
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // 72
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-cooler-temps-2',
		subtype: 'images',
		layout: 'food-safety-1-cooler-temps-2',
		text: 'What temperature should coolers be above?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 32
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 20
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 0
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 15
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // 38
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-freezer-temps',
		subtype: 'images',
		layout: 'food-safety-1-freezer-temps',
		text: 'What temperature should freezers be below?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 20
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 0
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 32
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // -10
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // 5
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `Keep an eye on Coolers/Freezers.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `Same in floor cases. Anyting above the line can spoil.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'food-safety-1-spot-errors-should-move-items',
		text: 'Which of these should be moved? Click on the ones that should be moved. Click ok when you think you have them all.',
		layout: 'food-safety-1-should-move-items',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'above-line'
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `There's another thing that matters for food safety...`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-food-allergens',
		text: 'How many major Food Allergens in the U.S.?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '5',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '9',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '14',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '32',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-some-of-them',
		subtype: 'images',
		layout: 'food-safety-1-some-of-them',
		text: 'Which of these are considered allergens?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Milk
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Eggs
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Beef
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Corn
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-some-more',
		subtype: 'images',
		layout: 'food-safety-1-some-more',
		text: `Which of these are considered allergens?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Fish
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Peanuts
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Lettuce
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Juice
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-some-more-2',
		subtype: 'images',
		layout: 'food-safety-1-some-more-2',
		text: `Which of these are considered allergens?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Wheat
				isCorrect: true,
			}),						
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Fish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Shellfish
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Bananas
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-treenuts',
		text: 'Are treenuts and peanuts the same?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'No',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Yes',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-treenuts-allergy',
		text: 'Which can people be allergic to?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Both',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Neither',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Peanuts',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Tree Nuts',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		background: 'ocean-raft',
		text: `... When prepping food, some things really matter.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'food-safety-1-mc-why-prep',
		text: 'Why is that?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Allergies',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Color',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Taste Sensitivities',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Pricing',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Production Time',
				isCorrect: false,
			})
		]
	}),
	// FOOD SAFETY DIALOG 1
	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'food-safety-1-dialogue-grilled-chicken',
		characterId: 'old-man',
		maxPoints: 8,
		introText: 'A customer approaches you at the Deli counter.',
		introTextType: 'info',
		introCharacter: 'old-man flipped',
		introShowAvatar: true,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-22',
				type: 'dialogue',
				text: 'Hi, I was wanting to some grilled chicken, but I have Celiac Disease.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Oh, so you can\'t have gluten?',
						nextStepId: 'SD-23',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'Oh, so you can\'t eat meat?', 
						nextStepId: 'SD-24',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'Oh, so you can\'t have peanut oil?', 
						nextStepId: 'SD-24',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, 
						text: 'Oh, so you can\'t eat anything cooked in eggs?', 
						nextStepId: 'SD-24',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-23',
				type: 'dialogue',
				nextStepId: 'SD-25',
				text: 'Yes, correct. Thank you.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-24',
				type: 'dialogue',
				nextStepId: 'SD-25',
				text: 'Actually, I can\'t eat gluten.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-25',
				type: 'dialogue',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'No problem, I\'ll just change my gloves.',
						nextStepId: 'SD-26',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'No problem, I\'ll just wash off these utensils.', 
						nextStepId: 'SD-27',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'No problem, I\'ll just let you pick it up.', 
						nextStepId: 'SD-28',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 4, 
						text: 'No problem, I\'ll just wash off the chicken.', 
						nextStepId: 'SD-29',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-26',
				type: 'dialogue',
				text: 'Thanks.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'You\'re welcome.',
						nextStepId: 'SD-31',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'And I\'ll also wash of these utensils.', 
						nextStepId: 'SD-27',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-27',
				type: 'dialogue',
				text: 'Thanks.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'You\'re welcome.',
						nextStepId: 'SD-31',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'And I\'ll also change my gloves.', 
						nextStepId: 'SD-30',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-28',
				type: 'dialogue',
				nextStepId: 'SD-31',
				image: 'angry',
				text: 'Um. I\'d rather not.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-29',
				type: 'dialogue',
				nextStepId: 'SD-41',
				image: 'annoyed',
				text: 'Uh. Never mind, I\'m going.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-30',
				type: 'dialogue',
				nextStepId: 'SD-31',
				text: 'Oh, thanks for doing that too.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-31',
				type: 'dialogue',
				text: 'Are those grilled on the same place as any buns?',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'I\'m not sure. Let me ask the Manager.',
						nextStepId: 'SD-32',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'I don\'t think so.', 
						nextStepId: 'SD-33',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'Yes.', 
						nextStepId: 'SD-35',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-32',
				type: 'action',
				text: 'You talk to the manager who says that bread is toasted separately. You tell it to the customer.',
				nextStepId: 'SD-34',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-33',
				type: 'dialogue',
				text: 'Can you find out.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'Yes, I\'ll ask the manager.',
						nextStepId: 'SD-32',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-34',
				type: 'dialogue',
				text: 'Great. And I\'m sorry to be such a problem.',
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, 
						text: 'No, not at all.',
						nextStepId: 'SD-37',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, 
						text: 'It\'s not that bad.',
						nextStepId: 'SD-36',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, 
						text: 'Are you really Celiac?',
						nextStepId: 'SD-38',
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-35',
				type: 'dialogue',
				showPoints: true,
				isFinalStep: true,
				character: 'old-man',
				text: 'Never mind then.'
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-36',
				type: 'dialogue',
				text: 'Um. ya. Sorry to bother you I guess.',
				nextStepId: 'SD-39',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-37',
				type: 'dialogue',
				text: 'Thank you for understanding.',
				nextStepId: 'SD-40',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-38',
				type: 'dialogue',
				text: 'Why would I lie?',
				nextStepId: 'SD-41',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-39',
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-man',
				text: 'The customer leaves.',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-40',
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-man',
				text: 'The customer leaves smiling.',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'SD-41',
				type: 'action',
				showPoints: true,
				isFinalStep: true,
				character: 'old-man',
				text: 'The customer leaves looking angry.',
			}),
		]		
	}),
	// FOOD SAFETY 1 DONE
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `Great start. If you can care for food safety...`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		background: 'ocean-raft',
		text: `You can care for a goldfish.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'fish',
					text: 'A goldfish has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'fs1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};