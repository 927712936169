import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { profileUiTexts } from 'data/ui-texts';
import { sortArrayByProperty } from 'helpers/array-helper';
import { getTotalStars, getGamePlayers } from 'helpers/highscore-helper';
import PopupYearbook from 'components/ui/popup-yearbook/popup-yearbook';
import Avatar from 'components/game/avatar/avatar';
import './profile-highscore.scss';

const ProfileHighscore = ({ userData, playerData, reportPlayer }) => {

	
	const [sortBy, setSortBy] = useState('maxWeeklyStars');

	// keep track of yearbook
	const [currentPlayerData, setCurrentPlayerData] = useState(null);
	// keep track of highscore array
	const [highscoreList, setHighscoreList] = useState([]);

	/**
	 * Load highscore data for players in game if it exsist else use default data
	 * listen to the toogle showThisWeekOnly and change the highscorelist to match
	 */
	useEffect(() => {
		if (playerData.id) {
			getGamePlayers(playerData.id).then((response) => {
				if (response.status === 'success') {
					let highscoreList = [];
					response.data.forEach((player) =>{
						highscoreList.push({
							id: player.id,
							maxWeeklyStars: getTotalStars(player, true),
							maxTotalStars: getTotalStars(player, false),
							name: player.name,
							yearbookData: player.yearbookData,
							avatar: player.avatar,
							lastLogin: player.lastLogin,
							maxBadges: player.badges?.length,
						});
					}
					);
					setHighscoreList(sortArrayByProperty(highscoreList, sortBy, 'DESC'));
				} else {
					this.setState({isLoading: false});
				}
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='ProfileHighscore'>
			<div className='ProfileHighscore-title'><span>{profileUiTexts.highscore}</span></div>
			<div className='ProfileHighscore-content' onClick={(e) => {e.stopPropagation();}}>
				<div className='ProfileHighscore-buttons'>
					<div 
						className={'ProfileHighscore-toggleBtn' 
							+ (sortBy ===  'maxWeeklyStars' ? ' active' : '')}
						onClick={() => {setSortBy('maxWeeklyStars');}}
					><span>{profileUiTexts.weekly}</span>
					</div>
					<div 
						className={'ProfileHighscore-toggleBtn' 
							+ (sortBy ===  'maxTotalStars' ? ' active' : '')}
						onClick={() => {setSortBy('maxTotalStars');}}
					><span>{profileUiTexts.total}</span>
					</div>
					<div 
						className={'ProfileHighscore-toggleBtn' 
							+ (sortBy ===  'maxBadges' ? ' active' : '')}
						onClick={() => {setSortBy('maxBadges');}}
					><span>{profileUiTexts.badges}</span>
					</div>
				</div>

				<div className={'ProfileHighscore-list ' +
			((playerData && playerData.role !== 'player') ? 'small' : '')}>
					{highscoreList && highscoreList.map((player, index) => {
						if (index > appConfig.highscoreListLength) { 
							return null;
						}
						return (
							<div key={index} 
								className={'ProfileHighscore-highscoreEntry ' 
							+ `position-${index + 1}`}
								onClick={()=>{setCurrentPlayerData(player);}}>
								<div className='ProfileHighscore-position'>
									<span>{`${index + 1}`}</span>
									<span>{`${player.name}`}</span>
								</div>

								{/* show max stars or weekly star or badges */}
								<div className={'ProfileHighscore-' + (sortBy === 'maxBadges' ? 'badge' : 'star') }>
									<span>{`${player[sortBy]}`}</span>
								</div>
								
								

								<div className='ProfileHighscore-avatarContainer'>
									<Avatar type="button" playerData={player} />
								</div>
							</div>
						);
					})}
					{(playerData && playerData.role === 'player') 
					&& <div className='ProfileHighscore-highscoreEntry last'
						onClick={()=>{
							setCurrentPlayerData(highscoreList.find((player) => {
								return player.id === userData.id;
							}));
						}}>
						<div className='ProfileHighscore-position'>
							<span>{`${highscoreList.findIndex((player) => {
								return player.id === userData.id;
							}) + 1}`}</span>
							<span>{`${userData.name}`}</span>
						</div>

						{/* show max stars or weekly star or badges */}
						<div className={'ProfileHighscore-' + (sortBy === 'maxBadges' ? 'badge' : 'star')}>
							<span>
								{`${highscoreList.find((player) => {
									return player.id === userData.id;
								})?.[sortBy]}`}
							</span>
						</div>
						


						<div className='ProfileHighscore-avatarContainer'>
							<Avatar type="button" playerData={playerData} />
						</div>
								
					</div>}
				</div>
			</div>
			{currentPlayerData &&
				<PopupYearbook 
					userData={currentPlayerData}
					playerData={currentPlayerData} 
					closeYearbook={() => {setCurrentPlayerData(false);}}
					canEdit = {false}
					currentPlayer={userData}
					reportPlayer={reportPlayer}
				/>
			}
		</div>
	);
};

ProfileHighscore.propTypes = {
	userData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	reportPlayer: PropTypes.func.isRequired,
};

export default ProfileHighscore;