import dayjs from 'dayjs';
import {facilitatorUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from './array-helper';

/**
 * Check if message has been filled out correct
 * @param {boolean} sendToAll
 * @param {array} selectedAreaIds
 * @param {string} text
 * @returns 
 */
export function checkMessage(sendToAll, selectedAreaIds, text) {
	let errMsg = null;

	/* No areas selected */
	if (!sendToAll && selectedAreaIds.length === 0) {
		errMsg = facilitatorUiTexts.newMessagePopup.errAreas;
		return errMsg;
	}

	/* Missing description */
	if (!text || text.length === 0) {
		errMsg = facilitatorUiTexts.newMessagePopup.errDescription;
		return errMsg;
	}

	return errMsg;
};




/**
 * Get all messages
 * @param {playerData} playerData
 * @param {object} gameData 
 * @returns 
 */
export function getAllMessages(playerData, gameData) {
	let messages = [];
	const deletedMessageIds = 
		playerData.deletedMessageIds ? JSON.parse(JSON.stringify(playerData.deletedMessageIds)) : [];

	if (gameData && gameData.messages && gameData.messages.length > 0) {
		messages = gameData.messages.filter((c) => {
			/* Check if message was created after player joined game */
			if (dayjs(c.created).diff(dayjs(playerData.created), 'days') < 0) {
				return false;
			}

			/* Check if message has been deleted by player */
			if (deletedMessageIds.indexOf(c.id) >= 0) {
				return false;
			}

			/* Check if player has access to at least 1 of the required areas for the message */
			let playerHasAccessToRequiredAreas = true;
			if (
				c.selectedAreaIds && c.selectedAreaIds.length > 0 &&
				playerData.disabledAreaIds && playerData.disabledAreaIds.length > 0
			) {
				playerHasAccessToRequiredAreas = false;
				c.selectedAreaIds.forEach((areaId) => {
					if (playerHasAccessToRequiredAreas) return;
					if (playerData.disabledAreaIds.indexOf(areaId) < 0) playerHasAccessToRequiredAreas = true;
				});	
			}
			return playerHasAccessToRequiredAreas;
		});
	}

	/* Sort by creation date (newest first) */
	messages = sortArrayByProperty(messages, 'created', 'DESC');

	return messages;
};