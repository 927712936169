const adminPagesData = [
	{
		id: 'games', 
		title: 'Games'
	},
	{
		id: 'facilitators', 
		title: 'Facilitators'
	},
	{
		id: 'statistics', 
		title: 'Statistics'
	},
	{
		id: 'game-content', 
		title: 'Game content',
		subpageIds: ['statistics'],
	},
	{
		id: 'users',
		title: 'Users'
	}
];

const gamePopupSections = [
	{id: 'info', title: 'Info'},
	{id: 'content', title: 'Content'},
	{id: 'players', title: 'Players'},
	{id: 'stats', title: 'Stats'}
];



export {
	adminPagesData,
	gamePopupSections
};
