// Triggers: finish-module-session, complete-task, task-effect
// Types: placeholder, stars-in-modules, complete-area, max-stars-in-area, total-number-of-stars, special-points, error, success


const badgesData = [
	{
		id: 'veteran-deli',
		title: 'Veteran Deli',
		textPinboard: 'Complete all deli modules',
		textPopup: 'You\'ve proven yourself to be a deli veteran!',
		triggers: ['finish-module-session'],
		type: 'complete-area',
		areaId: 'deli',
	},
	{
		id: 'deli-superstar',
		title: 'Deli Superstar',
		textPinboard: 'Earn 3 stars in Deli 1 and in Deli 2',
		textPopup: 'You are a deli superstar!',
		triggers: ['finish-module-session'],
		type: 'stars-in-modules',
		stars: 6,
		moduleIds: ['deli-1', 'deli-2']
	},
	{
		id: 'placeholder-1',
		type: 'placeholder',
	},
	{
		id: 'stardust',
		title: 'Stardust',
		textPinboard: 'Gain 10 stars in total',
		textPopup: 'You\'ve earned enough stars to gain the Stardust badge!',
		triggers: ['complete-task'],
		type: 'total-number-of-stars',
		numberOfStars: 10,
	},
	{
		id: 'shooting-star',
		title: 'Shooting Star',
		textPinboard: 'Gain 20 stars in total',
		textPopup: 'You\'ve earned enough stars to gain the Shooting Star badge!',
		triggers: ['complete-task'],
		type: 'total-number-of-stars',
		numberOfStars: 20,
	},
	{
		id: 'blazing-sun',
		title: 'Blazing Sun',
		textPinboard: 'Gain 30 stars in total',
		textPopup: 'You\'ve earned enough stars to gain the Blazing Sun badge!',
		triggers: ['complete-task'], // Should it be complete module?
		type: 'total-number-of-stars',
		numberOfStars: 30,
	},
	{
		id: 'smooth-talker',
		title: 'Smooth Talker',
		textPinboard: 'Choose the right options in customer dialogues',
		textPopup: 'You are truly a smooth talker!',
		newTierText: 'You\'ve earned a point towards getting the Smooth Talker badge.',
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'smooth-talker',
		numberOfPoints: 3
	},
	{
		id: 'problem-solver',
		title: 'Problem Solver',
		textPinboard: 'When you solve certain tasks, you earn progress towards getting this badge.',
		textPopup: 'You\'ve proven yourself to be a true problem solver!',
		newTierText: `You've earned a point towards getting the Problem Solver badge.`,
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'problem-solver',
		numberOfPoints: 3
	},
	{
		id: 'lucky-charm',
		title: 'Lucky Charm',
		textPinboard: 'You get this when you really need some good luck.',
		textPopup: 'You made 5 mistakes in a row. You\'ll need this lucky charm!',
		triggers: ['task-effect', 'complete-task'],
		type: 'error',
		streakValue: 5,
	},
	{
		id: 'undercover-hero',
		title: 'Undercover Hero',
		textPinboard: 'Accept your calling',
		textPopup: 'You\'ve earned the badge Undercover Hero!',
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'undercover-hero',
		numberOfPoints: 1,
	},
	{
		id: 'helping-hand',
		title: 'Helping Hand',
		textPinboard: 'Help customers or coworkers.',
		textPopup: `You've shown yourself to be a true helping hand.`,
		newTierText: `You've earned a point towards getting the Helping Hand badge.`,
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'helping-hand',
		numberOfPoints: 5
	},
	{
		id: 'produce-machine',
		title: 'Produce Machine',
		textPinboard: 'Know your produce.',
		textPopup: 'You are totally a produce machine.',
		newTierText: 'You\'ve earned a point towards getting the produce machine badge.',
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'produce-machine',
		numberOfPoints: 5,
	},
	{
		id: 'food-safety-specialist',
		title: 'Food Safety Specialist',
		textPinboard: 'Know all about food safety.',
		textPopup: 'You definitely know all about food safety.',
		triggers: ['finish-module-session'],
		type: 'stars-in-modules',
		stars: 3,
		moduleIds: ['food-safety-1'],
	},
	{
		id: 'neat-freak',
		title: 'Neat Freak',
		textPinboard: `Be a freak when it comes to keeping things neat.`,
		textPopup: `You are a neat freak!`,
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'neat-freak',
		numberOfPoints: 1,
	},
	{
		id: 'perfectionist',
		title: 'Perfectionist',
		textPinboard: 'Make no mistakes.',
		textPopup: 'You\'ve solved 30 tasks without making mistakes!',
		newTierText: `You've earned a point towards getting the Perfectionist badge`,
		triggers: ['task-effect', 'complete-task'],
		type: 'success',
		streakValue: 30,
	},

	{
		id: 'stickler-for-details',
		title: 'Stickler For Details',
		textPinboard: 'Be a stickler for details.',
		textPopup: `You've proven that you truly are a stickler for details.`,
		newTierText: `You've earned a point towards getting the Stickler for Detail badge.`,
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'stickler-for-details',
		numberOfPoints: 5
	},
	{
		id: 'released-the-kraken',
		title: 'Released the Kraken',
		textPinboard: 'Release the kraken. Which maybe you shouldn\'t.',
		textPopup: 'You\'ve released the kraken, you fool!',
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'released-the-kraken',
		numberOfPoints: 1,
	},
	{
		id: 'veteran-produce',
		title: 'Veteran Produce',
		textPinboard: 'Complete all produce modules',
		textPopup: 'You\'ve proven yourself to be a produce veteran!',
		triggers: ['finish-module-session'],
		type: 'complete-area',
		areaId: 'produce',
	},
	{
		id: 'produce-superstar',
		title: 'Produce Superstar',
		textPinboard: 'Earn 3 stars in Produce 1 and in Produce 2',
		textPopup: 'You are a produce superstar!',
		triggers: ['finish-module-session'],
		type: 'stars-in-modules',
		stars: 6,
		moduleIds: ['produce-1', 'produce-2']
	},
	{
		id: 'always-prepared',
		title: 'Always Prepared',
		textPinboard: 'Make sure you are prepared.',
		textPopup: `You are always prepared!`,
		newTierText: `You've earned a point towards the Always Prepared badge.`,
		triggers: ['task-effect', 'complete-task'],
		type: 'special-points',
		pointId: 'always-prepared',
		numberOfPoints: 3
	}
];


export {
	badgesData
};