const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `Welcome to Level 2.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `On the ground floor of production, we can talk Ground Beef.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-hamburger',
		subtype: 'images',
		layout: 'meat-2-hamburger',
		text: 'Which can be used in Hamburger?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'butcher',
							text: 'Fresh only in the grind.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'meat-2-order-grinds-process',
		text: `What is process with grinds?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'seperate-species', text: `Seperate by species`, correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'seperate-fat', text: 'Seperate by fat content', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'grind', text: 'Grind', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'clean', text: 'Clean before changing species', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-grinds-per-day',
		text: 'How many times per day should we grind?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `2`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `1`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `4`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Every hour`
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'meat-2-order-returning-trimmings',
		text: `When returning trimmings to cooler, what's the proces?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'store', text: `Store in lug`, correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'cover', text: 'Cover', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'date', text: 'Date with marker', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'place', text: 'Place in cooler', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-how-long-on-floor',
		text: 'How long was the meat being cut out of refrigeration?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Less than 30 min`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Less than 60 min`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Less than 45 min`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Less than 5 min`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-keep-every-day-for-year',
		text: 'What must we keep every day for a year?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Grind logs`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Production planner`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Hamburger meat`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Sanitation schedule`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-days-on-shelf-grind',
		text: 'How many days on shelf for in-house grind?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `2 days`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `1 day`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `5 days`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `1 week`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `There are many cuts in meat. You should familarize yourself in the store with the look and purpose of each.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-repackage',
		text: 'If we repackage anything, what do we do?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Keep original dating`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Put new dating`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Depends on if we recut`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `You can't repackage`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-help-plan-production',
		subtype: 'images',
		layout: 'meat-2-help-plan-production',
		text: 'Which of these will help us plan production?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // holidays
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // order history
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // zombie sighting
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // sanitation
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-more-for-holidays',
		text: 'Do we make more of everything for Holidays?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Depends on Holiday and item`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Depends on Holiday`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Depends on item`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Never change production for Holidays`
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `Always plan ahead. Make sure you never get out too much product for the task at hand.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-july-3rd',
		text: 'Today is July 3rd. What major things can we anticpate for production today?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Cutting more steaks`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! We sold a lot of grilling steaks yesterday. We need to restock.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Cutting more beef stew`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Preparing more pork roasts`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Preparing more beef roasts`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Checking case temperatures more often`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! July is getting hotter every day, and cases have to work harder than you might realize.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `Let's look at the freezer door.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-2-spot-errors-freezer',
		text: 'What is wrong?',
		layout: 'meat-2-freezer',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sign',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'butcher',
							text: `Never cover up product doors.`,
						})
					})
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-thaw-and-sell',
		text: 'What does Thaw & Sell meat mean?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Thaw it before stocking`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'butcher',
							text: `Yes. Don't put it on the shelf frozen.`,
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Thaw it before selling`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Thaws at customer home`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Thaw during receiving`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-shelf-life-after-thaw',
		text: 'What happens to the shelf life after thaw?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `It goes down`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `It stays the same`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `It restarts`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `It goes up`
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'meat-2-organize-meat-case',
		layout: 'meat-2-meat-case',
		text: `Load this meat case.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, containerId: 'b1', image: 'meat-1', alt: 'sausages 5/16', correctContainerIds: ['a1']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, containerId: 'b2', image: 'meat-2', alt: 'sausages 5/14', correctContainerIds: ['a2']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, containerId: 'b3', image: 'meat-3', alt: 'sausages 5/12', correctContainerIds: ['a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 4, containerId: 'b4', image: 'meat-4', alt: 'ground meat 5/14', correctContainerIds: ['a4']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 5, containerId: 'b5', image: 'meat-5', alt: 'ground meat 5/12', correctContainerIds: ['a5']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 6, containerId: 'b6', image: 'meat-6', alt: 'ground meat 5/11', correctContainerIds: ['a6']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 7, containerId: 'b7', image: 'meat-7', alt: 'bacon 5/15', correctContainerIds: ['a7']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 8, containerId: 'b8', image: 'meat-8', alt: 'bacon 5/13', correctContainerIds: ['a8']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 9, containerId: 'b9', image: 'meat-9', alt: 'bacon 5/11', correctContainerIds: ['a9']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'final', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'final', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'final', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'final', finalItemId: 7}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'final', finalItemId: 8}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'final', finalItemId: 9}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b8', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b9', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'meat-2-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-2-spot-errors-meat-case',
		text: 'Besides the meat, what else should we check?',
		layout: 'meat-2-meat-case',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'temperature',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'price',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'vent',
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `Make sure everything is clean and running corectly. And check that labels are correct.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher-arm-out',
		characterItems: ['checklist-meat-2'],
		text: `Let's check our checklist.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher-arm-out',
		characterItems: ['checklist-meat-2'],
		text: `Those 4 things help us keep our Quality and Food Safety at its best.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-things-we-record',
		text: `What kinds of things do we record?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Inventory`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Shrink`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Production`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Cleaning`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Bathroom breaks`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `Meat Department has a lot of things to clean ...`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `But some are detailed and dangerous. Make sure you follow the process every step.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher',
		text: `If in doubt about a cleaning process, you should always ask a manager.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-2-mc-do-before-we-leave',
		text: `Before we leave, what can we do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Check cooler/freezer doors`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Turn off equipment`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Take a break`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Leave nothing out`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Stock the floor`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'butcher-arm-out',
		characterItems: ['shirt-cowprint'],
		text: `More good work.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['shirt-m-cowprint', 'shirt-f-cowprint'],
					text: 'Shirt with Cow print has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'meat2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};