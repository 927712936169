import PropTypes from 'prop-types';
import {ReactComponent as ManagerSVG} from 'assets/images/characters/manager.svg';
import CharacterItem from '../character/character-item';
import './manager.scss';

const Manager = ({page, managerPose, managerId, items}) => {

	return (
		<div className={'Manager ' + page + ' ' + managerPose + ' manager-' + managerId}>
			<ManagerSVG />
			{items && items.map((item, index) => {
				return (
					<CharacterItem 
						key={index}
						character={managerPose}
						managerId={managerId}
						item={item}
					/>
				);
			})}
		</div>
	);
};

Manager.propTypes = {
	page: PropTypes.string.isRequired,
	managerPose: PropTypes.string.isRequired,
	managerId: PropTypes.string.isRequired,
	items: PropTypes.array,
};

export default Manager;
