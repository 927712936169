import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './facilitator-remove-player-popup.scss';

const FacilitatorRemovePlayerPopup = ({gameId, playerData, setRemovePlayerId, removePlayerFromGame}) => {
	/* Get player name and prepare popup text */
	const playerName = (playerData ? playerData.name : '???');
	const popupText = JSON.parse(JSON.stringify(facilitatorUiTexts.removePlayerFromGamePopup.text))
		.replace(/%player%/g, playerName);

	/* Handle remove player */
	const [isRemovingPlayer, setIsRemovingPlayer] = useState(false);
	const handleRemovePlayer = () => {
		setIsRemovingPlayer(true);
		removePlayerFromGame(gameId, playerData.id).then(() => {
			/* Player removed, close popup */
			setRemovePlayerId(null);
		}).catch((error) => {
			/* Error removing player */
			console.error(error);
			setIsRemovingPlayer(false);
		});
	};

	return (
		<div className="FacilitatorRemovePlayerPopup" onClick={() => {setRemovePlayerId(null);}}>
			<div className="FacilitatorRemovePlayerPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="FacilitatorRemovePlayerPopup-header">
					<div className="FacilitatorRemovePlayerPopup-closeBtn" onClick={() => {setRemovePlayerId(null);}} />
					<div className="FacilitatorRemovePlayerPopup-title">
						{facilitatorUiTexts.removePlayerFromGamePopup.title}
					</div>
				</div>
				<div className="FacilitatorRemovePlayerPopup-body">
					<div className="FacilitatorRemovePlayerPopup-text">
						{renderMarkdown(popupText)}
					</div>
					<div className="FacilitatorRemovePlayerPopup-buttons">
						<Button 
							isLoading={isRemovingPlayer}
							classes={['blue', 'adminPopup']}
							text={facilitatorUiTexts.removePlayerFromGamePopup.removeBtn}
							onClick={() => {handleRemovePlayer();}}
						/>
						<Button 
							classes={['blue', 'adminPopup']}
							text={facilitatorUiTexts.removePlayerFromGamePopup.cancelBtn}
							onClick={() => {setRemovePlayerId(null);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorRemovePlayerPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	setRemovePlayerId: PropTypes.func.isRequired,
	removePlayerFromGame: PropTypes.func.isRequired,
};

export default FacilitatorRemovePlayerPopup;
