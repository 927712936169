import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Select from 'components/ui/select/select';
import './admin-game-popup-info.scss';

const AdminGamePopupInfo = (props) => {
	const {
		gameData, 
		usersData, 
		tagsData, 
		updateStoreInfo, 
		addTag, 
		removeTag, 
		toggleShowAddFacilitatorPopup,
		toggleRemoveFacilitatorPopup,
		toggleShowDeleteGamePopup
	} = props;

	/* Store info */
	const [storeName, setStoreName] = useState(gameData.storeName ? gameData.storeName : '');
	const [storeAddress1, setStoreAddress1] = useState(gameData.storeAddress1 ? gameData.storeAddress1 : '');
	const [storeAddress2, setStoreAddress2] = useState(gameData.storeAddress2 ? gameData.storeAddress2 : '');
	useEffect(() => {
		setStoreName(gameData.storeName ? gameData.storeName : '');
		setStoreAddress1(gameData.storeAddress1 ? gameData.storeAddress1 : '');
		setStoreAddress2(gameData.storeAddress2 ? gameData.storeAddress2 : '');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameData.storeName, gameData.storeAddress1, gameData.storeAddress2]);
	
	/* Input store info */
	const inputStoreInfo = (event) => {
		if (event.target.name === 'storeName') setStoreName(event.target.value);
		if (event.target.name === 'storeAddress1') setStoreAddress1(event.target.value);
		if (event.target.name === 'storeAddress2') setStoreAddress2(event.target.value);
	};

	/* Save new store info */
	const saveStoreInfo = (event) => {
		const updates = {[event.target.name]: event.target.value};
		updateStoreInfo(updates);
		event.target.blur();
	};

	/* Get creation info */
	const createdOn = (gameData && gameData.created ? dayjs(gameData.created).format('MM/DD-YY') : '-');
	const createdBy = (gameData && gameData.createdByUserId 
		?	usersData.find((user) => {return user.id === gameData.createdByUserId;})
		: '-'
	);

	/* Get facilitators info */
	let gameFacilitators = [];
	if (gameData && gameData.facilitatorEmails && gameData.facilitatorEmails.length > 0) {
		gameData.facilitatorEmails.forEach((facilitatorEmail) => {
			const facilitatorData = usersData.find((user) => {
				return (user.email === facilitatorEmail);
			});
			if (facilitatorData) {
				gameFacilitators.push(facilitatorData);
			} else {
				gameFacilitators.push({email: facilitatorEmail, name: null});
			}
		});
	}

	/* Tag options */
	let tagOptions = tagsData.filter((tag) => {
		return (!gameData || !gameData.tags || gameData.tags.length === 0 || gameData.tags.indexOf(tag.id) < 0);
	});

	/* Game url */
	const gameUrl = appConfig.gameUrl + '/' + (gameData && gameData.url ? gameData.url : '');

	return (
		<div className="AdminGamePopupInfo">
			{/* Store info */}
			<div className="AdminGamePopupInfo-infoBlock store">
				<div className="AdminGamePopupInfo-infoBlockTitle"><span>{adminUiTexts.connectedStore}:</span></div>
				<div className="AdminGamePopupInfo-infoBlockRow storeName">
					<input 
						type="text" 
						name="storeName"
						placeholder="name of store"
						value={storeName ? storeName : ''}
						onBlur={() => {setStoreName(gameData.storeName ? gameData.storeName : '');}}
						onChange={(e) => {inputStoreInfo(e);}}
						onKeyUp={(e) => {if (e.key === 'Enter') saveStoreInfo(e);}}
					/>
				</div>
				<div className="AdminGamePopupInfo-infoBlockRow storeAddress1">
					<input 
						type="text" 
						name="storeAddress1"
						placeholder="address line 1"
						value={storeAddress1 ? storeAddress1 : ''}
						onBlur={() => {setStoreAddress1(gameData.storeAddress1 ? gameData.storeAddress1 : '');}}
						onChange={(e) => {inputStoreInfo(e);}}
						onKeyUp={(e) => {if (e.key === 'Enter') saveStoreInfo(e);}}
					/>
				</div>
				<div className="AdminGamePopupInfo-infoBlockRow storeAddress2">
					<input 
						type="text" 
						name="storeAddress2"
						placeholder="address line 2"
						value={storeAddress2 ? storeAddress2 : ''}
						onBlur={() => {setStoreAddress2(gameData.storeAddress2 ? gameData.storeAddress2 : '');}}
						onChange={(e) => {inputStoreInfo(e);}}
						onKeyUp={(e) => {if (e.key === 'Enter') saveStoreInfo(e);}}
					/>
				</div>
			</div>

			{/* Game info */}
			<div className="AdminGamePopupInfo-infoBlock game">
				<div className="AdminGamePopupInfo-infoBlockTitle"><span>{adminUiTexts.basicInfo}:</span></div>
				<div className="AdminGamePopupInfo-infoBlockRow createdOn">
					<span>{adminUiTexts.createdOn} {createdOn}</span>
				</div>
				<div className="AdminGamePopupInfo-infoBlockRow createdBy">
					<span>{adminUiTexts.createdBy} {createdBy ? createdBy.name : '-'}</span>
				</div>
				<div className="AdminGamePopupInfo-infoBlockRow url">
					<span>{adminUiTexts.url}: <a href={'https://' + gameUrl} target="_blank" rel="noreferrer">{gameUrl}</a></span>
				</div>
			</div>

			{/* Tags */}
			<div className="AdminGamePopupInfo-infoBlock tags">
				<div className="AdminGamePopupInfo-infoBlockTitle"><span>{adminUiTexts.tagsForStats}:</span></div>
				<div className="AdminGamePopupInfo-tagInput">
					<Select 
						isDisabled={false}
						canSearch={true} 
						canAddOption={true}
						type="tags" 
						placeholderText={adminUiTexts.typeToAddTag}
						noMatchText={adminUiTexts.noMatchesPressEnter}
						options={tagOptions}
						onSelect={addTag}
					/>
				</div>
				{(gameData && gameData.tags) && <div className="AdminGamePopupInfo-gameTags">
					{gameData.tags.map((tagId, index) => {
						const tagData = tagsData.find((tag) => {return tag.id === tagId;});
						if (!tagData) return null;
						return (
							<div key={index} className="AdminGamePopupInfo-gameTag">
								<div className="AdminGamePopupInfo-gameTagValue"><span>{tagData.value}</span></div>
								<div className="AdminGamePopupInfo-gameTagRemoveBtn">
									<Button 
										classes={['removeFacilitator']}
										text={adminUiTexts.remove}
										onClick={() => {removeTag(tagId);}}
									/>
								</div>
							</div>
						);
					})}
				</div>}
			</div>

			{/* Facilitators */}
			<div className="AdminGamePopupInfo-facilitators">
				<div className="AdminGamePopupInfo-facilitatorsHeader">
					<div className="AdminGamePopupInfo-facilitatorsTitle">
						<span>{adminUiTexts.facilitators}:</span>
					</div>
					<div className="AdminGamePopupInfo-addFacilitatorBtn">
						<Button 
							classes={['addFacilitator']} 
							text={adminUiTexts.addFacilitator} 
							onClick={() => {toggleShowAddFacilitatorPopup(true);}}
						/>
					</div>
				</div>
				<div className="AdminGamePopupInfo-facilitatorsBody">
					{gameFacilitators.map((facilitator, index) => {
						return (
							<div key={index} className="AdminGamePopupInfo-facilitatorRow">
								<div className={'AdminGamePopupInfo-facilitatorName' 
									+ (facilitator.name ? '' : ' invited')}
								>
									<span>{facilitator.name ? facilitator.name : adminUiTexts.invited}</span>
								</div>
								<div className="AdminGamePopupInfo-facilitatorEmail">
									<span>{facilitator.email}</span>
								</div>
								<div className="AdminGamePopupInfo-facilitatorDeleteBtn">
									<Button 
										classes={['removeFacilitator']}
										text={adminUiTexts.remove}
										onClick={() => {toggleRemoveFacilitatorPopup(facilitator.email);}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Delete game button */}
			<div className="AdminGamePopup-deleteGameBtn">
				<Button
					classes={['deleteGame']}
					text={adminUiTexts.deleteGame}
					onClick={() => {toggleShowDeleteGamePopup(true);}}
				/>
			</div>

		</div>
	);

};

AdminGamePopupInfo.propTypes = {
	gameData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	updateStoreInfo: PropTypes.func.isRequired,
	addTag: PropTypes.func.isRequired,
	removeTag: PropTypes.func.isRequired,
	toggleShowAddFacilitatorPopup: PropTypes.func.isRequired,
	toggleRemoveFacilitatorPopup: PropTypes.func.isRequired,
	toggleShowDeleteGamePopup: PropTypes.func.isRequired,
};

export default AdminGamePopupInfo;
