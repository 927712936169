import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';

/**
 * Get player modules data
 * @param {object} playerGameData 
 * @param {string} competitionId
 * @returns {array}
 */
export function getPlayerModulesData(playerGameData, competitionId = null) {
	let playerModulesData = [];

	if (
		competitionId && 
		playerGameData &&
		playerGameData.competitions
	) {
		playerModulesData = JSON.parse(JSON.stringify(playerGameData.competitions));
	} else {
		if (
			!competitionId &&
			playerGameData && 
			playerGameData.modules
		) {
			playerModulesData = JSON.parse(JSON.stringify(playerGameData.modules));
		}
	}

	return playerModulesData;
};

/**
 * Get player module data
 * @param {string} moduleId
 * @param {object} playerGameData 
 * @returns 
 */
export function getPlayerModuleData(moduleId, playerGameData, competitionId = null) {
	const playerModulesData = getPlayerModulesData(playerGameData, competitionId);
	const playerModuleData = playerModulesData.find((module) => {return module.moduleId === moduleId;});
	if (!playerModuleData) return null;
	return playerModuleData;
}

/**
 * Get player badges 
 * @param {object} playerGameData 
 * @returns {array}
 */
export function getPlayerBadges(playerGameData) {
	const playerBadges = (playerGameData && playerGameData.badges ? [...playerGameData.badges] : []);
	return playerBadges;
}

/**
 * Get player special points
 * @param {object} playerGameData 
 * @returns {array}
 */
export function getPlayerSpecialPoints(playerGameData) {
	const playerSpecialPoints = (playerGameData.specialPoints ? [...playerGameData.specialPoints] : []);
	return playerSpecialPoints;
}

/**
 * Get player items
 * @param {object} playerGameData 
 * @returns {array}
 */
export function getPlayerItems(playerGameData) {
	const playerItems = (playerGameData.items ? [...playerGameData.items] : []);
	return playerItems;
}

/**
 * Get player data for specific module session
 * @param {array} playerModulesData 
 * @param {number} moduleIndex 
 * @returns 
 */
export function getPlayerModuleSession(playerModulesData, moduleIndex) {
	/* Return null if parameters are invalid */
	if (moduleIndex < 0 || playerModulesData.length <= moduleIndex) return null;
	if (!playerModulesData[moduleIndex].sessions || playerModulesData[moduleIndex].sessions.length === 0) return null;
	
	return playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1];
}

/**
 * Get player data for specific module session
 * @param {array} playerModulesData 
 * @param {string} moduleId 
 * @returns 
 */
export function getPlayerModuleSessionById(playerGameData, moduleId, competitionId = null) {
	const playerModulesData = getPlayerModulesData(playerGameData, (competitionId ? true : false));
	const moduleIndex = (competitionId 
		? playerModulesData.findIndex((m) => {return m.competitionId === competitionId;})
		: playerModulesData.findIndex((m) => {return m.moduleId === moduleId;})
	);
	return getPlayerModuleSession(playerModulesData, moduleIndex);
}

/**
 * Create new player module
 * @param {string} moduleId 
 * @param {string} competitionId 
 * @returns 
 */
export function createNewPlayerModule(moduleId, competitionId = null) {
	const playerModule = {
		moduleId: moduleId,
		competitionId,
		maxStars: 0,
		maxPoints: 0,
		sessions: [
			{...getSessionTemplate(moduleId, competitionId)}
		],
	};
	return playerModule;		
}

/**
 * Start new module session
 * @param {string} moduleId 
 * @param {object} playerGameData 
 * @returns 
 */
export function starNewSession(moduleId, playerGameData, competitionId = null) {
	let playerModulesData = getPlayerModulesData(playerGameData, competitionId);
	const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === moduleId;});
	if (moduleIndex < 0) return null;
	if (!playerModulesData[moduleIndex].hasOwnProperty('sessions')) return null;
	playerModulesData[moduleIndex].sessions.push(getSessionTemplate(moduleId, competitionId));

	return playerModulesData;
}

/**
 * Get template for player session data
 * @param {string} moduleId 
 * @returns 
 */
export function getSessionTemplate(moduleId, competitionId) {
	const moduleData = (competitionId
		? modulesCompetitionsData.find((m) => {return m.id === moduleId;})
		: modulesData.find((m) => {return m.id === moduleId;})
	);
	const	taskId = (moduleData.tasks && moduleData.tasks.length > 0 ? moduleData.tasks[0].id : null);
	const sessionTemplate = {
		isCompleted: false,
		started: dayjs(new Date()).format('YYYY-MM-DD'),
		currentTaskId: taskId,
		points: 0,
		milisecondsPlayed: 0,
		tasks: []
	};
	return sessionTemplate;
}


/**
 * Get number of miliseconds since last activity timestamp
 * @param {number} timestamp 
 * @returns 
 */
export function getTimeSinceLastActivity(timestamp) {
	/* Get difference in miliseconds */
	const seconds = Date.now() - timestamp;

	/* Return differnce (max value is inactivity limit) */
	return Math.min(seconds, appConfig.inactivityLimitSeconds * 1000);
};

export function getLastPlayedTaskId(playerModuleData, competitionId = null)  {
	let taskId = null;
	if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
		/* Get module data */
		const moduleData = (competitionId
			? modulesCompetitionsData.find((m) => {return m.id === playerModuleData.moduleId;})
			: modulesData.find((m) => {return m.id === playerModuleData.moduleId;})
		);
		/* Make sure the data for the task id exists */
		if (playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId && 
			moduleData.tasks.some((m) => {
				return m.id === playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId;
			})
		) {
			taskId = playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId;
		}
	}

	return taskId;
};

/**
 * Check if a module is unlocked
 * @param {object} moduleData 
 * @param {object} playerGameData 
 * @returns {bool}
 */
export function checkIfModuleIsUnlocked (moduleData, playerGameData) {	
	let moduleIsUnlocked = true;

	if (moduleData.unlockConditions && moduleData.unlockConditions.length > 0) {
		moduleData.unlockConditions.forEach((condition) => {
			if (!moduleIsUnlocked) return;

			if (condition.type === 'module-completed') {
				/* Check if player has completed specific module */
				const playerModuleData = getPlayerModuleData(condition.moduleId, playerGameData, null);
				if (!checkIfModuleIsCompleted(playerModuleData)) {
					moduleIsUnlocked = false;
				}
			}
			if (condition.type === 'stars-in-modules') {
				/* Check if player has achieved at least X stars in specific modules */
				let totalStars = 0;
				condition.moduleIds.forEach((moduleId) => {
					const maxStarsInModule = getMaxNumberOfStarsInModule(playerGameData, moduleId, null);
					totalStars += maxStarsInModule;
				});
				if (totalStars < condition.stars) {
					moduleIsUnlocked = false;
				}
			}
		});
	}
	
	return moduleIsUnlocked;
};

export function getModuleBridgeStatus (moduleData, playerGameData) {
	let bridgeStatus = 'none';

	if (moduleData.unlockConditions && moduleData.unlockConditions.length > 0) {
		
		const starConditions = moduleData.unlockConditions.filter((condition) => {
			return condition.type === 'stars-in-modules';
		});
		if (starConditions.length > 0) {
			let totalStarsRequired = 0;
			let starsEarned = 0;
			
			starConditions.forEach((starCondition) => {
				totalStarsRequired += starCondition.stars;
				starCondition.moduleIds.forEach((moduleId) => {
					const maxStarsInModule = getMaxNumberOfStarsInModule(playerGameData, moduleId, null);
					starsEarned += maxStarsInModule;
				});
			});

			if (totalStarsRequired > 0) {
				if (starsEarned >= (Math.ceil(totalStarsRequired / 2.))) {
					bridgeStatus = 'half';
				}
			}
		}
	}
	
	return bridgeStatus;
}

export function getSideQuestVisibleStones (sideQuestData, playerGameData) {
	let visibleStones = 0;
	if (sideQuestData.unlockConditions && sideQuestData.unlockConditions.length > 0) {
		const starCondition = sideQuestData.unlockConditions.find((condition) => {
			return condition.type === 'stars-in-modules';
		});
		if (starCondition) {
			let totalStars = 0;
			starCondition.moduleIds.forEach((moduleId) => {
				const maxStarsInModule = getMaxNumberOfStarsInModule(playerGameData, moduleId, null);
				totalStars += maxStarsInModule;
			});
			visibleStones = totalStars;
		}
	}

	return visibleStones;
}


/**
 * Check if module session is completed
 * @param {object} moduleSessionPlayerData 
 * @returns 
 */
export function checkIfModuleSessionIsCompleted(moduleSessionPlayerData) {
	let sessionIsCompleted = false;
	if (moduleSessionPlayerData && moduleSessionPlayerData.isCompleted === true) sessionIsCompleted = true;

	return sessionIsCompleted;
}

/**
 * Check if module is completed
 * Condition: player has played through minimum 1 session
 * @param {object} modulePlayerData 
 * @returns {bool}
 */
export function checkIfModuleIsCompleted(modulePlayerData) {
	const moduleIsCompleted = (
		modulePlayerData && 
		modulePlayerData.sessions && 
		(
			modulePlayerData.sessions.length > 1 ||
			(
				modulePlayerData.sessions.length > 0 && 
				checkIfModuleSessionIsCompleted(modulePlayerData.sessions[0])
			)
		) ? true : false
	);
	return moduleIsCompleted;
};

/**
 * Get number of completed modules in an area
 * @param {string} areaId 
 * @param {array} playerModulesData 
 * @returns 
 */
export function getNumberOfCompletedModulesInAnArea(areaId, playerModulesData) {
	let numberOfCompletedModulesInArea = 0;
	
	const modulesInArea = (areaId ? modulesData.filter((m) => {return m.areaId === areaId;}) : []);
	modulesInArea.forEach((moduleData) => {
		const playerModuleData = playerModulesData.find((playerModuleData) => {
			return playerModuleData.moduleId === moduleData.id;
		});
		if (checkIfModuleIsCompleted(playerModuleData)) numberOfCompletedModulesInArea += 1;
	});

	return numberOfCompletedModulesInArea;
};


/**
 * Check if player has completed all modules in an area
 * @param {string} areaId 
 * @param {array} playerModulesData 
 * @returns 
 */
export function checkIfAllModulesInAreaAreCompleted(areaId, playerModulesData) {
	let allModulesInAreaAreCompleted = false;
	const modulesInArea = (areaId ? modulesData.filter((m) => {return m.areaId === areaId;}) : []);
	let numberOfCompletedModulesInArea = getNumberOfCompletedModulesInAnArea(areaId, playerModulesData);
	if (modulesInArea.length > 0 && modulesInArea.length === numberOfCompletedModulesInArea) {
		allModulesInAreaAreCompleted = true;
	}
	
	return allModulesInAreaAreCompleted;
};


/**
 * Check if all the tasks in a module are completed
 * @param {object} playerModuleData 
 * @returns 
 */
export function checkIfAllTasksAreCompleted(moduleId, playerSessionData, competitionId = null) {
	let tasksAreCompleted = false;

	const moduleData = (competitionId
		? modulesCompetitionsData.find((m) => {return m.id === moduleId;})
		: modulesData.find((m) => {return m.id === moduleId;})
	);
	if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
		tasksAreCompleted = true;
		moduleData.tasks.forEach((task) => {
			if (tasksAreCompleted === false) return;
			if (task.isSolveToContinue === false) return;
			if (!task.taskId) return;
			if (
				!playerSessionData.hasOwnProperty('tasks') ||
					!playerSessionData.tasks.some((t) => {
						return (t.taskId === task.taskId && t.isCompleted === true);
					})
			) {
				tasksAreCompleted = false;
			}
		});
	}

	return tasksAreCompleted;
};

/**
 * Gets the percentage of completed tasks in the given module.
 * @param {Array} modules 
 * @returns string
 */
export function getModulesCompletionPercentage(modules) {
	let completion = '';
	let fullTaskCount = 0;
	let fullCompletedTaskCount = 0;

	modulesData.forEach((module) => {
		fullTaskCount += module.tasks.filter((task) => {return task.isSolveToContinue;}).length;
	});

	modules.forEach((module) => {
		/* Only include tasks of first session in module */
		const allSessionTasks = (module.sessions && module.sessions.length > 0 
			? module.sessions[0].tasks &&  module.sessions[0].tasks.length > 0 ? module.sessions[0].tasks : []
			: []
		);
		fullCompletedTaskCount += allSessionTasks.filter((task) => {return task.isCompleted;}).length;
	});
	completion = (fullCompletedTaskCount / fullTaskCount * 100);
	return completion;
}

/**
 * Get time spent in module - single session
 * Reverse index: 0 = end of array, 1 = second to last etc
 * @param {Object} module
 * @param {number} sessionReverseIndex
 */
export function getTimeSpendInModuleSession(module, sessionReverseIndex) {
	let milisecondsPlayed = 0;
	if (module && module.sessions && module.sessions.length > 0) {
		const sessionIndex = (module.sessions.length - 1) - sessionReverseIndex;
		if (
			sessionIndex >= 0 &&
			module.sessions.length > sessionIndex &&
			module.sessions[sessionIndex].milisecondsPlayed
		) {
			milisecondsPlayed = module.sessions[sessionIndex].milisecondsPlayed;
		}
	}

	if (milisecondsPlayed && milisecondsPlayed > 0) milisecondsPlayed = Math.round(milisecondsPlayed / (60. * 1000.));

	return milisecondsPlayed;
}

/**
 * Gets total time spend in given module, in minutes
 * @param {Object} module 
 * @returns int
 */
export function getTotalTimeSpentInModule(module) {
	const sessionPlayTimes = module.sessions.map((session) => {
		return (session.milisecondsPlayed ? session.milisecondsPlayed : 0);
	});
	const milisecondsPlayed = sessionPlayTimes.reduce(
		(previous, current) => {
			return previous + current;
		}
	);
	if (milisecondsPlayed && milisecondsPlayed > 0) return Math.round(milisecondsPlayed / (60. * 1000.));
	return 0;
}

/**
 * Get new streak type/value depending on player answer
 * @param {string} streakType 
 * @param {string} streakValue 
 * @param {bool} isCorrectAnswer 
 */
export function getNewStreakData(streakType, streakValue, isCorrectAnswer) {
	/* Update streak type and value */
	let newStreakType = streakType;
	let newStreakValue = streakValue;
	
	if (isCorrectAnswer) {
		if (streakType === 'success') {
			/* Player continued success streak */
			newStreakValue += 1;
		} else {
			/* Player started success streak */
			newStreakValue = 1;
			newStreakType = 'success';
		}
	} else {
		if (streakType === 'error') {
			/* Player continued error streak */
			newStreakValue += 1;
		} else {
			/* Player started error streak */
			newStreakValue = 1;
			newStreakType = 'error';
		}
	}

	return {newStreakType, newStreakValue};
}

/**
 * Get avr number of errors for a module session
 * Reverse index: 0 = end of array, 1 = second to last etc
 * @param {object} module 
 * @param {number} sessionReverseIndex 
 * @returns 
 */
export function getSessionErrorAverage(module, sessionReverseIndex) {
	let errAvr = 0;

	if (module && module.sessions && module.sessions.length > 0) {
		const sessionIndex = (module.sessions.length - 1) - sessionReverseIndex;
		if (
			sessionIndex >= 0 &&
			module.sessions.length > sessionIndex &&
			module.sessions[sessionIndex].tasks &&
			module.sessions[sessionIndex].tasks.length > 0
		) {
			const errorCounts = module.sessions[sessionIndex].tasks.map((sessionTask) => {return sessionTask.errors;});
			if (errorCounts.length > 0) {
				const errorSum = errorCounts.reduce(
					(previous, current) => {
						return previous + current;
					}
				);
				errAvr = errorSum / module.sessions[sessionIndex].tasks.length;
			}
		}
	}
	return errAvr.toFixed(2);
}


/**
 * Calculates average amount of errors, with two decimal points, for all sessions played
 * @param {Object} module 
 * @returns float
 */
export function getModuleErrorAverage(module) {
	const allSessionTasks = module.sessions.map((session) => {return session.tasks;}).flat();
	const errorCounts = allSessionTasks.map((sessionTask) => {return sessionTask.errors;});
	let errorSum = 0;
	if (errorCounts.length > 0) {
		errorSum = errorCounts.reduce(
			(previous, current) => {
				return previous + current;
			}
		);
	}
	const errorAverage = errorSum > 0 ? errorSum / allSessionTasks.length : 0;
	return errorAverage.toFixed(2);
}

export function getMaxNumberOfStarsInModule(playerGameData, moduleId, competitionId) {
	let maxStars = 0;
	
	const playerModuleData = getPlayerModuleData(moduleId, playerGameData, competitionId);
	if (playerModuleData && playerModuleData.maxStars) {
		maxStars = playerModuleData.maxStars;
	}

	return maxStars;
}

export function getMaxModuleStars(playerGameData, moduleId, competitionId) {
	const filledStars = getMaxNumberOfStarsInModule(playerGameData, moduleId, competitionId);
	let stars = [];
	for (let i = 1; i <= appConfig.maxStarsPerModule; i++) {
		let star = (i <= filledStars);
		stars.push(star);
	}
	return stars;
}

export function getModuleStars(module) {
	const playerModuleSession = module.sessions[module.sessions.length - 1];
	const points = (playerModuleSession && playerModuleSession.points 
		? playerModuleSession.points : 0);
	const filledStars = getNumberOfFilledStars(points, getModuleMaxPoints(module.moduleId, false));
	
	let stars = [];
	for (let i = 1; i <= appConfig.maxStarsPerModule; i++) {
		let star = (i <= filledStars);
		stars.push(star);
	}

	return stars;
}

/**
 *  Get number of stars earned in 1 session
 * Reverse index: 0 = end of array, 1 = second to last etc
 * @param {object} module 
 * @param {number} sessionReverseIndex 
 * @returns 
 */
export function getSessionStars(module, sessionReverseIndex) {
	let stars = Array(appConfig.maxStarsPerModule).fill(false);

	if (module && module.sessions && module.sessions.length > 0) {
		const sessionIndex = (module.sessions.length - 1) - sessionReverseIndex;
		if (
			sessionIndex >= 0 &&
			module.sessions.length > sessionIndex &&
			module.sessions[sessionIndex].points
		) {
			const points = module.sessions[sessionIndex].points;
			const filledStars = getNumberOfFilledStars(points, getModuleMaxPoints(module.moduleId, false));

			stars = [];
			for (let i = 1; i <= appConfig.maxStarsPerModule; i++) {
				let star = (i <= filledStars);
				stars.push(star);
			}
		}
	}
	return stars;
}
	
/**
 * Calculates percentage of players with perfect scores for current task.
 * @returns string percentage of players with perfect scores for current task.
 */
export function getPerfectScorePercentage (task, modulePlayerData) {
	// We dont have player data for the module
	if (!modulePlayerData) return;

	const sessionData = modulePlayerData.map((stats) => {return stats.sessions;}).flat();
	const allTaskData = sessionData.map((session) => {return session.tasks;}).flat();
	let tryCount = 0;
	const perfectScoreCount = allTaskData.reduce((prev, curr) => {
		if (curr.taskId === task.taskId) {
			tryCount += 1;
			if (curr.errors === 0) return prev + 1;
		}			
		return prev;
	}, 0);

	const result = tryCount > 0 && perfectScoreCount > 0 ? (perfectScoreCount / tryCount * 100).toFixed(2) : 0;
	return result + '%';
};


/**
 * Calculates the average amount of task mistakes for the given module.
 * @param {object} modulePlayerData 
 * @returns calculated average of mistakes, with up to one decimal.
 */
export function getAverageTaskMistakes (task, modulePlayerData) {
	// We dont have player data for the module
	if (!modulePlayerData) return;

	let mistakeAvgSum = 0;
	let playerCount = 0;
	modulePlayerData.forEach((player) => {
		const allTaskData = player.sessions.map((session) => {return session.tasks;}).flat();
		let mistakeAvg = 0;
		let tryCount = 0;
		allTaskData.forEach((playerTask) => {
			if (playerTask.taskId === task.taskId) {
				tryCount ++;
				mistakeAvg += playerTask.errors;
			}
		});

		if (mistakeAvg > 0) {
			mistakeAvg = mistakeAvg / tryCount;
		}
		if (tryCount > 0) {
			playerCount += 1;
		}

		mistakeAvgSum += mistakeAvg;
	});
	if (playerCount > 0 && mistakeAvgSum > 0) return (mistakeAvgSum / playerCount).toFixed(2);

	return 0;
};

/**
 * Calculates the average time spent among all players for the given module data
 * @param {object} modulePlayerData 
 * @returns calculated average of time spent as minutes, with up to one decimal point.
 */
export function getAverageTimeSpent(modulePlayerData) {
	let totalTimeSpent = 0;
	modulePlayerData.forEach((playerGameData) => {totalTimeSpent += getTotalTimeSpentInModule(playerGameData);});
	const averageTimeSpent = totalTimeSpent / modulePlayerData.length;

	return averageTimeSpent > 0 ? averageTimeSpent.toFixed(1) : averageTimeSpent;
};

/**
 * Calculates the average amount of task mistakes for the given module.
 * @param {object} modulePlayerData 
 * @returns calculated average of mistakes, with up to one decimal.
 */
export function getAverageModuleTaskMistakes(modulePlayerData) {
	let totalMistakes = 0;
	modulePlayerData.forEach((playerGameData) => {
		totalMistakes += Number.parseFloat(getModuleErrorAverage(playerGameData));
	});
	const averageMistakes = totalMistakes / modulePlayerData.length;

	return averageMistakes > 0 ? averageMistakes.toFixed(1) : averageMistakes;
};


/**
 * Get average number of stars earned in an array of sessions
 * @param {array} sessions 
 */
export function getAverageStars(moduleId, sessions) {
	let avrNumberOfStars = 0;
	
	if (sessions && sessions.length > 0) {
		let totalNumberOfStars = 0;
		sessions.forEach((session) => {
			const points = (session && session.points ? session.points : 0);
			const filledStars = getNumberOfFilledStars(points, getModuleMaxPoints(moduleId, false));	
			totalNumberOfStars += filledStars;	
		});
		avrNumberOfStars = totalNumberOfStars / sessions.length;
	}

	avrNumberOfStars = avrNumberOfStars.toFixed(1);

	return avrNumberOfStars;
}