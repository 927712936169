import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getNewInsights} from 'helpers/insights-helper';
import {objectCompare} from 'helpers/general-helper';
import {generateRandomId, getParticipatingPlayers} from 'helpers/competition-helper';
import {checkIfModuleIsCompleted} from 'helpers/module-helper';
import {getGameUrl, getPlayersOfGame} from 'helpers/game-helper';
import {errorUiTexts} from 'data/ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import Facilitator from 'components/facilitator/facilitator';


class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadingErrMsg: null,
			page: 'select-store',
			subpage: null,
			pageProps: null,
			gameId: null,
			gamesData: [],
			usersData: [],
			competitionsNotificationData: null,
			reportsNotificationData: null
		};
		this.unsubscribeUsersData = null;
		this.unsubscribeGamesData = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Subscribe to users and games data */
		Promise.all([
			this.subscribeToUsersData(), 
			this.subscribeToGamesData(), 
		]).then((responses) => {
			if (responses[0].status === 'success' && responses[1].status === 'success') {
				/* Get game url */
				const gameUrl = getGameUrl(window.location.pathname);
				if (!gameUrl || gameUrl.length === 0 || gameUrl === '/') {
					/* Site root */
					if (this.state.gamesData.length === 1) {
						/* Auto-select game if user is only facilitator of 1 game */
						this.handleSelectGame(this.state.gamesData[0].id);
					} else {
						/* Show default page (select game) */ 
						this.setState({isLoading: false, loadingErrMsg: null});
					}
				} else {	
					/* Find game with matching url */
					const urlGameData = this.state.gamesData.find((game) => {return game.url === gameUrl;});
					if (urlGameData) {
						/* Select game that matches url */
						this.handleSelectGame(urlGameData.id);
					} else {
						/* No game matches url */
						this.setState({isLoading: false, loadingErrMsg: null});
					}
				}	
			} else {
				/* Error: Could not subscribe to users and/or games */
				this.setState({isLoading: false, loadingErrMsg: errorUiTexts.couldNotLoadUsersAndOrGames});
			}
		}).catch((error) => {
			/* Error: Could not subscribe to users and/or games */
			console.error(error);
			this.setState({isLoading: false, loadingErrMsg: errorUiTexts.couldNotLoadUsersAndOrGames});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel all subscriptions */
		if (this.unsubscribeUsersData !== null) this.unsubscribeUsersData();
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();
	};

	/**
	 * Subscribe to all users
	 * @returns {Promise}
	 */
	subscribeToUsersData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUsersData !== null) this.unsubscribeUsersData();
			
		/* Subscribe to users */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUsersData = db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let usersData = [];
				querySnapshot.forEach((doc) => {usersData.push({id: doc.id, ...doc.data()});});
				this.setState({usersData: usersData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get users data */
				console.error('Could not get users data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all games
	 * @returns {Promise}
	 */
	subscribeToGamesData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();

		/* Subscribe to games */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGamesData = db.collection(appConfig.gamesDbName).onSnapshot((querySnapshot) => {
				let gamesData = [];
				querySnapshot.forEach((doc) => {
					if (
						doc.data() && 
						((
							doc.data().facilitatorEmails && 
							doc.data().facilitatorEmails.indexOf(this.props.userData.email) >= 0
						) ||
						(
							doc.data().coFacilitators && 
							doc.data().coFacilitators.some((c) => {return c.email === this.props.userData.email;})
						))
					) gamesData.push({id: doc.id, ...doc.data()});
				});
				this.setState({gamesData: gamesData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get games data */
				console.error('Could not get games data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Select game
	 * @param {string} gameId 
	 */
	handleSelectGame = (gameId) => {
		const gameData = this.state.gamesData.find((game) => {return (game.id === gameId);});

		if (gameData) {
			this.setState({page: 'overview', gameId: gameId, isLoading: false, loadingErrMsg: null}, () => {
				this.checkEndedCompetitions();
				this.checkForNewGameInsights();
				this.checkForNewReports();
			});
		} else {
			this.setState({isLoading: false});
		}
	};

	/**
	 * Go to page
	 * @param {string} page 
	 * @param {string} subpage 
	 */
	handleGoToPage = (page, subpage = null, props = null) => {
		this.setState({page: page, subpage: subpage, pageProps: props}, () => {
			if (page === 'retention' && subpage === 'competitions') {
				this.closeCompetitionNotification();
			}
			if (page === 'training') {
				this.closeReportsNotification();
			}
		});
	};



	/**
	 * Update user data
	 * @param {string} userId 
	 * @param {object} updates 
	 * @returns 
	 */
	updateUserData = (userId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}
		
		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};

	/**
	 * Update game data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGameData = (gameId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.gamesDbName).doc(gameId).update(updates);
	};

	/**
	 * Update game manager
	 * @param {string} gameId 
	 * @param {string} managerId 
	 * @returns 
	 */
	updateGameManager = (gameId, managerId) => {
		const db = firebase.firestore();
		return db.collection(appConfig.gamesDbName).doc(gameId).update({managerId});
	};

	/**
	 * Remove player from game
	 * @param {string} userId 
	 * @param {string} gameId 
	 * @returns 
	 */
	removePlayerFromGame = (gameId, userId) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).doc(userId).get().then((doc) => {
				if (doc.exists) {
					let playerGames = doc.data().games;
					if (playerGames && playerGames.some((game) => {return game.id === gameId;})) {
						const gameIndex = playerGames.findIndex((game) => {return game.id === gameId;});
						playerGames[gameIndex].isRemoved = true;
						db.collection(appConfig.usersDbName).doc(userId).update({games: playerGames}).then(() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
						
					} else {
						resolve({status: 'error', error: 'not-connected-to-game'});
					}
				} else {
					resolve({status: 'error', error: 'user-not-found'});
				}
			}).catch((error) => {resolve({status: 'error', error: error});});
		});
	};

	/**
	 * updates the players disabledAreaIds field
	 * @param {string} userId 
	 * @param {string} gameId 
	 * @returns 
	 */
	updatePlayerAreaIds = (gameId, userId, disabledAreaIds) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).doc(userId).get().then((doc) => {
				if (doc.exists) {
					let playerGames = doc.data().games;
					if (playerGames && playerGames.some((game) => {return game.id === gameId;})) {
						const gameIndex = playerGames.findIndex((game) => {return game.id === gameId;});			
						playerGames[gameIndex].disabledAreaIds = disabledAreaIds;
						db.collection(appConfig.usersDbName).doc(userId).update({games: playerGames}).then(() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});						
					} else {
						resolve({status: 'error', error: 'not-connected-to-game'});
					}
				} else {
					resolve({status: 'error', error: 'user-not-found'});
				}
			}).catch((error) => {resolve({status: 'error', error: error});});
		});
	};

	/**
	 * Check for ended competitions with no winner
	 */
	checkEndedCompetitions = () => {
		if (this.state.gameId) {

			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData.competitions && gameData.competitions.length > 0) {
				/* Check for ended competitions with no winner */
				const finishedCompetitionsNoWinner = gameData.competitions.filter((c) => {
					return (
						(!c.type || c.type === 'competitive') && 
						!c.result &&
						c.endDate &&
						dayjs(new Date()).diff(dayjs(c.endDate), 'days') > 0
					);
				});
				if (finishedCompetitionsNoWinner.length > 0) {
					this.setState({competitionsNotificationData: finishedCompetitionsNoWinner});
				}

				/* Check for competitions with no result */
				gameData.competitions.forEach((c) => {
					if (
						(c.type === 'shared-goal') && 
						!c.result &&
						c.endDate  &&
						dayjs(new Date()).diff(dayjs(c.endDate), 'days') > 0
					) {
						this.getSharedGoalResult(c.id);
					}
				});
			} 
		}
	};

	/**
	 * Close competition notification
	 */
	closeCompetitionNotification = () => {
		this.setState({competitionsNotificationData: null});
	};

	/**
	 * Check for new game insights
	 */
	checkForNewGameInsights = () => {
		if (this.state.gameId) {
			const gamePlayers = getPlayersOfGame(this.state.gameId, this.state.usersData);
			if (gamePlayers.length > 0) {
				const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
				const {gameInsights, isUpdated} = getNewInsights(gameData, gamePlayers);
				if (isUpdated) {
					this.updateGameData(this.state.gameId, {insights: gameInsights}).catch((error) => {
						console.error(error);
					});
				}
			}
		}
	};

	/**
	 * Close reports notification
	 */
	closeReportsNotification = () => {
		this.setState({reportsNotificationData: null});
	};


	/**
	 * Check for new yearbook reports
	 */
	checkForNewReports = () => {
		if (this.state.gameId) {
			const gamePlayers = getPlayersOfGame(this.state.gameId, this.state.usersData);
			const reportsNotificationData = [];
			gamePlayers.forEach((player) => {
				player.yearbookReports.forEach((report) => {
					if (!report.hasOwnProperty('seenByFacilitatorId')) {
						reportsNotificationData.push(report);
					} else if (report.hasOwnProperty('seenByFacilitatorId')) {
						if (!report.seenByFacilitatorId.includes(this.props.userData.id)) {
							reportsNotificationData.push(report);
						}
					}
				});
			});
			if (reportsNotificationData.length > 0) {
				this.setState({reportsNotificationData: reportsNotificationData});
			}
		}
	};

	/**
	 * Adds an facilitatirId to the 'seenByFacilitatorId' field of a given report
	 * @param {Object} seenReport 
	 * @param {Object} reportedUser 
	 */
	markReportAsSeen = (seenReport, reportedUser) => {
		const db = firebase.firestore();
		db.collection(appConfig.usersDbName).doc(reportedUser).get().then((doc) => {
			// get report index for player
			const reportIndex = doc.data().yearbookReports.findIndex((report) => {
				return (report.reportedById === seenReport.reportedById
				&& objectCompare(seenReport.yearBook, report.yearBook));
			});

			const newYearbookReports = JSON.parse(JSON.stringify(doc.data().yearbookReports));

			// add field if it does not exsist
			if (!newYearbookReports[reportIndex].hasOwnProperty('seenByFacilitatorId')) {
				newYearbookReports[reportIndex].seenByFacilitatorId = [];
			}

			// check if field contains add if not
			if (!newYearbookReports[reportIndex].seenByFacilitatorId.includes(this.props.userData.id)) {
				newYearbookReports[reportIndex].seenByFacilitatorId.push(this.props.userData.id);
			}
			
			/* Update user data with new yearbookreports */
			db.collection(appConfig.usersDbName).doc(reportedUser).update({yearbookReports: newYearbookReports});
		},
		(error) => {
			/* Error: could not get game players */
			console.error('could not get game players: ', error);

		});
	
	};

	/**
	 * Deletes report from user
	 * @param {Object} report 
	 */
	deleteReport = (report, playerId) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).doc(playerId).get().then((doc) => {
				const user = JSON.parse(JSON.stringify(doc.data()));
				let yearbookReports = JSON.parse(JSON.stringify(user.yearbookReports));

				if (yearbookReports.some((object) => {return object.reportedById === report.reportedById;})) {
					const indexOfReport = yearbookReports.findIndex((object) => {
						return (object.reportedById === report.reportedById 
										&& objectCompare(object.yearBook, report.yearBook));
					});
					
					// remove if the index exsists
					if (indexOfReport > -1) {
						yearbookReports.splice(indexOfReport, 1);
					}
				}
				db.collection(appConfig.usersDbName).doc(playerId).update({yearbookReports: yearbookReports}).then(()=>{
					resolve({status: 'success'});
				}).catch((error) => {
					console.error('update user role error: ', error);
					resolve({status: 'error'});
				});

			}).catch((error) => {
				console.error('create game error: ', error);
				resolve({status: 'error'});
			});
		});
	};
	


	/**
	 * Flag insight notification as deleted by facilitator
	 * @param {string} insightId 
	 * @param {number} notificationIndex 
	 */
	deleteInsightNotification = (insightId, notificationIndex, isMuted) => {
		return new Promise((resolve) => {
			if (this.state.gameId) {
				const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
				let newInsights = JSON.parse(JSON.stringify(gameData.insights));
				const insightIndex = newInsights.findIndex((i) => {return i.insightId === insightId;});

				
				if (insightIndex >= 0) {
					let insightIsUpdated = false;
					/* Mark notification as deleted by facilitator */
					if (
						newInsights[insightIndex].notifications &&
						newInsights[insightIndex].notifications.length > notificationIndex
					) {
						if (!newInsights[insightIndex].notifications[notificationIndex].deletedBy) {
							newInsights[insightIndex].notifications[notificationIndex].deletedBy = [];
						}
						newInsights[insightIndex].notifications[notificationIndex].deletedBy.push(
							this.props.userData.email
						);
						insightIsUpdated = true;
					}

					/* Mark insight as muted by facilitator */
					if (isMuted) {
						if (!newInsights[insightIndex].mutedBy) {
							newInsights[insightIndex].mutedBy = [];
						}
						newInsights[insightIndex].mutedBy.push(this.props.userData.email);
						insightIsUpdated = true;
					}
					
					if (insightIsUpdated) {
						/* Update game data */
						this.updateGameData(this.state.gameId, {insights: newInsights}).then(() => {
							resolve({status: 'success'});	
						}).catch((error) => {
							console.error(error);
							resolve({status: 'error', error: 'could-not-update-game'});	
						});
					} else {
						resolve({status: 'error', error: 'insight-not-updated'});			
					}
				} else {
					resolve({status: 'error', error: 'insight-not-found'});		
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});	
			}
		});
	};

	/**
	 * Updatew which insights are muted by facilitator
	 * @param {array} mutedInsightIds 
	 * @returns 
	 */
	updateMutedInsights = (mutedInsightIds) => {
		return new Promise((resolve) => {
			if (this.state.gameId) {
				const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
				let newInsights = JSON.parse(JSON.stringify(gameData.insights));
				let insightsAreUpdated = false;
				newInsights.forEach((insight) => {
					if (!insight.mutedBy) {
						insight.mutedBy = [];
					}
					const mutedIndex = insight.mutedBy.indexOf(this.props.userData.email);
					if (mutedIndex >= 0 && mutedInsightIds.indexOf(insight.insightId) < 0) {
						/* Insight was un-muted */
						insight.mutedBy.splice(mutedIndex, 1);
						insightsAreUpdated = true;
					}
					if (mutedIndex < 0 && mutedInsightIds.indexOf(insight.insightId) >= 0) {
						/* Insight was muted */
						insight.mutedBy.push(this.props.userData.email);
						insightsAreUpdated = true;
					}
				});
				if (insightsAreUpdated) {
					/* Update game data */
					this.updateGameData(this.state.gameId, {insights: newInsights}).then(() => {
						resolve({status: 'success'});	
					}).catch((error) => {
						console.error(error);
						resolve({status: 'error', error: 'could-not-update-game'});	
					});
				} else {
					/* No updates */
					resolve({status: 'success'});	
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});	
			}
		});
	};

	/**
	 * Reset insights
	 * Remove facilitator from all mutedBy and deletedBy arrays in each game insight
	 * Full reset: remove all generated insights
	 */
	resetInsights = (isFullReset = false) => {
		return new Promise((resolve) => {
			if (this.state.gameId) {
				const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});

				/* Full reset */
				if (isFullReset) {
					this.updateGameData(this.state.gameId, {insights: []}).then(() => {
						resolve({status: 'success'});	
					}).catch((error) => {
						console.error(error);
						resolve({status: 'error', error: 'could-not-update-game'});	
					});
				} else {
					let newInsights = JSON.parse(JSON.stringify(gameData.insights));
					if (newInsights && newInsights.length > 0) {
						let insightIsUpdated = false;
						gameData.insights.forEach((insight, insightIndex) => {
							/* Un-mute facilitator */
							if (insight.mutedBy && insight.mutedBy.length > 0) {
								const mutedIndex = insight.mutedBy.indexOf(this.props.userData.email);
								if (mutedIndex >= 0) {
									newInsights[insightIndex].mutedBy.splice(mutedIndex, 1);
									insightIsUpdated = true;
								}
							}
							/* Un-delete notification */
							if (insight.notifications && insight.notifications.length > 0) {
								insight.notifications.forEach((notification, notificationIndex) => {
									if (notification.deletedBy && notification.deletedBy.length > 0) {
										const deletedIndex = notification.deletedBy.indexOf(this.props.userData.email);
										if (deletedIndex >= 0) {
											newInsights[insightIndex].notifications[notificationIndex].deletedBy
												.splice(deletedIndex, 1);
											insightIsUpdated = true;
										}
									} 
								});
							}
						});

						if (insightIsUpdated) {
							/* Update game data */
							this.updateGameData(this.state.gameId, {insights: newInsights}).then(() => {
								resolve({status: 'success'});	
							}).catch((error) => {
								console.error(error);
								resolve({status: 'error', error: 'could-not-update-game'});	
							});
						} else {
							/* No updates */
							resolve({status: 'success'});			
						}
					} else {
						/* No insights to delete */
						resolve({status: 'success'});		
					}
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});	
			}
		});
	};

	/**
	 * Create a new competition
	 * @param {string} moduleId 
	 * @param {object} startDate 
	 * @param {object} endDate 
	 * @param {string} description 
	 * @returns 
	 */
	createCompetition = (typeId, moduleId, startDate, endDate, 
		selectedAreaIds, stars, description, prize
	) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				const competitions = (gameData.competitions ? gameData.competitions : []);

				let competitionId = generateRandomId(5);
				let iterationNumber = 0;
				let competitionIdIsUnique = !competitions.some((c) => {return c.id === competitionId;});
				while (!competitionIdIsUnique && iterationNumber < 1000) {
					iterationNumber ++;
					competitionId = generateRandomId(5);
					// eslint-disable-next-line no-loop-func
					competitionIdIsUnique = !competitions.some((c) => {return c.id === competitionId;});
					iterationNumber ++;
				}

				if (competitionIdIsUnique) {
					competitions.push({
						id: competitionId,
						type: typeId,
						moduleId: moduleId,
						created: dayjs(new Date()).format('YYYY-MM-DD'),
						startDate: dayjs(startDate).format('YYYY-MM-DD'),
						endDate: dayjs(endDate).format('YYYY-MM-DD'),
						selectedAreaIds: selectedAreaIds,
						stars: stars,
						description: description,
						prize: prize
					});
	
					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({competitions}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					resolve({status: 'error', error: 'could not generate unique id'});
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	/**
	 * Delete competition
	 * @param {string} competitionId 
	 * @returns 
	 */
	deleteCompetition = (competitionId) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				const competitions = (gameData.competitions ? gameData.competitions : []);
				
				const competitionIndex = competitions.findIndex((c) => {return c.id === competitionId;});
				if (competitionIndex >= 0) {
					competitions.splice(competitionIndex, 1);
					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({competitions}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					resolve({status: 'error', error: 'competition-not-found'});
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	/**
	 * Edit competition
	 * @param {string} competitionId
	 * @param {object} competitionUpdates
	 * @returns 
	 */
	editCompetition = (competitionId, competitionUpdates) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				const competitions = (gameData.competitions ? gameData.competitions : []);
					
				const competitionIndex = competitions.findIndex((c) => {return c.id === competitionId;});
				if (competitionIndex >= 0) {
					competitions[competitionIndex] = {...competitions[competitionIndex], ...competitionUpdates};

					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({competitions}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					resolve({status: 'error', error: 'competition-not-found'});
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	/**
	 * Find winner of competition
	 */
	findCompetitionWinner = (competitionId, players, numberOfAvailablePlayers) => {
		return new Promise((resolve) => {
			if (players.length > 0) {
				const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
				if (gameData) {
					const competitions = (gameData.competitions ? gameData.competitions : []);
						
					const competitionIndex = competitions.findIndex((c) => {return c.id === competitionId;});
					if (competitionIndex >= 0) {
						/* One player ticket per star / bonus star */
						let playerTickets = [];
						players.forEach((player) => {
							const numberOfTickets = player.stars + player.bonusStars;
							for (let i = 1; i <= numberOfTickets; i++) {
								playerTickets.push(player.name);
							}
						});

						if (playerTickets.length > 0) {
							/* Randomly draw winner */
							const randomIndex = Math.floor(Math.random() * playerTickets.length);
							const winner = playerTickets[randomIndex];

							/* Update competition data */
							const result = {
								winner,
								numberOfAvailablePlayers,
								numberOfCompletedPlayers: players.length
							};
							competitions[competitionIndex].result = result;
							const db = firebase.firestore();
							db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({competitions}).then(
								() => {
									resolve({status: 'success', winner: winner});
								}).catch((error) => {resolve({status: 'error', error: error});});
						} else {
							resolve({status: 'error', error: 'no-tickets'});
						}
					} else {
						resolve({status: 'error', error: 'competition-not-found'});
					}
				} else {
					resolve({status: 'error', error: 'game-not-found'});
				}
			} else {
				resolve({status: 'error', error: 'no-players'});
			}
		});
	};

	/**
	 * Log result of "shared goal" competition
	 * @param {string} competitionId 
	 */
	getSharedGoalResult = (competitionId) => {
		const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
		if (gameData) {
			
			const competitions = (gameData.competitions ? gameData.competitions : []);
			const competitionIndex = competitions.findIndex((c) => {return c.id === competitionId;});
			if (competitionIndex >= 0) {
				const allCanParticipate = (
					!competitions[competitionIndex].selectedAreaIds || 
					competitions[competitionIndex].selectedAreaIds.length === 0
				);
				const gamePlayers = getPlayersOfGame(this.state.gameId, this.state.usersData);
				const participatingPlayers = getParticipatingPlayers(
					gamePlayers, 
					allCanParticipate, 
					competitions[competitionIndex].selectedAreaIds ? competitions[competitionIndex].selectedAreaIds : []
				);

				let numberOfCompletedPlayers = 0;
				let totalStars = 0;
				participatingPlayers.forEach((p) => {
					/* Check competition module */
					if (p.competitions && p.competitions.length > 0) {
						const playerCompetitionData = p.competitions.find((pcd) => {
							return pcd.competitionId === competitionId;
						});
						if (playerCompetitionData) {
							/* Player has started competition module */
							if (checkIfModuleIsCompleted(playerCompetitionData) === true) {
								/* Player has completed competition module */
								numberOfCompletedPlayers += 1;
								totalStars += playerCompetitionData.maxStars;
							}
						}
					}

					/* TODO: check stars in related modules? */
				});
					
				competitions[competitionIndex].result = {
					numberOfAvailablePlayers: participatingPlayers.length,
					numberOfCompletedPlayers: numberOfCompletedPlayers,
					totalStars: totalStars
				};
				const db = firebase.firestore();
				db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({competitions}).then(
					() => {
					}).catch((error) => {console.error(error);});
			}
		}
	};

	/**
	 * Add co-facilitator(s) to game
	 * @param {string} gameId 
	 * @param {array} emails 
	 */
	addCoFacilitatorsToGame = (gameId, emails) => {
		return new Promise((resolve) => {
			/* Get game data */
			const gameData = this.state.gamesData.find((game) => {return game.id === gameId;});

			if (gameData) {
				/* Get game facilitator emails & co-facilitators */
				let facilitatorEmails = (gameData.facilitatorEmails && gameData.facilitatorEmails.length > 0 
					? [...gameData.facilitatorEmails] : []);
				let coFacilitators = (gameData.coFacilitators && gameData.coFacilitators.length > 0 
					? [...gameData.coFacilitators] : []);

				let feedback = [];
				let promises = [];
				let newCoFacilitators = 0;
				emails.forEach((email) => {
					
					/* Get user data by e-mail (if they exist) */
					const userData = this.state.usersData.find((user) => {return user.email === email;});

					if (!userData) {
						/* Only existing users can be added */
						const feedbackText = JSON.parse(JSON.stringify(
							facilitatorUiTexts.addCoFacilitatorsToGamePopup.notExistingUser))
							.replace(/%email%/g, email);
						feedback.push(feedbackText);
					} else {
						/* Email already added to game */
						if (
							facilitatorEmails.indexOf(email) >= 0 ||
							coFacilitators.some((c) => {return c.email === email;})
						) {
							const feedbackText = JSON.parse(JSON.stringify(
								facilitatorUiTexts.addCoFacilitatorsToGamePopup.alreadyFacilitator))
								.replace(/%name%/g, userData.name);
							feedback.push(feedbackText);
						} else {
							/* Add co-facilitator to game */
							coFacilitators.push({
								email: email,
								addByEmail: this.props.userData.email
							});
							newCoFacilitators += 1;

							/* Flag user as coFacilitator */
							if (!userData.isCoFacilitator) {
								promises.push(this.updateUserData(userData.id, {isCoFacilitator: true}));
							}
						}
					}
				});

				if (newCoFacilitators > 0) {
					promises.push(this.updateGameData(gameId, {coFacilitators}));
					Promise.all(promises).then(() => {
						resolve({status: 'success', feedback: feedback});
					}).catch((error) => {
						console.error(error);
						resolve({status: 'error', feedback: error});
					});
				} else {
					resolve({status: 'error', feedback: feedback});
				}
				
			} else {
				resolve({status: 'error', feedback: 'noGame'});
			}
		});
	};

	/**
	 * Update co-facilitator
	 * @param {string} gameId 
	 * @param {string} email 
	 * @param {object} updates 
	 * @returns 
	 */
	updateCoFacilitator = (gameId, email, updates) => {
		return new Promise((resolve) => {
			/* Get game data */
			const gameData = this.state.gamesData.find((game) => {return game.id === gameId;});

			if (gameData) {
				/* Get co-facilitators */
				const coFacilitators = (
					gameData.coFacilitators && gameData.coFacilitators.length > 0 
						? JSON.parse(JSON.stringify(gameData.coFacilitators)) : []);

				/* Get co-facilitator index */
				const coFacilitatorIndex = coFacilitators.findIndex((fa) => {return fa.email === email;});
				if (coFacilitatorIndex >= 0) {
					/* Update co-facilitator data */
					for (const [property, value] of Object.entries(updates)) {
						coFacilitators[coFacilitatorIndex][property] = value;	
					}
					this.updateGameData(gameId, {coFacilitators}).then(() => {
						resolve({status: 'success'});
					}).catch((error) => {
						resolve({status: 'error', feedback: error});
					});
				} else {
					/* Error: Co-facilitator not found */
					resolve({status: 'error', feedback: 'noCoFacilitator'});
				}
			} else {
				resolve({status: 'error', feedback: 'noGame'});
			}
		});
	};

	/**
	 * Remove co-facilitator from game
	 * @param {*} gameId 
	 * @param {*} email 
	 */
	removeCoFacilitatorFromGame = (gameId, email) => {
		return new Promise((resolve) => {
			apiHelper('facilitator/remove-co-facilitator', {gameId, email}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};


	
	/**
		 * Create a new message
		 * @param {string} text 
		 * @param {array} selectedAreaIds 
		 * @returns 
		 */
	createMessage = (text, selectedAreaIds) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				/* Get all old messages */
				const messages = (gameData.messages ? JSON.parse(JSON.stringify(gameData.messages)) : []);

				/* Generate unique message id */
				let messageId = generateRandomId(5);
				let iterationNumber = 0;
				let messageIdIsUnique = !messages.some((c) => {return c.id === messageId;});
				while (!messageIdIsUnique && iterationNumber < 1000) {
					iterationNumber ++;
					messageId = generateRandomId(5);
					// eslint-disable-next-line no-loop-func
					messageIdIsUnique = !messages.some((c) => {return c.id === messageId;});
					iterationNumber ++;
				}

				if (messageIdIsUnique) {
					/* Add new message */
					messages.push({
						id: messageId,
						created: dayjs(new Date()).format('YYYY-MM-DD'),
						createdByName: this.props.userData.name,
						createdById: this.props.userData.id,
						selectedAreaIds: selectedAreaIds,
						text: text,
					});

					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({messages}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					/* Error: could not generate unique id */
					resolve({status: 'error', error: 'could not generate unique id'});
				}
			} else {
				/* Error: game not found */
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	/**
	 * Delete message
	 * @param {string} messageId 
	 * @returns 
	 */
	deleteMessage = (messageId) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				const messages = (gameData.messages ? JSON.parse(JSON.stringify(gameData.messages)) : []);
				
				const messageIndex = messages.findIndex((c) => {return c.id === messageId;});
				if (messageIndex >= 0) {
					messages[messageIndex].isDeleted = true;
					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({messages}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					resolve({status: 'error', error: 'message-not-found'});
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	/**
		 * Edit message
		 * @param {string} messageId
		 * @param {object} messageUpdates
		 * @returns 
		 */
	editMessage = (messageId, messageUpdates) => {
		return new Promise((resolve) => {
			const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});
			if (gameData) {
				const messages = (gameData.messages ? JSON.parse(JSON.stringify(gameData.messages)) : []);
				const messageIndex = messages.findIndex((c) => {return c.id === messageId;});
				if (messageIndex >= 0) {
					messages[messageIndex] = {...messages[messageIndex], ...messageUpdates};

					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).doc(this.state.gameId).update({messages}).then(
						() => {
							resolve({status: 'success'});
						}).catch((error) => {resolve({status: 'error', error: error});});
				} else {
					resolve({status: 'error', error: 'message-not-found'});
				}
			} else {
				resolve({status: 'error', error: 'game-not-found'});
			}
		});
	};

	

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-facilitator-data" deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Game data */
		const gameData = this.state.gamesData.find((game) => {return (game.id === this.state.gameId);});

		/* Game players data */
		const gamePlayers = (this.state.gameId ? getPlayersOfGame(this.state.gameId, this.state.usersData) : []);
		
		return (
			<Facilitator 
				loadingErrMsg={this.state.loadingErrMsg}
				page={this.state.page} 
				pageProps={this.state.pageProps}
				subpage={this.state.subpage}
				userData={this.props.userData} 
				gameId={this.state.gameId}
				usersData={this.state.usersData}
				gamesData={this.state.gamesData}
				gameData={gameData}
				gamePlayers={gamePlayers}
				competitionsNotificationData={this.state.competitionsNotificationData}
				reportsNotificationData={this.state.reportsNotificationData}
				handleSelectGame={this.handleSelectGame}
				handleGoToPage={this.handleGoToPage}
				removePlayerFromGame={this.removePlayerFromGame}
				updatePlayerAreaIds={this.updatePlayerAreaIds}
				updateGameManager={this.updateGameManager}
				deleteInsightNotification={this.deleteInsightNotification}
				updateMutedInsights={this.updateMutedInsights}
				resetInsights={this.resetInsights}
				createCompetition={this.createCompetition}
				deleteCompetition={this.deleteCompetition}
				editCompetition={this.editCompetition}
				findCompetitionWinner={this.findCompetitionWinner}
				createMessage={this.createMessage}
				deleteMessage={this.deleteMessage}
				editMessage={this.editMessage}
				addCoFacilitatorsToGame={this.addCoFacilitatorsToGame}
				updateCoFacilitator={this.updateCoFacilitator}
				removeCoFacilitatorFromGame={this.removeCoFacilitatorFromGame}
				handleLogout={this.props.handleLogout}
				deviceInfo={this.props.deviceInfo}
				closeCompetitionNotification={this.closeCompetitionNotification}
				closeReportsNotification={this.closeReportsNotification}
				markReportAsSeen={this.markReportAsSeen}
				userId={this.props.userData.id}
				deleteReport={this.deleteReport}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	userData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorController;
