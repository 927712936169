const moduleCleaning1 = [
	'modules/tasks/dialogue/cooler.svg',
	'modules/tasks/dialogue/freezer.svg',

	'modules/tasks/image/cleaning-1-3-well-sink.svg',

	'modules/tasks/multiple-choice/cleaning-1-common-schedules/background.svg',
	'modules/tasks/multiple-choice/cleaning-1-common-schedules/option-1.svg',
	'modules/tasks/multiple-choice/cleaning-1-common-schedules/option-2.svg',
	'modules/tasks/multiple-choice/cleaning-1-common-schedules/option-3.svg',
	'modules/tasks/multiple-choice/cleaning-1-common-schedules/option-4.svg',
	'modules/tasks/multiple-choice/cleaning-1-common-schedules/option-5.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-1.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-2.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-3.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-4.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-5.svg',
	'modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-6.svg',
	'modules/tasks/multiple-choice/cleaning-1-day-most-cleaning/background.svg',
	'modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-1.svg',
	'modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-2.svg',
	'modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-3.svg',
	'modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-4.svg',
	'modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-1.svg',
	'modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-2.svg',
	'modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-3.svg',
	'modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-4.svg',
	'modules/tasks/multiple-choice/cleaning-1-weekly-checklist/background.svg',

	'modules/tasks/spot-errors/cleaning-1-coffin-case-seafood.svg',
	'modules/tasks/spot-errors/cleaning-1-coffin-case.svg',
	'modules/tasks/spot-errors/cleaning-1-deli-production-area.svg',
	'modules/tasks/spot-errors/cleaning-1-handwash-sink.svg',
	'modules/tasks/spot-errors/cleaning-1-meat-production-area.svg',
];

export {
	moduleCleaning1
};
