import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { yearBookUiTexts } from 'data/ui-texts';
import Button from '../button/button';
import './popup-report-prompt.scss';

const PopupReportPrompt = ({ handleReportPlayer, closePopup}) => {

	const [reportPromptFeedback, setShowReportPromptFeedback] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	/**
	 * handleOnClick and based on the response give the correct feedback to user
	 */
	const handleOnClick = () => {
		setIsLoading(true);
		handleReportPlayer().then((response) => {
			setIsLoading(false);
			switch (response.status) {
			case 'userAlreadyReported':
				setShowReportPromptFeedback(yearBookUiTexts.reportPromptFailFeedback);
				break;
			case 'yearBookEmpty':
				setShowReportPromptFeedback(yearBookUiTexts.reportPromptYearBookEmptyFeedback);
				break;
			case 'error':
				closePopup(false);
				break;
			default:
				// success
				setShowReportPromptFeedback(yearBookUiTexts.reportPromptSuccessFeedback);
			}
		});
	};

	return (
		<div className='PopupReportPrompt' onClick={(e) => {e.stopPropagation();}}>
			{ reportPromptFeedback ?
				/* feedback prompt */
				<div className='PopupReportPrompt-content'>
					<div className='PopupReportPrompt-title'>
						<span>{reportPromptFeedback}</span>
					</div>
					<div className='PopupReportPrompt-buttonsWrapper'>
						<Button
							classes={['blueDark']}
							text={yearBookUiTexts.close}
							onClick={() => {closePopup(false);}}
						/>
					</div>
				</div> :
				/* report prompt */
				<div className='PopupReportPrompt-content'>
					<div className='PopupReportPrompt-title'>
						<span>{yearBookUiTexts.reportPrompt}</span>
					</div>
					<div className='PopupReportPrompt-buttonsWrapper'>
						<Button
							classes={[(isLoading ? 'blueDark loading' : 'blueDark')]}
							text={yearBookUiTexts.yes}
							onClick={() => {handleOnClick();}}
						/>
						<Button
							classes={['blueDark']}
							text={yearBookUiTexts.no}
							onClick={() => {closePopup(false);}}
						/>
					</div>
				</div>}
		</div>
	);
};

PopupReportPrompt.propTypes = {
	handleReportPlayer: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default PopupReportPrompt;