const backgrounds = [
	'backgrounds/avatar-maker.svg',
	'backgrounds/bagger-grey.svg',
	'backgrounds/bagger.svg',
	'backgrounds/bakery-grey.svg',
	'backgrounds/bakery.svg',
	'backgrounds/cashier-grey.svg',
	'backgrounds/cashier.svg',
	'backgrounds/center-store-grey.svg',
	'backgrounds/center-store.svg',
	'backgrounds/clouds-raft-rocks.svg',
	'backgrounds/customer-line.svg',
	'backgrounds/customer-service-grey.svg',
	'backgrounds/customer-service.svg',
	'backgrounds/deli-grey.svg',
	'backgrounds/deli-grey2.svg',
	'backgrounds/deli.svg',
	'backgrounds/floral-grey.svg',
	'backgrounds/floral.svg',
	'backgrounds/intro.svg',
	'backgrounds/login.svg',
	'backgrounds/meat-grey.svg',
	'backgrounds/meat.svg',
	'backgrounds/ocean-island.svg',
	'backgrounds/ocean-raft.svg',
	'backgrounds/ocean-raft-ice.svg',
	'backgrounds/ocean.svg',
	'backgrounds/orientation-grey.svg',
	'backgrounds/orientation.svg',
	'backgrounds/produce-grey.svg',
	'backgrounds/produce.svg',
	'backgrounds/salad-bar.svg',
	'backgrounds/seafood-grey.svg',
	'backgrounds/seafood.svg',
];

export {
	backgrounds
};
