import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { badgesData } from 'data/badges-data';
import { yearBookUiTexts } from 'data/ui-texts';
import Badge from '../badge/badge';
import Button from '../button/button';
import './popup-add-badge.scss';

const PopupAddBadge = ({playerBadges, closeAddBadgePopup, setYearbookBadges, yearbookBadges}) => {

	/**
	 * keep track of earned badges
	 */
	const availiableBadges = badgesData.filter((badge) => {
		return playerBadges.find((playerBadge) => {return playerBadge.id === badge.id;});
	}).map((badge)=>{return badge.id;});
	/**
	 * keep track of already selected badges
	 */
	const selectedBadges = availiableBadges.filter((badge) => {
		return yearbookBadges.includes(badge);
	});


	// Update yearbookData with written input
	const handleUpdateYearbookBadges = (newBadge) => {
		// cannot choose a locked badge
		if (!availiableBadges.includes(newBadge)) {return;}

		// remove already selected badges
		if (yearbookBadges.includes(newBadge)) {
			setYearbookBadges(yearbookBadges.filter((badge) => {return badge !== newBadge;}));
			return;
		}
		// do nothing if three badges are seleceted
		if (yearbookBadges.length >= 3) {
			return;
		};
		// update badge
		setYearbookBadges([...yearbookBadges, newBadge]);
	};

	const [initialBadges, setInitialBadges] = useState([]);
	/**
	 * Save the badges at the onmount of this component
	 */
	useEffect(() => {
		setInitialBadges(selectedBadges);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * sets back the inital badges before closing
	 */
	const handleClosePopup = () =>{
		setYearbookBadges(initialBadges);
		closeAddBadgePopup(false);
	};

	return (
		<div className='PopupAddBadge' onClick={(e) => {
			e.stopPropagation(); handleClosePopup();
		}}>
			<div className='PopupAddBadge-content' onClick={(e) => {e.stopPropagation();}}>
				<div className='PopupAddBadge-contentTitle'><span>{yearBookUiTexts.chooseBadges}</span></div>
				<div className='PopupAddBadge-badgesWarpper'>
					{badgesData.map((badge, index) => {
						if (badge.id === 'placeholder-1') {return null; }
						return (	
							<div className={'PopupAddBadge-Badge ' + 
							(selectedBadges.includes(badge.id) ? 'selected' : '') }
							key={index} 
							onClick={() => {handleUpdateYearbookBadges(badge.id);}}>
								<Badge 
									key={index} 
									isUnlocked={availiableBadges.includes(badge.id)} 
									page = "yearbook" 
									badgeId={badge.id}/>
							</div>
						);	
					})}
				</div>

				<div className='PopupAddBadge-buttonsWrapper'>
					<Button
						classes={['blueDark']}
						text={yearBookUiTexts.cancel}
						onClick={() => {handleClosePopup();}}
					/>
					<Button
						classes={['blueDark']}
						text={yearBookUiTexts.save}
						onClick={() => {closeAddBadgePopup(false);}}
					/>
				</div>
			</div>
		</div>
	);
};

PopupAddBadge.propTypes = {
	playerBadges: PropTypes.array.isRequired,
	closeAddBadgePopup: PropTypes.func.isRequired,
	setYearbookBadges: PropTypes.func.isRequired,
	yearbookBadges: PropTypes.array.isRequired,
};

export default PopupAddBadge;