const characters = [
	'characters/bearded-man-confused.svg',
	'characters/bearded-man-happy.svg',
	'characters/bob-1.svg',
	'characters/butcher.svg',
	'characters/chef.svg',
	'characters/elderly-lady-with-cart.svg',
	'characters/man-with-box-angry.svg',
	'characters/man-with-box-annoyed.svg',
	'characters/man-with-box.svg',
	'characters/man-with-boxes-angry.svg',
	'characters/man-with-boxes-annoyed.svg',
	'characters/man-with-boxes.svg',
	'characters/manager.svg',
	'characters/middle-aged-man-angry.svg',
	'characters/middle-aged-man-happy.svg',
	'characters/middle-aged-man-with-bag.svg',
	'characters/middle-aged-man-with-watch.svg',
	'characters/middle-aged-man-with-watch-happy.svg',
	'characters/old-lady-angry.svg',
	'characters/old-lady-happy.svg',
	'characters/old-lady.svg',
	'characters/old-man-with-cat-food-angry.svg',
	'characters/old-man-with-cat-food-annoyed.svg',
	'characters/old-man-with-cat-food.svg',
	'characters/parrot.svg',
	'characters/pirate-arm-out.svg',
	'characters/pirate.svg',
	'characters/shopping-cart-lady-angry.svg',
	'characters/shopping-cart-lady-happy.svg',
	'characters/shopping-cart-lady-neutral.svg',
	'characters/shopping-cart-lady-tired.svg',
	'characters/skipper-2.svg',
	'characters/skipper-3.svg',
	'characters/skipper-parrot-2.svg',
	'characters/skipper-parrot-3.svg',
	'characters/skipper-parrot-4.svg',
	'characters/skipper-parrot.svg',
	'characters/skipper-winter-gear-pick-axe.svg',
	'characters/skipper-winter-gear.svg',
	'characters/skipper.svg',
	'characters/wizard-1.svg',
	'characters/wizard-2.svg',
	'characters/wizard-3.svg',
	'characters/wizard-arm-out.svg',
	'characters/wizard-child.svg',
	'characters/wizard-customer.svg',
	'characters/wizard-side.svg',
	'characters/wizard-skates.svg',
	'characters/woman-with-baby-angry.svg',
	'characters/woman-with-baby-happy.svg',
	'characters/woman-with-baby-tired.svg',
	'characters/woman-with-baby.svg',
	'characters/woman-with-handbag-angry.svg',
	'characters/woman-with-handbag-very-angry.svg',
	'characters/woman-with-handbag.svg',
	'characters/woman-with-pizza-angry.svg',
	'characters/woman-with-sandwich-happy.svg',

	'characters/items/big-apple-1.svg',
	'characters/items/box-1.svg',
	'characters/items/box-items-1.svg',
	'characters/items/bread-wrapped-correct.svg',
	'characters/items/bread-wrapped.svg',
	'characters/items/bread.svg',
	'characters/items/cake-excessive-icing.svg',
	'characters/items/checklist-meat-2.svg',
	'characters/items/corny-1.svg',
	'characters/items/cupcake.svg',
	'characters/items/dog-white.svg',
	'characters/items/fireball.svg',
	'characters/items/flower-1.svg',
	'characters/items/hot-deal-1.svg',
	'characters/items/large-sign.svg',
	'characters/items/log-1.svg',
	'characters/items/mop.svg',
	'characters/items/ocean-ring.svg',
	'characters/items/parrot.svg',
	'characters/items/poof.svg',
	'characters/items/spoon-1.svg',
	'characters/items/stack-of-cakes.svg',
	'characters/items/sunglasses-1.svg',
	'characters/items/wand-1.svg',
];

export {
	characters
};
