const module5s = [
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/background.svg',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-1',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-2',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-3',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-4',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-5',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-6',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-7',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-8',
	'modules/tasks/multiple-choice/backroom-5-s-clipboards/option-9',
	'modules/tasks/organize/backroom-5-s-organize-files/computer.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/holiday-ads-placed.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/holiday-ads.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/invoice-placed.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/invoice.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/order-history-placed.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/order-history.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/sanitation-schedules-placed.svg',
	'modules/tasks/organize/backroom-5-s-organize-files/sanitation-schedules.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/bucket-shelved.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/bucket.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/cleaner-jug-shelved.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/cleaner-jug.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/gloves-shelved.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/gloves.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/paper-towels-shelved.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/paper-towels.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/shelves.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/spray-cleaner-shelved.svg',
	'modules/tasks/organize/backroom-5-s-organize-shelves/spray-cleaner.svg',
	'modules/tasks/task-intro/checklist5.svg',
	'modules/tasks/task-intro/order-history.svg',
];

export {
	module5s
};
