const badges = [
	'badges/always-prepared-shadow.svg',
	'badges/always-prepared.svg',
	'badges/blazing-sun-shadow.svg',
	'badges/blazing-sun.svg',
	'badges/deli-superstar-shadow.svg',
	'badges/deli-superstar.svg',
	'badges/food-safety-specialist-shadow.svg',
	'badges/food-safety-specialist.svg',
	'badges/helping-hand-shadow.svg',
	'badges/helping-hand.svg',
	'badges/lucky-charm-shadow.svg',
	'badges/lucky-charm.svg',
	'badges/neat-freak-shadow.svg',
	'badges/neat-freak.svg',
	'badges/perfectionist-shadow.svg',
	'badges/perfectionist.svg',
	'badges/problem-solver-shadow.svg',
	'badges/problem-solver.svg',
	'badges/produce-machine-shadow.svg',
	'badges/produce-machine.svg',
	'badges/produce-superstar-shadow.svg',
	'badges/produce-superstar.svg',
	'badges/released-the-kraken-shadow.svg',
	'badges/released-the-kraken.svg',
	'badges/shooting-star-shadow.svg',
	'badges/shooting-star.svg',
	'badges/smooth-talker-shadow.svg',
	'badges/smooth-talker.svg',
	'badges/stardust-shadow.svg',
	'badges/stardust.svg',
	'badges/stickler-for-details-shadow.svg',
	'badges/stickler-for-details.svg',
	'badges/undercover-hero-shadow.svg',
	'badges/undercover-hero.svg',
	'badges/veteran-deli-shadow.svg',
	'badges/veteran-deli.svg',
	'badges/veteran-produce-shadow.svg',
	'badges/veteran-produce.svg',
];

export {
	badges
};
