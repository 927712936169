const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate',
		characterItems: ['parrot-shoulder-behind'],
		animateCharacter: true,
		text: `Howdy. Welcome to Daily Guidelines.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate',
		characterItems: ['parrot-shoulder-behind'],
		speechBubbleDirection: 'middle',
		text: `<i>Yar, stay in the lines before you walk the plank.</i>`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate',
		characterItems: ['parrot-shoulder-behind'],
		text: `We don't do that here, actually. But we'll help you with the daily guidelines instead.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-first-thing',
		text: `What's the first thing we need to know?`,
		image: 'first',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Where to start', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'What to do',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Lunch',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Cleaning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'Stocking',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate',
		characterItems: ['parrot-shoulder-behind'],
		text: `Correct, we have to know where to look.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-where-start',
		text: `So where do we start?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Sales floor', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'pirate',
							text: `Exactly, get the customer's perspective.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Stock room',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Backroom',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Registers',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-quality-expectations',
		text: `And what do customers expect in Quality?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: '1st tier', isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'pirate',
							text: `It's silly, but true. And we always have to be aware.`
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: '2nd tier',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '3rd tier',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Honorable Mention',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-product-goal',
		text: `What is the goal for product at opening?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Fresh and Full', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Ad items full only',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: '80% ready',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Empty shelves, ready to stock',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-fresh-means',
		text: `Fresh means?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'In date',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Proper ripeness'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Clean label',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'All of the above', isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: 'None of the above',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-fresh-application',
		text: `So fresh only applies to produce, meat, and bakery?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'No, everything should be fresh', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Yes',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'No, also Deli',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'No, also Dairy',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-keep-fresh-how',
		text: `How do we keep items fresh?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Rotation', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Culling', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Order less',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Order often`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Store extra on floor`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-culling-meaning',
		text: `Do you know what cullling means?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Remove poor quality', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Eat'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: 'Mist produce',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Heat to proper temperature`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-culling-stocking',
		text: `Do we save time by culling and stocking together?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'No', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: 'Yes'
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Actually it's better to cull everything before stocking.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-cull-before-stocking',
		text: `Why should you cull before stocking?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'So customers never see poor quality', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `It's faster`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `It's more fun`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `It's easier`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-order-culling',
		text: `What is the process for Culling?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Remove all sub-quality items', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Check sales floor for low inventory', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Get stock from backroom', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Rotate and Stock', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'ocean-raft',
		text: `Before stocking, let's see if you can identify tag information.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `When you bought products, I'm sure you paid attention to the price.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Price tags contain a lot of identifying information.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-label-1',
		subtype: 'images',
		layout: 'daily-guidelines-1-label',
		shuffleOptions: false,
		text: `Click on the part of the label that provides the following information:<br /><br /><strong>UPC</strong>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'UPC', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'name'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'amount'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'code'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'price'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, text: 'unit-price'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-label-2',
		subtype: 'images',
		layout: 'daily-guidelines-1-label',
		shuffleOptions: false,
		text: `Click on the part of the label that provides the following information:<br /><br /><strong>Item code</strong>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'UPC'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'name'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'amount'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'code', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'price'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, text: 'unit-price'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-label-3',
		subtype: 'images',
		layout: 'daily-guidelines-1-label',
		shuffleOptions: false,
		text: `Click on the part of the label that provides the following information:<br /><br /><strong>Price</strong>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'UPC'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'name'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'amount'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'code'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'price', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, text: 'unit-price'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-label-4',
		subtype: 'images',
		layout: 'daily-guidelines-1-label',
		shuffleOptions: false,
		text: `Click on the part of the label that provides the following information:<br /><br /><strong>Item information</strong>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'UPC'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'name', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'amount'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'code'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'price'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, text: 'unit-price'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-label-5',
		subtype: 'images',
		layout: 'daily-guidelines-1-label',
		shuffleOptions: false,
		text: `Click on the part of the label that provides the following information:<br /><br /><strong>Price per oz</strong>`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'UPC'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'name'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'amount'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: 'code'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'price'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 6, text: 'oz-price', isCorrect: true})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Price is important to customers.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `And scanning is how we maintain accuracy. But let's try a scanning situation.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Take these two items up front for our customer.`,
		items: ['frying-pan-and-veg-oil']
	}),
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-branching-story-items-prices',
		maxPoints: 14,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-42',
				image: 'frying-pan-and-veg-oil',
				text: `"Thanks for helping me," the customer says. 
					<br /><br />What do you reply?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: `Of course, glad to help.`, 
						nextStepId: 'qb-43',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],						
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: `No worries.`,
						nextStepId: 'qb-43',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-43',
				text: `You scan the items. 
				<br /><br />What do you say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `"That's $27.39. No wait a minute ..."`,
						nextStepId: 'qb-44',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `"Your total is $27.39."`,
						nextStepId: 'qb-45',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-44',
				text: `"Yeah, that does sound wrong."
					<br /><br />What do you say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `"Let me check each item again."`,
						nextStepId: 'qb-46',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `"Well, that's what it says."`,
						nextStepId: 'qb-46',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-45',
				text: `"Whoa! What? That's not right!"
					<br /><br />What do you say?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `"Let me check each item again."`,
						nextStepId: 'qb-46',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `"Well, that's what it says."`,
						nextStepId: 'qb-46',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-46',
				text: `The pan rings up for $21.99. But how much is the tag?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `$16.99`,
						nextStepId: 'qb-47',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Exactly.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `$21.99`,
						nextStepId: 'qb-48',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `$19.99`,
						nextStepId: 'qb-48',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-47',
				text: `Exactly. So what do you say now?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `"Let me get the Manager."`,
						nextStepId: 'qb-49',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Sorry sir. The price has gone up but I'll ring it up for $16.99.`,
						nextStepId: 'qb-50',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Good idea, but you should get manager approval for that.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `There must have been a price change.`,
						nextStepId: 'qb-51',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-48',
				text: `No it doesn't. It said $16.99. So what do you say now?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `"Let me get the Manager."`,
						nextStepId: 'qb-49',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `Sorry sir. The price has gone but I'll ring it up for $16.99.`,
						nextStepId: 'qb-50',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `There must have been a price change.`,
						nextStepId: 'qb-51',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-49',
				text: `The manager comes and helps you fix the problem. The customer leaves satisfied.`,
				textType: 'info',
				nextStepId: 'qb-52',
				nextStepDelay: 1500
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-50',
				text: `"I appreciate that," the customer says. "But it needs to be fixed." 
					<br /><br />You agree and the customer leaves.`,
				textType: 'info',
				nextStepId: 'qb-52',
				nextStepDelay: 1500
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'qb-51',
				text: `"I want to talk to the manager," the customer says. 
					<br /><br />The manager comes and you manage to solve the issue.`,
				textType: 'info',
				nextStepId: 'qb-52',
				nextStepDelay: 1500
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-52',
				text: `How far off was that pan price?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						text: `$5 higher`,
						nextStepId: 'qb-53',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `That is a lot.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						text: `$3 higher`,
						nextStepId: 'qb-53',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `I don't think so.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						text: `$7.40 higher`,
						nextStepId: 'qb-53',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `I don't think so.`
								})
							})
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4,
						text: `I don't know`,
						nextStepId: 'qb-53',
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `You need to work a bit on your math.`
								})
							})
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistTemplate, {
				id: 'qb-53',
				text: `Here's your result.`,
				checklistData: {
					type: 'daily-guidelines branching-story',
					title: 'Checklist',
					items: [
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Engagement`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-42', optionId: 1}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Double checking `,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-44', optionId: 1}, {stepId: 'qb-45', optionId: 1}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Remembered pricing`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-46', optionId: 1}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Got help`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-47', optionId: 1}, {stepId: 'qb-48', optionId: 1}]}
							]
						}),
						Object.assign({}, taskTemplates.branchingStoryTemplate.stepChecklistItemTemplate, {
							text: `Math success`,
							conditions: [
								{type: 'selected-or', options: [{stepId: 'qb-52', optionId: 1}]}
							]
						}),
					]
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `I would be upset too if the price wasn't posted correctly.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now for something completely different.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `WIC is a nutritional program in grocery stores.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Qualifying items vary by state.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-wic-who',
		text: `First, who is this program for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'Women', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Infants`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Children`, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Men`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Pets`,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-wic-what-not',
		text: `Which of these will not be WIC?`,
		subtype: 'images',
		layout: 'daily-guidelines-1-wic-what-not',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // cookies
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // steak
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // juice
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // yoghurt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // milk
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Correct. Never sugar items and no fresh meat.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-wic-identify',
		text: `How are WIC items identified for customers?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: 'On the price tag', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `On the product label`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `On the aisle`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `By UPC`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `At the register they are scanned but not by the customer.`
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's work on labeling.`
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: null,
		extraCharacters: [{id: 'wizard-arm-out flipped', position: 'right onTop', type: 'animate-enter', items: ['large-sign']}],
		autoContinueSeconds: 3
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Can you move`,
		extraCharacters: [{id: 'wizard-arm-out flipped', position: 'right onTop', items: ['large-sign']}],
	}),
	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-branching-story-big-sign',
		maxPoints: 8,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-61',
				text: `What is wrong?`,
				image: 'wizard2',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						nextStepId: 'qb-62',
						text: `We can't see`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									type: 'wizard',
									text: `Oh, you're right. Sorry.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						nextStepId: 'qb-62',
						text: `You're sign is wrong`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									type: 'wizard',
									text: `Oh, I didn't read it.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3,
						nextStepId: 'qb-62',
						text: `What is on sale?`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									type: 'wizard',
									text: `Oh, I didn't read it.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4,
						nextStepId: 'qb-62',
						text: `Nothing. I'll take 2`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									type: 'wizard',
									text: `Sure`
								})
							}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-62',
				text: `That sign is too big for the store. Why?`,
				image: 'manager-top-right',
				showManager: true,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						nextStepId: 'qb-63',
						text: `It blocks everything`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Exactly.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						nextStepId: 'qb-63',
						text: `It's not, I like it`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Well it can't stay.`
								})
							}),
						],
					}),
					
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-63',
				text: `What would happen if we put signs like that everywhere?`,
				image: 'manager-top-right',
				showManager: true,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						nextStepId: 'qb-64',
						text: `Customers couldn't see product`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Customers want to see the product.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						nextStepId: 'qb-64',
						text: `We'd have a lot of sales`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `How would they get to the products?`
								})
							}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'qb-64',
				text: `How about using a big sign to cover a mistake?`,
				image: 'wizard2',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1,
						nextStepId: 'qb-65',
						text: `Nope, don't do that`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2}),
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `Also, I'm not a mistake, so don't cover me up.`
								})
							}),
						],
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2,
						nextStepId: 'qb-65',
						text: `It could work`,
						effects: [
							Object.assign({}, effectTemplate.mainTemplate, {
								type: 'feedback',
								feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
									text: `No it couldn't. And I'm not a mistake.`
								})
							}),
						],
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepEmptyFinalStepTemplate, {
				id: 'qb-65'
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-spot-errors-sign-blocks',
		text: 'Then what is wrong here?',
		layout: 'daily-guidelines-1-sign-blocks',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign'}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-spot-errors-service-case-signs',
		text: 'What about here?',
		layout: 'daily-guidelines-1-service-case-signs',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign-1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sign-2'}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-signs',
		subtype: 'images',
		layout: 'daily-guidelines-1-signs',
		text: 'Put the correct sign up.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: false
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-table-with-cookies',
		subtype: 'images',
		layout: 'daily-guidelines-1-table-with-cookies',
		text: `Which of these is better?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // table with 4 boxes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // table with 3 boxes
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `You definitely want to have more of your staple items.`
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-table-cookies-full',
		text: `So why this one?`,
		image: 'table-cookies-full',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `80/20 rule`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Looks better`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `More even`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `20/80 rule`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Chocolate chip!!!`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-paretos-law',
		text: `What law is that from?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Pareto's Law`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Martial Law`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `In-Law`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'daily-guidelines-1-multiple-choice-80p-sales',
		text: `80% of sales comes from your ...`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Top 20% items`, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Top 40% items`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, text: `Top 5% items`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Top 10% items`,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate',
		characterItems: ['parrot-shoulder-behind'],
		text: `You are definitely ready for the Grocery Day to Day.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'pirate-arm-out',
		characterItems: ['parrot-shoulder-front'],
		speechBubbleDirection: 'middle',
		text: `<i>Yar, but you still get the plank.</i>`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['plank-of-wood'],
					text: 'A Plank has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'dg1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};