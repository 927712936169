const moduleMeat1 = [
	'modules/tasks/image/meat-1-department.svg',
	'modules/tasks/image/meat-1-hot-deal.svg',
	'modules/tasks/image/meat-1-sales-floor.svg',

	'modules/tasks/multiple-choice/meat-1-chuck-eye/background.svg',
	'modules/tasks/multiple-choice/meat-1-measure/option-1.svg',
	'modules/tasks/multiple-choice/meat-1-measure/option-2.svg',
	'modules/tasks/multiple-choice/meat-1-measure/option-3.svg',
	'modules/tasks/multiple-choice/meat-1-measure/option-4.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-1.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-2.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-3.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-4.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-5.svg',
	'modules/tasks/multiple-choice/meat-1-meat-department/option-6.svg',
	'modules/tasks/multiple-choice/meat-1-steak-labels/option-1.svg',
	'modules/tasks/multiple-choice/meat-1-steak-labels/option-2.svg',
	'modules/tasks/multiple-choice/meat-1-steak-labels/option-3.svg',
	'modules/tasks/multiple-choice/meat-1-steak-labels/option-4.svg',
	'modules/tasks/multiple-choice/meat-1-where-to-look/option-1.svg',
	'modules/tasks/multiple-choice/meat-1-where-to-look/option-2.svg',
	'modules/tasks/multiple-choice/meat-1-where-to-look/option-3.svg',
	'modules/tasks/multiple-choice/meat-1-where-to-look/option-4.svg',
	'modules/tasks/multiple-choice/meat-1-where-to-look/option-5.svg',

	'modules/tasks/organize/meat-1-match-with-animal/categories.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-1.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-2.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-3.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-4.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-5.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-6.svg',
	'modules/tasks/organize/meat-1-match-with-animal/meat-7.svg',

	'modules/tasks/sort/meat-1-animals/chicken.svg',
	'modules/tasks/sort/meat-1-animals/cow.svg',
	'modules/tasks/sort/meat-1-animals/lamb.svg',
	'modules/tasks/sort/meat-1-animals/pig.svg',
	
	'modules/tasks/spot-errors/meat-1-meat-cooler.svg',
	'modules/tasks/spot-errors/meat-1-meat-cooler2.svg',
	'modules/tasks/spot-errors/meat-1-prep-area.svg',
	'modules/tasks/spot-errors/meat-1-stickers-sticker.svg',
	'modules/tasks/spot-errors/meat-1-stickers.svg'
];

export {
	moduleMeat1
};
