import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {capitalizeFirstLetterOfString} from 'helpers/text-helper';
import {getPlayerModuleSessionById} from 'helpers/module-helper';
import {getModuleMaxPoints, getNumberOfFilledStars, getErrorStats} from 'helpers/points-helper';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import './results.scss';

const Results = (props) => {
	const {
		isStartingNewSession, 
		navigationHistory, 
		competitionId,
		moduleData, 
		playerData, 
		handleGoToPage, 
		handleStartNewSession
	} = props;

	/* Show "try again" popup */
	const [showTryAgainPopup, setShowTryAgainPopup] = useState(false);
	const toggleTryAgainPopup = () => {setShowTryAgainPopup(!showTryAgainPopup);};

	/* Get player data for module session */
	const playerModuleSession = getPlayerModuleSessionById(playerData, moduleData.id, competitionId);

	/* Get points and stars */
	const playerPoints = (playerModuleSession && playerModuleSession.points ? playerModuleSession.points : 0);
	const maxPoints = getModuleMaxPoints(moduleData.id, (competitionId ? true : false));
	const filledStars = getNumberOfFilledStars(playerPoints, maxPoints);
	const timeSpent = (playerModuleSession && playerModuleSession.milisecondsPlayed 
		? Math.floor(playerModuleSession.milisecondsPlayed / 1000.) : 0);
	const timeSpentMinutes = (timeSpent > 0 ? Math.floor(timeSpent / 60.) : 0);
	const timeSpentSeconds = timeSpent - (timeSpentMinutes * 60);

	/* Get error stats */
	const {numberOfTasks, avrNumberOfErrorsPerTask, numberOfErrorFreeTasks} = 
		getErrorStats(moduleData, playerModuleSession);
	

	const handleLeaveModule = () => {
		if (moduleData.areaId !== 'side-quest' && moduleData.areaId !== 'competition') {
			handleGoToPage('area-map', null, moduleData.areaId);
		} else {
			
			if (navigationHistory.areaId) {
				handleGoToPage('area-map', null, navigationHistory.areaId);
			} else {
				handleGoToPage('areas', null, null);
			}
			
		}	
	};

	return (
		<div className="Results">
			<div className="Results-background" />
			
			{/* Title */}
			<div className="Results-title">
				<span>{moduleData.title}</span>
				<span>{gameUiTexts.results}:</span>
			</div>

			{/* Stars */}
			<div className="Results-result">
				<div className="Results-resultLabel">{capitalizeFirstLetterOfString(gameUiTexts.stars)}:</div>
				<div className="Results-resultValue"><span>{filledStars}</span></div>
			</div>


			{/* Points */}
			<div className="Results-result">
				<div className="Results-resultLabel">{capitalizeFirstLetterOfString(gameUiTexts.points)}:</div>
				<div className="Results-resultValue"><span>{playerPoints} / {maxPoints}</span></div>
			</div>

			{/* Time spent */}
			<div className="Results-result">
				<div className="Results-resultLabel">{gameUiTexts.timeSpent}:</div>
				<div className="Results-resultValue">
					<span>
						{timeSpentMinutes < 10 ? '0' : ''}{timeSpentMinutes}:
						{timeSpentSeconds < 10 ? '0' : ''}{timeSpentSeconds}
					</span>
				</div>
			</div>
			

			{/* Avr # of errors */}
			<div className="Results-result">
				<div className="Results-resultLabel">{gameUiTexts.avrErrors}:</div>
				<div className="Results-resultValue"><span>{avrNumberOfErrorsPerTask}</span></div>
			</div>

			{/* No-error tasks */}
			<div className="Results-result">
				<div className="Results-resultLabel">{gameUiTexts.noErrorsTasks}:</div>
				<div className="Results-resultValue"><span>{numberOfErrorFreeTasks}/{numberOfTasks}</span></div>
			</div>

			{/* Buttons */}
			<div className="Results-buttons">
				<Button 
					classes={['yellow']} 
					text={gameUiTexts.backToDepartment} 
					onClick={() => {handleLeaveModule();}} 
				/>
				<Button classes={['yellow']} text={gameUiTexts.tryAgain} onClick={() => {toggleTryAgainPopup();}} />
			</div>

			{/* "Try again" popup */}
			{showTryAgainPopup && 
				<Popup 
					type="try-again"
					title={gameUiTexts.tryAgainPopup.title}
					text={gameUiTexts.tryAgainPopup.text}
					buttons={[
						{
							isLoading: isStartingNewSession,
							classes: ['yellow', 'try-again'],
							text: gameUiTexts.tryAgainPopup.resetBtn, 
							action: handleStartNewSession
						},
						{
							classes: ['yellow', 'try-again'],
							text: gameUiTexts.tryAgainPopup.cancelBtn,
							action: toggleTryAgainPopup
						}
					]}
					togglePopup={toggleTryAgainPopup}
				/>
			}
		</div>
	);
};

Results.propTypes = {
	isStartingNewSession: PropTypes.bool.isRequired,
	navigationHistory: PropTypes.object.isRequired,
	competitionId: PropTypes.string,
	moduleData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleStartNewSession: PropTypes.func.isRequired
};

export default Results;
