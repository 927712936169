const moduleCoolerFreezer1 = [
	'modules/tasks/image/cooler-freezer-1-shelf-with-lettuce.svg',
	'modules/tasks/image/cooler-freezer-1-possible-errors.svg',

	'modules/tasks/instructions/boxes.svg',

	'modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-1.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-3.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-4.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-1.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-2.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-3.svg',
	'modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-4.svg',

	'modules/tasks/spot-errors/cooler-freezer-1-freezer-issues.svg',
];

export {
	moduleCoolerFreezer1
};
