import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {facilitatorUiTexts, generalUiTexts} from 'data/ui-texts';
import Manager from 'components/ui/manager/manager';
import './facilitator-select-store.scss';

const FacilitatorSelectStore = ({gamesData, handleSelectGame}) => {
	return (
		<div className="FacilitatorSelectStore">
			{/* Title */}
			<div className="FacilitatorSelectStore-title"><span>{facilitatorUiTexts.selectStore}</span></div>
			
			{/* No stores */}
			{gamesData.length === 0 && 
				<div className="FacilitatorSelectStore-noStores"><span>{facilitatorUiTexts.noStores}</span></div>}


			{/* Stores */}
			{gamesData.map((gameData) => {
				const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);
				return (
					<div 
						key={gameData.id} 
						className="FacilitatorSelectStore-store"
						onClick={() => {handleSelectGame(gameData.id);}}
					>
						
						<div className="FacilitatorSelectStore-storeInfo">
							<div className="FacilitatorSelectStore-storeName">
								<span>{generalUiTexts.store}:</span>{gameData.storeName}
							</div>
							<div className="FacilitatorSelectStore-storeUrl">
								<span>{generalUiTexts.url}:</span>{gameData.url}
							</div>
						</div>
						<div className="FacilitatorSelectStore-storeManager">
							<Manager page="facilitator-header" managerPose="manager-2" managerId={managerId} />
						</div>
					</div>
				);
			})}
		</div>
	);

};

FacilitatorSelectStore.propTypes = {
	gamesData: PropTypes.array.isRequired,
	handleSelectGame: PropTypes.func.isRequired
};

export default FacilitatorSelectStore;
