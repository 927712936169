import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {sortArrayByProperty} from 'helpers/array-helper';
// import {adminUiTexts} from 'data/ui-texts';
// import Button from 'components/ui/button/button';
import FacilitatorTaskPopup from 'components/facilitator/facilitator-task-popup/facilitator-task-popup';
import FacilitatorGameModules from 'components/facilitator/facilitator-game-modules/facilitator-game-modules';
import './admin-game-content.scss';

const AdminGameContent = ({deviceInfo, pageData, gamesData, usersData, handleGoToPage}) => {

	/* Selected module (shown in popup) */
	const [selectedModuleId, setSelectedModuleId] = useState(null);
	const toggleModulePopup = (module = null) => {
		if (module) {
			setSelectedModuleId(module.id);
		} else {
			setSelectedModuleId(null);
		}
	};

	return (
		<div className="AdminGameContent">
			<FacilitatorGameModules 
				deviceInfo={deviceInfo}
				pageData={pageData}
				handleGoToPage={handleGoToPage}
				toggleModulePopup={toggleModulePopup}
			/>
			
			{selectedModuleId &&
					<div className='Admin-popupOverlay' onClick={() => {toggleModulePopup(null);}}>
						<FacilitatorTaskPopup 
							isFacilitator={true}
							moduleId={selectedModuleId}
							managerId={'a'}
							deviceInfo={deviceInfo}
							playerData={{avater: null}}
							closePopup={() => {toggleModulePopup(null);}}
						/>
					</div>}
		</div>
	);

};

AdminGameContent.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	pageData: PropTypes.object.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default AdminGameContent;
