const moduleShrink1 = [
'modules/tasks/image/shrink-1-people.svg',

'modules/tasks/multiple-choice/shrink-1-bags/option-1.svg',
'modules/tasks/multiple-choice/shrink-1-bags/option-2.svg',
'modules/tasks/multiple-choice/shrink-1-bags/option-3.svg',
'modules/tasks/multiple-choice/shrink-1-bags/option-4.svg',
'modules/tasks/multiple-choice/shrink-1-buckets/option-1.svg',
'modules/tasks/multiple-choice/shrink-1-buckets/option-2.svg',
'modules/tasks/multiple-choice/shrink-1-buckets/option-3.svg',
'modules/tasks/multiple-choice/shrink-1-buckets/option-4.svg',
'modules/tasks/multiple-choice/shrink-1-buckets/option-5.svg',
	
'modules/tasks/sort/shrink-1-math/background.svg',

'modules/tasks/spot-errors/shrink-1-potential-shrink.svg',
'modules/tasks/spot-errors/shrink-1-where-shrink.svg'
];

export {
	moduleShrink1
};