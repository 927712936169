import dayjs from 'dayjs';
import {facilitatorUiTexts} from 'data/ui-texts';

/**
 * Check if competition has been filled out correct
 * @param {bool} isNew
 * @param {string} selectedTypeId 
 * @param {string} selectedModuleId 
 * @param {string} selectedStartDate 
 * @param {string} selectedEndDate 
 * @param {boolean} allCanParticipate
 * @param {array} selectedAreaIds
 * @param {string} description 
 * @param {string} prize 
 * @param {object} competitions 
 * @returns 
 */
export function checkCompetition(isNew, selectedTypeId, selectedModuleId,
	selectedStartDate, selectedEndDate, allCanParticipate, selectedAreaIds,
	description, prize, competitions
) {
	let errMsg = null;

	/* Type not selected */
	if (isNew && !selectedTypeId) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errNoType;
		return errMsg;
	}

	/* Module not selected */
	if (isNew && !selectedModuleId) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errNoModule;
		return errMsg;
	}

	/* No start / end date */
	if (!selectedStartDate || !selectedEndDate) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errNoSchedule;
		return errMsg;
	}

	/* Start date is after end date */
	if (dayjs(selectedStartDate).diff(dayjs(selectedEndDate)) > 0) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errDates1;
		return errMsg;
	}
	
	/* End date is in the past */
	const today = dayjs(new Date()).format('YYYY-MM-DD');
	if (dayjs(today).diff(dayjs(selectedEndDate), 'days') > 0) {
		
		errMsg = facilitatorUiTexts.addCompetitionPopup.errDates2;
		return errMsg;
	}

	/* Start date is in the past (only check for new competitions) */
	if (isNew && dayjs(today).diff(dayjs(selectedStartDate), 'days') > 0) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errDates4;
		return errMsg;
	}

	/* No areas selected (only check for new competitions) */
	if (isNew && !allCanParticipate && selectedAreaIds.length === 0) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errAreas;
		return errMsg;
	}

	/* Missing description */
	if (!description || description.length === 0) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errDescription;
		return errMsg;
	}

	/* Missing prize */
	if (!prize || prize.length === 0) {
		errMsg = facilitatorUiTexts.addCompetitionPopup.errPrize;
		return errMsg;
	}

	return errMsg;
};

/**
 * Generate random id
 * @param {number} length 
 * @returns 
 */
export function generateRandomId(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}


/**
 * Get active competitions
 * @param {object} gameData 
 * @returns 
 */
export function getActiveCompetitions(playerData, gameData) {
	let activeCompetitions = [];

	if (gameData && gameData.competitions && gameData.competitions.length > 0) {
		const today = dayjs(new Date()).format('YYYY-MM-DD');
		activeCompetitions = gameData.competitions.filter((c) => {
			/* Check if player has access to at least 1 of the required areas for the competition */
			let playerHasAccessToRequiredAreas = true;
			if (
				c.selectedAreaIds && c.selectedAreaIds.length > 0 &&
				playerData.disabledAreaIds && playerData.disabledAreaIds.length > 0
			) {
				playerHasAccessToRequiredAreas = false;
				c.selectedAreaIds.forEach((areaId) => {
					if (playerHasAccessToRequiredAreas) return;
					if (playerData.disabledAreaIds.indexOf(areaId) < 0) playerHasAccessToRequiredAreas = true;
				});	
			}

			/* Filter by acess to areas and start / end date */
			return (
				playerHasAccessToRequiredAreas &&
				dayjs(today).diff(dayjs(c.startDate), 'days') >= 0 &&
				dayjs(today).diff(dayjs(c.endDate), 'days') <= 0
			);
		});
	}

	return activeCompetitions;
};

/**
 * Get all participating players
 * @param {array} gamePlayers 
 * @param {bool} allCanParticipate 
 * @param {array} selectedAreaIds 
 * @returns 
 */
export function getParticipatingPlayers(gamePlayers, allCanParticipate, selectedAreaIds) {
	/* Get all current players that are able to participate */
	const participatingPlayers = gamePlayers.filter((player) => {
		/* All can participate */
		if (allCanParticipate) return true;
		
		/* Only some can participate */
		if (selectedAreaIds.length === 0) {
			/* No area ids selected yet */
			return false;
		}

		let playerCanParticipate = true;
		if (player.disabledAreaIds && player.disabledAreaIds.length > 0) {
			playerCanParticipate = false;
			selectedAreaIds.forEach((areaId) => {
				if (playerCanParticipate === true) return;
				if (player.disabledAreaIds.indexOf(areaId) < 0) playerCanParticipate = true;
			});
		}
		return playerCanParticipate;
	});

	return participatingPlayers;
}