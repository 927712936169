/* eslint-disable max-len */
import appConfig from './app.config';
import {LogLevel} from '@azure/msal-browser';

/* Login redirect */
let redirectUrl = appConfig.redirectUrl;
if (appConfig.env !== 'development') redirectUrl = 'https://' + window.location.host;

/* Logout redirect */
const postLogoutRedirectUri = window.location.href;

/* Client id */
let clientId = '26cee644-a3bf-4243-b238-06984dc916cd';
if (appConfig.env === 'demo') clientId = '746d226b-c9cd-4241-b113-0782e2091d40';
if (appConfig.env === 'production') clientId = '36911f48-a084-44ba-a5ad-fe22328c9e2c';

/* Domain */
let domain = 'AlexLeeB2CDev';
if (appConfig.env === 'demo') domain = 'AlexLeeB2CQA';
if (appConfig.env === 'production') domain = 'AlexLeeB2CTenant';


/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
	names: {
		signUpSignIn: 'B2C_1A_ACCOUNT_MANAGEMENT_SIGNIN',
		editProfile: 'B2C_1A_ACCOUNT_MANAGEMENT_SIGNIN'
	},
	authorities: {
		signUpSignIn: {
			authority: 'https://' + domain + '.b2clogin.com/' + domain + '.onmicrosoft.com/B2C_1A_ACCOUNT_MANAGEMENT_SIGNIN',
		},
		editProfile: {
			authority: 'https://' + domain + '.b2clogin.com/' + domain + '.onmicrosoft.com/B2C_1A_ACCOUNT_MANAGEMENT_SIGNIN'
		}
	},
	authorityDomain: domain + '.b2clogin.com'
};

export const msalConfig = {
	auth: {
		clientId: clientId, // This is the ONLY mandatory field; everything else is optional.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
		knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
		redirectUri: redirectUrl, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
		postLogoutRedirectUri: postLogoutRedirectUri
	},
	cache: {
		cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
				case LogLevel.Error:
					console.error(message);
					return;
				case LogLevel.Info:
					console.info(message);
					return;
				case LogLevel.Verbose:
					console.debug(message);
					return;
				case LogLevel.Warning:
					console.warn(message);
					return;
				default:
					return;
				}
			}
		}
	}
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit: 
* https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const loginRequest = {
	scopes: [],
};