import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {modulesData} from 'data/modules/modules-data';
import appConfig from 'config/app.config';
import {facilitatorUiTexts} from 'data/ui-texts';
import { 
	getModulesCompletionPercentage,
	getTotalTimeSpentInModule, 
	getModuleErrorAverage,
	getMaxModuleStars,
	getSessionStars,
	getTimeSpendInModuleSession,
	getSessionErrorAverage,
	getPlayerModuleData,
	checkIfModuleIsCompleted
} from 'helpers/module-helper';
import Star from 'components/ui/star/star';
import FacilitatorTaskPopup from '../../facilitator-task-popup/facilitator-task-popup';
import Avatar from 'components/game/avatar/avatar';
import Select from 'components/ui/select/select';
import './facilitator-player-popup.scss';

const FacilitatorPlayerPopup = ({deviceInfo, playerData, gameData, togglePopup}) => {
	/* Manager id */
	const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);

	/* Selected module id */
	const [selectedModuleId, setSelectedModuleId] = useState(modulesData[0].id);

	/* Selected task id */
	const [selectedTaskId, setSelectedTaskId] = useState(null);

	/* Selected module data & tasks */
	const selectedModuleData = modulesData.find((moduleData) => {return moduleData.id === selectedModuleId;});


	/* Player info */
	const lastLogin = (playerData.lastLogin ? dayjs(playerData.lastLogin).format('MM/DD-YY') : '-');

	/* General module stats */
	const completion = (playerData.modules ? getModulesCompletionPercentage(playerData.modules) : 0);
	const playerModuleData = getPlayerModuleData(selectedModuleId, playerData, null);
	const totalTimePlayed = (playerModuleData ? getTotalTimeSpentInModule(playerModuleData) : 0);
	const maxFilledStars = (playerModuleData ? getMaxModuleStars(playerModuleData,  selectedModuleId) : []);
	const avgErrors = (playerModuleData ? getModuleErrorAverage(playerModuleData) : 0);
	
	/* Module stats for 2 most recent sessions */
	const numberOfSessionsCompleted = (playerModuleData && playerModuleData.sessions 
		? playerModuleData.sessions.filter((s) => {return s.isCompleted === true;}).length
		: 0
	);
	const starsRecentSession = getSessionStars(playerModuleData, 0);
	const starsPrevSession = getSessionStars(playerModuleData, 1);
	const timeRecentSession = getTimeSpendInModuleSession(playerModuleData, 0);
	const timePrevSession = getTimeSpendInModuleSession(playerModuleData, 1);
	const avgErrorsRecentSession = getSessionErrorAverage(playerModuleData, 0);
	const avgErrorsPrevSession = getSessionErrorAverage(playerModuleData, 1);
	

	/* Module options (for drop down select module) */
	let moduleOptions = modulesData.map((m) => {
		return {
			id: m.id, 
			value: m.title,
			class: (checkIfModuleIsCompleted(getPlayerModuleData(m.id, playerData, null)) ? 'checked' : null)
		};
	});


	/* Select module  */
	const handleSelectModule = (moduleId = null) => {
		setSelectedTaskId(null);
		setSelectedModuleId(moduleId);
	};

	/* Select module task */
	const handleSelectTask = (taskId = null) => {
		if (!taskId || taskId === selectedTaskId) {
			setSelectedTaskId(null);
		} else {
			setSelectedTaskId(taskId);
		}
	};

	return (
		<div className="FacilitatorPlayerPopup" onClick={() => {togglePopup(null);}}>
			<div className="FacilitatorPlayerPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorPlayerPopup-header">
					<div className='FacilitatorPlayerPopup-headerRow spaceBetween'>
						<div className='FacilitatorPlayerPopup-headerColumn'>
							<div className='FacilitatorPlayerPopup-playerInfo'>
								<span>{playerData.name}</span>
								<span>{playerData.email}</span>
							</div>
							
							<div className='FacilitatorPlayerPopup-lastLogin'>
								{facilitatorUiTexts.lastLogin + ' ' + lastLogin}
							</div>
						</div>
						<div className='FacilitatorPlayerPopup-headerColumn'>
							<div className='FacilitatorPlayerPopup-avatar'>
								<Avatar type="largeButton" playerData={playerData} />
							</div>
							<div className='FacilitatorPlayerPopup-completion'>
								{facilitatorUiTexts.completion + ' ' + completion.toFixed(2) + '%'}
							</div>
						</div>
						<div className='FacilitatorPlayerPopup-headerColumn'>
							<div
								className="FacilitatorPlayerPopup-closeBtn" 
								onClick={() => {togglePopup();}}
							/>
							<div className="FacilitatorPlayerPopup-selectedModule">
								<div className='FacilitatorPlayerPopup-dropdownLabel'>
									<span>{facilitatorUiTexts.selectModule}:</span>
								</div>
								<div className='FacilitatorPlayerPopup-dropdown'>
									<Select 
										type="facilitator-player-popup"
										selectedId={selectedModuleId}
										options={moduleOptions}
										onSelect={handleSelectModule}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Popup general module stats (for all sessions played) */}
				<div className='FacilitatorPlayerPopup-generalModuleStats'>
					{/* Stars in module */}
					<div className='FacilitatorPlayerPopup-moduleStars'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{(selectedModuleData ? selectedModuleData.title : '')}</span>
						</div>
						<div className='FacilitatorPlayerPopup-stars'>
							{maxFilledStars.map((filled, index) => {
								return (
									<div key={index}>
										<Star page='facilitator' isFilled={filled}/>
									</div>
								);
							})}
						</div>
					</div>
					{/* Total time spent */}
					<div className='FacilitatorPlayerPopup-textWrapper center'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{facilitatorUiTexts.totalTimeSpent}:</span>
						</div>
						<div className='FacilitatorPlayerPopup-text'>
							<span>{totalTimePlayed + ' ' + facilitatorUiTexts.minute}</span>
						</div>
					</div>
					{/* Avr mistakes */}
					<div className='FacilitatorPlayerPopup-textWrapper'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{facilitatorUiTexts.avgMistakes}:</span>
						</div>
						<div className='FacilitatorPlayerPopup-text'>
							<span>{avgErrors}</span>
						</div>
					</div>
				</div>

				{/* Module stats for 2 most recent sessions */}
				{numberOfSessionsCompleted > 1 && <div className='FacilitatorPlayerPopup-recentModuleStats'>
					{/* Session labels */}
					<div className='FacilitatorPlayerPopup-recentModuleStatsColumn labels'>
						<div className="FacilitatorPlayerPopup-sessionLabel">
							<span>{facilitatorUiTexts.recentSession}:</span>
						</div>
						<div className="FacilitatorPlayerPopup-sessionLabel">
							<span>{facilitatorUiTexts.prevSession}:</span>
						</div>
					</div>

					{/* Stars */}
					<div className='FacilitatorPlayerPopup-recentModuleStatsColumn'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{facilitatorUiTexts.stars}</span>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-stars'>
								{starsRecentSession.map((filled, index) => {
									return (
										<div key={index}>
											<Star page='facilitator' isFilled={filled}/>
										</div>
									);
								})}
							</div>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-stars'>
								{starsPrevSession.map((filled, index) => {
									return (
										<div key={index}>
											<Star page='facilitator' isFilled={filled}/>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					{/* Time spent */}
					<div className='FacilitatorPlayerPopup-recentModuleStatsColumn'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{facilitatorUiTexts.timeSpent}</span>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-text'>
								<span>{timeRecentSession + ' ' + facilitatorUiTexts.minute}</span>
							</div>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-text'>
								<span>{timePrevSession + ' ' + facilitatorUiTexts.minute}</span>
							</div>
						</div>
					</div>

					{/* Avr mistakes */}
					<div className='FacilitatorPlayerPopup-recentModuleStatsColumn'>
						<div className='FacilitatorPlayerPopup-title'>
							<span>{facilitatorUiTexts.avgMistakes}:</span>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-text'>
								<span>{avgErrorsRecentSession}</span>
							</div>
						</div>
						<div className="FacilitatorPlayerPopup-session">
							<div className='FacilitatorPlayerPopup-text'>
								<span>{avgErrorsPrevSession}</span>
							</div>
						</div>
					</div>
				</div>}

				{/* Popup - body */}
				<div className='FacilitatorPlayerPopup-body'>
					<div className='FacilitatorPlayerPopup-table'>
						<div className='FacilitatorPlayerPopup-tableHeader'>
							<div className="FacilitatorPlayerPopup-tableItem task">
								<span>{facilitatorUiTexts.task}</span>
							</div>
							<div className="FacilitatorPlayerPopup-tableItem avgMistakes">
								<span>{facilitatorUiTexts.avgMistakes}</span>
							</div>
							<div className="FacilitatorPlayerPopup-tableItem mistakes">
								<span>{facilitatorUiTexts.mistakes}</span>
								<span>({facilitatorUiTexts.recentSession})</span></div>
							<div  className="FacilitatorPlayerPopup-tableItem mistakes">
								<span>{facilitatorUiTexts.mistakes}</span> 
								<span>({facilitatorUiTexts.prevSession})</span>
							</div>
							<div className="FacilitatorPlayerPopup-tableItem view">
								<span>{facilitatorUiTexts.view}</span>
							</div>
						</div>
						<div className='FacilitatorPlayerPopup-tableContent'>
							{(selectedModuleData && selectedModuleData.tasks) && 
								selectedModuleData.tasks.map((taskData) => {
									if (!taskData.isSolveToContinue) return null;
									let numberOfErrors = 0;
									let numberOfErrorsRecentSession = '-';
									let numberOfErrorsPrevSession = '-';
									let timesPlayed = 0;
									if (
										playerModuleData && 
										playerModuleData.sessions &&
										playerModuleData.sessions.length > 0
									) {
										playerModuleData.sessions.forEach((session, sessionIndex) => {
											if (session.tasks) {
												const playerTaskData = session.tasks.find((t) => {
													return t.taskId === taskData.taskId;
												});
												if (playerTaskData && playerTaskData.hasOwnProperty('errors')) {
													numberOfErrors += playerTaskData.errors;
													timesPlayed += 1;
													if (sessionIndex === 0) {
														if (numberOfErrorsRecentSession === '-') {
															numberOfErrorsRecentSession = 0;
														}
														numberOfErrorsRecentSession += playerTaskData.errors;
													}
													if (sessionIndex === 1) {
														if (numberOfErrorsPrevSession === '-') {
															numberOfErrorsPrevSession = 0;
														}
														numberOfErrorsPrevSession += playerTaskData.errors;
													}
												}
											}
										});
									}

									const avgErrors = (timesPlayed > 0 
										? Math.round(numberOfErrors / timesPlayed * 10) / 10
										: '-'
									);

									return ( 
										<div key={taskData.id} className="FacilitatorPlayerPopup-row">  
											<div className='FacilitatorPlayerPopup-tableItem task'>
												<span>{taskData.id}</span>
											</div>
											<div className='FacilitatorPlayerPopup-tableItem avgMistakes'>
												<span>{avgErrors}</span>
											</div>
											<div className='FacilitatorPlayerPopup-tableItem mistakes'>
												<span>{numberOfErrorsRecentSession}</span>
											</div>
											<div className='FacilitatorPlayerPopup-tableItem mistakes'>
												<span>{numberOfErrorsPrevSession}</span>
											</div>
											<div
												className='FacilitatorPlayerPopup-tableItem view' 
												onClick={() => {handleSelectTask(taskData.id);}}
											/>
										</div>
									);									
								})
							}
						</div>
					</div>
				</div>
			</div>

			{/* Task popup */}
			{(selectedModuleData && selectedTaskId) && 
				<FacilitatorTaskPopup 
					isFacilitator={false}
					initialTaskId={selectedTaskId}
					moduleId={selectedModuleId}
					managerId={managerId}
					deviceInfo={deviceInfo}
					playerData={playerData} 
					playerModuleTasks={(
						playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0
							? playerModuleData.sessions[playerModuleData.sessions.length - 1].tasks 
								? playerModuleData.sessions[playerModuleData.sessions.length - 1].tasks : []
							: []
					)}
					closePopup={() => {handleSelectTask(null);}}
				/>}
		</div>
	);
};

FacilitatorPlayerPopup.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	playerData: PropTypes.object,
	gameData: PropTypes.object.isRequired,
	togglePopup: PropTypes.func.isRequired
};

export default FacilitatorPlayerPopup;
