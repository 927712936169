const {competitionTemplate} = require('data/templates/competition-template');

const modulesCompetitionsData = [
	Object.assign({}, competitionTemplate, {
		id: 'easter-1',
		title: 'Easter',
		description: `Easter competition module.`,
		relatedModuleIds: ['orientation-1'],
		tasks: require('data/modules/competitions/easter-1-tasks').moduleTasks,
	}),
]

export {
	modulesCompetitionsData
};