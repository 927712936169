const moduleSeafood1 = [
	'modules/tasks/image/seafood-1-department.svg',
	'modules/tasks/image/seafood-1-calendar-first.svg',

	'modules/tasks/multiple-choice/seafood-1-fillets/option-1.svg',
	'modules/tasks/multiple-choice/seafood-1-fillets/option-2.svg',
	'modules/tasks/multiple-choice/seafood-1-fillets/option-3.svg',
	'modules/tasks/multiple-choice/seafood-1-fillets/option-4.svg',
	'modules/tasks/multiple-choice/seafood-1-knife-angle/option-1.svg',
	'modules/tasks/multiple-choice/seafood-1-knife-angle/option-2.svg',
	'modules/tasks/multiple-choice/seafood-1-knife-angle/option-3.svg',
	'modules/tasks/multiple-choice/seafood-1-issues-priority/background.svg',
	'modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-1.svg',
	'modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-2.svg',
	'modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-3.svg',
	'modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-4.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-1.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-2.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-3.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-4.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-5.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-6.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-7.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-8.svg',
	'modules/tasks/multiple-choice/seafood-1-shellfish/option-9.svg',
	'modules/tasks/multiple-choice/seafood-1-where-to-start/option-1.svg',
	'modules/tasks/multiple-choice/seafood-1-where-to-start/option-2.svg',
	'modules/tasks/multiple-choice/seafood-1-where-to-start/option-3.svg',

	'modules/tasks/organize/seafood-1-fish-labels/background.svg',
	'modules/tasks/organize/seafood-1-fish-labels/label-1.svg',
	'modules/tasks/organize/seafood-1-fish-labels/label-2.svg',
	'modules/tasks/organize/seafood-1-fish-labels/label-3.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/background.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/background1.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/background2.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/foreground.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-1.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-2.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-3.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-4.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-5.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-6.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-4.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-5.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-6.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-1.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-2.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-3.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-1.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-2.svg',
	'modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-3.svg',
	
	
	'modules/tasks/spot-errors/seafood-1-bad-fish.svg',
	'modules/tasks/spot-errors/seafood-1-calendar-first.svg',
	'modules/tasks/spot-errors/seafood-1-freezer-issues.svg',
	'modules/tasks/spot-errors/seafood-1-product-case.svg',
	'modules/tasks/spot-errors/seafood-1-shrimp-tag.svg',
];

export {
	moduleSeafood1
};
