import {useState} from 'react';
import PropTypes from 'prop-types';
import './select-multiple.scss';

const SelectMultiple = (props) => {
	const {
		isDisabled = false,
		type, 
		placeholderText = null, 
		selectedIds = [], 
		options, 
		onSelect
	} = props;

	/* Dropdown */
	let [dropdownIsOpen, setDropDown] = useState(false);
	const toggleDropdown = () => {
		if (isDisabled) return;
		setDropDown(!dropdownIsOpen);
	};

	/* Select / deselect option from list */
	const handleSelect = (optionId) =>  {
		if (isDisabled) return;
		onSelect(optionId);
	};	

	return (
		<div className={'SelectMultiple ' + type 
			+ (dropdownIsOpen ? ' open' : '') 
			+ (isDisabled ? ' disabled' : '')
		}>
			<div className="SelectMultiple-panel">				
				<div className="SelectMultiple-selected" onClick={() => {toggleDropdown();}}>
					<span>{(placeholderText ? placeholderText : '')}</span>
				</div>
				
			</div>
			<div className="SelectMultiple-dropdown">
				{options.map((option) => {
					const isSelected = (selectedIds.indexOf(option.id) >= 0);
					return (
						<div 
							key={option.id} 
							className={'SelectMultiple-option' + (isSelected ? ' selected' : '') }
							onMouseDown={() => {handleSelect(option.id);}}
							// if we use onClick it will trigger onBlur first,
							// and then onClick will never be triggered, 
							// onMouseDown is triggered before onBlur
						>
							<span>{option.value}</span>
						</div>
					);
				})}
			</div>

		</div>
	);
};

SelectMultiple.propTypes = {
	isDisabled:PropTypes.bool, 
	type: PropTypes.string.isRequired,
	placeholderText: PropTypes.string,
	selectedIds: PropTypes.array,
	options: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired
};

export default SelectMultiple;