const moduleBagger1 = [
	'modules/tasks/image/bagger-1-employee-perspective.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-packing/option-1.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-packing/option-2.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-packing/option-3.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-uniform/option-1.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-uniform/option-2.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-uniform/option-3.svg',
	'modules/tasks/multiple-choice/bagger-1-correct-uniform/option-4.svg',
	'modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-1.svg',
	'modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-2.svg',
	'modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-3.svg',
	'modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-4.svg',
	'modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-5.svg',
	'modules/tasks/multiple-choice/bagger-1-uniform-parts/option-1.svg',
	'modules/tasks/multiple-choice/bagger-1-uniform-parts/option-2.svg',
	'modules/tasks/multiple-choice/bagger-1-uniform-parts/option-3.svg',
	'modules/tasks/sort/bagger-1-sort-correct-bags-beans/bag-with-beans.svg',
	'modules/tasks/sort/bagger-1-sort-correct-bags-beans/empty-bag.svg',
	'modules/tasks/sort/bagger-1-sort-correct-bags-cat-food/bag-with-cat-food.svg',
	'modules/tasks/sort/bagger-1-sort-correct-bags-cat-food/empty-bag.svg',
	'modules/tasks/spot-errors/bagger-1-parkinglot-look-out.svg',
	

	'modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-1.svg',
	'modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-2.svg',
	'modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-3.svg',
	'modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-4.svg',

];

export {
	moduleBagger1
};
