import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {checkIfAreaNotificationIsTriggered} from 'helpers/notifications-helper';
import {
	checkIfModuleIsUnlocked, 
	getModuleBridgeStatus,
	getSideQuestVisibleStones,
	getMaxNumberOfStarsInModule
} from 'helpers/module-helper';
import {areasData} from 'data/areas-data';
import {modulesData} from 'data/modules/modules-data';
import {mapUiTexts} from 'data/ui-texts';
import {ReactComponent as ModuleStatus0} from 'assets/images/map/module-status-0.svg';
import {ReactComponent as ModuleStatus1} from 'assets/images/map/module-status-1.svg';
import {ReactComponent as ModuleStatus2} from 'assets/images/map/module-status-2.svg';
import {ReactComponent as ModuleStatus3} from 'assets/images/map/module-status-3.svg';
import {ReactComponent as ModuleStatusNoStars} from 'assets/images/map/module-status-no-stars.svg';
import {ReactComponent as Stones1} from 'assets/images/map/stones-1.svg';
import {ReactComponent as Stones2} from 'assets/images/map/stones-2.svg';
import {ReactComponent as Stones3} from 'assets/images/map/stones-3.svg';
import Area from 'components/ui/area/area';
import PopupLootBox from 'components/ui/popup-loot-box/popup-loot-box';
import Avatar from 'components/game/avatar/avatar';
import NavigationTop from 'components/ui/navigation-top/navigation-top';
import NotificationsGame from 'components/ui/notifications-game/notifications-game';
import NotificationManager from 'components/ui/notification-manager/notification-manager';
import './area-map.scss';

const AreaMap = (props) => {
	/* Props */
	const {
		areaId, 
		deviceInfo, 
		gameData, 
		playerData, 
		userData, 
		reserveLootBoxOptions,
		getLootBoxItem, 
		updatePlayerGameData, 
		handleGoToPage,
		setLastAreaIndex
	} = props;

	/* Timeouts */
	let timeout = null;
	let timeout2 = null;

	/* Notifications */
	const [managerNotification, setManagerNotification] = useState(null);

	/* Popups */
	const [lootBoxId, setLootBoxId] = useState(null);
	const [unavailableFeedback, setUnavailableFeedback] = useState(null);

	/* Move avatar to position */
	const [moveAvatar, setMoveAvatar] = useState(null);

	/* Get area data */
	const areaData = areasData.find((a) => {return a.id === areaId;});

	/* Get player area data and avatar position */
	const playerAreaData = (playerData.areas 
		? playerData.areas.find((a) => {return a.areaId === areaId;})
		: null
	);
	let avatarPosition = 0;
	if (playerAreaData && playerAreaData.lastModuleId && areaData.moduleIds.indexOf(playerAreaData.lastModuleId) >= 0) {
		avatarPosition = (areaData.moduleIds.indexOf(playerAreaData.lastModuleId) + 1);
	}

	/**
	 * Close notification
	 * @param {string} notificationId 
	 */
	const handleCloseNotification = (notificationId) => {
		const playerNotifications = playerData.notifications ? playerData.notifications : [];
		playerNotifications.push(notificationId);
		/* Log notifications as seen */
		updatePlayerGameData({notifications: playerNotifications}).then(() => {
			setManagerNotification(null);
		});
	};

	/**
	 * Go to module
	 * @param {bool} isUnlocked 
	 * @param {string} moduleId 
	 * @param {bool} isSideQuest
	 */
	const handleGoToModule = (isUnlocked, moduleId, isSideQuest) => {
		if (isUnlocked || appConfig.env === 'development' || appConfig.env === 'test' || userData.role === 'admin') {
			if (
				isSideQuest ||
				avatarPosition === areaData.moduleIds.indexOf(moduleId) + 1
			) {
				/* Avatar already stands at module */
				handleGoToPage('module', moduleId);
			} else {
				
				/* Get new position */
				const newPosition = areaData.moduleIds.indexOf(moduleId) + 1;

				/* Move avatar */
				setMoveAvatar('move-' + avatarPosition + '-' + newPosition);
				
				/* Auto-scroll to avatar */
				if (timeout) clearTimeout(timeout);
				timeout = setTimeout(() => {
					scrollToAvatar(newPosition);
				}, 250);	

				/* Go to module */
				if (timeout2) clearTimeout(timeout2);
				timeout2 = setTimeout(() => {
					handleGoToPage('module', moduleId);
				}, 1000);	
			}
			
		} else {
			if (isSideQuest) {
				setUnavailableFeedback(mapUiTexts.sideQuestModuleLocked);
			} else {
				setUnavailableFeedback(mapUiTexts.moduleLocked);
			}
		}
	};

	/**
	 * Get loot box
	 * @param {string} lootBoxId 
	 */
	const handleGetLootBox = (isUnlocked, lootBoxId) => {
		if (
			(isUnlocked || appConfig.env === 'test' || userData.role === 'admin')) {
			setLootBoxId(lootBoxId);
		} else {
			setUnavailableFeedback(mapUiTexts.lootBoxLocked);
		}
	};
	/**
	 *  Goes the side quest page, and keeps track of last area index
	 * @param {number} newAreaId 
	 */
	const handleGoToSidequestArea = (newAreaId) => {
		handleGoToPage('area-map', null, newAreaId);
		setLastAreaIndex(newAreaId);
	};

	/**
	 * Auto-scroll to bottom
	 */
	const scrollToBottom = () => {
		const element = document.querySelector('.AreaMap-mapWrap');
		if (element) {
			element.scrollTop = element.scrollHeight;
		}
	};

	/**
	 * Scrolls to the avatars position or to the bottom of the page if no elements is found.
	 * @param {number} newPosition 
	 */
	const scrollToAvatar = (newPosition) => {
		const element = document.querySelector('.AreaMap-mapWrap');
		const target = document.querySelector('#module-' + newPosition);
		if (target && element) {
			element.scrollTop = target.offsetTop;
		}  else {
			scrollToBottom();
		}
	};

	/**
	 * Component isSideQuest, scroll to avatar position
	*/
	useEffect(() => {
		if (areaData.isSideQuest) {
			/* scroll to avatar */
			scrollToAvatar(avatarPosition);

			/* Check manager notifications - get the first triggered one (if any) */
			let newNotification = null;
			if (areaData.managerNotifications) {
				areaData.managerNotifications.forEach((n) => {
					if (newNotification) return;
					const isTriggered = checkIfAreaNotificationIsTriggered(n, playerData);
					if (isTriggered) {
						newNotification = n;
					}
				});
			}
			if (newNotification) {
				setManagerNotification(newNotification);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [areaData.isSideQuest]);

	/**
	 * Component mounted
	 */
	useEffect(() => {
		/* Scroll to avatar / bottom */
		if (avatarPosition > 0) {
			/* Avatar is not on main island - scroll to avatar */
			scrollToAvatar(avatarPosition);
			
		} else {
			/* Avatar is on main island, scroll to bottom */
			scrollToBottom();
		}
		
		/* Check manager notifications - get the first triggered one (if any) */
		let newNotification = null;
		if (areaData.managerNotifications) {
			areaData.managerNotifications.forEach((n) => {
				if (newNotification) return;
				const isTriggered = checkIfAreaNotificationIsTriggered(n, playerData);
				if (isTriggered) {
					newNotification = n;
				}
			});
		}
		if (newNotification) {
			setManagerNotification(newNotification);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'AreaMap' + (deviceInfo ? ' ' + deviceInfo.orientation : '')}>
			<div className={'AreaMap-mapWrap' + (moveAvatar ? ' smoothScroll' : '')}>
				<div className={'AreaMap-mapContent modules-' + areaData.moduleIds.length}>
					<div className={'AreaMap-waves' + (areaData.isSideQuest ? ' sideQuest' : ' core')} />	
					{/* Modules & side quests */}
					{areaData.moduleIds.map((moduleId, moduleIndex) => {

						/* Module and player data */
						const moduleData = modulesData.find((m) => {return m.id === moduleId;});
						if (!moduleData) return null;
						
						/* Check if unlocked */
						const isUnlocked = (userData.role === 'admin'
							? true
							: checkIfModuleIsUnlocked(moduleData, playerData)
						);
						let bridgeStatus = (isUnlocked ? 'full' : 'none');

						/* Get number of filled stars and update bridge status */
						let filledStars = 0;
						if (isUnlocked || userData.role === 'admin') {	
							/* Number of stars (max if more than 1 session played) */
							filledStars = getMaxNumberOfStarsInModule(playerData, moduleId, null);
						} else {
							/* Get number of stars in required module */
							bridgeStatus = getModuleBridgeStatus(moduleData, playerData);
						}

						/* Get component for module status (number & stars) */
						let ModuleStatus = ModuleStatus0;
						if (filledStars === 1) ModuleStatus = ModuleStatus1;
						if (filledStars === 2) ModuleStatus = ModuleStatus2;
						if (filledStars === 3) ModuleStatus = ModuleStatus3;

						/* Check if it is the current module (avatar is standing on it / moving to it) */
						let isCurrentModule = false;
						if (
							(!moveAvatar && avatarPosition === moduleIndex + 1) ||
							(moveAvatar && moveAvatar.length > 0 && parseInt(moveAvatar.slice(-1)) === moduleIndex + 1)
						) {
							isCurrentModule = true;
						};

						return (
							<React.Fragment key={moduleData.id}>
								{/* Module */}
								<div 
									id = {'module-' + (moduleIndex + 1)}
									className={'AreaMap-module ' + moduleData.id 
										+ (areaData.isSideQuest ? ' isSideQuest' : '')
										+ (' position-' + (moduleIndex + 1))
										+ ' bridge-' + bridgeStatus
										+ (isUnlocked ? '' : ' locked')
										+ (moduleData.isUnderConstruction ? ' underConstruction' : '')
									}
								>
									{moduleData.isUnderConstruction && 
										<div className='AreaMap-constructionSign' />
									}
									<div className={'AreaMap-items ' + moduleData.id} />
									<div className={'AreaMap-moduleShadow module-' + (moduleIndex + 1)
										+ (isCurrentModule ? ' current' : '')} />
									<div 
										className={'AreaMap-moduleStatus module-' + (moduleIndex + 1) + 
											(isUnlocked ? ' stars-' + filledStars : ' locked') }
										onClick={() => {handleGoToModule(isUnlocked, moduleData.id, false);}}
									>
										
										<ModuleStatus />
										<div className="AreaMap-moduleLabel">
											<span>{moduleData.areaTitle ? '!' : moduleIndex + 1}</span>
											{moduleData.areaTitle &&
												<div className='AreaMap-moduleTitleWrapper'>
													<div className="AreaMap-moduleTitle">
														<span>{moduleData.areaTitle}</span>
													</div>
												</div>
											}
										</div>
									</div>
								</div>

								{/* Side quests */}
								{moduleData.sideQuests && moduleData.sideQuests.map((sq, sqIndex) => {
									/* Stones */
									const visibleStones = getSideQuestVisibleStones(sq, playerData);
									let StonesComponent = Stones1;
									let stoneType = 1;

									/* Unlock status */
									const sideQuestIsUnlocked = (userData.role === 'admin'
										? true
										: checkIfModuleIsUnlocked(sq, playerData)
									);

									/* Default module data */
									let sqModuleData = null;
									let sqAreaData = null;
									let sideQuestFilledStars = 0;

									/* Default loot box data */
									let lootBoxIsEmpty = false;
									
									if (sq.type === 'module') {
										/* Type: module */

										/* Get module data, overwrite unlock conditions */
										sqModuleData = modulesData.find((m) => {return m.id === sq.moduleId;});
										sqModuleData.unlockConditions = sq.unlockConditions;

										/* Check if unlocked */
										if (sideQuestIsUnlocked) {	
											/* Number of stars (max if more than 1 session played) */
											filledStars = getMaxNumberOfStarsInModule(playerData, moduleId, null);
										}
									}
									
									if (sq.type === 'area') {
										/* Type: area */

										/* Get area data, overwrite unlock conditions */
										sqAreaData = areasData.find((m) => {return m.id === sq.areaId;});
										sqAreaData.unlockConditions = sq.unlockConditions;

										/* Check if unlocked */
										if (sideQuestIsUnlocked) {	
										}
									}
									if (sq.type === 'loot-box') {
										/* Type: loot-box, 5 stones */
										StonesComponent = Stones2;
										stoneType = 2;
										if (areaData.isSideQuest) {
											StonesComponent = Stones3;
											stoneType = 3;
										}

										/* Check if loot box is empty */
										if (
											playerData.items && 
											playerData.items.some((i) => {
												return (i.sourceType === 'loot-box' && i.sourceId === sq.lootBoxId);
											})
										) {
											lootBoxIsEmpty = true;
										}
									}
										
									return (
										<div 
											key={sqIndex}
											className={'AreaMap-sideQuest ' + sq.type
													+ (' position-' + (moduleIndex + 1) + '-' + (sqIndex + 1))
													+ (sideQuestIsUnlocked ? ' unlocked' : ' locked')
													+ (lootBoxIsEmpty ? ' empty' : '')
											}
										>
											{sq.type === 'module' && <div 
												className={'AreaMap-sideQuestStatus' + 
													(sideQuestIsUnlocked 
														? ' stars-' + sideQuestFilledStars : ' locked') }
												onClick={() => {
													handleGoToModule(sideQuestIsUnlocked, sq.moduleId, true);
												}}
											>
												<ModuleStatus />
												<div className="AreaMap-sideQuestLabel">
													<span>{sqModuleData.title}</span>
												</div>
											</div>}
											{sq.type === 'area' &&
												<div className={'AreaMap-sideQuestStatus area'}
													onClick={() => {
														handleGoToSidequestArea(sq.areaId);
													}}
												>
													<ModuleStatusNoStars />
													<div className="AreaMap-sideQuestLabel">
														<span>{sqAreaData.title}</span>
													</div>
												</div>
											}
											{sq.type === 'loot-box' && <div 
												className={'AreaMap-lootBoxBtn' 
													+ (sideQuestIsUnlocked ? '' : ' locked')}
												onClick={() => {
													handleGetLootBox(sideQuestIsUnlocked, sq.lootBoxId);
												}}
											/>}
											<div className={'AreaMap-sideQuestStones stones-' 
												+ visibleStones + ' type-' + stoneType}>
												<StonesComponent />
											</div>
										</div>
									);
								})}
							</React.Fragment>
						);
					})}

					{/* Main area island */}
					{!areaData.isSideQuest &&
						<div className={'AreaMap-mainIsland ' + areaId}>
							<Area 
								type="main-island"
								areaData={areaData}
								playerData={playerData}
							/>
						</div>
					}

					{/* Avatar */}
					<div className={'AreaMap-avatar position-' + avatarPosition + 
						(areaData.isSideQuest ? ' isSidequest' : '') + 
						(moveAvatar ? ' ' + moveAvatar : '')}>
						<Avatar 
							type={'map-full-body'}
							playerData={playerData}
						/>

					</div>
				</div>
			</div>

			{/* Manager notification */}
			{managerNotification && 
				<NotificationManager 
					notificationData={managerNotification} 
					gameData={gameData}
					handleCloseNotification={handleCloseNotification}
				/>
			}

			{/* Top navigation */}
			<NavigationTop 
				type="map"
				deviceInfo={deviceInfo}
				gameData={gameData}
				playerData={playerData}
				handleGoToPage={handleGoToPage}
			/>

			{/* Notification */}
			<NotificationsGame 
				type="map"
				deviceInfo={deviceInfo}
				gameData={gameData}
				playerData={playerData}
				handleCloseNotification={handleCloseNotification}
				updatePlayerGameData={updatePlayerGameData}
				handleGoToPage={handleGoToPage}
			/>

			{/* Loot box popup */}
			{lootBoxId && <PopupLootBox 
				lootBoxId={lootBoxId}
				playerData={playerData}
				reserveLootBoxOptions={reserveLootBoxOptions}
				getLootBoxItem={getLootBoxItem}
				setLootBoxId={setLootBoxId}
				deviceOrientation = {(deviceInfo ? ' ' + deviceInfo.orientation : '')}
			/>}

			{/* Side quest unavailable popup */}
			{unavailableFeedback && 
				<div className="AreaMap-unavailableFeedback" onClick={() => {setUnavailableFeedback(null);}}>
					<div className="AreaMap-unavailableFeedbackContent" onClick={(e) => {e.stopPropagation();}}>
						<div className="AreaMap-unavailableFeedbackText">
							<span>{unavailableFeedback}</span>
						</div>
						<div 
							className="AreaMap-unavailableFeedbackBtn"
							onClick={() => {setUnavailableFeedback(null);}}
						>
							<span>{mapUiTexts.ok}</span>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

AreaMap.propTypes = {
	areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	reserveLootBoxOptions: PropTypes.func.isRequired,
	getLootBoxItem: PropTypes.func.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	setLastAreaIndex: PropTypes.func.isRequired,
};

export default AreaMap;
