const avatarData = {
	categories: [
		{
			id: 'body-type',
			label: 'Body type',
			imageFolder: 'body',
			isAutoGenerated: true,
			affectedCategories: ['clothes', 'work-clothes'],
			optionRows: 1,
			options: [
				{
					id: 'f', 
					image: 'body-f.svg', 
					isDefault: true,
					svgIds: ['Pants_01-2'],
				},
				{
					id: 'f2', 
					image: 'body-f2.svg',
					svgIds: ['Pants_01-2'],
				},				
				{
					id: 'm', 
					image: 'body-m.svg',
					svgIds: ['Pants_01'],
				},
				{
					id: 'm2', 
					image: 'body-m2.svg',
					svgIds: ['Pants_01'],
				}
			]
		},
		{
			id: 'skin-color',
			label: 'Skin color',
			imageFolder: 'body',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 1, row: 1, isDefault: true},
				{id: 2, row: 1},
				{id: 3, row: 1},
				{id: 4, row: 1},
				{id: 5, row: 1},
				{id: 6, row: 2},
				{id: 7, row: 2},
				{id: 8, row: 2},
				{id: 9, row: 2},
				{id: 10, row: 2},
			],
		},
		{
			id: 'eyes',
			label: 'Eyes',
			imageFolder: 'eyes',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'f-1', image: 'eyes-f-1.svg', row: 1, svgIds: ['Eyes_01-2'], isDefault: true},
				{id: 'f-2', image: 'eyes-f-2.svg', row: 1, svgIds: ['Eyes_02-2']},
				{id: 'f-3', image: 'eyes-f-3.svg', row: 1, svgIds: ['Eyes_03-2']},
				{id: 'f-4', image: 'eyes-f-4.svg', row: 1, svgIds: ['Eyes_04-2']},
				{id: 'f-5', image: 'eyes-f-5.svg', row: 1, svgIds: ['Eyes_05-2']},
				{id: 'f-6', image: 'eyes-f-6.svg', row: 1, svgIds: ['Eyes_06-2']},
				{id: 'f-7', image: 'eyes-f-7.svg', row: 1, svgIds: ['Eyes_07-2']},
				{id: 'f-8', image: 'eyes-f-8.svg', row: 1, svgIds: ['Eyes_08-2']},
				{id: 'f-9', image: 'eyes-f-9.svg', row: 1, svgIds: ['Eyes_09-2']},
				{id: 'f-10', image: 'eyes-f-10.svg', row: 1, svgIds: ['Eyes_10-2']},
				{id: 'm-1', image: 'eyes-m-1.svg', row: 2, svgIds: ['Eyes_01']},
				{id: 'm-2', image: 'eyes-m-2.svg', row: 2, svgIds: ['Eyes_02']},
				{id: 'm-3', image: 'eyes-m-3.svg', row: 2, svgIds: ['Eyes_03']},
				{id: 'm-4', image: 'eyes-m-4.svg', row: 2, svgIds: ['Eyes_04']},
				{id: 'm-5', image: 'eyes-m-5.svg', row: 2, svgIds: ['Eyes_05']},
				{id: 'm-6', image: 'eyes-m-6.svg', row: 2, svgIds: ['Eyes_06']},
				{id: 'm-7', image: 'eyes-m-7.svg', row: 2, svgIds: ['Eyes_07']},
				{id: 'm-8', image: 'eyes-m-8.svg', row: 2, svgIds: ['Eyes_08']},
				{id: 'm-9', image: 'eyes-m-9.svg', row: 2, svgIds: ['Eyes_09']},
				{id: 'm-10', image: 'eyes-m-10.svg', row: 2, svgIds: ['Eyes_10']},
			],
		},
		{
			id: 'nose',
			label: 'Nose',
			imageFolder: 'nose',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'f-1', row: 1, svgIds: ['Nose_01-2'], isDefault: true},
				{id: 'f-2', row: 1, svgIds: ['Nose_02-2']},
				{id: 'f-3', row: 1, svgIds: ['Nose_03-2']},
				{id: 'f-4', row: 1, svgIds: ['Nose_04-2']},
				{id: 'f-5', row: 1, svgIds: ['Nose_05-2']},
				{id: 'f-6', row: 1, svgIds: ['Nose_06-2']},
				{id: 'm-1', row: 2, svgIds: ['Nose_01']},
				{id: 'm-2', row: 2, svgIds: ['Nose_02']},
				{id: 'm-3', row: 2, svgIds: ['Nose_03']},
				{id: 'm-4', row: 2, svgIds: ['Nose_04']},
				{id: 'm-5', row: 2, svgIds: ['Nose_05']},
				{id: 'm-6', row: 2, svgIds: ['Nose_06']},
			],
		},
		{
			id: 'mouth',
			label: 'Mouth',
			imageFolder: 'mouth',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'f-1a', image: 'mouth-f-1a.svg', row: 1, svgIds: ['Mouth_01a-2'], isDefault: true},
				{id: 'f-1b', image: 'mouth-f-1b.svg', row: 2, svgIds: ['Mouth_01b-2']},
				{id: 'f-2a', image: 'mouth-f-2a.svg', row: 2, svgIds: ['Mouth_02a-2']},
				{id: 'f-2b', image: 'mouth-f-2b.svg', row: 1, svgIds: ['Mouth_02b-2']},
				{id: 'f-3a', image: 'mouth-f-3a.svg', row: 1, svgIds: ['Mouth_03a-2']},
				{id: 'f-3b', image: 'mouth-f-3b.svg', row: 2, svgIds: ['Mouth_03b-2']},
				{id: 'f-4a', image: 'mouth-f-4a.svg', row: 1, svgIds: ['Mouth_04a-2']},
				{id: 'f-4b', image: 'mouth-f-4b.svg', row: 2, svgIds: ['Mouth_04b-2']},
				{id: 'f-5a', image: 'mouth-f-5a.svg', row: 1, svgIds: ['Mouth_05a-2']},
				{id: 'f-5b', image: 'mouth-f-5b.svg', row: 2, svgIds: ['Mouth_05b-2']},
				{id: 'm-1a', image: 'mouth-m-1a.svg', row: 1, svgIds: ['Mouth_01a']},
				{id: 'm-1b', image: 'mouth-m-1b.svg', row: 2, svgIds: ['Mouth_01b']},
				{id: 'm-2a', image: 'mouth-m-2a.svg', row: 1, svgIds: ['Mouth_02a']},
				{id: 'm-2b', image: 'mouth-m-2b.svg', row: 2, svgIds: ['Mouth_02b']},
				{id: 'm-3a', image: 'mouth-m-3a.svg', row: 1, svgIds: ['Mouth_03a']},
				{id: 'm-3b', image: 'mouth-m-3b.svg', row: 2, svgIds: ['Mouth_03b']},
				{id: 'm-4a', image: 'mouth-m-4a.svg', row: 1, svgIds: ['Mouth_04a']},
				{id: 'm-4b', image: 'mouth-m-4b.svg', row: 2, svgIds: ['Mouth_04b']},
				{id: 'm-5a', image: 'mouth-m-5a.svg', row: 1, svgIds: ['Mouth_05a']},
				{id: 'm-5b', image: 'mouth-m-5b.svg', row: 2, svgIds: ['Mouth_05b']},
			],
		},
		{
			id: 'hair',
			label: 'Hair',
			imageFolder: 'hair',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'none', row: 1},
				{id: 'f-1', row: 1, svgIds: ['Hair_01-2', 'Hair_Back_01'], isDefault: true},
				{id: 'f-2', row: 1, svgIds: ['Hair_02-2', 'Hair_back_02']},
				{id: 'f-3', row: 1, svgIds: ['Hair_03-2', 'Hair_Back_03']},
				{id: 'f-4', row: 1, svgIds: ['Hair_04-2', 'Hair_Back_04']},
				{id: 'f-5', row: 1, svgIds: ['Hair_05-2']},
				{id: 'f-6', row: 1, svgIds: ['Hair_06-2', 'Hair_Back_06']},
				{id: 'f-7', row: 1, svgIds: ['Hair_07-2', 'Hair_Back_07']},
				{id: 'f-8', row: 1, svgIds: ['Hair_08-2', 'Hair_Back_08']},
				{id: 'f-9', row: 1, svgIds: ['Hair_09-2', 'Hair_Back_09']},
				{id: 'm-1', row: 2, svgIds: ['Hair_01']},
				{id: 'm-2', row: 2, svgIds: ['Hair_02']},
				{id: 'm-3', row: 2, svgIds: ['Hair_03']},
				{id: 'm-4', row: 2, svgIds: ['Hair_04']},
				{id: 'm-5', row: 2, svgIds: ['Hair_05']},
				{id: 'm-6', row: 2, svgIds: ['Hair_06']},
				{id: 'm-7', row: 2, svgIds: ['Hair_07']},
				{id: 'm-8', row: 2, svgIds: ['Hair_08']},
				{id: 'm-9', row: 2, svgIds: ['Hair_09']},
			],
		},
		{
			id: 'beard',
			label: 'Beard',
			imageFolder: 'beard',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 1, image: 'beard-1.svg', row: 1, svgIds: ['Beard_01']},
				{id: 2, image: 'beard-2.svg', row: 1, svgIds: ['Beard_02']},
				{id: 3, image: 'beard-3.svg', row: 1, svgIds: ['Beard_03']},
				{id: 4, image: 'beard-4.svg', row: 1, svgIds: ['Beard_04']},
				{id: 5, image: 'beard-5.svg', row: 1, svgIds: ['Beard_05']},
				{id: 6, image: 'beard-6.svg', row: 2, svgIds: ['Beard_06']},
				{id: 7, image: 'beard-7.svg', row: 2, svgIds: ['Beard_07']},
				{id: 8, image: 'beard-8.svg', row: 2, svgIds: ['Beard_08']},
				{id: 9, image: 'beard-9.svg', row: 2, svgIds: ['Beard_09']},
				{id: 10, image: 'beard-10.svg', row: 2, svgIds: ['Beard_10']},
				{id: 11, image: 'beard-11.svg', row: 2, svgIds: ['Beard_11'], isLocked: true},
			]
		},
		{
			id: 'glasses',
			label: 'Glasses',
			imageFolder: 'glasses',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 1, image: 'glasses-1.svg', row: 1, svgIds: ['Glasses_01']},
				{id: 2, image: 'glasses-2.svg', row: 1, svgIds: ['Glasses_02']},
				{id: 3, image: 'glasses-3.svg', row: 1, svgIds: ['Glasses_03']},
				{id: 4, image: 'glasses-4.svg', row: 1, svgIds: ['Glasses_04']},
				{id: 5, image: 'glasses-5.svg', row: 1, svgIds: ['Glasses_05']},
				{id: 6, image: 'glasses-6.svg', row: 2, svgIds: ['Glasses_06']},
				{id: 7, image: 'glasses-7.svg', row: 2, svgIds: ['Glasses_07']},
				{id: 8, image: 'glasses-8.svg', row: 2, svgIds: ['Glasses_08']},
				{id: 9, image: 'glasses-9.svg', row: 2, svgIds: ['Glasses_09']},
				{id: 10, image: 'glasses-10.svg', row: 2, svgIds: ['Glasses_10']},	
			]
		},
		{
			id: 'clothes',
			label: 'Clothes',
			imageFolder: 'clothes',
			isAutoGenerated: true,
			optionRows: 2,
			options: [
				{id: 'shirt-f-1', similarIds: ['shirt-m-1'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-1.svg', row: 1, svgIds: ['Tshirt_01-2'], isDefault: true},
				{id: 'shirt-f-2', similarIds: ['shirt-m-2'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-2.svg', row: 1, svgIds: ['Tshirt_02-2']},
				{id: 'shirt-f-3', similarIds: ['shirt-m-3'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-3.svg', row: 1, svgIds: ['Tshirt_03-2']},
				{id: 'shirt-f-4', similarIds: ['shirt-m-4'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-4.svg', row: 1, svgIds: ['Tshirt_04-2']},
				{id: 'shirt-f-5', similarIds: ['shirt-m-5'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-5.svg', row: 1, svgIds: ['Tshirt_05-2']},
				{id: 'shirt-f-6', similarIds: ['shirt-m-6'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-6.svg', row: 1, svgIds: ['Tshirt_06-2']},
				{id: 'shirt-f-7', similarIds: ['shirt-m-7'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-7.svg', row: 1, svgIds: ['Tshirt_07-2']},
				{id: 'shirt-f-8', similarIds: ['shirt-m-8'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-8.svg', row: 1, svgIds: ['Tshirt_08-2']},
				{id: 'shirt-f-9', similarIds: ['shirt-m-9'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-9.svg', row: 1, svgIds: ['Tshirt_09-2']},
				{id: 'shirt-f-10', similarIds: ['shirt-m-10'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-10.svg', row: 1, svgIds: ['Tshirt_10-2']},
				{id: 'shirt-f-11', similarIds: ['shirt-m-11'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-11.svg', row: 1, svgIds: ['Tshirt_11-2']},
				{id: 'shirt-f-12', similarIds: ['shirt-m-12'], bodyTypeIds: ['f', 'f2'], image: 'shirt-f-12.svg', row: 1, svgIds: ['Tshirt_12-2']},
				{id: 'longshirt-f-1', similarIds: ['longshirt-m-1'], bodyTypeIds: ['f', 'f2'], image: 'longshirt-f-1.svg', row: 2, svgIds: ['Shirt_01-2']},
				{id: 'longshirt-f-2', similarIds: ['longshirt-m-2'], bodyTypeIds: ['f', 'f2'], image: 'longshirt-f-2.svg', row: 2, svgIds: ['Shirt_02-2']},
				{id: 'sweatshirt-f-1', similarIds: ['sweatshirt-m-1'], bodyTypeIds: ['f', 'f2'], image: 'sweatshirt-f-1.svg', row: 2, svgIds: ['Sweatshirt_01-2']},
				{id: 'sweatshirt-f-2', similarIds: ['sweatshirt-m-2'], bodyTypeIds: ['f', 'f2'], image: 'sweatshirt-f-2.svg', row: 2, svgIds: ['Sweatshirt_02-2']},
				{id: 'jacket-f-1', similarIds: ['jacket-m-1'], bodyTypeIds: ['f', 'f2'], image: 'jacket-f-1.svg', row: 2, svgIds: ['Jacket_01-2']},
				{id: 'jacket-f-2', similarIds: ['jacket-m-2'], bodyTypeIds: ['f', 'f2'], image: 'jacket-f-2.svg', row: 2, svgIds: ['Jacket_02-2']},
				{id: 'leather-jacket-f', similarIds: ['leather-jacket-m'], bodyTypeIds: ['f', 'f2'], image: 'leather-jacket.svg', row: 2, svgIds: ['Leather-jacket_F'], isLocked: true},
				{id: 'shirt-f-13', similarIds: ['shirt-m-13'], bodyTypeIds: ['f', 'f2'], image: 'shirt-m-13.svg', row: 2, svgIds: ['Tshirt_13-2'], isLocked: true},
				{id: 'shirt-tropical-f', similarIds: ['shirt-tropical-m'], bodyTypeIds: ['f', 'f2'], image: 'shirt-tropical.svg', row: 2, svgIds: ['Shirt_Tropical_F'], isLocked: true},
				{id: 'shirt-f-cowprint', similarIds: ['shirt-m-cowprint'], bodyTypeIds: ['f', 'f2'], image: 'shirt-m-cowprint.svg', row: 2, svgIds: ['Shirt_Cowprint_F'], isLocked: true},
				{id: 'shirt-f-milk-sandwich', similarIds: ['shirt-m-milk-sandwich'], bodyTypeIds: ['f', 'f2'], image: 'shirt-m-milk-sandwich.svg', row: 2, svgIds: ['Shirt_F_Milk_Sandwich'], isLocked: true},
				
				{id: 'shirt-m-1', similarIds: ['shirt-f-1'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-1.svg', row: 1, svgIds: ['Tshirt_01'], isDefault: true},
				{id: 'shirt-m-2', similarIds: ['shirt-f-2'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-2.svg', row: 1, svgIds: ['Tshirt_02']},
				{id: 'shirt-m-3', similarIds: ['shirt-f-3'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-3.svg', row: 1, svgIds: ['Tshirt_03']},
				{id: 'shirt-m-4', similarIds: ['shirt-f-4'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-4.svg', row: 1, svgIds: ['Tshirt_04']},
				{id: 'shirt-m-5', similarIds: ['shirt-f-5'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-5.svg', row: 1, svgIds: ['Tshirt_05']},
				{id: 'shirt-m-6', similarIds: ['shirt-f-6'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-6.svg', row: 1, svgIds: ['Tshirt_06']},
				{id: 'shirt-m-7', similarIds: ['shirt-f-7'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-7.svg', row: 1, svgIds: ['Tshirt_07']},
				{id: 'shirt-m-8', similarIds: ['shirt-f-8'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-8.svg', row: 1, svgIds: ['Tshirt_08']},
				{id: 'shirt-m-9', similarIds: ['shirt-f-9'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-9.svg', row: 1, svgIds: ['Tshirt_09']},
				{id: 'shirt-m-10', similarIds: ['shirt-f-10'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-10.svg', row: 1, svgIds: ['Tshirt_10']},
				{id: 'shirt-m-11', similarIds: ['shirt-f-11'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-11.svg', row: 1, svgIds: ['Tshirt_11']},
				{id: 'shirt-m-12', similarIds: ['shirt-f-12'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-12.svg', row: 1, svgIds: ['Tshirt_12']},
				{id: 'longshirt-m-1', similarIds: ['longshirt-f-1'], bodyTypeIds: ['m', 'm2'], image: 'longshirt-m-1.svg', row: 2, svgIds: ['Shirt_01']},
				{id: 'longshirt-m-2', similarIds: ['longshirt-f-2'], bodyTypeIds: ['m', 'm2'], image: 'longshirt-m-2.svg', row: 2, svgIds: ['Shirt_02']},
				{id: 'sweatshirt-m-1', similarIds: ['sweatshirt-f-1'], bodyTypeIds: ['m', 'm2'], image: 'sweatshirt-m-1.svg', row: 2, svgIds: ['Sweatshirt_01']},
				{id: 'sweatshirt-m-2', similarIds: ['sweatshirt-f-2'], bodyTypeIds: ['m', 'm2'], image: 'sweatshirt-m-2.svg', row: 2, svgIds: ['Sweatshirt_02']},
				{id: 'jacket-m-1', similarIds: ['jacket-f-1'], bodyTypeIds: ['m', 'm2'], image: 'jacket-m-1.svg', row: 2, svgIds: ['Jacket_01']},
				{id: 'jacket-m-2', similarIds: ['jacket-f-2'], bodyTypeIds: ['m', 'm2'], image: 'jacket-m-2.svg', row: 2, svgIds: ['Jacket_02']},
				{id: 'leather-jacket-m', similarIds: ['leather-jacket-f'], bodyTypeIds: ['m', 'm2'], image: 'leather-jacket.svg', row: 2, svgIds: ['Leather-jacket_M'], isLocked: true},
				{id: 'shirt-m-13', similarIds: ['shirt-f-13'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-13.svg', row: 2, svgIds: ['Tshirt_13'], isLocked: true},
				{id: 'shirt-tropical-m', similarIds: ['shirt-tropical-f'], bodyTypeIds: ['m', 'm2'], image: 'shirt-tropical.svg', row: 2, svgIds: ['Shirt_Tropical_M'], isLocked: true},
				{id: 'shirt-m-cowprint', similarIds: ['shirt-f-cowprint'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-cowprint.svg', row: 2, svgIds: ['Shirt_Cowprint_M'], isLocked: true},
				{id: 'shirt-m-milk-sandwich', similarIds: ['shirt-f-milk-sandwich'], bodyTypeIds: ['m', 'm2'], image: 'shirt-m-milk-sandwich.svg', row: 2, svgIds: ['Shirt_M_Milk_Sandwich'], isLocked: true},
			]
		},
		{
			id: 'hats',
			label: 'Hats',
			imageFolder: 'hats',
			isAutoGenerated: false,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'fun-hat', image: 'fun-hat.svg', row: 1, svgIds: ['Hat_08']},
				{id: 'beret', image: 'beret.svg', row: 1, svgIds: ['Hat_03']},
				{id: 'straw-hat', image: 'straw-hat.svg', row: 1, svgIds: ['Hat_12']},
				{id: 'trapper', image: 'trapper.svg', row: 1, svgIds: ['Hat_04']},
				{id: 'hairnet', image: 'hairnet.svg', row: 1, svgIds: ['Hat_20'], isLocked: true},
				{id: 'chefs-hat', image: 'chefs-hat.svg', row: 1, svgIds: ['Hat_21'], isLocked: true},
				{id: 'banana-headdress', image: 'banana-headdress.svg', row: 2, svgIds: ['Hat_17'], isLocked: true},
				{id: 'bunny-ears', image: 'bunny-ears.svg', row: 2, svgIds: ['Hat_18'], isLocked: true},
				{id: 'smelly-cheese-crown', image: 'smelly-cheese-crown.svg', row: 2, svgIds: ['Hat_22'], isLocked: true},
				{id: 'watermelon-helmet', image: 'watermelon-helmet.svg', row: 2, svgIds: ['Hat_23'], isLocked: true},
				{id: 'shark-hat', image: 'shark-hat.svg', row: 2, svgIds: ['Hat_24'], isLocked: true},
				{id: 'giant-log', image: 'giant-log.svg', row: 2, svgIds: ['Hat_25'], isLocked: true},
				{id: 'cupcake-hat', image: 'cupcake-hat.svg', row: 2, svgIds: ['Hat_26'], isLocked: true},
				{id: 'flower-pot', image: 'flower-pot.svg', row: 2, svgIds: ['Hat_27'], isLocked: true},
				{id: 'lobster-hat', image: 'lobster-hat.svg', row: 2, svgIds: ['Hat_28'], isLocked: true},
				{id: 'donut-hat', image: 'donut-hat.svg', row: 2, svgIds: ['Hat_29'], isLocked: true},
				{id: 'karate-headband', image: 'karate-headband.svg', row: 2, svgIds: ['Hat_30'], isLocked: true},
				{id: 'mop-hat', image: 'mop-hat.svg', row: 2, svgIds: ['Hat_31'], isLocked: true},
				{id: 'bucket-hat', image: 'bucket-hat.svg', row: 2, svgIds: ['Hat_32'], isLocked: true},
				{id: 'wet-floor-sign-hat', image: 'wet-floor-sign-hat.svg', row: 2, svgIds: ['Hat_33'], isLocked: true},
			],
		},
		{
			id: 'work-clothes',
			label: 'Work clothes',
			imageFolder: 'work-clothes',
			isAutoGenerated: false,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'apron-f-1', similarIds: ['apron-m-1'], bodyTypeIds: ['f', 'f2'], image: 'apron-f-1.svg', row: 1, svgIds: ['Apron_01-2'], isLocked: true},
				{id: 'apron-f-2', similarIds: ['apron-m-2'], bodyTypeIds: ['f', 'f2'], image: 'apron-f-2.svg', row: 1, svgIds: ['Apron_02-2'], isLocked: true},
				{id: 'apron-f-3', similarIds: ['apron-m-3'], bodyTypeIds: ['f', 'f2'], image: 'apron-f-3.svg', row: 1, svgIds: ['Apron_03-2'], isLocked: true},
				{id: 'apron-m-1', similarIds: ['apron-f-1'], bodyTypeIds: ['m', 'm2'], image: 'apron-m-1.svg', row: 1, svgIds: ['Apron_01'], isLocked: true},
				{id: 'apron-m-2', similarIds: ['apron-f-2'], bodyTypeIds: ['m', 'm2'], image: 'apron-m-2.svg', row: 1, svgIds: ['Apron_02'], isLocked: true},
				{id: 'apron-m-3', similarIds: ['apron-f-3'], bodyTypeIds: ['m', 'm2'], image: 'apron-m-3.svg', row: 1, svgIds: ['Apron_03'], isLocked: true},
				{id: 'lifejacket-m', similarIds: ['lifejacket-f'], bodyTypeIds: ['m', 'm2'], image: 'lifejacket.svg', row: 2, svgIds: ['Lifejacket_M'], isLocked: true},
				{id: 'lifejacket-f', similarIds: ['lifejacket-m'], bodyTypeIds: ['f', 'f2'], image: 'lifejacket.svg', row: 2, svgIds: ['Lifejacket_F'], isLocked: true},
				{id: 'sandwich-costume-m', similarIds: ['sandwich-costume-f'], bodyTypeIds: ['m', 'm2'], image: 'sandwich-costume.svg', row: 2, svgIds: ['Sandwich_Costume_M'], isLocked: true},
				{id: 'sandwich-costume-f', similarIds: ['sandwich-costume-m'], bodyTypeIds: ['f', 'f2'], image: 'sandwich-costume.svg', row: 2, svgIds: ['Sandwich_Costume_F'], isLocked: true},
				{id: 'penguin-suit-m', similarIds: ['penguin-suit-f'], bodyTypeIds: ['m', 'm2'], image: 'penguin-suit.svg', row: 2, svgIds: ['Penguin_Suit_M'], isLocked: true},
				{id: 'penguin-suit-f', similarIds: ['penguin-suit-m'], bodyTypeIds: ['f', 'f2'], image: 'penguin-suit.svg', row: 2, svgIds: ['Penguin_Suit_F'], isLocked: true}
			],
			noAvailableOptionsText: `Gain new work clothes for your avatar by playing the game.`
		},
		{
			id: 'accessories',
			label: 'Accessories',
			imageFolder: 'accessories',
			isAutoGenerated: false,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'name-tag', image: 'name-tag.svg', row: 1, svgIds: ['Name_Tag'], isLocked: true},
				{id: 'four-leaf-clover', image: 'four-leaf-clover.svg', row: 1, svgIds: ['Clover'], isLocked: true},
				{id: 'green-thumb-badge', image: 'green-thumb-badge.svg', row: 1, svgIds: ['Green_Thumb_badge'], isLocked: true},
				{id: 'lobster-bib', image: 'lobster-bib.svg', row: 1, svgIds: ['Lobster_Bib'], isLocked: true},
				{id: 'cloud-stink', image: 'cloud-stink.svg', row: 2, svgIds: ['Cloud_Stink'], isLocked: true},
				{id: 'chicken-wings', image: 'chicken-wings.svg', row: 2, svgIds: ['Chicken_Wings'], isLocked: true}
				
			],
			noAvailableOptionsText: `Gain new accessories for your avatar by playing the game.`
		},
		{
			id: 'items',
			label: 'Items',
			imageFolder: 'items',
			isAutoGenerated: false,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'lightsaber-broom', image: 'lightsaber-broom.svg', svgIds: ['Lightsaber_Broom_'], row: 1, isInHand: true, isLocked: true},
				{id: 'wand-toy', image: 'wand-toy.svg', svgIds: ['Wand_Toy_'], row: 1, isInHand: true, isLocked: true},
				{id: 'balloon-yay-me', image: 'balloon-yay-me.svg', svgIds: ['Balloon_'], row: 2, isInHand: true, isLocked: true}
			],
			noAvailableOptionsText: `Gain new items for your avatar by playing the game.`
		},
		{
			id: 'animals',
			label: 'Animals',
			imageFolder: 'animals',
			isAutoGenerated: false,
			optionRows: 2,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'lizard', image: 'lizard.svg', svgIds: ['Lizard_'], isInHand: true, row: 1, isLocked: true},
				{id: 'bunny', image: 'bunny.svg', svgIds: ['Bunny_'], isInHand: true, row: 1, isLocked: true},
				{id: 'cat', image: 'cat.svg', row: 1, isLocked: true},
				{id: 'dog', image: 'dog.svg', row: 1, isLocked: true},
				{id: 'fish', image: 'fish.svg', svgIds: ['Fish_'], isInHand: true, row: 1, isLocked: true},
				{id: 'jarzard', image: 'jarzard.svg', svgIds: ['Jarzard_'], isInHand: true, row: 1, isLocked: true},
				{id: 'parrot', image: 'parrot.svg', svgIds: ['Parrot_'], isInHand: true, row: 1, isLocked: true},
				{id: 'plank-of-wood', image: 'plank-of-wood.svg', svgIds: ['Plank_'], isInHand: true, row: 1, isLocked: true},
				{id: 'toad', image: 'toad.svg', svgIds: ['Toad_'], isInHand: true, row: 2, isLocked: true},
				{id: 'turtle', image: 'turtle.svg', svgIds: ['Turtle_'], isInHand: true, row: 2, isLocked: true},
				{id: 'disco-chicken', image: 'disco-chicken.svg', svgIds: ['Disco_Chicken_'], isInHand: true, row: 2, isLocked: true},
				{id: 'canary', image: 'canary.svg', svgIds: ['Canary_'], isInHand: true, row: 2, isLocked: true},
				{id: 'hamster', image: 'hamster.svg', svgIds: ['Hamster_'], isInHand: true, row: 2, isLocked: true},
				{id: 'hedgehog', image: 'hedgehog.svg', svgIds: ['Hedgehog_'], isInHand: true, row: 2, isLocked: true},
				{id: 'snake', image: 'snake.svg', svgIds: ['Snake_'], isInHand: true, row: 2, isLocked: true}
			],
			noAvailableOptionsText: `Earn new animal companions for your avatar by playing the game.`
		},
		{
			id: 'decorations',
			label: 'Decorations',
			imageFolder: 'decorations',
			isAutoGenerated: false,
			optionRows: 1,
			options: [
				{id: 'none', row: 1, isDefault: true},
				{id: 'mounted-singing-fish', image: 'mounted-singing-fish.svg', row: 1, isLocked: true},
			]
		}
	]
};

export {
	avatarData,
};