import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {getNumberOfCompletedModulesInAnArea, checkIfAllModulesInAreaAreCompleted} from 'helpers/module-helper';
import {badgesData} from 'data/badges-data';
import {modulesData} from 'data/modules/modules-data';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';

/**
 * Get badge completion level
 * NOTE: This does not work for competition modules
 * @param {object} badgeData 
 * @param {object} player 
 * @returns 
 */
export function getBadgeCompletionLevel(badgeData, player) {
	/* TYPE: complete-area */
	if (badgeData.type === 'complete-area') {
		/* Get all modules in area area */
		const modulesInArea = modulesData.filter((m) => {return m.areaId === badgeData.areaId;});
		
		/* Count number of completed modules in area */
		const playerModulesData = (player && player.modules ? player.modules : []);
		let numberOfCompletedModulesInArea = getNumberOfCompletedModulesInAnArea(badgeData.areaId, playerModulesData);

		return {value: numberOfCompletedModulesInArea, required: modulesInArea.length};
	}

	/* TYPE: stars-in-modules */
	if (badgeData.type === 'stars-in-modules') {
		let numberOfStars = 0;
		if (player && player.modules) {
			badgeData.moduleIds.forEach((moduleIds) => {
				const playerModuleData = player.modules.find((m) => {return m.moduleId === moduleIds;});
				if (playerModuleData && playerModuleData.maxStars) {
					numberOfStars += playerModuleData.maxStars;
				}
			});
		}
		return {value: numberOfStars, required: badgeData.stars};		
	}
	
	/* TYPE: max-stars-in-area */
	if (badgeData.type === 'max-stars-in-area') {
		/* Get all modules in area area */
		const modulesInArea = modulesData.filter((m) => {return m.areaId === badgeData.areaId;});

		/* Count number of stars */
		let numberOfStars = 0;
		if (player && player.modules) {
			modulesInArea.forEach((moduleData) => {
				const playerModuleData = player.modules.find((m) => {return m.moduleId === moduleData.id;});
				if (playerModuleData && playerModuleData.maxStars) {
					numberOfStars += playerModuleData.maxStars;
				}
			});
		}
		return {value: numberOfStars, required: appConfig.maxStarsPerModule * modulesInArea.length};		
	}

	/* TYPE: total-number-of-stars */
	if (badgeData.type === 'total-number-of-stars') {
		let numberOfStars = 0;
		if (player && player.modules) {
			player.modules.forEach((playerModule) => {
				if (playerModule.maxStars) numberOfStars += playerModule.maxStars;
			});
		}
		return {value: numberOfStars, required: badgeData.numberOfStars};
	}

	/* TYPE: special-points */
	if (badgeData.type === 'special-points') {
		if (badgeData.numberOfPoints < 2) return null;
		
		let numberOfSpecialPoints = 0;
		if (player && player.specialPoints && player.specialPoints.length > 0) {
			player.specialPoints.filter((sp) => {return sp.pointId === badgeData.pointId;}).forEach((sp) => {
				numberOfSpecialPoints += sp.value;
			});
		}
		return {value: numberOfSpecialPoints, required: badgeData.numberOfPoints};
	}

	return null;
}

/**
 * Check if player earned points toward a badge
 * @param {array} prevPlayerSpecialPoints 
 * @param {array} playerSpecialPoints 
 * @param {array} playerBadges 
 */
export function getNewTierBadges(prevPlayerSpecialPoints, playerSpecialPoints, playerBadges ) {
	/* Array of badges where player has reached a new tier */
	let newTierBadges = [];
	badgesData.filter((badgeData) => {return badgeData.type === 'special-points';}).forEach((badgeData) => {
		/* Ignore already earned badges */
		if (playerBadges.some((playerBadge) => {return playerBadge.id === badgeData.id;})) return;


		const prevSpecialPoints = 
			(prevPlayerSpecialPoints.filter((sp) => {return sp.pointId === badgeData.pointId;})).reduce(
				(a, b) => {return a + b.value;}, 0);
		const specialPoints = 
			(playerSpecialPoints.filter((sp) => {return sp.pointId === badgeData.pointId;})).reduce(
				(a, b) => {return a + b.value;}, 0);
		if (specialPoints > prevSpecialPoints && specialPoints < badgeData.numberOfPoints) {
			newTierBadges.push(badgeData.id);
		}
	});

	return newTierBadges;
}


/**
 * Get new triggered badges 
 * NOTE: only badges affected by current module is triggered
 * @param {string} trigger
 * @param {array} playerModulesData 
 * @param {array} playerBadgesData 
 * @param {string} moduleIndex 
 */
export function getTriggeredBadges(
	trigger, 
	playerModulesData, 
	playerBadgesData, 
	playerSpecialPoints, 
	streakType, 
	streakValue, 
	moduleIndex,
	competitionId = null
) {
	/* Array of new badges, used for popup queue */
	let newBadges = [];

	/* Player badges data */
	let newPlayerBadgesData = JSON.parse(JSON.stringify(playerBadgesData));
	
	/* Special case: in development you can start on the last page of a module,
		and moduleIndex will be -1 */
	if (moduleIndex < 0) return {newBadges, newPlayerBadgesData};

	/* Loop over badges (filtered by trigger) */
	badgesData.filter((badgeData) => {
		return (badgeData.triggers && badgeData.triggers.indexOf(trigger) >= 0);
	}).forEach((badgeData) => {
		/* Get player badge data if it exists */
		const newPlayerBadgesDataIndex = newPlayerBadgesData.findIndex((playerBadge) => {
			return playerBadge.id === badgeData.id;
		});

		/* Ignore already earned badges */
		if (newPlayerBadgesDataIndex >= 0) return;

		/* Get area of current module */
		const currentModuleData = (competitionId
			? modulesCompetitionsData.find((m) => {return m.id === playerModulesData[moduleIndex].moduleId;})
			: modulesData.find((m) => {return m.id === playerModulesData[moduleIndex].moduleId;})
		);
		const currentModuleAreaId = (currentModuleData.areaId ? currentModuleData.areaId : null);

		/* TYPE: complete area */
		if (badgeData.type === 'complete-area') {
			if (currentModuleAreaId === badgeData.areaId) {
				const areaIsCompleted = checkIfAllModulesInAreaAreCompleted(badgeData.areaId, playerModulesData);
				if (areaIsCompleted) {
				/* New badge earned! */
					newBadges.push({id: badgeData.id});
					newPlayerBadgesData.push({id: badgeData.id, dateEarned: dayjs(new Date()).format('YYYY-MM-DD')});	
				}
			}
		}

		/* TYPE: stars in modules */
		if (badgeData.type === 'stars-in-modules') {
			let numberOfStars = 0;
			badgeData.moduleIds.forEach((moduleId) => {
				const playerModuleData = playerModulesData.find((m) => {return m.moduleId === moduleId;});
				if (playerModuleData) {
					numberOfStars += playerModuleData.maxStars;
				}
			});
			if (numberOfStars >= badgeData.stars) {
				/* New badge earned! */
				newBadges.push({id: badgeData.id});
				newPlayerBadgesData.push({id: badgeData.id, dateEarned: dayjs(new Date()).format('YYYY-MM-DD')});
			}
		}

		/*  TYPE: max stars in all modules in an area */
		if (badgeData.type === 'max-stars-in-area') {
			/* Get area of current module */
			if (currentModuleAreaId === badgeData.areaId) {
				/* Get all modules in current module's area */
				const modulesInArea = (currentModuleAreaId
					? modulesData.filter((m) => {return m.areaId === currentModuleAreaId;})
					: []
				);

				/* Count number of modules with max stars */
				let numberOfModulesWithMaxStars = 0;
				modulesInArea.forEach((moduleData) => {
					const playerModuleData = playerModulesData.find((m) => {return m.moduleId === moduleData.id;});
					if (playerModuleData && playerModuleData.maxStars === appConfig.maxStarsPerModule) {
						numberOfModulesWithMaxStars += 1;
					}
				});

				if (modulesInArea.length > 0 && numberOfModulesWithMaxStars === modulesInArea.length) {
					/* New badge earned! */
					newBadges.push({id: badgeData.id});
					newPlayerBadgesData.push({id: badgeData.id, dateEarned: dayjs(new Date()).format('YYYY-MM-DD')});
				}
			}
		}

		/* TYPE: total number of stars */
		if (badgeData.type === 'total-number-of-stars') {
			/* Count total number of stars */
			let totalNumberOfStars = 0;
			playerModulesData.forEach((playerModuleData) => {
				if (playerModuleData && playerModuleData.maxStars && playerModuleData.maxStars > 0) {
					totalNumberOfStars += playerModuleData.maxStars;
				}
			});

			/* Check if new badge is earned */
			if (totalNumberOfStars === badgeData.numberOfStars) {
				newBadges.push({id: badgeData.id});
				newPlayerBadgesData.push({
					id: badgeData.id, 
					dateEarned: dayjs(new Date()).format('YYYY-MM-DD'),
				});
			}
		}

		/* TYPE: special points */
		if (badgeData.type === 'special-points') {
			/* Count number of special points */
			let numberOfSpecialPoints = 0;

			if (playerSpecialPoints.length > 0) {
				playerSpecialPoints.forEach((sp) => {
					if (sp.pointId === badgeData.pointId) numberOfSpecialPoints += sp.value;
				});
			}

			if (numberOfSpecialPoints === badgeData.numberOfPoints) {
				/* Add badge (new badge) */
				newBadges.push({id: badgeData.id});
				newPlayerBadgesData.push({
					id: badgeData.id, 
					dateEarned: dayjs(new Date()).format('YYYY-MM-DD')
				});
			}			
		}		

		/* TYPE: succes / error streak */
		if (badgeData.type === streakType && streakValue === badgeData.streakValue) {
			newBadges.push({id: badgeData.id});
			newPlayerBadgesData.push({
				id: badgeData.id, 
				dateEarned: dayjs(new Date()).format('YYYY-MM-DD'),
			});
		}
	});

	return {newBadges, newPlayerBadgesData};
}