const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		animateCharacter: true,
		background: 'ocean-island',
		text: `Welcome to Center Store Island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		showAvatar: true,
		background: 'ocean-island',
		text: `Let's get ready for work. Select what you need.`,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'center-store-1-paper-doll-stocking-shelves',
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'nametag', 
				type: 'alternative', 
				dependsOnBodyHeight: true,
				isCorrect: true,
				title: 'Name tag'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-rubber', 
				type: 'alternative', 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Rubber gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scotch-tape',
				isCorrect: true,
				title: 'Scotch tape',				
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'christmas-sweater', 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Christmas sweater'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'spray-bottle',
				isCorrect: true,
				title: 'Spray bottle',
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'umbrella',
				title: 'Umbrella'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'box-cutter',
				isCorrect: true,
				title: 'Box cutter'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'whistle',
				title: 'Whistle'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'rag-wet-dirty',
				title: 'Dirty wet rag'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'rag-wet-clean',
				isCorrect: true,
				title: 'Clean wet rag'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'center-store-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		showAvatar: true,
		text: `Now that you're ready. Let's get the store ready.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		text: `We are going to work on our Daily Guidelines.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'center-store-1-endcap',
		text: 'Look at this endcap as an example.',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `This is what a good shelf looks like.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Let's look at another one.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'center-store-1-spot-errors-endcap',
		layout: 'center-store-1-endcap',
		text: 'What\'s wrong here? Click on the issues. Click ok when you think you have them all.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'not-pulled'}), // Not pulled to edge
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'uneven'}), // uneven
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wrong-spot'}), // wrong spot
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'backwards'}) // backwards (back of product)
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-1-mc-pulling',
		text: 'Pulling product flush with the shelf is called?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Blocking',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Everything forward.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Fronting',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Straightening',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Pulling',
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'center-store-1-shelf',
		text: 'Take a look at this shelf.',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-1-mc-hole-covering',
		text: 'How do we cover this hole?',
		image: 'shelf',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Pull product from the left',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Pull product from right',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Pull product from each side',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Leave it',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Never cover an out of stock item.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-1-mc-why',
		text: 'But we don\'t want to look out of stock. So why?',
		image: 'shelf',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Tag will be incorrect',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Won\'t know to order',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Harder to restock',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Thins other products',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's go to the backroom to find the missing stock.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Remember, if we can see a mess, so can a customer.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'center-store-1-spot-errors-backroom',
		layout: 'center-store-1-backroom',
		text: 'Click on the issues so we can fix them.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'boxes-floor'}), // boxes on floor
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'paperwork'}), // paperwork on wall
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wrong-side-up'}), // wrong side up
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Much better. Customer viewpoint matters.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `I have the two cases we need. Let's go check if we are ready to stock.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'center-store-1-spot-errors-restock',
		layout: 'center-store-1-restock',
		text: 'Ready to stock? Click on any issues you see.',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-tag'}), // broken tag
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirty-shelf'}), // dirty shelf
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		subtype: 'images',
		taskId: 'center-store-1-mc-ready-shelves',
		layout: 'center-store-1-ready-shelves',
		text: 'What do you need to ready the shelves? ',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Cleaner
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // rag
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // tape
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // hammer
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // cleaver
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // rolling pin
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // thread
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // bbq sauce
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Good job. I'll tape the tag up and you clean that shelf.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'center-store-1-fixed-shelf',
		text: `There we go. All done.`,
		showManager: true,
		managerPose: 'manager-2',
		image: 'manager-top-right',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'center-store-1-shelf-juice',
		text: `Here's a shelf that's partially empty.`,
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Now, lets fill that shelf.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-1-mc-fill-shelf',
		subtype: 'images',
		layout: 'center-store-1-fill-shelf',
		text: 'Click on the two items that should be moved forward on the shelf.',
		showNumberOfAnswersToSelect: false,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // orange 3/10
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // orange 3/12
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // apple 3/10
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // orange 3/10
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // apple 3/12
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // apple 3/12
			}),	
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Good work. Always use FIFO.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		subtype: 'images',
		taskId: 'center-store-1-mc-prep-deadline',
		layout: 'center-store-1-prep-deadline',
		text: 'Our store opens at 8:00am, when should all this be completed?',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 7:55
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. Before we open.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 8:00
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 9:00
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 7:15
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Doesn't have to be ready that early. Just before we open.`
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'center-store-1-mc-open-vs-busy',
		text: `What if our store opens at 8 but isn't busy until 10:00am?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Before 8:00',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: '100% ready at the start.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Before 10:00',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Between 8 and 10',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Previous night',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Great start, but we will have a lot more to cover.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `I'll see you again soon.`,
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cen1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});



export {
	moduleTasks
};
