import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {checkIfModuleIsCompleted} from 'helpers/module-helper';
import {facilitatorUiTexts, errorUiTexts} from 'data/ui-texts';
import {modulesCompetitionsData} from 'data/modules/modules-competitions-data';
import Button from 'components/ui/button/button';
import './facilitator-find-winner-popup.scss';

const FacilitatorFindWinnerPopup = (props) => {
	const {competitionData, gamePlayers, setFindWinnerCompetitionData, findCompetitionWinner} = props;

	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);

	/* Get players that have completed the competition */
	let completedPlayers = [];
	let numberOfStars = 0;
	let numberOfBonusStars = 0;
	gamePlayers.forEach((gp) => {
		if (gp.competitions && gp.competitions.length > 0) {
			const playerCompetitionData = gp.competitions.find((pcd) => {
				return pcd.competitionId === competitionData.id;
			});
			if (playerCompetitionData) {
				if (checkIfModuleIsCompleted(playerCompetitionData) === true) {
					/* Player completed competition module - add them to the draw */
					const playerData = {
						name: gp.name,
						stars: playerCompetitionData.maxStars,
						bonusStars: 0
					};

					/* Competition has related modules  */
					const competitionModuleData = modulesCompetitionsData.find((m) => {
						return m.id === competitionData.moduleId;
					});
					if (competitionModuleData.relatedModuleIds) {
						let bonusStars = 0;
						competitionModuleData.relatedModuleIds.forEach((moduleId) => {
							const playerModuleData = gp.modules.find((pmd) => {
								return pmd.moduleId === moduleId;
							});
							if (checkIfModuleIsCompleted(playerModuleData) === true) {
								if (playerModuleData.maxStars) {
									bonusStars += playerModuleData.maxStars;
								}
							}
						});
						playerData.bonusStars = bonusStars;
					}

					numberOfStars += playerData.stars;
					numberOfBonusStars += playerData.bonusStars;
					completedPlayers.push(playerData);
				}
			}
		}
	});

	/**
	 * Find competition winner
	 * @returns 
	 */
	const handleFindWinner = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrorMsg(null);
		findCompetitionWinner(competitionData.id, completedPlayers, gamePlayers.length)
			.then((response) => {
				if (response.status === 'success') {
					setSuccessMsg(facilitatorUiTexts.findCompetitionWinnerPopup.theWinnerIs 
						+ ': <span>' + response.winner + '!</span>');
					setIsLoading(false);
				} else {
					if (response.error === 'no-players' || response.error === 'no-tickets') {
						setErrorMsg(facilitatorUiTexts.findCompetitionWinnerPopup.errTickets);	
					} else {
						setErrorMsg(errorUiTexts.unknownError);	
					}
					setIsLoading(false);
				}
			});
	};

	return (
		<div className="FacilitatorFindWinnerPopup">
			<div className="FacilitatorFindWinnerPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorFindWinnerPopup-header">
					<div className="FacilitatorFindWinnerPopup-title">
						<span>{facilitatorUiTexts.findCompetitionWinnerPopup.title}</span>
					</div>		
					<div
						className="FacilitatorFindWinnerPopup-closeBtn" 
						onClick={() => {setFindWinnerCompetitionData(null);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorFindWinnerPopup-body'>
					<div className='FacilitatorFindWinnerPopup-text'>
						{renderMarkdown(facilitatorUiTexts.findCompetitionWinnerPopup.text)}
					</div>
					<div className='FacilitatorFindWinnerPopup-info'>
						<span>{facilitatorUiTexts.findCompetitionWinnerPopup.numberOfParticipants}: </span>
						<span>{completedPlayers.length}</span>
					</div>
					<div className='FacilitatorFindWinnerPopup-info'>
						<span>{facilitatorUiTexts.findCompetitionWinnerPopup.numberOfTickets}: </span>
						<span>{numberOfStars + numberOfBonusStars}</span>
					</div>
					{successMsg && <div className='FacilitatorFindWinnerPopup-successMsg'>
						{renderMarkdown(successMsg)}
					</div>}
					{errorMsg && <div className='FacilitatorFindWinnerPopup-errMsg'>
						{renderMarkdown(errorMsg)}
					</div>}
					{!successMsg && <div className='FacilitatorFindWinnerPopup-findWinnerBtn'>
						<Button 
							isLoading={isLoading}
							classes={['blue', 'adminPopup', 'wide']}
							text={facilitatorUiTexts.findCompetitionWinnerPopup.title}
							onClick={() => {handleFindWinner();}}
						/>
					</div>}
				</div>
			</div>
		</div>
	);
};

FacilitatorFindWinnerPopup.propTypes = {
	competitionData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	setFindWinnerCompetitionData: PropTypes.func.isRequired,
	findCompetitionWinner: PropTypes.func.isRequired,
};

export default FacilitatorFindWinnerPopup;
