const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');


let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		background: 'ocean-island',
		text: `Welcome to the Meatiest Island.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		background: 'ocean-island',
		text: `To reach safety, we'll need to get all the way to the end of this chain of islands.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-meat-dept',
		text: 'Do you know why Meat Dept is important?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Most expensive items',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Lots of total store sales',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Meals focus on meat',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Everything is refrigerated',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Zombie Attacks',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		text: `Yes, most shopping revolves around meat as central focus.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		text: `This is the best chop shop in town.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		text: `Let's get you ready.`,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'meat-1-paper-doll-dress',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'meat-cleaver',
				isCorrect: true,
				title: 'Meat cleaver'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beard-net',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Beard net',
				correctConditions: [
					Object.assign({}, taskTemplates.paperDollTemplate.correctConditionTemplate, {
						categoryId: 'beard'
					})
				]
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hair-net',
				dependsOnBodyHeight: true,
				isCorrect: true,
				title: 'Hair net'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full',
				type: 'bodytype',
				isCorrect: true, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic',
				type: 'alternative',
				isCorrect: true,
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Cut resistant gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'beef-slab',
				isCorrect: true,
				title: 'Big slab of beef'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'flower',
				title: 'Flower'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'goggles',
				dependsOnBodyHeight: true,
				title: 'Swimming goggles'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'umbrella',
				title: 'Umbrella'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'scales',
				isCorrect: true,
				title: 'Scales'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'clown-hair',
				dependsOnBodyHeight: true,
				title: 'Clown hair'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item',
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemId: 'chefs-hat',
					text: 'A Chef\'s hat has been added to your avatar.'					
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'meat-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		showAvatar: true,
		characterType: 'butcher',
		text: `Looking sharp. Let's go.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-best-place-to-start',
		text: 'Where\'s the best place to start when making sure the department is ready for customers?',
		showNumberOfAnswersToSelect: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Sales Floor', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct, the best place to start is from the customer\'s perspective.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Prep table',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Cooler',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Freezer',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'There\'s plenty to check on here, but it\'s not where we\'ll start.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Before we go further, let's see how you are at identifying meats.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'meat-1-sales-floor',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-where-to-look-corndog',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-where-to-look',
		text: `If you are looking for a corndog, where would you check?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Refridgerated prepacked
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Refridgerated fresh
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Service counter
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Dump bin with spices
				id: 4
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Frozen upright cases
				id: 5,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly. Most easy meal solutions that need to be microwaved or baked before consuming are here.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-where-to-look-meat-slices',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-where-to-look-2',
		text: `A man walks in dying of hunger. Quick, we need pre-packaged meat slices an emergency sandwich, stat! Where do you go?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Refridgerated prepacked
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Good job, let\'s grab some sliced roast beef and get this man fed.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Refridgerated fresh
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Service counter
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Dump bin with spices
				id: 4
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Frozen upright cases
				id: 5,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { // Hospital
				id: 6,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'I think we can revive him with a little sliced roast beef.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-boneless-chicken-breast',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-where-to-look',
		text: 'Now, where do you find fresh boneless chicken breast already cut? GO!',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Well played. Have you been here before?'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-fresh-brains',
		text: 'And where do I find fresh brains for keeping the zombies at bay?',
		image: 'wizard2',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Prepackaged meat case', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Refrigerated cases',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Service case',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Discount bin',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Frozen cases',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Who let this guy in?',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Will you get out of here?! Also, we haven\'t had any zombies in here for months!',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's get back to it. Next question is about grilling.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Most of what we make comes from just a few identifiable species.`,
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'meat-1-sort-meat-animal',
		text: `Match the type of meat with the animal.`,
		withHeader: false,
		layout: 'meat-1-animals',
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'cow', text: ''
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'chicken', text: ''
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'pig', text: ''
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'lamb', text: ''
			}),
		],	
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: 'Beef', categoryIds: ['cow']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: 'Poultry', categoryIds: ['chicken']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: 'Pork', categoryIds: ['pig']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: 'Lamb', categoryIds: ['lamb']
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'meat-1-organize-product-animal',
		layout: 'meat-1-match-with-animal',
		text: `Let's try again, but a little bit different. Match product with the right animal.`,
		showSolutionWhenDone: true,
		shuffleItems: true,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, containerId: 'c1', image: 'meat-1', alt: 'meat', correctContainerIds: ['a13', 'a14', 'a15']
			}),// image of a marbled steak (cow)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, containerId: 'c2', image: 'meat-2', alt: 'meat', correctContainerIds: ['a10', 'a11', 'a12']
			}),// image of porkchop (pig)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, containerId: 'c3', image: 'meat-3', alt: 'meat', correctContainerIds: ['a7', 'a8', 'a9']
			}),// image of very pale ground meat (turkey)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, containerId: 'c4', image: 'meat-4', alt: 'meat', correctContainerIds: ['a4', 'a5', 'a6']
			}),// Image of boneless chicken breast (chicken)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, containerId: 'c5', image: 'meat-5', alt: 'meat', correctContainerIds: ['a13', 'a14', 'a15']
			}),// image of cubed steak (cow)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, containerId: 'c6', image: 'meat-6', alt: 'meat', correctContainerIds: ['a10', 'a11', 'a12']
			}),// image of tied roast pale in color (pig)
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, containerId: 'c7', image: 'meat-7', alt: 'meat', correctContainerIds: ['a13', 'a14', 'a15']
			}),// Image of red roast that's not tied (cow)
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'shelves', finalItemId: 4}),// chicken
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'shelves'}),// chicken
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'shelves'}),// chicken
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'shelves', finalItemId: 3}),// turkey
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'shelves'}),// turkey
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'shelves'}),// turkey
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a10', type: 'shelves', finalItemId: 2}),// pig
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a11', type: 'shelves', finalItemId: 6}),// pig
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a12', type: 'shelves'}),// pig
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a13', type: 'shelves', finalItemId: 1}),// cow
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a14', type: 'shelves', finalItemId: 5}),// cow
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a15', type: 'shelves', finalItemId: 7}),// cow
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c7', type: 'initial'}),
				]
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'meat-1-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Okay, let's look at the prep area. We'll start from the customer's point of view.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-1-spot-errors-prep-area',
		text: 'Click on the issues. Click done when you think you have them all.',
		layout: 'meat-1-prep-area',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'spills',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'raw-meat',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'paper-work',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cow',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-whats-next',
		text: 'Now that\'s ready. What\'s next?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Cull', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Mop',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Stocking',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Get ready for cutting',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Nothing under quality when store opens.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Now we'll take a look at some cases.`,
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-1-spot-errors-meat-cooler',
		text: 'Spot the issues. Click done when you think you have them all.',
		layout: 'meat-1-meat-cooler',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// empty roll of customer bags
				id: 'empty-roll',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right, customers really like to be able to put meats in a bag to reduce risk of leaks contaminating anything.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// gauge on case is 45
				id: 'gauge',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Whoa, this case is too hot!',
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// product is over fill level in certain areas.
				id: 'over-fill',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Good catch, Product should NEVER be loaded above product level line because product will lose refrigeration and spoil.',
						})
					}),
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// only a few types in case but they are stacked at various heights.
				id: 'various-heights',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right. This isn\'t exactly Meat City, so we don\'t want the NY skyline. Make sure product is as level as possible across the case.',
						})
					}),
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'meat-1-order-fix-hot-case',
		text: `This case was too hot. What do we do?`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'manager', text: 'Notify the manager', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'pull-meat', text: 'Pull meat from case and place on racks', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'check-temp', text: 'Check product temperature off sales floor', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'put-meat', text: 'Put meat at or below safe temperature in cooler', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'discard', text: 'Discard meat over safe temperature', correctOrderNumbers: [5]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'Great work. Make sure we save as much product as possible.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Let\'s look at another case.',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-1-spot-errors-meat-cooler-2',
		text: 'Click on the issues. Click done when you think you have them all.',
		layout: 'meat-1-meat-cooler2',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// package upside down
				id: 'updside-down',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// package in back is frosted
				id: 'frosted-package',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// brown spots on meat
				id: 'brown-spots',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// package leaking
				id: 'leaking-package',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {// dirty shelf
				id: 'dirty-shelf',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-frosted-package',
		text: 'What simple solution could we utilize to help with that frosted package?',
		image: 'frosted-meat',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Move to the front of the case', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right, if the product isn\'t actually frozen, we can move closer to the front of the case where it\'s warmer.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Discard the item',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Don\'t toss out anything without recording it as a loss.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Throw it out and record it on the logs',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'It probably only got frosted from the case being a little too cold. It is still good.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Put it in the cooler in back.',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No, we don\'t need to remove it from the sales floor.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Repackage the film and tray, and add today\'s date',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Shouldn\'t be needed here, and definitely NEVER change the orignal dating.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Repackage the film and tray, and put the orginal date on it.',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'It\'s a good thought but not really necessary for lightly frosted packaging.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Now, let\'s look closer at the labels we use in the meat department.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-multiple-choice-chuck-eye-1',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-chuck-eye',
		text: `Click on the part of the label that provides the following information: <br><br>Item code`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-multiple-choice-chuck-eye-2',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-chuck-eye',
		text: `Click on the part of the label that provides the following information: <br><br>UPC`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4
			})	
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-multiple-choice-chuck-eye-3',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-chuck-eye',
		text: `Click on the part of the label that provides the following information: <br><br>Description`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4
			})			
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-multiple-choice-chuck-eye-4',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'meat-1-chuck-eye',
		text: `Click on the part of the label that provides the following information: <br><br>Pack date`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: 'We have talked about labelling and freshness, now we\'ll look at how to have strict quality.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-trim-standard',
		text: `What is the typical trim standard?`,
		image: 'steak',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1/8"',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '1/4"'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '1/2"'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Varies',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-measure',
		subtype: 'images',
		text: `What could be used to measure 1/8"?`,
		layout: 'meat-1-measure',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Pen
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes. 1/8" is about half the pen width.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thumb
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Finger
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Tape measure
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Usually don\'t have one on me.',
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-trim-standard-pork',
		text: `How much should the trim be on pork?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1/8"',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '1/4"'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '1/2"'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'None',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-oos',
		text: `Which item can we move over to help fill the empty section?`,
		image: 'meat-cooler',
		showNumberOfAnswersToSelect: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Nothing',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Correct. A hole is a hole.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Any same species'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'The item to the right'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'The item to the left',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Let\'s look closer at the packaging.',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-steak-labels',
		text: `Which of these is correct?`,
		subtype: 'images',
		layout: 'meat-1-steak-labels',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			})
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 'meat-1-hot-deal',
		text: 'Look at this one.',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'meat-1-spot-errors-stickers',
		text: 'Pull those off please.',
		layout: 'meat-1-stickers',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hot-deal-1',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hot-deal-2',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hot-deal-3',
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: 'Someone tried to cover up poor quality.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard',
		characterItems: ['hot-deal-1'],
		text: 'Wasn\'t me.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'meat-1-mc-what-to-do',
		text: `What should we do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Remove from case, poor quality',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Quality first.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Put sale sticker on',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'No, we just took those off, remember.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Lower price'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Leave it now, too late',
			})
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'meat-1-order-beef-qualities',
		text: `What are the qualities of beef. Best first.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Prime', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'CAB', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'High Quality', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Select', correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'chef',
		characterItems: ['chicken-wings'],
		text: 'Good work today.',
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['chicken-wings'],
					text: 'Chicken Wings has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'meat1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};