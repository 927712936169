import React from 'react';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';
import {getCurrentTime} from 'helpers/game-helper';
import {gameUiTexts} from 'data/ui-texts';
import './clock-tasks-overview.scss';

const ClockTasksOverview = (props) => {
	const {
		// areaId, 
		deviceInfo, 
		clockTasksAreCompleted, 
		minutesPassed, 
		taskData, 
		solvedTaskIds, 
		failedTaskIds, 
		selectClockTask
	} = props;
	
	const currentTime = getCurrentTime(taskData.startTime, minutesPassed);

	return (
		<div className={'ClockTasksOverview ' + taskData.layout + ' ' + deviceInfo.orientation}>
			<div className="ClockTasksOverview-clock"><span>{gameUiTexts.time}: {currentTime}</span></div>
			<div className="ClockTasksOverview-tasks">
				{taskData.tasks.map((task) => {
					/* Get status */
					let taskStatus = 'available';
					if (task.delayMinutes > minutesPassed) taskStatus = 'hidden';
					if (solvedTaskIds.indexOf(task.id) >= 0) taskStatus = 'solved';
					if (failedTaskIds.indexOf(task.id) >= 0) taskStatus = 'failed';
					if (clockTasksAreCompleted && solvedTaskIds.indexOf(task.id) < 0) taskStatus = 'failed';

					/* Get image */
					let image = null;
					if (task.hasImage) {
						try {
							if (taskStatus === 'solved' && task.hasSolvedImage === true) {
								image = require('assets/images/modules/tasks/clock-tasks/' +
								taskData.layout + '/' + task.id + '-solved.svg');
							} else {
								image = require('assets/images/modules/tasks/clock-tasks/' +
								taskData.layout + '/' + task.id + '.svg');
							}
						} catch (e) {
							console.error('image not found: assets/images/modules/tasks/clock-tasks/' +
							taskData.layout + '/' + task.id + '.svg');
						}
					}
					return (
						<div 
							key={task.id} 
							className={'ClockTasksOverview-task ' + task.id + ' ' + taskStatus} 
						>
							{image && <ReactSVG src={image}/>}
							{taskStatus === 'available' && 
								<div 
									className="ClockTasksOverview-taskBtn" 
									onClick={() => {if (taskStatus === 'available') selectClockTask(task.id);}}
								/>
							}
						</div>
					);
				})}
			</div>
		</div>
	);
};

ClockTasksOverview.propTypes = {
	// areaId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	clockTasksAreCompleted: PropTypes.bool.isRequired,
	minutesPassed: PropTypes.number.isRequired,
	taskData: PropTypes.object.isRequired,
	solvedTaskIds: PropTypes.array.isRequired,
	failedTaskIds: PropTypes.array.isRequired,
	selectClockTask: PropTypes.func.isRequired
};

export default ClockTasksOverview;
