import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Countdown from 'components/ui/countdown/countdown';
import Character from 'components/ui/character/character';
import Avatar from 'components/game/avatar/avatar';
import './instructions.scss';


const Instructions = ({deviceInfo, managerId, playerData, moduleData, taskData, endDelay}) => {

	return (
		<div 
			className={'Instructions ' 
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
				+ ' ' + (taskData.background ? taskData.background : moduleData.background)
				+ (taskData.animateCharacter ? ' animateCharacter-' + taskData.animationId : '')
				+ (taskData.fadeoutSpeachBubble ? ' fadeoutSpeachBubble' : '')	
			}
		>
			{taskData.subtype === 'countdown' && 
				<div className='Instructions-countdown'>
					<Countdown 
						delayTime={taskData.delayTime} 
						shownDelay={taskData.shownDelay} 
						onCountdown={endDelay}
					/>
				</div>
			}
			{taskData.extraCharacters && taskData.extraCharacters.map((character) => {
				return (
					<div key={character.id} className={'Instructions-extraCharacter ' + character.id 
						+ ' ' + character.position + ' ' + character.type}>
						<Character 
							page="instructions"
							managerId={managerId}
							characterId={character.id}
							items={character.items}
						/>
					</div>
				);
			})}
			{taskData.showCharacter && 
				<div 
					className={'Instructions-character' + (taskData.characterType ? ' ' + taskData.characterType : '')}
				>
					<Character 
						page="instructions" 
						managerId={managerId}
						characterId={taskData.characterType + '-' + taskData.characterPose}
						items={taskData.characterItems}
					/>
				</div>}
			{taskData.showAvatar && 
			<div className={'Instructions-avatar ' + (taskData.avatarPose ? taskData.avatarPose : '')}>
				<Avatar 
					type="instructions" 
					avatarPose={taskData.avatarPose ? taskData.avatarPose : null} 
					items={taskData.avatarItems}
					playerData={playerData} 
				/>
			</div>}
			{taskData.items && taskData.items.map((item, index) => {
				return (
					<div 
						key={index}
						className={'Instructions-item ' + item + ' manager-' + managerId}>	
					</div>
				);
			})}
			{(taskData.title || taskData.text) && 
				<div className={'Instructions-speechBubble'}>
					{taskData.title && <div className="Instructions-title">{taskData.title}</div>}
					{taskData.text && <div className="Instructions-text">{renderMarkdown(taskData.text)}</div>}
					<div className={'Instructions-bubbleTriangle ' + 
						(taskData.speechBubbleDirection ? taskData.speechBubbleDirection : '')}
					/>
				</div>
			}
		</div>
	);
};

Instructions.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	managerId: PropTypes.string.isRequired,
	playerData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	endDelay: PropTypes.func.isRequired
};

export default Instructions;
