import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {renderMarkdown} from 'helpers/text-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getParticipatingPlayers} from 'helpers/competition-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {areasData} from 'data/areas-data';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import Button from 'components/ui/button/button';
import FacilitatorNewMessagePopup from './facilitator-new-message-popup/facilitator-new-message-popup';
import FacilitatorDeleteMessagePopup from './facilitator-delete-message-popup/facilitator-delete-message-popup';
import FacilitatorEditMessagePopup from './facilitator-edit-message-popup/facilitator-edit-message-popup';
import './facilitator-messages.scss';

const FacilitatorMessages = (props) => {
	const {
		userData,
		coFacilitatorData,
		pageData, 
		gameData, 
		gamePlayers,
		createMessage,
		deleteMessage,
		editMessage,
		handleGoToPage
	} = props;

	/* Toggle "new message" popup */
	const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);

	/* Toggle edit popup */
	const [editMessageData, setEditMessageData] = useState(null);

	/* Toggle delete popup */
	const [deleteMessageData, setDeleteMessageData] = useState(null);

	/* Sort all created competition */
	const messages = (gameData.messages
		?	sortArrayByProperty(gameData.messages, 'created', 'DESC')
		: []
	);

	return (
		<div className="FacilitatorMessages">
			{/* Header */}
			<div className="FacilitatorMessages-header">
				<FacilitatorHeaderSubpage 
					page={pageData.id} 
					subpage="messages"
					subpageIds={pageData.subpageIds}
					handleGoToPage={handleGoToPage}
				/>
				{(!coFacilitatorData || coFacilitatorData.canSendMessages === true) && 
					<div className="FacilitatorMessages-addMessageBtn">
						<Button 
							classes={['addGame']} 
							text={facilitatorUiTexts.addMessageBtn}
							onClick={() => {setShowNewMessagePopup(true);}} />
					</div>}
			</div>
			
			<div className="FacilitatorMessages-messages">
				{messages.filter((m) => {return !m.isDeleted;}).map((m, i) => {
					/* Get recipients, count number of recipients that have seen the message */
					const sendToAll = (!m.selectedAreaIds || m.selectedAreaIds.length === 0 ? true : false);
					const recipients = getParticipatingPlayers(
						gamePlayers, sendToAll, m.selectedAreaIds ? m.selectedAreaIds : []
					);
					const numberOfRecipients = recipients.length;
					let numberOfMessagesSeen = 0;
					recipients.forEach((r) => {
						/* Check if message has been seen */
						if (
							r.notifications && 
							r.notifications.length > 0 &&
							r.notifications.indexOf('message-' + m.id) >= 0
						) {
							numberOfMessagesSeen += 1;
						}		
					});
					
					/* Only the creator of the message can edit it */
					const canEdit = (userData.id === m.createdById);

					/* Departments */
					let departments = facilitatorUiTexts.sentToAllDepartments;
					if (m.selectedAreaIds && m.selectedAreaIds.length > 0) {
						departments = facilitatorUiTexts.sentToPlayersInDepartments + ': ';
						m.selectedAreaIds.forEach((areaId, index) => {
							const areaData = areasData.find((a) => {return a.id === areaId;});
							if (areaData) {
								departments += areaData.title;
								if (index + 1 < m.selectedAreaIds.length) {
									departments += ', ';
								} else {
									departments += '.';
								}
							}
						});
					}

					return (
						<div key={i} className="FacilitatorMessages-message">
							<div className="FacilitatorMessages-messageHeader">
								<span>{facilitatorUiTexts.from}: {m.createdByName}</span>
								<span>{m.created && dayjs(m.created).format('MM/DD/YYYY')}</span>
							</div>
							<div className="FacilitatorMessages-messageBody">
								{/* Departments */}
								<div className="FacilitatorMessages-departments">
									<span>{departments}</span>
								</div>
								{/* Recipients */}
								<div className="FacilitatorMessages-recipients">
									<span>
										{numberOfMessagesSeen}/{numberOfRecipients} {facilitatorUiTexts.seenMessage}.
									</span>
								</div>

								{/* Text */}
								<div className="FacilitatorMessages-text">
									{m.text && renderMarkdown(m.text)}
								</div>

								{/* Delete btn */}
								{!coFacilitatorData && <div 
									className="FacilitatorMessages-deleteBtn"
									onClick={() => {setDeleteMessageData(m);}}
								>
									<span>{facilitatorUiTexts.delete}</span>
								</div>}

								{/* Edit btn */}
								{canEdit && <div 
									className="FacilitatorMessages-editBtn"
									onClick={() => {setEditMessageData(m);}}
								>
									<span>{facilitatorUiTexts.edit}</span>
								</div>}
							</div>
						</div>
					);
				})}
			</div>

			{/* New message popup */}
			{showNewMessagePopup &&
				<FacilitatorNewMessagePopup 
					gamePlayers={gamePlayers}
					setShowNewMessagePopup={setShowNewMessagePopup}
					createMessage={createMessage}
				/>
			}

			{/* Delete message popup */}
			{deleteMessageData && 
				<FacilitatorDeleteMessagePopup 
					messageData={deleteMessageData}
					setDeleteMessageData={setDeleteMessageData}
					deleteMessage={deleteMessage}
				/>
			}

			{/* Edit message popup */}
			{editMessageData &&
				<FacilitatorEditMessagePopup 
					gamePlayers={gamePlayers}
					messageData={editMessageData}
					setEditMessageData={setEditMessageData}
					editMessage={editMessage}
				/>
			}			
		</div>
	);
};

FacilitatorMessages.propTypes = {
	userData: PropTypes.object.isRequired,
	coFacilitatorData: PropTypes.object,
	pageData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	gamePlayers: PropTypes.array.isRequired,
	createMessage: PropTypes.func.isRequired,
	deleteMessage: PropTypes.func.isRequired,
	editMessage: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default FacilitatorMessages;
