import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './welcome.scss';

const Welcome = ({deviceInfo, taskData}) => {
	return (
		<div className={'Welcome' + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			<div className="Welcome-content">
				{taskData.title && <div className="Welcome-title">{renderMarkdown(taskData.title)}</div>}
				{taskData.text && <div className="Welcome-text">{renderMarkdown(taskData.text)}</div>}
			</div>
		</div>
	);
};

Welcome.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired
};

export default Welcome;
