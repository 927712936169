import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {renderMarkdown} from 'helpers/text-helper';
import {generateUniqueId} from 'helpers/admin-helper';
import {gamePopupSections} from 'data/admin-data';
import AdminGamePopupInfo from './sections/admin-game-popup-info';
import AdminGamePopupContent from './sections/admin-game-popup-content';
import AdminGamePopupPlayers from './sections/admin-game-popup-players';
import AdminGamePopupStats from './sections/admin-game-popup-stats';
import './admin-game-popup.scss';

const sectionComponents = {
	info: AdminGamePopupInfo,
	content: AdminGamePopupContent,
	players: AdminGamePopupPlayers,
	stats: AdminGamePopupStats
};

const AdminGamePopup = (props) => {
	const {
		deviceInfo,
		gameId, 
		gamesData, 
		usersData, 
		tagsData, 
		handleSelectGame, 
		updateGameData, 
		updateTagsData,
		toggleShowAddFacilitatorPopup,
		toggleRemoveFacilitatorPopup,
		toggleShowDeleteGamePopup
	} = props;

	/* Get game data */
	const gameData = gamesData.find((game) => {return game.id === gameId;});

	/* Section */
	const [sectionId, setSectionId] = useState(gamePopupSections[0].id);
	let SectionComponent = AdminGamePopupInfo;
	if (sectionComponents.hasOwnProperty(sectionId)) SectionComponent = sectionComponents[sectionId];


	/**
	 * Update store info
	 * @param {object} updates 
	 */
	const updateStoreInfo = (updates) => {
		updateGameData(gameId, updates);
	};

	/**
	 * Add game tag
	 * @param {string} tag 
	 * @param {bool} isNew 
	 */
	const addTag = (tag, isNew) => {
		let gameTags = (gameData && gameData.tags ? [...gameData.tags] : []);

		if (!isNew) {
			/* Tag already exists in database, just add tag id game */
			gameTags.push(tag);
			updateGameData(gameId, {tags: gameTags});
			
		} else {
			/* New tag, generate tag id */
			const existingTagIds = tagsData.map((tag) => {return tag.id;});
			generateUniqueId(tag, existingTagIds).then((response) => {
				/* Add tag to game */
				gameTags.push(response.id);
				updateGameData(gameId, {tags: gameTags});

				/* Add tag to tag database */
				updateTagsData({id: response.id, value: tag.trim()}, 'add');
			});
		}		
	};

	/**
	 * Remove game tag
	 * @param {string} tagId 
	 */
	const removeTag = (tagId) => {
		let gameTags = (gameData && gameData.tags ? [...gameData.tags] : []);
		const tagIndex = gameTags.indexOf(tagId);
		if (tagIndex >= 0) {
			/* Remove tag from game */
			gameTags.splice(tagIndex, 1);
			updateGameData(gameId, {tags: gameTags});

			/* Check if tag is still in use */
			if (gameData && !gamesData.some((game) => {
				return (
					game.id !== gameData.id && // do not check for currently selected game
					game.tags && 
					game.tags.length > 0 && 
					game.tags.indexOf(tagId) >= 0
				);
			})) {
				/* Tag is not in use by any game, delete from tag database */
				updateTagsData({id: tagId}, 'delete');
			};
		}
	};

	if (!gameData) return null;

	return (
		<div className="AdminGamePopup" onClick={() => {handleSelectGame();}}>
			<div className="AdminGamePopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminGamePopup-header">
					<div className="AdminGamePopup-sectionMenu">
						{gamePopupSections.map((section) => {
							return (
								<div 
									key={section.id} 
									className={'AdminGamePopup-sectionMenuItem' 
										+ (section.id === sectionId ? ' selected' : '')}
									onClick={() => {setSectionId(section.id);}}
								>
									<span>{section.title}</span>
								</div>
							);
						})}
					</div>
					<div className="AdminGamePopup-storeName"><span>{gameData ? gameData.storeName : ''}</span></div>
					<div className="AdminGamePopup-closeBtn" onClick={() => {handleSelectGame();}} />
					
				</div>

				{/* Popup body */}
				<div className="AdminGamePopup-body">
					{gameData && <SectionComponent 
						deviceInfo={deviceInfo}
						gameData={gameData} 
						usersData={usersData} 
						tagsData={tagsData}
						updateStoreInfo={updateStoreInfo}
						addTag={addTag}
						removeTag={removeTag}
						toggleShowAddFacilitatorPopup={toggleShowAddFacilitatorPopup}
						toggleRemoveFacilitatorPopup={toggleRemoveFacilitatorPopup}
						toggleShowDeleteGamePopup={toggleShowDeleteGamePopup}
						updateGameData={updateGameData}
					/>}
				</div>
			</div>
		</div>
	);

};

AdminGamePopup.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	handleSelectGame: PropTypes.func.isRequired,
	updateGameData: PropTypes.func.isRequired,
	updateTagsData: PropTypes.func.isRequired,
	toggleShowAddFacilitatorPopup: PropTypes.func.isRequired,
	toggleRemoveFacilitatorPopup: PropTypes.func.isRequired,
	toggleShowDeleteGamePopup: PropTypes.func.isRequired,
};

export default AdminGamePopup;
