/* Yearbook entry points */
const yearbookInitialEntryPoints = [
	{
		id: 'tvShow',
		title: 'Favorite tv-show:'
	},
	{
		id: 'priateName',
		title: 'Pirate name:'
	},
	{
		id: 'hobby',
		title: 'Hobby:'
	},
	{
		id: 'badgeIds', 
		badgeIds: []
	},
];

const yearbookAllQuestions = [
	{
		id: 'tvShow',
		title: 'Favorite tv-show:',
		charLimit: 13,
	},
	{
		id: 'priateName',
		title: 'Pirate name:',
		charLimit: 18,
	},
	{
		id: 'hobby',
		title: 'Hobby:',
		charLimit: 22,
	},
	{
		id: 'favAnimal',
		title: 'Favorite animal:',
		charLimit: 14,
	},
	{
		id: 'favSport',
		title: 'Favorite sport:',
		charLimit: 15,
	},
];


export {
	yearbookInitialEntryPoints,
	yearbookAllQuestions
};