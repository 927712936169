import React from 'react';
import PropTypes from 'prop-types';
import AdminStatistics from 'components/admin/admin-statistics/admin-statistics';
import './admin-game-popup-stats.scss';

const AdminGamePopupStats = ({deviceInfo, gameData, usersData}) => {
	return (
		<div className="AdminGamePopupStats">
			<AdminStatistics 
				type="game-popup" 
				deviceInfo={deviceInfo} 
				gameId={gameData.id} 
				gamesData={[gameData]} 
				tagsData={null}
				usersData={usersData}
			/>
		</div>
	);
};

AdminGamePopupStats.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
};

export default AdminGamePopupStats;
