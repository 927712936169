import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {mapUiTexts} from 'data/ui-texts';
import {areasData} from 'data/areas-data';
import {notificationsData} from 'data/notifications-data';
import {checkIfAreaNotificationIsTriggered} from 'helpers/notifications-helper';
import {getPlayerModulesData} from 'helpers/module-helper';
import {checkIfAreaIsUnlocked, getWhyAreaIsLocked} from 'helpers/area-helper';
import Area from 'components/ui/area/area';
import NavigationTop from 'components/ui/navigation-top/navigation-top';
import NotificationsGame from 'components/ui/notifications-game/notifications-game';
import NotificationManager from 'components/ui/notification-manager/notification-manager';
import './areas.scss';

const Areas = (props) => {
	const {
		deviceInfo, 
		gameData, 
		playerData, 
		userData, 
		updatePlayerGameData, 
		handleGoToPage,
		lastAreaIndex, 
		setLastAreaIndex
	} = props;


	/**
	 * Component mounted
	 */
	useEffect(() => {
		/* Scroll to top if no last areaindex then goto last area */
		if (lastAreaIndex) {
			const areaElement = document.querySelector('.' + lastAreaIndex);
			if (areaElement) {areaElement.scrollIntoView();}
		} else {
			const element = document.querySelector('.Areas');
			if (element ) {
				element.scrollTop = 0;
			} 
		}

		/* Check notifications */
		let newNotification = null;
		notificationsData.forEach((n) => {
			if (newNotification) return;
			const isTriggered = checkIfAreaNotificationIsTriggered(n, playerData);
			if (isTriggered) {
				newNotification = n;
			}
		});
		if (newNotification) {
			setManagerNotification(newNotification);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Notifications */
	const [managerNotification, setManagerNotification] = useState(null);

	/**
	 * Close notification
	 * @param {string} notificationId 
	 */
	const handleCloseNotification = (notificationId) => {
		const playerNotifications = 
			playerData.notifications ? JSON.parse(JSON.stringify(playerData.notifications)) : [];
		playerNotifications.push(notificationId);
		/* Log notifications as seen */
		updatePlayerGameData({notifications: playerNotifications}).then(() => {
			setManagerNotification(null);
		});
	};

	/* Area locked feedback */
	const [areaUnavailableFeedback, setAreaUnavailableFeedback] = useState(null);

	/* Player modules data */
	const playerModulesData = getPlayerModulesData(playerData, null);

	/**
	 * Go to area
	 * @param {string} areaId 
	 */
	const handleGoToArea = (areaId) => {
	
		/* the area is disabled for the player by the facilitator */
		if (playerData && playerData.disabledAreaIds && playerData.disabledAreaIds.includes(areaId)) {
			setAreaUnavailableFeedback(mapUiTexts.areaDisabled);
			return;
		}

		/* check if area is locked */
		const isUnlocked = checkIfAreaIsUnlocked(areaId, playerModulesData, gameData);
		if (isUnlocked || appConfig.env === 'development' || appConfig.env === 'test' || userData.role === 'admin') {
			handleGoToPage('area-map', null, areaId);
			setLastAreaIndex(areaId);
		} else {
			const whyLocked = getWhyAreaIsLocked(areaId, playerModulesData, gameData);
			if (whyLocked) {
				if (whyLocked.type === 'areaDeactivated') {
					setAreaUnavailableFeedback(mapUiTexts.areaDeactivated);
				}
				if (whyLocked.type === 'areaNotCompleted') {
					const blockingAreaData = areasData.find((a) => {return a.id === whyLocked.areaId;});
					if (blockingAreaData) {
						setAreaUnavailableFeedback(mapUiTexts.areaNotCompleted + ' ' + blockingAreaData.title);
					}
				}
			}
		}
	};

	/* Get areas that are displayed */
	const displayedAreasData = areasData.filter((areaData) => {
		return areaData.showOnMap !== false;
	});

	return (
		<div className={'Areas' + (deviceInfo ? ' ' + deviceInfo.orientation : '')}>
			<div className="Areas-wrap">
				<div className={'Areas-areas ' + (displayedAreasData.length % 2 === 1 ? 'uneven' : 'even')}>
					{displayedAreasData.map((areaData) => {
						let isDisabled = !checkIfAreaIsUnlocked(areaData.id, playerModulesData, gameData);

						if (playerData &&
							playerData.disabledAreaIds && playerData.disabledAreaIds.includes(areaData.id)) {
							isDisabled = true;
						}

						return (
							<div key={areaData.id}
								className={'Areas-area ' + 
									(isDisabled ? 'disabled' : '') + 
									(areaData.isUnderConstruction ? ' under-construction' : '')}
							>
								{areaData.isUnderConstruction &&
									<div className={'Areas-constructionSign'}/>
								}
								<Area 
									type="area"
									userData={userData}
									gameData={gameData}
									areaData={areaData}
									playerData={playerData}
									handleGoToArea={handleGoToArea}
								/>
							</div>
						);
					})}
				</div>
			</div>

			{/* Area locked feedback */}
			{areaUnavailableFeedback && 
				<div className="Areas-areaUnavailableFeedback" onClick={() => {setAreaUnavailableFeedback(null);}}>
					<div className="Areas-areaUnavailableFeedbackContent" onClick={(e) => {e.stopPropagation();}}>
						<div className="Areas-areaUnavailableFeedbackText">
							<span>{areaUnavailableFeedback}</span>
						</div>
						<div 
							className="Areas-areaUnavailableFeedbackBtn"
							onClick={() => {setAreaUnavailableFeedback(null);}}
						>
							<span>{mapUiTexts.ok}</span>
						</div>
					</div>
				</div>
			}

			{/* Top navigation */}
			<NavigationTop 
				type="areas"
				deviceInfo={deviceInfo}
				gameData={gameData}
				playerData={playerData}
				handleGoToPage={handleGoToPage}
			/>
			
			{/* Navigation */}
			<NotificationsGame 
				type="areas"
				deviceInfo={deviceInfo}
				gameData={gameData}
				playerData={playerData}
				updatePlayerGameData={updatePlayerGameData}
				handleCloseNotification={handleCloseNotification}
				handleGoToPage={handleGoToPage}
			/>

			{/* Manager notification */}
			{managerNotification && 
				<NotificationManager 
					notificationData={managerNotification} 
					gameData={gameData}
					handleCloseNotification={handleCloseNotification}
				/>
			}
		</div>
	);
};

Areas.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	updatePlayerGameData: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	lastAreaIndex: PropTypes.string,
	setLastAreaIndex: PropTypes.func.isRequired,
};

export default Areas;
