import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './facilitator-remove-co-facilitator-popup.scss';

const FacilitatorRemoveCoFacilitatorPopup = (props) => {
	/* Props */
	const {
		gameId, 
		coFacilitatorData, 
		togglePopup, 
		removeCoFacilitatorFromGame, 
		toggleShowEditCoFacilitatorPopup
	} = props;

	/* Get co-facilitator name/e-mail and prepare popup text */
	const coFacilitatorName = (coFacilitatorData.name ? coFacilitatorData.name : coFacilitatorData.email);
	const popupText = JSON.parse(JSON.stringify(facilitatorUiTexts.removeCoFacilitatorPopup.text))
		.replace(/%name%/g, coFacilitatorName);

	/* Handle remove player */
	const [isRemovingCoFacilitator, setIsRemovingCoFacilitator] = useState(false);
	const handleRemovePlayer = () => {
		setIsRemovingCoFacilitator(true);
		removeCoFacilitatorFromGame(gameId, coFacilitatorData.email).then(() => {
			/* Co-facilitator removed, close popups */
			togglePopup(false);
			toggleShowEditCoFacilitatorPopup(null);
		}).catch((error) => {
			/* Error removing co-facilitator */
			console.error(error);
			setIsRemovingCoFacilitator(false);
		});
	};

	return (
		<div className="FacilitatorRemoveCoFacilitatorPopup" onClick={() => {togglePopup(null);}}>
			<div className="FacilitatorRemoveCoFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorRemoveCoFacilitatorPopup-header">
					<div 
						className="FacilitatorRemoveCoFacilitatorPopup-closeBtn" 
						onClick={() => {togglePopup(null);}} 
					/>
					<div className="FacilitatorRemoveCoFacilitatorPopup-title">
						{facilitatorUiTexts.removeCoFacilitatorPopup.title}
					</div>
				</div>
				{/* Popup body */}
				<div className="FacilitatorRemoveCoFacilitatorPopup-body">
					<div className="FacilitatorRemoveCoFacilitatorPopup-text">
						{renderMarkdown(popupText)}
					</div>
					<div className="FacilitatorRemoveCoFacilitatorPopup-buttons">
						<Button 
							isLoading={isRemovingCoFacilitator}
							classes={['blue', 'addGame']}
							text={facilitatorUiTexts.removeCoFacilitatorPopup.confirmBtn}
							onClick={() => {handleRemovePlayer();}}
						/>
						<Button 
							classes={['blue', 'addGame']}
							text={facilitatorUiTexts.removeCoFacilitatorPopup.cancelBtn}
							onClick={() => {togglePopup(null);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorRemoveCoFacilitatorPopup.propTypes = {
	gameId: PropTypes.string.isRequired,
	coFacilitatorData: PropTypes.object.isRequired,
	togglePopup: PropTypes.func.isRequired,
	removeCoFacilitatorFromGame: PropTypes.func.isRequired,
	toggleShowEditCoFacilitatorPopup: PropTypes.func.isRequired,
};

export default FacilitatorRemoveCoFacilitatorPopup;
