import React from 'react';
import PropTypes from 'prop-types';
import {modulesStructure} from 'data/modules/modules-structure';
import FacilitatorHeaderSubpage from '../facilitator-header-subpage/facilitator-header-subpage';
import './facilitator-game-modules.scss';

const FacilitatorGameModules = ({isFacilitator = false, deviceInfo, pageData, gameData, handleGoToPage}) => {

	return (
		<div className="FacilitatorGameModules">
			{(isFacilitator && pageData && handleGoToPage) &&
			<FacilitatorHeaderSubpage 
				page={pageData.id} 
				subpage="game-modules"
				subpageIds={pageData.subpageIds}
				handleGoToPage={handleGoToPage}
			/>}

			<div className='FacilitatorGameModules-flowchart'>
				{modulesStructure.map((step) => {
					return (
						<div key={step.id} className={'FacilitatorGameModules-step ' + step.id}>
							<div className="FacilitatorGameModules-stepTitle">
								<span>{step.title}</span>
							</div>
							<div className="FacilitatorGameModules-stepDescription">
								<span>{step.description}</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);

};

FacilitatorGameModules.propTypes = {
	isFacilitator: PropTypes.bool,
	deviceInfo: PropTypes.object.isRequired,
	pageData: PropTypes.object,
	gameData: PropTypes.object,
	handleGoToPage: PropTypes.func,
};

export default FacilitatorGameModules;
