const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		background: 'floral',
		showAvatar: true,
		text: 'Welcome back! We\'ll dive right into it.',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		characterType: 'wizard-flower',
		showAvatar: true,
		text: 'Flower power!',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-never-out-of',
		text: 'What items should floral never be out of?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Advertised items', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Roses',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Daisies',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Potted/Vases',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Carnations',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Before we check displays. Can you identify some plants?',
	}),
	/* GUESSING PLANTS */
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-plant-1',
		text: 'What type of plant is this?',
		image: 'plant1',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Succulent', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cactus',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Blooming',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Foliage',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hanging',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-plant-2',
		text: 'What type of plant is this?',
		image: 'plant2',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Succulent', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cactus',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Blooming',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Foliage',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hanging',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-plant-3',
		text: 'What type of plant is this?',
		image: 'plant3',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Succulent', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cactus',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Blooming',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Foliage',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hanging',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-plant-4',
		text: 'What type of plant is this?',
		image: 'plant4',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Succulent', 
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Technically this is correct. But we are looking for an even more precise answer.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Cactus',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! All cacti are also succulents, but not all succulents are cacti.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Blooming',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Foliage',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hanging',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-plant-5',
		text: 'What type of plant is this?',
		image: 'plant5',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'Succulent'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: 'Cactus'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'Blooming'}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: 'Foliage', isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: 'Hanging'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Now let\'s see how good you really are.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-flower-1',
		text: 'What flower is this?',
		image: 'flower1',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Daisy', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Sunflower',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Rose',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Tulip',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-flower-2',
		text: 'What flower is this?',
		image: 'flower2',
		options: [								
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Peony', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Rose',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Lilly',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Ranunculus',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Ranunculus and the flower you are looking at are often mistaken. Usually ranunculus are a bit smaller, though.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-roses',
		subtype: 'images',
		text: 'Which of these flowers are roses?',
		layout: 'floral-2-flowers',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				isCorrect: true,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-flower-3',
		text: 'What flower is this?',
		image: 'flower3',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Dahlia', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Gardenia',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Tulip',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Carnation',
				isCorrect: false,
			}),
		]
	}),
	/* GUESSING PLANTS DONE */
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Great work. Let\'s focus on Roses.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-where-roses',
		subtype: 'images',
		text: 'Where should roses be?',
		layout: 'floral-2-where-roses',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // water bucket
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // wrapped in paper
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // open with tie around stems
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // potted
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-remove-wraps',
		text: 'Before we place in cooler, remove the wraps...',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'To prevent molding', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'To prevent dry out',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'To promote blooming',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'To slow blooming',
				isCorrect: false,
			}),
		]
	}),
	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-dangers',
		text: 'What are the three biggest dangers to flowers?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Lack of water', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Bacteria',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Temperature',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Season',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Lack of plant food',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Plant food help extend the life of flowers. But lack of it will not do as much damage as other issues.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Zombies',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Don\'t they mostly eat brains?',
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		text: 'What is the order of operations when caring for flowers on display in water buckets?',
		taskId: 'floral-2-multiple-choice-flower-care-water-buckets',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'Remove plants and empty water',
				correctOrderNumbers: [1],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'Use 3-bay sink to clean buckets',
				correctOrderNumbers: [2],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'Rinse and refill with water',
				correctOrderNumbers: [3],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'Trim stems under running water',
				correctOrderNumbers: [4],
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: 'Place flowers into bucket',
				correctOrderNumbers: [5],
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-trimming',
		subtype: 'images',
		text: 'If you retrimmed those flowers first thing in the morning at 8:00am, then when should you (at the latest) put them back in the water?',
		layout: 'floral-2-clocks',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '8:00', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! 8:00, don\'t hesitate. Air will obstruct the stem from soaking up the water effectively.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '8:10',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '8:30',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '8:45',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		subtype: 'countdown',
		shownDelay: 3,
		delayTime: 3,
		characterPose: 2,
		text: `You have only 3 seconds to get trimmed flowers under water.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `That was 3 seconds. Not very long was it?`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-cutting',
		subtype: 'images',
		text: 'How would you cut flower stems to make sure they get the most water aborbed?',
		layout: 'floral-2-stems',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '45 degrees', 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right, it will give a nice amount of surface area.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'flat',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '30 degrees',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '60 degrees',
				isCorrect: false,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-cleaning',
		text: 'The water buckets were cleaned and refilled on Monday. If today is Tuesday, when do they need to be cleaned again?',
		image: 'bucket2',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Wednesday', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Thursday',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right. New food and fresh water every 3 days.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Friday',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Next Monday',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Today',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-bouquets-per-buckets',
		text: 'How many bouquets per bucket?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '2-3', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '1-2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '3-4',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '5',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-roses-per-bucket',
		text: 'It\'s good to have a bucket for single roses. How many should you put in a bucket?',
		image: 'flower4',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '9',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Yes! Nine per bucket is the correct number to allow the flowers enough room.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '8 or less',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '11',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Too many. You risk crushing them and they can be hard for the customers to grab.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'As many as you can fit',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Nope, that\'s a sure way of crushing the flowers.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 'floral-2-organize-bouquets',
		layout: 'floral-2-bouquets',
		text: 'We have 3 different types of bouquets we can put in our 9 buckets.',
		showSolutionWhenDone: true,
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 1, containerId: 'a1', image: 'bouquet1', alt: 'bouquet', correctContainerIds: ['b1']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 2, containerId: 'a2', image: 'bouquet2', alt: 'bouquet', correctContainerIds: ['b2']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 3, containerId: 'a3', image: 'bouquet3', alt: 'bouquet', correctContainerIds: ['b3']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 4, containerId: 'a4', image: 'bouquet4', alt: 'bouquet', correctContainerIds: ['b4']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 5, containerId: 'a5', image: 'bouquet5', alt: 'bouquet', correctContainerIds: ['b5']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 6, containerId: 'a6', image: 'bouquet6', alt: 'bouquet', correctContainerIds: ['b6']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 7, containerId: 'a7', image: 'bouquet7', alt: 'bouquet', correctContainerIds: ['b7']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 8, containerId: 'a8', image: 'bouquet8', alt: 'bouquet', correctContainerIds: ['b8']}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {id: 9, containerId: 'a9', image: 'bouquet9', alt: 'bouquet', correctContainerIds: ['b9']}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'final',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'final', finalItemId: 3}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b4', type: 'final', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b5', type: 'final', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b6', type: 'final', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b7', type: 'final', finalItemId: 7}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b8', type: 'final', finalItemId: 8}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b9', type: 'final', finalItemId: 9}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a6', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a7', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a8', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a9', type: 'initial'}),
				]
			}),
		],
		doneConditions: [
			Object.assign({}, taskTemplates.organizeTemplate.doneConditionTemplate, {
				type: 'empty-main-container', mainContainerId: 'initial'
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'floral-2-problem-solver-1', pointId: 'problem-solver', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'The floral department is not only about flowers. We offer potted plants a well.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-potted-plants-sizes',
		text: 'When it comes to potted plants, what\'s a good balance of sizes?',
		image: 'ruler',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '25% 4 inch, 75% 6 inch',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '25% 4 inch, 50% 6 inch, 25% 8 inch',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '100% 4 inch',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '100% 6 inch',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-upgrades',
		text: `Now let's add some upgrades. How many of our arrangements need to be upgraded?`,
		image: 'upgrades',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '90-100%',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This way most of the selection will be too expensive, and you\'ll only get a few shoppers.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '50%',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This is a bit too much. Doing upgrades takes more labor, so you can run out of time during the day and also overuse supplies.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '5-10%',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'This is too few upgrades. You\'ll sell most of the flowers this way, but you\'ll leave a lot of profit on the table.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '20-30%',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Perfect amount.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-broken-cooler',
		text: 'The floral cooler has gone down and the repairman is on the way. But we need these flowers moved from this cooler. Where can we put them?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Backroom large cooler', 
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'spot-errors-fail',
							title: 'Backroom large cooler',
							text: 'The flowers are ruined. Produce emits gas as it ages, and it can effect sensitive flowers.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Backroom freezer',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'spot-errors-fail',
							title: 'Backroom freezer',
							text: 'The are now officially the worst flavored popcicles. A freezer is too cold for flowers.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Grocery sales floor refrigerated',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'spot-errors-fail',
							title: 'Grocery sales floor refrigerated',
							text: 'The flowers end up broken and with missing petals. Being crowded up against product and having customers near them while shopping is not good.'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Dairy cooler',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'popup',
						popup: Object.assign({}, effectTemplate.popupTemplate, {
							type: 'spot-errors-result',
							title: 'Dairy cooler',
							text: 'This works. It\'s cool, the flowers are out of the way and neither dairy nor flowers will suffer from it.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-why-not-produce',
		text: 'Why not the produce cooler?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Produce gives off gas', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Fruits are jealous',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Flower absorb the smells',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'The vegetables turn bad',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'The fruits turn bad',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'wizard-stink',
		text: 'Uh. That may have actually been me.',
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['cloud-stink'],
					text: 'Brown cloud has been added to your avatar.'
				})
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 3,
		text: '\\*Sigh* Also that.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-hydrated',
		text: 'Once received, how long should flowers be hydrated before being put in the cooler?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '45-60 min', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '30 min',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '2 hours',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '5 min max',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'floral-2-sort-watering-schedule',
		image: 'calendar2',
		text: `Different plants need water at different times. Sort the plants into the right watering schedule.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'Every 2-3 weeks'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Every 2-3 days'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '3', text: 'Once per week'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '4', text: 'Monitor daily'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 1, text: 'Succulents', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 2, text: 'Cacti', categoryIds: ['1']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 3, text: 'Blooming plants', categoryIds: ['2']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 4, text: 'Foliage', categoryIds: ['3']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 5, text: 'Hanging plants', categoryIds: ['3']}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {id: 6, text: 'Outdoor plants', categoryIds: ['4']})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-dagnerous-for-customers',
		text: 'What is most dangerous for customers if we keep live plants on the floor?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Cutting knives', 
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Knives are for employees only and should be hidden from customers most of the time. Good thought, but not usually an issue.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Water',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Exactly! Spills are some of the most dangerous hazards in a store. Water even more so because it\'s hard to see.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Allergies',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Thorns',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'floral-2-spot-errors-dangers',
		text: 'What dangers do you see?',
		layout: 'floral-2-dangers',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hoses', // hoses running around put a few in
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'puddle', // water puddle
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'puddle2', // water puddle
			}),
		],
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Be aware of where your hoses are.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-buckets',
		subtype: 'images',
		text: 'How full do we fill buckets?',
		layout: 'floral-2-buckets',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // half way
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 2/3
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 1/3
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // top
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // 1/4
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: 'Okay, almost ready to call it a day. Just one more thing.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'floral-2-multiple-choice-double-check',
		text: 'What\'s an important thing to double check before you leave?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'The cooler door',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Good choice. If left ajar it will cost a lot of money.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'The floor',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You already checked the floor earlier. No specific need to do it again.',
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'The roses',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'The schedule',
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Always good to be prepared. But it can wait until tomorrow.',
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: 'Such great work. Thanks for helping out.',
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['flower-pot'],
					text: 'Flower pot has been added to your avatar.'
				})
			}),
		]
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'flo2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};