import PropTypes from 'prop-types';
import './item.scss';

const Item = ({page, itemId}) => {
	return (
		<div className={'Item ' + page + ' ' + itemId} />
	);
};

Item.propTypes = {
	page: PropTypes.string.isRequired,
	itemId: PropTypes.string.isRequired
};

export default Item;
