import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {adminUiTexts} from 'data/ui-texts';
import AdminFacilitatorPopup from './admin-facilitator-popup/admin-facilitator-popup';
import AdminDeleteFacilitatorPopup from './admin-delete-facilitator-popup/admin-delete-facilitator-popup';
import './admin-facilitators.scss';

const AdminFacilitators = ({gamesData, usersData, deleteFacilitator}) => {

	/* Default sorting property and direction */
	const [sortProperty, setSortProperty] = useState('name');
	const [sortDirection, setSortDirection] = useState('ASC');

	/* Sorted facilitators */
	const facilitators = usersData.filter((user) => {return user.role === 'facilitator';});
	const sortedFacilitators = sortArrayByProperty(facilitators,  sortProperty, sortDirection);

	/**
	 * Sort facilitators by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortProperty) {
			sortDirection === 'ASC' ? setSortDirection('DESC') : setSortDirection('ASC');
		} else {
			setSortProperty(property);
			setSortDirection('ASC');
		}
	};

	/* Select facilitator (shows popup with info about facilitator) */
	const [selectedFacilitatorId, setSelectedFacilitatorId] = useState(null);
	const handleSelectFacilitator = (facilitatorId = null) => {
		setSelectedFacilitatorId(facilitatorId);
	};

	/* Delete facilitator (popup) */
	const [showDeleteFacilitatorPopup, setShowDeleteFacilitatorPopup] = useState(false);
	const toggleDeleteFacilitatorPopup = (show = false) => {
		setShowDeleteFacilitatorPopup(show);
	};


	return (
		<div className="AdminFacilitators">
			{/* Facilitators list - header */}
			<div className="AdminFacilitators-header">
				<div 
					className={'AdminFacilitators-cell name' 
						+ (sortProperty === 'name' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('name');}}
				>
					<span>{adminUiTexts.name}</span>
				</div>
				<div 
					className={'AdminFacilitators-cell email' 
						+ (sortProperty === 'email' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {handleSortByProperty('email');}}
				>
					<span>{adminUiTexts.email}</span>
				</div>
				<div className="AdminFacilitators-cell lastLogin"><span>{adminUiTexts.lastLogin}</span></div>
				<div className="AdminFacilitators-cell store"><span>{adminUiTexts.stores}</span></div>
			</div>
			{/* Facilitators list - body */}
			<div className="AdminFacilitators-body">
				{sortedFacilitators.map((facilitator, index) => {
					let facilitatorGames = '';
					gamesData.forEach((game) => {
						if (game.facilitatorEmails && game.facilitatorEmails.indexOf(facilitator.email) >= 0) {
							if (facilitatorGames.length === 0) {
								facilitatorGames = game.storeName;
							} else {
								facilitatorGames += ', ' + game.storeName;
							}
						};
					});
					const lastLogin = (facilitator && facilitator.lastLogin 
						? dayjs(facilitator.lastLogin).format('MM/DD/YY') : '-');
					return (
						<div 
							key={index} 
							className="AdminFacilitators-row"
							onClick={() => {handleSelectFacilitator(facilitator.id);}}
						>
							<div className="AdminFacilitators-cell name"><span>{facilitator.name}</span></div>
							<div className="AdminFacilitators-cell email"><span>{facilitator.email}</span></div>
							<div className="AdminFacilitators-cell lastLogin"><span>{lastLogin}</span></div>
							<div className="AdminFacilitators-cell stores"><span>{facilitatorGames}</span></div>
						</div>		
					);
				})}
			</div>

			{selectedFacilitatorId &&
				<AdminFacilitatorPopup 
					facilitatorId={selectedFacilitatorId}
					gamesData={gamesData}
					usersData={usersData}
					handleSelectFacilitator={handleSelectFacilitator}
					toggleDeleteFacilitatorPopup={toggleDeleteFacilitatorPopup}
				/>}
			{(showDeleteFacilitatorPopup && selectedFacilitatorId) && 
				<AdminDeleteFacilitatorPopup 
					facilitatorId={selectedFacilitatorId}
					usersData={usersData}
					toggleDeleteFacilitatorPopup={toggleDeleteFacilitatorPopup}
					handleSelectFacilitator={handleSelectFacilitator}
					deleteFacilitator={deleteFacilitator}
				/>
			}
		</div>
	);

};

AdminFacilitators.propTypes = {
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	deleteFacilitator: PropTypes.func.isRequired
};

export default AdminFacilitators;
