import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {facilitatorUiTexts} from 'data/ui-texts';
import { renderMarkdown } from 'helpers/text-helper';
import Manager from 'components/ui/manager/manager';
import Button from 'components/ui/button/button';
import './facilitator-manager-popup.scss';

const FacilitatorManagerPopup = ({gameData, updateGameManager, setShowManagerPopup}) => {

	/* Selected manager */
	const [selectedManagerId, setSelectedManagerId] = useState(null);
	const handleSelectManager = (managerId) => {
		setSelectedManagerId(managerId);
	};

	/* Component did mount */
	useEffect(() => {	
		const managerId = (gameData && gameData.managerId ? gameData.managerId : appConfig.defaultManagerId);
		setSelectedManagerId(managerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Save selected manager */
	const handleSaveSelectedManager = () => {
		updateGameManager(gameData.id, selectedManagerId).then(() => {
			setShowManagerPopup(false);
		}).catch((error) => {
			console.error(error);
		});
	};

	/* Save button status */
	const saveBtnIsDisabled = ((gameData && gameData.managerId === selectedManagerId) || !selectedManagerId);

	return (
		<div className="FacilitatorManagerPopup" onClick={() => {setShowManagerPopup(false);}}>
			<div className="FacilitatorManagerPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="FacilitatorManagerPopup-header">
					<div className="FacilitatorManagerPopup-title">
						<span>{facilitatorUiTexts.selectManagerPopup.title}</span>
					</div>
					<div className="FacilitatorManagerPopup-closeBtn" onClick={() => {setShowManagerPopup(false);}} />
					
				</div>

				{/* Popup body */}
				<div className="FacilitatorManagerPopup-body">
					<div className="FacilitatorManagerPopup-text">
						{renderMarkdown(facilitatorUiTexts.selectManagerPopup.text)}
					</div>
					<div className="FacilitatorManagerPopup-managers">
						{['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].map((managerId) => {
							return (
								<div 
									key={managerId} 
									className={'FacilitatorManagerPopup-manager' 
										+ (managerId === selectedManagerId ? ' selected' : '')}
									onClick={() => {handleSelectManager(managerId);}}
								>
									<Manager page="facilitator-popup" managerPose="manager-2" managerId={managerId} />
								</div>
							);
						})}
					</div>

					<div className="FacilitatorManagerpopup-saveBtn">
						<Button 
							isDisabled={saveBtnIsDisabled}
							classes={['blue']}
							text={facilitatorUiTexts.selectManagerPopup.saveBtn}
							onClick={() => {handleSaveSelectedManager();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

FacilitatorManagerPopup.propTypes = {
	gameData: PropTypes.object.isRequired,
	updateGameManager: PropTypes.func.isRequired,
	setShowManagerPopup: PropTypes.func.isRequired,
	
};

export default FacilitatorManagerPopup;
