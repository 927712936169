import PropTypes from 'prop-types';
import './toggle-switch.scss';

const ToggleSwitch = ({ id, checked, isDisabled = false, onChange }) => {
	const handleToggle = (e) => {
		if (!isDisabled) {
			onChange(e.target.id);
		}
		
	};

	return (
		<div className={'ToggleSwitch' + (isDisabled ? ' disabled' : '')}>
			<input
				type="checkbox"
				className="ToggleSwitch-checkbox"
				id={id}
				checked={checked}
				onChange = {(e) => {handleToggle(e);}}
			/>
			<label className="ToggleSwitch-label" htmlFor={id}>
				<span className="ToggleSwitch-inner" />
				<span className="ToggleSwitch-switch" />
			</label>
		</div>
	);

};

ToggleSwitch.propTypes = {
	id: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default ToggleSwitch;
