const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		background: 'ocean-island',
		showAvatar: true,
		text: `Welcome to Customer Service Island. The one island everyone here has in common.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		animateCharacter: true,
		background: 'cashier',
		showAvatar: true,
		text: `Would it be too cheesy to say "let's dive in"?`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		animateCharacter: true,
		background: 'cashier',
		showAvatar: true,
		text: `Let's start at the Introduction Board.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-mc-information-board',
		subtype: 'images',
		layout: 'orientation-1-information-board',
		text: 'Select the High Five Steps',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
			})
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `These are very important to ensure customer satisfaction.`,
		layout: 'customer-service-1-clip-board',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-food-date',
		text: `Which of these steps ensures food is within date?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Customer Service'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Quality & Freshness',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Cleanliness'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Stock Level`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `Signage/Pricing`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-customer-needs',
		text: `Which of these supports the Customer the most?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Customer Service',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Quality & Freshness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Cleanliness'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Stock Level`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `Signage/Pricing`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-product-planning',
		text: `Which of these reflects proper production planning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Customer Service'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Quality & Freshness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Cleanliness'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Stock Level`,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `Signage/Pricing`
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-purchasing',
		text: `Which assures customers of exactly what they are purchasing?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: 'Customer Service'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: 'Quality & Freshness',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: 'Cleanliness'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Stock Level`,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `Signage/Pricing`,
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-mc-high-five',
		subtype: 'images',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		layout: 'high-five',
		text: 'Since they are the High Five Steps, how about a high five?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Oh ya!'
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: false,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'You missed.'
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Let's go back to the information board.`,
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `The first step will be making the store look it's best.`,
		layout: 'customer-service-1-clip-board',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `Take a look at our Customer Service desk from the Customer’s View.`,
		layout: 'customer-service-1-desk',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		text: `Take a look at our Customer Service desk.`,
		layout: 'customer-service-1-desk-front',
		nextBtnTypes: ['next'],
		nextBtnText: null
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'customer-service-1-spot-errors-service-desk',
		layout: 'customer-service-1-service-desk',
		text: `Click on the elements that the customer shouldn't see.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'trash'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'cup'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'shoe'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'post-its'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `It's very important to always start with the customer's perspective.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-customer-perspective',
		subtype: 'images',
		layout: 'customer-service-1-customer-perspective',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		text: `Looking on these items and areas, where does customer perspective matter?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 1, text: 'Store', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2, text: `generic employee`, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: 'Produce', isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4, text: `Signs/pricing`, isCorrect: true}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 5, text: `uncooked steak`, isCorrect: true})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Exactly, ALL of it matters in the eyes of the customer.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Now the store looks ready, how about yourself?`,
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'customer-service-1-paper-doll-get-dressed',
		showToolbox: true,
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'apron-full', 
				type: 'bodytype', 
				isCorrect: false, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Full apron'
			}),			
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'smile', 
				isCorrect: true, 
				dependsOnBodyHeight: true,
				title: 'Giant smile'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'nametag', 
				type: 'alternative', 
				isCorrect: true, 
				dependsOnBodyHeight: true,
				title: 'Name tag'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'ears', 
				type: 'alternative', 
				isCorrect: true, 
				dependsOnBodyHeight: true,
				title: 'Big ears'
			}),

			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'gloves-plastic', 
				type: 'alternative', 
				isCorrect: false, 
				dependsOnBodyHeight: true, 
				dependsOnBodyWidth: true,
				title: 'Gloves'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone',
				isCorrect: true,
				title: 'Phone'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'pencil-and-pad',
				isCorrect: true,
				title: 'Pencil & pad'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'map',
				isCorrect: true,
				title: 'Map'
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'knife',
				isCorrect: false,
				title: 'Knife'
			}),
		],
		doneEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'special-points',
				specialPoints: Object.assign({}, effectTemplate.specialPointsTemplate, {
					id: 'customer-service-1-always-prepared-1', pointId: 'always-prepared', value: 1
				}),
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate)
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `Now you are ready to greet the customers.`,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-greeting-customers',
		subtype: 'images',
		layout: 'customer-service-1-greeting-customers',
		text: `Which of these looks right for greeting customers?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'That\'s right. Eye contact and comfortable smile.'
						})
					})
				]
			}), // Eyes forward, smiling
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 2}), // eyes closed, smiling
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3}), // looking down
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 4}), // asleep
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		text: `On the next screen, see if you can help these customers.`,
		background: 'customer-line',
	}),
	// CUSTOMER SERVICE CLOCKTASK 1
	Object.assign({}, taskTemplates.clockTasksTemplate.mainTemplate, {
		taskId: 'customer-service-1-clock-tasks-customer-service',
		layout: 'customer-service-1',
		startTime: {hours: 13, minutes: 0},
		maxDurationMinutes: 120,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'feedback',
				feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
					text: `Click on a customer to help them.`
				})
			})
		],
		tasks: [
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.dialogueTemplate.mainTemplate, {
				id: 'csc-01',
				endTime: 45,
				durationMinutes: 15,
				showPoints: false,
				hasImage: true,
				hasSolvedImage: false,
				title: 'Old lady',
				characterId: 'elderly-lady-with-cart',
				maxPoints: 13,
				introText: 'What do you ask the lady?',
				introTextType: 'info',
				introCharacter: 'elderly-lady-with-cart',
				introShowAvatar: true,
				steps: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-01',
						optionPrompt: 'Choose your answer',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Do you need any assistance getting those to your car?', nextStepId: 'cd-02',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 2, text: 'Did you find everything you were looking for?', nextStepId: 'cd-03',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})]
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 3, text: 'Thanks for coming. Have a good day.', nextStepId: 'cd-04'
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-02',
						text: 'Thank you for helping me. These are so heavy.',
						nextStepId: 'cd-05',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-03',
						text: 'Yes. I believe so. Now I just have to get them all in the trunk.',
						nextStepId: 'cd-06',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-04',
						text: 'Thank you.',
						nextStepId: 'cd-07',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-05',
						text: 'A+ action. Well done!',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-06',
						text: 'Not bad. Solid B. But we could have done more.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-07',
						text: 'I think we could have done much better.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
				]
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.dialogueTemplate.mainTemplate, {
				id: 'csc-02',
				endTime: 45,
				durationMinutes: 15,
				showPoints: false,
				hasImage: true,
				hasSolvedImage: false,
				title: 'Two box customer',
				characterId: 'man-with-boxes',
				maxPoints: 13,
				introText: 'Can you help me decide on these cereals?',
				introTextType: 'info',
				introCharacter: 'man-with-boxes',
				introShowAvatar: true,
				steps: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-11',
						optionPrompt: 'Choose your answer',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Who would be eating the cereal?', nextStepId: 'cd-12',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 2, text: 'This one is very popular here.', nextStepId: 'cd-13',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})]
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 3, text: 'That one is my favorite.', nextStepId: 'cd-14',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
							}),
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-12',
						text: 'It\'s for my kids.',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Then I would suggest this one. It\'s very popular with families.', nextStepId: 'cd-15',
							}),
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-13',
						text: 'Sounds good.',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Great. Have a nice day.', nextStepId: 'cd-16',
							}),
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-14',
						text: 'Okay, but it\'s for my kids.',
						image: 'annoyed',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Well, you can\'t go wrong with sugar.', nextStepId: 'cd-17',
							}),
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-15',
						text: 'Way to exceed expectations by asking questions. A+',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-16',
						text: 'Good that you know what\'s going on in the store. Good for a B.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-17',
						text: 'Good try at least.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
				],
			}),
			Object.assign({}, taskTemplates.clockTasksTemplate.taskTemplate, taskTemplates.dialogueTemplate.mainTemplate, {
				id: 'csc-03',
				endTime: 45,
				durationMinutes: 15,
				showPoints: false,
				hasImage: true,
				hasSolvedImage: false,
				title: '',
				characterId: 'middle-aged-man-with-watch',
				maxPoints: 13,
				introText: 'I really need to find the bathroom.',
				introTextType: 'info',
				introCharacter: 'middle-aged-man-with-watch',
				steps: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-21',
						optionPrompt: 'Choose your answer',
						options: [
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 1, text: 'Of course. Right this way.', nextStepId: 'cd-22',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
							}),
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 2, text: 'Straight down this aisle. Then turn Right.', nextStepId: 'cd-23',
								effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})]
							}),	
							Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
								id: 3, text: 'I\'m sorry sir, but bathroom\'s are for paying customers only.', nextStepId: 'cd-24'
							})
						]
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-22',
						text: 'Thank you so much.',
						image: 'happy',
						nextStepId: 'cd-25',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-23',
						text: 'Thanks',
						image: 'happy',
						nextStepId: 'cd-26',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-24',
						text: 'Well, you won\'t get any money from me. Goodbye!',
						nextStepId: 'cd-27',
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-25',
						text: 'Well done. Always lead the way. A+',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-26',
						text: 'Good directions. Good store knowledge. Less helpful though.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
						id: 'cd-27',
						text: 'Not the way we should handle it.',
						showPoints: true,
						isFinalStep: true,
						type: 'dialogue',
						character: 'manager-1'
					}),
				]
			}),
		]
	}),
	// CUSTOMER SERVICE CLOCKTASK 1 DONE
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'customer-service-1-multiple-choice-end-conversation',
		image: 'customer-service-manager',
		showManager: true,
		image: 'manager-top-right',
		text: `And one more thing before they all go?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Thank you for coming.',
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: 'Always end on a Thank you.'
						})
					}),
				]
			}), 
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Good to see you.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Glad you stopped by.',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Merry Thanksgiving.',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 2,
		text: `Nicely done working with those customers.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		characterPose: 1,
		characterType: 'manager',
		text: `That's all for today. Welcome to the team.`,
		onLoadEffects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				type: 'avatar-item', 
				avatarItem: Object.assign({}, effectTemplate.avatarItemTemplate, {
					itemIds: ['leather-jacket-m', 'leather-jacket-f'],
					text: 'A leather jacket has been added to your avatar.'
				})
			}),
		]
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'cus1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};