import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import {insightsData} from 'data/insights-data';
import Button from 'components/ui/button/button';
import './facilitator-muted-insights-popup.scss';

const FacilitatorMutedInsightsPopup = ({canEdit, userData, gameData, setShowMutedPopup, handleUpdateMutedInsights}) => {

	/* Editing, Loading */
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	
	/* Track insights marked for muting */
	const [mutedInsightIds, setMutedInsightIds] = useState([]);

	/* Get muted insights on load */
	useEffect(() => {
		if (gameData.insights && gameData.insights.length > 0) {
			let newMutedInsightIds = [];
			gameData.insights.forEach((insight) => {
				if (insight.mutedBy && insight.mutedBy.length > 0 && insight.mutedBy.indexOf(userData.email) >= 0) {
					newMutedInsightIds.push(insight.insightId);
				}
			});
			setMutedInsightIds(newMutedInsightIds);
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/**
	 * Toggle if insight is muted
	 * @param {string} insightId 
	 */
	const toggleMutedInsight = (insightId) => {
		if (!canEdit) return;

		let newMutedInsightIds = JSON.parse(JSON.stringify(mutedInsightIds));
		const insightIndex = newMutedInsightIds.indexOf(insightId);
		if (insightIndex >= 0) {
			newMutedInsightIds.splice(insightIndex, 1);
		} else {
			newMutedInsightIds.push(insightId);
		}
		setMutedInsightIds(newMutedInsightIds);
		setIsEditing(true);
	};

	/**
	 * Save changes
	 * @returns
	 */
	const saveChanges = () => {
		if (isLoading || !isEditing) return;
		setIsLoading(true);
		handleUpdateMutedInsights(mutedInsightIds);
	};


	return (
		<div className="FacilitatorMutedInsightsPopup">
			<div className="FacilitatorMutedInsightsPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorMutedInsightsPopup-header">
					<div className="FacilitatorMutedInsightsPopup-title">
						<span>{facilitatorUiTexts.insights.mutedInsightsPopup.title}</span>
					</div>		
					<div
						className="FacilitatorMutedInsightsPopup-closeBtn" 
						onClick={() => {setShowMutedPopup(false);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorMutedInsightsPopup-body'>
					<div className="FacilitatorMutedInsightsPopup-text">
						<span>{facilitatorUiTexts.insights.mutedInsightsPopup.text}</span>
					</div>
					<div className="FacilitatorMutedInsightsPopup-insights">
						{gameData.insights && gameData.insights.map((insight) => {
							const insightData = insightsData.find((i) => {return i.id === insight.insightId;});
							if (!insightData) return null;

							return (
								<div 
									key={insight.insightId}
									className={'FacilitatorMutedInsightsPopup-insight' 
										+ (canEdit ? '' : ' disabled')
										+ (mutedInsightIds.indexOf(insight.insightId) >= 0 ? ' muted' : '')}
									onClick={() => {toggleMutedInsight(insight.insightId);}}
								>
									<span>{insightData.title}</span>
								</div>
							);
						})}
					</div>

					{canEdit && <div className="FacilitatorMutedInsightsPopup-buttons">
						<div className="FacilitatorMutedInsightsPopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								isDisabled={!isEditing}
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.insights.mutedInsightsPopup.saveBtn}
								onClick={() => {saveChanges(mutedInsightIds);}}
							/>
						</div>
						<div className="FacilitatorMutedInsightsPopup-cancelBtn">
							<Button 
								classes={['blue', 'adminPopup', 'wide']}
								text={facilitatorUiTexts.insights.mutedInsightsPopup.cancelBtn}
								onClick={() => {setShowMutedPopup(false);}}
							/>
						</div>
					</div>}
				</div>
			</div>
		</div>
	);

};

FacilitatorMutedInsightsPopup.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	setShowMutedPopup: PropTypes.func.isRequired,
	handleUpdateMutedInsights: PropTypes.func.isRequired,
};

export default FacilitatorMutedInsightsPopup;
