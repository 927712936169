import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {checkMessage} from 'helpers/message-helper';
import {getParticipatingPlayers} from 'helpers/competition-helper';
import {facilitatorUiTexts, errorUiTexts} from 'data/ui-texts';
import {areasData} from 'data/areas-data';
import SelectMultiple from 'components/ui/select-multiple/select-multiple';
import Button from 'components/ui/button/button';
import './facilitator-edit-message-popup.scss';

const FacilitatorEditMessagePopup = ({gamePlayers, messageData, setEditMessageData, editMessage}) => {
	/* Recipients  */
	const [sendToAll, setSendToAll] = 
		useState(!messageData.selectedAreaIds || messageData.selectedAreaIds.length === 0 ? true : false);
	const [selectedAreaIds, setSelectedAreaIds] = 
		useState(messageData.selectedAreaIds ? JSON.parse(JSON.stringify(messageData.selectedAreaIds)) : []);
	
	/* Get area options */
	const areaOptions = areasData.filter((area) => {return area.canBeDisabled === true;}).map((area) => {
		return {id: area.id, value: area.title};
	});

	/* Toggle area id */
	const toggleSelectedAreaId = (areaId) => {
		let newSelectedAreaIds = JSON.parse(JSON.stringify(selectedAreaIds));
		const areaIndex = selectedAreaIds.indexOf(areaId);
		if (areaIndex >= 0) {
			newSelectedAreaIds.splice(areaIndex, 1);
		} else {
			newSelectedAreaIds.push(areaId);
		}
		setSelectedAreaIds(newSelectedAreaIds);
		setErrMsg(null);
	};


	/* Text */
	const [text, setText] = useState(messageData.text ? JSON.parse(JSON.stringify(messageData.text)) : '');
	const editText = (event) => {
		setText(event.target.value);
		setErrMsg(null);
	};

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/* Is creating new message */
	const [isCreating, setIsCreating] = useState(false);

	/* Get all current players that message will be send to */
	const participatingPlayers = getParticipatingPlayers(gamePlayers, sendToAll, selectedAreaIds);
	
	/* Confirm */
	const handleConfirmMessage = () => {
		if (isCreating) return;

		const newErrMsg = checkMessage(sendToAll, selectedAreaIds, text);

		if (newErrMsg) {
			setErrMsg(newErrMsg);
		} else {
			setIsCreating(true);
			editMessage(messageData.id, {text: text, selectedAreaIds: selectedAreaIds}).then((response) => {
				if (response.status === 'success') {
					setEditMessageData(null);
				} else {
					setErrMsg(errorUiTexts.unknownError);
					setIsCreating(false);
				}
			});
		}		
	};

	return (
		<div className="FacilitatorEditMessagePopup">
			<div className="FacilitatorEditMessagePopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorEditMessagePopup-header">
					<div className="FacilitatorEditMessagePopup-title">
						<span>{facilitatorUiTexts.editMessagePopup.title}</span>
					</div>		
					<div
						className="FacilitatorEditMessagePopup-closeBtn" 
						onClick={() => {setEditMessageData(null);}}
					/>
				</div>

				{/* Popup - body */}
				<div className='FacilitatorEditMessagePopup-body'>
					{/* Message text */}
					<div className='FacilitatorEditMessagePopup-text'>
						<div className='FacilitatorEditMessagePopup-textLabel'>
							<span>{facilitatorUiTexts.newMessagePopup.text}</span>
						</div>
						<div className='FacilitatorEditMessagePopup-textInput'>
							<textarea 
								name="text"
								placeholder={facilitatorUiTexts.newMessagePopup.typeHere}
								value={text ? text : ''}
								onChange={(e) => {editText(e);}}
							/>
						</div>
					</div>
					
					{/* Recipient  */}
					<div className='FacilitatorEditMessagePopup-recipients'>
						<div className='FacilitatorEditMessagePopup-recipientsLabel'>
							<span>{facilitatorUiTexts.newMessagePopup.recipients}</span>
						</div>
						<div 
							className={'FacilitatorEditMessagePopup-recipientsOption all' +
								(sendToAll ? ' selected' : '')}
							onClick={() => {setSendToAll(true);}}
						>
							<span>{facilitatorUiTexts.newMessagePopup.allPlayers +
								(sendToAll ? ' (' + participatingPlayers.length + ')' : '')
							}</span>
						</div>
						<div 
							className={'FacilitatorEditMessagePopup-recipientsOption some' +
								(sendToAll ? '' : ' selected')}
							
							onClick={() => {setSendToAll(false);}}
						>
							<span>{facilitatorUiTexts.newMessagePopup.somePlayers +
								(!sendToAll ? ' (' + participatingPlayers.length + ')' : '')
							}</span>
							{!sendToAll && 
								<div className="FacilitatorEditMessagePopup-departmentsSelect">
									<SelectMultiple 
										isDisabled={false}
										type="competition"
										placeholderText={facilitatorUiTexts.newMessagePopup.selectedDepartments 
											+ ' (' + selectedAreaIds.length + ')'}
										selectedIds={selectedAreaIds}
										options={areaOptions}
										onSelect={toggleSelectedAreaId}
									/>
								</div>
							}
						</div>
					</div>

					{/* Error message */}
					<div className="FacilitatorEditMessagePopup-errorMsg">
						{errMsg && renderMarkdown(facilitatorUiTexts.newMessagePopup.error + ': ' + errMsg)}
					</div>

					{/* Confirm btn */}
					<div className="FacilitatorEditMessagePopup-confirmBtn">
						<Button 
							isLoading={isCreating}
							text={facilitatorUiTexts.editCompetitionPopup.edit}
							classes={['download', 'launch']}
							onClick={() => {handleConfirmMessage();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

FacilitatorEditMessagePopup.propTypes = {
	gamePlayers: PropTypes.array.isRequired,
	messageData: PropTypes.object.isRequired,
	setEditMessageData: PropTypes.func.isRequired,
	editMessage: PropTypes.func.isRequired
};

export default FacilitatorEditMessagePopup;
