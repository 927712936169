import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {multipleChoicePoints} from 'data/points-data';
import { checkIfConditionsAreFulfilled } from 'helpers/effect-helper';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import Manager from 'components/ui/manager/manager';
import './multiple-choice.scss';

const MultipleChoice = (props) => {
	const {
		isFacilitator = false,
		deviceInfo,
		areaId,
		managerId,
		playerTaskData, 
		taskData, 
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask,
		
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	
	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);


	/**
	 * Adjust height of options container with respect to task intro
	 */
	const [optionsStyle, setOptionsStyle] = useState(null);
	const getOptionsStyle = () => {
		let style = null;
		const taskIntroElement = document.getElementById('taskIntro');
		if (taskIntroElement) {
			const offsetTop = taskIntroElement.clientHeight + taskIntroElement.offsetTop;
			style = {position: 'absolute', bottom: '0', top: offsetTop + 'px'};
		}
		return style;
	};
	

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];
		if (playerTaskData && playerTaskData.selectedOptionIds) optionIds = playerTaskData.selectedOptionIds;
		return optionIds;
	};

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	const [points, setPoints] = useState(0);
	
	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionIds(getSelectedOptionIds());

		if (taskData.layout !== 'high-five') {
			setOptionsStyle(getOptionsStyle());
		}

		setPoints(0);
		setAnimateSelectedOptions(false);

		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Update options style if device dimensions change */
	useEffect(() => {
		if (taskData.layout !== 'high-five') {
			setOptionsStyle(getOptionsStyle());
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceInfo]);

	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Facilitator cannot solve task */
		if (isFacilitator) return;

		/* Already completed */
		if (isCompleted === true) return;

		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;

		/* Update logged time */
		updateLoggedTime();

		/* Enable animation */
		setAnimateSelectedOptions(true);

		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);


		/* Get option data */
		const selectedOptionData = (taskData.options 
			? taskData.options.find((o) => {return o.id === optionId;})
			: 0
		);
		
		/* Handle effects */
		let newPoints = points;
		let instantEffects = [];
		if (selectedOptionData && selectedOptionData.effects.length > 0) {
			selectedOptionData.effects.forEach((effect) => {
				if (effect.type === 'points') {
					/* Effect: points */
					newPoints += effect.value;	
				} else {
					/* Effect: feedback, popup, special points, new avatar item */
					instantEffects.push(effect);
				}
			});
		}

		/* Error/success streak */
		if (!taskData.isDilemma && selectedOptionData) {
			instantEffects.push({type: 'streak', isCorrectAnswer: selectedOptionData.isCorrect});
		}

		/* Update points */
		setPoints(newPoints);

		/* Check if task is completed */
		const taskIsCompleted = (taskData.isDilemma
			? taskData.numberOfAnswersToSelect === newSelectedOptionIds.length
			: checkIfAllCorrectOptionsAreSelected(newSelectedOptionIds)
		);
		if (taskIsCompleted) {
			/* Complete task, instant effects -> normal effects */
			completeTask(newSelectedOptionIds, newPoints, instantEffects);
		} else {
			/* Instant effects (shown while task is still not completed) */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Check if all correct options are selected (i.e. if task is complete)
	 * @param {array} newSelectedOptionIds 
	 * @returns 
	 */
	const checkIfAllCorrectOptionsAreSelected = (newSelectedOptionIds) => {
		let allCorrectOptionsAreSelected = true;
		taskData.options.forEach((optionData) => {
			if (optionData.isCorrect === true && newSelectedOptionIds.indexOf(optionData.id) < 0) {
				allCorrectOptionsAreSelected = false;
			}
		});
		return allCorrectOptionsAreSelected;
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 * @param {number} newPoints
	 */
	const completeTask = (newSelectedOptionIds, newPoints, effects) => {
		/* Prepare array of effects */
		let newEffects = (effects ? [...effects] : []);

		/* Get number of wrong answers */
		let wrongAnswers = 0;
		if (!taskData.isDilemma) {
			newSelectedOptionIds.forEach((selectedOptionId) => {
				if (!taskData.options.some((optionData) => {
					return (optionData.id === selectedOptionId && optionData.isCorrect === true);
				})) {
					wrongAnswers += 1;
				}
			});
		}

		/* Calculate points */
		let updatedPoints = 0;
		if (!taskData.isDilemma) {
			const maxPoints = Math.min(taskData.options.length, multipleChoicePoints.basePoints);
			updatedPoints = Math.max(
				multipleChoicePoints.minimumPoints, 
				maxPoints - (wrongAnswers * multipleChoicePoints.minusPointsPerWrongAnswers)
			);
		}
		updatedPoints += newPoints;
		
		/* Get effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, wrongAnswers);

				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						updatedPoints += effect.value;
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						newEffects.push(effect);
					}
				}
			});
		}

		/* Save completed task */
		handleCompleteTask(
			'multiple-choice', 
			updatedPoints,
			wrongAnswers, 
			newEffects,
			{selectedOptionIds: newSelectedOptionIds}
		);
	};

	return (
		<div className={'MultipleChoice ' + areaId 
			+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
			+ (taskData.isDilemma ? ' dilemma' : '')
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
			+ ' manager-' + managerId
		}
		>
			<div id="taskIntro" className="MultipleChoice-intro">
				<TaskIntro 
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					numberOfCorrectAnswers={numberOfCorrectAnswers} 
					text={taskData.text}
					image={taskData.image}
					showManager={taskData.showManager}
					managerId={managerId}
					managerPose={taskData.managerPose}
				/>
			</div>
			<div id="multipleChoiceOptions" className="MultipleChoice-options" style={optionsStyle}>
				
				<div className={'MultipleChoice-optionsWrap manager-' + managerId}>
					{(taskData.subtype === 'images' && taskData.layout === 'high-five') && 
						<div className="MultipleChoice-manager">
							<Manager page="high-five" managerPose="manager-7" managerId={managerId} />
						</div>
					}
					{optionIds.map((optionId, index) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;
						const isSelected = (
							selectedOptionIds.indexOf(optionData.id) >= 0 ||
							(isFacilitator && isCompleted && optionData.isCorrect)
						);
						let optionClass = 'MultipleChoice-option';
						if (isSelected) optionClass += ' selected';
						if (!isSelected && isCompleted) optionClass += ' completed';
						if (isSelected && !taskData.isDilemma && optionData.hasOwnProperty('isCorrect')) {
							if (optionData.isCorrect) {
								optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
							} else {
								optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
							}
						}				

						if (taskData.layout) optionClass += ' option-' + optionData.id + ' position-' + (index + 1);

						return (
							<div 
								key={optionData.id} 
								className={optionClass} 
								onClick={() => {selectOptionId(optionData.id);}}
							>
								<span>{optionData.text}</span>
								<span></span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	isFacilitator: PropTypes.bool,
	deviceInfo: PropTypes.object.isRequired,
	areaId: PropTypes.string.isRequired,
	managerId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default MultipleChoice;
