/**
 * checks if two objects has the same content
 * @param {Object} obj1 
 * @param {Object} obj2 
 * @returns 
 */
export const objectCompare = (obj1, obj2) => {  
	if (Object.keys(obj1).length === Object.keys(obj2).length) {
		return Object.keys(obj1).every(
			(key) => {
				return obj2.hasOwnProperty(key)
				&& obj2[key] === obj1[key];
			});
	}
	return false;
};