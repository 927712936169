const moduleSeafood2 = [
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-1.svg',
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-2.svg',
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-3.svg',
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-4.svg',
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-5.svg',
	'modules/tasks/multiple-choice/seafood-2-plan-how-far/option-6.svg',
	'modules/tasks/multiple-choice/seafood-2-service-case-shingle/background.svg',
	
	'modules/tasks/spot-errors/seafood-2-self-service-case1.svg',
];

export {
	moduleSeafood2
};
